import { createSelector } from 'reselect';
import Buckets from '../Buckets';
import DataSources from '../DataSources';

export const getLoadingFinished = createSelector(
  DataSources.selectors.getLoadingInProgress,
  DataSources.selectors.getDataSources,
  Buckets.selectors.getLoadingInProgress,
  Buckets.selectors.getBuckets,
  (dataSourcesLoading, dataSources, bucketsLoading, buckets) => {
    return !(
      (dataSourcesLoading && (!dataSources || dataSources.length === 0)) ||
      (bucketsLoading && (!buckets || buckets.length === 0))
    );
  },
);
