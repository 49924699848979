import { string, bool } from 'prop-types';
import Page from '../../Page';
import Banner from '../../Banner';
import Form from '../../Form';
import {
  AuthorizationServiceTypeOptionsDiv,
  AuthorizationServiceTypeOptionDiv,
} from './styledComponents';
import AuthorizationServices from '../../AuthorizationServices';
import RangerHive from '../../../img/logo/hive-ranger.svg';
import LakeFormation from '../../../img/logo/lakeformation.svg';

const display = {
  boxTitle: 'Service Type',
  rangerHive: 'Apache Ranger with Hive Plugin',
  lakeFormation: 'AWS Lake Formation',
  requiredError: 'Please select an authorization service type',
};

const authorizationServiceType = (label, Logo) => {
  return () => {
    return (
      <AuthorizationServiceTypeOptionDiv>
        <img src={Logo} alt={label} />
        <div>{label}</div>
      </AuthorizationServiceTypeOptionDiv>
    );
  };
};

const authorizationServiceTypeValues = [
  {
    value: AuthorizationServices.constants.authorizationServiceTypes.rangerHive,
    label: display.rangerHive,
    component: authorizationServiceType(display.rangerHive, RangerHive),
  },
  {
    value:
      AuthorizationServices.constants.authorizationServiceTypes.lakeFormation,
    label: display.lakeFormation,
    component: authorizationServiceType(display.lakeFormation, LakeFormation),
  },
];

const SelectAuthorizationServiceType = ({
  error,
  disabled,
  defaultAuthService,
}) => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <AuthorizationServiceTypeOptionsDiv>
        <Form.RadioInputs
          name='authorizationServiceType'
          values={authorizationServiceTypeValues}
          disabled={disabled}
          defaultValue={defaultAuthService}
          validationRules={{ required: display.requiredError }}
        />
      </AuthorizationServiceTypeOptionsDiv>
    </Page.Box>
  );
};

SelectAuthorizationServiceType.defaultProps = {
  error: undefined,
  disabled: false,
};

SelectAuthorizationServiceType.propTypes = {
  error: string,
  disabled: bool,
  defaultAuthService: string.isRequired,
};

export default SelectAuthorizationServiceType;
