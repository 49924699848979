import { useEffect } from 'react';
import { bool, func, string, arrayOf } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Form from '../../Form';
import Page from '../../Page';
import PrestoUsers from '../../PrestoUsers';
import PrestoClusters from '../../PrestoClusters';
import useRouteBuilder from '../../../router/useRouteBuilder';
import UpdatePrestoUserForm from './UpdatePrestoUserForm';

const UpdatePrestoUser = ({
  submitInProgress,
  submitSuccess,
  error,
  prestoUserId,
  prestoUser,
  prestoClusters,
  notFound,
  usePop,
  canModify,
  loadPrestoUser,
  loadPrestoClusters,
  savePrestoUser,
}) => {
  useEffect(() => {
    loadPrestoUser(prestoUserId);
    loadPrestoClusters(prestoUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoUserId]);

  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!prestoUser || notFound) {
    return <Page.Loading />;
  }

  return (
    <Page.PageWithOverflow>
      <Form.Form
        name='update-presto-user-password'
        submitAction={savePrestoUser}
      >
        <UpdatePrestoUserForm
          prestoUser={prestoUser}
          error={error}
          submitInProgress={submitInProgress}
          submitSuccess={submitSuccess}
          prestoClusters={prestoClusters}
          canModify={canModify}
          prestoUserId={prestoUserId}
          usePop={usePop}
          notFound={notFound}
        />
      </Form.Form>
    </Page.PageWithOverflow>
  );
};

UpdatePrestoUser.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  error: undefined,
  prestoUser: null,
  prestoClusters: null,
  usePop: true,
  notFound: false,
  canModify: true,
};

UpdatePrestoUser.propTypes = {
  submitInProgress: bool,
  submitSuccess: bool,
  error: string,
  prestoUserId: string.isRequired,
  prestoUser: PrestoUsers.propTypes.PrestoUser,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  notFound: bool,
  usePop: bool,
  canModify: bool,
  loadPrestoUser: func.isRequired,
  loadPrestoClusters: func.isRequired,
  savePrestoUser: func.isRequired,
};

export default UpdatePrestoUser;
