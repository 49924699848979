import { connect } from '../../utils/redux';
import { AddPrestoUser } from './components';
import * as selectors from './selectors';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
  return {
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    error: selectors.getError(state),
    usePop: selectors.getUsePop(state),
    hideCreatePrestoUser: props.hideCreatePrestoUser,
  };
};

export default connect(mapStateToProps, {
  submit: actions.submit,
})(AddPrestoUser);
