import { string } from 'prop-types';
import Logo from '../../../img/logo/presto.svg';
import Data from '../../Data';
import Page from '../../Page';
import PrestoClusters from '../../PrestoClusters';
import { useIsSubscriptionValid } from '../../utils/featureHooks';
import { PrestoEndpointDiv, DownloadsDiv } from './styledComponents';

const display = {
  title: 'Connection Details',
  clusterEndpointLabel: 'Presto endpoint',
  jdbcLabel: 'JDBC endpoint',
  prestoConsoleLabel: 'Presto Console',
  buttonPrestoCLI: 'Presto CLI',
  buttonPrestoJDBC: 'JDBC Driver',
};

const ConnectionDetails = ({
  navTarget,
  prestoClusterUrl,
  jdbcConnectionUrl,
  downloadLinks,
}) => {
  const isSubscriptionValid = useIsSubscriptionValid();

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      <Page.DataContentWithLogo>
        <img src={Logo} alt='PrestoDB' className='logo' />
        <div>
          <Data.Label label={display.clusterEndpointLabel} />
          <PrestoEndpointDiv>
            <Data.DataFieldWithCopy
              value={prestoClusterUrl}
              url={prestoClusterUrl}
              disabled={!isSubscriptionValid}
            />
            {prestoClusterUrl && (
              <Page.TertiaryLink to={prestoClusterUrl} external newTab>
                {display.prestoConsoleLabel}
              </Page.TertiaryLink>
            )}
          </PrestoEndpointDiv>
          <Data.DataFieldWithCopy
            label={display.jdbcLabel}
            value={jdbcConnectionUrl}
            disabled={!isSubscriptionValid}
          />
          {downloadLinks && (
            <DownloadsDiv>
              <Page.TertiaryLink
                to={downloadLinks?.prestoCLI}
                disabled={!downloadLinks}
                download
              >
                {display.buttonPrestoCLI}
              </Page.TertiaryLink>
              <Page.TertiaryLink
                to={downloadLinks?.prestoJDBC}
                disabled={!downloadLinks}
                download
              >
                {display.buttonPrestoJDBC}
              </Page.TertiaryLink>
            </DownloadsDiv>
          )}
        </div>
      </Page.DataContentWithLogo>
    </Page.BoxNavTarget>
  );
};

ConnectionDetails.defaultProps = {
  downloadLinks: null,
};

ConnectionDetails.propTypes = {
  navTarget: string.isRequired,
  prestoClusterUrl: string.isRequired,
  jdbcConnectionUrl: string.isRequired,
  downloadLinks: PrestoClusters.propTypes.DownloadLinks,
};

export default ConnectionDetails;
