import { node } from 'prop-types';
import { PageHeaderWithNavDiv, MainDiv } from './styledComponents';

const PageHeaderWithNav = ({ children }) => {
  return (
    <PageHeaderWithNavDiv>
      <MainDiv>{children}</MainDiv>
    </PageHeaderWithNavDiv>
  );
};

PageHeaderWithNav.propTypes = {
  children: node.isRequired,
};

export default PageHeaderWithNav;
