import { useFormContext } from 'react-hook-form';
import { bool, func, string, shape } from 'prop-types';
import Page from '../../Page';
import Form from '../../Form';
import Help from '../../Help';
import SelectAuthorizationServiceType from './SelectAuthorizationServiceType';
import AuthorizationServiceDetails from './AuthorizationServiceDetails';
import ConnectionDetails from './ConnectionDetails';
import PageHelp from './PageHelp';
import Security from '../../Security';
import RangerHiveSSLDetails from './RangerHiveSSLDetails';
import AuthorizationServices from '../../AuthorizationServices';
import LakeFormationSetup from './LakeFormationSetup';
import { AuthorizationServiceConfigurationDiv } from './styledComponents';

const display = {
  header: 'Add a Presto Authorization Service',
  addButton: 'Add Authorization Service',
  cancelButton: 'Cancel',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  savingAuthorizationService: 'Adding authorization service...',
};

const AddAuthorizationServiceForm = ({
  submitInProgress,
  submitSuccess,
  usePop,
  errors,
}) => {
  const { watch } = useFormContext();

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute: Security.route.getRoute,
    usePop,
  });

  const defaultAuthService =
    AuthorizationServices.constants.authorizationServiceTypes.rangerHive;
  const authorizationServiceType = watch(
    'authorizationServiceType',
    defaultAuthService,
  );

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <SelectAuthorizationServiceType
            disabled={submitInProgress}
            error={errors.type}
            defaultAuthService={defaultAuthService}
          />
          {authorizationServiceType === 'lakeformation' && (
            <LakeFormationSetup />
          )}
          <AuthorizationServiceConfigurationDiv
            className={
              authorizationServiceType === 'lakeformation' ? 'hidden' : ''
            }
          >
            <AuthorizationServiceDetails
              disabled={submitInProgress}
              error={errors.details}
            />
            <ConnectionDetails
              disabled={submitInProgress}
              error={errors.connection}
            />
            <RangerHiveSSLDetails disabled={submitInProgress} />
            <Page.Buttons>
              <Form.SecondaryButton
                onClick={onCancel}
                label={display.cancelButton}
                disabled={submitInProgress}
                disabledOnErrors={false}
              />
              <Form.Submit
                label={
                  !submitInProgress
                    ? display.addButton
                    : display.savingAuthorizationService
                }
                disabled={submitInProgress}
                showLoading={submitInProgress}
              />
            </Page.Buttons>
          </AuthorizationServiceConfigurationDiv>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

AddAuthorizationServiceForm.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  usePop: true,
  errors: {},
};

AddAuthorizationServiceForm.propTypes = {
  submitInProgress: bool,
  submitSuccess: bool,
  usePop: bool,
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
};

const AddAuthorizationService = ({
  create,
  submitInProgress,
  submitSuccess,
  usePop,
  errors,
}) => {
  return (
    <Page.PageWithOverflow>
      <Form.Form name='add-authorization-service' submitAction={create}>
        <AddAuthorizationServiceForm
          submitInProgress={submitInProgress}
          submitSuccess={submitSuccess}
          usePop={usePop}
          errors={errors}
        />
      </Form.Form>
    </Page.PageWithOverflow>
  );
};

AddAuthorizationService.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  usePop: true,
  errors: {},
};

AddAuthorizationService.propTypes = {
  create: func.isRequired,
  submitInProgress: bool,
  submitSuccess: bool,
  usePop: bool,
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
};

export default AddAuthorizationService;
