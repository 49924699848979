import { connect } from '../../utils/redux';
import { UpdatePrestoUser } from './components';
import PrestoUsers from '../PrestoUsers';
import * as selectors from './selectors';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
  return {
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    error: selectors.getError(state),
    prestoUserId: PrestoUsers.selectors.getPrestoUserId(state, props),
    prestoUser: PrestoUsers.selectors.getPrestoUser(state, props),
    prestoClusters: PrestoUsers.selectors.getPrestoClustersForPrestoUser(
      state,
      props,
    ),
    notFound: PrestoUsers.selectors.getIsNotFound(state, props),
    usePop: selectors.getUsePop(state),
    canModify: PrestoUsers.selectors.getCanModify(state, props),
  };
};

export default connect(mapStateToProps, {
  loadPrestoUser: PrestoUsers.actions.get,
  loadPrestoClusters: PrestoUsers.actions.getPrestoClustersForPrestoUser,
  savePrestoUser: actions.submit,
})(UpdatePrestoUser);
