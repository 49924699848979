import { useCallback } from 'react';
import { string, arrayOf } from 'prop-types';
import { onAccessibleClick } from '../utils/accessibility';
import { PageNavigationDiv } from './styledComponents';
import {
  useInPageNavigationState,
  useInPageNavigationDispatch,
} from './InPageNavigation';

const PageNavigation = ({ navTabs }) => {
  const { activeNav } = useInPageNavigationState();
  const dispatch = useInPageNavigationDispatch();
  const navClick = useCallback(
    (event) => {
      return onAccessibleClick(event, (e) => {
        if (e.type === 'click' && e.clientX !== 0 && e.clientY !== 0) {
          e.currentTarget.blur();
        }

        const { navTarget } = e.target.dataset;
        dispatch({ type: 'scrollIntoView', payload: { navTarget } });
      });
    },
    [dispatch],
  );

  return (
    <PageNavigationDiv>
      <div>
        {navTabs.map((navTab) => {
          return (
            <div
              className={`page-nav ${activeNav === navTab ? 'selected' : ''}`}
              data-nav-target={navTab}
              onClick={navClick}
              onKeyDown={navClick}
              role='button'
              tabIndex='0'
              key={navTab}
            >
              {navTab}
            </div>
          );
        })}
      </div>
    </PageNavigationDiv>
  );
};

PageNavigation.propTypes = {
  navTabs: arrayOf(string).isRequired,
};

export default PageNavigation;
