export const status = Object.freeze({
  initializing: 'initializing',
  active: 'active',
  init_failure: 'init_failure',
  destroying: 'destroying',
  destroyed: 'destroyed',
  destroy_failure: 'destroy_failure',
  patching: 'patching',
  upgrading: 'upgrading',
});

export const idpTypes = {
  oidc: 'oidc',
};
