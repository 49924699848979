import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Enable/Disable Spot Instances</Help.HelpHeader>
      <Help.P>
        Choose if spot instances should be utilized for the Presto workers.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
