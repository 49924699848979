import { useEffect } from 'react';
import { string, func, bool } from 'prop-types';
import Page from '../../Page';
import Banner from '../../Banner';
import AccessDetails from './AccessDetails';
import PolicyUpgrade from './PolicyUpgrade';

const display = {
  boxTitle: 'AWS IAM Settings',
  bannerTitle: 'No infrastructure policy attached to provisioning role.',
};

const ViewAccessDetails = ({
  controlPlaneAwsAccountId,
  awsExternalId,
  awsPermissionBoundaryPolicyContent,
  awsCoreInfrastructurePolicyContent,
  awsOperationsPolicyContent,
  awsTrustRelationshipPolicyContent,
  checkProvisioningPolicies,
  computePlaneId,
  shouldUpgradePolicy,
  missingInfrastructurePolicy,
  awsCloudFormationTemplateS3Url,
  cloudFormationStacksListingUrl,
  computePlaneUpdateDate,
  cloudFormationTemplate,
  cloudFormationStackName,
  terraformContent,
  terraformFileName,
  roleCreationMethod,
}) => {
  useEffect(() => {
    checkProvisioningPolicies(computePlaneId);
  }, [checkProvisioningPolicies, computePlaneId]);

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {shouldUpgradePolicy && (
        <PolicyUpgrade
          awsCloudFormationTemplateS3Url={awsCloudFormationTemplateS3Url}
          checkProvisioningPolicies={checkProvisioningPolicies}
          computePlaneId={computePlaneId}
          cloudFormationStacksListingUrl={cloudFormationStacksListingUrl}
          computePlaneUpdateDate={computePlaneUpdateDate}
          cloudFormationTemplate={cloudFormationTemplate}
          cloudFormationStackName={cloudFormationStackName}
          terraformContent={terraformContent}
          terraformFileName={terraformFileName}
          roleCreationMethod={roleCreationMethod}
        />
      )}
      {!shouldUpgradePolicy && missingInfrastructurePolicy && (
        <Banner title={display.bannerTitle} isWarning />
      )}
      <Page.FormContent rowCount={2}>
        <AccessDetails
          controlPlaneAwsAccountId={controlPlaneAwsAccountId}
          awsExternalId={awsExternalId}
          awsPermissionBoundaryPolicyContent={
            awsPermissionBoundaryPolicyContent
          }
          awsCoreInfrastructurePolicyContent={
            awsCoreInfrastructurePolicyContent
          }
          awsOperationsPolicyContent={awsOperationsPolicyContent}
          awsTrustRelationshipPolicyContent={awsTrustRelationshipPolicyContent}
        />
      </Page.FormContent>
    </Page.Box>
  );
};

ViewAccessDetails.defaultProps = {
  awsPermissionBoundaryPolicyContent: null,
  awsCoreInfrastructurePolicyContent: null,
  awsOperationsPolicyContent: null,
  awsTrustRelationshipPolicyContent: null,
  shouldUpgradePolicy: false,
  missingInfrastructurePolicy: false,
};

ViewAccessDetails.propTypes = {
  controlPlaneAwsAccountId: string.isRequired,
  awsExternalId: string.isRequired,
  awsPermissionBoundaryPolicyContent: string,
  awsCoreInfrastructurePolicyContent: string,
  awsOperationsPolicyContent: string,
  awsTrustRelationshipPolicyContent: string,
  checkProvisioningPolicies: func.isRequired,
  computePlaneId: string.isRequired,
  shouldUpgradePolicy: bool,
  missingInfrastructurePolicy: bool,
  awsCloudFormationTemplateS3Url: string.isRequired,
  cloudFormationStacksListingUrl: string.isRequired,
  computePlaneUpdateDate: string.isRequired,
  cloudFormationTemplate: string.isRequired,
  cloudFormationStackName: string.isRequired,
  terraformContent: string.isRequired,
  terraformFileName: string.isRequired,
  roleCreationMethod: string.isRequired,
};

export default ViewAccessDetails;
