export const status = Object.freeze({
  initializing: 'initializing',
  active: 'active',
  init_failure: 'init_failure',
  inactive: 'inactive',
  destroyed: 'destroyed',
  archived: 'archived',
  workflowInProgress: 'workflowInProgress',
});

export const simpleStatus = Object.freeze({
  running: 'running',
  stopped: 'stopped',
  deleted: 'deleted',
  error: 'error',
  pending: 'pending',
});

export const getSimpleStatus = (fullStatus, workflowInProgress) => {
  if (workflowInProgress) {
    return simpleStatus.pending;
  }
  switch (fullStatus) {
    case status.active:
      return simpleStatus.running;
    case status.destroyed:
    case status.archived:
      return simpleStatus.deleted;
    case status.inactive:
      return simpleStatus.stopped;
    case status.init_failure:
      return simpleStatus.error;
    case status.initializing:
      return simpleStatus.pending;
    default:
      return fullStatus;
  }
};
