export const prometheusStatus = Object.freeze({
  modifying: 'modifying',
  active: 'active',
  error: 'error',
});

export const status = Object.freeze({
  creating: 'creating',
  initializing: 'initializing',
  active: 'active',
  init_failure: 'init_failure',
  destroying: 'destroying',
  destroyed: 'destroyed',
  destroy_failure: 'destroy_failure',
  patching: 'patching',
  upgrading: 'upgrading',
  modifying: 'modifying',
});
