/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useCallback } from 'react';
import { bool, func, string, shape, arrayOf } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Page from '../../Page';
import Form from '../../Form';
import useRouteBuilder from '../../../router/useRouteBuilder';
import AuthorizationServices from '../../AuthorizationServices';
import DataSources from '../../DataSources';
import PrestoClusters from '../../PrestoClusters';
import PrestoUsers from '../../PrestoUsers';
import PrestoUsersAdd from '../../PrestoUsersAdd';
import UpdateDataSourceForm from './UpdateDataSourceForm';

const UpdateDataSource = ({
  loadDataSource,
  loadPrestoClusters,
  update,
  getConfiguration,
  usePop,
  submitInProgress,
  submitSuccess,
  notFound,
  errors,
  dataSourceId,
  dataSource,
  awsRegions,
  computePlaneRegion,
  awsGlueS3CloudFormationQuickCreateUrl,
  awsLakeFormationCloudFormationQuickCreateUrl,
  prestoClusters,
  prestoUsers,
  loadingPrestoUsers,
  loadPrestoUsers,
  resetCreatePrestoUser,
  unsupportedLakeFormationPrestoClusters,
  unsupportedRangerHivePrestoClusters,
  getAuthorizationServices,
  authorizationServices,
}) => {
  useEffect(() => {
    loadDataSource(dataSourceId);
    loadPrestoClusters(dataSourceId);
    loadPrestoUsers();
    getAuthorizationServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceId]);

  useEffect(() => {
    if (!awsGlueS3CloudFormationQuickCreateUrl) {
      getConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awsGlueS3CloudFormationQuickCreateUrl]);

  const submitAction = useCallback(
    (data) => {
      update(dataSourceId, dataSource.type, data);
    },
    [update, dataSourceId, dataSource],
  );

  const showCreatePrestoUser =
    PrestoUsersAdd.components.useCreatePrestoUserState();
  useEffect(() => {
    if (!showCreatePrestoUser) {
      resetCreatePrestoUser();
    }
  }, [showCreatePrestoUser, resetCreatePrestoUser]);
  const dispatch = PrestoUsersAdd.components.useCreatePrestoUserDispatch();
  const hideCreatePrestoUser = useCallback(() => {
    return dispatch(false);
  }, [dispatch]);

  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!dataSource || notFound) {
    return <Page.Loading />;
  }

  return (
    <>
      {showCreatePrestoUser && (
        <PrestoUsersAdd.Container hideCreatePrestoUser={hideCreatePrestoUser} />
      )}
      <Page.PageWithOverflow
        style={{ display: showCreatePrestoUser ? 'none' : undefined }}
      >
        <Form.Form name='update-data-source' submitAction={submitAction}>
          <UpdateDataSourceForm
            update={update}
            usePop={usePop}
            submitInProgress={submitInProgress}
            submitSuccess={submitSuccess}
            errors={errors}
            dataSourceId={dataSourceId}
            dataSource={dataSource}
            awsRegions={awsRegions}
            computePlaneRegion={computePlaneRegion}
            awsGlueS3CloudFormationQuickCreateUrl={
              awsGlueS3CloudFormationQuickCreateUrl
            }
            awsLakeFormationCloudFormationQuickCreateUrl={
              awsLakeFormationCloudFormationQuickCreateUrl
            }
            prestoClusters={prestoClusters}
            prestoUsers={prestoUsers}
            loadingPrestoUsers={loadingPrestoUsers}
            unsupportedLakeFormationPrestoClusters={
              unsupportedLakeFormationPrestoClusters
            }
            unsupportedRangerHivePrestoClusters={
              unsupportedRangerHivePrestoClusters
            }
            authorizationServices={authorizationServices}
          />
        </Form.Form>
      </Page.PageWithOverflow>
    </>
  );
};

UpdateDataSource.defaultProps = {
  usePop: true,
  submitInProgress: false,
  submitSuccess: false,
  notFound: false,
  errors: {},
  awsGlueS3CloudFormationQuickCreateUrl: null,
  awsLakeFormationCloudFormationQuickCreateUrl: null,
  dataSource: null,
  prestoClusters: [],
  prestoUsers: null,
  unsupportedLakeFormationPrestoClusters: false,
  unsupportedRangerHivePrestoClusters: false,
  authorizationServices: null,
};

UpdateDataSource.propTypes = {
  loadDataSource: func.isRequired,
  loadPrestoClusters: func.isRequired,
  update: func.isRequired,
  getConfiguration: func.isRequired,
  usePop: bool,
  submitInProgress: bool,
  submitSuccess: bool,
  notFound: bool,
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
  dataSourceId: string.isRequired,
  dataSource: DataSources.propTypes.DataSource,
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  computePlaneRegion: string.isRequired,
  awsGlueS3CloudFormationQuickCreateUrl: string,
  awsLakeFormationCloudFormationQuickCreateUrl: string,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadingPrestoUsers: bool.isRequired,
  loadPrestoUsers: func.isRequired,
  resetCreatePrestoUser: func.isRequired,
  unsupportedLakeFormationPrestoClusters: bool,
  unsupportedRangerHivePrestoClusters: bool,
  getAuthorizationServices: func.isRequired,
  authorizationServices: arrayOf(
    AuthorizationServices.propTypes.AuthorizationService,
  ),
};

const { CreatePrestoUserNavigation } = PrestoUsersAdd.components;
const UpdateDataSourceWrapper = (props) => {
  return (
    <CreatePrestoUserNavigation>
      <UpdateDataSource {...props} />
    </CreatePrestoUserNavigation>
  );
};

UpdateDataSourceWrapper.defaultProps = {
  usePop: true,
  submitInProgress: false,
  submitSuccess: false,
  notFound: false,
  errors: {},
  awsGlueS3CloudFormationQuickCreateUrl: null,
  awsLakeFormationCloudFormationQuickCreateUrl: null,
  dataSource: null,
  prestoClusters: [],
  prestoUsers: null,
  unsupportedPrestoClusters: false,
  authorizationServices: null,
};

UpdateDataSourceWrapper.propTypes = {
  loadDataSource: func.isRequired,
  loadPrestoClusters: func.isRequired,
  update: func.isRequired,
  getConfiguration: func.isRequired,
  usePop: bool,
  submitInProgress: bool,
  submitSuccess: bool,
  notFound: bool,
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
  dataSourceId: string.isRequired,
  dataSource: DataSources.propTypes.DataSource,
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  computePlaneRegion: string.isRequired,
  awsGlueS3CloudFormationQuickCreateUrl: string,
  awsLakeFormationCloudFormationQuickCreateUrl: string,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadingPrestoUsers: bool.isRequired,
  loadPrestoUsers: func.isRequired,
  unsupportedPrestoClusters: bool,
  getAuthorizationServices: func.isRequired,
  authorizationServices: arrayOf(
    AuthorizationServices.propTypes.AuthorizationService,
  ),
};

export default UpdateDataSourceWrapper;
