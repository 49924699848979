import { useCallback, useMemo } from 'react';
import { func, shape, string, bool } from 'prop-types';
import Page from '../../../Page';
import Form from '../../../Form';
import Banner from '../../../Banner';
import * as prestoClusterRoutes from '../../../PrestoClusters/routes';
import SuccessFilled from '../../../../icons/SuccessFilled';
import { SuccessDiv } from '../styledComponents';
import { useFormWizard } from '../../../FormWizard/useFormWizard';
import { stepIds, status } from '../../constants';

const display = {
  titleSuccess: 'Steps 8 of 8 - Success',
  titleError: 'Steps 8 of 8 - Fix Some Problems',
  createCluster: 'Create Presto Cluster',
  errorBannerHeader: 'There was an issue setting up your account',
};

const SuccessButtons = () => {
  return (
    <Page.Buttons>
      <Page.PrimaryLink to={prestoClusterRoutes.getRoute()}>
        {display.createCluster}
      </Page.PrimaryLink>
    </Page.Buttons>
  );
};

export const CreateFinished = ({
  onRetry,
  computePlane,
  canCreateCluster,
  submitInProgress,
  destroyInProgress,
  destroyComputePlane,
}) => {
  const formWizard = useFormWizard();
  const createSuccessful = useMemo(() => {
    return computePlane.status === status.active;
  }, [computePlane.status]);

  // We take you back to step 1 not step 7 since we don't know what the problems are.
  const handleBackClick = useCallback(() => {
    formWizard.onSetActiveStep(stepIds.step1);
  }, [formWizard]);

  const handleDestroy = useCallback(() => {
    destroyComputePlane(computePlane.computePlaneId);
  }, [destroyComputePlane, computePlane]);

  return (
    <>
      <Page.Box>
        <Page.BoxHeader>
          <h2>
            {createSuccessful ? display.titleSuccess : display.titleError}
          </h2>
        </Page.BoxHeader>
        {createSuccessful && (
          <SuccessDiv>
            <SuccessFilled />
            <div>Setup complete</div>
          </SuccessDiv>
        )}
        {!createSuccessful && (
          <Banner scrollIntoView title={display.errorBannerHeader}>
            <div>
              {computePlane.errorMessages?.map((m) => {
                if (typeof m === 'string') {
                  return <p key={m}>{m}</p>;
                }
                return <p key={m}>{m.errorSummary || m.errorMessage}</p>;
              })}
            </div>
          </Banner>
        )}
      </Page.Box>
      {createSuccessful && (
        <SuccessButtons canCreateCluster={canCreateCluster} />
      )}
      {!createSuccessful && (
        <Page.Buttons>
          <Form.ErrorButton
            label='Destroy Compute Plane'
            onClick={handleDestroy}
            disabled={submitInProgress}
            showLoading={destroyInProgress}
            disabledOnErrors
          />
          <Form.SecondaryButton
            label='Back'
            onClick={handleBackClick}
            disabled={submitInProgress}
            disabledOnErrors
          />
          <Form.PrimaryButton
            label='Retry'
            disabled={submitInProgress}
            showLoading={submitInProgress && !destroyInProgress}
            disabledOnErrors
            onClick={onRetry}
          />
        </Page.Buttons>
      )}
    </>
  );
};

CreateFinished.defaultProps = {
  computePlane: null,
  submitInProgress: false,
  destroyInProgress: false,
};

CreateFinished.propTypes = {
  canCreateCluster: bool.isRequired,
  computePlane: shape({
    computePlaneId: string,
    status: string,
  }),
  submitInProgress: bool,
  destroyInProgress: bool,
  onRetry: func.isRequired,
  destroyComputePlane: func.isRequired,
};
