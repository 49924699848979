import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';

export const put = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `authorizationservices/${params.authorizationServiceId}`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};
