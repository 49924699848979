import { arrayOf, shape, func, string } from 'prop-types';

export const column = shape({
  headerRender: func,
  cellRender: func,
  selector: func.isRequired,
  name: string.isRequired,
});

export const columns = arrayOf(column);
