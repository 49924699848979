import { any, arrayOf, shape, string } from 'prop-types';
import noop from '../../../utils/noop';
import Page from '../../Page';
import Table from '../../Table';
import * as propTypes from '../propTypes';
import { getManageAuthorizationServiceRoute } from '../routes';
import HiveRangerLogo from '../../../img/logo/hive-ranger.svg';

const display = {
  serviceColumnLabel: 'Service',
  nameColumnLabel: 'Name',
  urlColumnLabel: 'URL',
  manageButtonLabel: 'View',
  plugInHiveTagValue: 'PlugIn:Hive',
};

const ManageCell = ({ data, key }) => {
  return (
    <Table.ButtonCell key={key}>
      <Page.TertiaryLink to={data}>
        {display.manageButtonLabel}
      </Page.TertiaryLink>
    </Table.ButtonCell>
  );
};

ManageCell.propTypes = {
  data: string.isRequired,
  key: string.isRequired,
};

const LogoCell = ({ data, key }) => {
  return (
    <Table.ImageCell key={key}>
      <img src={data.Logo} alt={data.alt} style={{ display: 'block' }} />
    </Table.ImageCell>
  );
};

LogoCell.propTypes = {
  data: shape({
    // eslint-disable-next-line react/forbid-prop-types
    Logo: any,
    alt: string.isRequired,
  }).isRequired,
  key: string.isRequired,
};

const dataKeyGenerator = (authorizationService) => {
  return authorizationService.authorizationServiceId;
};

const columns = [
  {
    name: display.serviceColumnLabel,
    selector: () => {
      return {
        Logo: HiveRangerLogo,
        alt: 'Apache Ranger',
        title: 'Apache Ranger',
      };
    },
    headerRender: noop,
    cellRender: LogoCell,
    cellWidth: '1fr',
  },
  {
    name: display.nameColumnLabel,
    selector: (authorizationService) => {
      return authorizationService.name;
    },
    cellWidth: '2fr',
  },
  {
    name: display.urlColumnLabel,
    selector: (authorizationService) => {
      return authorizationService.connection.hive.ranger['rest-endpoint'];
    },
    cellWidth: '2fr',
  },
  {
    name: 'Manage',
    selector: (authorizationService) => {
      return getManageAuthorizationServiceRoute(
        authorizationService.authorizationServiceId,
      );
    },
    headerRender: noop,
    cellRender: ManageCell,
    cellWidth: '2fr',
  },
];

const AuthorizationServicesTable = ({ authorizationServices }) => {
  return (
    <Table.Table
      data={authorizationServices}
      columns={columns}
      dataKeyGenerator={dataKeyGenerator}
    />
  );
};

AuthorizationServicesTable.propTypes = {
  authorizationServices: arrayOf(propTypes.AuthorizationService).isRequired,
};

export default AuthorizationServicesTable;
