import { string } from 'prop-types';
import Loading from '../../Loading';
import Page from '../../Page';

const LoadingPage = ({ header }) => {
  return (
    <Page.Page>
      <Page.PageHeader>
        <h1>{header}</h1>
      </Page.PageHeader>
      <Page.PageEmpty>
        <Loading.Loading />
      </Page.PageEmpty>
    </Page.Page>
  );
};

export default LoadingPage;

LoadingPage.defaultProps = {
  header: null,
};

LoadingPage.propTypes = {
  header: string,
};
