import * as actionTypes from './actionTypes';

export const setActiveStep = (activeStepId) => {
  return {
    type: actionTypes.setActiveStep,
    payload: { activeStepId },
  };
};

export const initialize = (steps, initialStep) => {
  return {
    type: actionTypes.initialize,
    payload: { steps, initialStep },
  };
};
