import { connect } from '../../utils/redux';
import { Buckets } from './components';
import * as actions from './actions';
import * as selectors from './selectors';

const mapStateToProps = (state) => {
  return {
    buckets: selectors.getBuckets(state),
    loadingInProgress: selectors.getLoadingInProgress(state),
    errorMessages: selectors.getErrorMessages(state),
  };
};

export default connect(mapStateToProps, {
  getBuckets: actions.getAll,
  deleteBucket: actions.remove,
})(Buckets);
