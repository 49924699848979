import * as authorizationServices from '../components/AuthorizationServices/routes';
import * as computePlanes from '../components/ComputePlane/routes';
import * as costManagement from '../components/CostManagement/routes';
import * as dataSources from '../components/DataSources/routes';
import * as errors from '../components/ErrorPage/routes';
import * as home from '../components/HomePage/route';
import * as iamPolicy from '../components/IAMPolicy/routes';
import * as prestoClusters from '../components/PrestoClusters/routes';
import * as prestoUsers from '../components/PrestoUsers/routes';
import * as prestoUsersAdd from '../components/PrestoUsersAdd/route';
import * as security from '../components/Security/route';
import * as signIn from '../components/SignIn/route';
import * as StandalonePrometheus from '../components/StandalonePrometheus/routes';

const module = {
  authorizationServices,
  computePlanes,
  costManagement,
  dataSources,
  errors,
  home,
  iamPolicy,
  prestoClusters,
  prestoUsers,
  prestoUsersAdd,
  security,
  signIn,
  StandalonePrometheus,
};

export default module;
