import Dexie from 'dexie';
import { schema as dismissibleSchema } from '../components/Dismissible/schema';

Dexie.debug = true;

const db = new Dexie('AhanaCloud');
db.version(1).stores({
  ...dismissibleSchema,
});

export default db;
