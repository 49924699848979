import actionSanitizer from './actionSanitizer';

const currentRouteChange = 'CURRENT_ROUTE_CHANGE';
const formwizardSetAvtiveStep = 'FORM_WIZARD/SET_ACTIVE_STEP';
const getAuthPending = 'GET_AUTH_PENDING';
const initSearchParams = 'INIT_SEARCH_PARAMS';

const telemeteryMiddleware = () => {
  return () => {
    return (next) => {
      return (action) => {
        try {
          return next(action);
        } finally {
          const sanitizedAction = actionSanitizer.sanitizeAction(action);
          if (sanitizedAction) {
            switch (sanitizedAction.type) {
              case currentRouteChange:
                sendEvent(currentRouteChange);
                if (sanitizedAction?.payload?.location?.pathname !== undefined)
                  ineum('page', sanitizedAction.payload.location.pathname);
                break;
              case formwizardSetAvtiveStep:
                if (sanitizedAction?.payload?.activeStepId !== undefined)
                  ineum(
                    'page',
                    `${window.location.pathname}/${sanitizedAction.payload.activeStepId}`,
                  );
                sendEvent(formwizardSetAvtiveStep);
                break;
              case getAuthPending:
                ineum('page', 'auth');
                sendEvent(getAuthPending);
                break;
              case initSearchParams:
                ineum('page', 'loading');
                sendEvent(initSearchParams);
                break;
              default:
                sendEvent(sanitizedAction.type);
                break;
            }
          }
        }
      };
    };
  };
};

const sendEvent = (type) => {
  ineum('reportEvent', 'redux-action', {
    meta: {
      'redux-action': {
        level: 'info',
        message: type,
      },
    },
  });
};

export default telemeteryMiddleware;
