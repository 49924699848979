import styled from 'styled-components/macro';
import { fonts, colors } from '../../css';

const helpSectionPaddingTop = 70;
export const HelpSection = styled.div`
  padding-left: 60px;
  padding-top: ${helpSectionPaddingTop}px;
`;

export const HelpHeader = styled.div`
  ${fonts.large_bold}
  letter-spacing: 0.2px;
  color: ${colors.font_light};
  padding-bottom: 32px;
`;

export const OrderedList = styled.div`
  color: ${colors.font_light};
  padding-left: 16px;
`;

export const ListItem = styled.p`
  ${fonts.medium}
  color: ${colors.font_light};
  letter-spacing: 0.16px;
  padding-bottom: 32px;
  padding-left: 32px;
  margin: 0;
  border-left: solid 1px ${colors.help_bullet_border};
  counter-increment: section;
  position: relative;

  &:last-child {
    border-left: 0;
  }

  &:before {
    content: counter(section);
    right: 100%;
    margin-right: -16px;
    position: absolute;
    border-radius: 50%;
    border: solid 1px ${colors.help_bullet_border};
    color: ${colors.font_light};
    ${fonts.medium_bold}
    line-height: 32px;
    height: 32px;
    width: 32px;
    background-color: ${colors.help_bullet_fill};
    text-align: center;
  }
`;

export const P = styled.p`
  ${fonts.medium}
  color: ${colors.font_light};
  letter-spacing: 0.16px;
  margin: 0;
  padding: 0;
  padding-bottom: 32px;
`;

export const Strong = styled.span`
  ${fonts.medium_bold}
`;

export const HelpColumnDiv = styled.div`
  display: block;
`;

export const StickyHelpColumnDiv = styled.div`
  position: sticky;
  top: 0;
  overflow: auto;
  max-height: calc(100vh - var(--header-height));
  display: block;

  > button.close {
    flex-grow: 0;
    border: 1px solid transparent;
    padding: 0;
    height: 20px;
    padding: 2px;
    margin-top: 10px;
    margin-left: 10px;
    background-color: ${colors.page_background};
    cursor: pointer;

    &:focus,
    &:active,
    &:hover {
      border-color: ${colors.focus_dark};
      text-decoration: underline;
      outline: none;
    }

    svg {
      height: 15px;
      fill: ${colors.white};
    }
  }

  ${HelpSection} {
    padding-top: ${helpSectionPaddingTop - 30}px;
  }
`;

export const Button = styled.button`
  flex-grow: 0;
  border: 1px solid transparent;
  padding: 0;
  border-radius: 50%;
  height: 30px;
  padding: 2px;
  background-color: ${colors.section_background};
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    border-color: ${colors.focus_dark};
    text-decoration: underline;
    outline: none;
  }

  svg {
    height: 24px;
    fill: ${colors.white};
  }

  &.error {
    svg {
      fill: ${colors.error};
    }
  }

  &.warning {
    svg {
      fill: ${colors.warning};
    }
  }
`;

export const MultiColumnWrapperDiv = styled.div`
  --row-count: 1;
  display: grid;
  grid-template-columns: auto minmax(373px, calc(50% + 20px));
  grid-template-rows: repeat(var(--row-count), auto);
  grid-column-gap: 24px;
  padding: 0px;
`;

export const MultiStepButtonGroupDiv = styled.div`
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;

  > div.link-wrapper {
    display: flex;
  }
`;

export const ColumnTitleDiv = styled.div`
  ${fonts.medium_semibold}
  color: ${colors.font_light};
  letter-spacing: 0.16px;
  margin-bottom: 18px;
`;

export const MultiColumnDescriptionDiv = styled.div`
  margin: 20px 0 30px 0;
`;
