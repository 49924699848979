import { connect } from '../../utils/redux';
import { IAMPolicyContentPage } from './components';
import * as selectors from './selectors';

const mapStateToProps = (state, props) => {
  return {
    usePop: selectors.getUsePop(state),
    policyContent: selectors.getPolicyContent(state, props),
    policyTitle: selectors.getPolicyTitle(state, props),
  };
};

export default connect(mapStateToProps)(IAMPolicyContentPage);
