import { string, bool } from 'prop-types';
import OIDCLogo from '../../../img/logo/oidc.svg';
import Page from '../../Page';
import Banner from '../../Banner';
import Form from '../../Form';
import IdentityProviders from '../../IdentityProviders';
import { IDPTypeOptionDiv, IDPTypeOptionsDiv } from './styledComponent';

const display = {
  boxHeader: 'Identity Providers',
  oidc: 'OIDC',
  required: 'Please select an Identity Provider Type',
};

const idpType = (label, Logo) => {
  return () => {
    return (
      <IDPTypeOptionDiv>
        <img src={Logo} alt={label} />
        <div>{label}</div>
      </IDPTypeOptionDiv>
    );
  };
};

const IdpTypeValues = [
  {
    value: IdentityProviders.constants.idpTypes.oidc,
    label: display.oidc,
    component: idpType(display.oidc, OIDCLogo),
  },
];

const SelectIDPType = ({ error, disabled }) => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxHeader}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <IDPTypeOptionsDiv>
        <Form.RadioInputs
          name='providerType'
          values={IdpTypeValues}
          disabled={disabled}
          defaultValue={IdentityProviders.constants.idpTypes.oidc}
          validationRules={{ required: display.required }}
        />
      </IDPTypeOptionsDiv>
    </Page.Box>
  );
};

SelectIDPType.defaultProps = {
  error: undefined,
  disabled: false,
};

SelectIDPType.propTypes = {
  error: string,
  disabled: bool,
};

export default SelectIDPType;
