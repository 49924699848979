import { createContext, useContext } from 'react';

const AlertIdStateContext = createContext();
const AlertIdDispatchContext = createContext();

const useAlertIdStateContext = () => {
  const context = useContext(AlertIdStateContext);
  if (context === undefined) {
    throw new Error('useAlertIdState must be used within Table');
  }
  return context;
};

const useAlertIdDispatchContext = () => {
  const context = useContext(AlertIdDispatchContext);
  if (context === undefined) {
    throw new Error('useAlertIdDispatch must be used within Table');
  }
  return context;
};

export {
  AlertIdDispatchContext,
  AlertIdStateContext,
  useAlertIdStateContext,
  useAlertIdDispatchContext,
};
