import Help from '../../Help';

const EnableSpot = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Enable Spot</Help.HelpHeader>
      <Help.P>
        If enabled, the Presto worker nodes will be provisioned with 40%
        on-demand instances and 60% spot instances.
      </Help.P>

      <Help.P>
        This will decrease compute cost but might increase the risk of query
        failure.
      </Help.P>

      <Help.P>
        If spot instances are being reclaimed by AWS, the coordinator will
        remove the Spot worker node and retry provisioning new instances.
      </Help.P>
    </Help.HelpSection>
  );
};

const EnableCapacityRebalance = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Capacity Rebalance</Help.HelpHeader>
      <Help.P>
        If your cluster is using spot instances and you are experiencing churn
        of the workers, disabling capacity rebalancing may reduce churn.
      </Help.P>
      <Help.P>
        The downside to disabling capacity rebalancing is that when a spot
        instance is terminated due to ec2 demand, a replacement instance request
        will not begin until the original instance is terminated. With capacity
        rebalancing enabled, a replacement instance is requested immediately
        when notification of rebalance recommendation or spot instance
        termination is received.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  EnableSpot,
  EnableCapacityRebalance,
};

export default module;
