import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool } from 'prop-types';
import { required } from '../utils';
import AuthorizationServices from '../../AuthorizationServices';
import Help from './RangerHiveConnectionDetailsHelp';
import Form from '../../Form';
import {
  rangerHiveHostnameEndpointRegex,
  rangerHiveIPAddressEndpointRegex,
} from '../../../utils/regex';

const display = {
  endpointLabel: 'Ranger REST Endpoint URL',
  endpointRequiredError: 'Endpoint is required',
  endpointPatternError:
    'Endpoint must be in the format: http(s)://[hostname]:[port]',
  servicenameLabel: 'Hive Plugin Service Name',
  servicenameRequiredError: 'Service name is required',
  usernameLabel: 'Ranger Admin Username',
  usernameRequiredError: 'Username is required',
  passwordLabel: 'Ranger Admin Password',
  passwordRequiredError: 'Password is required',
  formContentHeader: 'Connection Configuration',
  auditConfigFileLabel: 'Ranger Audit Config File',
};

const type =
  AuthorizationServices.constants.authorizationServiceTypes.rangerHive;

export const endpointValidate = (errorMessage) => {
  return (value) => {
    if (value === undefined || value === null) return true;

    return (
      rangerHiveHostnameEndpointRegex.test(value) ||
      rangerHiveIPAddressEndpointRegex.test(value) ||
      errorMessage
    );
  };
};

const RangerHiveConnectionDetails = ({
  disabled,
  active,
  authorizationService,
}) => {
  const { getValues, clearErrors } = useFormContext();

  useEffect(() => {
    if (!active) {
      clearErrors(['rangerhive']);
    }
  }, [active, clearErrors]);

  return (
    <>
      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.formContentHeader}</h3>
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.TextInput
          name='rangerhive.endpoint'
          label={display.endpointLabel}
          disabled={disabled}
          defaultValue={
            authorizationService &&
            authorizationService.connection.hive.ranger['rest-endpoint']
          }
          validationRules={{
            validate: required(
              getValues,
              type,
              display.endpointRequiredError,
              endpointValidate(display.endpointPatternError),
            ),
          }}
          fieldHelp={Help.Endpoint}
        />
        <Form.TextInput
          name='rangerhive.servicename'
          label={display.servicenameLabel}
          disabled={disabled}
          defaultValue={
            authorizationService &&
            authorizationService.connection.hive.ranger.policy[
              'hive-servicename'
            ]
          }
          validationRules={{
            required: display.servicenameRequiredError,
          }}
          fieldHelp={Help.Servicename}
        />
        <Form.TextInput
          name='rangerhive.username'
          label={display.usernameLabel}
          disabled={disabled}
          defaultValue={
            authorizationService &&
            authorizationService.connection.hive.ranger.service[
              'basic-auth-username'
            ]
          }
          validationRules={{
            required: display.usernameRequiredError,
          }}
          fieldHelp={Help.Username}
        />
        <Form.PasswordInput
          name='rangerhive.password'
          label={display.passwordLabel}
          disabled={disabled}
          validationRules={{
            required: display.passwordRequiredError,
          }}
          fieldHelp={Help.Password}
        />
        <Form.FileInput
          name='rangerhive.auditconfig'
          label={display.auditConfigFileLabel}
          disabled={disabled}
          optional
          accept='.xml'
          fieldHelp={Help.AuditConfigFile}
        />
      </div>
    </>
  );
};

RangerHiveConnectionDetails.defaultProps = {
  disabled: false,
  authorizationService: null,
};

RangerHiveConnectionDetails.propTypes = {
  disabled: bool,
  active: bool.isRequired,
  authorizationService: AuthorizationServices.propTypes.AuthorizationService,
};

export default RangerHiveConnectionDetails;
