import { useCallback, useContext, useMemo, useEffect } from 'react';
import { bool, string, shape, number } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import PrestoClusters from '../../PrestoClusters';
import { ActionInProgressStateContext } from '../../utils/ActionInProgressContext';
import PrestoClusterInfo from './PrestoClusterInfo';
import PageHelp from './PageHelp';
import PendingStatus from './PendingStatus';
import PrestoDebuggingHelp from './PrestoDebuggingHelp';

const display = {
  header: 'Trigger Heap Dump Generation and Transfer',
  cancelButton: 'Cancel',
  submitButton: 'Submit',
  confirmCancel: 'Are you sure?',
  k8sNodesLabel: 'Kubernetes node names',
  k8sHeader: 'Kubernetes',
  forceGenerationLabel: 'Force Heap Dump Generation',
  forcedValue: 'yes',
  unforcedValue: 'no',
  forceLabel: 'Yes',
  unforceLabel: 'No',
  heapDumpGenerationRequiredError: 'required',
  k8sNodeNamesRequiredError: 'Node names are required.',
};

const PrestoDebuggingForm = ({
  prestoClusterId,
  prestoCluster,
  actionStatus,
  usePop,
  submitSuccess,
}) => {
  const actionInProgress = useContext(ActionInProgressStateContext);
  const { watch, clearErrors, register, unregister } = useFormContext();
  const forceGenerate = watch('forceHeapDumpGeneration', display.forcedValue);

  useEffect(() => {
    if (forceGenerate === display.forcedValue) {
      register('k8s.nodeNames', {
        required: display.k8sNodeNamesRequiredError,
      });
    } else {
      unregister('k8s.nodeNames');
      clearErrors('k8s.nodeNames');
    }
  }, [forceGenerate, register, unregister, clearErrors]);

  const submitInProgress = useMemo(() => {
    return (
      actionStatus &&
      actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    );
  }, [actionStatus, actionInProgress]);

  const getRoute = useCallback(() => {
    return PrestoClusters.routes.getManagePrestoClusterRoute(prestoClusterId);
  }, [prestoClusterId]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  const isNotActive =
    prestoCluster?.status !== PrestoClusters.constants.status.active ||
    prestoCluster?.workflowInProgress;

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    !actionStatus.inProgress &&
    actionStatus.name === 'triggerPrestoDebugging';

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <>
            {isNotActive && (
              <Banner scrollIntoView>
                <PendingStatus
                  name={prestoCluster.name}
                  status={prestoCluster.status}
                />
              </Banner>
            )}
            {showActionStatusError && (
              <Banner scrollIntoView title={actionStatus.error} />
            )}
            <PrestoClusterInfo name={prestoCluster.name} />
            <Page.Box>
              <Page.BoxHeader>
                <h2>{display.header}</h2>
              </Page.BoxHeader>
              <Page.FormContent>
                <div>
                  <h3>{display.k8sHeader}</h3>
                </div>
                <div>
                  <Form.RadioInputs
                    name='forceHeapDumpGeneration'
                    label={display.forceGenerationLabel}
                    disabled={submitInProgress}
                    defaultValue={display.forcedValue}
                    validationRules={{
                      required: display.heapDumpGenerationRequiredError, // Ensure this message is defined in the display object
                    }}
                    fieldHelp={PrestoDebuggingHelp.forceGenerate}
                    values={[
                      {
                        label: display.forceLabel,
                        value: display.forcedValue,
                      },
                      {
                        label: display.unforceLabel,
                        value: display.unforcedValue,
                      },
                    ]}
                  />
                  <Form.TextInput
                    name='k8s.nodeNames'
                    type='string'
                    label={display.k8sNodesLabel}
                    optional={forceGenerate !== display.forcedValue}
                    disabled={submitInProgress}
                  />
                </div>
              </Page.FormContent>
            </Page.Box>
            <Page.Buttons>
              <Form.SecondaryButton
                onClick={onCancel}
                label={display.cancelButton}
                disabled={submitInProgress}
                disabledOnErrors={false}
              />
              <Form.Submit
                label={display.submitButton}
                disabled={submitInProgress || isNotActive}
                showLoading={submitInProgress}
              />
            </Page.Buttons>
          </>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

PrestoDebuggingForm.defaultProps = {
  prestoCluster: null,
  actionStatus: undefined,
  submitSuccess: false,
  usePop: false,
};

PrestoDebuggingForm.propTypes = {
  prestoClusterId: string.isRequired,
  prestoCluster: PrestoClusters.propTypes.PrestoCluster,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  submitSuccess: bool,
  usePop: bool,
};

export default PrestoDebuggingForm;
