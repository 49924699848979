import { createSelector } from 'reselect';

export const getLoadingInProgress = (state) => {
  return state.buckets.loadingInProgress;
};

const bucketsSelector = (state) => {
  return state.buckets.buckets;
};

const removeNotFound = (bucket) => {
  if (bucket === 'not found') {
    return null;
  }
  return bucket;
};

export const getBuckets = createSelector(bucketsSelector, (buckets) => {
  if (!buckets) return null;
  // eslint-disable-next-line prefer-const
  let bucketsArr = Array.from(buckets.values(), removeNotFound);
  bucketsArr = bucketsArr.filter((b) => {
    return b !== null;
  });

  bucketsArr = bucketsArr.map((b) => {
    return {
      ...b,
      type: b.type === 'query log' ? 'Query Log' : 'Cluster Data',
      awsUrl: `https://s3.console.aws.amazon.com/s3/buckets/${b.name}?region=${b.region}&tab=objects`,
    };
  });

  // Sort buckets by cluster name, presto cluster id and bucket name
  return bucketsArr.sort((a, b) => {
    const clusterNameComparison = a.prestoClusterName.localeCompare(
      b.prestoClusterName,
    );
    if (clusterNameComparison) return clusterNameComparison;

    const clusterIdComparison = a.prestoClusterId.localeCompare(
      b.prestoClusterId,
    );
    if (clusterIdComparison) return clusterIdComparison;

    return a.name.localeCompare(b.name);
  });
});

const getError = (state) => {
  return state.buckets.error;
};

const getErrorMessageBody = (errorMessage) => {
  return `${errorMessage}${
    errorMessage.slice(-1) === '.' ? '' : '.'
  } Please try again.`;
};

const getErrorMessageTitle = (bucketName) => {
  return `There was an issue when deleting bucket "${bucketName}"`;
};

// This selector concatenates workflow errors and any API error in a single array
export const getErrorMessages = createSelector(
  getBuckets,
  getError,
  (buckets, apiError) => {
    let allErrors = [];
    if (buckets) {
      const workflowErrors = buckets
        .filter((b) => {
          return b.errorMessages && b.errorMessages.length > 0;
        })
        .map((bucket) => {
          const errorMessage =
            bucket.errorMessages[0].errorSummary ||
            bucket.errorMessages[0].errorMessage;
          return {
            key: `${bucket.bucketId}${errorMessage}${bucket.updateDate}`,
            data: getErrorMessageBody(errorMessage),
            title: getErrorMessageTitle(bucket.name),
          };
        });

      allErrors = [...workflowErrors];
    }

    if (apiError && buckets) {
      const bucket = buckets.find((b) => {
        return b.bucketId === apiError.bucketId;
      });

      if (bucket) {
        allErrors.unshift({
          key: `${bucket.bucketId}${apiError.message}${Date.now()}`,
          data: getErrorMessageBody(apiError.message),
          title: getErrorMessageTitle(bucket.name),
        });
      }
    }

    return allErrors;
  },
);
