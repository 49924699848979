import { string, bool, elementType, node } from 'prop-types';
import Label from './Label';
import { DataDiv, DataDivDiv, SecondLabelDiv } from './styledComponents';

const DataField = ({
  label,
  secondLabel,
  value,
  disabled,
  isPrivate,
  fieldHelp,
  children,
}) => {
  return (
    <>
      {label && (
        <Label label={label} fieldHelp={fieldHelp} disabled={disabled} />
      )}
      {secondLabel && (
        <DataDivDiv className='secondLabel'>
          <DataDiv
            className={disabled ? 'disabled' : undefined}
            data-private={isPrivate ? true : undefined}
          >
            {value}
          </DataDiv>
          <SecondLabelDiv className={disabled ? 'disabled' : undefined}>
            {secondLabel}
          </SecondLabelDiv>
        </DataDivDiv>
      )}
      {!secondLabel && (
        <DataDiv
          className={disabled ? 'disabled' : undefined}
          data-private={isPrivate ? true : undefined}
        >
          {children || value}
        </DataDiv>
      )}
    </>
  );
};

DataField.defaultProps = {
  label: undefined,
  secondLabel: undefined,
  value: null,
  disabled: false,
  isPrivate: false,
  fieldHelp: undefined,
  children: null,
};

DataField.propTypes = {
  label: string,
  secondLabel: string,
  value: string,
  disabled: bool,
  isPrivate: bool,
  fieldHelp: elementType,
  children: node,
};

export default DataField;
