import { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { string, func, node, bool } from 'prop-types';
import { Form as FormCss } from './styledComponents';

const Form = ({ name, submitAction, horizontal, children }) => {
  const methods = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = useCallback(
    (data) => {
      submitAction(data);
    },
    [submitAction],
  );

  const getFieldId = useCallback(
    (fieldName) => {
      return `${name}-${fieldName}`;
    },
    [name],
  );
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider getFieldId={getFieldId} {...methods}>
      <FormCss
        className={horizontal ? 'horizontal' : undefined}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {children}
      </FormCss>
    </FormProvider>
  );
};

Form.defaultProps = {
  horizontal: false,
};

Form.propTypes = {
  name: string.isRequired,
  submitAction: func.isRequired,
  horizontal: bool,
  children: node.isRequired,
};

export default Form;
