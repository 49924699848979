import { connect } from '../../utils/redux';
import ComputePlane from '../ComputePlane';
import * as prestoClusterSelectors from '../PrestoClusters/selectors';
import * as selectors from './selectors';
import * as actions from './actions';
import { CreateComputePlane } from './components';

const mapStateToProps = (state) => {
  return {
    isComputePlaneActive: ComputePlane.selectors.isComputePlaneActive(state),
    awsExternalId: ComputePlane.selectors.getAwsExternalId(state),
    awsRegions: ComputePlane.selectors.getAwsRegions(state),
    error: ComputePlane.selectors.getError(state),
    submitInProgress: selectors.getSubmitInProgress(state),
    destroyInProgress: selectors.getDestroyInProgress(state),
    computePlane: ComputePlane.selectors.getComputePlane(state),
    cfStackName: ComputePlane.selectors.getProvisioningRoleCFStackName(state),
    awsAccountId: ComputePlane.selectors.getAwsAccountIdFromRoleArn(state),
    canCreateCluster: prestoClusterSelectors.canCreateCluster(state),
    computePlaneConfiguration: ComputePlane.selectors.getConfiguration(state),
    hasVerifyRoleSuccess: selectors.getHasVerifyRoleSuccess(state),
    verifyRoleInProgress: selectors.getVerifyRoleInProgress(state),
    verifyRoleError: selectors.getVerifyRoleError(state),
    awsCloudFormationUrl:
      ComputePlane.selectors.getCloudFormationStacksListingUrl(state),
    terraformContent: ComputePlane.selectors.getTerraformContent(state),
    terraformFileName:
      ComputePlane.selectors.getProvisioningRoleTerraformFileName(state),
    awsAZsIDsByRegion: ComputePlane.selectors.getAwsAZsIDsByRegion(state),
    awsSubnets: ComputePlane.selectors.getAwsSubnets(state),
  };
};

export default connect(mapStateToProps, {
  deploy: actions.requestDeploy,
  updateComputePlane: actions.updateComputePlane,
  verifyProvisioningRole: ComputePlane.actions.verifyProvisioningRole,
  destroyComputePlane: actions.requestDestroy,
  getRegionInfo: ComputePlane.actions.getRegionInfo,
  getVPCSubnets: ComputePlane.actions.getVPCSubnets,
})(CreateComputePlane);
