import EmptyPrestoClusters from '../../../icons/EmptyPrestoClusters';
import Page from '../../Page';
import TitleNav from './TitleNav';

const Empty = () => {
  return (
    <Page.Page>
      <Page.PageHeader>
        <TitleNav canCreateCluster />
      </Page.PageHeader>
      <Page.PageEmpty>
        <EmptyPrestoClusters />
        <div>
          <div className='title'>There are no clusters yet</div>
          <div className='details'>
            Add a cluster to start analyzing data with watsonx.data
          </div>
        </div>
      </Page.PageEmpty>
    </Page.Page>
  );
};

export default Empty;
