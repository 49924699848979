/* eslint-disable arrow-body-style */
import { useCallback, useMemo } from 'react';
import { string, shape, bool, func } from 'prop-types';
import Page from '../../Page';
import FormWizard from '../../FormWizard';
import { useFormWizard } from '../../FormWizard/useFormWizard';
import ComputePlane from '../../ComputePlane';
import {
  SelectVPC,
  CreateRole,
  ReviewPolicies,
  CreateProvisioningRole,
  DeploymentDetails,
  CheckPrerequisites,
  CreateComputePlane,
  CreateFinished,
} from './Steps';
import { PageHelp } from './PageHelp';
import * as constants from '../constants';
import * as utils from '../utils';
import * as propTypes from '../propTypes';

const display = {
  newCreateComputePlaneHeader: 'Create a Compute Plane',
};

const CreateComputePlaneBase = ({
  isComputePlaneActive,
  awsAccountId,
  awsRegions,
  canCreateCluster,
  computePlane,
  computePlaneConfiguration,
  cfStackName,
  error,
  submitInProgress,
  destroyInProgress,
  deploy,
  updateComputePlane,
  hasVerifyRoleSuccess,
  verifyProvisioningRole,
  verifyRoleInProgress,
  verifyRoleError,
  awsCloudFormationUrl,
  terraformContent,
  terraformFileName,
  destroyComputePlane,
  getVPCSubnets,
  getRegionInfo,
  awsSubnets,
  awsAZsIDsByRegion,
}) => {
  const formWizard = useFormWizard();

  const handleSave = useCallback(
    ({ nextStepId, data, isComputeConfigurationDirty = false }) => {
      const shouldReverifyRole = utils.shouldReverifyRole(
        data,
        computePlane,
        awsAccountId,
      );
      updateComputePlane({
        computePlaneId: computePlane.computePlaneId,
        updatedData: data,
        nextStepId,
        isComputeConfigurationDirty,
        shouldReverifyRole,
      });
    },
    [computePlane, updateComputePlane, awsAccountId],
  );

  const handleBack = useCallback(() => {
    const { previousStepId } = formWizard;
    formWizard.onSetActiveStep(previousStepId);
  }, [formWizard]);

  const handleNext = useCallback(() => {
    const { nextStepId } = formWizard;
    formWizard.onSetActiveStep(nextStepId);
  }, [formWizard]);

  const handleDeployComputePlane = useCallback(() => {
    const { nextStepId } = formWizard;
    deploy(computePlane.computePlaneId, nextStepId);
  }, [deploy, computePlane, formWizard]);

  const handleVerifyProvisioningRole = useCallback(() => {
    verifyProvisioningRole({
      computePlaneId: computePlane.computePlaneId,
    });
  }, [verifyProvisioningRole, computePlane]);

  const handleRetry = useCallback(() => {
    deploy(computePlane.computePlaneId, constants.stepIds.step7);
  }, [deploy, computePlane]);

  const initialStep = useMemo(() => {
    // If compute plane is active or init_failure you end up on the final step
    if (
      computePlane?.status === constants.status.active ||
      computePlane?.status === constants.status.init_failure
    ) {
      return constants.stepIds.step8;
    }
    // if compute plane is initializing you end up on step 7 the current loading step we show while initializing.
    if (computePlane?.status === constants.status.initializing) {
      return constants.stepIds.step7;
    }
    return null;
  }, [computePlane]);

  return (
    <Page.PageWithOverflow>
      <Page.PageHeader>
        <h1>{display.newCreateComputePlaneHeader}</h1>
      </Page.PageHeader>
      <FormWizard.FormWizard
        help={PageHelp}
        initialStep={initialStep}
        steps={[
          {
            id: constants.stepIds.step1,
            render: () => (
              <SelectVPC
                error={error}
                onNext={handleSave}
                onBack={handleBack}
                submitInProgress={submitInProgress}
                computePlane={computePlane}
              />
            ),
          },
          {
            id: constants.stepIds.step2,
            render: () => (
              <CreateRole
                awsAccountId={awsAccountId}
                awsRegions={awsRegions}
                computePlane={computePlane}
                submitInProgress={submitInProgress}
                error={error}
                onNext={handleSave}
                onBack={handleBack}
              />
            ),
          },
          {
            id: constants.stepIds.step3,
            render: () => (
              <ReviewPolicies
                computePlane={computePlane}
                submitInProgress={submitInProgress}
                onNext={handleNext}
                onBack={handleBack}
              />
            ),
          },
          {
            id: constants.stepIds.step4,
            render: () => (
              <CreateProvisioningRole
                awsAccountId={awsAccountId}
                awsCloudFormationQuickCreateUrl={
                  computePlaneConfiguration?.awsCloudFormationQuickCreateUrl
                }
                awsCloudFormationUrl={awsCloudFormationUrl}
                cloudFormationTemplate={
                  computePlaneConfiguration?.cloudFormationTemplateContent
                }
                computePlane={computePlane}
                cfStackName={cfStackName}
                onNext={handleSave}
                onBack={handleBack}
                onVerifyProvisioningRole={handleVerifyProvisioningRole}
                verifyRoleInProgress={verifyRoleInProgress}
                verifyRoleError={verifyRoleError}
                hasVerifyRoleSuccess={hasVerifyRoleSuccess}
                terraformContent={terraformContent}
                terraformFileName={terraformFileName}
                submitInProgress={submitInProgress}
              />
            ),
          },
          {
            id: constants.stepIds.step5,
            render: () => (
              <DeploymentDetails
                computePlane={computePlane}
                awsSubnets={awsSubnets}
                awsAZsIDsByRegion={awsAZsIDsByRegion}
                submitInProgress={submitInProgress}
                getRegionInfo={getRegionInfo}
                getVPCSubnets={getVPCSubnets}
                error={error}
                onNext={handleSave}
                onBack={handleBack}
              />
            ),
          },
          {
            id: constants.stepIds.step6,
            render: () => (
              <CheckPrerequisites
                submitInProgress={submitInProgress}
                onDeployComputePlane={handleDeployComputePlane}
                error={error}
                onBack={handleBack}
                onNext={handleNext}
              />
            ),
          },
          {
            id: constants.stepIds.step7,
            render: () => (
              <CreateComputePlane
                computePlane={computePlane}
                isComputePlaneActive={isComputePlaneActive}
                onNext={handleNext}
              />
            ),
          },
          {
            id: constants.stepIds.step8,
            render: () => (
              <CreateFinished
                canCreateCluster={canCreateCluster}
                computePlane={computePlane}
                submitInProgress={submitInProgress}
                destroyInProgress={destroyInProgress}
                onBack={handleBack}
                onRetry={handleRetry}
                destroyComputePlane={destroyComputePlane}
              />
            ),
          },
        ]}
      />
    </Page.PageWithOverflow>
  );
};

CreateComputePlaneBase.defaultProps = {
  awsAccountId: null,
  awsRegions: null,
  computePlane: null,
  computePlaneConfiguration: null,
  cfStackName: null,
  hasVerifyRoleSuccess: false,
  error: undefined,
  submitInProgress: false,
  destroyInProgress: false,
  verifyRoleError: undefined,
  verifyRoleInProgress: false,
  awsCloudFormationUrl: null,
  awsSubnets: null,
  awsAZsIDsByRegion: null,
  terraformContent: null,
  terraformFileName: null,
};

CreateComputePlaneBase.propTypes = {
  isComputePlaneActive: bool.isRequired,
  awsAccountId: string,
  awsRegions: propTypes.awsRegions,
  canCreateCluster: bool.isRequired,
  computePlane: shape({
    computeplaneId: string,
    status: string,
  }),
  computePlaneConfiguration: propTypes.configuration,
  cfStackName: string,
  error: string,
  hasVerifyRoleSuccess: bool,
  submitInProgress: bool,
  destroyInProgress: bool,
  verifyRoleError: string,
  verifyRoleInProgress: bool,
  deploy: func.isRequired,
  updateComputePlane: func.isRequired,
  verifyProvisioningRole: func.isRequired,
  awsCloudFormationUrl: string,
  awsSubnets: ComputePlane.propTypes.awsSubnets,
  awsAZsIDsByRegion: ComputePlane.propTypes.awsAZsIDs,
  terraformContent: string,
  terraformFileName: string,
  destroyComputePlane: func.isRequired,
  getVPCSubnets: func.isRequired,
  getRegionInfo: func.isRequired,
};

export default CreateComputePlaneBase;
