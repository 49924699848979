import { bool, elementType } from 'prop-types';
import { useLocation, useParams, Navigate } from 'react-router-dom';
import HomePage from '../components/HomePage';
import Page from '../components/Page';

const PublicRoute = ({
  isAuthReady,
  authenticated,
  component: Component,
  noNav,
}) => {
  const location = useLocation();
  const params = useParams();

  if (!isAuthReady) {
    return (
      <Page.StandardLayout noNav>
        <Page.Loading />
      </Page.StandardLayout>
    );
  }

  if (authenticated) {
    const { from } = location.state || {
      from: { pathname: HomePage.route.getRoute(), search: location.search },
    };
    return <Navigate to={from} replace />;
  }

  return (
    <Page.StandardLayout noNav={noNav} displayFooter={false}>
      <Component params={params} location={location} />
    </Page.StandardLayout>
  );
};

PublicRoute.defaultProps = {
  component: null,
  isAuthReady: false,
  authenticated: false,
  noNav: false,
};

PublicRoute.propTypes = {
  component: elementType,
  isAuthReady: bool,
  authenticated: bool,
  noNav: bool,
};

export default PublicRoute;
