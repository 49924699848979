import Help from '../../Help';

const enableJMX = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Enable JMX for Dubugging</Help.HelpHeader>
      <Help.P>
        This will send JMX metrics to provide our support team key debugging
        information for troubleshooting.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  enableJMX,
};

export default module;
