import { useState, useEffect } from 'react';
import { func, string, bool } from 'prop-types';
import Form from '../../Form';
import Help from '../../Help';
import RenderModal from '../../Form/RenderModal';
import Modal from '../../Form/Modal';

const display = {
  confirmEnable:
    'Are you sure you want to enable sending Presto JMX metrics to IBM?',
  confirmDisable:
    'Are you sure you want to disable sending Presto JMX metrics to IBM?',
  enableButtonLabel: 'Enable',
  disableButtonLabel: 'Disable',
};

const EditJMX = ({
  submitInProgress,
  patchPrometheusJMX,
  computePlaneId,
  enableJMX,
  canDestroyOrEditPrometheus,
}) => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (showPrompt) {
      RenderModal(
        <Modal
          onSubmit={() => {
            setShowPrompt(false);
            patchPrometheusJMX(computePlaneId, !enableJMX);
          }}
          onCancel={() => {
            setShowPrompt(false);
          }}
          confirmMessage={
            enableJMX ? display.confirmDisable : display.confirmEnable
          }
        />,
      );
    }
  }, [patchPrometheusJMX, computePlaneId, enableJMX, showPrompt]);

  return (
    <Help.HelpNavigation>
      <Form.TertiaryButton
        onClick={() => {
          setShowPrompt(true);
        }}
        label={
          enableJMX ? display.disableButtonLabel : display.enableButtonLabel
        }
        disabled={submitInProgress || !canDestroyOrEditPrometheus}
        disabledOnErrors={false}
      />
    </Help.HelpNavigation>
  );
};

EditJMX.defaultProps = {
  submitInProgress: false,
};

EditJMX.propTypes = {
  submitInProgress: bool,
  patchPrometheusJMX: func.isRequired,
  computePlaneId: string.isRequired,
  enableJMX: bool.isRequired,
  canDestroyOrEditPrometheus: bool.isRequired,
};

export default EditJMX;
