import { useCallback, useState } from 'react';
import { arrayOf, bool, string, shape } from 'prop-types';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import Banner from '../../Banner';
import AuthorizationServices from '../../AuthorizationServices';
import PrestoClusters from '../../PrestoClusters';
import {
  AuthorizationServiceDetails,
  ConnectionDetails,
  RangerHiveSSLDetails,
} from '../../AuthorizationServiceAdd/components';
import PageHelp from './PageHelp';

const display = {
  header: 'Edit Authorization Service',
  updateButton: 'Modify Authorization Service',
  cancelButton: 'Cancel',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  savingDataSource: 'Updating authorization service...',
  confirmTitle:
    'This action will restart the Presto clusters that have a data source with this authorization service attached.',
  confirmDetails:
    'All queries running on those clusters will fail. The clusters will be moved to pending state and will be unusable until they move back to active state.',
};

const UpdateAuthorizationServiceForm = ({
  errors,
  usePop,
  submitInProgress,
  submitSuccess,
  authorizationService,
  prestoClusters,
  authorizationServiceId,
}) => {
  const getRoute = useCallback(() => {
    return AuthorizationServices.routes.getManageAuthorizationServiceRoute(
      authorizationServiceId,
    );
  }, [authorizationServiceId]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const onConfirm = useCallback(() => {
    setConfirmSubmit(true);
  }, []);

  const cancelConfirm = useCallback(() => {
    setConfirmSubmit(false);
  }, []);

  const showBanner =
    prestoClusters === null
      ? false
      : prestoClusters.some((pc) => {
          return pc.status === PrestoClusters.constants.status.active;
        });

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>
          {display.header} {usePop}
        </h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <Form.HiddenInput
            name='authorizationServiceType'
            value={authorizationService.type}
          />
          <AuthorizationServiceDetails
            authorizationService={authorizationService}
            error={errors.details}
          />
          <ConnectionDetails
            authorizationService={authorizationService}
            disabled={submitInProgress}
            error={errors.connection}
          />
          <RangerHiveSSLDetails disabled={submitInProgress} />
          {!showBanner && (
            <Page.Buttons>
              <Form.SecondaryButton
                onClick={onCancel}
                label={display.cancelButton}
                disabled={submitInProgress}
                disabledOnErrors={false}
              />
              <Form.Submit
                label={
                  !submitInProgress
                    ? display.updateButton
                    : display.savingDataSource
                }
                disabled={submitInProgress}
                showLoading={submitInProgress}
              />
            </Page.Buttons>
          )}
          {showBanner && confirmSubmit && (
            <Banner
              title={display.confirmTitle}
              details={display.confirmDetails}
            >
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={cancelConfirm}
                  label={display.cancelButton}
                  disabled={submitInProgress}
                  disabledOnErrors={false}
                />
                <Form.Submit
                  label={
                    !submitInProgress
                      ? display.updateButton
                      : display.savingDataSource
                  }
                  disabled={submitInProgress}
                  showLoading={submitInProgress}
                />
              </div>
            </Banner>
          )}
          {showBanner && !confirmSubmit && (
            <Page.Buttons>
              <Form.SecondaryButton
                onClick={onCancel}
                label={display.cancelButton}
                disabled={submitInProgress}
                disabledOnErrors={false}
              />
              <Form.PrimaryButton
                onClick={onConfirm}
                label={
                  !submitInProgress
                    ? display.updateButton
                    : display.savingDataSource
                }
                disabled={submitInProgress}
                showLoading={submitInProgress}
              />
            </Page.Buttons>
          )}
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

UpdateAuthorizationServiceForm.defaultProps = {
  errors: {},
  usePop: true,
  submitInProgress: false,
  submitSuccess: false,
  prestoClusters: null,
};

UpdateAuthorizationServiceForm.propTypes = {
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
  usePop: bool,
  submitInProgress: bool,
  submitSuccess: bool,
  authorizationService:
    AuthorizationServices.propTypes.AuthorizationService.isRequired,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  authorizationServiceId: string.isRequired,
};

export default UpdateAuthorizationServiceForm;
