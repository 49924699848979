/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, useCallback, useMemo } from 'react';
import { bool, func, string, shape, number, arrayOf } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Form from '../../Form';
import Page from '../../Page';
import PrestoUsers from '../../PrestoUsers';
import PrestoUsersAdd from '../../PrestoUsersAdd';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import useRouteBuilder from '../../../router/useRouteBuilder';
import ChangePrestoUsersForm from './ChangePrestoUsersForm';

const ChangePrestoUsers = ({
  prestoClusterId,
  prestoUsers,
  prestoUserIds,
  status,
  name,
  notFound,
  actionStatus,
  usePop,
  loadPrestoCluster,
  loadPrestoUsers,
  savePrestoUsers,
  resetCreatePrestoUser,
  workflowInProgress,
}) => {
  useEffect(() => {
    loadPrestoCluster(prestoClusterId);
    loadPrestoUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoClusterId]);

  const [actionInProgress, setActionInProgress] = useState(false);

  const save = useCallback(
    (values) => {
      const selectedIds = Object.keys(values.prestoUsers).reduce((acc, key) => {
        if (values.prestoUsers[key]) {
          acc.push(key);
        }
        return acc;
      }, []);
      const submitId = Date.now();
      setActionInProgress(submitId);
      return savePrestoUsers(prestoClusterId, selectedIds, submitId);
    },
    [savePrestoUsers, prestoClusterId],
  );

  const submitSuccess = useMemo(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    ) {
      return actionStatus.success;
    }
    return false;
  }, [actionStatus, actionInProgress]);

  const showCreatePrestoUser =
    PrestoUsersAdd.components.useCreatePrestoUserState();
  useEffect(() => {
    if (!showCreatePrestoUser) {
      resetCreatePrestoUser();
    }
  }, [showCreatePrestoUser, resetCreatePrestoUser]);
  const dispatch = PrestoUsersAdd.components.useCreatePrestoUserDispatch();
  const hideCreatePrestoUser = useCallback(() => {
    return dispatch(false);
  }, [dispatch]);

  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if ((!prestoClusterId && !prestoUsers) || !status || notFound) {
    return <Page.Loading />;
  }

  return (
    <>
      {showCreatePrestoUser && (
        <PrestoUsersAdd.Container hideCreatePrestoUser={hideCreatePrestoUser} />
      )}
      <ActionInProgressStateContext.Provider value={actionInProgress}>
        <ActionInProgressSetStateContext.Provider value={setActionInProgress}>
          <Page.PageWithOverflow
            style={{ display: showCreatePrestoUser ? 'none' : undefined }}
          >
            <Form.Form
              name='change-presto-cluster-presto-users'
              submitAction={save}
            >
              <ChangePrestoUsersForm
                prestoUsers={prestoUsers}
                prestoUserIds={prestoUserIds}
                name={name}
                status={status}
                actionStatus={actionStatus}
                submitSuccess={submitSuccess}
                usePop={usePop}
                prestoClusterId={prestoClusterId}
                workflowInProgress={workflowInProgress}
              />
            </Form.Form>
          </Page.PageWithOverflow>
        </ActionInProgressSetStateContext.Provider>
      </ActionInProgressStateContext.Provider>
    </>
  );
};

ChangePrestoUsers.defaultProps = {
  prestoUsers: null,
  prestoUserIds: null,
  name: null,
  status: null,
  usePop: true,
  notFound: false,
  actionStatus: undefined,
  workflowInProgress: null,
};

ChangePrestoUsers.propTypes = {
  prestoClusterId: string.isRequired,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  prestoUserIds: arrayOf(string),
  name: string,
  status: string,
  notFound: bool,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  usePop: bool,
  loadPrestoCluster: func.isRequired,
  loadPrestoUsers: func.isRequired,
  savePrestoUsers: func.isRequired,
  resetCreatePrestoUser: func.isRequired,
  workflowInProgress: bool,
};

const { CreatePrestoUserNavigation } = PrestoUsersAdd.components;
const ChangePrestoUsersWrapper = (props) => {
  return (
    <CreatePrestoUserNavigation>
      <ChangePrestoUsers {...props} />
    </CreatePrestoUserNavigation>
  );
};

ChangePrestoUsersWrapper.defaultProps = {
  prestoUsers: null,
  prestoUserIds: null,
  name: null,
  status: null,
  usePop: true,
  notFound: false,
  actionStatus: undefined,
};

ChangePrestoUsersWrapper.propTypes = {
  prestoClusterId: string.isRequired,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  prestoUserIds: arrayOf(string),
  name: string,
  status: string,
  notFound: bool,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  usePop: bool,
  loadPrestoCluster: func.isRequired,
  loadPrestoUsers: func.isRequired,
  savePrestoUsers: func.isRequired,
  resetCreatePrestoUser: func.isRequired,
};

export default ChangePrestoUsersWrapper;
