import { node } from 'prop-types';
import { FormPageDiv } from './styledComponents';

const FormPage = ({ children }) => {
  return <FormPageDiv>{children}</FormPageDiv>;
};

FormPage.propTypes = {
  children: node.isRequired,
};

export default FormPage;
