import { string, func, arrayOf, shape } from 'prop-types';
import Help from '../../Help';
import Page from '../../Page';
import DestroyInProgress from './DestroyInProgress';
import DestroyError from './DestroyError';
import PageHelp from './PageHelp';

const display = {
  header: 'Destroying Compute Plane',
};

const DestroyComputePlane = ({
  status,
  errorMessages,
  iamRoleUrl,
  awsRoleARN,
  destroy,
  computePlaneId,
}) => {
  return (
    <Page.PageWithOverflow>
      <Help.HelpNavigation>
        <Page.PageHeader>
          <h1>{display.header}</h1>
        </Page.PageHeader>
        <Page.FormPage>
          <Page.FormColumn>
            {status === 'destroying' ? (
              <DestroyInProgress />
            ) : (
              <DestroyError
                errorMessages={errorMessages}
                iamRoleUrl={iamRoleUrl}
                awsRoleARN={awsRoleARN}
                destroy={destroy}
                computePlaneId={computePlaneId}
              />
            )}
          </Page.FormColumn>
          <Help.HelpColumn DefaultHelp={PageHelp} />
        </Page.FormPage>
      </Help.HelpNavigation>
    </Page.PageWithOverflow>
  );
};

DestroyComputePlane.defaultProps = {
  errorMessages: null,
};

DestroyComputePlane.propTypes = {
  status: string.isRequired,
  errorMessages: arrayOf(
    shape({
      errorSummary: string,
      errorMessage: string,
    }),
  ),
  iamRoleUrl: string.isRequired,
  awsRoleARN: string.isRequired,
  destroy: func.isRequired,
  computePlaneId: string.isRequired,
};

export default DestroyComputePlane;
