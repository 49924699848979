import { connect } from '../../utils/redux';
import AuthorizationServices from '../AuthorizationServices';
import { UpdateAuthorizationService } from './components';
import * as selectors from './selectors';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
  return {
    errors: selectors.getErrors(state),
    usePop: selectors.getUsePop(state),
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    notFound: AuthorizationServices.selectors.getIsNotFound(state, props),
    authorizationServiceId:
      AuthorizationServices.selectors.getAuthorizationServiceId(state, props),
    authorizationService:
      AuthorizationServices.selectors.getAuthorizationService(state, props),
    prestoClusters: AuthorizationServices.selectors.getPrestoClusters(
      state,
      props,
    ),
  };
};

export default connect(mapStateToProps, {
  loadAuthorizationService: AuthorizationServices.actions.get,
  loadPrestoClusters: AuthorizationServices.actions.getPrestoClusters,
  update: actions.submit,
})(UpdateAuthorizationService);
