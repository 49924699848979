import { createSelector } from 'reselect';
import ComputePlane from '../ComputePlane';
import PrestoClusters from '../PrestoClusters';

export const getAwsEBSCacheVolumesUrl = createSelector(
  [
    PrestoClusters.selectors.getPrestoCluster,
    ComputePlane.selectors.getActiveComputePlane,
  ],
  (prestoCluster, computePlane) => {
    if (prestoCluster && computePlane) {
      if (
        prestoCluster.clusterConfiguration.enableDataCache ||
        prestoCluster.clusterConfiguration.enableFragmentResultCache
      ) {
        const awsEBSCacheVolumesUrl = `https://${computePlane.awsRegion}.console.aws.amazon.com/ec2/v2/home?region=${computePlane.awsRegion}#Volumes:tag:ibmlh:prestoclusterid=${prestoCluster.prestoClusterId};tag:Name=ibmlh-IO-cache;sort=size`;
        return awsEBSCacheVolumesUrl;
      }
    }
    return null;
  },
);
