import { bool, string, shape } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Page from '../../Page';
import Banner from '../../Banner';
import RangerHiveConnectionDetails from './RangerHiveConnectionDetails';
import AuthorizationServices from '../../AuthorizationServices';

const display = {
  boxTitle: 'Access Details',
};

const ConnectionDetails = ({ disabled, error, authorizationService }) => {
  const { watch } = useFormContext();
  const authorizationServiceType = watch(
    'authorizationServiceType',
    AuthorizationServices.constants.authorizationServiceTypes.rangerHive,
  );

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && error.type === authorizationServiceType && (
        <Banner title={error.error} scrollIntoView />
      )}
      <Page.FormContent>
        <RangerHiveConnectionDetails
          authorizationService={authorizationService}
          disabled={disabled}
          active={
            authorizationServiceType ===
            AuthorizationServices.constants.authorizationServiceTypes.rangerHive
          }
        />
      </Page.FormContent>
    </Page.Box>
  );
};

ConnectionDetails.defaultProps = {
  disabled: false,
  error: null,
  authorizationService: null,
};

ConnectionDetails.propTypes = {
  disabled: bool,
  error: shape({
    type: string,
    error: string,
  }),
  authorizationService: AuthorizationServices.propTypes.AuthorizationService,
};

export default ConnectionDetails;
