import Ajv from 'ajv';
import { parse } from 'yaml';
import remoteWriteRuleSchema from './remoteWriteRuleSchema.json';

const ajv = new Ajv();

export const parser = (yamlString, schemaJsonFile) => {
  // convert input yaml file to json
  const inputJson = parse(yamlString);

  // validate input json against schema json
  const isValid = ajv.validate(remoteWriteRuleSchema, inputJson);

  // return response and errors (if any)
  return [isValid, ajv.errors, inputJson];
};

export const findKeysWithMissingValues = (obj, path = '') => {
  let errors = [];

  // Recursively check each property of the object
  Object.entries(obj).forEach(([key, value]) => {
    // Construct a string that represents the current path in the object
    const currentPath = path ? `${path}.${key}` : key;

    if (value === '' || value === null || value === undefined) {
      // If the value is missing, add the path to the errors array
      errors.push(`Missing value for key: ${currentPath}`);
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      // If the value is an object, recursively check its properties
      errors = errors.concat(findKeysWithMissingValues(value, currentPath));
    }
  });

  return errors;
};

export const mustBeValidYaml = (value) => {
  try {
    const result = parse(value);
    if (typeof result !== 'object') {
      return 'Remote write rule must be yaml object';
    }
    if (Array.isArray(result)) {
      return 'Only single remote write rule supported';
    }
    return true;
  } catch (error) {
    return error.message; // Error message for validation
  }
};
