import { func, string, bool } from 'prop-types';
import Page from '../../Page';
import Form from '../../Form';
import AddIdentityProviderForm from './AddIdentityProviderForm';

const AddIdentityProvider = ({
  submitInProgress,
  submitSuccess,
  usePop,
  error,
  submit,
}) => {
  return (
    <Page.PageWithOverflow>
      <Form.Form name='add-identity-provider-form' submitAction={submit}>
        <AddIdentityProviderForm
          submitInProgress={submitInProgress}
          submitSuccess={submitSuccess}
          usePop={usePop}
          error={error}
        />
      </Form.Form>
    </Page.PageWithOverflow>
  );
};

AddIdentityProvider.defaultProps = {
  submitSuccess: false,
  usePop: true,
  error: null,
};

AddIdentityProvider.propTypes = {
  submitInProgress: bool.isRequired,
  submitSuccess: bool,
  usePop: bool,
  error: string,
  submit: func.isRequired,
};

export default AddIdentityProvider;
