import { bool, oneOfType, string } from 'prop-types';
import { SuccessBadge, WarningBadge } from '../../Badge';

const display = {
  enabled: 'Enabled',
  disabled: 'Disabled',
};

const Enabled = () => {
  return <SuccessBadge>{display.enabled}</SuccessBadge>;
};

const Disabled = () => {
  return <WarningBadge>{display.disabled}</WarningBadge>;
};

const CacheStatus = ({ status }) => {
  if (status) {
    return <Enabled />;
  }

  return <Disabled />;
};

CacheStatus.defaultProps = {
  status: false,
};

CacheStatus.propTypes = {
  status: oneOfType([bool, string]),
};

export default CacheStatus;
