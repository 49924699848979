import Loading from '../../Loading';
import Page from '../../Page';
import TitleNav from './TitleNav';

const LoadingPage = () => {
  return (
    <Page.Page>
      <Page.PageHeader>
        <TitleNav canCreateCluster={false} />
      </Page.PageHeader>
      <Page.PageEmpty>
        <Loading.Loading />
      </Page.PageEmpty>
    </Page.Page>
  );
};

export default LoadingPage;
