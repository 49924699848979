import { useCallback, useEffect, useMemo } from 'react';
import { arrayOf, string, bool, shape } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Banner from '../../Banner';
import Form from '../../Form';
import Table from '../../Table';
import Page from '../../Page';
import PrestoUsers from '../../PrestoUsers';
import PrestoUsersAdd from '../../PrestoUsersAdd';
import { NoDataDiv } from './styledComponents';

const display = {
  boxTitle: 'Presto Users',
  nameColumnLabel: 'Name',
  selectedColumnLabel: 'Selected',
  atLeastOneUserRequired: 'At least one Presto user must be selected.',
  noPrestoUsers: 'No Presto users found.',
  loadingPrestoUsers: 'Loading...',
  createPrestoUserLabel: 'Create Presto User',
};

const validateHidden = (_, values) => {
  const { prestoUsers } = values;
  if (!prestoUsers) {
    return display.atLeastOneUserRequired;
  }
  const prestoUserValues = Object.values(prestoUsers);
  if (
    !prestoUserValues ||
    !prestoUserValues.some((pu) => {
      return pu;
    })
  ) {
    return display.atLeastOneUserRequired;
  }
  return true;
};

const SelectCell = ({ data, className, key }) => {
  return (
    <Table.DataCell key={key} className={`justify-center ${className}`}>
      <Form.CheckboxInput
        name={`prestoUsers.${data.prestoUserId}`}
        disabled={data.disabled}
      />
    </Table.DataCell>
  );
};

SelectCell.defaultProps = {
  className: '',
};

SelectCell.propTypes = {
  data: shape({
    dataSourceId: string.isRequired,
    defaultValue: bool.isRequired,
  }).isRequired,
  className: string,
  key: string.isRequired,
};

const columns = [
  {
    name: display.nameColumnLabel,
    selector: (prestoUser) => {
      return prestoUser.username;
    },
    cellWidth: 'auto',
  },
  {
    name: display.selectedColumnLabel,
    selector: (prestoUser, additionalContext) => {
      return {
        prestoUserId: prestoUser.prestoUserId,
        disabled: additionalContext.disabled,
      };
    },
    cellRender: SelectCell,
    cellWidth: '0fr',
  },
];

const dataKeyGenerator = (prestoUser) => {
  return prestoUser.prestoUserId;
};

const MultiUserClusterAuth = ({ disabled, error, prestoUsers }) => {
  const {
    watch,
    formState: { errors: formErrors, isSubmitted },
    trigger,
    getValues,
    setValue,
  } = useFormContext();

  const watchFields = useMemo(() => {
    if (!prestoUsers) return null;
    return prestoUsers.map((pu) => {
      return `prestoUsers.${pu.prestoUserId}`;
    });
  }, [prestoUsers]);

  const prestoUserFields = watchFields ? watch(watchFields) : null;
  useEffect(() => {
    if (!prestoUserFields) return;
    const hasSelectedUsers = getValues('hasSelectedUsers');
    const usersSelected = prestoUserFields.some((pu) => {
      return pu;
    });
    const currentHasSelectedUsers = usersSelected ? 1 : 0;
    const su = hasSelectedUsers === '' || hasSelectedUsers === 0 ? 0 : 1;
    if (currentHasSelectedUsers !== su) {
      setValue('hasSelectedUsers', currentHasSelectedUsers, {
        shouldValidate: isSubmitted,
      });
      if (isSubmitted) {
        trigger();
      }
    }
  }, [prestoUserFields, getValues, setValue, isSubmitted, trigger]);

  const additionalContext = useMemo(() => {
    return {
      disabled,
    };
  }, [disabled]);

  const dispatch = PrestoUsersAdd.components.useCreatePrestoUserDispatch();
  const showCreatePrestoUser = useCallback(() => {
    dispatch(true);
  }, [dispatch]);

  return (
    <Page.Box>
      <Page.TableBoxHeader>
        <h2>{display.boxTitle}</h2>
        <div className='buttons'>
          <Form.PrimaryButton
            label={display.createPrestoUserLabel}
            onClick={showCreatePrestoUser}
            disabledOnErrors={false}
            disabled={disabled}
          />
        </div>
      </Page.TableBoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      {formErrors && formErrors.hasSelectedUsers && (
        <Banner title={formErrors.hasSelectedUsers.message} scrollIntoView />
      )}
      {!prestoUsers && <NoDataDiv>{display.loadingPrestoUsers}</NoDataDiv>}
      {prestoUsers && prestoUsers.length === 0 && (
        <NoDataDiv>{display.noPrestoUsers}</NoDataDiv>
      )}
      {prestoUsers && prestoUsers.length > 0 && (
        <Table.Table
          data={prestoUsers}
          columns={columns}
          dataKeyGenerator={dataKeyGenerator}
          additionalContext={additionalContext}
        />
      )}
      <Form.HiddenInput
        name='hasSelectedUsers'
        validationRules={{
          validate: validateHidden,
        }}
      />
    </Page.Box>
  );
};

MultiUserClusterAuth.defaultProps = {
  disabled: false,
  error: undefined,
  prestoUsers: undefined,
};

MultiUserClusterAuth.propTypes = {
  disabled: bool,
  error: string,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
};

export default MultiUserClusterAuth;
