import BoxNavTarget from './BoxNavTarget';
import FormContent from './FormContent';
import FormPage from './FormPage';
import InPageNavigation from './InPageNavigation';
import Links from './Links';
import Loading from './Loading';
import LogoBox from './LogoBox';
import PageHeader from './PageHeader';
import PageHeaderWithNav from './PageHeaderWithNav';
import PageNavigation from './PageNavigation';
import Scroll from './Scroll';
import StandardLayout from './StandardLayout';
import {
  BoxContentHelpDiv,
  BoxContentWithHelpDiv,
  BoxDiv,
  BoxHeaderDiv,
  ButtonsDiv,
  ConfirmDangerDiv,
  DangerZoneDiv,
  DataContentWithLogoDiv,
  FormColumnDiv,
  HelpColumnDiv,
  InformationBannerDiv,
  PageDiv,
  PageHeaderButtonsDiv,
  PageWithOverflowDiv,
  PageEmptyDiv,
  TableBoxHeaderDiv,
} from './styledComponents';

const module = {
  Box: BoxDiv,
  BoxContentHelp: BoxContentHelpDiv,
  BoxContentWithHelp: BoxContentWithHelpDiv,
  BoxNavTarget,
  BoxHeader: BoxHeaderDiv,
  Buttons: ButtonsDiv,
  ConfirmDanger: ConfirmDangerDiv,
  DangerZone: DangerZoneDiv,
  DataContentWithLogo: DataContentWithLogoDiv,
  FormColumn: FormColumnDiv,
  FormContent,
  FormPage,
  DeprecatedHelpColumn: HelpColumnDiv,
  InformationBanner: InformationBannerDiv,
  InPageNavigation,
  LogoBox,
  Loading,
  Page: PageDiv,
  PageWithOverflow: PageWithOverflowDiv,
  PageEmpty: PageEmptyDiv,
  PageHeaderWithNav,
  PageNavigation,
  PageHeader,
  PageHeaderButtons: PageHeaderButtonsDiv,
  PrimaryLink: Links.PrimaryLink,
  Scroll,
  StandardLayout,
  SecondaryLink: Links.SecondaryLink,
  TableBoxHeader: TableBoxHeaderDiv,
  TertiaryLink: Links.TertiaryLink,
};

export default module;
