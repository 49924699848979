import { combineReducers } from 'redux';
import auth from '../auth';
import routerReducer from '../router/reducer';
import AccountSetup from '../components/AccountSetup';
import AuthorizationServiceAdd from '../components/AuthorizationServiceAdd';
import AuthorizationServiceUpdate from '../components/AuthorizationServiceUpdate';
import AuthorizationServices from '../components/AuthorizationServices';
import Buckets from '../components/Buckets';
import ComputePlane from '../components/ComputePlane';
import ComputePlaneAdd from '../components/ComputePlaneAdd';
import CostManagement from '../components/CostManagement';
import DataSources from '../components/DataSources';
import DataSourceAdd from '../components/DataSourceAdd';
import DataSourceUpdate from '../components/DataSourceUpdate';
import IdentityProviderAdd from '../components/IdentityProviderAdd';
import IdentityProviders from '../components/IdentityProviders';
import IdentityProvidersUpdate from '../components/IdentityProvidersUpdate';
import PrestoClusters from '../components/PrestoClusters';
import PrestoClusterAdd from '../components/PrestoClusterAdd';
import PrestoUsers from '../components/PrestoUsers';
import PrestoUsersAdd from '../components/PrestoUsersAdd';
import PrestoUsersUpdate from '../components/PrestoUsersUpdate';
import SignIn from '../components/SignIn';
import FormWizard from '../components/FormWizard';
import * as ably from '../rayApi/ably';
import StandalonePrometheusAdd from '../components/StandalonePrometheusAdd';
import StandalonePrometheus from '../components/StandalonePrometheus';

const rootReducer = combineReducers({
  ably: ably.reducer,
  accountSetup: AccountSetup.reducer,
  auth: auth.reducer,
  authorizationServiceAdd: AuthorizationServiceAdd.reducer,
  authorizationServices: AuthorizationServices.reducer,
  authorizationServiceUpdate: AuthorizationServiceUpdate.reducer,
  buckets: Buckets.reducer,
  computePlane: ComputePlane.reducer,
  computePlaneAdd: ComputePlaneAdd.reducer,
  costManagement: CostManagement.reducer,
  currentRoute: routerReducer,
  dataSources: DataSources.reducer,
  dataSourceAdd: DataSourceAdd.reducer,
  dataSourceUpdate: DataSourceUpdate.reducer,
  formWizard: FormWizard.reducer,
  identityProviderAdd: IdentityProviderAdd.reducer,
  identityProviders: IdentityProviders.reducer,
  identityProviderUpdate: IdentityProvidersUpdate.reducer,
  prestoClusters: PrestoClusters.reducer,
  prestoClusterAdd: PrestoClusterAdd.reducer,
  prestoUsers: PrestoUsers.reducer,
  prestoUsersAdd: PrestoUsersAdd.reducer,
  prestoUsersUpdate: PrestoUsersUpdate.reducer,
  signIn: SignIn.reducer,
  StandalonePrometheusAdd: StandalonePrometheusAdd.reducer,
  standalonePrometheus: StandalonePrometheus.reducer,
});

export default rootReducer;
