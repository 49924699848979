import { useEffect } from 'react';
import { string, arrayOf, func, any, shape } from 'prop-types';
import DataSources from '../../DataSources';
import Page from '../../Page';
import PrestoUsers from '../../PrestoUsers';
import noop from '../../../utils/noop';
import Table from '../../Table';
import { NoDataDiv } from './styledComponents';

const display = {
  title: 'Data Sources',
  loading: 'Loading...',
  manageButtonLabel: 'View',
  nameColumnLabel: 'Name',
  typeColumnLabel: 'Type',
  catalogNameColumnLabel: 'Catalog Name',
  urlColumnLabel: 'URL',
  mappingColumnLabel: 'IAM Role ARN',
};

const ManageCell = ({ data, key }) => {
  return (
    <Table.ButtonCell key={key}>
      <Page.TertiaryLink to={data}>
        {display.manageButtonLabel}
      </Page.TertiaryLink>
    </Table.ButtonCell>
  );
};

ManageCell.propTypes = {
  data: string.isRequired,
  key: string.isRequired,
};

const LogoCell = ({ data, key }) => {
  return (
    <Table.ImageCell key={key}>
      <img src={data.Logo} alt={data.alt} style={{ display: 'block' }} />
    </Table.ImageCell>
  );
};

LogoCell.propTypes = {
  data: shape({
    // eslint-disable-next-line react/forbid-prop-types
    Logo: any,
    alt: string.isRequired,
  }).isRequired,
  key: string.isRequired,
};

const columns = [
  {
    name: display.typeColumnLabel,
    selector: (dataSource) => {
      return DataSources.constants.dataSourceTypeLogos[dataSource.type];
    },
    headerRender: noop,
    cellRender: LogoCell,
    cellWidth: '1fr',
  },
  {
    name: display.catalogNameColumnLabel,
    selector: (dataSource) => {
      return dataSource.catalogName;
    },
    cellWidth: '2fr',
  },
  {
    name: display.mappingColumnLabel,
    selector: (dataSource, additionalContext) => {
      switch (dataSource.type) {
        case DataSources.constants.dataSourceTypes.glue: {
          const mapping = dataSource.prestoUserMappings.find((pu) => {
            return pu.username === additionalContext.prestoUser.username;
          });
          return mapping && mapping.configuration.iamRoleMapping;
        }
        default:
          return null;
      }
    },
    cellWidth: '3fr',
  },
  {
    name: 'Manage',
    selector: (dataSource) => {
      return DataSources.routes.getManageDataSourceRoute(
        dataSource.dataSourceId,
      );
    },
    headerRender: noop,
    cellRender: ManageCell,
    cellWidth: '2fr',
  },
];

const dataKeyGenerator = (dataSource) => {
  return dataSource.dataSourceId;
};

const DataSourcesTable = ({ dataSources, prestoUser }) => {
  return (
    <Table.Table
      data={dataSources}
      columns={columns}
      dataKeyGenerator={dataKeyGenerator}
      additionalContext={{ prestoUser }}
    />
  );
};

DataSourcesTable.propTypes = {
  dataSources: arrayOf(DataSources.propTypes.DataSource).isRequired,
  prestoUser: PrestoUsers.propTypes.PrestoUser.isRequired,
};

const DataSourcesList = ({
  prestoUser,
  dataSources,
  loadDataSources,
  loadDataSourcePrestoClusters,
  prestoUserId,
}) => {
  useEffect(() => {
    loadDataSources(prestoUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoUserId]);

  useEffect(() => {
    if (dataSources && dataSources.length > 0) {
      dataSources.forEach((ds) => {
        loadDataSourcePrestoClusters(ds.dataSourceId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSources]);

  if (!prestoUser || prestoUser.dataSourceCount === 0) return null;

  return (
    <Page.Box>
      <Page.TableBoxHeader>
        <h2>{display.title}</h2>
      </Page.TableBoxHeader>
      {dataSources && dataSources.length > 0 && (
        <DataSourcesTable dataSources={dataSources} prestoUser={prestoUser} />
      )}
      {!dataSources && <NoDataDiv>{display.loading}</NoDataDiv>}
    </Page.Box>
  );
};

DataSourcesList.defaultProps = {
  prestoUser: null,
  dataSources: null,
};

DataSourcesList.propTypes = {
  prestoUser: PrestoUsers.propTypes.PrestoUser,
  dataSources: arrayOf(DataSources.propTypes.DataSource),
  loadDataSources: func.isRequired,
  loadDataSourcePrestoClusters: func.isRequired,
  prestoUserId: string.isRequired,
};

export default DataSourcesList;
