import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';
import * as environment from '../../utils/environment';

export const getAll = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: 'computeplanes' });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const get = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `computeplanes/${params.computePlaneId}`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const post = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: 'computeplanes' });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePOST(uri, headers, prepedBody, abortFetchSignal);
};

export const patch = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `computeplanes/${params.computePlaneId}`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePATCH(uri, headers, prepedBody, abortFetchSignal);
};

export const putDestroy = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `computeplanes/${params.computePlaneId}/destroy`,
  });
  return rayApi.api.executePUT(uri, headers, body, abortFetchSignal);
};

export const putUpgrade = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `computeplanes/${params.computePlaneId}/upgrade`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};

export const putCheckProvisioningPolicies = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `computeplanes/${params.computePlaneId}/checkprovisioningpolicies`,
  });
  return rayApi.api.executePUT(uri, headers, body, abortFetchSignal);
};

export const deploy = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `computeplanes/${params.computePlaneId}/deploy`,
  });
  return rayApi.api.executePOST(uri, headers, body, abortFetchSignal);
};

export const getConfiguration = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  let baseUri = rootUri;
  // Local environment is not using nginx right now so we have to route appropriately.
  if (environment.isLocalhost()) {
    baseUri = `${window.location.protocol}//localhost:3014/`;
  }
  const path = `cumulus/computeplanes/${params.computePlaneId}/configuration`;
  const uri = buildUrl({ baseUrl: baseUri, path });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getRegionInfo = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  let baseUri = rootUri;
  // Local environment is not using nginx right now so we have to route appropriately.
  if (environment.isLocalhost()) {
    baseUri = `${window.location.protocol}//localhost:3014/`;
  }
  const path = `cumulus/computeplanes/${params.computePlaneId}/region/${params.region}`;
  const uri = buildUrl({ baseUrl: baseUri, path });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getVPCSubnets = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  let baseUri = rootUri;
  // Local environment is not using nginx right now so we have to route appropriately.
  if (environment.isLocalhost()) {
    baseUri = `${window.location.protocol}//localhost:3014/`;
  }
  const path = `cumulus/computeplanes/${params.computePlaneId}/subnets`;
  const uri = buildUrl({ baseUrl: baseUri, path });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const verifyProvisioningRole = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  let baseUri = rootUri;
  // Local environment is not using nginx right now so we have to route appropriately.
  if (environment.isLocalhost()) {
    baseUri = `${window.location.protocol}//localhost:3014/`;
  }
  const uri = buildUrl({
    baseUrl: baseUri,
    path: `cumulus/computeplanes/${params.computePlaneId}/validateiamconfiguration`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};
