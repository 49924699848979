import { useMemo } from 'react';
import { func, bool, shape, string } from 'prop-types';
import Page from '../../../Page';
import Form from '../../../Form';
import { awsAccountIdRegex, vpcIdRegex } from '../../../../utils/regex';
import * as Help from '../CreateComputePlaneHelp';
import * as constants from '../../constants';
import * as propTypes from '../../propTypes';
import Banner from '../../../Banner';
import { useFormWizard } from '../../../FormWizard/useFormWizard';

const display = {
  title: 'Step 2 of 8 - Prepare to Create Role',
  roleConfigurationHeader: 'Role Configuration',
  awsAccountIdLabel: 'AWS Account ID',
  awsAccountIdRequiredError: 'AWS Account ID is required',
  awsAccountIdPatternError: 'AWS Account ID must be a valid 12-digit number',
  awsRegionLabel: 'AWS Region',
  awsRegionRequiredError: 'AWS region is required',
  vpcId: 'VPC ID',
  vpcIdRequiredError: 'VPC ID is required',
  vpcIdPatternError:
    'VPC ID is invalid, valid VPC ID should have a prefix of vpc- folow by lowercase letters and numbers with no special characters',
};

export const CreateRole = ({
  awsRegions,
  awsAccountId,
  onNext,
  onBack,
  computePlane,
  submitInProgress,
  error,
}) => {
  const formWizard = useFormWizard();

  const awsRegionsSelectItems = useMemo(() => {
    if (!awsRegions) return [];
    return awsRegions.map((r) => {
      return {
        display: `${r.regionName} ${r.region}`,
        value: r.region,
      };
    });
  }, [awsRegions]);

  const isUserManagedVPC = useMemo(() => {
    return computePlane?.computeConfiguration?.isBYOVPC ?? false;
  }, [computePlane]);

  const handleSubmit = (data) => {
    const { nextStepId } = formWizard;
    const updateData = {
      awsRegion: data.awsRegion,
      awsAccountId: data.awsAccountId,
    };
    // We have to wipe the availability zones if you change the region since that is set elsewhere in this wizard and validated by the backend
    if (data.awsRegion !== computePlane?.awsRegion) {
      updateData.awsAvailabilityZones = null;
    }
    if (isUserManagedVPC) {
      updateData.vpcId = data.vpcId;
      // We have to wipe the subnets if you change any of these values
      if (
        data.vpcId !== computePlane?.awsCloudConfiguration?.vpcId ||
        data.awsRegion !== computePlane?.awsRegion ||
        data.awsAccountId !== awsAccountId
      ) {
        updateData.privateSubnets = null;
        updateData.publicSubnets = null;
      }
    }
    onNext({ data: updateData, nextStepId, isComputeConfigurationDirty: true });
  };

  const defaultVpcId = useMemo(() => {
    return computePlane?.awsCloudConfiguration?.vpcId;
  }, [computePlane]);

  const defaultAwsRegion = useMemo(() => {
    return computePlane?.awsRegion;
  }, [computePlane]);

  return (
    <Form.Form name={constants.stepIds.step2} submitAction={handleSubmit}>
      <Page.Box>
        <Page.BoxHeader>
          <h2>{display.title}</h2>
        </Page.BoxHeader>
        {error && <Banner title={error} scrollIntoView />}

        <Page.FormContent rows={3}>
          <div>
            <h3>{display.roleConfigurationHeader}</h3>
          </div>
          <div>
            <Form.TextInput
              name='awsAccountId'
              label={display.awsAccountIdLabel}
              validationRules={{
                required: display.awsAccountIdRequiredError,
                pattern: {
                  value: awsAccountIdRegex,
                  message: display.awsAccountIdPatternError,
                },
              }}
              fieldHelp={Help.AWSAccountId}
              disabled={submitInProgress}
              defaultValue={awsAccountId}
            />
            <Form.SelectInput
              name='awsRegion'
              label={display.awsRegionLabel}
              defaultValue={defaultAwsRegion}
              validationRules={{ required: display.awsRegionRequiredError }}
              items={awsRegionsSelectItems}
              disabled={submitInProgress}
              fieldHelp={Help.AWSRegion}
            />
            {isUserManagedVPC && (
              <Form.TextInput
                name='vpcId'
                label={display.vpcId}
                disabled={submitInProgress}
                validationRules={{
                  required: display.vpcIdRequiredError,
                  pattern: {
                    value: vpcIdRegex,
                    message: display.vpcIdPatternError,
                  },
                }}
                fieldHelp={Help.VpcId}
                defaultValue={defaultVpcId}
              />
            )}
          </div>
        </Page.FormContent>
      </Page.Box>
      <Page.Buttons>
        <Form.SecondaryButton
          label='Back'
          onClick={onBack}
          disabled={submitInProgress}
          disabledOnErrors={false}
        />
        <Form.Submit
          label='Next'
          disabled={submitInProgress}
          disabledOnErrors
          showLoading={submitInProgress}
        />
      </Page.Buttons>
    </Form.Form>
  );
};

CreateRole.defaultProps = {
  awsAccountId: null,
  awsRegions: null,
  computePlane: null,
  error: undefined,
  submitInProgress: false,
};

CreateRole.propTypes = {
  awsAccountId: string,
  awsRegions: propTypes.awsRegions,
  computePlane: shape({
    awsRegion: string,
  }),
  error: string,
  submitInProgress: bool,
  onBack: func.isRequired,
  onNext: func.isRequired,
};
