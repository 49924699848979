export const createApiAction = (
  actionType,
  api,
  requiresAuthentication,
  params,
  body,
  actionInfo,
) => {
  return {
    type: actionType.request,
    payload: {
      actionTypes: actionType,
      api,
      apiCallId: Date.now(),
      requiresAuthentication,
      params,
      body,
      actionInfo: actionInfo || { actionId: Date.now() },
    },
  };
};
