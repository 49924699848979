import { apiVerbs, createApiActionType } from '../../rayApi/actionTypes';

const componentName = 'PRESTO_USERS';
export const getAll = createApiActionType(apiVerbs.GET, `${componentName}_ALL`);
export const get = createApiActionType(apiVerbs.GET, componentName);
export const getPrestoClustersForPrestoUser = createApiActionType(
  apiVerbs.GET,
  `${componentName}_PRESTO_CLUSTERS`,
);
export const getDataSourcesForPrestoUser = createApiActionType(
  apiVerbs.GET,
  `${componentName}_DATA_SOURCES`,
);
export const patch = createApiActionType(apiVerbs.PATCH, componentName);
export const remove = createApiActionType(apiVerbs.DELETE, componentName);
