import { node } from 'prop-types';
import { ErrorBadgeSpan } from './styledComponents';

const ErrorBadge = ({ children }) => {
  return <ErrorBadgeSpan>{children || 'Error'}</ErrorBadgeSpan>;
};

ErrorBadge.defaultProps = {
  children: null,
};

ErrorBadge.propTypes = {
  children: node,
};

export default ErrorBadge;
