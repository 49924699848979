import { string } from 'prop-types';

const Download = ({ fillColor }) => {
  return (
    <svg viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.5 48c-1.2 0-2.25-.45-3.15-1.35C.45 45.75 0 44.7 0 43.5V32.775h4.5V43.5h39V32.775H48V43.5c0 1.2-.45 2.25-1.35 3.15-.9.9-1.95 1.35-3.15 1.35h-39ZM24 36.525 9.525 22.05l3.225-3.225 9 9V0h4.5v27.825l9-9 3.225 3.225L24 36.525Z'
        fill={fillColor}
        fillRule='nonzero'
      />
    </svg>
  );
};

Download.defaultProps = {
  fillColor: '#000000',
};

Download.propTypes = {
  fillColor: string,
};

export default Download;
