/* eslint-disable prettier/prettier */
import { string } from 'prop-types';

const Link = ({ fillColor }) => {
  return (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.33333333 4v2.66666667H2.66666667V21.3333333H17.3333333v-6.6666666H20v8C20 23.4030463 19.4030463 24 18.6666667 24H1.33333333C.59695367 24 0 23.4030463 0 22.6666667V5.33333333C0 4.59695367.59695367 4 1.33333333 4h8zM24 0v10.6666667h-2.6666667V4.55066667L10.9426667 14.9426667l-1.88533337-1.8853334L19.4466667 2.66666667h-6.1133334V0H24z'
        fill={fillColor}
        fillRule='nonzero'
      />
    </svg>
  );
};

Link.defaultProps = {
  fillColor: '#FFFFFF',
};

Link.propTypes = {
  fillColor: string,
};

export default Link;
