import { createSelector } from 'reselect';
import semverLessThan from 'semver/functions/lt';
import * as versioning from '../../utils/versioning';
import * as constants from './constants';
import logger from '../../utils/logger';

const getComputePlaneSelector = (state, props) => {
  if (!state.computePlane.computePlanes) return null;
  if (props?.computePlaneId) {
    return state.computePlane.computePlanes.get(props.computePlaneId);
  }
  const computePlanes = Array.from(state.computePlane.computePlanes.values());
  if (!computePlanes || computePlanes.length === 0) {
    return null;
  }
  computePlanes.sort((a, b) => {
    return new Date(b.createDate) - new Date(a.createDate);
  });

  return computePlanes[0];
};

export const getComputePlane = createSelector(
  getComputePlaneSelector,
  (computePlane) => {
    return computePlane;
  },
);

export const isComputePlaneDestroying = createSelector(
  getComputePlaneSelector,
  (computePlane) => {
    if (!computePlane) return false;

    return (
      computePlane.status === constants.status.destroying ||
      computePlane.status === constants.status.destroy_failure
    );
  },
);

export const isComputePlaneCreating = createSelector(
  getComputePlaneSelector,
  (computePlane) => {
    if (!computePlane) return true;

    return (
      computePlane.status === constants.status.init_failure ||
      computePlane.status === constants.status.initializing ||
      computePlane.status === constants.status.creating ||
      computePlane.status === constants.status.destroyed
    );
  },
);

export const isComputePlaneActive = createSelector(
  getComputePlaneSelector,
  (computePlane) => {
    if (!computePlane) return false;

    return (
      computePlane.status === constants.status.active ||
      computePlane.status === constants.status.patching ||
      computePlane.status === constants.status.upgrading
    );
  },
);

export const getAwsCloudFormationQuickCreateUrl = (state) => {
  return (
    state.computePlane.configuration &&
    state.computePlane.configuration.awsCloudFormationQuickCreateUrl
  );
};

export const getAwsCloudFormationTemplateS3Url = (state) => {
  return (
    state.computePlane.configuration &&
    state.computePlane.configuration.awsCloudFormationTemplateS3Url
  );
};

export const getControlPlaneAwsAccountId = (state) => {
  return (
    state.computePlane.configuration &&
    state.computePlane.configuration.ahanaAwsAccountId
  );
};

export const getAwsExternalId = (state) => {
  return (
    state.computePlane.configuration &&
    state.computePlane.configuration.awsExternalId
  );
};

export const getPermissionBoundaryPolicyContent = (state) => {
  if (!state.computePlane.configuration) return null;

  return state.computePlane.configuration.awsPermissionBoundaryPolicyContent;
};

export const getCoreInfrastructurePolicyContent = (state) => {
  if (!state.computePlane.configuration) return null;

  return state.computePlane.configuration.awsCoreInfrastructurePolicyContent;
};

export const getAwsRegions = (state) => {
  return (
    state.computePlane.configuration &&
    state.computePlane.configuration.awsRegions
  );
};

export const getError = (state) => {
  return state.computePlane.error;
};

export const getSubmitInProgress = (state) => {
  return state.computePlane.submitInProgress;
};

export const getActiveComputePlane = createSelector(
  getComputePlane,
  getAwsRegions,
  (computePlane, awsRegions) => {
    if (!computePlane) {
      return null;
    }

    if (
      computePlane.status === constants.status.active ||
      computePlane.status === constants.status.patching ||
      computePlane.status === constants.status.upgrading
    ) {
      const awsRegion = awsRegions?.find((a) => {
        return a.region === computePlane.awsRegion;
      });

      const display = {
        ...computePlane,
        awsRegionDisplay: `${awsRegion?.regionName} ${awsRegion?.region}`,
        awsAvailabilityZonesDisplay:
          computePlane.awsAvailabilityZones?.join(', '),
        isBYOVPC: computePlane.computeConfiguration?.isBYOVPC ?? false,
        privateSubnetsDisplay:
          computePlane.awsCloudConfiguration?.privateSubnetIds?.join(','),
        publicSubnetsDisplay:
          computePlane.awsCloudConfiguration?.publicSubnetIds?.join(','),
      };

      return display;
    }
    return null;
  },
);

export const getActiveComputePlaneId = createSelector(
  getActiveComputePlane,
  (computePlane) => {
    if (!computePlane) return null;

    return computePlane.computePlaneId;
  },
);

export const getAwsAccountId = createSelector(
  getActiveComputePlane,
  (computePlane) => {
    if (!computePlane) return null;

    return computePlane.awsRoleARN && computePlane.awsRoleARN.split(':')[4];
  },
);

export const getMissingInfrastructurePolicy = createSelector(
  getActiveComputePlane,
  (computePlane) => {
    if (computePlane?.provisioningPolicyVersions?.infrastructurePolicy)
      return !computePlane.provisioningPolicyVersions.infrastructurePolicy
        .version;

    return !computePlane?.provisioningPolicyVersions
      ?.infrastructurePolicyVersion;
  },
);

export const canDestroy = createSelector(
  getActiveComputePlane,
  getMissingInfrastructurePolicy,
  (computePlane, missingInfrastructurePolicy) => {
    if (!computePlane) return false;

    return !computePlane.hasActiveClusters && !missingInfrastructurePolicy;
  },
);

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getIamRoleUrl = createSelector(getComputePlane, (computePlane) => {
  if (!computePlane || !computePlane.awsRoleARN) return null;

  return `https://console.aws.amazon.com/iam/home#/roles/${
    computePlane.awsRoleARN.split('/')[1]
  }`;
});

export const getRoleCreationUrl = createSelector(
  getControlPlaneAwsAccountId,
  getAwsExternalId,
  (accountId, externalId) => {
    return `https://console.aws.amazon.com/iam/home?#/roles$new?step=permissions&roleType=crossAccount&isThirdParty&accountID=${accountId}&externalID=${externalId}`;
  },
);

const minimumComputePlaneVersion = {
  major: 7,
  minor: 0,
  patch: 0,
};

export const isComputePlaneGreaterThanMinimumVersion = createSelector(
  getActiveComputePlane,
  (computePlane) => {
    if (!computePlane) {
      return false;
    }
    try {
      const computePlaneVersion = versioning.parseVersion(
        computePlane.computePlaneVersion,
        true,
      );
      return versioning.greaterThanOrEqualTo(
        computePlaneVersion,
        minimumComputePlaneVersion,
      );
    } catch (error) {
      logger.error(error.message);
      return false;
    }
  },
);

export const getComputePlaneStatus = createSelector(
  getActiveComputePlane,
  (computePlane) => {
    if (!computePlane) {
      return false;
    }

    return computePlane.status;
  },
);

export const getPolicyType = (state, props) => {
  return props.policyType || props.params.policyType;
};

export const getShouldUpgradePolicy = (state) => {
  const computePlane = getActiveComputePlane(state);

  if (computePlane && !computePlane.provisioningPolicyVersions) {
    // policies haven't been checked yet
    return false;
  }

  return !computePlane?.provisioningPolicyVersions?.policiesCurrent;
};

export const getCloudFormationStacksListingUrl = createSelector(
  getComputePlane,
  (computePlane) => {
    if (!computePlane) return null;

    let region = computePlane.awsRegion;
    let deepLink = '';

    if (computePlane?.provisioningPolicyVersions?.cloudformationArn) {
      // eslint-disable-next-line prefer-destructuring
      region =
        computePlane.provisioningPolicyVersions.cloudformationArn.split(':')[3];
      deepLink = `/stackinfo?filteringText=&filteringStatus=active&viewNested=true&stackId=${computePlane.provisioningPolicyVersions.cloudformationArn}`;
    }
    return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks${deepLink}`;
  },
);

export const getComputePlaneUpdateDate = createSelector(
  getActiveComputePlane,
  (computePlane) => {
    if (!computePlane) return null;

    return computePlane.updateDate;
  },
);

export const getAwsAccountIdFromRoleArn = createSelector(
  getComputePlane,
  (computePlane) => {
    if (!computePlane) {
      return null;
    }

    return computePlane.awsRoleARN?.split(':')?.[4];
  },
);

export const isComputePlaneDestroyFailure = createSelector(
  getComputePlane,
  (computePlane) => {
    if (!computePlane) {
      return false;
    }

    return computePlane.status === constants.status.destroy_failure;
  },
);

export const getConfiguration = (state) => {
  return state.computePlane?.configuration;
};

export const getAwsAZsIDsByRegion = (state) => {
  return state.computePlane?.awsAZsIDsByRegion;
};

export const getAwsSubnets = (state) => {
  if (!state.computePlane?.awsSubnets) return null;

  const subnets = Array.from(state.computePlane?.awsSubnets?.values());

  return subnets?.sort((a, b) => {
    const subnetNameComparison = a.name?.localeCompare(b.name);
    if (subnetNameComparison) return subnetNameComparison;
    return a.id.localeCompare(b.id);
  });
};

export const getProvisioningRoleCFStackName = createSelector(
  getConfiguration,
  (configuration) => {
    if (!configuration) return null;

    const { awsCloudFormationQuickCreateUrl } = configuration;
    const stackName =
      awsCloudFormationQuickCreateUrl.match(/stackName=([^&]*)&/);
    return stackName && stackName[1];
  },
);

export const getProvisioningRoleTerraformFileName = createSelector(
  getComputePlane,
  (computePlane) => {
    if (!computePlane) return null;

    return `ibmlh-provisioning-role-${computePlane.computePlaneId}.tf`;
  },
);

export const getTerraformContent = createSelector(
  getConfiguration,
  (configuration) => {
    if (!configuration) return null;

    return configuration.terraformContent;
  },
);

export const getCloudFormationContent = createSelector(
  getConfiguration,
  (configuration) => {
    if (!configuration) return null;

    return configuration.cloudFormationTemplateContent;
  },
);

export const getRoleCreationMethod = createSelector(
  getComputePlane,
  (computePlane) => {
    if (!computePlane) return null;

    if (!computePlane.roleCreationMethod) return 'CloudFormation';
    return computePlane.roleCreationMethod;
  },
);

export const getCanUpgrade = createSelector(getComputePlane, (computePlane) => {
  return semverLessThan(computePlane.computePlaneVersion, '7.3.0');
});
