import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { string, bool } from 'prop-types';
import Loading from '../Loading';
import { ButtonWrapperDiv, SubmitInput } from './styledComponents';

const Submit = ({ label, disabled, showLoading, fullWidth }) => {
  const {
    formState: { errors: formErrors },
  } = useFormContext();
  const className1 = useMemo(() => {
    if (fullWidth && showLoading) {
      return 'full-width show-loading bx--btn bx--btn--primary';
    }
    if (fullWidth) {
      return 'full-width bx--btn bx--btn--primary';
    }
    if (showLoading) {
      return 'show-loading bx--btn bx--btn--primary';
    }
    return 'bx--btn bx--btn--primary';
  }, [showLoading, fullWidth]);

  return (
    <ButtonWrapperDiv>
      <SubmitInput
        type='submit'
        disabled={disabled || Object.keys(formErrors).length > 0}
        value={label}
        className={className1}
      />
      {showLoading && <Loading.CircleIcon />}
    </ButtonWrapperDiv>
  );
};

Submit.defaultProps = {
  disabled: false,
  showLoading: false,
  fullWidth: false,
};

Submit.propTypes = {
  label: string.isRequired,
  disabled: bool,
  showLoading: bool,
  fullWidth: bool,
};

export default Submit;
