import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as components from './components';
import Container from './Container';
import reducer from './reducer';
import saga from './saga';
import * as selectors from './selectors';
import * as route from './route';

const module = {
  actions,
  actionTypes,
  components,
  Container,
  reducer,
  route,
  saga,
  selectors,
};

export default module;
