import * as environment from '../utils/environment';
import * as actionTypes from './actionTypes';

export const initSearchParams = () => {
  let searchParam = window.location?.search;

  if (environment.isLocalUI()) {
    const searchParams = new URLSearchParams(searchParam ?? undefined);
    const crnParam = searchParams.get('crn');

    if (!crnParam) {
      const crn = document.cookie
        .split('; ')
        .find((row) => {
          return row.startsWith('ibmlh.dev.crn=');
        })
        ?.split('=')[1];
      if (crn) {
        searchParams.append('crn', crn);
        searchParam = searchParams.toString();
      }
    }
  }

  return {
    type: actionTypes.initSearchParams,
    payload: {
      search: searchParam ?? '',
    },
  };
};

export const createHistoryAction = (location, action) => {
  return {
    type: actionTypes.currentRouteChange,
    payload: {
      location: {
        ...location,
        state: { ...location.state },
      },
      action,
    },
  };
};
