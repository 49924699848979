import { isProductionUI, isStagingUI, isLocalUI } from '../utils/environment';
import telemeteryMiddleware from './telemeteryMiddleware';

const buildVersion =
  isProductionUI() || isStagingUI() ? process.env.REACT_APP_VERSION : 'unknown';

const enableTelemeteryForLocal = false;

const enableTelemeteryForStaging = true;

const enableTelemetery =
  isProductionUI() ||
  (enableTelemeteryForStaging && isStagingUI()) ||
  (enableTelemeteryForLocal && isLocalUI());

const middlewares = [];
if (enableTelemetery) {
  ineum('key', process.env.REACT_APP_INSTANA_TRAKING_KEY);
  ineum('reportingUrl', process.env.REACT_APP_INSTANA_REPORT_URL);
  ineum('trackSessions');
  ineum('meta', 'release', buildVersion);
  ineum('meta', 'environment', isLocalUI() ? 'local' : 'production');

  ineum('ignoreErrorMessages', [
    /'ResizeObserver loop limit exceeded'/i,
    /'Ably client in a bad state. Reason: Connection to server unavailable'/i,
    /'Ably client in a bad state. Reason: Client configured authentication provider request failed'/i,
    /'Ably client in a bad state. Reason: Token expired'/i,
    /'Ably client in a bad state. Reason: Transport superseded'/i,
    /'Ably client in a bad state. Reason: XHR error occurred (event type: error)'/i,
  ]);

  middlewares.push(telemeteryMiddleware());
}

const getTelemetryMiddleware = () => {
  return middlewares.slice();
};

const setUser = (userId, email, name, role, serviceTier, tenantId) => {
  // if (!enableTelemetery) return;
  // ineum('meta', { userId, email, name, role, serviceTier });
};

const clearUser = () => {
  // if (!enableTelemetery) return;
  // ineum('meta', { userid: '', email: '', name: '', role: '', serviceTier: '' });
};

const captureException = (exception) => {
  if (!enableTelemetery) return;
  ineum('reportError', exception);
};

const captureMessage = (message, data) => {
  if (!enableTelemetery) return;

  if (data) {
    const meta = { meta: data };
    ineum('reportEvent', message, meta);
    return;
  }
  ineum('reportEvent', message);
};

const module = {
  getTelemetryMiddleware,
  setUser,
  clearUser,
  captureException,
  captureMessage,
};

export default module;
