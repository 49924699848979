import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const getTenantSummary = (tenantId) => {
  return createApiAction(
    actionTypes.getTenantSummary,
    api.getTenantSummary,
    true,
    {
      tenantId,
    },
  );
};

export const getContract = (contractId) => {
  return createApiAction(actionTypes.getContract, api.getContract, true, {
    contractId,
  });
};

export const getClusters = (startDate, endDate) => {
  return createApiAction(actionTypes.getClusters, api.getClusters, true, {
    startDate,
    endDate,
  });
};
