import Help from '../../Help';

const instanceType = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Instance Type</Help.HelpHeader>
      <Help.P>The ec2 instance type dedicated to Prometheus.</Help.P>
    </Help.HelpSection>
  );
};

const remoteWrite = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Remote Write Rules</Help.HelpHeader>
      <Help.P>
        Prometheus configuration to allow compute plane Prometheus metrics to be
        consumed by an external metric server.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  instanceType,
  remoteWrite,
};

export default module;
