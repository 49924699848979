import { useCallback } from 'react';
import { bool, elementType, string } from 'prop-types';
import HelpIcon from '../../icons/Help';
import { onAccessibleClick } from '../utils/accessibility';
import { useHelpNavigationDispatch } from './HelpNavigation';
import { Button } from './styledComponents';

const HelpButton = ({ fieldHelp, ignoreNullDispatch, icon, className }) => {
  const helpDispatch = useHelpNavigationDispatch(ignoreNullDispatch);

  const onHelpClick = useCallback(
    (event) => {
      if (!fieldHelp || !helpDispatch) return;

      onAccessibleClick(event, () => {
        helpDispatch(fieldHelp);
      });
    },
    [fieldHelp, helpDispatch],
  );

  if (!fieldHelp || !helpDispatch) {
    return null;
  }

  const Icon = icon || HelpIcon;

  return (
    <Button onClick={onHelpClick} type='button' className={className}>
      <Icon />
    </Button>
  );
};

HelpButton.defaultProps = {
  fieldHelp: undefined,
  ignoreNullDispatch: false,
  icon: null,
  className: undefined,
};

HelpButton.propTypes = {
  fieldHelp: elementType,
  ignoreNullDispatch: bool,
  icon: elementType,
  className: string,
};

export default HelpButton;
