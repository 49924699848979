import Data from '../../Data';
import DataSources from '../../DataSources';

const display = {
  formContentHeader: 'Access',
  formExplaination: 'Secondary explanation',
  endpointLabel: 'Endpoint',
  connectionParametersLabel: 'Connection Parameters',
  userLabel: 'User',
  passwordLabel: 'Password',
};

const RedshiftConnectionDetails = ({
  'connection-user': user,
  'connection-password': password,
  endpoint,
  connectionParameters,
}) => {
  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
        {/* <div>{display.formExplaination}</div> */}
      </div>
      <div>
        <Data.DataField label={display.endpointLabel} value={endpoint} />
        <Data.DataField
          optional
          label={display.connectionParametersLabel}
          value={connectionParameters}
        />
        <Data.DataField label={display.userLabel} value={user} />
        <Data.DataField label={display.passwordLabel} value={password} />
      </div>
    </>
  );
};

RedshiftConnectionDetails.propTypes = DataSources.propTypes.RedshiftConnection;

export default RedshiftConnectionDetails;
