import { useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { unstable_usePrompt as usePrompt, useNavigate } from 'react-router-dom';
import Security from '../../Security';
import Page from '../../Page';
import useRouteBuilder from '../../../router/useRouteBuilder';
import AuthorizationServices from '../../AuthorizationServices';
import DataSources from '../../DataSources';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import AuthorizedDataSources from './AuthorizedDataSources';
import Details from './Details';
import DangerZone from './DangerZone';
import PrestoClusters from '../../PrestoClusters';

const display = {
  authorizedDataSources: 'Authorized Data Sources',
  details: 'Details',
  dangerZone: 'Danger Zone',
  confirmCancel: 'Are you sure? Your changes will be lost.',
};

const navTabs = [
  display.authorizedDataSources,
  display.details,
  display.dangerZone,
];

const AuthorizationService = ({
  authorizationService,
  authorizationServiceId,
  dataSources,
  actionStatus,
  notFound,
  usePop,
  loadAuthorizationService,
  loadDataSources,
  deleteAuthorizationService,
  loadPrestoClusters,
  prestoClusters,
}) => {
  useEffect(() => {
    loadAuthorizationService(authorizationServiceId);
    loadPrestoClusters(authorizationServiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationServiceId]);

  const [actionInProgress, setActionInProgress] = useState(false);

  const showPrompt = useCallback(() => {
    return actionInProgress;
  }, [actionInProgress]);

  usePrompt({ when: showPrompt, message: display.confirmCancel });

  const navigate = useNavigate();
  const securityRoute = useRouteBuilder(Security.route.getRoute);
  const notFoundRoute = useRouteBuilder('/notfound');

  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.success &&
      actionStatus.name === 'delete'
    ) {
      // nav back
      if (usePop) {
        navigate(-1);
      } else {
        navigate(securityRoute, { replace: true });
      }
    }
  }, [actionStatus, navigate, usePop, securityRoute]);

  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!authorizationService || notFound) {
    return <Page.Loading />;
  }

  return (
    <ActionInProgressStateContext.Provider value={actionInProgress}>
      <ActionInProgressSetStateContext.Provider value={setActionInProgress}>
        <Page.Page>
          <Page.InPageNavigation navTabs={navTabs}>
            <Page.PageHeaderWithNav>
              <div>
                <h1>{authorizationService.name}</h1>
              </div>
              <Page.PageNavigation navTabs={navTabs} />
            </Page.PageHeaderWithNav>
            <Page.Scroll>
              <AuthorizedDataSources
                navTarget={display.authorizedDataSources}
                authorizationServiceId={authorizationServiceId}
                dataSources={dataSources}
                loadDataSources={loadDataSources}
              />
              <Details
                navTarget={display.details}
                authorizationServiceId={authorizationServiceId}
                authorizationService={authorizationService}
                prestoClusters={prestoClusters}
              />
              <DangerZone
                navTarget={display.dangerZone}
                authorizationServiceId={authorizationServiceId}
                authorizationService={authorizationService}
                deleteAuthorizationService={deleteAuthorizationService}
                actionStatus={actionStatus}
                usePop={usePop}
              />
            </Page.Scroll>
          </Page.InPageNavigation>
        </Page.Page>
      </ActionInProgressSetStateContext.Provider>
    </ActionInProgressStateContext.Provider>
  );
};

AuthorizationService.defaultProps = {
  authorizationService: null,
  dataSources: null,
  actionStatus: undefined,
  notFound: false,
  usePop: true,
  prestoClusters: null,
};

AuthorizationService.propTypes = {
  authorizationServiceId: string.isRequired,
  authorizationService: AuthorizationServices.propTypes.AuthorizationService,
  dataSources: arrayOf(DataSources.propTypes.DataSource),
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  notFound: bool,
  usePop: bool,
  loadAuthorizationService: func.isRequired,
  loadDataSources: func.isRequired,
  deleteAuthorizationService: func.isRequired,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  loadPrestoClusters: func.isRequired,
};

export default AuthorizationService;
