import { takeEvery, call, put, all } from 'redux-saga/effects';
import { parse } from 'yaml';
import { bcryptPassword } from '../../utils/bcrypt';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import encryptData from '../../utils/encryptData';

const keyId = process.env.REACT_APP_KMS_BROWSER_ENCRYPT_KEY_ALIAS;
const encryptionAlgorithm = 'RSAES_OAEP_SHA_256';

function* encryptSensitiveFields(remoteWriteRule) {
  // Define a structure for fields to be encrypted
  const fieldsToEncrypt = [
    { path: ['basic_auth', 'password'] },
    { path: ['authorization', 'credentials'] },
    { path: ['sigv4', 'secret_key'] },
    { path: ['oauth2', 'client_secret'] },
    // Assuming this is the field requiring encryption, not sure which key should be encrypt
    { path: ['oauth2', 'proxy_connect_header'] },
    { path: ['azuread', 'oauth', 'client_secret'] },
  ];

  // Helper function to access nested object properties
  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce((obj, key) => {
      return obj && obj[key] !== undefined ? obj[key] : undefined;
    }, nestedObj);
  };

  // Helper function to set a value on a nested object using a path array
  const setNestedObjectValue = (obj, path, value) => {
    const cur = obj;
    if (path.length === 1) {
      cur[path[0]] = value;
    } else {
      const [first, ...rest] = path;
      if (!cur[first]) {
        cur[first] = {};
      }
      setNestedObjectValue(cur[first], rest, value);
    }
  };

  // Iterate over each field and encrypt if exists
  for (const field of fieldsToEncrypt) {
    const valueToEncrypt = getNestedObject(remoteWriteRule, field.path);
    if (valueToEncrypt) {
      const encryptedValue = yield call(
        encryptData,
        String(valueToEncrypt),
        keyId,
        encryptionAlgorithm,
      );
      setNestedObjectValue(remoteWriteRule, field.path, encryptedValue);
    }
  }
}

function* cancelableCreateOrUpdatePrometheus(action) {
  const { values, computePlaneId } = action.payload;

  // Construct the body of the request with the parsed YAML object or null
  const newBody = {
    instanceType: values.prometheus.instanceType,
    enableJMXMetrics: values.prometheus.enableJMX,
  };

  if (values.prometheus.website === 'enabled') {
    newBody.ingress = {
      username: values.prometheus.user,
      password: values.prometheus.password,
    };
  }

  if (values.prometheus.remoteWrite) {
    // Convert the YAML string to a JavaScript object
    newBody.remoteWriteRule = parse(values.prometheus.remoteWrite);
  }

  if (values.customTagKeys?.length > 0 && values.customTagValues?.length > 0) {
    const tags = {};
    values.customTagKeys.forEach((key, i) => {
      if (key) {
        tags[`${key}`] = values.customTagValues[i];
      }
    });
    newBody.customTags = tags;
  }

  const { ingress, remoteWriteRule } = newBody;

  // Encrypt ingress password
  if (ingress?.password) {
    const passwordHash = yield call(bcryptPassword, ingress.password);
    newBody.ingress.password = passwordHash;
  }

  // Encrypt sensitive fields in remoteWriteRule
  if (remoteWriteRule) {
    yield call(encryptSensitiveFields, remoteWriteRule);
  }

  // Proceed with updating the Prometheus configuration
  if (action.type === actionTypes.submit) {
    yield put(actions.put(computePlaneId, newBody));
  }
}

export default function* prometheusAddRootSaga() {
  yield all([
    takeEvery(actionTypes.submit, cancelableCreateOrUpdatePrometheus),
  ]);
}
