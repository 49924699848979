export const required = (
  getValues,
  type,
  errorMessage,
  additionalValidation,
) => {
  return (value) => {
    const { authorizationServiceType } = getValues();
    if (authorizationServiceType !== type) return true;
    if (!value) {
      return errorMessage;
    }
    if (additionalValidation) {
      return additionalValidation(value);
    }
    return true;
  };
};
