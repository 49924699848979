import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const deletePrometheus = (computePlaneId) => {
  return createApiAction(
    actionTypes.deletePrometheus,
    api.deletePrometheus,
    true,
    { computePlaneId },
  );
};

export const patchPrometheusJMX = (computePlaneId, enableJMXMetrics) => {
  return createApiAction(
    actionTypes.patchPrometheusJMX,
    api.patchPrometheusJMX,
    true,
    {
      computePlaneId,
    },
    { enableJMXMetrics },
  );
};
