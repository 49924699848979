import { call, put, takeEvery, select } from 'redux-saga/effects';
/* These imports are done like this to remove cyclic dependencies */
import { get as tenantGet } from '../components/AccountSetup/actions';
import {
  get as authorizationServiceGet,
  getDataSources as authorizationServiceGetDataSources,
  getPrestoClusters as authorizationServiceGetPrestoClusters,
} from '../components/AuthorizationServices/actions';
import { get as bucketGet } from '../components/Buckets/actions';
import {
  get as computePlaneGet,
  getConfiguration,
} from '../components/ComputePlane/actions';
import {
  canDestroy as canDestroyComputePlane,
  getActiveComputePlaneId,
} from '../components/ComputePlane/selectors';
import {
  get as dataSourceGet,
  getPrestoClusters as dataSourceGetPrestoClusters,
} from '../components/DataSources/actions';
import { getDataSource } from '../components/DataSources/selectors';
import { get as identityProviderGet } from '../components/IdentityProviders/actions';
import {
  get as prestoClusterGet,
  getDataSources as prestoClusterGetDataSources,
} from '../components/PrestoClusters/actions';
import { getPrestoClusters } from '../components/PrestoClusters/selectors';
import { get as prestoUserGet } from '../components/PrestoUsers/actions';
import logger from '../utils/logger';
import { callApi } from './saga';

const loadEntity = {
  AuthorizationService: function* load(publicId) {
    yield put(authorizationServiceGet(publicId));
    yield put(authorizationServiceGetDataSources(publicId));
  },
  Bucket: function* load(publicId) {
    yield put(bucketGet(publicId));
  },
  ComputePlane: function* load(publicId) {
    yield put(computePlaneGet(publicId));
    yield put(getConfiguration(publicId));
  },
  DataSource: function* load(publicId) {
    // Fetch data source and corresponding auth service id before update
    // NOTE: the below won't work sometimes, as there's a race condition where the
    // data source reload on the DataSource component reloads the data source and updates the store,
    // which results in the old auth id being lost as it's replaced by the new one
    let dataSource = yield select(getDataSource, { dataSourceId: publicId });
    if (dataSource) {
      const oldAuthorizationServiceId = dataSource.authorizationServiceId;

      if (oldAuthorizationServiceId) {
        // Fetch old auth service, and corresponding data sources and presto clusters
        yield put(authorizationServiceGet(oldAuthorizationServiceId));
        yield put(
          authorizationServiceGetDataSources(oldAuthorizationServiceId),
        );
        yield put(
          authorizationServiceGetPrestoClusters(oldAuthorizationServiceId),
        );
      }
    }

    yield call(callApi, dataSourceGet(publicId));

    // After fetching data source again, we find the new authorization service id
    dataSource = yield select(getDataSource, { dataSourceId: publicId });
    if (dataSource) {
      const newAuthorizationServiceId = dataSource.authorizationServiceId;

      if (newAuthorizationServiceId) {
        // Fetch new auth service, and corresponding data sources and presto clusters
        yield put(authorizationServiceGet(newAuthorizationServiceId));
        yield put(
          authorizationServiceGetDataSources(newAuthorizationServiceId),
        );
        yield put(
          authorizationServiceGetPrestoClusters(newAuthorizationServiceId),
        );
      }
    }

    yield put(dataSourceGetPrestoClusters(publicId));
  },
  IdentityProvider: function* load(publicId) {
    yield put(identityProviderGet(publicId));
  },
  PrestoCluster: function* load(publicId) {
    yield call(callApi, prestoClusterGet(publicId));
    yield put(prestoClusterGetDataSources(publicId));

    const clusters = yield select(getPrestoClusters);
    const canDestroyCP = yield select(canDestroyComputePlane);
    const computePlaneId = yield select(getActiveComputePlaneId);
    if (
      (canDestroyCP && clusters && clusters.length > 0) ||
      (!canDestroyCP && (!clusters || clusters.length === 0))
    ) {
      yield put(computePlaneGet(computePlaneId));
    }
  },
  PrestoUser: function* load(publicId) {
    yield put(prestoUserGet(publicId));
  },
  Tenant: function* load(publicId) {
    yield call(callApi, tenantGet(publicId));
    yield put(getConfiguration());
  },
};

function* realtimeApi(action) {
  const { message } = action.payload;
  const { entityType, changeType, entityPublicId } = message.data;

  if (changeType === 'create' || changeType === 'update') {
    if (!loadEntity[entityType]) {
      logger.error('Unknown load entity type', message);
      return;
    }
    yield call(loadEntity[entityType], entityPublicId);
  } else if (changeType === 'delete') {
    // for right now try using the 404 message
    yield call(loadEntity[entityType], entityPublicId);
  } else {
    logger.error('Unknown change type', message);
  }
}

export function* realtimeRootSaga() {
  yield takeEvery('REALTIME_CDC', realtimeApi);
}
