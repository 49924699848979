import logger from '../utils/logger';

const apiCallIdHeader = 'x-ibmlh-api-call-id';
const crnHeader = 'x-iam-crn';

export const buildHeaders = (authToken, apiCallId, crn) => {
  const headers = new Headers();
  headers.append('accept', 'application/json');
  headers.append('content-type', 'application/json');

  if (authToken) {
    headers.append('authorization', `Bearer ${authToken}`);
  }

  if (apiCallId) {
    headers.append(apiCallIdHeader, apiCallId);
  }

  if (crn) {
    headers.append(crnHeader, crn);
  }

  return headers;
};

export const buildBody = (body) => {
  return JSON.stringify(body);
};

const executeFetch = async (resource, init) => {
  let status;
  try {
    const response = await fetch(resource, init);
    status = response.status;
    // will eventually need to check this for content-type
    let body;
    if (status !== 204) {
      body = await response.json();
    }
    return {
      ok: response.ok,
      status,
      resource,
      body,
      headers: response.headers,
    };
  } catch (error) {
    const apiCallId = init.headers.get(apiCallIdHeader);
    if (error instanceof DOMException && error.name === 'AbortError') {
      logger.warn('fetch aborted', { apiCallId, status });
      return {
        ok: false,
        resource,
        body: {
          error,
          errorMessage: 'Fetch cancelled',
        },
      };
    }
    logger.error(error, { apiCallId, status });
    return {
      ok: false,
      status,
      resource,
      body: {
        error,
        errorMessage: 'Server error',
      },
    };
  }
};

export const executeGET = async (
  uri,
  headers,
  abortFetchSignal,
  includeCookies = true,
) => {
  const options = {
    method: 'GET',
    headers,
    signal: abortFetchSignal,
  };
  if (includeCookies) {
    options.credentials = 'include';
  }
  return executeFetch(uri, options);
};

export const executeDELETE = async (
  uri,
  headers,
  body,
  abortFetchSignal,
  includeCookies = true,
) => {
  let options = {
    method: 'DELETE',
    headers,
    signal: abortFetchSignal,
  };

  if (body) {
    options = { ...options, body };
  }

  if (includeCookies) {
    options.credentials = 'include';
  }

  return executeFetch(uri, options);
};

export const executePOST = async (
  uri,
  headers,
  body,
  abortFetchSignal,
  includeCookies = true,
) => {
  const options = {
    method: 'POST',
    headers,
    body,
    signal: abortFetchSignal,
  };

  if (includeCookies) {
    options.credentials = 'include';
  }

  return executeFetch(uri, options);
};

export const executePATCH = async (
  uri,
  headers,
  body,
  abortFetchSignal,
  includeCookies = true,
) => {
  const options = {
    method: 'PATCH',
    headers,
    body,
    signal: abortFetchSignal,
  };
  if (includeCookies) {
    options.credentials = 'include';
  }
  return executeFetch(uri, options);
};

export const executePUT = async (
  uri,
  headers,
  body,
  abortFetchSignal,
  includeCookies = true,
) => {
  const options = {
    method: 'PUT',
    headers,
    body,
    signal: abortFetchSignal,
  };

  if (includeCookies) {
    options.credentials = 'include';
  }

  return executeFetch(uri, options);
};
