import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Ready to create a Presto user?</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>Pick a username.</Help.ListItem>
        <Help.ListItem>Pick a password.</Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        This will create a Presto user you can add to Presto clusters in your
        compute plane.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
