import {
  string,
  func,
  bool,
  arrayOf,
  shape,
  element,
  oneOfType,
  elementType,
} from 'prop-types';
import Label from './Label';
import { RadioGroupDiv, RadioLabel } from './styledComponents';

const RadioField = ({
  label,
  options,
  value,
  disabled,
  isPrivate,
  fieldHelp,
}) => {
  return (
    <>
      {label && <Label label={label} fieldHelp={fieldHelp} />}
      <RadioGroupDiv>
        {options.map((option) => {
          const Component = option.component;
          return (
            <RadioLabel
              key={option.value}
              disabled={disabled || option.disabled}
              data-private={isPrivate ? true : undefined}
              className={option.value === value ? 'selected' : undefined}
            >
              {Component ? <Component /> : option.label}
            </RadioLabel>
          );
        })}
      </RadioGroupDiv>
    </>
  );
};

RadioField.defaultProps = {
  label: undefined,
  value: undefined,
  disabled: false,
  isPrivate: false,
  fieldHelp: undefined,
};

RadioField.propTypes = {
  label: string,
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
      component: oneOfType([element, func]),
    }),
  ).isRequired,
  value: string,
  disabled: bool,
  isPrivate: bool,
  fieldHelp: elementType,
};

export default RadioField;
