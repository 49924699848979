import Loading from './Loading';
import Spinner from './Spinner';
import CircleIcon from './CircleIcon';
import SmallLoading from './SmallLoading';

const module = {
  Loading,
  Spinner,
  CircleIcon,
  SmallLoading,
};

export default module;
