import AlertIcon from '../../../icons/Alert';
import { FooterDiv, FooterBoxDiv } from './styledComponents';

const ComputePlaneLowerThanMinimumVersion = () => {
  return (
    <FooterDiv className='warning'>
      <FooterBoxDiv className='warning'>
        <div className='icon'>
          <AlertIcon />
        </div>
        <div className='message'>
          <h3>Your compute plane version is no longer supported.</h3>
          <div>
            Please use IBM Cloud{' '}
            <a
              href='https://cloud.ibm.com/unifiedsupport/supportcenter/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Support Center
            </a>{' '}
            to create a support case.
          </div>
        </div>
      </FooterBoxDiv>
    </FooterDiv>
  );
};

export default ComputePlaneLowerThanMinimumVersion;
