import { number } from 'prop-types';
import { useMemo } from 'react';

const daysMs = 1000 * 60 * 60 * 24;
const hoursMs = 1000 * 60 * 60;
const minutesMs = 1000 * 60;
const secondsMs = 1000;

const getTimespan = (milliseconds) => {
  const timespan = {
    days: Math.floor(milliseconds / daysMs),
    hours: Math.floor((milliseconds / hoursMs) % 24),
    minutes: Math.floor((milliseconds / minutesMs) % 60),
    seconds: Math.floor((milliseconds / secondsMs) % 60),
  };

  return timespan;
};

const Timespan = ({ milliseconds }) => {
  const timespan = useMemo(() => {
    return getTimespan(milliseconds);
  }, [milliseconds]);

  let duration = '';
  if (timespan.days > 0) {
    duration = `${timespan.days}d`;
  }

  if (timespan.hours || duration) {
    if (duration) {
      duration = `${duration} `;
    }
    duration = `${duration}${timespan.hours}h`;
  }

  if (timespan.minutes || duration) {
    if (duration) {
      duration = `${duration} `;
    }
    duration = `${duration}${timespan.minutes}m`;
  }

  if (!duration) {
    duration = '0m';
  }
  return duration;
};

Timespan.propTypes = {
  milliseconds: number.isRequired,
};

export default Timespan;
