import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as api from './api';
import * as saga from './saga';
import * as urls from './urls';

const module = {
  actions,
  actionTypes,
  api,
  saga,
  urls,
};

export default module;
