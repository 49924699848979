import styled from 'styled-components/macro';
import { colors, fonts } from '../../../css';

export const ClusterSettingsConcurrencyDivs = styled.div`
  grid-area: 3 / 1 / 3 / 3;

  > div:last-child {
    display: grid;
    grid-template-columns: minmax(calc(50% - 24px), 350px) minmax(
        calc(50% - 24px),
        50%
      );
    grid-column-gap: 24px;
    margin-left: 0;
    margin-bottom: 7px;
    width: 100%;

    > label {
      ${fonts.medium_bold}
      letter-spacing: 0;
      color: ${colors.font_light};
      padding: 0;
      border: 1px solid ${colors.border};
      border-radius: 4px;
      background-position: right 16px top 16px;

      &:after {
        display: none;
      }

      .details {
        ${fonts.medium}
      }
    }
  }
`;

export const ClusterSettingsConcurrencyDiv = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: left;
  padding: 4px 24px 24px 24px;

  div {
    margin-top: 20px;
    text-align: left;
  }
`;

export const NoDataDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;
