import styled from 'styled-components/macro';
import Page from '../../Page';
import { colors } from '../../../css';

export const NoDataDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;

export const DetailsHeader = styled(Page.BoxHeader)`
  display: flex;
  flex-flow: row;
  justify-content: stretch;

  h2 {
    flex: 1 0 auto;
  }
`;
