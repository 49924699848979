import { createSelector } from 'reselect';
import AccountSetup from '../AccountSetup';
import * as BucketSelectors from '../Buckets/selectors';
import PrestoClusters from '../PrestoClusters';

export const getDataSourceId = (state, props) => {
  return props.dataSourceId || props.params.dataSourceId;
};

const dataSourcesSelector = (state) => {
  return state.dataSources.dataSources;
};

const removeNotFound = (dataSource) => {
  if (dataSource === 'not found') {
    return null;
  }
  return dataSource;
};

export const getDataSources = createSelector(
  dataSourcesSelector,
  (dataSources) => {
    if (!dataSources) return null;
    let dataSourcesArray = Array.from(dataSources.values(), removeNotFound);
    dataSourcesArray = dataSourcesArray.filter((b) => {
      return b !== null;
    });
    return dataSourcesArray;
  },
);

export const getDataSource = (state, props) => {
  const dataSourceId = getDataSourceId(state, props);
  if (!state.dataSources.dataSources) {
    return null;
  }
  const ds = state.dataSources.dataSources.get(dataSourceId);
  if (ds === 'not found') {
    return null;
  }
  return ds;
};

export const getIsNotFound = (state, props) => {
  const dataSourceId = getDataSourceId(state, props);
  if (!state.dataSources.dataSources) {
    return null;
  }
  const ds = state.dataSources.dataSources.get(dataSourceId);
  if (ds === 'not found') {
    return true;
  }
  return false;
};

export const getError = (state) => {
  return state.dataSources.error;
};

export const getLoadingInProgress = (state) => {
  return state.dataSources.loadingInProgress;
};

export const getActionStatus = (state, props) => {
  const dataSourceId = getDataSourceId(state, props);
  const status = state.dataSources.actionStatus.get(dataSourceId);
  return status;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

const getPrestoClusterIds = (state, props) => {
  if (!state.dataSources.dataSourcesPrestoClusterIds) {
    return null;
  }

  const dataSourceId = getDataSourceId(state, props);
  return state.dataSources.dataSourcesPrestoClusterIds.get(dataSourceId);
};

const getPrestoClustersMap = (state) => {
  return state.prestoClusters.prestoClusters;
};

export const getPrestoClusters = createSelector(
  getPrestoClusterIds,
  getPrestoClustersMap,
  (prestoClusterIds, prestoClusters) => {
    if (!prestoClusterIds) {
      return null;
    }

    if (prestoClusterIds.length === 0) {
      return [];
    }

    let results = prestoClusterIds.map((id) => {
      return removeNotFound(prestoClusters.get(id));
    });

    results = results.filter((pc) => {
      return !!pc;
    });

    return results;
  },
);

export const getLoadingFinished = createSelector(
  getLoadingInProgress,
  getDataSources,
  BucketSelectors.getLoadingInProgress,
  BucketSelectors.getBuckets,
  (dataSourcesLoading, dataSources, bucketsLoading, buckets) => {
    return !(
      (dataSourcesLoading && (!dataSources || dataSources.length === 0)) ||
      (bucketsLoading && (!buckets || buckets.length === 0))
    );
  },
);

export const getCanModify = createSelector(
  getDataSource,
  getPrestoClusters,
  AccountSetup.selectors.isSubscriptionValid,
  (dataSource, prestoClusters, isSubscriptionValid) => {
    if (!isSubscriptionValid) {
      return false;
    }

    if (!dataSource) {
      return false;
    }

    if (!dataSource.isAttached) {
      return true;
    }

    if (!prestoClusters && dataSource.isAttached) {
      return false;
    }

    if (dataSource.isAttached) {
      const pcInvalidStatus = prestoClusters.filter((pc) => {
        return (
          (pc.status !== PrestoClusters.constants.status.active &&
            pc.status !== PrestoClusters.constants.status.inactive) ||
          pc.workflowInProgress
          // pc.status !== PrestoClusters.constants.status.inactive_failure &&
          // pc.status !== PrestoClusters.constants.status.start_failure &&
          // pc.status !== PrestoClusters.constants.status.restart_failure &&
          // pc.status !== PrestoClusters.constants.status.stop_failure
        );
      });

      if (pcInvalidStatus.length > 0) {
        return false;
      }
    }

    return true;
  },
);
