import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import * as propTypes from './propTypes';
import Container from './Container';
import reducer from './reducer';
import * as routes from './routes';
import * as selectors from './selectors';
import * as api from './api';

const module = {
  actions,
  actionTypes,
  constants,
  propTypes,
  Container,
  reducer,
  routes,
  selectors,
  api,
};

export default module;
