import StopIcon from '../../../icons/Stop';
import Links from '../../Page/Links';
import { FooterDiv, FooterBoxDiv } from './styledComponents';

const AWSUnsubscribed = () => {
  return (
    <FooterDiv className='error'>
      <FooterBoxDiv className='error'>
        <div className='icon'>
          <StopIcon />
        </div>
        <div className='message'>
          <h3>You are unsubscribed from IBM watsonx.data Cloud</h3>
          <div>
            In order to continue using the service, you will need to resubscribe
            in the AWS Marketplace.
          </div>
        </div>
        <div>
          <Links.TertiaryLink
            to={process.env.REACT_APP_MARKETPLACE_URL}
            external
            newTab
          >
            Go to AWS Marketplace
          </Links.TertiaryLink>
        </div>
      </FooterBoxDiv>
    </FooterDiv>
  );
};

export default AWSUnsubscribed;
