import { useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { unstable_usePrompt as usePrompt, useNavigate } from 'react-router-dom';
import DataSources from '../../DataSources';
import PrestoClusters from '../../PrestoClusters';
import Page from '../../Page';
import useRouteBuilder from '../../../router/useRouteBuilder';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import AttachedClusters from './AttachedClusters';
import ConnectionDetails from './ConnectionDetails';
import PrestoUsers from './PrestoUsers';
import DangerZone from './DangerZone';

const display = {
  attachedClusters: 'Attached Clusters',
  details: 'Details',
  databases: 'Databases',
  dangerZone: 'Danger zone',
  confirmCancel: 'Are you sure? Your changes will be lost.',
};

const navTabs = [
  display.attachedClusters,
  display.details,
  // display.databases,
  display.dangerZone,
];

const DataSource = ({
  loadDataSource,
  dataSourceId,
  dataSource,
  notFound,
  deleteDataSource,
  actionStatus,
  prestoClusters,
  loadPrestoClusters,
  usePop,
  canModify,
}) => {
  useEffect(() => {
    loadDataSource(dataSourceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceId]);

  const [actionInProgress, setActionInProgress] = useState(false);

  const showPrompt = useCallback(() => {
    return actionInProgress;
  }, [actionInProgress]);

  usePrompt({ when: showPrompt, message: display.confirmCancel });

  const navigate = useNavigate();
  const dataSourcesRoute = useRouteBuilder(DataSources.routes.getRoute);
  const notFoundRoute = useRouteBuilder('/notfound');

  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.success &&
      actionStatus.name === 'delete'
    ) {
      // nav back
      if (usePop) {
        navigate(-1);
      } else {
        navigate(dataSourcesRoute, { replace: true });
      }
    }
  }, [actionStatus, navigate, usePop, dataSourcesRoute]);

  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!dataSource || notFound) {
    return <Page.Loading />;
  }

  return (
    <ActionInProgressStateContext.Provider value={actionInProgress}>
      <ActionInProgressSetStateContext.Provider value={setActionInProgress}>
        <Page.Page>
          <Page.InPageNavigation navTabs={navTabs}>
            <Page.PageHeaderWithNav>
              <div>
                <h1>{dataSource.name}</h1>
              </div>
              <Page.PageNavigation navTabs={navTabs} />
            </Page.PageHeaderWithNav>
            <Page.Scroll>
              <AttachedClusters
                navTarget={display.attachedClusters}
                prestoClusters={prestoClusters}
                loadPrestoClusters={loadPrestoClusters}
                dataSourceId={dataSourceId}
              />
              <ConnectionDetails
                dataSourceType={dataSource.type}
                connection={dataSource.connection}
                metadata={dataSource.metadata}
                catalogName={dataSource.catalogName}
                description={dataSource.description}
                navTarget={display.details}
                dataSourceId={dataSourceId}
                dataSource={dataSource}
                canModify={canModify}
                errorMessages={dataSource.errorMessages}
              />
              {dataSource.prestoUserMappings &&
                dataSource.prestoUserMappings.length > 0 && (
                  <PrestoUsers
                    dataSourceType={dataSource.type}
                    prestoUserMappings={dataSource.prestoUserMappings}
                  />
                )}
              <DangerZone
                navTarget={display.dangerZone}
                dataSourceId={dataSourceId}
                dataSource={dataSource}
                deleteDataSource={deleteDataSource}
                actionStatus={actionStatus}
                usePop={usePop}
              />
            </Page.Scroll>
          </Page.InPageNavigation>
        </Page.Page>
      </ActionInProgressSetStateContext.Provider>
    </ActionInProgressStateContext.Provider>
  );
};

DataSource.defaultProps = {
  dataSource: null,
  notFound: false,
  actionStatus: undefined,
  usePop: true,
  prestoClusters: [],
  canModify: true,
};

DataSource.propTypes = {
  loadDataSource: func.isRequired,
  dataSourceId: string.isRequired,
  dataSource: DataSources.propTypes.DataSource,
  notFound: bool,
  deleteDataSource: func.isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  loadPrestoClusters: func.isRequired,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  usePop: bool,
  canModify: bool,
};

export default DataSource;
