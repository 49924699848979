import { connect } from '../../utils/redux';
import PrestoClusters from '../PrestoClusters';
import { ChangeEnableSpot } from './components';

const mapStateToProps = (state, props) => {
  return {
    prestoClusterId: PrestoClusters.selectors.getPrestoClusterId(state, props),
    spotEnabled: PrestoClusters.selectors.getPrestoClusterSpotEnabled(
      state,
      props,
    ),
    enableASGCapacityRebalance:
      PrestoClusters.selectors.getEnableASGCapacityRebalance(state, props),
    status: PrestoClusters.selectors.getPrestoClusterStatus(state, props),
    name: PrestoClusters.selectors.getPrestoClusterName(state, props),
    notFound: PrestoClusters.selectors.getIsNotFound(state, props),
    actionStatus: PrestoClusters.selectors.getActionStatus(state, props),
    usePop: PrestoClusters.selectors.getUsePop(state),
    workflowInProgress:
      PrestoClusters.selectors.getPrestoClusterWorkflowInProgress(state, props),
  };
};

export default connect(mapStateToProps, {
  loadPrestoCluster: PrestoClusters.actions.get,
  saveEnableSpot: PrestoClusters.actions.putEnableSpot,
})(ChangeEnableSpot);
