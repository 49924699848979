import Help from '../../Help';

const Section = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Postgresql</Help.HelpHeader>
      <Help.P>something</Help.P>
    </Help.HelpSection>
  );
};

const Endpoint = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Endpoint</Help.HelpHeader>
      <Help.P>
        The endpoint to the PostgreSQL instance (e.g.
        database-1.abcd.us-east-1.rds.amazonaws.com). This endpoint needs to be
        accessible from the VPC where the Presto clusters are running.
      </Help.P>
    </Help.HelpSection>
  );
};

const Port = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Port</Help.HelpHeader>
      <Help.P>
        The port for the PostgreSQL instance (the default port for PostgreSQL is
        5432).
      </Help.P>
    </Help.HelpSection>
  );
};

const ConfigurationProperties = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Configuration Properties</Help.HelpHeader>
      <Help.P>
        Additional properties which can be used to specify additional driver
        behavior specific to PostgreSQL (e.g. readOnly=true&loggerLevel=DEBUG).
        See{' '}
        <a
          href='https://jdbc.postgresql.org/documentation/head/connect.html'
          target='_blank'
          rel='noopener noreferrer'
        >
          https://jdbc.postgresql.org/documentation/head/connect.html
        </a>{' '}
        for more details.
      </Help.P>
    </Help.HelpSection>
  );
};

const Database = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Database Name</Help.HelpHeader>
      <Help.P>
        The name of the database to connect to. If you need to access multiple
        databases on this database server, please create additional data sources
        with the same endpoint and port and a different database name.
      </Help.P>
    </Help.HelpSection>
  );
};

const User = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>User</Help.HelpHeader>
      <Help.P>The database username.</Help.P>
    </Help.HelpSection>
  );
};

const Password = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Password</Help.HelpHeader>
      <Help.P>The password for the database user.</Help.P>
    </Help.HelpSection>
  );
};

const module = {
  Section,
  Endpoint,
  Port,
  ConfigurationProperties,
  Database,
  User,
  Password,
};

export default module;
