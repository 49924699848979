import { string, bool } from 'prop-types';
import { ConnectionDetailsHeader as Header } from './styledComponents';
import Page from '../../Page';
import DataSources from '../../DataSources';
import { useEnableFeature } from '../../utils/featureHooks';

const display = {
  modifyDataSourcesButton: 'Modify Data Source',
};

const ConnectionDetailsHeader = ({ title, dataSourceId, canModify }) => {
  const enableManageLink = useEnableFeature();

  return (
    <Header>
      <h2>{title}</h2>
      <div className='buttons'>
        <Page.PrimaryLink
          to={DataSources.routes.getUpdateDataSourceRoute(dataSourceId)}
          disabled={!canModify || !enableManageLink}
        >
          {display.modifyDataSourcesButton}
        </Page.PrimaryLink>
      </div>
    </Header>
  );
};

ConnectionDetailsHeader.defaultProps = {
  canModify: true,
};

ConnectionDetailsHeader.propTypes = {
  title: string.isRequired,
  dataSourceId: string.isRequired,
  canModify: bool,
};

export default ConnectionDetailsHeader;
