import { useMemo } from 'react';
import { string, bool, elementType } from 'prop-types';
import { CheckboxLabelDiv, CheckboxLabel } from './styledComponents';

const CheckboxField = ({ label, value, disabled, isPrivate, fieldHelp }) => {
  const className = useMemo(() => {
    let c = '';
    if (label) {
      c = `${c} hasLabel`;
    }
    if (value === true) {
      c = `${c} checked`;
    }
    return c !== '' ? c : undefined;
  }, [label, value]);

  return (
    <CheckboxLabelDiv className={fieldHelp ? 'hasFieldHelp' : undefined}>
      <CheckboxLabel
        className={className}
        disabled={disabled ? true : undefined}
        data-private={isPrivate ? true : undefined}
        checked={value === true ? true : undefined}
      >
        {label}
      </CheckboxLabel>
    </CheckboxLabelDiv>
  );
};

CheckboxField.defaultProps = {
  label: undefined,
  value: undefined,
  disabled: false,
  isPrivate: false,
  fieldHelp: undefined,
};

CheckboxField.propTypes = {
  label: string,
  value: bool,
  disabled: bool,
  isPrivate: bool,
  fieldHelp: elementType,
};

export default CheckboxField;
