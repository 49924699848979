import { cloneElement, useCallback } from 'react';
import {
  string,
  node,
  number,
  arrayOf,
  shape,
  oneOfType,
  instanceOf,
  func,
} from 'prop-types';
import { useLiveQuery } from 'dexie-react-hooks';
import db from '../../indexedDB';
import logger from '../../utils/logger';

const dismiss = async (componentName, key) => {
  await db.dismissible.put({ componentName, key });
};

const undismiss = async (componentName, key) => {
  return db.dismissible
    .where({ componentName, key })
    .delete()
    .then((deleteCount) => {
      logger.info(
        `Undismiss deleted ${deleteCount} entries for ${componentName}+${key}`,
      );
      return true;
    });
};

const reset = async (componentName) => {
  return db.dismissible
    .where({ componentName })
    .delete()
    .then((deleteCount) => {
      logger.info(`Reset cleared ${deleteCount} entries for ${componentName}`);
      return true;
    });
};

const Dismissible = ({ componentName, data, children }) => {
  const filteredData = useLiveQuery(async () => {
    const dataKeys = data
      ? data.map((d) => {
          return [componentName, d.key];
        })
      : [[componentName, '']];
    const dismissedValues = await db.dismissible
      .where('[componentName+key]')
      .anyOf(dataKeys)
      .toArray();

    if (!dismissedValues || dismissedValues.length === 0) {
      return data || [];
    }

    if (!data) {
      return null;
    }

    const dismissedKeys = dismissedValues.map((d) => {
      return d.key;
    });
    const remainingData = data.filter((d) => {
      return !dismissedKeys.includes(d.key);
    });
    return !remainingData || remainingData.length === 0 ? null : remainingData;
  }, [componentName, data]);

  const dismissed = filteredData === null;

  const onDismiss = useCallback(
    (key) => {
      if (!data) {
        return dismiss(componentName, '');
      }
      return dismiss(componentName, key);
    },
    [componentName, data],
  );
  const onUndismiss = useCallback(
    (key) => {
      if (!data) {
        return undismiss(componentName, '');
      }
      return undismiss(componentName, key);
    },
    [componentName, data],
  );
  const onReset = useCallback(() => {
    return reset(componentName);
  }, [componentName]);

  const newProps = {
    data: filteredData,
    dismissed,
    onDismiss,
    onUndismiss,
    onReset,
  };

  if (typeof children === 'function') {
    return children(newProps);
  }

  return cloneElement(children, newProps);
};

Dismissible.defaultProps = {
  data: null,
};

Dismissible.propTypes = {
  componentName: string.isRequired,
  data: arrayOf(
    shape({
      key: oneOfType([string, number, instanceOf(Date)]).isRequired,
    }),
  ),
  children: oneOfType([node, func]).isRequired,
};

export default Dismissible;
