import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';
import * as environment from '../../utils/environment';

// This api is used to update versions in metadataserviceRay and store versions in db
export const updateVersions = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `computeplanes/${params.computePlaneId}/prestoVersions`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};

export const getVersions = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  let baseUri = rootUri;
  // Local environment is not using nginx right now so we have to route appropriately.
  if (environment.isLocalhost()) {
    baseUri = `${window.location.protocol}//localhost:3014/`;
  }
  const path = `cumulus/computeplanes/${params.computePlaneId}/prestoVersions`;
  const uri = buildUrl({ baseUrl: baseUri, path });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};
