import { NavLink, useLocation } from 'react-router-dom';
import routes from '../../../router/routes';
import { PublicNavigationDiv } from './styledComponents';

const blurClicks = (event) => {
  if (event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) {
    event.currentTarget.blur();
  }
};

const PublicNavigation = () => {
  const location = useLocation();

  return (
    <PublicNavigationDiv className='nav'>
      <NavLink
        to={{ pathname: routes.signIn.getRoute(), state: location.state }}
        className='nav_link'
        onClick={blurClicks}
      >
        Sign In
      </NavLink>
    </PublicNavigationDiv>
  );
};

export default PublicNavigation;
