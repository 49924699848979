import styled from 'styled-components/macro';
import RadioButtonMarker from '../../img/radio_button.svg';
import RadioButtonSelectedMarker from '../../img/radio_button_selected.svg';
import CheckboxMarker from '../../img/checkbox.svg';
import CheckboxSelectedMarker from '../../img/checkbox_selected.svg';
import { fonts, colors, buttons } from '../../css';
import Icons from '../../icons';

export const FieldRowDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: stretch;

  > div {
    flex: 1 1 0;
    margin-right: 30px;
    display: flex;
    flex-flow: column;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Label = styled.label`
  ${fonts.medium_bold}
  color: ${colors.font_light};
  margin-bottom: 15px;

  > span {
    margin-left: 10px;
    vertical-align: 2px;
  }
`;

export const LabelDiv = styled.div`
  margin-bottom: 7px;
  display: flex;
  justify-content: space-around;

  > ${Label} {
    margin-bottom: 8px;
    flex-grow: 1;
  }
`;

export const SecondLabelDiv = styled.div`
  ${fonts.medium_bold}
  color: ${colors.font_light};
`;

export const TextInputDiv = styled.div`
  display: flex;

  &.secondLabel {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 35% auto;
    align-items: baseline;
    column-gap: 22px;
  }

  &.hidden {
    display: none;
  }
`;

export const TextInput = styled.input`
  flex: 1 1 0;
  ${fonts.medium}
  letter-spacing: 0.16px;
  color: ${colors.font_light};
  caret-color: ${colors.input_focus};
  outline: 0;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  background-color: ${colors.section_background};
  box-shadow: 0 2px 1px 0 ${colors.input_shadow};
  padding: 9px 16px;
  margin-bottom: 24px;

  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill::first-line {
    ${fonts.medium}
    color: ${colors.font_light} !important;
    background-color: ${colors.section_background} !important;
    transition: background-color 50000s ease-in-out 0s;

    &:disabled {
      opacity: 0.54;
    }
  }

  ::placeholder {
    color: purple;
    opacity: 0.54;
  }

  &:disabled {
    opacity: 0.54;
  }

  &:focus {
    border-color: ${colors.input_focus};
    outline: 0;
    box-shadow: 0 2px 1px 0 ${colors.input_focus_shadow};
  }

  &.error {
    border-color: ${colors.error};
    margin-bottom: 3px;
  }
`;

export const PasswordInput = styled(TextInput)`
  flex-grow: 1;
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
  box-shadow: none;

  &.hide {
    font-family: text-security-disc;
    -webkit-text-security: disc;
  }

  &:focus {
    box-shadow: none;
  }

  & + button {
    height: 42px;
    padding-top: 6px;
    flex-grow: 0;
    background-color: ${colors.section_background};
    border: 0;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    svg {
      width: 24px;
      height: 24px;
      fill: ${colors.white};
    }
  }
`;

export const PasswordWrapper = styled.div`
  display: flex;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  box-shadow: 0 2px 1px 0 ${colors.input_shadow};
  margin-bottom: 24px;

  &.error {
    border-color: ${colors.error};
    margin-bottom: 3px;
  }

  &.hidden {
    display: none;
  }

  &:focus-within {
    border-color: ${colors.input_focus};
    outline: 0;
    box-shadow: 0 2px 1px 0 ${colors.input_focus_shadow};
  }
`;

export const InputErrorDiv = styled.div`
  ${fonts.medium_bold}
  color: ${colors.error};
  margin-bottom: 15px;
  margin-left: 24px;
  position: relative;
  white-space: pre-wrap;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: -24px;
    top: 2px;
    background-color: ${colors.error};
    mask-image: ${Icons.dataUri.Alert};
    -webkit-mask-image: ${Icons.dataUri.Alert};
  }
`;

export const HorizontalInputDiv = styled.div`
  align-self: center;
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
  margin-right: 11px;
`;

const BaseButtonWrapperDiv = styled.div`
  position: relative;
  display: inline-block;
`;

export const ButtonWrapperDiv = styled(BaseButtonWrapperDiv)`
  & + ${BaseButtonWrapperDiv} {
    margin-left: 11px;
  }
  & .bx--loading--small {
    position: absolute;
    right: 19px;
    top: 15px;
    width: 18px;
    height: 18px;
  }
`;

export const UpdateButtonWrapperDiv = styled(BaseButtonWrapperDiv)`
  & + ${BaseButtonWrapperDiv} {
    margin-left: 11px;
  }
  & .bx--loading--small {
    position: absolute;
    right: 19px;
    top: 15px;
    width: 18px;
    height: 18px;
  }
`;

const Button = styled.button`
  ${buttons.baseButton}

  &.show-loading {
    padding-right: 35px;
    & + svg {
      width: 22px;
      fill: ${colors.font_light};
      position: absolute;
      right: 7px;
      top: 12px;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  ${buttons.primaryButton}

  &.show-loading {
    padding-right: 40px;
    & + svg {
      width: 22px;
      margin-top: 15px;
      fill: ${colors.primary_button_font};
      position: absolute;
      right: 19px;
    }

    &:disabled,
    &[disabled] {
      & + svg {
        fill: ${colors.primary_button_font};
      }
    }
  }
`;

export const SecondaryButton = styled(Button)`
  ${buttons.secondaryButton}
`;

export const TertiaryButton = styled(Button)`
  ${buttons.tertiaryButton}

  &.show-loading {
    padding-right: 40px;
    & + svg {
      width: 22px;
      margin-top: 15px;
      fill: ${colors.tertiary_button_font};
      position: absolute;
      right: 19px;
    }

    &:disabled,
    &[disabled] {
      & + svg {
        fill: ${colors.font_disabled};
      }
    }
  }
`;

export const ErrorButton = styled(Button)`
  ${buttons.errorButton}
`;

export const SuccessButton = styled(Button)`
  ${buttons.successButton}
`;

export const WarningButton = styled(Button)`
  ${buttons.warningButton}
`;

export const CopyButtonWrapperDiv = styled.div`
  button {
    display: flex;
  }

  button svg {
    fill: #000000;
    height: 18px;
    margin-right: 5px;
    align-self: center;
  }
`;

export const SubmitInput = styled.input`
  padding: 10px 20px;
  min-height: 3rem;
  font-weight: 700;
  font-size: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  &.full-width {
    width: 100%;
    max-width: unset;
  }

  &.show-loading {
    padding-right: 40px;
    & + svg {
      width: 22px;
      margin-top: 12px;
      fill: ${colors.font_light};
      position: absolute;
      right: 10px;
    }

    &:disabled,
    &.bx--btn--disabled {
      color: ${colors.white};
    }
  }
`;

export const SubmitDestructiveButton = styled(Button)`
  ${buttons.baseButton}
  ${buttons.errorButton}

&.full-width {
    width: 100%;
  }

  &.show-loading {
    padding-right: 40px;
    & + svg {
      width: 22px;
      margin-top: 12px;
      fill: ${colors.font_light};
      position: absolute;
      right: 10px;
    }
  }
`;

export const SelectInputButton = styled.button`
  display: flex;
  flex-flow: row;
  justify-content: stretch;
  align-items: center;
  text-align: left;
  outline: 0;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  ${fonts.medium}
  color: ${colors.font_light};
  background-color: ${colors.section_background};
  box-shadow: 0 2px 1px 0 ${colors.input_shadow};
  padding: 9px 16px;
  cursor: pointer;

  > div {
    flex: 1;
    min-height: 22px;
  }

  > svg {
    flex: 0 0 auto;
    height: 8px;
    fill: ${colors.font_light};
  }

  &.open,
  &:focus {
    border-color: ${colors.input_focus};
    outline: 0;
    box-shadow: 0 2px 1px 0 ${colors.input_focus_shadow};
  }

  &.open {
    > svg {
      transform: rotate(-180deg);
    }
  }

  &.error {
    border-color: ${colors.error};
    margin-bottom: 3px;

    &.open {
      margin-bottom: 0;
    }
  }

  &:disabled {
    opacity: 0.54;
  }
`;

export const SelectInputDiv = styled.div`
  position: relative;
  margin-bottom: 24px;

  &.error {
    margin-bottom: 0;
  }
`;

export const SelectList = styled.ul`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  width: var(--dropdown-width);
  z-index: 1000;
  position: absolute;
  border-top: 0;
  max-height: 250px;
  overflow-y: auto;
  background-color: ${colors.section_background};
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 ${colors.shadow};

  &:focus {
    border-color: ${colors.input_focus};
  }

  li {
    list-style: none;
    ${fonts.medium}
    color: ${colors.font_light};
    padding: 14px 16px;
    border: 1px solid ${colors.border};
    border-bottom-width: 0;
    cursor: pointer;

    &.selected {
      ${fonts.medium_bold}
      background-color: ${colors.page_background};
    }

    &:focus,
    &.highlighted {
      background-color: ${colors.header_menu_focus};
      border-color: ${colors.header_menu_focus_border};

      + li {
        border-top-color: ${colors.header_menu_focus_border};
      }
    }
  }

  li:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  li:last-child {
    border-bottom-width: 1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const RadioInputGroupLabel = styled.div`
  ${fonts.medium_bold}
  color: ${colors.font_light};
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;

  > span {
    flex-grow: 1;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.54;
    }
  }
`;

export const RadioInputGroup = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 19px;

  &.hidden {
    display: none;
  }
`;

export const CheckboxLabel = styled.label`
  ${fonts.medium_bold}
  line-height: 24px;
  letter-spacing: 0.16px;
  color: ${colors.font_light};
  min-height: 28px;
  min-width: 28px;
  padding: 1px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-image: url(${CheckboxMarker});
  background-position: 1px 1px;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;

  &.hasLabel {
    padding-left: 35px;
  }

  &.mediumFont {
    ${fonts.medium}
  }
`;

export const CheckboxLabelDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  min-height: 67px;

  > ${CheckboxLabel} {
    flex-grow: 1;
  }

  &.hasFieldHelp > ${CheckboxLabel} {
    margin-bottom: 8px;
  }

  &.compact {
    min-height: 43px;
    align-self: flex-start;

    > ${CheckboxLabel} {
      flex-grow: 0;
    }
  }
`;

export const CheckboxInput = styled.input`
  opacity: 0;
  position: fixed;
  width: 0;

  &:focus + ${CheckboxLabel} {
    border-color: ${colors.input_focus};
  }

  &:checked + ${CheckboxLabel} {
    background-image: url(${CheckboxSelectedMarker});
  }

  &:disabled + ${CheckboxLabel} {
    cursor: not-allowed;
    opacity: 0.54;
  }
`;

export const RadioLabel = styled.label`
  flex: 1;
  ${fonts.medium}
  letter-spacing: 0.16px;
  color: ${colors.font_light};
  background-repeat: no-repeat;
  background-image: url(${RadioButtonMarker});
  background-size: 22px 22px;
  background-position: 5px 5px;
  position: relative;
  cursor: pointer;
  padding: 5px 10px 5px 43px;

  &:after {
    content: '';
    border: 1px solid transparent;
    border-radius: 4px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
  }
`;

export const RadioInput = styled.input`
  opacity: 0;
  position: fixed;
  width: 0;

  &:focus + ${RadioLabel}:after, &:focus + ${RadioLabel} {
    border-color: ${colors.input_focus};
  }

  &:checked + ${RadioLabel} {
    background-image: url(${RadioButtonSelectedMarker});
  }

  &:disabled + ${RadioLabel} {
    cursor: not-allowed;
    opacity: 0.54;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.horizontal {
    position: relative;
    flex-direction: row;

    button,
    a,
    input[type='submit'] {
      align-self: flex-start;
      margin-top: 25px;
    }

    > * {
      flex: 0 0 0;
    }
    ${HorizontalInputDiv} {
      flex: 1 1 0;
    }

    ${InputErrorDiv} {
      margin-bottom: 0;
    }

    ${Label} {
      margin-bottom: 4px;
    }

    ${TextInput} {
      margin-bottom: 0;
    }

    ${SubmitInput} {
      &.show-loading {
        & + svg {
          top: 25px;
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .buttons {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: end;
  }
`;

export const CDSModal = styled.div`
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--cds-overlay, rgba(22, 22, 22, 0.5));
  content: '';
  opacity: 1;
  transition:
    opacity 240ms cubic-bezier(0, 0, 0.3, 1),
    visibility 0ms linear;
  visibility: inherit;
`;

export const VisuallyHidden = styled.span`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
`;

export const ModalContainer = styled.div`
  position: fixed;
  display: grid;
  overflow: hidden;
  width: 60%;
  height: 30%;
  max-height: 60%;
  background-color: #393939;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  outline: 3px solid transparent;
  outline-offset: -3px;
  transform: translate3d(0, -24px, 0);
  transform-origin: top center;
  transition: transform 240ms motion(exit, expressive);

  &.ModalContainer-body {
    display: contents;
  }
`;

export const ModalHeader = styled.div`
  padding-top: 1rem;
  padding-right: 3rem;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  grid-column: 1/-1;
  grid-row: 1/1;
`;

export const ModalHeaderLabel = styled.h2`
  ${fonts.medium_small}
  box-sizing: border-box;
  padding: 0;
  padding-right: 0;
  border: 0;
  margin: 0;
  vertical-align: baseline;
  letter-spacing: var(--cds-heading-03-letter-spacing, 0);
  padding-top: 32px;
  color: var(--cds-text-primary, #f4f4f4);
`;

export const ModalHeaderHeading = styled.h3`
  ${fonts.medium_bold}
  box-sizing: border-box;
  padding: 0;
  padding-right: 0px;
  border: 0;
  margin: 0;
  vertical-align: baseline;
  letter-spacing: var(--cds-heading-03-letter-spacing, 0);
  color: var(--cds-text-primary, #f4f4f4);
`;

export const ModalClose = styled.button`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  border: 2px solid rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
`;

export const ModalCloseIcon = styled.svg`
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--cds-icon-primary, #161616);
`;

export const ModalContent = styled.div`
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  position: relative;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 3rem;
  color: var(--cds-text-primary, #161616);
  font-weight: 400;
  grid-column: 1/-1;
  grid-row: 2/-2;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  ${fonts.medium_bold}
  display: flex;
  height: 4rem;
  justify-content: flex-end;
  margin-top: auto;
  grid-column: 1/-1;
  grid-row: -1/-1;
`;

export const ModalFooterBtnSecondary = styled.button`
  ${fonts.medium_bold}
  max-width: none;
  height: 4rem;
  flex: 0 1 50%;
  align-items: baseline;
  padding-top: 0.875rem;
  padding-bottom: 2rem;
  margin: 0;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  background-color: var(--cds-button-secondary, #6f6f6f);
  color: var(--cds-text-on-color, #ffffff);
  cursor: pointer;

  &:focus {
    border-color: var(--cds-button-focus-color, var(--cds-focus, #0f62fe));
    box-shadow:
      inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)),
      inset 0 0 0 2px var(--cds-background, #ffffff);
  }

  &:hover {
    background-color: var(--cds-button-secondary-hover,#474747);
`;

export const ModalFooterBtnPrimary = styled.button`
  ${fonts.medium_bold}
  max-width: none;
  height: 4rem;
  flex: 0 1 50%;
  align-items: baseline;
  padding-top: 0.875rem;
  padding-bottom: 2rem;
  margin: 0;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  background-color: var(--cds-button-primary, #0f62fe);
  color: var(--cds-text-on-color, #ffffff);
  cursor: pointer;

  &:focus {
    border-color: var(--cds-button-focus-color, var(--cds-focus, #0f62fe));
    box-shadow:
      inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)),
      inset 0 0 0 2px var(--cds-background, #ffffff);
  }
`;

export const TextAreaInput = styled.textarea`
  flex: 1 1 0;
  ${fonts.medium}
  letter-spacing: 0.16px;
  color: ${colors.font_light};
  caret-color: ${colors.input_focus};
  outline: 0;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  background-color: ${colors.section_background};
  box-shadow: 0 2px 1px 0 ${colors.input_shadow};
  padding: 9px 16px;
  margin-bottom: 24px;
  resize: vertical; /* Allows resizing vertically, typically useful for a textarea */
  white-space: pre;
  overflow-x: auto;
  min-height: 200px; // Set a minimum height

  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill::first-line {
    ${fonts.medium}
    color: ${colors.font_light} !important;
    background-color: ${colors.section_background} !important;
    transition: background-color 50000s ease-in-out 0s;
  }

  ::placeholder {
    color: purple;
    opacity: 0.54;
  }

  &:disabled {
    opacity: 0.54;
  }

  &:focus {
    border-color: ${colors.input_focus};
    outline: 0;
    box-shadow: 0 2px 1px 0 ${colors.input_focus_shadow};
  }

  &.error {
    border-color: ${colors.error};
    margin-bottom: 3px;
  }
`;
