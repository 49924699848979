export const getRoute = () => {
  return '/computeplane/manage';
};

export const getAddStandalonePrometheusRoute = () => {
  return '/prometheus/add';
};

export const getPatchPrometheusJMXRoute = () => {
  return '/prometheus/jmx';
};
