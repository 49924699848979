import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { createSelector } from 'reselect';
import { getNow } from '../../utils/dateUtils';
import { getComputePlane, getConfiguration } from '../ComputePlane/selectors';
import * as constants from './constants';

const getTenant = (state) => {
  return state.accountSetup.tenant;
};

export const isAccountLoaded = createSelector(
  getTenant,
  getComputePlane,
  getConfiguration,
  (tenant, computePlane, computePlaneConfiguration) => {
    // tenant loaded
    // compute plane loaded
    // compute plane config loaded
    return !!tenant && !!computePlane && !!computePlaneConfiguration;
  },
);

export const getTenantId = (state) => {
  return state.accountSetup.tenantId;
};

export const isSubscriptionValid = (state) => {
  return (
    state.accountSetup.subscriptionStatus !==
      constants.subscriptionStatus.unsubscribed &&
    state.accountSetup.subscriptionStatus !==
      constants.subscriptionStatus.failed &&
    state.accountSetup.subscriptionStatus !==
      constants.subscriptionStatus['trial-expired']
  );
};

export const isSubscriptionUnsubscribed = (state) => {
  return (
    state.accountSetup.subscriptionStatus ===
    constants.subscriptionStatus.unsubscribed
  );
};

export const isSubscriptionFailed = (state) => {
  return (
    state.accountSetup.subscriptionStatus ===
    constants.subscriptionStatus.failed
  );
};

export const isCommunityEditionTenant = (state) => {
  return (
    state.accountSetup.subscriptionStatus ===
    constants.subscriptionStatus['community-edition']
  );
};

export const isTrialActive = (state) => {
  return (
    state.accountSetup.subscriptionStatus === constants.subscriptionStatus.trial
  );
};

export const trialDaysLeft = (state) => {
  if (state.accountSetup.subscriptionStatus !== 'trial') return null;

  const today = getNow();
  const { trialExpirationDate } = state.accountSetup;
  return differenceInCalendarDays(trialExpirationDate, today);
};

export const isTrialExpired = (state) => {
  return (
    state.accountSetup.subscriptionStatus ===
    constants.subscriptionStatus['trial-expired']
  );
};

export const getTenantServiceName = (state) => {
  return state.accountSetup?.tenant?.serviceName;
};

export const getLakehouseCRN = (state) => {
  return state.accountSetup?.tenant?.lakehouseCRN;
};
