import { string, object } from 'prop-types';
import { useFormContext } from 'react-hook-form';

const HiddenInput = ({ name, value, defaultValue, validationRules }) => {
  const { register: formRegister } = useFormContext();

  return (
    <input
      type='hidden'
      defaultValue={defaultValue}
      value={value}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formRegister(name, validationRules)}
    />
  );
};

HiddenInput.defaultProps = {
  validationRules: undefined,
  defaultValue: undefined,
  value: undefined,
};

HiddenInput.propTypes = {
  name: string.isRequired,
  value: string,
  defaultValue: string,
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
};

export default HiddenInput;
