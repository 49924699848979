import { string, shape, bool, node, oneOfType, object } from 'prop-types';
import useRouteBuilder from '../../router/useRouteBuilder';
import {
  PrimaryLink,
  SecondaryLink,
  TertiaryLink,
  PrimaryExternalLink,
  SecondaryExternalLink,
  TertiaryExternalLink,
  DisabledPrimaryLink,
  DisabledSecondaryLink,
  DisabledTertiaryLink,
  DownloadPrimaryLink,
  DownloadSecondaryLink,
  DownloadTertiaryLink,
} from './styledComponents';

const LinkFactory = (InAppLink, ExternalLink, DisabledLink, DownloadLink) => {
  const Link = ({
    to,
    disabled,
    tooltip,
    external,
    download,
    downloadFileName,
    newTab,
    children,
    state,
    // eslint-disable-next-line no-unused-vars
    inlineDisabled,
  }) => {
    const normalizedTo = useRouteBuilder(to, external || download);

    if (disabled) {
      let classes;
      if (inlineDisabled) {
        classes = 'inline';
      }

      return (
        <DisabledLink className={classes} disabled title={tooltip}>
          {children}
        </DisabledLink>
      );
    }
    if (external) {
      return (
        <ExternalLink
          href={normalizedTo}
          target={newTab ? '_blank' : 'self'}
          rel={newTab ? 'noopener' : undefined}
          className={newTab ? 'new-tab' : undefined}
          title={tooltip}
        >
          {children}
        </ExternalLink>
      );
    }
    if (download) {
      return (
        <DownloadLink
          href={normalizedTo}
          download={downloadFileName}
          className='icon'
        >
          {children}
        </DownloadLink>
      );
    }
    return (
      <InAppLink to={normalizedTo} title={tooltip} state={state}>
        {children}
      </InAppLink>
    );
  };

  Link.defaultProps = {
    disabled: false,
    external: false,
    download: false,
    downloadFileName: null,
    newTab: false,
    inlineDisabled: false,
    tooltip: undefined,
    state: undefined,
  };

  Link.propTypes = {
    to: oneOfType([
      string,
      // eslint-disable-next-line react/forbid-prop-types
      shape({ pathname: string.isRequired, state: object }),
    ]).isRequired,
    disabled: bool,
    external: bool,
    download: bool,
    downloadFileName: string,
    newTab: bool,
    children: node.isRequired,
    inlineDisabled: bool,
    tooltip: string,
    // eslint-disable-next-line react/forbid-prop-types
    state: object,
  };

  return Link;
};

const module = {
  PrimaryLink: LinkFactory(
    PrimaryLink,
    PrimaryExternalLink,
    DisabledPrimaryLink,
    DownloadPrimaryLink,
  ),
  SecondaryLink: LinkFactory(
    SecondaryLink,
    SecondaryExternalLink,
    DisabledSecondaryLink,
    DownloadSecondaryLink,
  ),
  TertiaryLink: LinkFactory(
    TertiaryLink,
    TertiaryExternalLink,
    DisabledTertiaryLink,
    DownloadTertiaryLink,
  ),
};

export default module;
