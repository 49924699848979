/* eslint-disable import/no-import-module-exports */
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import './css/main.css';
import logger from './utils/logger';
import initializeStore from './store';

const store = initializeStore();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const render = () => {
  // eslint-disable-next-line global-require
  const Router = require('./router/Router').default;
  root.render(
    <Provider store={store}>
      <Router />
    </Provider>,
  );
};

render();

// TODO: replace module.hot with process.webpackHot
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./router/Router', () => {
    logger.log('resetting prop types warnings');
    PropTypes.resetWarningCache();
    render();
  });
}
