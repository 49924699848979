const Copy = () => {
  return (
    <svg viewBox='0 0 18 21' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='white'
        fillRule='evenodd'
        d='M4 4.225V1.244A.997.997 0 015 .25h12c.552 0 1 .445 1 .994v13.912a.997.997 0 01-1 .994h-3v2.981a1 1 0 01-1.007.994H1.007a1.004 1.004 0 01-.711-.289.992.992 0 01-.296-.705L.003 5.22a1 1 0 011.006-.994H4zM2.002 6.212L2 18.137h10V6.212H2.002zM6 4.225h8v9.937h2V2.237H6v1.988zM4 9.194h6v1.987H4V9.194zm0 3.975h6v1.987H4V13.17z'
      />
    </svg>
  );
};

export default Copy;
