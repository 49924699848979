import { string } from 'prop-types';
import Data from '../../Data';
import Page from '../../Page';

const display = {
  title: 'Presto Cluster',
  formContentHeader: 'Cluster name',
  nameLabel: 'Cluster',
};

const PrestoClusterInfo = ({ name }) => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      <Page.FormContent rowCount={2}>
        <div>
          <h3>{display.formContentHeader}</h3>
        </div>
        <div>
          <Data.DataField label={display.nameLabel} value={name} />
        </div>
      </Page.FormContent>
    </Page.Box>
  );
};

PrestoClusterInfo.propTypes = {
  name: string.isRequired,
};

export default PrestoClusterInfo;
