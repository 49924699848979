import { connect } from '../../utils/redux';
import { AuthorizationServices } from './components';
import * as selectors from './selectors';
import * as actions from './actions';

const mapStateToProps = (state) => {
  return {
    authorizationServices: selectors.getAuthorizationServices(state),
  };
};

export default connect(mapStateToProps, {
  getAuthorizationServices: actions.getAll,
})(AuthorizationServices);
