import telemetry from '../../telemetry';
import * as actionTypes from './actionTypes';

export const submit = ({ crn }, location, navigate) => {
  return {
    type: actionTypes.submit,
    payload: {
      crn,
      location,
      navigate,
    },
  };
};

export const success = () => {
  return {
    type: actionTypes.success,
  };
};

export const error = (errorMessage) => {
  return {
    type: actionTypes.error,
    payload: {
      error: errorMessage,
    },
  };
};

const actionSanitizer = (action) => {
  let sanitizedAction = action;
  if (action.type === actionTypes.submit) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        password: telemetry.sanitize(action.payload.password),
      },
      sanitized: true,
    };
  }
  return sanitizedAction;
};

telemetry.actionSanitizer.registerActionSanitizer('signIn', actionSanitizer);
