import { func, string, bool, number } from 'prop-types';
import Page from '../../Page';
import ErrorRoute from '../../../router/ErrorRoute';
import SignInForm from './SignInForm';

const display = {
  logInTitle: 'Log In',
  setPasswordTitle: 'Set Password',
};

const SignIn = ({
  crn,
  submitInProgress,
  error,
  errorStatus,
  message,
  signIn,
}) => {
  if (errorStatus) {
    return <ErrorRoute authError={{ status: errorStatus }} />;
  }

  return (
    <Page.LogoBox>
      <div className='title'>{display.logInTitle}</div>
      <SignInForm
        submitInProgress={submitInProgress}
        error={error}
        message={message}
        signIn={signIn}
        crn={crn}
      />
    </Page.LogoBox>
  );
};

SignIn.defaultProps = {
  error: null,
  errorStatus: null,
  message: null,
  crn: null,
};

SignIn.propTypes = {
  submitInProgress: bool.isRequired,
  error: string,
  errorStatus: number,
  message: string,
  signIn: func.isRequired,
  crn: string,
};

export default SignIn;
