import { useState, useCallback, useEffect } from 'react';
import { string, func, bool, shape, object } from 'prop-types';
import { stringify } from 'yaml';
import Page from '../../Page';
import Banner from '../../Banner';
import Form from '../../Form';
import Data from '../../Data';
import * as routes from '../routes';
import EditJMX from './EditJMX';

const display = {
  boxTitle: 'Prometheus',
  bannerTitle: 'No infrastructure policy attached to provisioning role.',
  enableButtonLabel: 'Enable',
  deleteButtonLabel: 'Delete',
  editButtonLabel: 'Edit',
  confirmTitle: 'Are you sure?',
  confirmDetails:
    'This will remove the Prometheus remote write rule and Prometheus ingress. It will also revert the Prometheus container to running on a shared node.',
  cancelButton: 'Cancel',
  confirmButton: 'Delete',
  configurationContentHeader: 'Configuration',
  ingressContentHeader: 'Ingress',
  AWSContentHeader: 'AWS Tags',
  instanceTypeLabel: 'Instance Type',
  remoteWriteLabel: 'Prometheus Remote Write Rule',
  prometheusLabel: 'Prometheus URL',
  userLabel: 'Basic Auth User Name',
  passwordLabel: 'Basic Auth Password',
  AWSTagsLabel: 'Tags',
  destroyTooltip: 'Can only delete when Prometheus is already active',
  prestoMetricsContentHeader: 'Presto Metrics',
  prestoMetricsLabel: 'Enable sending Presto metrics to IBM',
};

const StandalonePrometheus = ({
  prometheus,
  deletePrometheus,
  computePlaneId,
  deleteInProgress,
  canDestroyOrEditPrometheus,
  error,
  canEnablePrometheus,
  get,
  submitSuccess,
  usePop,
  submitInProgress,
  patchPrometheusJMX,
}) => {
  useEffect(() => {
    if (computePlaneId) {
      get(computePlaneId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computePlaneId]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const onConfirm = useCallback(() => {
    deletePrometheus(computePlaneId);
    setShowDeletePrompt(false);
  }, [deletePrometheus, computePlaneId]);
  const onCancel = useCallback(() => {
    setShowDeletePrompt(false);
  }, []);

  // const submitAction = useCallback(
  //   (data) => {
  //     get(computePlaneId, data);
  //   },
  //   [get, computePlaneId],
  // );

  if (!prometheus) {
    return (
      <Page.Box>
        <Page.BoxHeader>
          <h2>{display.boxTitle}</h2>
          <div className='buttons'>
            <Page.PrimaryLink
              to={routes.getAddStandalonePrometheusRoute()}
              disabled={!canEnablePrometheus || deleteInProgress}
            >
              {display.enableButtonLabel}
            </Page.PrimaryLink>
          </div>
        </Page.BoxHeader>
        <Page.FormContent>
          <p>Prometheus configuration currently standard.</p>
        </Page.FormContent>
      </Page.Box>
    );
  }

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
        <div className='buttons'>
          <Form.SecondaryButton
            disabled={
              !canDestroyOrEditPrometheus ||
              showDeletePrompt ||
              deleteInProgress
            }
            onClick={() => {
              setShowDeletePrompt(true);
            }}
            label={display.deleteButtonLabel}
            tooltip={
              !canDestroyOrEditPrometheus ? display.destroyTooltip : undefined
            }
          />
          <Page.PrimaryLink
            to={routes.getAddStandalonePrometheusRoute()}
            disabled={
              !canDestroyOrEditPrometheus ||
              showDeletePrompt ||
              deleteInProgress
            }
          >
            {display.editButtonLabel}
          </Page.PrimaryLink>
        </div>
      </Page.BoxHeader>
      {error && <Banner title={error} />}
      {prometheus.errorMessages &&
        prometheus.errorMessages.map((err, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Banner key={index} title={err.errorMessage || err.errorSummary} />
          );
        })}
      {showDeletePrompt && (
        <Banner
          title={display.confirmTitle}
          details={display.confirmDetails}
          isWarning
        >
          <div className='buttons'>
            <Form.SecondaryButton
              onClick={onCancel}
              label={display.cancelButton}
            />
            <Form.PrimaryButton
              onClick={onConfirm}
              label={display.confirmButton}
            />
          </div>
        </Banner>
      )}
      <Page.FormContent rows={2}>
        <div>
          <h3>{display.configurationContentHeader}</h3>
        </div>
        <div>
          <Data.DataField
            label={display.instanceTypeLabel}
            value={prometheus.instanceType}
          />
          {prometheus.remoteWriteRule ? (
            <Data.CodeField
              label={display.remoteWriteLabel}
              code={stringify(prometheus.remoteWriteRule)}
              language='yaml'
            />
          ) : (
            <div>
              <Data.DataField label={display.remoteWriteLabel} disabled />
            </div>
          )}
        </div>

        <div>
          <h3>{display.ingressContentHeader}</h3>
        </div>
        {prometheus.ingress ? (
          <div>
            <Data.DataFieldWithLink
              label={display.prometheusLabel}
              value={prometheus.endpoint}
              url={prometheus.endpoint}
            />
            <Data.DataField
              label={display.userLabel}
              value={prometheus.ingress.user}
            />
            <Data.DataField
              label={display.passwordLabel}
              value={prometheus.ingress.password}
            />
          </div>
        ) : (
          <div>
            <Data.DataField label={display.prometheusLabel} disabled />
          </div>
        )}

        <div>
          <h3>{display.prestoMetricsContentHeader}</h3>
        </div>
        <div>
          <Data.CheckboxField
            label={display.prestoMetricsLabel}
            value={prometheus.enableJMXMetrics}
          />
          <EditJMX
            submitInProgress={submitInProgress}
            patchPrometheusJMX={patchPrometheusJMX}
            computePlaneId={computePlaneId}
            enableJMX={!!prometheus.enableJMXMetrics}
            canDestroyOrEditPrometheus={canDestroyOrEditPrometheus}
          />
        </div>
      </Page.FormContent>
    </Page.Box>
  );
};

StandalonePrometheus.defaultProps = {
  prometheus: null,
  computePlaneId: null,
  error: undefined,
  canDestroyOrEditPrometheus: false,
  deleteInProgress: false,
  canEnablePrometheus: false,
  submitSuccess: false,
  usePop: false,
  submitInProgress: false,
};

StandalonePrometheus.propTypes = {
  prometheus: shape({
    instanceType: string.isRequired,
    enableStandalonePrometheus: bool,
    status: string,
    // eslint-disable-next-line react/forbid-prop-types
    customTags: object,
    ingress: shape({
      user: string,
      password: string,
      basicAuth: string,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    remoteWriteRule: object,
    endpoint: string,
  }),
  computePlaneId: string,
  deletePrometheus: func.isRequired,
  deleteInProgress: bool,
  canDestroyOrEditPrometheus: bool,
  error: string,
  canEnablePrometheus: bool,
  get: func.isRequired,
  submitSuccess: bool,
  usePop: bool,
  submitInProgress: bool,
  patchPrometheusJMX: func.isRequired,
};

export default StandalonePrometheus;
