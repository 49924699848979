import { useState, useCallback, useContext, useEffect } from 'react';
import { string, func, number, shape, bool } from 'prop-types';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import { useEnableFeature } from '../../utils/featureHooks';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';

const display = {
  title: 'Danger Zone',
  deletePrestoUser: 'Delete Presto User',
  deletePrestoUserButton: 'Delete',
  confirmTitle:
    'Deleting this Presto user will restart the Presto clusters that have data sources attached where the user has an IAM role mapping.',
  confirmDetails:
    'All queries running on those clusters will fail. The clusters will be moved to pending state and will be unusable until they move back to active state.',
  cancelButton: 'Cancel',
};

const DangerZone = ({
  navTarget,
  prestoUserId,
  deletePrestoUser,
  actionStatus,
  usePop,
  canDelete,
  alertBeforeDelete,
}) => {
  const enableLink = useEnableFeature();
  const actionInProgress = useContext(ActionInProgressStateContext);
  const setActionInProgress = useContext(ActionInProgressSetStateContext);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const onDelete = useCallback(() => {
    setActionInProgress(true);
    setConfirmDelete(true);
  }, [setActionInProgress]);

  const onConfirmDelete = useCallback(() => {
    const actionId = Date.now();
    setConfirmDelete(actionId);
    deletePrestoUser(prestoUserId, actionId);
    setActionInProgress(false);
  }, [prestoUserId, deletePrestoUser, setActionInProgress]);

  const onCancel = useCallback(() => {
    setActionInProgress(false);
    setConfirmDelete(false);
  }, [setActionInProgress]);

  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === confirmDelete
    ) {
      setConfirmDelete(false);
    }
  }, [actionStatus, confirmDelete, usePop]);

  const showActionStatusError =
    actionStatus && actionStatus.error && actionStatus.name === 'delete';

  return (
    <Page.BoxNavTarget navTarget={navTarget} error>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      {showActionStatusError && (
        <Page.InformationBanner>
          <Banner scrollIntoView title={actionStatus.error} />
        </Page.InformationBanner>
      )}
      <Page.DangerZone>
        {confirmDelete !== true && (
          <>
            <div>
              <h3>{display.deletePrestoUser}</h3>
            </div>
            <div>
              <Form.ErrorButton
                label={display.deletePrestoUserButton}
                onClick={alertBeforeDelete ? onDelete : onConfirmDelete}
                disabled={
                  !enableLink ||
                  !canDelete ||
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress)
                }
                showLoading={
                  actionStatus &&
                  actionStatus.inProgress &&
                  actionStatus.actionId === confirmDelete
                }
              />
            </div>
          </>
        )}
        {confirmDelete === true && (
          <Page.ConfirmDanger>
            <Banner
              title={display.confirmTitle}
              details={display.confirmDetails}
            >
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={onCancel}
                  label={display.cancelButton}
                />
                <Form.ErrorButton
                  onClick={onConfirmDelete}
                  label={display.deletePrestoUserButton}
                />
              </div>
            </Banner>
          </Page.ConfirmDanger>
        )}
      </Page.DangerZone>
    </Page.BoxNavTarget>
  );
};

DangerZone.defaultProps = {
  actionStatus: undefined,
  usePop: true,
  canDelete: true,
  alertBeforeDelete: true,
};

DangerZone.propTypes = {
  navTarget: string.isRequired,
  prestoUserId: string.isRequired,
  deletePrestoUser: func.isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    inProgress: bool.isRequired,
  }),
  usePop: bool,
  canDelete: bool,
  alertBeforeDelete: bool,
};

export default DangerZone;
