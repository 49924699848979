import Help from '../../Help';

export const VPCType = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>VPC Type</Help.HelpHeader>
      <Help.P>
        Indicate whether you are would like to use a fully managed VPC created
        by IBM watsonx.data or bring your own VPC that you created yourself.
      </Help.P>
    </Help.HelpSection>
  );
};

export const SubdomainName = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Compute Plane Name</Help.HelpHeader>
      <Help.P>
        Enter a compute plane name that IBM watsonx.data will create and use to
        provide access to all resources created within your IBM watsonx.data
        compute plane.
      </Help.P>
      <Help.P>
        The compute plane name must begin and end with a-z or 0-9. Hyphens,
        underscores, lowercase letters and numbers are valid characters. It has
        a max length of 63 characters.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSAccountId = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Account ID</Help.HelpHeader>
      <Help.P>
        The AWS account ID is a 12-digit number that uniquely identifies an AWS
        account. Enter the AWS account ID of the AWS account you want the IBM
        watsonx.data compute plane to be deployed in. You should have access to
        this AWS account.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSRegion = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Region</Help.HelpHeader>
      <Help.P>
        Select the AWS region where you want the IBM watsonx.data compute plane
        to be deployed. We recommend that you pick a region where most of your
        data sources reside.
      </Help.P>
    </Help.HelpSection>
  );
};

export const VpcId = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>VPC ID</Help.HelpHeader>
      <Help.P>
        The ID of the VPC that you want us to use when we create and deploy your
        compute plane. The VPC <b>must</b> exist in the AWS region selected
        above.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSPermissionBoundaryPolicy = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Permission Boundary AWS Policy</Help.HelpHeader>
      <Help.P>
        The IBM watsonx.data permission boundary AWS policy sets the maximum
        permissions allowable for roles created by watsonx.data.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSCoreInfrastructurePolicy = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Core Infrastructure AWS Policy</Help.HelpHeader>
      <Help.P>
        The IBM watsonx.data core infrastructure AWS policy contains the
        permissions needed to create and destroy the compute plane. This policy
        can be revoked once the compute plane is created; however, it is
        required if and when you want to update or destroy the compute plane.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSOperationsPolicy = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Operations AWS Policy</Help.HelpHeader>
      <Help.P>
        The IBM watsonx.data operations AWS policy contains the permissions to
        run a managed service, such as provisioning and deprovisioning Presto
        clusters. This policy is constrained by the IBM watsonx.data permission
        boundary AWS policy.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSTrustRelationshipPolicy = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>IAM Role Trust Relationship Policy</Help.HelpHeader>
      <Help.P>
        The trust relationship policy for the IBM watsonx.data provisioning role
        allows the IBM AWS account to assume the provisioning role in order to
        manage watsonx.data compute plane resources in your AWS account.
      </Help.P>
    </Help.HelpSection>
  );
};

export const ProvisioningRoleCFTemplate = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Provisioning Role CloudFormation Template
      </Help.HelpHeader>
      <Help.P>
        The CloudFormation template that will create the provisioning role.
      </Help.P>
    </Help.HelpSection>
  );
};

export const RoleCreationMethod = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Role Creation Method</Help.HelpHeader>
      <Help.P>
        Choose a method for setting up the necessary AWS role either manually or
        automatically using CloudFormation quick create (recommended).
      </Help.P>
    </Help.HelpSection>
  );
};

export const CloudFormationQuickCreate = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>CloudFormation Quick Create</Help.HelpHeader>
      <Help.P>
        CloudFormation Quick Create will create the necessary role and
        permissions automatically for you.
      </Help.P>
      <Help.P>
        Click on the Open CloudFormation button and follow the steps.
      </Help.P>
    </Help.HelpSection>
  );
};

export const CloudFormationManualCreate = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>CloudFormation Manual Create</Help.HelpHeader>
      <Help.P>
        Manually upload the CloudFormation template to create the necessary role
        and permission.
      </Help.P>
      <Help.P>
        Follow the{' '}
        <Help.Strong>
          Manual Role Creation Steps with CloudFormation
        </Help.Strong>{' '}
        instructions to ensure the role is created correctly.
      </Help.P>
    </Help.HelpSection>
  );
};

export const TerraformCreate = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Terraform</Help.HelpHeader>
      <Help.P>Apply the Terraform file to create the provisioning role.</Help.P>
      <Help.P>
        Follow the{' '}
        <Help.Strong>Manual Role Creation Steps with Terraform</Help.Strong>{' '}
        instructions to ensure the role is created correctly.
      </Help.P>
    </Help.HelpSection>
  );
};

export const VerifyRoleCreation = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Verify Role Creation</Help.HelpHeader>
      <Help.P>
        Click on the Verify Role button to verify the provisioning role and go
        to Next button.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSAvailabilityZones = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Availability Zones</Help.HelpHeader>
      <Help.P>
        Select the AWS availability zones for the VPC created by the IBM
        watsonx.data compute plane.
      </Help.P>
      <Help.P>
        You are required to pick two or three. The IBM watsonx.data compute
        plane will be deployed across them providing built-in high availability.
      </Help.P>
      <Help.P>
        If your compute plane will access data in other AWS accounts, use the
        Availability Zone Id shown in parenthesis to ensure the compute plane is
        created in the same physical Availability Zone as the data. This will
        reduce AWS network cost and improve query performance. See{' '}
        <a
          href='https://docs.aws.amazon.com/ram/latest/userguide/working-with-az-ids.html'
          target='_blank'
          rel='noopener noreferrer'
        >
          Availability Zone IDs for your AWS resources
        </a>{' '}
        for more information.
      </Help.P>
    </Help.HelpSection>
  );
};

export const VpcCIDR = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>IPv4 CIDR Block</Help.HelpHeader>
      <Help.P>
        You can override the default IPv4 address range for your VPC.
      </Help.P>
      <Help.P>
        Specify the IPv4 address range as a Classless Inter-Domain Routing
        (CIDR) block; for example, 10.128.0.0/16.
      </Help.P>
      <Help.P>
        A custom CIDR block size must be between a /16 netmask and /24 netmask.
        Once the compute plane is created, the CIDR block size cannot be
        changed.
      </Help.P>
      <Help.P>
        This CIDR block cannot overlap with another VPC CIDR block in the same
        region.
      </Help.P>
    </Help.HelpSection>
  );
};

export const privateSubnets = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Private Subnets</Help.HelpHeader>
      You must provide at least two private subnet IDs when you bring your own
      VPC. They should be included as a comma separated list. For example,
      subnet-01f2a4c36efe79137, subnet-039b473f2bc9d8431
    </Help.HelpSection>
  );
};

export const publicSubnets = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Public Subnets</Help.HelpHeader>
      Public subnets are optional when you bring your own VPC. If you are
      including public subnets then you should provide at least two of them as a
      comma separated list of subnet IDs. For example, subnet-0616934522863deda,
      subnet-0d54317b477de583c
    </Help.HelpSection>
  );
};
