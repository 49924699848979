import { useEffect } from 'react';
import { string, arrayOf, func } from 'prop-types';
import DataSources from '../../DataSources';
import Page from '../../Page';
import { NoDataDiv } from './styledComponents';
import DataSourcesTable from '../../DataSources/components/DataSourcesTable';

const display = {
  title: 'Authorized Data Sources',
  noDataSources: 'No authorized data sources.',
  loading: 'Loading...',
};

const AuthorizedDataSources = ({
  navTarget,
  authorizationServiceId,
  dataSources,
  loadDataSources,
}) => {
  useEffect(() => {
    loadDataSources(authorizationServiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationServiceId]);

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.TableBoxHeader>
        <h2>{display.title}</h2>
      </Page.TableBoxHeader>
      {dataSources && dataSources.length === 0 && (
        <NoDataDiv>{display.noDataSources}</NoDataDiv>
      )}
      {!dataSources && <NoDataDiv>{display.loading}</NoDataDiv>}
      {dataSources && dataSources.length > 0 && (
        <DataSourcesTable dataSources={dataSources} />
      )}
    </Page.BoxNavTarget>
  );
};

AuthorizedDataSources.defaultProps = {
  dataSources: null,
};

AuthorizedDataSources.propTypes = {
  navTarget: string.isRequired,
  authorizationServiceId: string.isRequired,
  dataSources: arrayOf(DataSources.propTypes.DataSource),
  loadDataSources: func.isRequired,
};

export default AuthorizedDataSources;
