import styled from 'styled-components/macro';
import { colors, fonts } from '../../../css';

const headerMinHeight = 72;
export const HeaderDiv = styled.div`
  flex: 0 0 auto;
  min-height: ${headerMinHeight}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  background-color: ${colors.header};
  background-image: linear-gradient(
    to bottom,
    ${colors.header_gradient},
    transparent
  );
  border-bottom: 1px solid ${colors.header};
  box-shadow: 0 2px 4px 0 ${colors.shadow};

  .logo {
    display: block;
    position: relative;
    margin-right: 58px;
    margin-left: 40px;
    padding: 18px 0 17px;
    border: 2px solid transparent;

    img {
      margin: auto;
    }

    &:focus {
      border-color: ${colors.font_light};
      outline: 0;
    }
  }

  &.communityEdition {
    background-color: ${colors.darkPurple};
    .logo {
      padding: 18px 0 3px;
    }
  }

  div.nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &.pages {
      flex: 1 0 auto;
    }
  }

  .nav_service_name {
    display: block;
    ${fonts.medium_semibold}
    color: ${colors.font_light};
    outline: none;
    text-decoration: none;
    padding: 25px 0 24px;
    margin: 0 18px;
  }

  .nav_link {
    display: block;
    ${fonts.medium_semibold}
    color: ${colors.font_light};
    outline: none;
    text-decoration: none;
    padding: 25px 0 24px;
    margin: 0 18px;

    &.active {
      padding-bottom: 20px;
      border-bottom: 4px solid ${colors.primary_button};
    }

    &:focus {
      padding: 23px 10px 22px;
      margin: 0 6px;
      border: 2px solid ${colors.font_light};
    }
  }
`;

export const PublicNavigationDiv = styled.div`
  padding-right: 40px;
`;

export const AccountOptionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${fonts.medium_semibold}
  color: ${colors.font_light};
  letter-spacing: 0.16px;
  padding-left: 20px;
  padding-right: 40px;
  cursor: pointer;
  position: relative;

  &:hover,
  &:focus {
    background-color: ${colors.header_gradient};
    outline: 0;
  }

  .name {
    padding: 0 16px 0 10px;
  }

  .dropdown {
    svg {
      fill: ${colors.font_light};
    }
  }

  &.open {
    background-color: ${colors.header_gradient};
    .dropdown {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
`;

export const AccountOptionsDropDownDiv = styled.div`
  position: absolute;
  z-index: 100;
  top: ${headerMinHeight + 1 + 11}px;
  right: 11px;
  color: ${colors.font_light};
  ${fonts.medium_bold}
  border-radius: 4px;
  background-color: ${colors.section_background};
  box-shadow: 0 4px 8px 0 ${colors.shadow};

  .option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 11px 21px;
    min-width: max(var(--account-options-dropdown-width), 225px);
    border: 1px solid ${colors.border};
    border-bottom-width: 0;
    cursor: pointer;
    text-decoration: none;
    color: ${colors.font_light};

    &:any-link {
      color: ${colors.font_light};
    }

    &:hover,
    &:focus {
      background-color: ${colors.header_menu_focus};
      border-color: ${colors.header_menu_focus_border};

      + .option {
        border-top-color: ${colors.header_menu_focus_border};
      }
    }

    > div + div {
      margin-left: 10px;
    }

    .log-out > svg {
      fill: ${colors.error};
    }

    .account > svg {
      fill: ${colors.white};
    }
  }

  .option:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .option:last-child {
    border-bottom-width: 1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const IconDiv = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;

  &.small {
    width: 16px;
    height: 16px;
  }

  svg {
    flex: 1 1 auto;
  }
`;
