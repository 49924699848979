export const destroyComputePlaneRoute = () => {
  return '/computeplane/destroy';
};

export const addComputePlaneRoute = () => {
  return '/computeplane/add';
};

export const viewComputePlaneRoute = () => {
  return '/computeplane/manage';
};
