import { bool } from 'prop-types';
import Page from '../../Page';
import * as routes from '../routes';
import { useEnableFeature } from '../../utils/featureHooks';

const display = {
  header: 'Clusters',
  addLabel: 'Create Cluster',
  communityEditionTooltip: 'Community edition is limited to a single cluster',
};

const TitleNav = ({ canCreateCluster }) => {
  const enableLink = useEnableFeature();

  return (
    <>
      <h1>{display.header}</h1>
      <div className='buttons'>
        <Page.PrimaryLink
          to={routes.getAddPrestoClusterRoute()}
          disabled={!enableLink || !canCreateCluster}
          tooltip={
            !canCreateCluster ? display.communityEditionTooltip : undefined
          }
        >
          {display.addLabel}
        </Page.PrimaryLink>
      </div>
    </>
  );
};

TitleNav.propTypes = {
  canCreateCluster: bool.isRequired,
};

export default TitleNav;
