import { connect } from '../../utils/redux';
import { UpdateIdentityProvider } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import IdentityProviders from '../IdentityProviders';

const mapStateToProps = (state, props) => {
  return {
    identityProvider: IdentityProviders.selectors.getIdentityProvider(
      state,
      props,
    ),
    notFound: IdentityProviders.selectors.getIsNotFound(state, props),
    identityProviderId: selectors.getIdentityProviderId(state, props),
    error: selectors.getError(state),
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    usePop: selectors.getUsePop(state),
  };
};

export default connect(mapStateToProps, {
  loadIdentityProvider: IdentityProviders.actions.get,
  update: actions.submit,
})(UpdateIdentityProvider);
