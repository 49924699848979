import { createApiAction } from '../../rayApi/actions';
import telemetry from '../../telemetry';
import PrestoUsers from '../PrestoUsers';
import * as actionTypes from './actionTypes';

export const resetForm = () => {
  return {
    type: actionTypes.resetForm,
  };
};

export const submit = (values) => {
  return {
    type: actionTypes.submit,
    payload: {
      values,
    },
  };
};

export const post = (body) => {
  return createApiAction(
    actionTypes.post,
    PrestoUsers.api.post,
    true,
    null,
    body,
  );
};

const actionSanitizer = (action) => {
  let sanitizedAction = action;
  if (action.type === actionTypes.submit) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        values: {
          ...action.payload.values,
          password: telemetry.sanitize(action.payload.values.password),
          password2: telemetry.sanitize(action.payload.values.password2),
        },
      },
    };
  } else if (action.type === actionTypes.post.request) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        body: {
          ...action.payload.body,
          password: telemetry.sanitize(action.payload.body.password),
        },
      },
      sanitized: true,
    };
  } else if (
    action.type === actionTypes.post.pending ||
    action.type === actionTypes.post.accepted ||
    action.type === actionTypes.post.success ||
    action.type === actionTypes.post.failure ||
    action.type === actionTypes.post.abort
  ) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        requestBody: {
          ...action.payload.requestBody,
          password: telemetry.sanitize(action.payload.requestBody.password),
        },
      },
      sanitized: true,
    };
  }

  return sanitizedAction;
};

telemetry.actionSanitizer.registerActionSanitizer(
  'prestoUserAdd',
  actionSanitizer,
);
