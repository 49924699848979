import { func, string, shape, bool } from 'prop-types';
import Page from '../../../../Page';
import Form from '../../../../Form';
import * as constants from '../../../constants';
import { useFormWizard } from '../../../../FormWizard/useFormWizard';
import { RoleCreationMethod } from './RoleCreationMethod';
import { VerifyRoleCreation } from './VerifyRoleCreation';

export const CreateProvisioningRole = ({
  awsAccountId,
  awsCloudFormationQuickCreateUrl,
  cloudFormationTemplate,
  computePlane,
  cfStackName,
  onBack,
  onNext,
  onVerifyProvisioningRole,
  hasVerifyRoleSuccess,
  verifyRoleInProgress,
  verifyRoleError,
  awsCloudFormationUrl,
  terraformContent,
  terraformFileName,
  submitInProgress,
}) => {
  const formWizard = useFormWizard();
  const handleSubmit = (data) => {
    const { nextStepId } = formWizard;
    const updateData = {
      roleCreationMethod: data.verify.roleCreationSetup,
    };

    onNext({ data: updateData, nextStepId });
  };

  return (
    <Form.Form name={constants.stepIds.step4} submitAction={handleSubmit}>
      <RoleCreationMethod
        awsAccountId={awsAccountId}
        awsCloudFormationQuickCreateUrl={awsCloudFormationQuickCreateUrl}
        computePlane={computePlane}
        cfStackName={cfStackName}
        cloudFormationTemplate={cloudFormationTemplate}
        awsCloudFormationUrl={awsCloudFormationUrl}
        terraformContent={terraformContent}
        terraformFileName={terraformFileName}
      />
      <VerifyRoleCreation
        onVerifyProvisioningRole={onVerifyProvisioningRole}
        verifyRoleInProgress={verifyRoleInProgress}
        error={verifyRoleError}
      />
      <Page.Buttons>
        <Form.SecondaryButton
          label='Back'
          onClick={onBack}
          disabled={verifyRoleInProgress || submitInProgress}
          disabledOnErrors={false}
        />
        <Form.Submit
          label='Next'
          disabled={
            verifyRoleInProgress || !hasVerifyRoleSuccess || submitInProgress
          }
          disabledOnErrors={false}
          showLoading={submitInProgress}
        />
      </Page.Buttons>
    </Form.Form>
  );
};

CreateProvisioningRole.defaultProps = {
  awsAccountId: null,
  computePlane: null,
  cfStackName: null,
  hasVerifyRoleSuccess: false,
  verifyRoleError: undefined,
  verifyRoleInProgress: false,
  terraformContent: null,
  terraformFileName: null,
  submitInProgress: false,
};

CreateProvisioningRole.propTypes = {
  awsAccountId: string,
  awsCloudFormationQuickCreateUrl: string.isRequired,
  cloudFormationTemplate: string.isRequired,
  computePlane: shape({
    computePlaneId: string,
  }),
  cfStackName: string,
  hasVerifyRoleSuccess: bool,
  verifyRoleError: string,
  verifyRoleInProgress: bool,
  onBack: func.isRequired,
  onNext: func.isRequired,
  onVerifyProvisioningRole: func.isRequired,
  awsCloudFormationUrl: string.isRequired,
  terraformContent: string,
  terraformFileName: string,
  submitInProgress: bool,
};
