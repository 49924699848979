import { bool, number } from 'prop-types';
import AWSUnsubscribed from './AWSUnsubscribed';
import AWSFailed from './AWSFailed';
import ComputePlaneUpgrade from './ComputePlaneUpgrade';
import ComputePlaneLowerThanMinimumVersion from './ComputePlaneLowerThanMinimumVersion';
import Trial from './Trial';
import TrialExpired from './TrialExpired';

const Footer = ({
  displayFooter,
  displayAWSUnsubscribed,
  displayAWSFailed,
  displayTrial,
  displayTrialExpired,
  trialDaysLeft,
  displayComputePlaneUpgrade,
  displayComputePlaneLowerThanMinimumVersion,
}) => {
  if (!displayFooter) return null;

  if (displayAWSUnsubscribed) {
    return <AWSUnsubscribed />;
  }

  if (displayAWSFailed) {
    return <AWSFailed />;
  }

  if (displayTrial) {
    return <Trial trialDaysLeft={trialDaysLeft} />;
  }

  if (displayTrialExpired) {
    return <TrialExpired />;
  }

  if (displayComputePlaneUpgrade) {
    return <ComputePlaneUpgrade />;
  }

  if (displayComputePlaneLowerThanMinimumVersion) {
    return <ComputePlaneLowerThanMinimumVersion />;
  }
  return null;
};

Footer.defaultProps = {
  displayAWSUnsubscribed: false,
  displayAWSFailed: false,
  displayTrial: false,
  displayTrialExpired: false,
  trialDaysLeft: null,
  displayComputePlaneUpgrade: false,
  displayComputePlaneLowerThanMinimumVersion: false,
};

Footer.propTypes = {
  displayFooter: bool.isRequired,
  displayAWSUnsubscribed: bool,
  displayAWSFailed: bool,
  displayTrial: bool,
  displayTrialExpired: bool,
  trialDaysLeft: number,
  displayComputePlaneUpgrade: bool,
  displayComputePlaneLowerThanMinimumVersion: bool,
};

export default Footer;
