const actionSanitizers = new Map();

const registerActionSanitizer = (name, actionSanitizer) => {
  if (actionSanitizers.has(name)) {
    throw new Error(`Action sanitizer with name ${name} already registered`);
  }

  actionSanitizers.set(name, actionSanitizer);
};

const sanitizeAction = (originalAction) => {
  let action = originalAction;

  for (const actionSanitizer of actionSanitizers.values()) {
    action = actionSanitizer(action);
    if (!action) {
      return null;
    }
  }

  return action;
};

const module = {
  registerActionSanitizer,
  sanitizeAction,
};

export default module;
