import { connect } from '../../utils/redux';
import { PrestoUsers } from './components';
import * as actions from './actions';
import * as selectors from './selectors';

const mapStateToProps = (state) => {
  return {
    loadingInProgress: selectors.getLoadingInProgress(state),
    prestoUsers: selectors.getPrestoUsers(state),
    error: selectors.getError(state),
  };
};

export default connect(mapStateToProps, {
  getPrestoUsers: actions.getAll,
})(PrestoUsers);
