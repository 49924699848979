import actionSanitizer from './actionSanitizer';
import networkSanitizer from './networkSanitizer';
import stateSanitizer from './stateSanitizer';
import telemetry from './telemetry';
import * as utils from './utils';

const module = {
  actionSanitizer,
  networkSanitizer,
  stateSanitizer,
  ...telemetry,
  ...utils,
};

export default module;
