import { buildUrl } from '../../utils/buildUrl';
import telemetry from '../../telemetry';
import rayApi from '../../rayApi';
import noop from '../../utils/noop';

const baseResource = 'prestoClusters';

export const getAll = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: baseResource });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const get = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getDataSources = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/datasources`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const post = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: baseResource });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePOST(uri, headers, prepedBody, abortFetchSignal);
};

export const patch = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePATCH(uri, headers, prepedBody, abortFetchSignal);
};

export const remove = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executeDELETE(uri, headers, prepedBody, abortFetchSignal);
};

export const putRestart = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/restart`,
  });
  return rayApi.api.executePUT(uri, headers, body, abortFetchSignal);
};

export const putStart = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/start`,
  });
  return rayApi.api.executePUT(uri, headers, body, abortFetchSignal);
};

export const putStop = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/stop`,
  });
  return rayApi.api.executePUT(uri, headers, body, abortFetchSignal);
};

export const patchWorkerNodes = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/workernodes`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePATCH(uri, headers, prepedBody, abortFetchSignal);
};

export const putDataSources = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/datasources`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};

export const putCredentials = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/credentials`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};

export const putExportLogs = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/exportlogs`,
  });
  return rayApi.api.executePUT(uri, headers, body, abortFetchSignal);
};

export const putTriggerWorkflow = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/triggerworkflow`,
  });
  const prepedBody = body ? rayApi.api.buildBody(body) : null;
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};

export const putAutoScalingPolicy = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/autoscaling`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};

export const putGroupedConfig = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/groupedconfig`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};

export const putEnableSpot = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/spotinstances`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};

export const putPrestoUsers = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoClusterId}/prestousers`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePUT(uri, headers, prepedBody, abortFetchSignal);
};

const networkRequestSanitizer = (request) => {
  let sanitizedRequest = request;
  if (
    (sanitizedRequest.url.endsWith(baseResource) &&
      sanitizedRequest.method === 'POST') ||
    (sanitizedRequest.url.includes(baseResource) &&
      sanitizedRequest.url.endsWith('credentials') &&
      sanitizedRequest.method === 'PUT')
  ) {
    sanitizedRequest = {
      ...sanitizedRequest,
      body: telemetry.sanitize(sanitizedRequest.body),
    };
  }
  return sanitizedRequest;
};

telemetry.networkSanitizer.registerNetworkSanitizer(
  'prestoClusters',
  networkRequestSanitizer,
  noop,
);
