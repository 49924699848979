import StopIcon from '../../../icons/Stop';
import Links from '../../Page/Links';
import { FooterDiv, FooterBoxDiv } from './styledComponents';

const AWSFailed = () => {
  return (
    <FooterDiv className='error'>
      <FooterBoxDiv className='error'>
        <div className='icon'>
          <StopIcon />
        </div>
        <div className='message'>
          <h3>Your subscription to IBM watsonx.data Cloud failed</h3>
          <div>
            Please visit the IBM watsonx.data AWS Marketplace listing to retry
            to subscribe
          </div>
        </div>
        <div>
          <Links.TertiaryLink
            to={process.env.REACT_APP_MARKETPLACE_URL}
            external
            newTab
          >
            Go to AWS Marketplace
          </Links.TertiaryLink>
        </div>
      </FooterBoxDiv>
    </FooterDiv>
  );
};

export default AWSFailed;
