import { bool, object, arrayOf } from 'prop-types';
import Form from '../../Form';
import { DropDownValue } from '../propTypes';
import { mustBeValidYaml } from '../utils';
import Help from './ConfigurationDetailsHelp';

const display = {
  formContentHeader: 'Configuration',
  instanceTypeLabel: 'Instance Type',
  instanceTypeRequiredError: 'required',
  prometheusRemoteWriteLabel: 'Prometheus Remote Write',
  secondLabel: 'Please re-enter or change your credentials',
};

const ConfigurationDetails = ({ disabled, prometheus, instanceTypeValues }) => {
  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
      </div>
      <div>
        <Form.SelectInput
          name='prometheus.instanceType'
          label={display.instanceTypeLabel}
          disabled={disabled}
          defaultValue={prometheus?.instanceType || 't3.xlarge'}
          items={instanceTypeValues}
          validationRules={{
            required: display.instanceTypeRequiredError,
          }}
          fieldHelp={Help.instanceType}
        />
        <Form.MultiLineInput
          name='prometheus.remoteWrite'
          label={display.prometheusRemoteWriteLabel}
          disabled={disabled}
          optional
          defaultValue={prometheus?.displayRemoteWriteRule}
          fieldHelp={Help.remoteWrite}
          validationRules={{
            validate: mustBeValidYaml,
          }}
        />
      </div>
    </>
  );
};

ConfigurationDetails.defaultProps = {
  disabled: false,
  prometheus: null,
};

ConfigurationDetails.propTypes = {
  disabled: bool,
  // eslint-disable-next-line react/forbid-prop-types
  prometheus: object,
  instanceTypeValues: arrayOf(DropDownValue).isRequired,
};

export default ConfigurationDetails;
