import { useEffect, useCallback, useState, useMemo } from 'react';
import { string, bool, func } from 'prop-types';
import Banner from '../../Banner';
import Data from '../../Data';
import Page from '../../Page';
import Form from '../../Form';
import { useEnableFeature } from '../../utils/featureHooks';
import * as propTypes from '../propTypes';
import * as routes from '../routes';
import { NoDataDiv, SpanningContentDiv } from './styledComponent';
import * as constants from '../constants';

const display = {
  title: 'Identity Provider',
  bannerTitleDelete: 'Deleting Identity Provider',
  bannerDetailsDelete:
    'Are you sure you want to delete this identity provider? Once deleted, only basic authentication will be available for Presto clusters.',
  configurationHeader: 'Identity Provider Configuration',
  redirectUrlLabel: 'Sign-in Redirect URL',
  nameLabel: 'Name',
  wellKnownConfigUrlLabel: 'Well Known Config URL',
  clientIdLabel: 'Client ID',
  statusLabel: 'Status',
  noIdentityProviderLabel: 'There is no identity provider.',
  addIdentityProvider: 'Add Identity Provider',
  loadingLabel: 'Loading...',
  modifyIdentityProvider: 'Edit Identity Provider',
  deleteIdentityProvider: 'Delete',
  cancelButton: 'Cancel',
  errorCreate: 'Error Creating Identity Provider',
  errorDestroy: 'Error Destroying Identity Provider',
};

const checkStatus = (status) => {
  switch (status) {
    case constants.status.init_failure:
      return display.errorCreate;
    case constants.status.destroy_failure:
      return display.errorDestroy;
    default:
      return null;
  }
};

const IdentityProvider = ({
  loadingInProgress,
  identityProvider,
  enableFeature,
  loadIdentityProviders,
  loadPrestoClusters,
  destroy,
  canDestroy,
  error,
  errorMessagesText,
}) => {
  const enableEditLink = useEnableFeature();
  useEffect(() => {
    loadPrestoClusters();
    loadIdentityProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [confirmSubmitDelete, setConfirmSubmitDelete] = useState(false);

  const onDelete = useCallback(async () => {
    setConfirmSubmitDelete(true);
  }, []);

  const handleCancelConfirmDelete = useCallback(() => {
    setConfirmSubmitDelete(false);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    setConfirmSubmitDelete(false);
    return destroy(identityProvider?.identityProviderId);
  }, [identityProvider, destroy]);

  const showError = useMemo(() => {
    return checkStatus(identityProvider?.status) || error;
  }, [identityProvider, error]);

  const showAdd = useMemo(() => {
    return enableEditLink && !loadingInProgress && !identityProvider;
  }, [loadingInProgress, identityProvider, enableEditLink]);

  const showModify = useMemo(() => {
    return enableEditLink && identityProvider;
  }, [identityProvider, enableEditLink]);

  const disableDestroy = useMemo(() => {
    return (
      !canDestroy ||
      identityProvider?.status === constants.status.initializing ||
      identityProvider?.status === constants.status.destroying ||
      identityProvider?.status === constants.status.patching
    );
  }, [identityProvider, canDestroy]);

  const disableEdit = useMemo(() => {
    return (
      showError ||
      identityProvider?.status === constants.status.initializing ||
      identityProvider?.status === constants.status.destroying ||
      identityProvider?.status === constants.status.patching
    );
  }, [identityProvider, showError]);

  if (!enableFeature) return null;

  return (
    <>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
        {showAdd && (
          <div className='buttons'>
            <Page.PrimaryLink
              to={routes.getAddIdentityProviderRoute()}
              disabled={!showAdd}
            >
              {display.addIdentityProvider}
            </Page.PrimaryLink>
          </div>
        )}
        {showModify && (
          <div className='buttons'>
            <Form.SecondaryButton onClick={onDelete} disabled={disableDestroy}>
              {display.deleteIdentityProvider}
            </Form.SecondaryButton>
            <Page.PrimaryLink
              to={routes.getUpdateIdentityProviderRoute(
                identityProvider?.identityProviderId,
              )}
              disabled={disableEdit}
            >
              {display.modifyIdentityProvider}
            </Page.PrimaryLink>
          </div>
        )}
      </Page.BoxHeader>
      {confirmSubmitDelete && (
        <Banner title={display.bannerTitleDelete}>
          <p>{display.bannerDetailsDelete}</p>
          <div className='buttons'>
            <Form.SecondaryButton
              onClick={handleCancelConfirmDelete}
              label={display.cancelButton}
            />
            <Form.ErrorButton
              onClick={handleConfirmDelete}
              label={display.deleteIdentityProvider}
              disabled={disableDestroy}
            />
          </div>
        </Banner>
      )}
      {showError && <Banner title={showError} details={errorMessagesText} />}
      {loadingInProgress && !identityProvider && (
        <NoDataDiv>{display.loadingLabel}</NoDataDiv>
      )}
      {!loadingInProgress && !identityProvider && (
        <NoDataDiv>{display.noIdentityProviderLabel}</NoDataDiv>
      )}
      {identityProvider && (
        <SpanningContentDiv>
          <div>
            <h3>{display.configurationHeader}</h3>
          </div>
          <div>
            <Data.DataFieldWithCopy
              label={display.redirectUrlLabel}
              value={identityProvider.redirectUrl}
            />
            <Data.DataField
              label={display.nameLabel}
              value={identityProvider.name}
            />
            <Data.DataField
              label={display.wellKnownConfigUrlLabel}
              value={identityProvider.wellKnownConfigUrl}
            />
            <Data.DataField
              label={display.clientIdLabel}
              value={identityProvider.clientId}
            />
            <Data.DataField
              label={display.statusLabel}
              value={identityProvider.status}
            />
          </div>
        </SpanningContentDiv>
      )}
    </>
  );
};

IdentityProvider.defaultProps = {
  identityProvider: null,
  canDestroy: false,
  error: undefined,
  errorMessagesText: null,
};

IdentityProvider.propTypes = {
  loadingInProgress: bool.isRequired,
  identityProvider: propTypes.IdentityProvider,
  enableFeature: bool.isRequired,
  loadIdentityProviders: func.isRequired,
  loadPrestoClusters: func.isRequired,
  canDestroy: bool,
  destroy: func.isRequired,
  error: string,
  errorMessagesText: string,
};

export default IdentityProvider;
