/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import { useCallback } from 'react';
import { string, bool, shape, any, arrayOf, func, object } from 'prop-types';
import noop from '../../../utils/noop';
import Table from '../../Table';

import Page from '../../Page';
import Form from '../../Form';
import Banner from '../../Banner';
import { status } from '../enums';
import BucketStatus from './BucketStatus';
import * as propTypes from '../propTypes';
import { convertTimestampStrToLocaleString } from '../../../utils/dateUtils';
import { onAccessibleClick } from '../../utils/accessibility';
import { useEnableFeature } from '../../utils/featureHooks';

const display = {
  bucketNameColumnLabel: 'S3 Bucket Name',
  prestoClusterColumnLabel: 'Presto Cluster',
  bucketTypeColumnLabel: 'Bucket Type',
  creationDateColumnLabel: 'Creation Date',
  statusColumnLabel: 'Status',
  viewButtonLabel: 'View',
  deleteButtonLabel: 'Delete',
  deletingButtonLabel: 'Deleting...',
  queryBucketDeletionMessage:
    'A Query Log S3 Bucket contains execution information on the queries you ran on the associated cluster. Deleting a bucket cannot be undone.',
  hiveBucketDeletionMessage:
    'A Cluster Data S3 Bucket contains data file created by the CREATE operation on the cluster. Deleting a bucket cannot be undone.',
};

const ManageCell = ({ data, additionalContext, key }) => {
  const { enableDelete } = additionalContext;
  const selectedAlertId = Table.useAlertIdStateContext();
  const setAlertId = Table.useAlertIdDispatchContext();
  const onClick = useCallback(
    (event) => {
      return onAccessibleClick(event, () => {
        setAlertId(data.id);
      });
    },
    [setAlertId, data.id],
  );

  return (
    <Table.ButtonCell key={key}>
      {data.status !== status.deleting && (
        <Page.TertiaryLink
          to={data.to}
          disabled={data.status === status.deleting}
          external
          newTab
        >
          {display.viewButtonLabel}
        </Page.TertiaryLink>
      )}{' '}
      <Form.ErrorButton
        onClick={onClick}
        disabled={
          ![status.detached, status.delete_failed].includes(data.status) ||
          (selectedAlertId && selectedAlertId !== data.id) ||
          !enableDelete
        }
        showLoading={data.status === status.deleting}
      >
        {data.status === status.deleting
          ? display.deletingButtonLabel
          : display.deleteButtonLabel}
      </Form.ErrorButton>
    </Table.ButtonCell>
  );
};

ManageCell.propTypes = {
  data: shape({
    to: string.isRequired,
    status: bool.isRequired,
    id: bool.isRequired,
  }).isRequired,
  additionalContext: shape({
    enableDelete: bool.isRequired,
  }).isRequired,
  key: string.isRequired,
};

const alertIdSelector = (bucket) => {
  return bucket.bucketId;
};

const columns = [
  {
    name: display.bucketNameColumnLabel,
    selector: (bucket) => {
      return bucket.name;
    },
    cellWidth: '2fr',
  },
  {
    name: display.prestoClusterColumnLabel,
    selector: (bucket) => {
      return bucket.prestoClusterName;
    },
    cellWidth: '1fr',
  },
  {
    name: display.bucketTypeColumnLabel,
    selector: (bucket) => {
      return bucket.type;
    },
    cellWidth: '1fr',
  },
  {
    name: display.creationDateColumnLabel,
    selector: (bucket) => {
      return convertTimestampStrToLocaleString(Date.parse(bucket.createDate));
    },
    cellWidth: '1fr',
  },
  {
    name: display.statusColumnLabel,
    selector: (bucket) => {
      return <BucketStatus bucketStatus={bucket.status.toLowerCase()} />;
    },
    cellWidth: '1fr',
  },
  {
    name: 'Manage',
    selector: (bucket) => {
      return {
        to: bucket.awsUrl,
        status: bucket.status,
        id: bucket.bucketId,
      };
    },
    headerRender: noop,
    cellRender: ManageCell,
    cellWidth: '1fr',
  },
];

const dataKeyGenerator = (bucket) => {
  return bucket.bucketId;
};

const AlertRow = ({ data, additionalContext, key, className, style }) => {
  const setAlertId = Table.useAlertIdDispatchContext();
  const onCancel = useCallback(
    (e) => {
      return onAccessibleClick(e, () => {
        setAlertId(null);
      });
    },
    [setAlertId],
  );
  const { deleteBucket } = additionalContext;
  const onConfirmDelete = useCallback(
    (e) => {
      return onAccessibleClick(e, () => {
        deleteBucket(data.bucketId);
        setAlertId(null);
      });
    },
    [deleteBucket, data.bucketId, setAlertId],
  );

  return (
    <Table.DataCell key={key} className={className} style={style}>
      <Banner
        title={`Deleting ${data.type} S3 Bucket`}
        details={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <span>
            Are you sure you want to delete bucket{' '}
            <strong>&#34;{data.name}&#34;</strong> created on{' '}
            <strong>
              {convertTimestampStrToLocaleString(Date.parse(data.createDate))}
            </strong>
            ?{' '}
            {data.type === 'Query Log'
              ? display.queryBucketDeletionMessage
              : display.hiveBucketDeletionMessage}
          </span>
        }
      >
        <div className='buttons'>
          <Form.SecondaryButton onClick={onCancel} label='Cancel' />
          <Form.ErrorButton onClick={onConfirmDelete} label='Delete' />
        </div>
      </Banner>
    </Table.DataCell>
  );
};

AlertRow.defaultProps = {
  additionalContext: null,
  className: null,
  style: null,
};

AlertRow.propTypes = {
  data: shape({
    name: string.isRequired,
    createDate: string.isRequired,
  }).isRequired,
  additionalContext: any,
  key: string.isRequired,
  className: string,
  style: object,
};

const BucketsTable = ({ buckets, deleteBucket }) => {
  const enableDelete = useEnableFeature({
    requireValidSubscription: false,
    requireActiveComputePlane: true,
  });

  return (
    <Table.Table
      data={buckets}
      columns={columns}
      dataKeyGenerator={dataKeyGenerator}
      alert={AlertRow}
      alertIdSelector={alertIdSelector}
      additionalContext={{ deleteBucket, enableDelete }}
    />
  );
};

BucketsTable.defaultProps = {
  buckets: null,
};

BucketsTable.propTypes = {
  buckets: arrayOf(propTypes.Bucket),
  deleteBucket: func.isRequired,
};

export default BucketsTable;
