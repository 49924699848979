import { makeReducer } from '../../utils/reducer';
import * as actionTypes from './actionTypes';

const initialState = {
  activeStepId: null,
  steps: [],
};

const setActiveStep = (draft, action) => {
  draft.activeStepId = action.payload.activeStepId;
};

const initialize = (draft, action) => {
  draft.steps = action.payload.steps;
  draft.activeStepId = action.payload.initialStep
    ? action.payload.initialStep
    : action.payload.steps[0]?.id;
};

const actionReducers = {
  [actionTypes.setActiveStep]: setActiveStep,
  [actionTypes.initialize]: initialize,
};

export default makeReducer(initialState, actionReducers);
