import telemetry from '../telemetry';
import { makeReducer } from '../utils/reducer';
import * as actionTypes from './actionTypes';

const initialState = {
  finishedInitialCheck: false,
  jwtToken: null,
  role: null,
  serviceTier: null,
  emailAddress: null,
  name: null,
  issuer: null,
  expirationTimestamp: null,
  tenantId: null,
  error: null,
};

const updateAuth = (draft, action) => {
  draft.error = null;
  draft.jwtToken = action.payload.apiResult?.body?.accessToken;
  draft.emailAddress = action.payload.apiResult?.body?.profile?.emails?.[0];
  draft.name = action.payload.apiResult?.body?.profile?.displayName;
  draft.tenantId = action.payload.apiResult.body.tenantId;
  // draft.issuer = action.payload.issuer;
  if (action.payload.apiResult?.body?.expiration) {
    draft.expirationTimestamp = action.payload.apiResult.body.expiration * 1000;
  }
  // draft.userId = action.payload.userId;

  // Not sure if this is staying around at all.
  // telemetry.setUser(
  //   action.payload.userId,
  //   action.payload.emailAddress,
  //   action.payload.name,
  //   action.payload.role,
  //   action.payload.serviceTier,
  //   action.payload.tenantId
  // );
  // ineum('user', draft.name);
  ineum('meta', 'tenantId', draft.tenantId);
};

const finishedInitialCheck = (draft) => {
  draft.finishedInitialCheck = true;
};

const signOut = () => {
  return { ...initialState, finishedInitialCheck: true, error: null };
};

const error = (draft, action) => {
  draft.error = {
    message: action.payload.apiResult.body.errorMessage,
    status: action.payload.apiResult.status,
  };
};

const actionReducers = {
  [actionTypes.signOut]: signOut,
  [actionTypes.finishedInitialCheck]: finishedInitialCheck,
  [actionTypes.getAuth.success]: updateAuth,
  [actionTypes.getAuth.failure]: error,
};

const stateSanitizer = (state) => {
  return {
    ...state,
    auth: {
      ...state.auth,
      jwtToken: telemetry.sanitize(state.auth.jwtToken),
    },
  };
};

telemetry.stateSanitizer.registerStateSanitizer('auth', stateSanitizer);

export default makeReducer(initialState, actionReducers);
