import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';

export const deletePrometheus = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const baseUri = rootUri;
  const uri = buildUrl({
    baseUrl: baseUri,
    path: `computeplanes/${params.computePlaneId}/prometheus`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executeDELETE(uri, headers, prepedBody, abortFetchSignal);
};

export const patchPrometheusJMX = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const baseUri = rootUri;
  const uri = buildUrl({
    baseUrl: baseUri,
    path: `computeplanes/${params.computePlaneId}/prometheus/jmx`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePATCH(uri, headers, prepedBody, abortFetchSignal);
};
