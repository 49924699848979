import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool } from 'prop-types';
import { connectionParametersOrConfigurationPropertiesRegex } from '../../../utils/regex';
import Form from '../../Form';
import DataSources from '../../DataSources';
import { required, portValidation, endpointValidation } from '../utils';
import Help from './IBMDb2ConnectionDetailsHelp';

const display = {
  formContentHeader: 'Connection Configuration',
  formExplaination: 'Secondary explanation',
  urlLabel: 'JDBC URL',
  endpointLabel: 'Hostname',
  endpointRequiredError: 'Hostname is required',
  endpointPatternError: 'Hostname is not in valid format',
  portLabel: 'Port',
  portRequiredError: 'Port is required',
  portNotANumberError: 'Port should be a number',
  configurationPropertiesLabel: 'Configuration Properties',
  configurationPropertiesIllegalCharactersError:
    'Configuration properties should be in the format: parameter=value;parameter=value with valid characters',
  databaseLabel: 'Database Name',
  databaseRequiredError: 'Database name is required',
  userLabel: 'User',
  userRequiredError: 'User is required',
  passwordLabel: 'Password',
  passwordRequiredError: 'Password is required',
  enableSSLConnectionLabel: 'SSL connection',
};

const type = DataSources.constants.dataSourceTypes.db2;

const IBMDb2ConnectionDetails = ({ disabled, active, dataSource }) => {
  const { getValues, clearErrors } = useFormContext();

  useEffect(() => {
    if (!active) {
      clearErrors('db2');
    }
  }, [active, clearErrors]);

  return (
    <>
      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.formContentHeader}</h3>
        {/* <div>{display.formExplaination}</div> */}
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.TextInput
          name='db2.endpoint'
          label={display.endpointLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.metadata.endpoint}
          validationRules={{
            validate: required(
              getValues,
              type,
              display.endpointPatternError,
              endpointValidation(display.endpointPatternError),
            ),
          }}
          fieldHelp={Help.Hostname}
        />
        <Form.TextInput
          type='integer'
          name='db2.port'
          label={display.portLabel}
          disabled={disabled}
          defaultValue={(dataSource && dataSource.metadata.port) || '50000'}
          validationRules={{
            validate: required(
              getValues,
              type,
              display.portRequiredError,
              portValidation,
            ),
            valueAsNumber: true,
          }}
          fieldHelp={Help.Port}
        />
        <Form.TextInput
          name='db2.database'
          label={display.databaseLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.metadata.database}
          validationRules={{
            validate: required(getValues, type, display.databaseRequiredError),
          }}
          fieldHelp={Help.Database}
        />
        <Form.TextInput
          name='db2.configurationProperties'
          label={display.configurationPropertiesLabel}
          disabled={disabled}
          optional
          defaultValue={
            dataSource && dataSource.metadata.configurationProperties
          }
          validationRules={{
            pattern: {
              value: connectionParametersOrConfigurationPropertiesRegex,
              message: display.configurationPropertiesIllegalCharactersError,
            },
          }}
          fieldHelp={Help.ConfigurationProperties}
        />
        <Form.TextInput
          name='db2.user'
          label={display.userLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.connection['connection-user']}
          validationRules={{
            validate: required(getValues, type, display.userRequiredError),
          }}
          fieldHelp={Help.User}
        />
        <Form.PasswordInput
          name='db2.password'
          label={display.passwordLabel}
          disabled={disabled}
          validationRules={{
            validate: required(getValues, type, display.passwordRequiredError),
          }}
          fieldHelp={Help.Password}
        />
        <Form.CheckboxInput
          name='db2.sslConnection'
          label={display.enableSSLConnectionLabel}
          defaultChecked={
            dataSource && dataSource.metadata.sslConnection === false
              ? undefined
              : true
          }
          fieldHelp={Help.SSLEnabled}
        />
      </div>
    </>
  );
};

IBMDb2ConnectionDetails.defaultProps = {
  disabled: false,
  dataSource: null,
};

IBMDb2ConnectionDetails.propTypes = {
  disabled: bool,
  active: bool.isRequired,
  dataSource: DataSources.propTypes.DataSource,
};

export default IBMDb2ConnectionDetails;
