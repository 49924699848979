import { string } from 'prop-types';
import { status } from '../enums';
import { SuccessBadge, WarningBadge } from '../../Badge';

const Success = (statusLabel) => {
  return <SuccessBadge>{statusLabel}</SuccessBadge>;
};

const Warning = (statusLabel) => {
  return <WarningBadge>{statusLabel}</WarningBadge>;
};

const BucketStatus = ({ bucketStatus }) => {
  let statusLabel;

  if ([status.detached, status.delete_failed].includes(bucketStatus)) {
    statusLabel = status.detached;
  } else {
    statusLabel = bucketStatus;
  }

  return bucketStatus === status.attached
    ? Success(statusLabel)
    : Warning(statusLabel);
};

BucketStatus.propTypes = {
  bucketStatus: string.isRequired,
};

export default BucketStatus;
