/* eslint-disable react/jsx-props-no-spreading */
import Loading from '../Loading';
import { PageDiv, PageEmptyDiv } from './styledComponents';

const LoadingPage = (props) => {
  return (
    <PageDiv>
      <PageEmptyDiv>
        <Loading.Loading {...props} />
      </PageEmptyDiv>
    </PageDiv>
  );
};

export default LoadingPage;
