export const prettifyJSON = (json) => {
  if (json === null || json === undefined) {
    return json;
  }

  if (typeof json === 'string') {
    const c = JSON.parse(json);
    return JSON.stringify(c, null, 4);
  }

  return JSON.stringify(json, null, 4);
};
