/* eslint-disable react/jsx-props-no-spreading */
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { bool } from 'prop-types';
import { createBrowserHistory } from 'history';
import { connect } from '../utils/redux';
import { buildUrl } from '../utils/buildUrl';
import * as environment from '../utils/environment';
import auth from '../auth';
import AccountSetup from '../components/AccountSetup';
import AuthorizationService from '../components/AuthorizationService';
import AuthorizationServices from '../components/AuthorizationServices';
import AuthorizationServiceAdd from '../components/AuthorizationServiceAdd';
import AuthorizationServiceUpdate from '../components/AuthorizationServiceUpdate';
import ComputePlane from '../components/ComputePlane';
import ComputePlaneAdd from '../components/ComputePlaneAdd';
import ComputePlaneDestroy from '../components/ComputePlaneDestroy';
import DataSource from '../components/DataSource';
import DataSources from '../components/DataSources';
import DataSourcesPage from '../components/DataSourcesPage';
import DataSourceAdd from '../components/DataSourceAdd';
import DataSourceUpdate from '../components/DataSourceUpdate';
import ErrorPage from '../components/ErrorPage';
import Footer from '../components/Footer';
import HomePage from '../components/HomePage';
import IAMPolicy from '../components/IAMPolicy';
import IdentityProvidersAdd from '../components/IdentityProviderAdd';
import IdentityProviders from '../components/IdentityProviders';
import IdentityProvidersUpdate from '../components/IdentityProvidersUpdate';
import PrestoCluster from '../components/PrestoCluster';
import PrestoClusters from '../components/PrestoClusters';
import PrestoClusterAdd from '../components/PrestoClusterAdd';
import PrestoClusterAttach from '../components/PrestoClusterAttachDataSource';
import PrestoClusterAutoScalingPolicy from '../components/PrestoClusterChangeAutoScalingPolicy';
import PrestoUser from '../components/PrestoUser';
import PrestoUsers from '../components/PrestoUsers';
import PrestoUsersAdd from '../components/PrestoUsersAdd';
import PrestoUsersUpdate from '../components/PrestoUsersUpdate';
import PrestoClusterEnableSpot from '../components/PrestoClusterChangeEnableSpot';
import PrestoClusterGroupedConfig from '../components/PrestoClusterChangeGroupedConfig';
import PrestoClusterPrestoUsers from '../components/PrestoClusterChangePrestoUsers';
import Security from '../components/Security';
import SignIn from '../components/SignIn';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import RootRoute from './RootRoute';
import Base from './Base';
import PrestoDebugging from '../components/PrestoDebugging';
import StandalonePrometheus from '../components/StandalonePrometheus';
import StandalonePrometheusAdd from '../components/StandalonePrometheusAdd';
// import StandalonePrometheusDestroy from '../components/StandalonePrometheusDestroy';

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const createRouters = (props) => {
  return createBrowserRouter([
    {
      element: <Base />,
      children: [
        {
          path: '/',
          element: <RootRoute authenticated={props.authenticated} />,
        },

        /* Public Routes */
        {
          path: SignIn.route.getRoute(),
          element: <PublicRoute component={SignIn.Container} {...props} />,
        },

        /* Home Page Route */
        {
          path: HomePage.route.getRoute(),
          element: <PrivateRoute component={HomePage.Container} {...props} />,
        },

        /* Compute Plane Setup and Settings Routes */
        {
          path: ComputePlane.routes.viewComputePlaneRoute(),
          element: (
            <PrivateRoute component={ComputePlane.Container} {...props} />
          ),
        },
        {
          path: ComputePlane.routes.addComputePlaneRoute(),
          element: (
            <PrivateRoute component={ComputePlaneAdd.Container} {...props} />
          ),
        },
        {
          path: ComputePlane.routes.destroyComputePlaneRoute(),
          element: (
            <PrivateRoute
              component={ComputePlaneDestroy.Container}
              {...props}
            />
          ),
        },
        {
          path: IAMPolicy.routes.getIAMPolicyRoute(':policyType'),
          element: (
            <PrivateRoute component={IAMPolicy.Container} {...props} noNav />
          ),
        },
        /* Authorization Services Routes */
        {
          path: AuthorizationServices.routes.getAddAuthorizationServiceRoute(),
          element: (
            <PrivateRoute
              component={AuthorizationServiceAdd.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: AuthorizationServices.routes.getUpdateAuthorizationServiceRoute(
            ':authorizationServiceId',
          ),
          element: (
            <PrivateRoute
              component={AuthorizationServiceUpdate.Container}
              {...props}
            />
          ),
        },
        {
          path: AuthorizationServices.routes.getManageAuthorizationServiceRoute(
            ':authorizationServiceId',
          ),
          element: (
            <PrivateRoute
              component={AuthorizationService.Container}
              {...props}
            />
          ),
        },
        /* Data Sources Routes */
        {
          path: DataSources.routes.getAddDataSourceRoute(),
          element: (
            <PrivateRoute
              component={DataSourceAdd.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: DataSources.routes.getUpdateDataSourceRoute(':dataSourceId'),
          element: (
            <PrivateRoute
              component={DataSourceUpdate.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: DataSources.routes.getManageDataSourceRoute(':dataSourceId'),
          element: <PrivateRoute component={DataSource.Container} {...props} />,
        },
        {
          path: DataSources.routes.getRoute(),
          element: (
            <PrivateRoute component={DataSourcesPage.Container} {...props} />
          ),
        },

        /* Presto Cluster Routes */
        {
          path: PrestoClusters.routes.getAddPrestoClusterRoute(),
          element: (
            <PrivateRoute
              component={PrestoClusterAdd.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: PrestoClusters.routes.getManagePrestoClusterDataSourcesRoute(
            ':prestoClusterId',
          ),
          element: (
            <PrivateRoute
              component={PrestoClusterAttach.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: PrestoClusters.routes.getPrestoClusterGroupedConfigRoute(
            ':prestoClusterId',
          ),
          element: (
            <PrivateRoute
              component={PrestoClusterGroupedConfig.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: PrestoClusters.routes.getPrestoClusterEnableSpotRoute(
            ':prestoClusterId',
          ),
          element: (
            <PrivateRoute
              component={PrestoClusterEnableSpot.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: PrestoClusters.routes.getPrestoClusterAutoScalingPolicy(
            ':prestoClusterId',
          ),
          element: (
            <PrivateRoute
              component={PrestoClusterAutoScalingPolicy.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: PrestoClusters.routes.getPrestoClusterPrestoUsersRoute(
            ':prestoClusterId',
          ),
          element: (
            <PrivateRoute
              component={PrestoClusterPrestoUsers.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: PrestoClusters.routes.getManagePrestoClusterRoute(
            ':prestoClusterId',
          ),
          element: (
            <PrivateRoute component={PrestoCluster.Container} {...props} />
          ),
        },
        {
          path: PrestoClusters.routes.getRoute(),
          element: (
            <PrivateRoute component={PrestoClusters.Container} {...props} />
          ),
        },

        /* Presto User Routes */
        {
          path: PrestoUsersAdd.route.getRoute(),
          element: (
            <PrivateRoute
              component={PrestoUsersAdd.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: PrestoUsers.routes.getUpdatePrestoUserRoute(':prestoUserId'),
          element: (
            <PrivateRoute
              component={PrestoUsersUpdate.Container}
              {...props}
              noNav
            />
          ),
        },
        {
          path: PrestoUsers.routes.getManagePrestoUserRoute(':prestoUserId'),
          element: <PrivateRoute component={PrestoUser.Container} {...props} />,
        },
        {
          path: Security.route.getRoute(),
          element: <PrivateRoute component={Security.Container} {...props} />,
        },

        /* Identity Provider Routes */
        {
          path: IdentityProviders.routes.getAddIdentityProviderRoute(),
          element: (
            <PrivateRoute
              component={IdentityProvidersAdd.Container}
              {...props}
              noNav
            />
          ),
        },

        {
          path: IdentityProviders.routes.getUpdateIdentityProviderRoute(
            ':identityProviderId',
          ),
          element: (
            <PrivateRoute
              component={IdentityProvidersUpdate.Container}
              {...props}
              noNav
            />
          ),
        },

        /* Presto Debugging Routes */
        {
          path: PrestoClusters.routes.getPrestoDebuggingRoute(
            ':prestoClusterId',
          ),
          element: (
            <PrivateRoute component={PrestoDebugging.Container} {...props} />
          ),
        },

        /* Standalone Prometheus Routes */
        {
          path: StandalonePrometheus.routes.getRoute(),
          element: (
            <PrivateRoute component={ComputePlane.Container} {...props} />
          ),
        },

        {
          path: StandalonePrometheus.routes.getAddStandalonePrometheusRoute(), // assuming :prometheusId is a dynamic segment representing the Prometheus instance to update
          element: (
            <PrivateRoute
              component={StandalonePrometheusAdd.Container}
              {...props}
              // noNav
            />
          ),
        },

        /* Prometheus Destroy Route */
        // {
        //   path: StandalonePrometheus.routes.getDestroyStandalonePrometheusRoute(
        //     ':prometheusId',
        //   ), // assuming :prometheusId is a dynamic segment representing the Prometheus instance to destroy
        //   element: (
        //     <PrivateRoute
        //       component={StandalonePromethuesDestroy.Container}
        //       {...props}
        //       noNav
        //     />
        //   ),
        // },

        /* Error routes */
        {
          path: ErrorPage.routes.getForbiddenRoute(),
          element: <ErrorPage.ForbiddenPage {...props} />,
        },
        {
          path: '*',
          element: <ErrorPage.NotFoundPage {...props} />,
        },
      ],
    },
  ]);
};

const AppRouter = ({ ...props }) => {
  if (environment.isLocalUI()) {
    const searchParam = window.location?.search;
    const searchParams = new URLSearchParams(searchParam ?? undefined);
    const crnParam = searchParams.get('crn');

    if (!crnParam) {
      const crn = document.cookie
        .split('; ')
        .find((row) => {
          return row.startsWith('ibmlh.dev.crn=');
        })
        ?.split('=')[1];
      if (crn) {
        searchParams.append('crn', crn);

        const uri = buildUrl({
          path: window.location?.pathname,
          queryParams: paramsToObject(searchParams.entries()),
          relative: true,
        });
        const history = createBrowserHistory();
        return history.replace(uri);
      }
    }
  }

  const router = createRouters(props);
  return <RouterProvider router={router} />;
};

AppRouter.defaultProps = {
  authenticated: false,
  isAuthReady: false,
  isAccountLoaded: false,
  isComputePlaneActive: false,
  isComputePlaneDestroying: false,
  isComputePlaneCreating: false,
  displayFooter: true,
};

AppRouter.propTypes = {
  authenticated: bool,
  isAuthReady: bool,
  isAccountLoaded: bool,
  isComputePlaneActive: bool,
  isComputePlaneDestroying: bool,
  isComputePlaneCreating: bool,
  displayFooter: bool,
};

const mapStateToProps = (state) => {
  return {
    authenticated: auth.selectors.isAuthenticated(state),
    authError: auth.selectors.getError(state),
    isAuthReady: auth.selectors.isReady(state),
    isAccountLoaded: AccountSetup.selectors.isAccountLoaded(state),
    isComputePlaneDestroying:
      ComputePlane.selectors.isComputePlaneDestroying(state),
    isComputePlaneCreating:
      ComputePlane.selectors.isComputePlaneCreating(state),
    isComputePlaneActive: ComputePlane.selectors.isComputePlaneActive(state),
    displayFooter: Footer.selectors.displayFooter(state),
  };
};

export default connect(mapStateToProps)(AppRouter);
