import { string, shape, arrayOf } from 'prop-types';

export const awsAZsIDs = arrayOf(
  shape({
    zoneId: string,
    zoneName: string,
  }),
);

export const awsSubnets = arrayOf(
  shape({
    id: string,
    name: string,
  }),
);
