import Help from '../../Help';

const Section = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Hive Metastore</Help.HelpHeader>
      <Help.P>something</Help.P>
    </Help.HelpSection>
  );
};

const Thrift = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Thrift Endpoint URI(s)</Help.HelpHeader>
      <Help.P>
        The URI(s) to your Hive metastore Thrift service (e.g.
        thrift://example.net:9083). You may define multiple URIs by separating
        them with commas.
      </Help.P>
    </Help.HelpSection>
  );
};

const RoleARN = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>S3 Role ARN</Help.HelpHeader>
      <Help.P>
        The ARN of the role that can connect to your S3 Bucket (e.g.
        arn:aws:iam::123456789012:role/my-s3-role).
      </Help.P>
    </Help.HelpSection>
  );
};

const AccessKey = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Access Key ID</Help.HelpHeader>
      <Help.P>
        The AWS access key for the user with permission to connect to your S3
        bucket (e.g. AKIRASB3AK3RXMR33PUK).
      </Help.P>
    </Help.HelpSection>
  );
};

const SecretKey = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Secret Access Key</Help.HelpHeader>
      <Help.P>
        The secret access key associated with the access key ID defined above.
      </Help.P>
    </Help.HelpSection>
  );
};

const AuthorizationService = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Authorization Service</Help.HelpHeader>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
        <Help.P>
          The authorization service to use to secure this data source.
        </Help.P>
      </Help.HelpSection>
    );
  };
};

const module = {
  Section,
  Thrift,
  RoleARN,
  AccessKey,
  SecretKey,
  AuthorizationService,
};

export default module;
