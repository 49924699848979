import { all, call, spawn } from 'redux-saga/effects';
import auth from '../auth';
import rayApi from '../rayApi';
import * as realtimeApi from '../rayApi/realtime';
import AccountSetup from '../components/AccountSetup';
import AuthorizationServiceAdd from '../components/AuthorizationServiceAdd';
import DataSourceAdd from '../components/DataSourceAdd';
import PrestoClusterAdd from '../components/PrestoClusterAdd';
import PrestoUsersAdd from '../components/PrestoUsersAdd';
import logger from '../utils/logger';
import IdentityProviderAdd from '../components/IdentityProviderAdd';
import ComputePlaneAdd from '../components/ComputePlaneAdd';
import StandalonePrometheusAdd from '../components/StandalonePrometheusAdd';

function safeSagaStart(saga) {
  return function* innerSafeSagaStart() {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        logger.error(e);
      }
    }
  };
}

export default function* rootSaga() {
  const sagas = [
    auth.saga,
    rayApi.saga.rayApiRootSaga,
    realtimeApi.realtimeRootSaga,
    AccountSetup.saga,
    AuthorizationServiceAdd.saga,
    DataSourceAdd.saga,
    IdentityProviderAdd.saga,
    PrestoClusterAdd.saga,
    PrestoUsersAdd.saga,
    ComputePlaneAdd.saga,
    StandalonePrometheusAdd.saga,
  ];

  const safeSagas = sagas.map((saga) => {
    return spawn(safeSagaStart(saga));
  });
  yield all(safeSagas);
}
