import { useMemo } from 'react';
import { any, object, func } from 'prop-types';
import * as propTypes from './propTypes';
import { DataCellDiv, DataRowDiv } from './styledComponents';
import { useAlertIdStateContext } from './context';

// This article explains the approach being used with RenderFunction
// https://unsplash.com/blog/calling-react-hooks-conditionally-dynamically-using-render-props/
// https://github.com/facebook/react/issues/19056#issuecomment-639474427
const RenderFunction = ({ children }) => {
  return children();
};

const Row = ({
  columns,
  data,
  additionalContext,
  alert,
  alertIdSelector,
  summaryRow,
}) => {
  const selectedAlertId = useAlertIdStateContext();
  const inAlert = useMemo(() => {
    if (!alertIdSelector) {
      return false;
    }

    const alertId = alertIdSelector(data);
    return alertId === selectedAlertId;
  }, [alertIdSelector, data, selectedAlertId]);

  if (inAlert) {
    const props = {
      className: 'last',
      style: { gridColumn: '1/-1' },
      data,
      key: 'alert',
      additionalContext,
    };
    return (
      <DataRowDiv>
        <RenderFunction>
          {() => {
            return alert(props);
          }}
        </RenderFunction>
      </DataRowDiv>
    );
  }

  return (
    <>
      <DataRowDiv>
        {columns.map((c, index) => {
          const props = {
            key: c.name,
            className: index + 1 === columns.length ? 'last' : undefined,
          };

          const propData = c.selector(data, additionalContext);

          if (c.cellRender) {
            props.data = propData;
            props.additionalContext = additionalContext;
            return (
              <RenderFunction key={c.name}>
                {() => {
                  return c.cellRender(props);
                }}
              </RenderFunction>
            );
          }
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <DataCellDiv {...props}>{propData}</DataCellDiv>
          );
        })}
      </DataRowDiv>
      {summaryRow && summaryRow(data)}
    </>
  );
};

Row.defaultProps = {
  additionalContext: undefined,
  alert: undefined,
  alertIdSelector: undefined,
  summaryRow: undefined,
};

Row.propTypes = {
  columns: propTypes.columns.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalContext: any,
  alert: func,
  alertIdSelector: func,
  summaryRow: func,
};

export default Row;
