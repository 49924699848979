import { apiVerbs, createApiActionType } from '../../rayApi/actionTypes';

const componentName = 'PRESTO_CLUSTERS';
export const post = createApiActionType(apiVerbs.POST, componentName);
export const submit = `${componentName}_SUBMIT`;
export const updateVersions = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_VERSIONS`,
);
export const getVersions = createApiActionType(
  apiVerbs.GET,
  `${componentName}_VERSIONS`,
);
