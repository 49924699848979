import { string } from 'prop-types';
import Data from '../../Data';
import DataSources from '../../DataSources';

const display = {
  formContentHeader: 'Access',
  formExplaination: 'Secondary explanation',
  thriftUrlLabel: 'Thrift Endpoint URI(s)',
  credentialTypeLabel: 'S3 Credentials',
  accessTypeIAMRoleLabel: 'IAM role',
  roleLabel: 'Role ARN',
  accessKeyLabel: 'Access Key ID',
  secretLabel: 'Secret Access Key',
};

const credentialTypeValues = [
  {
    label: 'IAM role',
    value: 'iam',
  },
  {
    label: 'Access key',
    value: 'access_key',
  },
];

const HiveConnectionDetails = ({ hive, credentialType }) => {
  if (!hive) {
    return null;
  }

  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
        {/* <div>{display.formExplaination}</div> */}
      </div>
      <div>
        <Data.DataField
          label={display.thriftUrlLabel}
          value={hive.metastore.uri}
        />
        <Data.RadioField
          label={display.credentialTypeLabel}
          options={credentialTypeValues}
          value={credentialType}
        />
        {credentialType === 'iam' && (
          <Data.DataField
            label={display.roleLabel}
            value={hive.s3['iam-role']}
          />
        )}
        {credentialType === 'access_key' && (
          <>
            <Data.DataField
              label={display.accessKeyLabel}
              value={hive.s3['aws-access-key']}
            />
            <Data.DataField
              label={display.secretLabel}
              value={hive.s3['aws-secret-key']}
              isPrivate
            />
          </>
        )}
      </div>
    </>
  );
};

HiveConnectionDetails.propTypes = {
  hive: DataSources.propTypes.HiveConnection.hive.isRequired,
  credentialType: string.isRequired,
};

export default HiveConnectionDetails;
