import styled from 'styled-components/macro';
import { colors, fonts } from '../../../css';

export const DataSourceTypeOptionsDiv = styled.div`
  padding: 24px;
  padding-bottom: 0;

  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    margin-left: 0;
    width: 100%;

    > label {
      ${fonts.medium_bold}
      letter-spacing: 0;
      color: ${colors.font_light};
      padding: 0;
      border: 1px solid ${colors.border};
      border-radius: 4px;
      background-position: right 16px top 16px;

      &:after {
        display: none;
      }
    }
  }
`;

export const DataSourceTypeOptionDiv = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 24px 24px;

  img {
    height: 70px;
  }

  div {
    margin-top: 20px;
    text-align: center;
  }

  div.coming-soon {
    margin-top: 5px;
    ${fonts.small}
    color: ${colors.error};
  }
`;

export const CloudFormationHelpDiv = styled.div`
  margin-bottom: 20px;
`;

export const NoDataDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;
