import { useEffect, useState, useCallback, useMemo } from 'react';
import { bool, func, string, shape, number } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Form from '../../Form';
import Page from '../../Page';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import useRouteBuilder from '../../../router/useRouteBuilder';
import ChangeEnableSpotForm from './ChangeEnableSpotForm';

const ChangeEnableSpot = ({
  prestoClusterId,
  spotEnabled,
  enableASGCapacityRebalance,
  status,
  name,
  notFound,
  actionStatus,
  usePop,
  loadPrestoCluster,
  saveEnableSpot,
  workflowInProgress,
}) => {
  useEffect(() => {
    loadPrestoCluster(prestoClusterId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoClusterId]);

  const [actionInProgress, setActionInProgress] = useState(false);

  const save = useCallback(
    (values) => {
      const submitId = Date.now();
      setActionInProgress(submitId);
      return saveEnableSpot(prestoClusterId, values, submitId);
    },
    [saveEnableSpot, prestoClusterId],
  );

  const submitSuccess = useMemo(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    ) {
      return actionStatus.success;
    }
    return false;
  }, [actionStatus, actionInProgress]);

  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!prestoClusterId || !status || notFound) {
    return <Page.Loading />;
  }

  return (
    <ActionInProgressStateContext.Provider value={actionInProgress}>
      <ActionInProgressSetStateContext.Provider value={setActionInProgress}>
        <Page.PageWithOverflow>
          <Form.Form
            name='change-presto-cluster-enable-spot'
            submitAction={save}
          >
            <ChangeEnableSpotForm
              spotEnabled={spotEnabled}
              enableASGCapacityRebalance={enableASGCapacityRebalance}
              status={status}
              name={name}
              actionStatus={actionStatus}
              prestoClusterId={prestoClusterId}
              submitSuccess={submitSuccess}
              usePop={usePop}
              workflowInProgress={workflowInProgress}
            />
          </Form.Form>
        </Page.PageWithOverflow>
      </ActionInProgressSetStateContext.Provider>
    </ActionInProgressStateContext.Provider>
  );
};

ChangeEnableSpot.defaultProps = {
  spotEnabled: false,
  enableASGCapacityRebalance: true,
  name: null,
  status: null,
  usePop: true,
  notFound: false,
  actionStatus: undefined,
  workflowInProgress: null,
};

ChangeEnableSpot.propTypes = {
  prestoClusterId: string.isRequired,
  spotEnabled: bool,
  enableASGCapacityRebalance: bool,
  name: string,
  status: string,
  notFound: bool,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  usePop: bool,
  loadPrestoCluster: func.isRequired,
  saveEnableSpot: func.isRequired,
  workflowInProgress: bool,
};

export default ChangeEnableSpot;
