import { connect } from '../../utils/redux';
import PrestoClusters from '../PrestoClusters';
import PrestoUsers from '../PrestoUsers';
import IdentityProviders from '../IdentityProviders';
import * as PrestoClusterAddSelectors from '../PrestoClusterAdd/selectors';
import { PrestoCluster } from './components';
import { getAwsEBSCacheVolumesUrl } from './selectors';

const mapStateToProps = (state, props) => {
  return {
    prestoClusterId: PrestoClusters.selectors.getPrestoClusterId(state, props),
    prestoCluster: PrestoClusters.selectors.getPrestoCluster(state, props),
    notFound: PrestoClusters.selectors.getIsNotFound(state, props),
    actionStatus: PrestoClusters.selectors.getActionStatus(state, props),
    usePop: PrestoClusters.selectors.getUsePop(state),
    dataSources: PrestoClusters.selectors.getDataSources(state, props),
    awsEBSCacheVolumesUrl: getAwsEBSCacheVolumesUrl(state, props),
    customPluginBucketLink:
      PrestoClusters.selectors.getPrestoClusterCustomPluginBucketLink(
        state,
        props,
      ),
    prestoUsers: PrestoClusters.selectors.getPrestoUsers(state, props),
    prestoUsersLoading: PrestoUsers.selectors.getLoadingInProgress(state),
    identityProvider: IdentityProviders.selectors.getIdentityProvider(
      state,
      props,
    ),
    identityProviderLoading:
      IdentityProviders.selectors.getLoadingInProgress(state),
    prestoClusterSubdomain: PrestoClusters.selectors.getPrestoClusterSubdomain(
      state,
      props,
    ),
    downloadLinks: PrestoClusters.selectors.getDownloadLinks(state, props),
    maxWorkerNodeCount: PrestoClusterAddSelectors.getMaxWorkerNodeCount(state),
    showSendDebugging: PrestoClusters.selectors.getShowSendDebugging(
      state,
      props,
    ),
    enableEnableSpot: PrestoClusterAddSelectors.getEnableSpot(state),
  };
};

export default connect(mapStateToProps, {
  loadPrestoCluster: PrestoClusters.actions.get,
  deleteCluster: PrestoClusters.actions.remove,
  restartCluster: PrestoClusters.actions.putRestart,
  startCluster: PrestoClusters.actions.putStart,
  stopCluster: PrestoClusters.actions.putStop,
  setWorkerNodes: PrestoClusters.actions.patchWorkerNodes,
  exportLogs: PrestoClusters.actions.putExportLogs,
  exportDebuggingInfo: PrestoClusters.actions.putExportDebuggingInfo,
  loadDataSources: PrestoClusters.actions.getDataSources,
  loadPrestoUsers: PrestoUsers.actions.getAll,
  loadIdentityProviders: IdentityProviders.actions.getAll,
})(PrestoCluster);
