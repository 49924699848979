import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Need to update your authorization service details?
      </Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Change any of the information about your Apache Ranger Service.
        </Help.ListItem>
        <Help.ListItem>
          When you click &quot;Modify&quot; the Ranger Service will be updated
          with the new information. The change takes effect immediately.
        </Help.ListItem>
      </Help.OrderedList>
    </Help.HelpSection>
  );
};

export default PageHelp;
