import { takeEvery, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import { roleCreationMethods, glueS3RoleMethods } from './constants';
import * as DataSourceUpdateActions from '../DataSourceUpdate/actions';
import * as DataSourceUpdateActionTypes from '../DataSourceUpdate/actionTypes';
import encryptData from '../../utils/encryptData';

const keyId = process.env.REACT_APP_KMS_BROWSER_ENCRYPT_KEY_ALIAS;
const encryptionAlgorithm = 'RSAES_OAEP_SHA_256';

function* cancelableCreateOrUpdateDataSource(action) {
  const newBody = { ...action.payload.body };
  const { type, connection, prestoUserMappings } = newBody;
  delete newBody.prestoUserMappings;

  if (type === 'hive' && connection.s3 && connection.s3.secret) {
    const encrypted = yield call(
      encryptData,
      connection.s3.secret,
      keyId,
      encryptionAlgorithm,
    );
    newBody.connection = { ...newBody.connection };
    newBody.connection.s3 = { ...newBody.connection.s3 };
    newBody.connection.s3.secret = encrypted;
  }
  if (type === 'elasticsearch' && connection.aws && connection.aws.secretKey) {
    const encrypted = yield call(
      encryptData,
      connection.aws.secretKey,
      keyId,
      encryptionAlgorithm,
    );
    newBody.connection = { ...newBody.connection };
    newBody.connection.aws = { ...newBody.connection.aws };
    newBody.connection.aws.secretKey = encrypted;
  }
  if (
    (type === 'mysql' ||
      type === 'postgresql' ||
      type === 'redshift' ||
      type === 'db2') &&
    connection.password
  ) {
    const encrypted = yield call(
      encryptData,
      connection.password,
      keyId,
      encryptionAlgorithm,
    );
    newBody.connection = { ...newBody.connection };
    newBody.connection.password = encrypted;
  }

  if (type === 'glue') {
    if (connection.security) {
      newBody.connection.security = 'lake-formation';

      // When updating, if LF is selected, remove any previous auth service to avoid having both services set
      if (
        action.type === DataSourceUpdateActionTypes.update &&
        newBody.authorizationServiceId
      ) {
        delete newBody.authorizationServiceId;
      }
    } else if (
      connection.roleCreationMethod === roleCreationMethods.cloudFormation ||
      connection.s3RoleMethod === glueS3RoleMethods.sameAsGlue
    ) {
      // If using CloudFormation or manual + same role, set S3 role to match the Glue role
      newBody.connection.s3.iamRole = connection.iamRole;
    }
  } // Otherwise, S3 and Glue roles are different and both are set individually by the user

  if (prestoUserMappings) {
    if (type === 'glue' && newBody.connection.security === 'lake-formation') {
      newBody.prestoUserMappings = Object.entries(prestoUserMappings).map(
        ([key, value]) => {
          return {
            prestoUserId: key,
            ...value,
          };
        },
      );
    }
  }

  if (action.type === actionTypes.submit) {
    yield put(actions.post(newBody));
  }

  if (action.type === DataSourceUpdateActionTypes.update) {
    const { dataSourceId } = action.payload;
    yield put(DataSourceUpdateActions.put(dataSourceId, newBody));
  }
}

export default function* dataSourceAddRootSaga() {
  yield all([
    takeEvery(actionTypes.submit, cancelableCreateOrUpdateDataSource),
    takeEvery(
      DataSourceUpdateActionTypes.update,
      cancelableCreateOrUpdateDataSource,
    ),
  ]);
}
