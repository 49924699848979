import Help from '../../Help';

export const AWSPermissionBoundaryPolicy = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Permission Boundary AWS Policy</Help.HelpHeader>
      <Help.P>
        The IBM watsonx.data permission boundary AWS policy sets the maximum
        permissions allowable for roles created by watsonx.data.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSCoreInfrastructurePolicy = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Core Infrastructure AWS Policy</Help.HelpHeader>
      <Help.P>
        The IBM watsonx.data core infrastructure AWS policy contains the
        permissions needed to create and destroy the compute plane. This policy
        can be revoked once the compute plane is created; however, it is
        required if and when you want to update or destroy the compute plane.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSOperationsPolicy = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Operations AWS Policy</Help.HelpHeader>
      <Help.P>
        The IBM watsonx.data operations AWS policy contains the permissions to
        run a managed service, such as provisioning and deprovisioning Presto
        clusters. This policy is constrained by the IBM watsonx.data permission
        boundary AWS policy.
      </Help.P>
    </Help.HelpSection>
  );
};

export const AWSTrustRelationshipPolicy = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>IAM Role Trust Relationship Policy</Help.HelpHeader>
      <Help.P>
        The trust relationship policy for the IBM watsonx.data provisioning role
        allows the IBM AWS account to assume the provisioning role in order to
        manage watsonx.data compute plane resources in your AWS account.
      </Help.P>
    </Help.HelpSection>
  );
};

export const ProvisioningRoleCFTemplate = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Provisioning Role CloudFormation Template
      </Help.HelpHeader>
      <Help.P>
        The CloudFormation template that will create the provisioning role.
      </Help.P>
    </Help.HelpSection>
  );
};

export const ProvisioningRoleTerraformTemplate = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Provisioning Role Terraform Template</Help.HelpHeader>
      <Help.P>
        The Terraform template that will create the provisioning role.
      </Help.P>
    </Help.HelpSection>
  );
};
