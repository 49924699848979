import { connect } from '../../utils/redux';
import auth from '../../auth';
import { HomePage } from './components';
import PrestoClusters from '../PrestoClusters';

const mapStateToProps = (state) => {
  return {
    name: auth.selectors.getName(state),
    canCreateCluster: PrestoClusters.selectors.canCreateCluster(state),
  };
};

export default connect(mapStateToProps, {
  signOut: auth.actions.signOut,
  loadPrestoClusters: PrestoClusters.actions.getAll,
})(HomePage);
