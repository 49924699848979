import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Need to update your data source details?
      </Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Change any of the information about your data source.
        </Help.ListItem>
        <Help.ListItem>
          When you click &quot;Modify Data Source&quot; the data source will be
          updated with the new information. The change takes effect immediately.
        </Help.ListItem>
      </Help.OrderedList>
    </Help.HelpSection>
  );
};

export default PageHelp;
