/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import get from 'lodash.get';
import { useFormContext } from 'react-hook-form';
import {
  string,
  bool,
  object,
  number,
  oneOfType,
  elementType,
} from 'prop-types';
import VisibleIcon from '../../icons/Visible';
import NotVisibleIcon from '../../icons/NotVisible';
import { onAccessibleClick } from '../utils/accessibility';
import Label from './Label';
import {
  InputErrorDiv,
  PasswordInput,
  HorizontalInputDiv,
  PasswordWrapper,
} from './styledComponents';

const InnerCompleteTextInput = ({
  name,
  label,
  disabled,
  optional,
  defaultValue,
  validationRules,
  autoComplete,
  fieldHelp,
}) => {
  const [viewPassword, setViewPassword] = useState(false);
  const toggleViewPassword = useCallback(
    (e) => {
      return onAccessibleClick(e, () => {
        setViewPassword(!viewPassword);
      });
    },
    [viewPassword],
  );

  const {
    register: formRegister,
    formState: { errors: formErrors },
    getFieldId,
  } = useFormContext();
  const inputId = getFieldId(name);

  const error = get(formErrors, name);

  return (
    <>
      <Label
        inputId={inputId}
        label={label}
        optional={optional}
        fieldHelp={fieldHelp}
      />
      <PasswordWrapper className={error ? 'error' : undefined}>
        <PasswordInput
          type={!viewPassword ? 'password' : 'text'}
          disabled={disabled}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          id={inputId}
          data-private
          aria-invalid={error ? 'true' : 'false'}
          {...formRegister(name, validationRules)}
        />
        <button onClick={toggleViewPassword} type='button' tabIndex={-1}>
          {viewPassword ? <VisibleIcon /> : <NotVisibleIcon />}
        </button>
      </PasswordWrapper>
      {error && <InputErrorDiv role='alert'>{error.message}</InputErrorDiv>}
    </>
  );
};

InnerCompleteTextInput.defaultProps = {
  disabled: false,
  optional: false,
  defaultValue: undefined,
  validationRules: undefined,
  autoComplete: 'off',
  fieldHelp: undefined,
};

InnerCompleteTextInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  optional: bool,
  defaultValue: oneOfType([string, number]),
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  autoComplete: string,
  fieldHelp: elementType,
};

const CompleteTextInput = ({ horizontal, ...props }) => {
  if (horizontal) {
    return (
      <HorizontalInputDiv>
        <InnerCompleteTextInput {...props} />
      </HorizontalInputDiv>
    );
  }

  return <InnerCompleteTextInput {...props} />;
};

CompleteTextInput.defaultProps = {
  disabled: false,
  optional: false,
  defaultValue: undefined,
  validationRules: undefined,
  autoComplete: 'off',
  horizontal: false,
};

CompleteTextInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  optional: bool,
  defaultValue: oneOfType([string, number]),
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  autoComplete: string,
  horizontal: bool,
};

export default CompleteTextInput;
