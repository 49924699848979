import Page from '../../Page';
import Loading from '../../Loading';
import { LoadingDiv } from './styledComponents';

const display = {
  title: 'Destroying Compute Plane',
  message:
    'Your compute plane environment is being deprovisioned. This can take between 20-30 minutes.',
};

const DestroyInProgress = () => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      <LoadingDiv>
        <Loading.Loading message={display.message} />
      </LoadingDiv>
    </Page.Box>
  );
};

DestroyInProgress.defaultProps = {};

DestroyInProgress.propTypes = {};

export default DestroyInProgress;
