import { connect } from '../../utils/redux';
import IdentityProviders from '../IdentityProviders';
import { Security } from './components';

const mapStateToProps = (state) => {
  return {
    enableIdentityProvider:
      IdentityProviders.selectors.getEnableExternalIdentityProvider(state),
  };
};

export default connect(mapStateToProps, null)(Security);
