const networkSanitizers = new Map();

const registerNetworkSanitizer = (
  name,
  requestSanitizer,
  responseSanitizer,
) => {
  if (networkSanitizers.has(name)) {
    throw new Error(`Network sanitizer with name ${name} already registered`);
  }

  networkSanitizers.set(name, {
    request: requestSanitizer,
    response: responseSanitizer,
  });
};

const sanitizeNetworkRequest = (originalRequest) => {
  let request = originalRequest;

  if (originalRequest.headers.authorization) {
    request.headers.authorization = 'sanitized';
  } else if (originalRequest.headers.Authorization) {
    request.headers.Authorization = 'sanitized';
  }

  if (originalRequest.headers.cookie) {
    request.headers.cookie = 'sanitized';
  } else if (originalRequest.headers.Cookie) {
    request.headers.Cookie = 'sanitized';
  }

  for (const networkSanitizer of networkSanitizers.values()) {
    request = networkSanitizer.request(request);
    if (!request) {
      return null;
    }
  }

  return request;
};

const sanitizeNetworkResponse = (originalResponse) => {
  let response = originalResponse;

  if (originalResponse.headers.authorization) {
    response.headers.authorization = 'sanitized';
  }

  for (const networkSanitizer of networkSanitizers.values()) {
    response = networkSanitizer.response(response);
    if (!response) {
      return null;
    }
  }

  return response;
};

const module = {
  registerNetworkSanitizer,
  sanitizeNetworkRequest,
  sanitizeNetworkResponse,
};

export default module;
