import styled from 'styled-components';
import { colors, fonts } from '../../../css';

export const ClusterNameCellDiv = styled.div`
  & {
    margin-top: 11px;
  }

  .cluster-name {
    ${fonts.large_bold}
    margin-bottom: 5px;
    justify-content: flex-end;
  }

  .created-on {
    ${fonts.medium_small_bold}
    justify-content: flex-start;
  }
`;

export const SummaryRowDiv = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;

  div {
    padding: 15px 0 15px 0;
    ${fonts.medium_bold}
    border-bottom: 1px solid ${colors.border};
  }

  .label {
    border-top: 1px solid ${colors.border};
  }

  .sum {
    padding-right: 24px;
    border-top: 1px solid ${colors.border};
    text-align: right;
  }
`;

export const TotalCredits = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;

  div {
    padding: 35px 24px;
    ${fonts.large_bold}
  }

  .total {
    text-align: right;
    padding-right: 24px;
  }
`;

export const DateFilterDiv = styled.div`
  padding: 15px 24px;
  width: 35% !important;

  > button {
    margin-top: 10px;
    width: 100%;
  }
`;

export const NoDataDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;

export const LoadingDiv = styled.div`
  padding-bottom: 50px;

  div {
    justify-content: center;
  }

  &.top-padding {
    padding-top: 40px;
  }
`;
