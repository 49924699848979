import { string } from 'prop-types';
import image from './error403.svg';
import { HttpErrorsDiv } from '../styledComponents';

const Forbidden = ({ title, code, description }) => {
  return (
    <HttpErrorsDiv>
      <div className='content'>
        <p className='error-code'>{code || 'Error 403'}</p>
        <h1 className='title'>{title || 'Forbidden'}</h1>
        <p className='description'>
          {description || 'You are not authorized to access this resource.'}
        </p>
      </div>
      <img className='error-image' src={image} alt='Forbidden' />
    </HttpErrorsDiv>
  );
};

Forbidden.displayName = 'Forbidden';
Forbidden.propTypes = {
  title: string,
  code: string,
  description: string,
};
Forbidden.defaultProps = {
  title: undefined,
  code: undefined,
  description: undefined,
};

export default Forbidden;
