import * as propTypes from './propTypes';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  ErrorButton,
  SuccessButton,
  WarningButton,
} from './Button';
import CheckboxInput from './CheckboxInput';
import ConfirmPasswordInput from './ConfirmPasswordInput';
import CopyButton from './CopyButton';
import FieldRow from './FieldRow';
import FileInput from './FileInput';
import Form from './Form';
import HiddenInput from './HiddenInput';
import MultiSelectInput from './MultiSelectInput';
import PasswordInput from './PasswordInput';
import RadioInputs from './RadioInputs';
import SelectInput from './SelectInput';
import Submit from './Submit';
import SubmitEditNode from './SubmitEditNode';
import TextInput from './TextInput';
import { ButtonWrapperDiv, Footer } from './styledComponents';
import LoginPasswordInput from './LoginPasswordInput';
import SubmitDestructive from './SubmitDestructive';
import useFormNavigation from './formNavigationHook';
import Modal from './Modal';
import MultiLineInput from './MultiLineInput';

const module = {
  ButtonWrapperDiv,
  CheckboxInput,
  ConfirmPasswordInput,
  CopyButton,
  ErrorButton,
  FieldRow,
  FileInput,
  Footer,
  Form,
  HiddenInput,
  LoginPasswordInput,
  MultiSelectInput,
  PasswordInput,
  PrimaryButton,
  RadioInputs,
  SecondaryButton,
  SelectInput,
  Submit,
  SubmitEditNode,
  SubmitDestructive,
  SuccessButton,
  TertiaryButton,
  TextInput,
  WarningButton,
  useFormNavigation,
  propTypes,
  Modal,
  MultiLineInput,
};

export default module;
