/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { shape, string, func, bool } from 'prop-types';
import Page from '../../../Page';
import Loading from '../../../Loading';
import { status } from '../../constants';
import { LoadingDiv } from '../styledComponents';

const display = {
  title: 'Steps 7 of 8 - Create Compute Plane',
  message:
    'Your IBM watsonx.data environment is being provisioned. This can take between 20-30 minutes.',
};

export const CreateComputePlane = ({
  computePlane,
  onNext,
  isComputePlaneActive,
}) => {
  useEffect(() => {
    if (isComputePlaneActive || computePlane.status === status.init_failure) {
      onNext();
    }
  }, [computePlane, isComputePlaneActive, onNext]);

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      <LoadingDiv>
        <Loading.Spinner message={display.message} />
      </LoadingDiv>
    </Page.Box>
  );
};

CreateComputePlane.defaultProps = {
  computePlane: null,
};

CreateComputePlane.propTypes = {
  isComputePlaneActive: bool.isRequired,
  computePlane: shape({
    status: string,
  }),
  onNext: func.isRequired,
};
