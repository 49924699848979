import { connect } from '../../utils/redux';
import { IdentityProvider } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import { getAll } from '../PrestoClusters/actions';

const mapStateToProps = (state, props) => {
  return {
    identityProvider: selectors.getIdentityProvider(state, props),
    loadingInProgress: selectors.getLoadingInProgress(state),
    error: selectors.getError(state),
    errorMessagesText: selectors.getErrorMessagesText(state, props),
    enableFeature: selectors.getEnableExternalIdentityProvider(state),
    canDestroy: selectors.canDestroy(state),
  };
};

export default connect(mapStateToProps, {
  loadIdentityProviders: actions.getAll,
  loadPrestoClusters: getAll,
  destroy: actions.remove,
})(IdentityProvider);
