import { connect } from '../../utils/redux';
import PrestoClusters from '../PrestoClusters';
import { ChangeGroupedConfig } from './components';

const mapStateToProps = (state, props) => {
  return {
    prestoClusterId: PrestoClusters.selectors.getPrestoClusterId(state, props),
    concurrency: PrestoClusters.selectors.getPrestoClusterGroupedConfig(
      state,
      props,
    ),
    status: PrestoClusters.selectors.getPrestoClusterStatus(state, props),
    name: PrestoClusters.selectors.getPrestoClusterName(state, props),
    notFound: PrestoClusters.selectors.getIsNotFound(state, props),
    actionStatus: PrestoClusters.selectors.getActionStatus(state, props),
    usePop: PrestoClusters.selectors.getUsePop(state),
    workflowInProgress:
      PrestoClusters.selectors.getPrestoClusterWorkflowInProgress(state, props),
  };
};

export default connect(mapStateToProps, {
  loadPrestoCluster: PrestoClusters.actions.get,
  saveGroupedConfig: PrestoClusters.actions.putGroupedConfig,
})(ChangeGroupedConfig);
