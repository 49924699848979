import { connect } from '../../utils/redux';
import DataSources from '../DataSources';
import PrestoUsers from '../PrestoUsers';
import { PrestoUser } from './components';

const mapStateToProps = (state, props) => {
  return {
    prestoUserId: PrestoUsers.selectors.getPrestoUserId(state, props),
    prestoUser: PrestoUsers.selectors.getPrestoUser(state, props),
    notFound: PrestoUsers.selectors.getIsNotFound(state, props),
    actionStatus: PrestoUsers.selectors.getActionStatus(state, props),
    prestoClusters: PrestoUsers.selectors.getPrestoClustersForPrestoUser(
      state,
      props,
    ),
    dataSources: PrestoUsers.selectors.getDataSourcesForPrestoUser(
      state,
      props,
    ),
    usePop: PrestoUsers.selectors.getUsePop(state),
    canModify: PrestoUsers.selectors.getCanModify(state, props),
    canDelete: PrestoUsers.selectors.getCanDelete(state, props),
    alertBeforeDelete: PrestoUsers.selectors.getAlertBeforeDelete(state, props),
  };
};

export default connect(mapStateToProps, {
  loadPrestoUser: PrestoUsers.actions.get,
  deletePrestoUser: PrestoUsers.actions.remove,
  loadPrestoClusters: PrestoUsers.actions.getPrestoClustersForPrestoUser,
  loadDataSources: PrestoUsers.actions.getDataSourcesForPrestoUser,
  loadDataSourcePrestoClusters: DataSources.actions.getPrestoClusters,
})(PrestoUser);
