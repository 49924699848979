import { connect } from '../../utils/redux';
import { PrestoDebugging } from './components';
import PrestoClusters from '../PrestoClusters';

const mapStateToProps = (state, props) => {
  return {
    prestoClusterId: PrestoClusters.selectors.getPrestoClusterId(state, props),
    prestoCluster: PrestoClusters.selectors.getPrestoCluster(state, props),
    notFound: PrestoClusters.selectors.getIsNotFound(state, props),
    actionStatus: PrestoClusters.selectors.getActionStatus(state, props),
  };
};

export default connect(mapStateToProps, {
  loadPrestoCluster: PrestoClusters.actions.get,
  triggerPrestoDebugging: PrestoClusters.actions.putTriggerPrestoDebugging,
})(PrestoDebugging);
