import { useSelector } from '../../utils/redux';
import { isSubscriptionValid } from '../AccountSetup/selectors';
import {
  getComputePlaneStatus,
  isComputePlaneGreaterThanMinimumVersion,
} from '../ComputePlane/selectors';
import * as computePlaneConstants from '../ComputePlane/constants';

const useIsSubscriptionValid = () => {
  return useSelector(isSubscriptionValid);
};

const useIsComputePlaneActive = () => {
  const status = useSelector(getComputePlaneStatus);
  return status === computePlaneConstants.status.active;
};

const useIsComputePlaneVersionGreaterThanMinimumVersion = () => {
  return useSelector(isComputePlaneGreaterThanMinimumVersion);
};

const useEnableFeature = ({
  requireValidSubscription = true,
  requireActiveComputePlane = true,
  requireComputePlaneGreaterThanMinimumVersion = true,
} = {}) => {
  const validSubscription = useIsSubscriptionValid();
  const isComputePlaneActive = useIsComputePlaneActive();
  const computePlaneGreaterThanMinimumVersion =
    useIsComputePlaneVersionGreaterThanMinimumVersion();

  return (
    ((requireValidSubscription && validSubscription) ||
      !requireValidSubscription) &&
    ((requireActiveComputePlane && isComputePlaneActive) ||
      !requireActiveComputePlane) &&
    ((requireComputePlaneGreaterThanMinimumVersion &&
      computePlaneGreaterThanMinimumVersion) ||
      !requireComputePlaneGreaterThanMinimumVersion)
  );
};

export {
  useIsSubscriptionValid,
  useIsComputePlaneActive,
  useIsComputePlaneVersionGreaterThanMinimumVersion,
  useEnableFeature,
};
