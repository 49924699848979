import { connect } from '../../utils/redux';
import { PrestoClusters } from './components';
import * as selectors from './selectors';
import * as actions from './actions';

const mapStateToProps = (state) => {
  return {
    loadingInProgress: selectors.getLoadingInProgress(state),
    activePrestoClusters: selectors.getActivePrestoClusters(state),
    inactivePrestoClusters: selectors.getInactivePrestoClusters(state),
    pendingPrestoClusters: selectors.getPendingPrestoClusters(state),
    canCreateCluster: selectors.canCreateCluster(state),
  };
};

export default connect(mapStateToProps, {
  loadPrestoClusters: actions.getAll,
})(PrestoClusters);
