import { useMemo, useCallback } from 'react';
import { node } from 'prop-types';
import { PageScrollDiv, MainDiv } from './styledComponents';
import {
  useInPageNavigationState,
  useInPageNavigationDispatch,
} from './InPageNavigation';

const Scroll = ({ children }) => {
  const inPageNavigationState = useInPageNavigationState(true);
  const dispatch = useInPageNavigationDispatch(true);

  const scrollRef = useCallback(
    (n) => {
      if (dispatch) {
        dispatch({ type: 'setScrollRef', payload: { scrollRef: n } });
      }
    },
    [dispatch],
  );

  const mainStyle = useMemo(() => {
    if (inPageNavigationState) {
      const style = {
        paddingTop: 0,
      };

      if (inPageNavigationState.scrollPaddingBottom) {
        style.paddingBottom = `${inPageNavigationState.scrollPaddingBottom}px`;
      }
      return style;
    }

    return undefined;
  }, [inPageNavigationState]);

  return (
    <PageScrollDiv ref={scrollRef}>
      <MainDiv style={mainStyle}>
        <div>{children}</div>
      </MainDiv>
    </PageScrollDiv>
  );
};

Scroll.propTypes = {
  children: node.isRequired,
};

export default Scroll;
