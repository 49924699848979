import { Navigate, useLocation } from 'react-router-dom';
import { shape, number, string } from 'prop-types';
import routes from './routes';

const ErrorRoute = ({ authError }) => {
  const location = useLocation();

  if (!authError) {
    return null;
  }

  if (authError.status === 401) {
    return (
      <Navigate
        to={{
          pathname: routes.signIn.getRoute(),
          search: location.search,
        }}
      />
    );
  }

  if (authError.status === 403) {
    return (
      <Navigate
        to={{
          pathname: routes.errors.getForbiddenRoute(),
          search: location.search,
        }}
        replace
      />
    );
  }
  return (
    <Navigate
      to={{
        pathname: routes.errors.getNotFoundRoute(),
        search: location.search,
      }}
      replace
    />
  );
};

ErrorRoute.propTypes = {
  authError: shape({
    status: number,
    message: string,
  }),
};

ErrorRoute.defaultProps = {
  authError: undefined,
};

export default ErrorRoute;
