import styled from 'styled-components/macro';
import { colors, fonts } from '../../../css';

export const BoxesDiv = styled.div`
  display: grid;
  grid-column-gap: 31px;
  grid-row-gap: 33px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 60px;
`;

export const BoxDiv = styled.div`
  border: 1px solid ${colors.border};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 ${colors.shadow};
  background-color: ${colors.section_background};
  padding: 32px;
  padding-left: 180px;
  color: ${colors.font_light};
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 32px;

  h2 {
    ${fonts.large_bold}
    letter-spacing: 0.2px;
    margin-bottom: 16px;
  }

  p {
    ${fonts.medium_regular}
    letter-spacing: 0.16px;
    margin-bottom: 16px;
  }

  a,
  span {
    display: inline-block;
  }
`;

export const LogoDiv = styled.div`
  display: flex;
  > svg {
    height: 60px;
    align-self: center;
    margin-right: 20px;
  }
`;
