import { isDevUI, isStagingUI } from '../../utils/environment';
import { instanceTypes, nonProdInstanceTypes } from './constants';

export const getErrors = (state) => {
  return state.StandalonePrometheusAdd.errors;
};

export const getSubmitInProgress = (state) => {
  return state.StandalonePrometheusAdd.submitInProgress;
};

export const getSubmitSuccess = (state) => {
  return state.StandalonePrometheusAdd.submitSuccess;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getPrometheusConfiguration = (state) => {
  return state.StandalonePrometheusAdd.prometheusConfiguration;
};

export const getInstanceTypes = () => {
  if (isDevUI() || isStagingUI()) {
    return nonProdInstanceTypes;
  }
  return instanceTypes;
};
