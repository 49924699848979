import { string, bool, elementType, node } from 'prop-types';
import Label from './Label';
import { TextArea, DataDivDiv, SecondLabelDiv } from './styledComponents';

const MultiLineDataField = ({
  label,
  secondLabel,
  value,
  disabled,
  isPrivate,
  fieldHelp,
  children,
}) => {
  return (
    <>
      {label && (
        <Label label={label} fieldHelp={fieldHelp} disabled={disabled} />
      )}
      {secondLabel && (
        <DataDivDiv className='secondLabel'>
          <TextArea
            className={disabled ? 'disabled' : undefined}
            data-private={isPrivate ? true : undefined}
            value={value}
            readOnly
          />
          <SecondLabelDiv className={disabled ? 'disabled' : undefined}>
            {secondLabel}
          </SecondLabelDiv>
        </DataDivDiv>
      )}
      {!secondLabel && (
        <TextArea
          className={disabled ? 'disabled' : undefined}
          data-private={isPrivate ? true : undefined}
          value={children || value}
          readOnly
        />
      )}
    </>
  );
};

MultiLineDataField.defaultProps = {
  label: undefined,
  secondLabel: undefined,
  value: '',
  disabled: false,
  isPrivate: false,
  fieldHelp: undefined,
  children: null,
};

MultiLineDataField.propTypes = {
  label: string,
  secondLabel: string,
  value: string,
  disabled: bool,
  isPrivate: bool,
  fieldHelp: elementType,
  children: node,
};

export default MultiLineDataField;
