import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as selectors from './selectors';
import { FormWizard } from './FormWizard';
import { useFormWizard } from './useFormWizard';
import reducer from './reducer';

const module = {
  actions,
  actionTypes,
  FormWizard,
  reducer,
  selectors,
  useFormWizard,
};

export default module;
