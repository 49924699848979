/* eslint-disable react/jsx-props-no-spreading */
import { Highlight } from 'prism-react-renderer';
import { string } from 'prop-types';
import IAMTheme from './IAMTheme';
import { Pre, Line, LineNumber, LineContent } from './styledComponents';

// (typeof global !== 'undefined' ? global : window).Prism = Prism;
// await import('prismjs/components/prism-json');

const SyntaxHighlighting = ({ code, language }) => {
  return (
    <Highlight theme={IAMTheme} code={code} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        return (
          <Pre className={className} style={style}>
            {tokens.map((line, i) => {
              return (
                <Line {...getLineProps({ line, key: i })}>
                  <LineNumber>{i + 1}</LineNumber>
                  <LineContent>
                    {line.map((token, key) => {
                      return <span {...getTokenProps({ token, key })} />;
                    })}
                  </LineContent>
                </Line>
              );
            })}
          </Pre>
        );
      }}
    </Highlight>
  );
};

SyntaxHighlighting.propTypes = {
  code: string.isRequired,
  language: string.isRequired,
};

export default SyntaxHighlighting;
