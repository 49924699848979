import { useCallback } from 'react';
import { string, bool, elementType } from 'prop-types';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../icons/Copy';
import Label from './Label';
import { DataWithActionDiv, Tooltip } from './styledComponents';
import DataField from './DataField';

const display = {
  copiedMessage: 'Copied',
};

const DataFieldWithCopy = ({
  label,
  value,
  hideValue,
  isPrivate,
  fieldHelp,
  optional,
  disabled,
}) => {
  const onCopyClick = useCallback(
    (event) => {
      copy(value);
      if (
        event.type === 'click' &&
        event.clientX !== 0 &&
        event.clientY !== 0
      ) {
        event.currentTarget.blur();
      }
    },
    [value],
  );

  if (disabled) {
    return (
      <DataField
        label={label}
        value={value}
        disabled
        isPrivate={isPrivate}
        fieldHelp={fieldHelp}
      />
    );
  }

  return (
    <>
      {label && (
        <Label label={label} fieldHelp={fieldHelp} optional={optional} />
      )}
      <DataWithActionDiv>
        <div
          className={hideValue ? 'hide-value' : undefined}
          data-private={isPrivate ? true : undefined}
        >
          {hideValue ? 'click to copy' : value}
        </div>
        <button
          onClick={onCopyClick}
          type='button'
          data-tooltip-id='data-field-with-copy-tooltip'
        >
          <CopyIcon />
        </button>
        <Tooltip
          id='data-field-with-copy-tooltip'
          content={display.copiedMessage}
          openOnClick
          delayHide={100000}
          noArrow
          place='left'
        />
      </DataWithActionDiv>
    </>
  );
};

DataFieldWithCopy.defaultProps = {
  label: undefined,
  value: null,
  hideValue: false,
  isPrivate: false,
  fieldHelp: undefined,
  optional: false,
  disabled: false,
};

DataFieldWithCopy.propTypes = {
  label: string,
  value: string,
  hideValue: bool,
  isPrivate: bool,
  fieldHelp: elementType,
  optional: bool,
  disabled: bool,
};

export default DataFieldWithCopy;
