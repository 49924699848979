import * as actionTypes from './actionTypes';

// Used by the new create compute plane wizard
export const updateComputePlane = ({
  computePlaneId,
  updatedData,
  nextStepId,
  isComputeConfigurationDirty,
  shouldReverifyRole,
}) => {
  return {
    type: actionTypes.updateComputePlane,
    payload: {
      computePlaneId,
      updatedData,
      nextStepId,
      isComputeConfigurationDirty,
      shouldReverifyRole,
    },
  };
};

// TODO Figure out if I need this succss and or the error one below it?
export const updateComputePlaneSuccess = () => {
  return {
    type: actionTypes.updateComputePlaneSuccess,
  };
};

export const updateComputePlaneError = ({ apiResult }) => {
  return {
    type: actionTypes.updateComputePlaneError,
    payload: apiResult,
  };
};

export const requestDeploy = (computePlaneId, nextStepId) => {
  return {
    type: actionTypes.requestDeploy,
    payload: { computePlaneId, nextStepId },
  };
};

export const requestDestroy = (computePlaneId, nextStepId) => {
  return {
    type: actionTypes.requestDestroy,
    payload: { computePlaneId, nextStepId },
  };
};

export const resetHasVerifyRoleSuccess = () => {
  return {
    type: actionTypes.resetHasVerifyRoleSuccess,
  };
};

export const setSubmitInProgress = ({ submitInProgress }) => {
  return {
    type: actionTypes.setSubmitInProgress,
    payload: { submitInProgress },
  };
};
