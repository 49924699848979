import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import Container from './Container';
import reducer from './reducer';
import saga from './saga';
import * as selectors from './selectors';

const module = {
  actions,
  actionTypes,
  constants,
  Container,
  reducer,
  saga,
  selectors,
};

export default module;
