import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';

export const patch = (rootUri, headers, params, body) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `prestousers/${params.prestoUserId}`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePATCH(uri, headers, prepedBody);
};
