import { useCallback, useState } from 'react';
import { func, string, elementType, shape, arrayOf } from 'prop-types';
import Page from '../../Page';
import Form from '../../Form';
import Data from '../../Data';
import Banner from '../../Banner';
import IAMPolicy from '../../IAMPolicy';
import * as Help from './DestroyErrorHelp';

const display = {
  title: 'Error Destroying Compute Plane',
  provisioningTitle: 'Role Provisioning Setup',
  errorBannerHeader: 'There was an issue destroying your compute plane',
  roleARNLabel: 'Role ARN',
  accessContentHeader: 'Provisioning Credentials',
  policyDefinitionsHeader: 'IAM Policy Definitions',
  iamRoleCreationHeader: 'IAM Role Creation',
  awsPermissionBoundaryPolicyLabel: 'Permission Boundary AWS IAM Policy',
  awsCoreInfrastructurePolicyLabel: 'Core Infrastructure AWS IAM Policy',
  awsOperationsPolicyLabel: 'Operations AWS IAM Policy',
  awsRoleTrustRelationshipPolicyLabel: 'IAM Role Trust Relationship Policy',
  viewPolicy: 'View Policy',
  cloudFormationTemplateLabel: 'Provisioning Role CloudFormation Template',
  viewCloudFormationTemplate: 'View CloudFormation Template',
  terraformTemplateLabel: 'Provisioning Role Terraform',
  viewTerraformTemplate: 'View Terraform',
};

const PolicyField = ({ label, fieldHelp, policyType, setViewPolicy }) => {
  const handleClick = useCallback(() => {
    setViewPolicy(policyType);
  }, [setViewPolicy, policyType]);
  return (
    <>
      <Data.Label label={label} fieldHelp={fieldHelp} />
      <Data.ButtonGroup>
        <Form.TertiaryButton onClick={handleClick}>
          {display.viewPolicy}
        </Form.TertiaryButton>
      </Data.ButtonGroup>
    </>
  );
};

PolicyField.defaultProps = {
  fieldHelp: undefined,
};

PolicyField.propTypes = {
  fieldHelp: elementType,
  label: string.isRequired,
  policyType: string.isRequired,
  setViewPolicy: func.isRequired,
};

const DestroyError = ({
  errorMessages,
  iamRoleUrl,
  awsRoleARN,
  destroy,
  computePlaneId,
}) => {
  const [policyType, setViewPolicy] = useState(null);

  const handleCloseViewPolicy = useCallback(() => {
    setViewPolicy(null);
  }, [setViewPolicy]);

  const onTryAgain = useCallback(() => {
    destroy(computePlaneId);
  }, [destroy, computePlaneId]);

  if (policyType) {
    return (
      <IAMPolicy.EmbededContainer
        policyType={policyType}
        onClose={handleCloseViewPolicy}
      />
    );
  }

  return (
    <>
      <Page.Box>
        <Page.BoxHeader>
          <h2>{display.title}</h2>
        </Page.BoxHeader>
        <Banner scrollIntoView title={display.errorBannerHeader}>
          <div>
            {errorMessages?.map((m) => {
              if (typeof m === 'string') {
                return <p key={m}>{m}</p>;
              }
              return <p key={m}>{m.errorSummary || m.errorMessage}</p>;
            })}
          </div>
        </Banner>
      </Page.Box>
      <Page.Buttons>
        <Form.PrimaryButton
          label='Try Again'
          disabled={false}
          disabledOnErrors={false}
          onClick={onTryAgain}
        />
      </Page.Buttons>
      <Page.Box>
        <Page.BoxHeader>
          <h2>{display.provisioningTitle}</h2>
        </Page.BoxHeader>
        <Page.FormContent rows={3}>
          <div>
            <h3>{display.accessContentHeader}</h3>
            {/* <div>{display.formExplaination}</div> */}
          </div>
          <div>
            <Data.DataFieldWithLink
              label={display.roleARNLabel}
              value={awsRoleARN}
              url={iamRoleUrl}
            />
          </div>
          <div>
            <h3>{display.policyDefinitionsHeader}</h3>
          </div>
          <div>
            <PolicyField
              label={display.awsPermissionBoundaryPolicyLabel}
              fieldHelp={Help.AWSPermissionBoundaryPolicy}
              policyType={IAMPolicy.constants.policyType.permissionBoundary}
              setViewPolicy={setViewPolicy}
            />
            <PolicyField
              label={display.awsCoreInfrastructurePolicyLabel}
              fieldHelp={Help.AWSCoreInfrastructurePolicy}
              policyType={IAMPolicy.constants.policyType.coreInfrastructure}
              setViewPolicy={setViewPolicy}
            />
            <PolicyField
              label={display.awsOperationsPolicyLabel}
              fieldHelp={Help.AWSOperationsPolicy}
              policyType={IAMPolicy.constants.policyType.operations}
              setViewPolicy={setViewPolicy}
            />
            {/* TODO when we get this policy we will render this button */}
            <PolicyField
              label={display.awsRoleTrustRelationshipPolicyLabel}
              fieldHelp={Help.AWSTrustRelationshipPolicy}
              policyType={IAMPolicy.constants.policyType.trustRelationship}
              setViewPolicy={setViewPolicy}
            />
          </div>
          <div>
            <h3>{display.iamRoleCreationHeader}</h3>
          </div>
          <div>
            <PolicyField
              label={display.cloudFormationTemplateLabel}
              fieldHelp={Help.ProvisioningRoleCFTemplate}
              policyType={
                IAMPolicy.constants.policyType.provisioningRoleCFTemplate
              }
              setViewPolicy={setViewPolicy}
            />
            <PolicyField
              label={display.terraformTemplateLabel}
              fieldHelp={Help.ProvisioningRoleTerraformTemplate}
              policyType={
                IAMPolicy.constants.policyType.provisioningRoleTerraformTemplate
              }
              setViewPolicy={setViewPolicy}
            />
          </div>
        </Page.FormContent>
      </Page.Box>
    </>
  );
};

DestroyError.defaultProps = {
  errorMessages: null,
};

DestroyError.propTypes = {
  errorMessages: arrayOf(
    shape({
      errorSummary: string,
      errorMessage: string,
    }),
  ),
  iamRoleUrl: string.isRequired,
  awsRoleARN: string.isRequired,
  destroy: func.isRequired,
  computePlaneId: string.isRequired,
};

export default DestroyError;
