import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';

export const getTenantSummary = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `/billing/tenants/${params.tenantId}`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getContract = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `/billing/contracts/${params.contractId}`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getClusters = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `/billing/clusters?startDate=${params.startDate}&endDate=${params.endDate}`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};
