import { string, bool } from 'prop-types';
import Banner from '../../Banner';
import Page from '../../Page';
import Form from '../../Form';
import Help from '../../Help';
import PageHelp from './PageHelp';
import Security from '../../Security';
import * as UpdateProviderHelp from '../../IdentityProviderAdd/components/IdentityProviderAddHelp';
import IdentityProviders from '../../IdentityProviders';

const display = {
  header: 'Edit Identity Provider',
  cancelButton: 'Cancel',
  boxTitle: 'Details',
  generalContentHeader: 'General',
  formContentHeader: 'Connection',
  nameLabel: 'Name',
  clientSecretLabel: 'Client Secret',
  clientSecretRequiredError: 'Client Secret is required',
  nameRequiredError: 'Name is required',
  submitButton: 'Edit Identity Provider',
  submittingButton: 'Modifying Identity Provider...',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
};

const UpdateIdentityProviderForm = ({
  submitInProgress,
  submitSuccess,
  usePop,
  error,
  identityProvider,
}) => {
  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute: Security.route.getRoute,
    usePop,
  });

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.PrimaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <Page.Box>
            <Page.BoxHeader>
              <h2>{display.boxTitle}</h2>
            </Page.BoxHeader>
            {error && <Banner title={error} />}
            <Page.FormContent>
              <div>
                <h3>{display.generalContentHeader}</h3>
              </div>
              <div>
                <Form.TextInput
                  name='name'
                  label={display.nameLabel}
                  defaultValue={identityProvider.name}
                  disabled={submitInProgress}
                  validationRules={{
                    required: display.nameRequiredError,
                  }}
                  fieldHelp={UpdateProviderHelp.Name}
                />
              </div>
              <div>
                <h3>{display.formContentHeader}</h3>
              </div>
              <div>
                <Form.PasswordInput
                  name='clientSecret'
                  label={display.clientSecretLabel}
                  disabled={submitInProgress || false}
                  validationRules={{
                    required: display.clientSecretRequiredError,
                  }}
                  fieldHelp={UpdateProviderHelp.ClientSecret}
                />
              </div>
            </Page.FormContent>
          </Page.Box>
          <Page.Buttons>
            <Form.SecondaryButton
              onClick={onCancel}
              label={display.cancelButton}
              disabled={submitInProgress}
              disabledOnErrors={false}
            />
            <Form.Submit
              label={
                !submitInProgress
                  ? display.submitButton
                  : display.submittingButton
              }
              disabled={submitInProgress}
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

UpdateIdentityProviderForm.defaultProps = {
  usePop: true,
  error: null,
};

UpdateIdentityProviderForm.propTypes = {
  identityProvider: IdentityProviders.propTypes.isRequired,
  submitInProgress: bool.isRequired,
  submitSuccess: bool.isRequired,
  usePop: bool,
  error: string,
};

export default UpdateIdentityProviderForm;
