import Help from '../../Help';

const Section = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Glue</Help.HelpHeader>
      <Help.P>something</Help.P>
    </Help.HelpSection>
  );
};

const AWSRegion = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Region</Help.HelpHeader>
      <Help.P>
        Select the Region where your AWS Glue Data Catalog is located.
      </Help.P>
    </Help.HelpSection>
  );
};

const GlueRoleARN = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Glue Role ARN</Help.HelpHeader>
      <Help.P>
        Amazon Resource Names (ARNs) uniquely identify AWS resources. Enter the
        ARN for the Glue IAM Role that has the permissions to access the Glue
        Catalog in the selected Region.
      </Help.P>
      <Help.P>
        This role will be used by your Presto cluster to access your Glue
        Catalog.
      </Help.P>
    </Help.HelpSection>
  );
};

const S3RoleARN = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>S3 Role ARN</Help.HelpHeader>
      <Help.P>
        Amazon Resource Names (ARNs) uniquely identify AWS resources. If
        different from the Glue Role above, enter the ARN for the S3 IAM Role
        that has the permissions to access your S3 bucket(s).
      </Help.P>
      <Help.P>
        This role will be used by your Presto cluster to access your S3
        bucket(s).
      </Help.P>
    </Help.HelpSection>
  );
};

const CloudFormationQuickCreate = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>CloudFormation Quick Create</Help.HelpHeader>
      <Help.P>
        CloudFormation Quick Create will create the necessary Role and
        permissions automatically for you.
      </Help.P>
      <Help.P>
        Click on the Open CloudFormation button and follow the steps.
      </Help.P>
    </Help.HelpSection>
  );
};

const GlueCatalogId = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Glue Data Catalog ID</Help.HelpHeader>
      <Help.P>
        The 12-digit AWS account ID of where the Glue Data Catalog is located.
      </Help.P>
      <Help.P>
        This ID is needed if the role being used is from a different account
        than the Glue Data Catalog.
      </Help.P>
    </Help.HelpSection>
  );
};

const GlueS3RoleArn = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Glue/S3 Role ARN</Help.HelpHeader>
      <Help.P>
        Amazon Resource Names (ARNs) uniquely identify AWS resources. Enter the
        ARN for the Glue/S3 IAM Role that was created using the CloudFormation
        quick create.
      </Help.P>
      <Help.P>
        This role will be used by your Presto cluster to access your S3 data via
        the Glue Catalog.
      </Help.P>
    </Help.HelpSection>
  );
};

const AWSLakeFormation = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Enable AWS Lake Formation</Help.HelpHeader>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
        <Help.P>
          Lake Formation is an AWS service to simplify and secure data lakes
          backed by Amazon S3 and Glue. IBM watsonx.data-managed Presto clusters
          can integrate with Lake Formation to enforce data permissions
          centrally defined in the service.
        </Help.P>
        <Help.P>
          Checking this box will allow you to configure this data source to work
          with Lake Formation.
        </Help.P>
      </Help.HelpSection>
    );
  };
};

const LakeFormationCloudFormationQuickCreate = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Data Lake Settings</Help.HelpHeader>
      <Help.P>
        The data lake settings CloudFormation will create the necessary IAM role
        and update your AWS Lake Formation data lake settings to allow IBM
        watsonx.data-managed Presto clusters to integrate with your Lake
        Formation service in your AWS account.
      </Help.P>
      <Help.P>
        Click on the Open CloudFormation button and follow the steps.
      </Help.P>
      <Help.P>
        This is an optional step. If the CloudFormation template is not done, we
        assume you will manually configure your Lake Formation data lake
        settings for IBM watsonx.data integration. Please read our public
        documentation for these steps.
      </Help.P>
    </Help.HelpSection>
  );
};

const AuthorizationService = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Authorization Service</Help.HelpHeader>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
        <Help.P>
          The authorization service to use to secure this data source.
        </Help.P>
      </Help.HelpSection>
    );
  };
};

const module = {
  Section,
  AWSRegion,
  GlueCatalogId,
  GlueRoleARN,
  S3RoleARN,
  CloudFormationQuickCreate,
  GlueS3RoleArn,
  AWSLakeFormation,
  LakeFormationCloudFormationQuickCreate,
  AuthorizationService,
};

export default module;
