/* eslint-disable react/jsx-props-no-spreading */
import { node } from 'prop-types';
import login from '../../img/login.svg';
import { LogoBoxDiv, ScrollDiv } from './styledComponents';

const LogoBox = ({ children }) => {
  return (
    <ScrollDiv>
      <LogoBoxDiv>
        <img className='logo' src={login} alt='watsonx.data' />
        {children}
      </LogoBoxDiv>
    </ScrollDiv>
  );
};

LogoBox.propTypes = {
  children: node.isRequired,
};

export default LogoBox;
