import { createSelector } from 'reselect';
import auth from '../../auth';
// importing this way to remove dependency cycle
import * as AccountSetupSelectors from '../AccountSetup/selectors';
import * as ComputePlaneSelectors from '../ComputePlane/selectors';

export const displayAWSUnsubscribed = (state) => {
  return (
    auth.selectors.isAuthenticated(state) &&
    AccountSetupSelectors.isSubscriptionUnsubscribed(state)
  );
};

export const displayAWSFailed = (state) => {
  return (
    auth.selectors.isAuthenticated(state) &&
    AccountSetupSelectors.isSubscriptionFailed(state)
  );
};

export const displayTrial = (state) => {
  return (
    auth.selectors.isAuthenticated(state) &&
    AccountSetupSelectors.isTrialActive(state)
  );
};

export const displayTrialExpired = (state) => {
  return (
    auth.selectors.isAuthenticated(state) &&
    AccountSetupSelectors.isTrialExpired(state)
  );
};

export const trialDaysLeft = (state) => {
  return AccountSetupSelectors.trialDaysLeft(state);
};

export const displayComputePlaneUpgrade = createSelector(
  ComputePlaneSelectors.getComputePlaneStatus,
  (computePlaneVersion) => {
    return computePlaneVersion === 'upgrading';
  },
);

export const displayComputePlaneLowerThanMinimumVersion = (state) => {
  return (
    ComputePlaneSelectors.getActiveComputePlane(state) &&
    !ComputePlaneSelectors.isComputePlaneGreaterThanMinimumVersion(state)
  );
};

export const displayFooter = (state) => {
  return (
    displayAWSUnsubscribed(state) ||
    displayAWSFailed(state) ||
    displayTrial(state) ||
    displayTrialExpired(state) ||
    displayComputePlaneUpgrade(state) ||
    displayComputePlaneLowerThanMinimumVersion(state) ||
    false
  );
};
