import { string, bool, elementType } from 'prop-types';
import SyntaxHighlighting from '../SyntaxHighlighting';
import Label from './Label';
import { DataDiv, CodeDiv } from './styledComponents';

const CodeField = ({
  label,
  value,
  disabled,
  isPrivate,
  fieldHelp,
  code,
  language,
}) => {
  return (
    <>
      {label && (
        <Label label={label} fieldHelp={fieldHelp} disabled={disabled} />
      )}

      <DataDiv
        className={disabled ? 'disabled' : undefined}
        data-private={isPrivate ? true : undefined}
      >
        <CodeDiv>
          <SyntaxHighlighting code={code} language={language} />
        </CodeDiv>
      </DataDiv>
    </>
  );
};

CodeField.defaultProps = {
  label: undefined,
  value: null,
  disabled: false,
  isPrivate: false,
  fieldHelp: undefined,
};

CodeField.propTypes = {
  label: string,
  value: string,
  disabled: bool,
  isPrivate: bool,
  fieldHelp: elementType,
  code: string.isRequired,
  language: string.isRequired,
};

export default CodeField;
