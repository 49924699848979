import { connect } from '../../utils/redux';
import * as selectors from './selectors';
import ComputePlane from '../ComputePlane';
import * as actions from './actions';
import { AddStandalonePrometheus } from './components';

const mapStateToProps = (state) => {
  return {
    errors: selectors.getErrors(state),
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    usePop: selectors.getUsePop(state),
    instanceTypeValues: selectors.getInstanceTypes(),
    prometheusConfiguration: selectors.getPrometheusConfiguration(state),
    computePlaneId: ComputePlane.selectors.getActiveComputePlaneId(state),
  };
};

export default connect(mapStateToProps, {
  create: actions.submit,
  get: ComputePlane.actions.get,
})(AddStandalonePrometheus);
