import Help from '../../Help';

const Section = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>MySql</Help.HelpHeader>
      <Help.P>something</Help.P>
    </Help.HelpSection>
  );
};

const JDBC = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>JDBC URL</Help.HelpHeader>
      <Help.P>
        The URI to connect to the MySql server. This URI needs to be accessible
        from the VPC where the Presto clusters are running.
      </Help.P>
      <Help.P>This must begin with jdbc:mysql://</Help.P>
      <Help.P>Do not include the database name in the URI.</Help.P>
    </Help.HelpSection>
  );
};

const Endpoint = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Endpoint</Help.HelpHeader>
      <Help.P>
        The endpoint to the MySQL instance (e.g.
        database-1.abcd.us-east-1.rds.amazonaws.com). This endpoint needs to be
        accessible from the VPC where the Presto clusters are running.
      </Help.P>
    </Help.HelpSection>
  );
};

const Port = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Port</Help.HelpHeader>
      <Help.P>
        The port for the MySQL instance (the default port for MySQL is 3306).
      </Help.P>
    </Help.HelpSection>
  );
};

const ConfigurationProperties = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Configuration Properties</Help.HelpHeader>
      <Help.P>
        Configuration properties define how the connector will make a connection
        to a MySQL server (e.g. zeroDateTimeBehavior=convertToNull). See{' '}
        <a
          href='https://dev.mysql.com/doc/connector-j/8.0/en/connector-j-reference-configuration-properties.html'
          target='_blank'
          rel='noopener noreferrer'
        >
          https://dev.mysql.com/doc/connector-j/8.0/en/connector-j-reference-configuration-properties.html
        </a>{' '}
        for more details.
      </Help.P>
    </Help.HelpSection>
  );
};

const User = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>User</Help.HelpHeader>
      <Help.P>The database username.</Help.P>
    </Help.HelpSection>
  );
};

const Password = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Password</Help.HelpHeader>
      <Help.P>The password for the database user.</Help.P>
    </Help.HelpSection>
  );
};

const module = {
  Section,
  Endpoint,
  Port,
  ConfigurationProperties,
  JDBC,
  User,
  Password,
};

export default module;
