/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo } from 'react';
import { node, bool } from 'prop-types';
import Footer from '../Footer';
import Header from '../Header';
import { StandardLayoutDiv } from './styledComponents';

const StandardLayout = ({ noNav, displayFooter, children, ...props }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const style = useMemo(() => {
    return {
      '--header-height': !displayFooter ? '72px' : `${72 + 115}px`,
    };
  }, [displayFooter]);

  return (
    <StandardLayoutDiv style={style}>
      <Header.Container noNav={noNav} {...props} />
      {children}
      <Footer.Container {...props} />
    </StandardLayoutDiv>
  );
};

StandardLayout.defaultProps = {
  noNav: false,
  displayFooter: true,
};

StandardLayout.propTypes = {
  noNav: bool,
  displayFooter: bool,
  children: node.isRequired,
};

export default StandardLayout;
