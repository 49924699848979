import { connect } from '../../utils/redux';
import IAMPolicy from '../IAMPolicy';
import { ComputePlane } from './components';
import * as actions from './actions';
import * as selectors from './selectors';
import * as accountSetupSelector from '../AccountSetup/selectors';

const mapStateToProps = (state) => {
  return {
    error: selectors.getError(state),
    canDestroy: selectors.canDestroy(state),
    submitInProgress: selectors.getSubmitInProgress(state),

    computePlane: selectors.getActiveComputePlane(state),
    computePlaneId: selectors.getActiveComputePlaneId(state),

    controlPlaneAwsAccountId: selectors.getControlPlaneAwsAccountId(state),
    awsExternalId: selectors.getAwsExternalId(state),
    iamRoleUrl: selectors.getIamRoleUrl(state),

    awsPermissionBoundaryPolicyContent: IAMPolicy.selectors.getPolicyContent(
      state,
      IAMPolicy.constants.policyType.permissionBoundary,
    ),
    awsCoreInfrastructurePolicyContent: IAMPolicy.selectors.getPolicyContent(
      state,
      IAMPolicy.constants.policyType.coreInfrastructure,
    ),
    awsOperationsPolicyContent: IAMPolicy.selectors.getPolicyContent(
      state,
      IAMPolicy.constants.policyType.operations,
    ),
    awsTrustRelationshipPolicyContent: IAMPolicy.selectors.getPolicyContent(
      state,
      IAMPolicy.constants.policyType.trustRelationship,
    ),

    shouldUpgradePolicy: selectors.getShouldUpgradePolicy(state),
    missingInfrastructurePolicy:
      selectors.getMissingInfrastructurePolicy(state),
    awsCloudFormationTemplateS3Url:
      selectors.getAwsCloudFormationTemplateS3Url(state),
    cloudFormationStacksListingUrl:
      selectors.getCloudFormationStacksListingUrl(state),
    computePlaneUpdateDate: selectors.getComputePlaneUpdateDate(state),
    tenantCRN: accountSetupSelector.getLakehouseCRN(state),
    tenantServiceName: accountSetupSelector.getTenantServiceName(state),
    cloudFormationTemplate: selectors.getCloudFormationContent(state),
    cloudFormationStackName: selectors.getProvisioningRoleCFStackName(state),
    terraformContent: selectors.getTerraformContent(state),
    terraformFileName: selectors.getProvisioningRoleTerraformFileName(state),
    roleCreationMethod: selectors.getRoleCreationMethod(state),
    canUpgrade: selectors.getCanUpgrade(state),
  };
};

export default connect(mapStateToProps, {
  get: actions.get,
  getConfiguration: actions.getConfiguration,
  destroy: actions.destroy,
  upgrade: actions.upgrade,
  checkProvisioningPolicies: actions.putCheckProvisioningPolicies,
})(ComputePlane);
