import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { string, bool, elementType, object } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { DataWithActionDiv } from '../Data/styledComponents';
import { InputErrorDiv } from './styledComponents';
import Label from './Label';

const display = {
  browseButton: 'Browse',
};

const FileInput = ({
  name,
  label,
  optional,
  disabled,
  fieldHelp,
  binary,
  accept,
  base64Encode,
  validationRules,
}) => {
  const {
    register: formRegister,
    formState: { errors: formErrors },
    setValue,
    formState,
  } = useFormContext();
  const fileInputRef = useRef(null);
  const [fileName, setFilename] = useState('');
  const error = get(formErrors, name);
  const isFormSubmitted = formState.isSubmitted;

  useEffect(() => {
    formRegister(name, validationRules);
  }, [name, validationRules, formRegister]);

  const onChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      // Read contents of the uploaded file
      if (file) {
        setFilename(file.name); // filename for display purposes

        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          // Set the field's value manually to the contents of the file
          let contents = e.target.result;

          if (base64Encode) {
            contents = window.btoa(contents);
          }

          setValue(name, contents, {
            shouldDirty: true,
            shouldValidate: isFormSubmitted,
          });
        };

        if (binary) {
          fileReader.readAsBinaryString(file);
        } else {
          fileReader.readAsText(file);
        }
      }
    },
    [name, setValue, base64Encode, binary, isFormSubmitted],
  );

  // Triggers the input field when clicking custom Browse button
  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const className = useMemo(() => {
    if (!error) return undefined;

    let c = '';
    if (error) {
      c = `${c} error`;
    }
    return c;
  }, [error]);

  return (
    <>
      <Label label={label} optional={optional} fieldHelp={fieldHelp} />
      <DataWithActionDiv className={className}>
        <div>{fileName}</div>
        <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={onChange}
          accept={accept || '*'}
        />
        <button type='button' onClick={handleBrowseClick} disabled={disabled}>
          {display.browseButton}
        </button>
      </DataWithActionDiv>
      {error && <InputErrorDiv role='alert'>{error.message}</InputErrorDiv>}
    </>
  );
};

FileInput.defaultProps = {
  optional: false,
  fieldHelp: undefined,
  binary: false,
  accept: undefined,
  base64Encode: false,
  validationRules: undefined,
  disabled: false,
};

FileInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  optional: bool,
  fieldHelp: elementType,
  binary: bool,
  accept: string,
  base64Encode: bool,
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  disabled: bool,
};

export default FileInput;
