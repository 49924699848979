/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect } from 'react';
import { arrayOf, func, bool, string, shape } from 'prop-types';
import DataSources from '../../DataSources';
import PrestoUsers from '../../PrestoUsers';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import AuthorizationServices from '../../AuthorizationServices';
import PrestoUsersAdd from '../../PrestoUsersAdd';
import SelectDataSourceType from './SelectDataSourceType';
import DataSourceDetails from './DataSourceDetails';
import ConnectionDetails from './ConnectionDetails';
import PrestoUserMappings from './PrestoUserMappings';
import PageHelp from './PageHelp';

const display = {
  header: 'Add a Data Source',
  addButton: 'Add Data Source',
  cancelButton: 'Cancel',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  savingDataSource: 'Adding data source...',
};

const AddDataSourceForm = ({
  errors,
  submitInProgress,
  submitSuccess,
  usePop,
  awsRegions,
  computePlaneRegion,
  awsGlueS3CloudFormationQuickCreateUrl,
  awsLakeFormationCloudFormationQuickCreateUrl,
  prestoUsers,
  loadingPrestoUsers,
  authorizationServices,
}) => {
  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute: DataSources.routes.getRoute,
    usePop,
  });

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>
          {display.header} {usePop}
        </h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <SelectDataSourceType
            disabled={submitInProgress}
            error={errors.type}
          />
          <DataSourceDetails
            disabled={submitInProgress}
            error={errors.details}
          />
          <ConnectionDetails
            disabled={submitInProgress}
            error={errors.connection}
            awsRegions={awsRegions}
            computePlaneRegion={computePlaneRegion}
            awsGlueS3CloudFormationQuickCreateUrl={
              awsGlueS3CloudFormationQuickCreateUrl
            }
            awsLakeFormationCloudFormationQuickCreateUrl={
              awsLakeFormationCloudFormationQuickCreateUrl
            }
            prestoUsers={prestoUsers}
            loadingPrestoUsers={loadingPrestoUsers}
            authorizationServices={authorizationServices}
          />
          <PrestoUserMappings
            disabled={submitInProgress}
            error={errors.prestoUserMappings}
            prestoUsers={prestoUsers}
            loadingPrestoUsers={loadingPrestoUsers}
          />
          <Page.Buttons>
            <Form.SecondaryButton
              onClick={onCancel}
              label={display.cancelButton}
              disabled={submitInProgress}
              disabledOnErrors={false}
            />
            <Form.Submit
              label={
                !submitInProgress ? display.addButton : display.savingDataSource
              }
              disabled={submitInProgress}
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

AddDataSourceForm.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  awsGlueS3CloudFormationQuickCreateUrl: null,
  awsLakeFormationCloudFormationQuickCreateUrl: null,
  usePop: true,
  errors: {},
  prestoUsers: null,
  authorizationServices: null,
};

AddDataSourceForm.propTypes = {
  submitInProgress: bool,
  submitSuccess: bool,
  usePop: bool,
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  computePlaneRegion: string.isRequired,
  awsGlueS3CloudFormationQuickCreateUrl: string,
  awsLakeFormationCloudFormationQuickCreateUrl: string,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadingPrestoUsers: bool.isRequired,
  authorizationServices: arrayOf(
    AuthorizationServices.propTypes.AuthorizationService,
  ),
};

const AddDataSource = ({
  create,
  getConfiguration,
  errors,
  submitInProgress,
  submitSuccess,
  usePop,
  awsRegions,
  computePlaneRegion,
  awsGlueS3CloudFormationQuickCreateUrl,
  awsLakeFormationCloudFormationQuickCreateUrl,
  prestoUsers,
  loadingPrestoUsers,
  loadPrestoUsers,
  resetCreatePrestoUser,
  getAuthorizationServices,
  authorizationServices,
}) => {
  useEffect(() => {
    loadPrestoUsers();
    getAuthorizationServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!awsGlueS3CloudFormationQuickCreateUrl) {
      getConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awsGlueS3CloudFormationQuickCreateUrl]);

  const showCreatePrestoUser =
    PrestoUsersAdd.components.useCreatePrestoUserState();
  useEffect(() => {
    if (!showCreatePrestoUser) {
      resetCreatePrestoUser();
    }
  }, [showCreatePrestoUser, resetCreatePrestoUser]);
  const dispatch = PrestoUsersAdd.components.useCreatePrestoUserDispatch();
  const hideCreatePrestoUser = useCallback(() => {
    return dispatch(false);
  }, [dispatch]);

  return (
    <>
      {showCreatePrestoUser && (
        <PrestoUsersAdd.Container hideCreatePrestoUser={hideCreatePrestoUser} />
      )}
      <Page.PageWithOverflow
        style={{ display: showCreatePrestoUser ? 'none' : undefined }}
      >
        <Form.Form name='add-data-source' submitAction={create}>
          <AddDataSourceForm
            submitInProgress={submitInProgress}
            submitSuccess={submitSuccess}
            errors={errors}
            usePop={usePop}
            awsRegions={awsRegions}
            computePlaneRegion={computePlaneRegion}
            awsGlueS3CloudFormationQuickCreateUrl={
              awsGlueS3CloudFormationQuickCreateUrl
            }
            awsLakeFormationCloudFormationQuickCreateUrl={
              awsLakeFormationCloudFormationQuickCreateUrl
            }
            prestoUsers={prestoUsers}
            loadingPrestoUsers={loadingPrestoUsers}
            authorizationServices={authorizationServices}
          />
        </Form.Form>
      </Page.PageWithOverflow>
    </>
  );
};

AddDataSource.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  awsGlueS3CloudFormationQuickCreateUrl: null,
  awsLakeFormationCloudFormationQuickCreateUrl: null,
  usePop: true,
  errors: {},
  prestoUsers: null,
  authorizationServices: null,
};

AddDataSource.propTypes = {
  create: func.isRequired,
  getConfiguration: func.isRequired,
  submitInProgress: bool,
  submitSuccess: bool,
  usePop: bool,
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  computePlaneRegion: string.isRequired,
  awsGlueS3CloudFormationQuickCreateUrl: string,
  awsLakeFormationCloudFormationQuickCreateUrl: string,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadingPrestoUsers: bool.isRequired,
  loadPrestoUsers: func.isRequired,
  resetCreatePrestoUser: func.isRequired,
  authorizationServices: arrayOf(
    AuthorizationServices.propTypes.AuthorizationService,
  ),
  getAuthorizationServices: func.isRequired,
};

const { CreatePrestoUserNavigation } = PrestoUsersAdd.components;
const AddDataSourceWrapper = (props) => {
  return (
    <CreatePrestoUserNavigation>
      <AddDataSource {...props} />
    </CreatePrestoUserNavigation>
  );
};

AddDataSourceWrapper.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  awsGlueS3CloudFormationQuickCreateUrl: null,
  awsLakeFormationCloudFormationQuickCreateUrl: null,
  usePop: true,
  errors: {},
  prestoUsers: null,
};

AddDataSourceWrapper.propTypes = {
  create: func.isRequired,
  getConfiguration: func.isRequired,
  submitInProgress: bool,
  submitSuccess: bool,
  usePop: bool,
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  computePlaneRegion: string.isRequired,
  awsGlueS3CloudFormationQuickCreateUrl: string,
  awsLakeFormationCloudFormationQuickCreateUrl: string,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadingPrestoUsers: bool.isRequired,
  loadPrestoUsers: func.isRequired,
  getAuthorizationServices: func.isRequired,
};

export default AddDataSourceWrapper;
