import { bool, elementType, shape, number, string } from 'prop-types';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import SignIn from '../components/SignIn';
import Page from '../components/Page';
import ComputePlane from '../components/ComputePlane';
import ErrorRoute from './ErrorRoute';

const PrivateRoute = ({
  component: Component,
  authenticated,
  authError,
  isAccountLoaded,
  isComputePlaneDestroying,
  isComputePlaneCreating,
  displayFooter,
  noNav,
  isAuthReady,
}) => {
  const location = useLocation();
  const params = useParams();
  const { pathname: path } = location;

  if (authError) {
    return <ErrorRoute authError={authError} />;
  }

  if (authenticated) {
    if (!isAccountLoaded) {
      return (
        <Page.StandardLayout noNav>
          <Page.Loading />
        </Page.StandardLayout>
      );
    }

    if (
      isComputePlaneCreating &&
      path !== ComputePlane.routes.addComputePlaneRoute()
    ) {
      return (
        <Navigate
          to={{
            pathname: ComputePlane.routes.addComputePlaneRoute(),
            search: location.search,
          }}
          state={{ from: location }}
          replace
        />
      );
    }

    if (
      isComputePlaneDestroying &&
      path !== ComputePlane.routes.destroyComputePlaneRoute()
    ) {
      return (
        <Navigate
          to={{
            pathname: ComputePlane.routes.destroyComputePlaneRoute(),
            search: location.search,
          }}
          state={{ from: location }}
          replace
        />
      );
    }

    return (
      <Page.StandardLayout
        params={params}
        noNav={isComputePlaneCreating || isComputePlaneDestroying || noNav}
        displayFooter={displayFooter}
      >
        <Component params={params} location={location} />
      </Page.StandardLayout>
    );
  }

  if (!isAuthReady) {
    return (
      <Page.StandardLayout noNav>
        <Page.Loading />
      </Page.StandardLayout>
    );
  }

  return (
    <Navigate
      to={{
        pathname: SignIn.route.getRoute(),
        search: location.search,
      }}
      state={{ from: location }}
      replace
    />
  );
};

PrivateRoute.defaultProps = {
  component: null,
  authenticated: false,
  noNav: false,
  isAccountLoaded: false,
  isComputePlaneDestroying: false,
  isComputePlaneCreating: false,
  displayFooter: false,
  isAuthReady: bool,
  authError: null,
};

PrivateRoute.propTypes = {
  component: elementType,
  authenticated: bool,
  noNav: bool,
  isAccountLoaded: bool,
  isComputePlaneDestroying: bool,
  isComputePlaneCreating: bool,
  displayFooter: bool,
  isAuthReady: bool,
  authError: shape({
    status: number,
    message: string,
  }),
};

export default PrivateRoute;
