import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const addParams = (to, currentSearch, skipSearchParams) => {
  if (skipSearchParams) return to;
  if (!to) return to;

  const path = typeof to === 'string' ? { pathname: to } : to;

  const currentSearchParams = new URLSearchParams(currentSearch);
  const pathSearchParams = new URLSearchParams(path.search);

  // Add all globally necessary search params here
  const crn = currentSearchParams.get('crn');
  if (crn) {
    pathSearchParams.append('crn', crn);
  }

  const pathSearch = pathSearchParams.toString();

  path.search = pathSearch ? `?${pathSearch}` : undefined;
  return path;
};

const useRouteBuilder = (to, skipSearchParams = false) => {
  const { search } = useLocation();
  const resolvedTo = typeof to === 'function' ? to() : to;

  return useMemo(() => {
    return addParams(resolvedTo, search, skipSearchParams);
  }, [resolvedTo, search, skipSearchParams]);
};

export { addParams };
export default useRouteBuilder;
