import styled from 'styled-components/macro';
import Page from '../../Page';
import { colors, fonts } from '../../../css';

export const NoDataDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;

export const ConnectionDetailsHeader = styled(Page.BoxHeader)`
  display: flex;
  flex-flow: row;
  justify-content: stretch;

  h2 {
    flex: 1 0 auto;
  }

  .logo {
    flex: 0 0 auto;
    border-left: 1px solid ${colors.border};
    padding: 10px 17px;

    img {
      vertical-align: middle;
      height: 56px;
    }
  }

  .data-source-type {
    flex: 0 0 auto;
    border-left: 1px solid ${colors.border};
    padding: 26px 24px 26px 16px;
    ${fonts.medium_bold}
    letter-spacing: 0.16px;
  }
`;
