import { createSelector } from 'reselect';

const getStateDomain = (state) => {
  return state.formWizard;
};

export const getActiveStepId = createSelector(
  getStateDomain,
  (formWizardState) => {
    if (!formWizardState) {
      return null;
    }
    return formWizardState.activeStepId;
  },
);

export const getSteps = createSelector(getStateDomain, (formWizardState) => {
  if (!formWizardState) {
    return null;
  }

  return formWizardState.steps;
});

export const getState = createSelector(getStateDomain, (formWizardState) => {
  if (!formWizardState) {
    return null;
  }

  return {
    activeStepId: formWizardState.activeStepId,
    steps: formWizardState.steps,
  };
});
