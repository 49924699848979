import { connect } from '../../utils/redux';
import { AddAuthorizationService } from './components';
import * as actions from './actions';
import * as selectors from './selectors';

const mapStateToProps = (state) => {
  return {
    errors: selectors.getErrors(state),
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    usePop: selectors.getUsePop(state),
  };
};

export default connect(mapStateToProps, {
  create: actions.submit,
})(AddAuthorizationService);
