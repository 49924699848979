export const getRoute = () => {
  return '/datasources';
};

export const getAddDataSourceRoute = () => {
  return '/datasources/add';
};

export const getManageDataSourceRoute = (dataSourceId) => {
  return `/datasources/${dataSourceId}`;
};

export const getUpdateDataSourceRoute = (dataSourceId) => {
  return `/datasources/${dataSourceId}/update`;
};
