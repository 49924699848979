import Ably from 'ably';
import { buffers, eventChannel } from 'redux-saga';
import { buildUrl } from '../utils/buildUrl';
import logger from '../utils/logger';
import auth from '../auth';
import { executeGET } from './api';
import { createApiAction } from './actions';
import { apiVerbs, createApiActionType } from './actionTypes';
import { makeReducer } from '../utils/reducer';

const errorCodeCount = {};

const logConnectionStateChange = (change) => {
  logger.info(
    `Ably state changed from ${change.previous} to ${change.current}`,
  );

  if (
    change.current === 'disconnected' ||
    change.current === 'suspended' ||
    change.current === 'failed'
  ) {
    const code = change.reason && change.reason.code;
    const message = change.reason && change.reason.message;
    const statusCode = change.reason && change.reason.statusCode;

    if (code) {
      if (errorCodeCount[code]) errorCodeCount[code] += 1;
      else errorCodeCount[code] = 1;
    }

    if (
      code === 80003 || // No activity seen from realtime in 6444480ms; assuming connection has dropped
      ((code === 80002 || // Connection to server unavailable
        code === 80016 || // Operation on superseded connection or Invalid transport id
        code === 80022 || // Unable to find connection
        code === 40142 || // Token expired code or Key/token status changed (expire)
        code === 40102 || // Unexpected clientId mismatch
        (code === 80019 && statusCode === 401) || // Client configured authentication provider request failed - sign out
        code === 20000) && // connection superseded or Transport superseded
        (!errorCodeCount[code] || errorCodeCount[code] < 10)) // report these as errors if happens more than 10 times
    ) {
      logger.warn(
        `Ably client in a potentially bad state. Reason: ${message}`,
        change,
      );
      return;
    }
    logger.error(`Ably client in a bad state. Reason: ${message}`, change);
  }
};

const ablyChannels = {};
export function connectToChannel(channelName) {
  return eventChannel((emitter) => {
    const authCallback = (data, callback) => {
      return emitter({ type: 'authCallback', args: { data, callback } });
    };

    const messageHandler = (message) => {
      return emitter({ type: 'message', args: { message } });
    };

    const ably = new Ably.Realtime({ authCallback });
    ably.connection.on(logConnectionStateChange);

    const channel = ably.channels.get(channelName);
    ablyChannels[channelName] = channel;

    channel.subscribe(messageHandler);

    channel.whenState('detached', () => {
      ably.channels.release(channelName);
      ably.connection.close();
      ablyChannels[channelName] = null;
      logger.info('ably connection closed');
    });
    return () => {
      logger.info('shutting down ably');
      channel.unsubscribe();
      channel.detach();
    };
  }, buffers.expanding(10));
}

export const actionTypes = {
  getAblyJWT: createApiActionType(apiVerbs.GET, 'ABLY_JWT'),
};

const api = {
  getAblyJWT: (rootUri, headers) => {
    const uri = buildUrl({ baseUrl: rootUri, path: 'ably/jwt' });
    return executeGET(uri, headers);
  },
};

export const actions = {
  getAblyJWT: () => {
    return createApiAction(actionTypes.getAblyJWT, api.getAblyJWT, true);
  },
};

const reducers = {
  setTenantId: (draft, action) => {
    draft.tenantId = action.payload.apiResult.body.tenantId;
  },
  get: (draft, action) => {
    draft.ablyJWT = action.payload.apiResult.body.ablyJWT;
  },
  reset: (draft) => {
    draft.ablyJWT = null;
    draft.tenantId = null;
  },
};

const actionReducers = {
  [actionTypes.getAblyJWT.success]: reducers.get,
  [actionTypes.getAblyJWT.failure]: reducers.reset,
  [auth.actionTypes.getAuth.success]: reducers.setTenantId,
  [auth.actionTypes.signOut]: reducers.reset,
};

export const reducer = makeReducer(
  { ablyJWT: null, tenantId: null },
  actionReducers,
);

export const selectors = {
  getAblyJWT: (state) => {
    return state.ably.ablyJWT;
  },
  getTenantId: (state) => {
    return state.ably.tenantId;
  },
};
