import Help from '../../Help';

const Name = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Name</Help.HelpHeader>
      <Help.P>
        Pick a name for the authorization service. This is unique across the IBM
        watsonx.data compute plane. We recommend a descriptive name to help you
        identify the authorization service.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = { Name };

export default module;
