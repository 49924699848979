import { makeReducer } from '../../utils/reducer';
import * as authActionTypes from '../../auth/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {
  submitInProgress: false,
  error: null,
  errorStatus: null,
};

const onNavigation = () => {
  return { ...initialState };
};

const submit = (draft) => {
  draft.submitInProgress = true;
  draft.firstSignIn = false;
  draft.error = null;
};

const success = () => {
  return { ...initialState };
};

const error = (draft, action) => {
  draft.submitInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
  draft.errorStatus = action.payload.apiResult.status;
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.submit]: submit,
  [actionTypes.success]: success,
  [actionTypes.error]: error,
  [authActionTypes.getAuth.failure]: error,
};

export default makeReducer(initialState, actionReducers);
