import { createApiAction } from '../../rayApi/actions';
import telemetry from '../../telemetry';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const getAll = () => {
  return createApiAction(actionTypes.getAll, api.getAll, true);
};

export const get = (dataSourceId) => {
  return createApiAction(actionTypes.get, api.get, true, { dataSourceId });
};

export const getPrestoClusters = (dataSourceId) => {
  return createApiAction(
    actionTypes.getPrestoClusters,
    api.getPrestoClusters,
    true,
    { dataSourceId },
  );
};

export const patch = (
  dataSourceId,
  { name, description, type, connection },
) => {
  return createApiAction(
    actionTypes.patch,
    api.patch,
    true,
    { dataSourceId },
    { name, description, type, connection },
  );
};

export const remove = (dataSourceId, actionId) => {
  return createApiAction(
    actionTypes.remove,
    api.remove,
    true,
    {
      dataSourceId,
    },
    null,
    { actionId, name: 'delete' },
  );
};

const actionSanitizer = (action) => {
  let sanitizedAction = action;
  if (action.type === actionTypes.get.success) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        apiResult: {
          ...action.payload.apiResult,
          body: {
            ...action.payload.apiResult.body,
            connection: telemetry.sanitize(
              action.payload.apiResult.body.connection,
            ),
          },
        },
      },
      sanitized: true,
    };
  } else if (action.type === actionTypes.getAll.success) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        apiResult: {
          ...action.payload.apiResult,
          body: {
            ...action.payload.apiResult.body,
            results: telemetry.sanitize(action.payload.apiResult.body.results),
          },
        },
      },
      sanitized: true,
    };
  }
  return sanitizedAction;
};

telemetry.actionSanitizer.registerActionSanitizer(
  'dataSources',
  actionSanitizer,
);
