const display = {
  passwordInvalidCharacters:
    'Password can only contain printable ASCII characters except space( ), slash(/), double quote("), at sign(@), backslash(\\) or single quote(\')',
  usernameAndPasswordMustBeDifferent: 'Username and password must be different',
  complexityError:
    'Password must contain at least 3 of the following: one uppercase letter, one lowercase letter, one number and one special character.',
};

function validatePasswordComplexity(value, username) {
  if (value === username) {
    return display.usernameAndPasswordMustBeDifferent;
  }

  const validCharacterSet = /^[a-zA-Z0-9!#$%&()*+,-.:;<=>?[\]^_`{|}~]*$/.test(
    value,
  );
  if (!validCharacterSet) {
    return display.passwordInvalidCharacters;
  }

  const containsNumber = /\d/.test(value);
  const containsLowercase = /[a-z]/.test(value);
  const containsUppercase = /[A-Z]/.test(value);
  const containsSpecialCharacter = /[!#$%&()*+,-.:;<=>?[\]^_`{|}~]/.test(value);

  const complexityRequirementsMet =
    containsNumber +
    containsLowercase +
    containsUppercase +
    containsSpecialCharacter;

  if (complexityRequirementsMet < 3) {
    return display.complexityError;
  }

  return true;
}

export default validatePasswordComplexity;
