/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { string, shape, bool, object, arrayOf, elementType } from 'prop-types';
import { useSelect, useMultipleSelection } from 'downshift';
import DropDownIcon from '../../icons/DropDown';
import Label from './Label';
import {
  InputErrorDiv,
  SelectInputButton,
  SelectList,
  SelectInputDiv,
} from './styledComponents';

const itemsToString = (items) => {
  if (items && items.length > 0) {
    return items.reduce((acc, i) => {
      if (acc.length > 0) {
        return `${acc}, ${i.display}`;
      }
      return i.display;
    }, '');
  }
  return '';
};

const MultiSelectInput = ({
  name,
  label,
  disabled,
  defaultValue,
  validationRules,
  items,
  isPrivate,
  optional,
  fieldHelp,
}) => {
  const {
    field: { onChange, onBlur, value, ref: selectButtonRef },
    fieldState: { error: fieldError },
  } = useController({
    name,
    rules: validationRules,
    defaultValue,
  });

  const initItems = useMemo(() => {
    if (!value || value.length === 0) return [];
    return value.reduce((acc, v) => {
      const item = items.find((i) => {
        return i.value === v;
      });
      if (item) acc.push(item);
      return acc;
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectedItemsChange = useCallback(
    (state) => {
      if (state.selectedItems) {
        const values = state.selectedItems.map((s) => {
          return s.value;
        });
        if (values.length === 0) {
          onChange(null);
        } else {
          onChange(values);
        }
      } else {
        onChange(null);
      }
    },
    [onChange],
  );

  const onIsOpenChange = useCallback(
    (state) => {
      if (!state.isOpen) {
        onBlur();
      }
    },
    [onBlur],
  );

  const {
    getDropdownProps,
    reset,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    initialSelectedItems: initItems,
    onSelectedItemsChange,
  });

  const [prevItems, setPrevItems] = useState(items);
  useEffect(() => {
    if (prevItems) {
      for (let i = 0; i < items.length; i += 1) {
        if (
          items[i].display !== prevItems[i].display ||
          items[i].value !== prevItems[i].value
        ) {
          reset();
          break;
        }
      }
    }
    setPrevItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0,
    items,
    initialSelectedItem: initItems,
    onIsOpenChange,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            const item = selectedItems.find((s) => {
              return selectedItem.value === s.value;
            });
            if (item) {
              removeSelectedItem(item);
            } else {
              addSelectedItem(selectedItem);
            }
          }
          break;
        default:
          break;
      }
    },
  });

  const selectInputDivRef = useRef(null);
  const [dropDownWidth, setDropDownWidth] = useState('200px');
  useEffect(() => {
    if (selectInputDivRef.current) {
      const width = selectInputDivRef.current.offsetWidth;
      setDropDownWidth(`${width}px`);
    }
  }, [selectInputDivRef]);

  const handleFocusChange = useCallback(
    (e) => {
      if (
        selectInputDivRef.current &&
        !selectInputDivRef.current.contains(e.target) &&
        selectButtonRef.current &&
        !selectButtonRef.current.contains(e.target)
      ) {
        closeMenu();
      }
    },
    [selectInputDivRef, closeMenu, selectButtonRef],
  );

  useEffect(() => {
    document.addEventListener('focusin', handleFocusChange);

    return () => {
      document.removeEventListener('focusin', handleFocusChange);
    };
  }, [handleFocusChange]);

  let buttonClassName = fieldError ? 'error ' : '';
  if (isOpen) {
    buttonClassName = `${buttonClassName}open`;
  }
  if (buttonClassName === '') {
    buttonClassName = undefined;
  }

  return (
    <>
      <Label
        label={label}
        optional={optional}
        fieldHelp={fieldHelp}
        {...getLabelProps()}
      />
      <SelectInputButton
        ref={selectButtonRef}
        {...getToggleButtonProps({
          disabled,
          className: buttonClassName,
          type: 'button',
          ...getDropdownProps({ preventKeyAction: isOpen }),
        })}
        data-private={isPrivate ? true : undefined}
      >
        <div>{selectedItems ? itemsToString(selectedItems) : ''}</div>
        <DropDownIcon />
      </SelectInputButton>
      {fieldError && !isOpen && (
        <InputErrorDiv role='alert'>{fieldError.message}</InputErrorDiv>
      )}
      <SelectInputDiv
        ref={selectInputDivRef}
        style={{ '--dropdown-width': dropDownWidth }}
        className={buttonClassName}
      >
        <SelectList
          {...getMenuProps({ className: isOpen ? 'open' : undefined })}
        >
          {isOpen &&
            items.map((item, index) => {
              let itemClassName =
                index === highlightedIndex ? 'highlighted ' : '';
              if (
                selectedItems &&
                selectedItems.find((s) => {
                  return item.value === s.value;
                })
              ) {
                itemClassName = `${itemClassName}selected`;
              }
              if (itemClassName === '') {
                itemClassName = undefined;
              }

              return (
                <li
                  key={`${item.value}`}
                  {...getItemProps({ item, index, className: itemClassName })}
                  data-private={isPrivate ? true : undefined}
                >
                  {item.display}
                </li>
              );
            })}
        </SelectList>
      </SelectInputDiv>
    </>
  );
};

MultiSelectInput.defaultProps = {
  disabled: false,
  validationRules: undefined,
  defaultValue: undefined,
  items: [],
  isPrivate: false,
  optional: false,
  fieldHelp: undefined,
};

MultiSelectInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  defaultValue: arrayOf(string),
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  items: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ),
  isPrivate: bool,
  optional: bool,
  fieldHelp: elementType,
};

export default MultiSelectInput;
