import { useEffect } from 'react';
import { string, bool, func } from 'prop-types';
import Data from '../../Data';
import Page from '../../Page';
import * as propTypes from '../propTypes';
import { SpanningContentDiv } from './styledComponent';

const display = {
  title: 'Identity Provider',
  configurationHeader: 'Identity Provider Configuration',
  nameLabel: 'Name',
  groupNameLabel: 'Group Name',
  groupNamePartial: 'ibmlh-',
  adminGroupNameLabel: 'Admin Group Name',
  adminGroupName: 'ibmlh-ibmlh-admin',
};

const AttachedIdentityProvider = ({
  loadingInProgress,
  identityProvider,
  loadIdentityProviders,
  prestoClusterSubdomain,
}) => {
  useEffect(() => {
    loadIdentityProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingInProgress || !identityProvider) return null;

  return (
    <Page.Box>
      {identityProvider && (
        <div>
          <Page.BoxHeader>
            <h2>{display.title}</h2>
          </Page.BoxHeader>
          <SpanningContentDiv>
            <div>
              <h3>{display.configurationHeader}</h3>
            </div>
            <div>
              <Data.DataField
                label={display.nameLabel}
                value={identityProvider.name}
              />
              <Data.DataField
                label={display.groupNameLabel}
                value={display.groupNamePartial + prestoClusterSubdomain}
              />
              <Data.DataField
                label={display.adminGroupNameLabel}
                value={display.adminGroupName}
              />
            </div>
          </SpanningContentDiv>
        </div>
      )}
    </Page.Box>
  );
};

AttachedIdentityProvider.defaultProps = {
  identityProvider: null,
};

AttachedIdentityProvider.propTypes = {
  loadingInProgress: bool.isRequired,
  identityProvider: propTypes.IdentityProvider,
  loadIdentityProviders: func.isRequired,
  prestoClusterSubdomain: string.isRequired,
};

export default AttachedIdentityProvider;
