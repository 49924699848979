import { useEffect } from 'react';
import { bool, arrayOf, func } from 'prop-types';
import Page from '../../Page';
import PrestoClustersTable from './PrestoClustersTable';
import Empty from './Empty';
import Loading from './Loading';
import TitleNav from './TitleNav';
import * as propTypes from '../propTypes';
import { NoClustersDiv } from './styledComponents';

const display = {
  header: 'Clusters',
  addLabel: 'Create Cluster',
  activePrestoClusters: 'Active Clusters',
  pendingPrestoClusters: 'Initializing Clusters',
  inactivePrestoClusters: 'Inactive Clusters',
  noActiveClusters: 'No active clusters',
  noPendingClusters: 'No initializing clusters',
  noInactiveClusters: 'No inactive clusters',
};

const navTabs = [
  display.activePrestoClusters,
  display.inactivePrestoClusters,
  display.pendingPrestoClusters,
];

const PrestoClusters = ({
  loadingInProgress,
  activePrestoClusters,
  inactivePrestoClusters,
  pendingPrestoClusters,
  loadPrestoClusters,
  canCreateCluster,
}) => {
  useEffect(() => {
    loadPrestoClusters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    (!activePrestoClusters &&
      !inactivePrestoClusters &&
      !pendingPrestoClusters) ||
    (loadingInProgress &&
      activePrestoClusters.length === 0 &&
      inactivePrestoClusters.length === 0 &&
      pendingPrestoClusters.length === 0);

  const isEmpty =
    !loadingInProgress &&
    activePrestoClusters &&
    inactivePrestoClusters &&
    pendingPrestoClusters &&
    activePrestoClusters.length === 0 &&
    inactivePrestoClusters.length === 0 &&
    pendingPrestoClusters.length === 0;

  if (isLoading) {
    return <Loading />;
  }

  if (isEmpty) {
    return <Empty />;
  }
  return (
    <Page.Page>
      <Page.InPageNavigation navTabs={navTabs}>
        <Page.PageHeaderWithNav>
          <div>
            <TitleNav canCreateCluster={canCreateCluster} />
          </div>
          <Page.PageNavigation navTabs={navTabs} />
        </Page.PageHeaderWithNav>
        <Page.Scroll>
          <Page.BoxNavTarget navTarget={display.activePrestoClusters}>
            <Page.TableBoxHeader>
              <h2>{display.activePrestoClusters}</h2>
            </Page.TableBoxHeader>
            {activePrestoClusters.length > 0 ? (
              <PrestoClustersTable prestoClusters={activePrestoClusters} />
            ) : (
              <NoClustersDiv>{display.noActiveClusters}</NoClustersDiv>
            )}
          </Page.BoxNavTarget>
          <Page.BoxNavTarget navTarget={display.inactivePrestoClusters}>
            <Page.TableBoxHeader>
              <h2>{display.inactivePrestoClusters}</h2>
            </Page.TableBoxHeader>
            {inactivePrestoClusters.length > 0 ? (
              <PrestoClustersTable prestoClusters={inactivePrestoClusters} />
            ) : (
              <NoClustersDiv>{display.noInactiveClusters}</NoClustersDiv>
            )}
          </Page.BoxNavTarget>
          <Page.BoxNavTarget navTarget={display.pendingPrestoClusters}>
            <Page.TableBoxHeader>
              <h2>{display.pendingPrestoClusters}</h2>
            </Page.TableBoxHeader>
            {pendingPrestoClusters.length > 0 ? (
              <PrestoClustersTable prestoClusters={pendingPrestoClusters} />
            ) : (
              <NoClustersDiv>{display.noPendingClusters}</NoClustersDiv>
            )}
          </Page.BoxNavTarget>
        </Page.Scroll>
      </Page.InPageNavigation>
    </Page.Page>
  );
};

PrestoClusters.defaultProps = {
  activePrestoClusters: null,
  inactivePrestoClusters: null,
  pendingPrestoClusters: null,
};

PrestoClusters.propTypes = {
  loadingInProgress: bool.isRequired,
  activePrestoClusters: arrayOf(propTypes.PrestoCluster),
  inactivePrestoClusters: arrayOf(propTypes.PrestoCluster),
  pendingPrestoClusters: arrayOf(propTypes.PrestoCluster),
  loadPrestoClusters: func.isRequired,
  canCreateCluster: bool.isRequired,
};

export default PrestoClusters;
