import { createSelector } from 'reselect';

export const getAuthorizationServiceId = (state, props) => {
  return props.params.authorizationServiceId;
};

export const getAuthorizationService = (state, props) => {
  const authorizationServiceId = getAuthorizationServiceId(state, props);
  if (!state.authorizationServices.authorizationServices) {
    return null;
  }
  const as = state.authorizationServices.authorizationServices.get(
    authorizationServiceId,
  );
  if (as === 'not found') {
    return null;
  }
  return as;
};

export const getActionStatus = (state, props) => {
  const authorizationServiceId = getAuthorizationServiceId(state, props);
  const status = state.authorizationServices.actionStatus.get(
    authorizationServiceId,
  );
  return status;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getIsNotFound = (state, props) => {
  const authorizationServiceId = getAuthorizationServiceId(state, props);
  if (!state.authorizationServices.authorizationServices) {
    return null;
  }
  const as = state.authorizationServices.authorizationServices.get(
    authorizationServiceId,
  );
  if (as === 'not found') {
    return true;
  }
  return false;
};

const getDataSourceIds = (state, props) => {
  if (!state.authorizationServices.authorizationServicesDataSourceIds) {
    return null;
  }

  const authorizationServiceId = getAuthorizationServiceId(state, props);
  const result =
    state.authorizationServices.authorizationServicesDataSourceIds.get(
      authorizationServiceId,
    );

  return result;
};

const getDataSourcesMap = (state) => {
  return state.dataSources.dataSources;
};

const removeNotFound = (authorizationService) => {
  if (authorizationService === 'not found') {
    return null;
  }
  return authorizationService;
};

export const getDataSources = createSelector(
  getDataSourceIds,
  getDataSourcesMap,
  (dataSourceIds, dataSources) => {
    if (!dataSourceIds) {
      return null;
    }

    if (dataSourceIds.length === 0) {
      return [];
    }

    let results = dataSourceIds.map((id) => {
      return removeNotFound(dataSources.get(id));
    });

    results = results.filter((ds) => {
      return !!ds;
    });

    return results;
  },
);

const authorizationServicesSelector = (state) => {
  return state.authorizationServices.authorizationServices;
};

export const getAuthorizationServices = createSelector(
  authorizationServicesSelector,
  (authorizationServices) => {
    if (!authorizationServices) return null;

    let authorizationServicesArray = Array.from(
      authorizationServices.values(),
      removeNotFound,
    );
    authorizationServicesArray = authorizationServicesArray.filter((as) => {
      return as !== null;
    });

    return authorizationServicesArray.sort((a, b) => {
      const serviceComparison = a.type.localeCompare(b.type);
      if (serviceComparison) return serviceComparison;

      return a.name.localeCompare(b.name);
    });
  },
);

const getPrestoClusterIds = (state, props) => {
  if (!state.authorizationServices.authorizationServicesPrestoClusterIds) {
    return null;
  }

  const authorizationServiceId = getAuthorizationServiceId(state, props);
  return state.authorizationServices.authorizationServicesPrestoClusterIds.get(
    authorizationServiceId,
  );
};

const getPrestoClustersMap = (state) => {
  return state.prestoClusters.prestoClusters;
};

export const getPrestoClusters = createSelector(
  getPrestoClusterIds,
  getPrestoClustersMap,
  (prestoClusterIds, prestoClusters) => {
    if (!prestoClusterIds) {
      return null;
    }

    if (prestoClusterIds.length === 0) {
      return [];
    }

    let results = prestoClusterIds.map((id) => {
      return removeNotFound(prestoClusters.get(id));
    });

    results = results.filter((pc) => {
      return !!pc;
    });

    return results;
  },
);
