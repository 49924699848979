import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';

const baseResource = 'authorizationservices';

export const getAll = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: baseResource });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const get = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.authorizationServiceId}`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getDataSources = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.authorizationServiceId}/datasources`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getPrestoClusters = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.authorizationServiceId}/prestoclusters`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const remove = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.authorizationServiceId}`,
  });
  return rayApi.api.executeDELETE(uri, headers, body, abortFetchSignal);
};
