import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool } from 'prop-types';
import {
  redshiftEndpointRegex,
  connectionParametersOrConfigurationPropertiesRegex,
} from '../../../utils/regex';
import Form from '../../Form';
import DataSources from '../../DataSources';
import { required, pattern } from '../utils';
import Help from './RedshiftConnectionDetailsHelp';

const display = {
  boxTitle: 'Access details',
  formContentHeader: 'Connection Configuration',
  formExplaination: 'Secondary explanation',
  // eslint-disable-next-line quotes
  urlFormatError: "JDBC URL must start with 'jdbc:postgresql://'",
  endpointLabel: 'Endpoint',
  endpointRequiredError: 'Endpoint is required',
  endpointPatternError:
    'Endpoint should be in the format: [host]:[port]/[database]',
  connectionParametersLabel: 'Connection Parameters',
  connectionParameterIllegalCharactersError:
    'Connection parameters should be in the format: parameter=value&parameter=value with valid characters',
  userLabel: 'User',
  userRequiredError: 'User is required',
  passwordLabel: 'Password',
  passwordRequiredError: 'Password is required',
};

const type = DataSources.constants.dataSourceTypes.redshift;

const RedshiftConnectionDetails = ({ disabled, active, dataSource }) => {
  const { getValues, clearErrors } = useFormContext();
  const { dataSourceType } = getValues();

  useEffect(() => {
    if (!active) {
      clearErrors('redshift');
    }
  }, [active, clearErrors]);

  return (
    <>
      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.formContentHeader}</h3>
        {/* <div>{display.formExplaination}</div> */}
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.TextInput
          name='redshift.endpoint'
          label={display.endpointLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.metadata.endpoint}
          validationRules={{
            validate: required(
              getValues,
              type,
              display.endpointRequiredError,
              pattern(redshiftEndpointRegex, display.endpointPatternError),
            ),
          }}
          fieldHelp={Help.Endpoint}
        />
        <Form.TextInput
          name='redshift.connectionParameters'
          label={display.connectionParametersLabel}
          disabled={disabled}
          optional
          defaultValue={dataSource && dataSource.metadata.connectionParameters}
          validationRules={{
            pattern:
              dataSourceType === type
                ? {
                    value: connectionParametersOrConfigurationPropertiesRegex,
                    message: display.connectionParameterIllegalCharactersError,
                  }
                : null,
          }}
          fieldHelp={Help.ConnectionParameters}
        />
        <Form.TextInput
          name='redshift.user'
          label={display.userLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.connection['connection-user']}
          validationRules={{
            validate: required(getValues, type, display.userRequiredError),
          }}
          fieldHelp={Help.User}
        />
        <Form.PasswordInput
          name='redshift.password'
          label={display.passwordLabel}
          disabled={disabled}
          validationRules={{
            validate: required(getValues, type, display.passwordRequiredError),
          }}
          fieldHelp={Help.Password}
        />
      </div>
    </>
  );
};

RedshiftConnectionDetails.defaultProps = {
  disabled: false,
  dataSource: null,
};

RedshiftConnectionDetails.propTypes = {
  disabled: bool,
  active: bool.isRequired,
  dataSource: DataSources.propTypes.DataSource,
};

export default RedshiftConnectionDetails;
