import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import ComputePlane from '../ComputePlane';
import * as actionTypes from './actionTypes';
import * as formWizardActionTypes from '../FormWizard/actionTypes';

const initialState = {
  submitInProgress: false,
  destroyInProgress: false,
  hasVerifyRoleSuccess: false,
  verifyRoleInProgress: false,
  verifyRoleError: null,
  error: null,
};

const submitInProgress = (draft) => {
  draft.submitInProgress = true;
};

const destroyInProgress = (draft) => {
  draft.submitInProgress = true;
  draft.destroyInProgress = true;
};

const setSubmitInProgress = (draft, action) => {
  draft.submitInProgress = action.payload.submitInProgress;
};

const onSetActiveStep = (draft) => {
  draft.error = null;
};

const onInitialize = (draft) => {
  draft.error = null;
  draft.verifyRoleError = null;
};

const verifyRoleStart = (draft) => {
  draft.verifyRoleInProgress = true;
  draft.hasVerifyRoleSuccess = false;
  draft.verifyRoleError = null;
};

const verifyRoleSuccess = (draft) => {
  draft.verifyRoleInProgress = false;
  draft.hasVerifyRoleSuccess = true;
  draft.verifyRoleError = null;
};

const verifyRoleFailure = (draft, action) => {
  draft.verifyRoleInProgress = false;
  draft.hasVerifyRoleSuccess = false;
  draft.verifyRoleError = action.payload.apiResult.body.errorMessage;
};

const resetHasVerifyRoleSuccess = (draft) => {
  draft.hasVerifyRoleSuccess = false;
  draft.verifyRoleError = null;
};

const clear = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: clear,
  [auth.actionTypes.signOut]: clear,
  [actionTypes.resetHasVerifyRoleSuccess]: resetHasVerifyRoleSuccess,
  [actionTypes.updateComputePlane]: submitInProgress,
  [actionTypes.requestDeploy]: submitInProgress,
  [actionTypes.requestDestroy]: destroyInProgress,
  [actionTypes.setSubmitInProgress]: setSubmitInProgress,
  [formWizardActionTypes.setActiveStep]: onSetActiveStep,
  [formWizardActionTypes.initialize]: onInitialize,
  [ComputePlane.actionTypes.verifyProvisioningRole.request]: verifyRoleStart,
  [ComputePlane.actionTypes.verifyProvisioningRole.success]: verifyRoleSuccess,
  [ComputePlane.actionTypes.verifyProvisioningRole.failure]: verifyRoleFailure,
};

export default makeReducer(initialState, actionReducers);
