const Alert = () => {
  return (
    <svg viewBox='0 0 54 54' xmlns='http://www.w3.org/2000/svg'>
      <g stroke='none' strokeWidth='1' fillRule='evenodd'>
        <path d='M26.6666667,53.3333333 C11.9386667,53.3333333 2.13162821e-14,41.3946667 2.13162821e-14,26.6666667 C2.13162821e-14,11.9386667 11.9386667,-7.10542736e-15 26.6666667,-7.10542736e-15 C41.3946667,-7.10542736e-15 53.3333333,11.9386667 53.3333333,26.6666667 C53.3333333,41.3946667 41.3946667,53.3333333 26.6666667,53.3333333 Z M24,34.6666667 L24,40 L29.3333333,40 L29.3333333,34.6666667 L24,34.6666667 Z M24,13.3333333 L24,29.3333333 L29.3333333,29.3333333 L29.3333333,13.3333333 L24,13.3333333 Z' />
      </g>
    </svg>
  );
};

export default Alert;
