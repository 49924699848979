import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import * as ComputePlaneActionTypes from '../ComputePlane/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {
  prometheusDeleteInProgress: false,
  prometheusError: null,
  prometheusConfiguration: null,
  computePlaneId: null,
  computePlaneVersion: null,
};

const onNavigation = (draft) => {
  draft.prometheusDeleteInProgress = false;
  draft.prometheusError = null;
};

const get = (draft, action) => {
  const computePlane = action.payload.apiResult.body;
  draft.computePlaneId = action.payload.params.computePlaneId;
  draft.computePlaneVersion = computePlane.computePlaneVersion;
  draft.prometheusConfiguration = computePlane.prometheusConfiguration;
};

const getAll = (draft, action) => {
  const { results } = action.payload.apiResult.body;

  results.sort((a, b) => {
    return new Date(b.createDate) - new Date(a.createDate);
  });

  const computePlane = results[0];
  draft.prometheusConfiguration = computePlane.prometheusConfiguration;
  draft.computePlaneId = computePlane.computePlaneId;
  draft.computePlaneVersion = computePlane.computePlaneVersion;
};

const getAllFailure = (draft) => {
  draft.computePlanes = null;
};

const signOut = () => {
  return { ...initialState };
};

const deleteStart = (draft) => {
  draft.prometheusDeleteInProgress = true;
  draft.prometheusError = null;
};

const deleteSuccess = (draft) => {
  draft.prometheusDeleteInProgress = false;
  draft.prometheusError = null;
};

const deleteFailure = (draft, action) => {
  draft.prometheusDeleteInProgress = false;
  draft.prometheusError = action.payload.apiResult.body.errorMessage;
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [ComputePlaneActionTypes.get.success]: get,
  [ComputePlaneActionTypes.getAll.success]: getAll,
  [ComputePlaneActionTypes.getAll.failure]: getAllFailure,
  [auth.actionTypes.signOut]: signOut,
  [actionTypes.deletePrometheus.request]: deleteStart,
  [actionTypes.deletePrometheus.success]: deleteSuccess,
  [actionTypes.deletePrometheus.failure]: deleteFailure,
};

export default makeReducer(initialState, actionReducers);
