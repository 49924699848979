import { arrayOf } from 'prop-types';
import Page from '../../Page';
import * as propTypes from '../propTypes';
import * as routes from '../routes';
import { useEnableFeature } from '../../utils/featureHooks';
import DataSourcesTable from './DataSourcesTable';
import { NoDataDiv } from './styledComponent';

const display = {
  addLabel: 'Add Data Source',
  dataSourcesLabel: 'Data Sources',
  noDataSourcesLabel: 'There are no data sources yet.',
};

const DataSources = ({ dataSources }) => {
  const enableFeature = useEnableFeature();

  return (
    <>
      <Page.TableBoxHeader>
        <h2>{display.dataSourcesLabel}</h2>
        <div className='buttons'>
          <Page.PrimaryLink
            to={routes.getAddDataSourceRoute()}
            disabled={!enableFeature}
          >
            {display.addLabel}
          </Page.PrimaryLink>
        </div>
      </Page.TableBoxHeader>
      {dataSources && dataSources.length === 0 && (
        <NoDataDiv>{display.noDataSourcesLabel}</NoDataDiv>
      )}
      {dataSources && dataSources.length > 0 && (
        <DataSourcesTable dataSources={dataSources} />
      )}
    </>
  );
};

DataSources.defaultProps = {
  dataSources: null,
};

DataSources.propTypes = {
  dataSources: arrayOf(propTypes.DataSource),
};

export default DataSources;
