const EmptyPrestoClusters = () => {
  return (
    <svg viewBox='0 0 350 250' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h350v250H0z' />
        <g transform='translate(50)'>
          <circle
            fill='#F0E9FF'
            fillRule='nonzero'
            cx='125'
            cy='125'
            r='124.679368'
          />
          <g fillRule='nonzero'>
            <g opacity='0.300000012'>
              <path
                fill='#E7E1F4'
                d='M199.758365 77.89498099l-1.565986-1.80762082h1.565986z'
              />
              <path
                fill='#E6E0F4'
                d='M199.758365 79.90241593l-.585502.50650558-3.731413-4.32156134h2.750929l1.565986 1.80762082z'
              />
              <path
                fill='#E5DFF3'
                d='M199.172863 80.40892151l-1.575279 1.36152416-4.907063-5.6830855h2.750929z'
              />
              <path
                fill='#E4DDF3'
                d='M197.597584 81.77044567l-1.579926 1.36152417-6.082714-7.04460967h2.755577z'
              />
              <path
                fill='#E3DCF2'
                d='M196.017658 83.13196984l-1.575279 1.36152416-7.258364-8.40613383h2.750929z'
              />
              <path
                fill='#E2DBF2'
                d='M194.442379 84.493494l-.297397.25557621h-2.23513l-7.4814131-8.66171004h2.7555761z'
              />
              <path
                fill='#E1DAF2'
                d='M189.158922 84.74907021l-7.4814125-8.66171004h2.7509294l7.4814131 8.66171004z'
              />
              <path
                fill='#E0D8F1'
                d='M186.403346 84.74907021l-7.4767658-8.66171004h2.7509293l7.4814125 8.66171004z'
              />
              <path
                fill='#DFD7F1'
                d='M183.6524166 84.74907021l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#DED6F0'
                d='M180.8968404 84.74907021l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#DDD4F0'
                d='M178.145911 84.74907021l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#DCD3EF'
                d='M175.3949817 84.74907021l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#DBD1EF'
                d='M172.6394055 84.74907021l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#DAD0EE'
                d='M169.8884761 84.74907021l-7.4814127-8.66171004h2.7509294l7.4814127 8.66171004z'
              />
              <path
                fill='#D8CEEE'
                d='M167.1328999 84.74907021l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#D7CDED'
                d='M164.3819705 84.74907021l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
              />
              <path
                fill='#D6CBEC'
                d='M161.6263943 84.74907021l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#D5CAEC'
                d='M158.8754649 84.74907021l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#D3C8EB'
                d='M156.1245356 84.74907021l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#D2C6EB'
                d='M153.3689594 84.74907021l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#D1C5EA'
                d='M150.61803 84.74907021l-7.4814127-8.66171004h2.7509294l7.4814127 8.66171004z'
              />
              <path
                fill='#CFC3EA'
                d='M147.8624538 84.74907021l-7.4767658-8.66171004h2.7509293l7.4814127 8.66171004z'
              />
              <path
                fill='#CEC1E9'
                d='M145.1115244 84.74907021l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#CDC0E8'
                d='M142.360595 84.74907021l-7.4814126-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#CBBEE8'
                d='M139.6050188 84.74907021l-7.4814126-8.66171004h2.7555762l7.4814126 8.66171004z'
              />
              <path
                fill='#CABCE7'
                d='M136.8540895 84.74907021l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#C9BAE6'
                d='M134.0985133 84.74907021l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#C7B9E6'
                d='M131.3475839 84.74907021l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
              />
              <path
                fill='#C6B7E5'
                d='M128.5920077 84.74907021l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#C4B5E4'
                d='M125.8410783 84.74907021l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#C3B3E4'
                d='M123.0901489 84.74907021l-7.4814126-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#C1B1E3'
                d='M120.3345727 84.74907021l-7.4814126-8.66171004h2.7555762l7.4814126 8.66171004z'
              />
              <path
                fill='#C0AFE2'
                d='M117.5836434 84.74907021l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#BEADE2'
                d='M114.8280672 84.74907021l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#BDABE1'
                d='M112.0771378 84.74907021l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
              />
              <path
                fill='#BBA9E0'
                d='M109.3215616 84.74907021l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#B9A7DF'
                d='M106.5706322 84.74907021l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#B8A5DF'
                d='M103.8197029 84.74907021l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#B6A3DE'
                d='M101.0641266 84.74907021l-7.00278806-8.1133829.6366171-.54832714h1.64033456l7.4814127 8.66171004z'
              />
              <path
                fill='#B6A3DE'
                d='M86.25464709 83.37825236l7.80669145-6.74256505 7.00278806 8.1133829H86.25464709z'
              />
            </g>
            <g transform='translate(97.876394 38.684944)'>
              <rect
                fill='#C3A8FF'
                transform='rotate(180 50.494888 13.394517)'
                x='7.30947955'
                y='-1.59872116e-14'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#FFF'
                transform='rotate(180 43.185409 13.394517)'
                x='1.49213975e-13'
                y='-1.59872116e-14'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#F0E9FF'
                transform='rotate(-180 48.38987 12.915892)'
                x='18.7453532'
                y='9.24256506'
                width='59.2890335'
                height='7.34665428'
                rx='3.67332714'
              />
              <rect
                fill='#FF8000'
                x='6.32899628'
                y='9.72118959'
                width='7.34665428'
                height='7.34665428'
                rx='3.67332714'
              />
            </g>
            <g transform='translate(97.876394 7.718401)'>
              <rect
                fill='#C3A8FF'
                transform='rotate(180 50.494888 13.394517)'
                x='7.30947955'
                y='1.77635684e-14'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#FFF'
                transform='rotate(180 43.185409 13.394517)'
                x='1.49213975e-13'
                y='1.77635684e-14'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#F0E9FF'
                transform='rotate(-180 48.38987 13.38987)'
                x='18.7453532'
                y='9.71654275'
                width='59.2890335'
                height='7.34665428'
                rx='3.67332714'
              />
              <rect
                fill='#FFA64C'
                x='6.32899628'
                y='9.66078067'
                width='7.34665428'
                height='7.34665428'
                rx='3.67332714'
              />
            </g>
          </g>
          <g transform='translate(13.878253 72.806691)'>
            <g fillRule='nonzero'>
              <g opacity='0.300000012'>
                <path
                  fill='#E7E1F4'
                  d='M114.634759 99.59107806l-1.561339-1.80762082h1.561339z'
                />
                <path
                  fill='#E6E0F4'
                  d='M114.634759 101.598513l-.580855.50650558-3.731413-4.32156134h2.750929l1.561339 1.80762082z'
                />
                <path
                  fill='#E5DFF3'
                  d='M114.053904 102.10501858l-1.579926 1.36152416-4.907063-5.6830855h2.755576z'
                />
                <path
                  fill='#E4DDF3'
                  d='M112.473978 103.46654274l-1.575279 1.36152417-6.082714-7.04460967h2.75093z'
                />
                <path
                  fill='#E3DCF2'
                  d='M110.898699 104.82806691l-1.575279 1.36152416-7.263011-8.40613383h2.755576z'
                />
                <path
                  fill='#E2DBF2'
                  d='M109.32342 106.18959107l-.297397.25557621h-2.23513l-7.4814131-8.66171004h2.7509291z'
                />
                <path
                  fill='#E1DAF2'
                  d='M104.035316 106.44516728l-7.4767655-8.66171004h2.7509294l7.4814131 8.66171004z'
                />
                <path
                  fill='#E0D8F1'
                  d='M101.284387 106.44516728l-7.4814127-8.66171004h2.7555762l7.4767655 8.66171004z'
                />
                <path
                  fill='#DFD7F1'
                  d='M98.5288107 106.44516728l-7.4767658-8.66171004h2.7509294l7.4814127 8.66171004z'
                />
                <path
                  fill='#DED6F0'
                  d='M95.7778814 106.44516728l-7.4814127-8.66171004h2.7555762l7.4767658 8.66171004z'
                />
                <path
                  fill='#DDD4F0'
                  d='M93.026952 106.44516728l-7.4814127-8.66171004h2.7509294l7.4814127 8.66171004z'
                />
                <path
                  fill='#DCD3EF'
                  d='M90.2713758 106.44516728L82.79461 97.78345724h2.7509293l7.4814127 8.66171004z'
                />
                <path
                  fill='#DBD1EF'
                  d='M87.5204464 106.44516728l-7.4814126-8.66171004H82.79461l7.4767658 8.66171004z'
                />
                <path
                  fill='#DAD0EE'
                  d='M84.7648702 106.44516728l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#D8CEEE'
                  d='M82.0139408 106.44516728l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
                />
                <path
                  fill='#D7CDED'
                  d='M79.2630115 106.44516728l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#D6CBEC'
                  d='M76.5074353 106.44516728l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
                />
                <path
                  fill='#D5CAEC'
                  d='M73.7565059 106.44516728l-7.4814127-8.66171004h2.7509294l7.4814127 8.66171004z'
                />
                <path
                  fill='#D3C8EB'
                  d='M71.0009297 106.44516728l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
                />
                <path
                  fill='#D2C6EB'
                  d='M68.2500003 106.44516728l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
                />
                <path
                  fill='#D1C5EA'
                  d='M65.4944241 106.44516728l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#CFC3EA'
                  d='M62.7434947 106.44516728l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
                />
                <path
                  fill='#CEC1E9'
                  d='M59.9925654 106.44516728l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#CDC0E8'
                  d='M57.2369892 106.44516728l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
                />
                <path
                  fill='#CBBEE8'
                  d='M54.4860598 106.44516728l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
                />
                <path
                  fill='#CABCE7'
                  d='M51.7304836 106.44516728l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#C9BAE6'
                  d='M48.9795542 106.44516728l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
                />
                <path
                  fill='#C7B9E6'
                  d='M46.223978 106.44516728l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#C6B7E5'
                  d='M43.4730486 106.44516728l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
                />
                <path
                  fill='#C4B5E4'
                  d='M40.7221193 106.44516728l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#C3B3E4'
                  d='M37.9665431 106.44516728l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
                />
                <path
                  fill='#C1B1E3'
                  d='M35.2156137 106.44516728l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
                />
                <path
                  fill='#C0AFE2'
                  d='M32.4600375 106.44516728l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#BEADE2'
                  d='M29.7091081 106.44516728l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
                />
                <path
                  fill='#BDABE1'
                  d='M26.9581788 106.44516728l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#BBA9E0'
                  d='M24.2026025 106.44516728l-7.4814126-8.66171004h2.7555762l7.4814127 8.66171004z'
                />
                <path
                  fill='#B9A7DF'
                  d='M21.4516732 106.44516728l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
                />
                <path
                  fill='#B8A5DF'
                  d='M18.696097 106.44516728l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
                />
                <path
                  fill='#B6A3DE'
                  d='M15.9451676 106.44516728l-7.00743495-8.1133829.64126394-.54832714h1.63568771l7.4814127 8.66171004z'
                />
                <path
                  fill='#B6A3DE'
                  d='M1.13568804 105.06970259l7.80204461-6.73791821 7.00743495 8.1133829H1.13568805z'
                />
              </g>
              <g transform='translate(7)'>
                <rect
                  fill='#6826FF'
                  transform='rotate(180 50.485595 13.394517)'
                  x='7.30018587'
                  y='-1.77635684e-15'
                  width='86.3708178'
                  height='26.7890335'
                  rx='6'
                />
                <rect
                  fill='#B392FF'
                  transform='rotate(180 43.185409 13.394517)'
                  x='1.42108547e-13'
                  y='-1.77635684e-15'
                  width='86.3708178'
                  height='26.7890335'
                  rx='6'
                />
                <rect
                  fill='#C3A8FF'
                  transform='rotate(180 48.38987 13.38987)'
                  x='18.7453532'
                  y='9.71654275'
                  width='59.2890335'
                  height='7.34665428'
                  rx='3.67332714'
                />
                <rect
                  fill='#FFF'
                  x='6.32434944'
                  y='9.72118959'
                  width='7.34665428'
                  height='7.34665428'
                  rx='3.67332714'
                />
              </g>
              <g transform='translate(7 30.966543)'>
                <rect
                  fill='#6826FF'
                  transform='rotate(180 50.485595 13.394517)'
                  x='7.30018587'
                  y='-7.10542736e-15'
                  width='86.3708178'
                  height='26.7890335'
                  rx='6'
                />
                <rect
                  fill='#B392FF'
                  transform='rotate(180 43.185409 13.394517)'
                  x='1.42108547e-13'
                  y='-7.10542736e-15'
                  width='86.3708178'
                  height='26.7890335'
                  rx='6'
                />
                <rect
                  fill='#FF8000'
                  transform='rotate(-180 48.38987 13.38987)'
                  x='18.7453532'
                  y='9.71654275'
                  width='59.2890335'
                  height='7.34665428'
                  rx='3.67332714'
                />
                <rect
                  fill='#FF8000'
                  x='6.32434944'
                  y='9.72118959'
                  width='7.34665428'
                  height='7.34665428'
                  rx='3.67332714'
                />
              </g>
              <g transform='translate(7 61.928439)'>
                <rect
                  fill='#6826FF'
                  transform='rotate(180 50.485595 13.394517)'
                  x='7.30018587'
                  y='1.24344979e-14'
                  width='86.3708178'
                  height='26.7890335'
                  rx='6'
                />
                <rect
                  fill='#B392FF'
                  transform='rotate(180 43.185409 13.394517)'
                  x='1.42108547e-13'
                  y='1.24344979e-14'
                  width='86.3708178'
                  height='26.7890335'
                  rx='6'
                />
                <rect
                  fill='#C3A8FF'
                  transform='rotate(-180 48.38987 13.394517)'
                  x='18.7453532'
                  y='9.72118959'
                  width='59.2890335'
                  height='7.34665428'
                  rx='3.67332714'
                />
                <rect
                  fill='#FFF'
                  x='7.30483271'
                  y='9.72118959'
                  width='7.34665428'
                  height='7.34665428'
                  rx='3.67332714'
                />
              </g>
            </g>
            <path
              d='M100.671004 20.0650558h39.665427c1.25828 0 2.279036 1.0186747 2.281599 2.2769516v43.6291822c0 .6063082-.241794 1.1875825-.671825 1.6149968-.430031.4274143-1.012771.665667-1.619067.6621591l-20.153346-.1163752'
              stroke='#FFF'
              strokeWidth='2'
            />
            <rect
              fill='#FF8000'
              fillRule='nonzero'
              transform='rotate(180 141.500465 20.062732)'
              x='137.827138'
              y='16.3894052'
              width='7.34665428'
              height='7.34665428'
              rx='3.67332714'
            />
          </g>
          <g fillRule='nonzero'>
            <g opacity='0.3'>
              <path
                fill='#E7E1F4'
                d='M230.362454 238.25278755l-1.561338-1.80762082h1.561338z'
              />
              <path
                fill='#E6E0F4'
                d='M230.362454 240.26022249l-.585502.50650558-3.731413-4.32156134h2.755577l1.561338 1.80762082z'
              />
              <path
                fill='#E5DFF3'
                d='M229.776952 240.76672807l-1.575279 1.36152416-4.907063-5.6830855h2.750929z'
              />
              <path
                fill='#E4DDF3'
                d='M228.201673 242.12825223l-1.579926 1.36152417-6.082713-7.04460967h2.755576z'
              />
              <path
                fill='#E3DCF2'
                d='M226.621747 243.4897764l-1.575278 1.36152416-7.258365-8.40613383h2.75093z'
              />
              <path
                fill='#E2DBF2'
                d='M225.046469 244.85130056l-.297398.25557621h-2.23513l-7.4814129-8.66171004h2.7555759z'
              />
              <path
                fill='#E1DAF2'
                d='M219.763011 245.10687677l-7.4814122-8.66171004h2.7509293l7.4814129 8.66171004z'
              />
              <path
                fill='#E0D8F1'
                d='M217.007435 245.10687677l-7.4767656-8.66171004h2.7509294l7.4814122 8.66171004z'
              />
              <path
                fill='#DFD7F1'
                d='M214.2565058 245.10687677l-7.4814126-8.66171004h2.7555762l7.4767656 8.66171004z'
              />
              <path
                fill='#DED6F0'
                d='M211.5009296 245.10687677l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#DDD4F0'
                d='M208.7500003 245.10687677l-7.4814127-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#DCD3EF'
                d='M205.9990709 245.10687677l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
              />
              <path
                fill='#DBD1EF'
                d='M203.2434947 245.10687677l-7.4814126-8.66171004h2.7555762l7.4814126 8.66171004z'
              />
              <path
                fill='#DAD0EE'
                d='M200.4925653 245.10687677l-7.4814126-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#D8CEEE'
                d='M197.7369891 245.10687677l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#D7CDED'
                d='M194.9860597 245.10687677l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#D6CBEC'
                d='M192.2351304 245.10687677l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#D5CAEC'
                d='M189.4795542 245.10687677l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#D3C8EB'
                d='M186.7286248 245.10687677l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
              />
              <path
                fill='#D2C6EB'
                d='M183.9730486 245.10687677l-7.4814126-8.66171004h2.7555762l7.4814126 8.66171004z'
              />
              <path
                fill='#D1C5EA'
                d='M181.2221192 245.10687677l-7.4814126-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#CFC3EA'
                d='M178.466543 245.10687677l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#CEC1E9'
                d='M175.7156137 245.10687677l-7.4814127-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#CDC0E8'
                d='M172.9646843 245.10687677l-7.4814127-8.66171004h2.7509294l7.4814127 8.66171004z'
              />
              <path
                fill='#CBBEE8'
                d='M170.2091081 245.10687677l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#CABCE7'
                d='M167.4581787 245.10687677l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
              />
              <path
                fill='#C9BAE6'
                d='M164.7026025 245.10687677l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#C7B9E6'
                d='M161.9516731 245.10687677l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#C6B7E5'
                d='M159.2007438 245.10687677l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#C4B5E4'
                d='M156.4451676 245.10687677l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#C3B3E4'
                d='M153.6942382 245.10687677l-7.4814127-8.66171004h2.7509294l7.4814127 8.66171004z'
              />
              <path
                fill='#C1B1E3'
                d='M150.938662 245.10687677l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#C0AFE2'
                d='M148.1877326 245.10687677l-7.4814126-8.66171004h2.7509293l7.4814127 8.66171004z'
              />
              <path
                fill='#BEADE2'
                d='M145.4321564 245.10687677l-7.4767658-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#BDABE1'
                d='M142.681227 245.10687677l-7.4814126-8.66171004h2.7555762l7.4767658 8.66171004z'
              />
              <path
                fill='#BBA9E0'
                d='M139.9302977 245.10687677l-7.4814127-8.66171004h2.7509294l7.4814126 8.66171004z'
              />
              <path
                fill='#B9A7DF'
                d='M137.1747215 245.10687677l-7.4814127-8.66171004h2.7555762l7.4814127 8.66171004z'
              />
              <path
                fill='#B8A5DF'
                d='M134.4237921 245.10687677l-7.4814127-8.66171004h2.7509294l7.4814127 8.66171004z'
              />
              <path
                fill='#B6A3DE'
                d='M131.6682159 245.10687677l-7.00278812-8.1133829.6366171-.54832714h1.64033452l7.4814127 8.66171004z'
              />
              <path
                fill='#B6A3DE'
                d='M116.85873633 243.73141209l7.80669145-6.73791822 7.00278812 8.1133829h-14.80947957z'
              />
            </g>
            <g transform='translate(120.678439 134.879182)'>
              <rect
                fill='#9567FF'
                transform='rotate(180 50.494888 13.394517)'
                x='7.30947955'
                y='-1.77635684e-15'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#D2BEFF'
                transform='rotate(180 43.185409 13.394517)'
                x='1.42108547e-13'
                y='-1.77635684e-15'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#E1D4FF'
                transform='rotate(-180 48.38987 12.822955)'
                x='18.7453532'
                y='9.14962825'
                width='59.2890335'
                height='7.34665428'
                rx='3.67332714'
              />
              <rect
                fill='#FFF'
                x='6.32899628'
                y='9.72583643'
                width='7.34665428'
                height='7.34665428'
                rx='3.67332714'
              />
            </g>
            <g transform='translate(120.678439 165.850372)'>
              <rect
                fill='#9567FF'
                transform='rotate(180 50.494888 13.394517)'
                x='7.30947955'
                y='-1.77635684e-15'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#D2BEFF'
                transform='rotate(180 43.185409 13.394517)'
                x='1.42108547e-13'
                y='-1.77635684e-15'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#E1D4FF'
                transform='rotate(-180 48.38987 13.38987)'
                x='18.7453532'
                y='9.71654275'
                width='59.2890335'
                height='7.34665428'
                rx='3.67332714'
              />
              <rect
                fill='#FFF'
                x='6.32899628'
                y='9.72118959'
                width='7.34665428'
                height='7.34665428'
                rx='3.67332714'
              />
            </g>
            <g transform='translate(120.678439 196.816914)'>
              <rect
                fill='#9567FF'
                transform='rotate(180 50.494888 13.394517)'
                x='7.30947955'
                y='1.11910481e-13'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#D2BEFF'
                transform='rotate(180 43.185409 13.394517)'
                x='1.42108547e-13'
                y='-1.77635684e-15'
                width='86.3708178'
                height='26.7890335'
                rx='6'
              />
              <rect
                fill='#FFF'
                x='6.32899628'
                y='9.72118959'
                width='7.34665428'
                height='7.34665428'
                rx='3.67332714'
              />
              <rect
                fill='#1950FF'
                transform='rotate(180 48.38987 13.38987)'
                x='18.7453532'
                y='9.71654275'
                width='59.2890335'
                height='7.34665428'
                rx='3.67332714'
              />
            </g>
          </g>
          <path
            d='M95.26022292 148.8847582V208.14684c.00118744.7048803.28427417 1.3800004.78617981 1.874923.50190563.4949226 1.18092552.7685233 1.88575327.7600361l33.0762082-.4648848'
            stroke='#FFF'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <g transform='translate(91.565985 182.332714)' fillRule='nonzero'>
            <rect
              fill='#FFF'
              x='-4.33431069e-13'
              y='16.0827138'
              width='7.34665428'
              height='7.34665428'
              rx='3.67332714'
            />
            <rect
              fill='#CAB3FF'
              x='1.8169145'
              y='7.36524164'
              width='3.70817844'
              height='3.70817844'
              rx='1.85408922'
            />
            <rect
              fill='#CAB3FF'
              x='2.49070632'
              y='1.42108547e-14'
              width='2.3605948'
              height='2.3605948'
              rx='1.1802974'
            />
          </g>
          <g transform='translate(28.717472 18.276022)'>
            <path
              d='M1.85408922 64.2518587V6.88197026c0-1.07343663.42642104-2.10290809 1.18545536-2.86194241s1.78850578-1.18545536 2.86194241-1.18545536H69.1589219'
              stroke='#D2BEFF'
              strokeWidth='2'
            />
            <rect
              fill='#773CFF'
              fillRule='nonzero'
              x='0.673791822'
              y='30.9665428'
              width='2.3605948'
              height='2.3605948'
              rx='1.1802974'
            />
            <rect
              fill='#1950FF'
              fillRule='nonzero'
              x='0.673791822'
              y='38.3085502'
              width='2.3605948'
              height='2.3605948'
              rx='1.1802974'
            />
            <rect
              fill='#1950FF'
              fillRule='nonzero'
              x='-3.66151554e-13'
              y='46.7379182'
              width='3.70817844'
              height='3.70817844'
              rx='1.85408922'
            />
            <rect
              fill='#FFA64C'
              fillRule='nonzero'
              x='43.9265799'
              width='5.5436803'
              height='5.5436803'
              rx='2.77184015'
            />
          </g>
          <g transform='translate(145.738848 57.527881)'>
            <path
              d='M.17193309 0l.10223048 8.22026022C.28439754 10.568934 2.19126672 12.4674944 4.53996283 12.4674721H31.9563197c3.2112926-.000001 5.8152789 2.6019055 5.8178439 5.813197v58.9498142'
              stroke='#FFF'
              strokeWidth='2'
            />
            <g transform='translate(35 33.387546)'>
              <rect
                fill='#CAB3FF'
                fillRule='nonzero'
                x='30.1347584'
                y='0.920074349'
                width='3.70817844'
                height='3.70817844'
                rx='1.85408922'
              />
              <path
                stroke='#FFF'
                strokeWidth='2'
                d='M2.77416357 2.77416357H20.9247212'
              />
              <rect
                fill='#773CFF'
                fillRule='nonzero'
                x='42.0864312'
                y='1.59386617'
                width='2.3605948'
                height='2.3605948'
                rx='1.1802974'
              />
              <rect
                fill='#FFA64C'
                fillRule='nonzero'
                x='1.98951966e-13'
                width='5.5436803'
                height='5.5436803'
                rx='2.77184015'
              />
              <rect
                fill='#FFF'
                fillRule='nonzero'
                x='18.1505576'
                width='5.5436803'
                height='5.5436803'
                rx='2.77184015'
              />
            </g>
            <g transform='translate(35 43.355019)'>
              <path
                stroke='#FFF'
                strokeWidth='2'
                d='M2.77416357 2.76951673H20.9247212'
              />
              <rect
                fill='#FFA64C'
                fillRule='nonzero'
                x='1.98951966e-13'
                width='5.5436803'
                height='5.5436803'
                rx='2.77184015'
              />
              <rect
                fill='#CAB3FF'
                fillRule='nonzero'
                x='30.1347584'
                y='0.915427509'
                width='3.70817844'
                height='3.70817844'
                rx='1.85408922'
              />
              <rect
                fill='#773CFF'
                fillRule='nonzero'
                x='42.0864312'
                y='1.59386617'
                width='2.3605948'
                height='2.3605948'
                rx='1.1802974'
              />
              <rect
                fill='#FFF'
                fillRule='nonzero'
                x='18.1505576'
                width='5.5436803'
                height='5.5436803'
                rx='2.77184015'
              />
            </g>
            <g transform='translate(35 53.317844)'>
              <path
                stroke='#FFF'
                strokeWidth='2'
                d='M2.77416357 2.77416357H20.9247212'
              />
              <rect
                fill='#FFA64C'
                fillRule='nonzero'
                x='1.98951966e-13'
                y='7.10542736e-15'
                width='5.5436803'
                height='5.5436803'
                rx='2.77184015'
              />
              <rect
                fill='#CAB3FF'
                fillRule='nonzero'
                x='30.1347584'
                y='0.920074349'
                width='3.70817844'
                height='3.70817844'
                rx='1.85408922'
              />
              <rect
                fill='#773CFF'
                fillRule='nonzero'
                x='42.0864312'
                y='1.59386617'
                width='2.3605948'
                height='2.3605948'
                rx='1.1802974'
              />
              <rect
                fill='#FFF'
                fillRule='nonzero'
                x='18.1505576'
                y='7.10542736e-15'
                width='5.5436803'
                height='5.5436803'
                rx='2.77184015'
              />
            </g>
            <rect
              fill='#CAB3FF'
              fillRule='nonzero'
              x='36.5938662'
              y='25'
              width='2.3605948'
              height='2.3605948'
              rx='1.1802974'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmptyPrestoClusters;
