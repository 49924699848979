import { useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { unstable_usePrompt as usePrompt, useNavigate } from 'react-router-dom';
import DataSources from '../../DataSources';
import PrestoClusters from '../../PrestoClusters';
import PrestoUsers from '../../PrestoUsers';
import Page from '../../Page';
import useRouteBuilder from '../../../router/useRouteBuilder';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import Clusters from './Clusters';
import DataSourcesList from './DataSources';
import Details from './Details';
import DangerZone from './DangerZone';

const display = {
  clusters: 'Clusters',
  details: 'Details',
  dangerZone: 'Danger Zone',
};

const navTabs = [display.clusters, display.details, display.dangerZone];

const PrestoUser = ({
  prestoUserId,
  prestoUser,
  notFound,
  actionStatus,
  prestoClusters,
  dataSources,
  usePop,
  canModify,
  canDelete,
  alertBeforeDelete,
  loadPrestoUser,
  deletePrestoUser,
  loadPrestoClusters,
  loadDataSources,
  loadDataSourcePrestoClusters,
}) => {
  useEffect(() => {
    loadPrestoUser(prestoUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoUserId]);

  const [actionInProgress, setActionInProgress] = useState(false);

  const showPrompt = useCallback(() => {
    return actionInProgress;
  }, [actionInProgress]);

  usePrompt({ when: showPrompt, message: display.confirmCancel });

  const navigate = useNavigate();
  const prestoUsersRoute = useRouteBuilder(PrestoUsers.routes.getRoute);
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.success &&
      actionStatus.name === 'delete'
    ) {
      // nav back
      if (usePop) {
        navigate(-1);
      } else {
        navigate(prestoUsersRoute, { replace: true });
      }
    }
  }, [actionStatus, navigate, usePop, prestoUsersRoute]);

  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!prestoUser || notFound) {
    return <Page.Loading />;
  }
  return (
    <ActionInProgressStateContext.Provider value={actionInProgress}>
      <ActionInProgressSetStateContext.Provider value={setActionInProgress}>
        <Page.Page>
          <Page.InPageNavigation navTabs={navTabs}>
            <Page.PageHeaderWithNav>
              <div>
                <h1>{prestoUser.username}</h1>
              </div>
              <Page.PageNavigation navTabs={navTabs} />
            </Page.PageHeaderWithNav>
            <Page.Scroll>
              <Clusters
                navTarget={display.clusters}
                prestoClusters={prestoClusters}
                loadPrestoClusters={loadPrestoClusters}
                prestoUserId={prestoUserId}
                prestoClusterCount={prestoUser.prestoClusterCount}
              />
              <DataSourcesList
                prestoUserId={prestoUserId}
                loadDataSources={loadDataSources}
                loadDataSourcePrestoClusters={loadDataSourcePrestoClusters}
                dataSources={dataSources}
                prestoUser={prestoUser}
              />
              <Details
                navTarget={display.details}
                prestoUserId={prestoUserId}
                username={prestoUser.username}
                password={prestoUser.password}
                canModify={canModify}
              />
              <DangerZone
                navTarget={display.dangerZone}
                prestoUserId={prestoUserId}
                deletePrestoUser={deletePrestoUser}
                actionStatus={actionStatus}
                usePop={usePop}
                canDelete={canDelete}
                alertBeforeDelete={alertBeforeDelete}
              />
            </Page.Scroll>
          </Page.InPageNavigation>
        </Page.Page>
      </ActionInProgressSetStateContext.Provider>
    </ActionInProgressStateContext.Provider>
  );
};

PrestoUser.defaultProps = {
  prestoUser: null,
  notFound: false,
  actionStatus: undefined,
  prestoClusters: [],
  dataSources: null,
  usePop: true,
  canModify: true,
  canDelete: true,
  alertBeforeDelete: true,
};

PrestoUser.propTypes = {
  loadPrestoUser: func.isRequired,
  prestoUserId: string.isRequired,
  prestoUser: PrestoUsers.propTypes.PrestoUser,
  notFound: bool,
  deletePrestoUser: func.isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  loadPrestoClusters: func.isRequired,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  loadDataSources: func.isRequired,
  loadDataSourcePrestoClusters: func.isRequired,
  dataSources: arrayOf(DataSources.propTypes.DataSource),
  usePop: bool,
  canModify: bool,
  canDelete: bool,
  alertBeforeDelete: bool,
};

export default PrestoUser;
