import Help from '../../Help';

const Name = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Name</Help.HelpHeader>
      <Help.P>
        Pick a name for the data source. This is unique across the IBM
        watsonx.data compute plane. We recommend a descriptive name to help you
        identify the data source. It will also be used as the name of the
        catalog when attached to a Presto cluster.
      </Help.P>
      <Help.P>
        Due to Presto limitations, this value can only contain lowercase
        letters, numbers and underscores.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  Name,
};

export default module;
