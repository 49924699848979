import { string } from 'prop-types';
import { SuccessBadge, ErrorBadge } from '../../Badge';

const Enabled = (statusLabel) => {
  return <SuccessBadge>{statusLabel}</SuccessBadge>;
};

const Disabled = (statusLabel) => {
  return <ErrorBadge>{statusLabel}</ErrorBadge>;
};

const display = {
  enabled: 'ENABLED',
  disabled: 'DISABLED',
};
const IsEnabledBadge = ({ enabled }) => {
  return enabled ? Enabled(display.enabled) : Disabled(display.disabled);
};

IsEnabledBadge.defaultProps = {
  enabled: null,
};

IsEnabledBadge.propTypes = {
  enabled: string,
};

export default IsEnabledBadge;
