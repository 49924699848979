export const getManageIdentityProviderRoute = (authorizationServiceId) => {
  return `/identityproviders/${authorizationServiceId}`;
};

export const getAddIdentityProviderRoute = () => {
  return '/identityproviders/add';
};

export const getUpdateIdentityProviderRoute = (identityProviderId) => {
  return `/identityproviders/${identityProviderId}/update`;
};
