import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { arrayOf, bool, string, shape } from 'prop-types';
import Banner from '../../Banner';
import Page from '../../Page';
import DataSources from '../../DataSources';
import PrestoUsers from '../../PrestoUsers';
import AuthorizationServices from '../../AuthorizationServices';
import ElasticsearchConnectionDetails from './ElasticsearchConnectionDetails';
import GlueConnectionDetails from './GlueConnectionDetails';
import HiveConnectionDetails from './HiveConnectionDetails';
import MySqlConnectionDetails from './MySqlConnectionDetails';
import PostgresConnectionDetails from './PostgresConnectionDetails';
import IBMDb2ConnectionDetails from './IBMDb2ConnectionDetails';
import RedshiftConnectionDetails from './RedshiftConnectionDetails';

const display = {
  boxTitle: 'Access details',
};

const ConnectionDetails = ({
  disabled,
  error,
  awsRegions,
  computePlaneRegion,
  awsGlueS3CloudFormationQuickCreateUrl,
  awsLakeFormationCloudFormationQuickCreateUrl,
  prestoUsers,
  loadingPrestoUsers,
  authorizationServices,
}) => {
  const { watch } = useFormContext();
  const dataSourceType = watch(
    'dataSourceType',
    DataSources.constants.dataSourceTypes.hive,
  );

  const authorizationServicesDisplay = useMemo(() => {
    if (!authorizationServices || authorizationServices.length === 0) {
      return null;
    }

    return authorizationServices.reduce(
      (acc, as) => {
        acc.push({
          display: as.name,
          value: as.authorizationServiceId,
        });
        return acc;
      },
      [{ display: 'No Authorization Service', value: '' }],
    );
  }, [authorizationServices]);

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && error.type === dataSourceType && (
        <Banner title={error.error} scrollIntoView />
      )}
      <Page.FormContent>
        <ElasticsearchConnectionDetails
          disabled={disabled}
          active={
            dataSourceType ===
            DataSources.constants.dataSourceTypes.elasticsearch
          }
          awsRegions={awsRegions}
        />
        <GlueConnectionDetails
          disabled={disabled}
          active={dataSourceType === DataSources.constants.dataSourceTypes.glue}
          awsRegions={awsRegions}
          computePlaneRegion={computePlaneRegion}
          awsGlueS3CloudFormationQuickCreateUrl={
            awsGlueS3CloudFormationQuickCreateUrl
          }
          awsLakeFormationCloudFormationQuickCreateUrl={
            awsLakeFormationCloudFormationQuickCreateUrl
          }
          prestoUsers={prestoUsers}
          loadingPrestoUsers={loadingPrestoUsers}
          authorizationServices={authorizationServicesDisplay}
        />
        <HiveConnectionDetails
          disabled={disabled}
          active={dataSourceType === DataSources.constants.dataSourceTypes.hive}
          authorizationServices={authorizationServicesDisplay}
        />
        <MySqlConnectionDetails
          disabled={disabled}
          active={
            dataSourceType === DataSources.constants.dataSourceTypes.mysql
          }
        />
        <PostgresConnectionDetails
          disabled={disabled}
          active={
            dataSourceType === DataSources.constants.dataSourceTypes.postgresql
          }
        />
        <IBMDb2ConnectionDetails
          disabled={disabled}
          active={dataSourceType === DataSources.constants.dataSourceTypes.db2}
        />
        <RedshiftConnectionDetails
          disabled={disabled}
          active={
            dataSourceType === DataSources.constants.dataSourceTypes.redshift
          }
        />
      </Page.FormContent>
    </Page.Box>
  );
};

ConnectionDetails.defaultProps = {
  disabled: false,
  error: null,
  awsGlueS3CloudFormationQuickCreateUrl: null,
  awsLakeFormationCloudFormationQuickCreateUrl: null,
  prestoUsers: null,
  authorizationServices: null,
};

ConnectionDetails.propTypes = {
  disabled: bool,
  error: shape({
    type: string,
    error: string,
  }),
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  computePlaneRegion: string.isRequired,
  awsGlueS3CloudFormationQuickCreateUrl: string,
  awsLakeFormationCloudFormationQuickCreateUrl: string,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadingPrestoUsers: bool.isRequired,
  authorizationServices: arrayOf(
    AuthorizationServices.propTypes.AuthorizationService,
  ),
};

export default ConnectionDetails;
