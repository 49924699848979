import Help from '../../Help';

const Section = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Redshift</Help.HelpHeader>
      <Help.P>something</Help.P>
    </Help.HelpSection>
  );
};

const Endpoint = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Endpoint</Help.HelpHeader>
      <Help.P>
        The Endpoint to the Redshift server (e.g.
        redshift-cluster-1.clngqtvnco8w.eu-west-2.redshift.amazonaws.com:5439/dev).
        This Endpoint needs to be accessible from the VPC where the Presto
        clusters are running.
      </Help.P>
    </Help.HelpSection>
  );
};

const ConnectionParameters = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Connection Parameters</Help.HelpHeader>
      <Help.P>
        Additional properties which can be used to specify additional driver
        behavior specific to PostgreSQL (e.g. readOnly=true&loggerLevel=DEBUG).
      </Help.P>
    </Help.HelpSection>
  );
};

const User = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>User</Help.HelpHeader>
      <Help.P>The database username.</Help.P>
    </Help.HelpSection>
  );
};

const Password = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Password</Help.HelpHeader>
      <Help.P>The password for the database user.</Help.P>
    </Help.HelpSection>
  );
};

const module = {
  Section,
  Endpoint,
  ConnectionParameters,
  User,
  Password,
};

export default module;
