import { colors } from '../../css';

const theme = {
  plain: {
    color: colors.font_light,
    backgroundColor: colors.section_background,
  },
  styles: [
    {
      types: ['comment', 'prolog', 'doctype', 'cdata'],
      style: {
        color: colors.white,
        fontStyle: 'italic',
      },
    },
    {
      types: [
        'string',
        'attr-value',
        'number',
        'boolean',
        'regex',
        'url',
        'symbol',
      ],
      style: {
        color: colors.error,
      },
    },
  ],
};

export default theme;
