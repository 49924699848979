import { buildUrl } from '../utils/buildUrl';
import * as rayApi from '../rayApi/api';
import telemetry from '../telemetry';
import noop from '../utils/noop';

export const getAuth = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: 'auth/jwt' });
  return rayApi.executeGET(uri, headers, abortFetchSignal, true);
};

export const getCognitoToken = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({ baseUrl: rootUri, path: 'auth/cognitotoken' });
  return rayApi.executeGET(uri, headers, abortFetchSignal);
};

const networkResponseSanitizer = (response) => {
  let sanitizedResponse = response;

  if (
    sanitizedResponse.status === 200 &&
    sanitizedResponse.method === 'GET' &&
    sanitizedResponse.url.includes('auth/jwt')
  ) {
    sanitizedResponse = {
      ...sanitizedResponse,
      body: {
        ...sanitizedResponse.body,
        accessToken: telemetry.sanitize(sanitizedResponse.body.accessToken),
      },
    };
  }

  if (
    sanitizedResponse.status === 200 &&
    sanitizedResponse.method === 'GET' &&
    sanitizedResponse.url.includes('auth/cognitotoken')
  ) {
    sanitizedResponse = {
      ...sanitizedResponse,
      body: {
        ...sanitizedResponse.body,
        token: telemetry.sanitize(sanitizedResponse.body.token),
      },
    };
  }

  return sanitizedResponse;
};

telemetry.networkSanitizer.registerNetworkSanitizer(
  'auth',
  noop,
  networkResponseSanitizer,
);
