import telemetry from '../telemetry/telemetry';

/* eslint-disable no-console */
function log(...args) {
  console.log(...args);
}

function debug(...args) {
  console.debug(...args);
}

function info(...args) {
  console.info(...args);
}

function warn(...args) {
  console.warn(...args);
}

function error(...args) {
  console.error(...args);

  if (args[0] instanceof Error) {
    telemetry.captureException(args[0]);
  } else {
    const message = typeof args[0] === 'string' ? args.shift() : 'Error';
    if (args.length === 1) {
      telemetry.captureMessage(message, args[0]);
    } else {
      telemetry.captureMessage(message, args);
    }
  }
}

function group(label) {
  console.group(label);
}

function groupEnd() {
  console.groupEnd();
}

const module = {
  log,
  debug,
  info,
  warn,
  error,
  group,
  groupEnd,
};

export default module;
