import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { arrayOf, bool, shape, string } from 'prop-types';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import Table from '../../Table';
import DataSources from '../../DataSources';
import PrestoUsers from '../../PrestoUsers';
import PrestoUsersAdd from '../../PrestoUsersAdd';
import { patternIAMRole } from '../utils';
import PrestoUserMappingsHelp from './PrestoUserMappingsHelp';
import { NoDataDiv } from './styledComponents';

const display = {
  title: 'Available Presto Users',
  usernameColumnLabel: 'Name',
  iamRoleLabel: 'IAM Role ARN',
  roleARNPatternError:
    'Role ARN should be in the format of "arn:aws:iam::<AWS ACCOUNT ID>:role/<ROLE NAME>"',
  loadingPrestoUsers: 'Loading...',
  noPrestoUsers: 'No Presto users found.',
  createPrestoUserLabel: 'Create Presto User',
};

const ifLakeFormation = (getValues, additionalValidation) => {
  return (value) => {
    const lakeFormationChecked = getValues('glue.security');
    if (!lakeFormationChecked) return true;
    return additionalValidation(value);
  };
};

const SelectCell = ({ data, className, key }) => {
  const { prestoUser, disabled } = data;
  return (
    <Table.DataCell key={key} className={`form-cell ${className}`}>
      <Form.TextInput
        name={`prestoUserMappings.${prestoUser.prestoUserId}.configuration.iamRoleMapping`}
        disabled={disabled}
        defaultValue={data.prestoUserMapping?.configuration?.iamRoleMapping}
        validationRules={{
          validate: ifLakeFormation(
            data.getValues,
            patternIAMRole(display.roleARNPatternError),
          ),
        }}
        key={prestoUser.prestoUserId}
        trimPasted
      />
    </Table.DataCell>
  );
};

SelectCell.defaultProps = {
  className: '',
};

SelectCell.propTypes = {
  data: shape({
    prestoUser: PrestoUsers.propTypes.PrestoUser.isRequired,
    disabled: bool.isRequired,
    prestoUserMapping: DataSources.propTypes.PrestoUserMapping,
  }).isRequired,
  className: string,
  key: string.isRequired,
};

const dataKeyGenerator = (prestoUserMapping) => {
  return prestoUserMapping.prestoUser.prestoUserId;
};

const baseColumns = [
  {
    name: display.usernameColumnLabel,
    selector: (prestoUserMapping) => {
      return prestoUserMapping.prestoUser.username;
    },
    cellWidth: 'auto',
  },
];

const dataSourceColumns = {
  glue: [
    {
      name: display.iamRoleLabel,
      headerRender: () => {
        return (
          <>
            <div>{display.iamRoleLabel}</div>
            <Help.Button fieldHelp={PrestoUserMappingsHelp.Glue} />
          </>
        );
      },
      selector: (prestoUserMapping) => {
        return prestoUserMapping;
      },
      cellRender: SelectCell,
      cellWidth: 'minmax(calc(50% + 24px), 373px)',
    },
  ],
};

const PrestoUserMappings = ({
  disabled,
  error,
  dataSource,
  prestoUsers,
  loadingPrestoUsers,
}) => {
  const { watch, getValues } = useFormContext();
  const dataSourceType = watch(
    'dataSourceType',
    DataSources.constants.dataSourceTypes.hive,
  );
  const isLakeFormation = watch(
    'glue.security',
    dataSource && dataSource.connection?.hive?.security === 'lake-formation',
  );

  const columns = useMemo(() => {
    return baseColumns.concat(dataSourceColumns[dataSourceType]);
  }, [dataSourceType]);

  const showPrestoUserMapping = useMemo(() => {
    return dataSourceType === 'glue' && isLakeFormation;
  }, [dataSourceType, isLakeFormation]);

  const prestoUserMappings = useMemo(() => {
    if (!prestoUsers || prestoUsers.length === 0) {
      return null;
    }
    const result = prestoUsers.map((pu) => {
      const prestoUserMapping =
        dataSource &&
        dataSource.prestoUserMappings &&
        dataSource.prestoUserMappings.find((pum) => {
          return pum.prestoUserId === pu.prestoUserId;
        });
      return {
        prestoUser: pu,
        disabled,
        prestoUserMapping,
        getValues,
      };
    });
    return result;
  }, [disabled, dataSource, prestoUsers, getValues]);

  const dispatch = PrestoUsersAdd.components.useCreatePrestoUserDispatch();
  const showCreatePrestoUser = useCallback(() => {
    dispatch(true);
  }, [dispatch]);

  if (!dataSourceColumns[dataSourceType]) {
    return null;
  }

  return (
    <Page.Box style={{ display: showPrestoUserMapping ? undefined : 'none' }}>
      <Page.TableBoxHeader>
        <h2>{display.title}</h2>
        <div className='buttons'>
          <Form.PrimaryButton
            label={display.createPrestoUserLabel}
            onClick={showCreatePrestoUser}
            disabledOnErrors={false}
            disabled={disabled}
          />
        </div>
      </Page.TableBoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      {!prestoUsers && loadingPrestoUsers && (
        <NoDataDiv>{display.loadingPrestoUsers}</NoDataDiv>
      )}
      {prestoUsers && prestoUsers.length === 0 && (
        <NoDataDiv>{display.noPrestoUsers}</NoDataDiv>
      )}
      {prestoUsers && prestoUsers.length > 0 && (
        <Table.Table
          data={prestoUserMappings}
          columns={columns}
          dataKeyGenerator={dataKeyGenerator}
        />
      )}
    </Page.Box>
  );
};

PrestoUserMappings.defaultProps = {
  disabled: false,
  error: null,
  dataSource: null,
  prestoUsers: null,
};

PrestoUserMappings.propTypes = {
  disabled: bool,
  error: string,
  dataSource: DataSources.propTypes.DataSource,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadingPrestoUsers: bool.isRequired,
};

export default PrestoUserMappings;
