import { connect } from '../../utils/redux';
import DataSources from '../DataSources';
import DataSourceAdd from '../DataSourceAdd';
import PrestoUsers from '../PrestoUsers';
import PrestoUsersAdd from '../PrestoUsersAdd';
import AuthorizationServices from '../AuthorizationServices';
import { UpdateDataSource } from './components';
import * as selectors from './selectors';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
  return {
    errors: selectors.getErrors(state),
    usePop: selectors.getUsePop(state),
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    notFound: DataSources.selectors.getIsNotFound(state, props),
    dataSourceId: DataSources.selectors.getDataSourceId(state, props),
    dataSource: DataSources.selectors.getDataSource(state, props),
    awsRegions: DataSourceAdd.selectors.getAwsRegions(state),
    computePlaneRegion: DataSourceAdd.selectors.getComputePlaneRegion(state),
    awsGlueS3CloudFormationQuickCreateUrl:
      DataSourceAdd.selectors.getAwsGlueS3CloudFormationQuickCreateUrl(state),
    awsLakeFormationCloudFormationQuickCreateUrl:
      DataSourceAdd.selectors.getAwsLakeFormationCloudFormationQuickCreateUrl(
        state,
      ),
    prestoClusters: DataSources.selectors.getPrestoClusters(state, props),
    prestoUsers: PrestoUsers.selectors.getPrestoUsers(state),
    loadingPrestoUsers: PrestoUsers.selectors.getLoadingInProgress(state),
    unsupportedLakeFormationPrestoClusters:
      selectors.getUnsupportedLakeFormationPrestoClusters(state, props),
    unsupportedRangerHivePrestoClusters:
      selectors.getUnsupportedRangerHivePrestoClusters(state, props),
    authorizationServices:
      AuthorizationServices.selectors.getAuthorizationServices(state),
  };
};

export default connect(mapStateToProps, {
  loadDataSource: DataSources.actions.get,
  update: actions.submit,
  getConfiguration: DataSourceAdd.actions.getConfiguration,
  loadPrestoClusters: DataSources.actions.getPrestoClusters,
  loadPrestoUsers: PrestoUsers.actions.getAll,
  resetCreatePrestoUser: PrestoUsersAdd.actions.resetForm,
  getAuthorizationServices: AuthorizationServices.actions.getAll,
})(UpdateDataSource);
