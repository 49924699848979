import { string } from 'prop-types';
import Form from '../../Form';
import Page from '../../Page';
import Data from '../../Data';
import IAMPolicy from '../../IAMPolicy';

const display = {
  policyContentHeader: 'IAM Policy Settings',
  roleContentHeader: 'IAM Role Settings',
  controlPlaneAwsAccountIdLabel: 'Control Plane AWS Account ID',
  externalIdLabel: 'External ID',
  awsPermissionBoundaryPolicyLabel: 'Permission Boundary AWS Policy',
  awsCoreInfrastructurePolicyLabel: 'Core Infrastructure AWS Policy',
  awsOperationsPolicy: 'Operations AWS Policy',
  awsTrustRelationshipPolicy: 'IAM Role Trust Relationship Policy',
  viewPolicy: 'View Policy',
  copyPolicy: 'Copy Policy',
  copiedMessage: 'Copied',
};

const AccessDetails = ({
  controlPlaneAwsAccountId,
  awsExternalId,
  awsPermissionBoundaryPolicyContent,
  awsCoreInfrastructurePolicyContent,
  awsOperationsPolicyContent,
  awsTrustRelationshipPolicyContent,
}) => {
  return (
    <>
      <div>
        <h3>{display.policyContentHeader}</h3>
      </div>
      <div>
        <Data.Label label={display.awsPermissionBoundaryPolicyLabel} />
        <Data.ButtonGroup>
          <Page.TertiaryLink
            to={IAMPolicy.routes.getIAMPolicyRoute(
              IAMPolicy.constants.policyType.permissionBoundary,
            )}
          >
            {display.viewPolicy}
          </Page.TertiaryLink>
          <Form.CopyButton
            contentToCopy={awsPermissionBoundaryPolicyContent}
            buttonLabel={display.copyPolicy}
            disabledOnErrors={false}
          />
        </Data.ButtonGroup>
        <Data.Label label={display.awsCoreInfrastructurePolicyLabel} />
        <Data.ButtonGroup>
          <Page.TertiaryLink
            to={IAMPolicy.routes.getIAMPolicyRoute(
              IAMPolicy.constants.policyType.coreInfrastructure,
            )}
          >
            {display.viewPolicy}
          </Page.TertiaryLink>
          <Form.CopyButton
            contentToCopy={awsCoreInfrastructurePolicyContent}
            buttonLabel={display.copyPolicy}
            disabledOnErrors={false}
          />
        </Data.ButtonGroup>
        <Data.Label label={display.awsOperationsPolicy} />
        <Data.ButtonGroup>
          <Page.TertiaryLink
            to={IAMPolicy.routes.getIAMPolicyRoute(
              IAMPolicy.constants.policyType.operations,
            )}
          >
            {display.viewPolicy}
          </Page.TertiaryLink>
          <Form.CopyButton
            contentToCopy={awsOperationsPolicyContent}
            buttonLabel={display.copyPolicy}
            disabledOnErrors={false}
          />
        </Data.ButtonGroup>
        <Data.Label label={display.awsTrustRelationshipPolicy} />
        <Data.ButtonGroup>
          <Page.TertiaryLink
            to={IAMPolicy.routes.getIAMPolicyRoute(
              IAMPolicy.constants.policyType.trustRelationship,
            )}
          >
            {display.viewPolicy}
          </Page.TertiaryLink>
          <Form.CopyButton
            contentToCopy={awsTrustRelationshipPolicyContent}
            buttonLabel={display.copyPolicy}
            disabledOnErrors={false}
          />
        </Data.ButtonGroup>
      </div>
      <div>
        <h3>{display.roleContentHeader}</h3>
      </div>
      <div>
        <Data.DataFieldWithCopy
          label={display.controlPlaneAwsAccountIdLabel}
          value={controlPlaneAwsAccountId}
        />
        <Data.DataFieldWithCopy
          label={display.externalIdLabel}
          value={awsExternalId}
        />
      </div>
    </>
  );
};

AccessDetails.defaultProps = {
  awsPermissionBoundaryPolicyContent: null,
  awsCoreInfrastructurePolicyContent: null,
  awsOperationsPolicyContent: null,
  awsTrustRelationshipPolicyContent: null,
};

AccessDetails.propTypes = {
  controlPlaneAwsAccountId: string.isRequired,
  awsExternalId: string.isRequired,
  awsPermissionBoundaryPolicyContent: string,
  awsCoreInfrastructurePolicyContent: string,
  awsOperationsPolicyContent: string,
  awsTrustRelationshipPolicyContent: string,
};

export default AccessDetails;
