import Help from '../../Help';

const username = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Username</Help.HelpHeader>
      <Help.P>The username for connecting to Prometheus.</Help.P>
      <Help.P>
        The username value can only consist of alphanumeric characters, dash(-),
        underscore(_), period(.), and tilde(~).
      </Help.P>
    </Help.HelpSection>
  );
};

const password = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Password</Help.HelpHeader>
      <Help.P>The password for connecting to Prometheus.</Help.P>
      <Help.P>
        The password must be between 8 and 64 characters long. Must contain at
        least 3 of the following: one uppercase letter, one lowercase letter,
        one number and one special character. Can be any printable ASCII
        character except space( ), slash(/), double quote(&quot;), at sign(@),
        backslash(\) or single quote(&apos;).
      </Help.P>
      <Help.P>
        If you need to set a password value that uses special characters, you
        will have to URL-encode the special characters when setting up your JDBC
        connection.
      </Help.P>
      <Help.P>
        The period(.), underscore(_) and tilde(~) characters don&apos;t require
        URL encoding.
      </Help.P>
    </Help.HelpSection>
  );
};

const website = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Prometheus Website</Help.HelpHeader>
      <Help.P>
        Enable or disable internet access to the Prometheus website.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  username,
  password,
  website,
};

export default module;
