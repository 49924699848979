import { connect } from '../../utils/redux';
import { DataSources } from './components';
import * as selectors from './selectors';
import * as actions from './actions';

const mapStateToProps = (state) => {
  return {
    dataSources: selectors.getDataSources(state),
  };
};

export default connect(mapStateToProps, {
  loadDataSources: actions.getAll,
})(DataSources);
