import { useCallback } from 'react';
import { elementType } from 'prop-types';
import { onAccessibleClick } from '../utils/accessibility';
import CloseIcon from '../../icons/Close';
import {
  useHelpNavigationState,
  useHelpNavigationDispatch,
} from './HelpNavigation';
import { StickyHelpColumnDiv, HelpColumnDiv } from './styledComponents';

const HelpColumn = ({ DefaultHelp }) => {
  const SelectedHelp = useHelpNavigationState();
  const dispatch = useHelpNavigationDispatch();

  const closeSelectedHelp = useCallback(
    (event) => {
      onAccessibleClick(event, () => {
        dispatch(null);
      });
    },
    [dispatch],
  );

  if (SelectedHelp) {
    // I think webpack is trying to be clever and making these
    // help components elements not functional components.
    // If we start to need parameters passed into the selected
    // help for certain components, then this will get more complicated.
    return (
      <StickyHelpColumnDiv>
        <button onClick={closeSelectedHelp} type='button' className='close'>
          <CloseIcon />
        </button>
        {SelectedHelp}
      </StickyHelpColumnDiv>
    );
  }

  return (
    <HelpColumnDiv>
      <DefaultHelp />
    </HelpColumnDiv>
  );
};

HelpColumn.propTypes = {
  DefaultHelp: elementType.isRequired,
};

export default HelpColumn;
