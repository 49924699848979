import { useEffect } from 'react';
import { bool, func } from 'prop-types';
import Page from '../../Page';
import Form from '../../Form';
import IdentityProviders from '../../IdentityProviders';

const display = {
  title: 'Identity Provider',
  externalAuthentication: 'External Authentication',
  enableAuthenticationOIDC: 'Enable authentication through OIDC',
};

const AttachedIdentityProvider = ({
  loadingInProgress,
  identityProvider,
  loadIdentityProviders,
}) => {
  useEffect(() => {
    loadIdentityProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingInProgress || !identityProvider) return null;

  return (
    <Page.Box>
      {identityProvider && (
        <div>
          <Page.BoxHeader>
            <h2>{display.title}</h2>
          </Page.BoxHeader>
          <Page.FormContent>
            <div>
              <h3>{display.externalAuthentication}</h3>
            </div>
            <Form.CheckboxInput
              name='enableOIDC'
              label={display.enableAuthenticationOIDC}
              defaultChecked
              disabled
            />
          </Page.FormContent>
        </div>
      )}
    </Page.Box>
  );
};

AttachedIdentityProvider.defaultProps = {
  identityProvider: null,
};

AttachedIdentityProvider.propTypes = {
  loadingInProgress: bool.isRequired,
  identityProvider: IdentityProviders.propTypes.IdentityProvider,
  loadIdentityProviders: func.isRequired,
};

export default AttachedIdentityProvider;
