import { useCallback, useState, useEffect, useMemo } from 'react';
import { func, string, bool } from 'prop-types';
import useObjectURL from 'use-object-url';
import Help from '../../Help';
import Page from '../../Page';
import Form from '../../Form';
import Banner from '../../Banner';

const display = {
  openCloudFormationLabel: 'Open CloudFormation',
  verifyIAMUpdateLabel: 'Verify IAM Update',
  copyS3UrlLabel: 'Copy S3 URL',
  bannerTitle: 'Updated IAM Policies for AWS Provisioning Role',
  manualCloudFormationButtonLabel: 'Save CloudFormation Template',
  terraformButtonLabel: 'Save Terraform',
};

const CloudFormation = ({
  awsCloudFormationTemplateS3Url,
  cloudFormationStacksListingUrl,
  onVerifyIAMUpdate,
  isDisabled,
  cloudFormationStackName,
  cloudFormationTemplate,
}) => {
  const cloudFormationTemplateBlob = useMemo(() => {
    return new Blob([cloudFormationTemplate], { type: 'application/text' });
  }, [cloudFormationTemplate]);
  const url = useObjectURL(cloudFormationTemplateBlob);

  return (
    <Help.MultiColumn>
      <div>
        <Help.ColumnTitle>CloudFormation Role Creation Update</Help.ColumnTitle>
        <Help.OrderedList>
          <Help.ListItem>
            Copy this S3 URL. This value will be used in later steps inside the
            AWS Console.
            <Help.MultiStepButtonGroupDiv>
              <Form.CopyButton
                contentToCopy={awsCloudFormationTemplateS3Url}
                buttonLabel={display.copyS3UrlLabel}
              />
            </Help.MultiStepButtonGroupDiv>
          </Help.ListItem>
          <Help.ListItem>
            Open the AWS CloudFormation console using the link below. If the
            CloudFormation stack that created the role does not open, find it in
            the list and open it.
            <Help.MultiStepButtonGroupDiv>
              <div className='link-wrapper'>
                <Page.TertiaryLink
                  to={cloudFormationStacksListingUrl}
                  external
                  newTab
                >
                  {display.openCloudFormationLabel}
                </Page.TertiaryLink>
              </div>
            </Help.MultiStepButtonGroupDiv>
          </Help.ListItem>
          <Help.ListItem>
            Click the &quot;Update&quot; button for the CloudFormation stack.
          </Help.ListItem>
          <Help.ListItem>
            Select &quot;Replace current template&quot;. Paste the S3 URL that
            was copied above.
          </Help.ListItem>
          <Help.ListItem>
            Complete the stack update by clicking through the rest of the
            wizard; no other changes are required. Acknowledge the update and
            press &quot;Submit&quot;. The update will be complete once the stack
            status is set to &quot;UPDATE_COMPLETE&quot;.
          </Help.ListItem>
          <Help.ListItem>
            Verify the IAM update was successful by clicking on the button
            below. This message banner will disappear if the update was
            successful.
            <Help.MultiStepButtonGroupDiv>
              <Form.TertiaryButton
                label={display.verifyIAMUpdateLabel}
                onClick={onVerifyIAMUpdate}
                disabled={isDisabled}
                showLoading={isDisabled}
              />
            </Help.MultiStepButtonGroupDiv>
          </Help.ListItem>
        </Help.OrderedList>
      </div>
      <div>
        <Help.ColumnTitle>Manual Role Creation Update</Help.ColumnTitle>
        <Help.OrderedList>
          <Help.ListItem>
            If you manually downloaded the CloudFormation template during the
            role creation process, download the updated version below.
            <Help.MultiStepButtonGroupDiv>
              <div className='link-wrapper'>
                <Page.TertiaryLink
                  download
                  downloadFileName={`${cloudFormationStackName}.yaml`}
                  to={url}
                >
                  {display.manualCloudFormationButtonLabel}
                </Page.TertiaryLink>
              </div>
            </Help.MultiStepButtonGroupDiv>
          </Help.ListItem>
          <Help.ListItem>
            Open the AWS CloudFormation console using the link below. If the
            CloudFormation stack that created the role does not open, find it in
            the list and open it.
            <Help.MultiStepButtonGroupDiv>
              <div className='link-wrapper'>
                <Page.TertiaryLink
                  to={cloudFormationStacksListingUrl}
                  external
                  newTab
                >
                  {display.openCloudFormationLabel}
                </Page.TertiaryLink>
              </div>
            </Help.MultiStepButtonGroupDiv>
          </Help.ListItem>
          <Help.ListItem>
            Click the &quot;Update&quot; button for the CloudFormation stack.
          </Help.ListItem>
          <Help.ListItem>
            Select &quot;Replace current template&quot;. Paste the URL of the S3
            object that contains the downloaded content or use the form to
            upload the file.
          </Help.ListItem>
          <Help.ListItem>
            Complete the stack update by clicking through the rest of the
            wizard; no other changes are required. Acknowledge the update and
            press &quot;Submit&quot;. The update will be complete once the stack
            status is set to &quot;UPDATE_COMPLETE&quot;.
          </Help.ListItem>
          <Help.ListItem>
            Verify the IAM update was successful by clicking on the button
            below. This message banner will disappear if the update was
            successful.
            <Help.MultiStepButtonGroupDiv>
              <Form.TertiaryButton
                label={display.verifyIAMUpdateLabel}
                onClick={onVerifyIAMUpdate}
                disabled={isDisabled}
                showLoading={isDisabled}
              />
            </Help.MultiStepButtonGroupDiv>
          </Help.ListItem>
        </Help.OrderedList>
      </div>
    </Help.MultiColumn>
  );
};

CloudFormation.propTypes = {
  awsCloudFormationTemplateS3Url: string.isRequired,
  cloudFormationStacksListingUrl: string.isRequired,
  cloudFormationTemplate: string.isRequired,
  cloudFormationStackName: string.isRequired,
  isDisabled: bool.isRequired,
  onVerifyIAMUpdate: func.isRequired,
};

const Terraform = ({
  onVerifyIAMUpdate,
  isDisabled,
  terraformContent,
  terraformFileName,
}) => {
  const terraformBlob = useMemo(() => {
    return new Blob([terraformContent], { type: 'application/text' });
  }, [terraformContent]);
  const url = useObjectURL(terraformBlob);

  return (
    <Help.MultiColumn>
      <div>
        <Help.ColumnTitle>Terraform Role Creation Update</Help.ColumnTitle>
        <Help.OrderedList>
          <Help.ListItem>
            Save this Terraform file to disk.
            <Help.MultiStepButtonGroupDiv>
              <div className='link-wrapper'>
                <Page.TertiaryLink
                  download
                  downloadFileName={terraformFileName}
                  to={url}
                >
                  {display.terraformButtonLabel}
                </Page.TertiaryLink>
              </div>
            </Help.MultiStepButtonGroupDiv>
          </Help.ListItem>
          <Help.ListItem>
            Apply the Terraform file so that it replaces the previously deployed
            Terraform.
          </Help.ListItem>
          <Help.ListItem>
            Verify the IAM update was successful by clicking on the button
            below. This message banner will disappear if the update was
            successful.
            <Help.MultiStepButtonGroupDiv>
              <Form.TertiaryButton
                label={display.verifyIAMUpdateLabel}
                onClick={onVerifyIAMUpdate}
                disabled={isDisabled}
                showLoading={isDisabled}
              />
            </Help.MultiStepButtonGroupDiv>
          </Help.ListItem>
        </Help.OrderedList>
      </div>
    </Help.MultiColumn>
  );
};

Terraform.propTypes = {
  terraformContent: string.isRequired,
  terraformFileName: string.isRequired,
  isDisabled: bool.isRequired,
  onVerifyIAMUpdate: func.isRequired,
};

const PolicyUpgrade = ({
  awsCloudFormationTemplateS3Url,
  checkProvisioningPolicies,
  computePlaneId,
  cloudFormationStacksListingUrl,
  computePlaneUpdateDate,
  cloudFormationTemplate,
  cloudFormationStackName,
  roleCreationMethod,
  terraformContent,
  terraformFileName,
}) => {
  const [isDisabled, setDisabled] = useState(false);
  useEffect(() => {
    setDisabled(false);
  }, [computePlaneUpdateDate]);
  const onVerifyIAMUpdate = useCallback(() => {
    setDisabled(true);
    checkProvisioningPolicies(computePlaneId);
  }, [checkProvisioningPolicies, computePlaneId]);

  return (
    <Banner title={display.bannerTitle} isWarning>
      <Help.MultiColumnDescription>
        There are new IAM policy versions for the AWS provisioning role. We
        recommend you update your watsonx.data provisioning IAM role with new
        policies. Newer functionality may not properly work without the latest
        IAM policies. If you require assistance, please use IBM Cloud{' '}
        <a
          href='https://cloud.ibm.com/unifiedsupport/supportcenter/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Support Center
        </a>{' '}
        to create a support case. Otherwise, follow the following steps to
        update your watsonx.data provisioning IAM role.
      </Help.MultiColumnDescription>
      {roleCreationMethod === 'CloudFormation' && (
        <CloudFormation
          awsCloudFormationTemplateS3Url={awsCloudFormationTemplateS3Url}
          cloudFormationStacksListingUrl={cloudFormationStacksListingUrl}
          onVerifyIAMUpdate={onVerifyIAMUpdate}
          isDisabled={isDisabled}
          cloudFormationStackName={cloudFormationStackName}
          cloudFormationTemplate={cloudFormationTemplate}
        />
      )}
      {roleCreationMethod === 'Terraform' && (
        <Terraform
          onVerifyIAMUpdate={onVerifyIAMUpdate}
          isDisabled={isDisabled}
          terraformContent={terraformContent}
          terraformFileName={terraformFileName}
        />
      )}
    </Banner>
  );
};

PolicyUpgrade.propTypes = {
  awsCloudFormationTemplateS3Url: string.isRequired,
  checkProvisioningPolicies: func.isRequired,
  computePlaneId: string.isRequired,
  cloudFormationStacksListingUrl: string.isRequired,
  computePlaneUpdateDate: string.isRequired,
  cloudFormationTemplate: string.isRequired,
  cloudFormationStackName: string.isRequired,
  roleCreationMethod: string.isRequired,
  terraformContent: string.isRequired,
  terraformFileName: string.isRequired,
};

export default PolicyUpgrade;
