import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Ready to add an identity provider?</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Configure your identity provider OIDC application.
        </Help.ListItem>
        <Help.ListItem>Provide an identity provider name.</Help.ListItem>
        <Help.ListItem>
          Provide the well known configuration URL, client ID, and client secret
          from your identity provider.
        </Help.ListItem>
        <Help.ListItem>
          After the compute plane is configured to connect with the identity
          provider, update the identity provider OIDC application with the
          provided sign-in redirect URL.
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        Once you add an identity provider, it cannot be removed without
        watsonx.data support. If you need to remove an identity provider, please
        use IBM Cloud{' '}
        <a
          href='https://cloud.ibm.com/unifiedsupport/supportcenter/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Support Center
        </a>{' '}
        to create a support case.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
