import { createSelector } from 'reselect';
import semverGreaterThanOrEqualTo from 'semver/functions/gte';
import * as constants from './constants';

export const getComputePlaneId = (state) => {
  return state.standalonePrometheus.computePlaneId;
};

export const getPrometheusDeleteInProgress = (state) => {
  return state.standalonePrometheus?.prometheusDeleteInProgress;
};

export const getPrometheus = (state) => {
  return state.standalonePrometheus.prometheusConfiguration;
};

export const getCanDestroyOrEditPrometheus = createSelector(
  getPrometheus,
  (prometheusConfiguration) => {
    if (!prometheusConfiguration) return false;
    return (
      prometheusConfiguration.status !== constants.prometheusStatus.modifying
    );
  },
);

export const getError = (state) => {
  return state.standalonePrometheus?.PrometheusError;
};

export const getComputePlaneVersion = (state) => {
  return state.standalonePrometheus.computePlaneVersion;
};

export const getCanEnablePrometheus = createSelector(
  getComputePlaneVersion,
  (computePlaneVersion) => {
    const minVersion = '7.2.0';
    if (computePlaneVersion) {
      return semverGreaterThanOrEqualTo(computePlaneVersion, minVersion);
    }
    return false;
  },
);
