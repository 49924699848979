const Progress = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <path d='M16,2A14,14,0,1,0,30,16,14.0158,14.0158,0,0,0,16,2Zm0,26A12,12,0,0,1,16,4V16l8.4812,8.4814A11.9625,11.9625,0,0,1,16,28Z' />
      <rect width='32' height='32' style={{ fill: 'none' }} />
    </svg>
  );
};

export default Progress;
