import Help from '../../Help';

const Glue = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Presto User to IAM Role Mapping</Help.HelpHeader>
      <Help.P>
        A Presto user will effectively assume an IAM role when using Lake
        Formation. This section specifies which IAM role is used for each Presto
        user. If no IAM role is mapped to a Presto user, that Presto user will
        not have access to Lake Formation backed data catalog resources.
      </Help.P>
      <Help.P>
        Amazon Resource Names (ARNs) uniquely identify AWS resources. For each
        Presto user that would like to access Lake Formation, enter the ARN for
        the corresponding IAM role you would like the Presto user to assume.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  Glue,
};

export default module;
