import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../Page';
import Form from '../../Form';
import useRouteBuilder from '../../../router/useRouteBuilder';
import { MessageDiv } from './styledComponents';
import DataSources from '../../DataSources';

const display = {
  boxTitle: 'Lake Formation',
  boxMessage:
    'Lake Formation authorization is configured directly in the Glue Data Source.',
  addGlueButton: 'Add a Glue Data Source',
};

const LakeFormationSetup = () => {
  const navigate = useNavigate();
  const addDataSourceRoute = useRouteBuilder(
    DataSources.routes.getAddDataSourceRoute,
  );
  const addGlueHandler = useCallback(() => {
    navigate(addDataSourceRoute, { replace: true });
  }, [navigate, addDataSourceRoute]);

  return (
    <Page.Box>
      <Page.TableBoxHeader>
        <h2>{display.boxTitle}</h2>
        <div className='buttons'>
          <Form.PrimaryButton onClick={addGlueHandler}>
            {display.addGlueButton}
          </Form.PrimaryButton>
        </div>
      </Page.TableBoxHeader>
      <MessageDiv>
        Lake Formation authorization is configured directly in the Glue Data
        Source.
      </MessageDiv>
    </Page.Box>
  );
};

export default LakeFormationSetup;
