import { bool, string, func } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../img/ibm_logo_white.svg';
import communityLogo from '../../../img/ahana_ce_logo_horz_white.svg';
import routes from '../../../router/routes';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import Navigation from './Navigation';
import PublicNavigation from './PublicNavigation';
import { HeaderDiv } from './styledComponents';

const blurClicks = (event) => {
  if (event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) {
    event.currentTarget.blur();
  }
};

const Header = ({ authenticated, name, serviceName, noNav, signOut }) => {
  const isCommunityEditionTenant = useIsCommunityEditionTenant();
  const location = useLocation();
  return (
    <HeaderDiv
      className={isCommunityEditionTenant ? 'communityEdition' : undefined}
    >
      {!noNav && (
        <Link
          to={{ pathname: routes.home.getRoute(), search: location.search }}
          className='logo'
          onClick={blurClicks}
        >
          <img
            src={isCommunityEditionTenant ? communityLogo : logo}
            alt='IBM watsonx.data'
          />
        </Link>
      )}
      {noNav && (
        <div className='logo'>
          <img
            src={isCommunityEditionTenant ? communityLogo : logo}
            alt='IBM watsonx.data'
          />
        </div>
      )}
      {authenticated && (
        <Navigation
          name={name}
          noNav={noNav}
          signOut={signOut}
          serviceName={serviceName}
        />
      )}
      {!authenticated && !noNav && <PublicNavigation />}
    </HeaderDiv>
  );
};

Header.defaultProps = {
  noNav: false,
  name: undefined,
  serviceName: undefined,
};

Header.propTypes = {
  authenticated: bool.isRequired,
  name: string,
  serviceName: string,
  noNav: bool,
  signOut: func.isRequired,
};

export default Header;
