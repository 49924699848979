import { bool, string } from 'prop-types';
import Data from '../../Data';
import Page from '../../Page';
import PrestoUsers from '../../PrestoUsers';
import { useEnableFeature } from '../../utils/featureHooks';

const display = {
  title: 'Details',
  usernameLabel: 'Username',
  passwordLabel: 'Password',
  changeCredentialsButton: 'Change Credentials',
  sectionHeader: 'Credential Settings',
};

const Details = ({
  navTarget,
  username,
  password,
  prestoUserId,
  canModify,
}) => {
  const enableLink = useEnableFeature();

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
        <div className='buttons'>
          <Page.PrimaryLink
            to={PrestoUsers.routes.getUpdatePrestoUserRoute(prestoUserId)}
            disabled={!canModify || !enableLink}
          >
            {display.changeCredentialsButton}
          </Page.PrimaryLink>
        </div>
      </Page.BoxHeader>
      <Page.FormContent rows={2}>
        <div>
          <h3>{display.sectionHeader}</h3>
          {/* <div>{display.formExplaination}</div> */}
        </div>
        <div>
          <Data.DataField label={display.usernameLabel} value={username} />
          <Data.DataField label={display.passwordLabel} value={password} />
        </div>
      </Page.FormContent>
    </Page.BoxNavTarget>
  );
};

Details.defaultProps = {
  username: null,
  password: null,
  canModify: true,
};

Details.propTypes = {
  navTarget: string.isRequired,
  username: string,
  password: string,
  prestoUserId: string.isRequired,
  canModify: bool,
};

export default Details;
