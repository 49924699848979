import { createApiAction } from '../../rayApi/actions';
import telemetry from '../../telemetry';
import PrestoClusters from '../PrestoClusters';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const submit = (values) => {
  return {
    type: actionTypes.submit,
    payload: {
      values,
    },
  };
};

export const post = (body) => {
  return createApiAction(
    actionTypes.post,
    PrestoClusters.api.post,
    true,
    null,
    body,
  );
};

export const updateVersions = (computePlaneId) => {
  return createApiAction(
    actionTypes.updateVersions,
    api.updateVersions,
    true,
    {
      computePlaneId,
    },
    {},
  );
};

export const getVersions = (computePlaneId) => {
  return createApiAction(actionTypes.getVersions, api.getVersions, true, {
    computePlaneId,
  });
};

const actionSanitizer = (action) => {
  let sanitizedAction = action;
  if (action.type === actionTypes.submit) {
    if (action.payload.values.credentials) {
      sanitizedAction = {
        ...action,
        payload: {
          ...action.payload,
          values: {
            ...action.payload.values,
            credentials: {
              ...action.payload.values.credentials,
              password: telemetry.sanitize(
                action.payload.values.credentials.password,
              ),
              password2: telemetry.sanitize(
                action.payload.values.credentials.password2,
              ),
            },
          },
          sanitized: true,
        },
      };
    }
  } else if (action.type === actionTypes.post.request) {
    if (action.payload.body.credentials) {
      sanitizedAction = {
        ...action,
        payload: {
          ...action.payload,
          body: {
            ...action.payload.body,
            credentials: action.payload.body.credentials.map((c) => {
              return {
                ...c,
                password: telemetry.sanitize(c.password),
              };
            }),
          },
        },
        sanitized: true,
      };
    }
  } else if (
    action.type === actionTypes.post.pending ||
    action.type === actionTypes.post.accepted ||
    action.type === actionTypes.post.success ||
    action.type === actionTypes.post.failure ||
    action.type === actionTypes.post.abort
  ) {
    if (action.payload.requestBody.credentials) {
      sanitizedAction = {
        ...action,
        payload: {
          ...action.payload,
          requestBody: {
            ...action.payload.requestBody,
            credentials: action.payload.requestBody.credentials.map((c) => {
              return {
                ...c,
                password: telemetry.sanitize(c.password),
              };
            }),
          },
        },
        sanitized: true,
      };
    }
  }
  return sanitizedAction;
};

telemetry.actionSanitizer.registerActionSanitizer(
  'prestoClusterAdd',
  actionSanitizer,
);
