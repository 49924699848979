import { renderToStaticMarkup } from 'react-dom/server';

const convertSvgComponentToDataUri = (Component, componentProps) => {
  const svgString = `<?xml version="1.0" encoding="UTF-8"?>${renderToStaticMarkup(
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Component {...componentProps} />,
  )}`;
  const dataUri = `url("data:image/svg+xml;base64,${btoa(svgString)}")`;
  return dataUri;
};

export default convertSvgComponentToDataUri;
