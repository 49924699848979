import { useMemo } from 'react';
import { arrayOf, bool, string, shape } from 'prop-types';
import Page from '../../Page';
import Banner from '../../Banner';
import Form from '../../Form';
import DataSources from '../../DataSources';
import AuthorizationServices from '../../AuthorizationServices';
import {
  ElasticsearchConnectionDetails,
  GlueConnectionDetails,
  HiveConnectionDetails,
  MySqlConnectionDetails,
  PostgresConnectionDetails,
  IBMDb2ConnectionDetails,
  RedshiftConnectionDetails,
} from '../../DataSourceAdd/components';

const display = {
  boxTitle: 'Access details',
};

const ConnectionDetails = ({
  disabled,
  error,
  awsRegions,
  computePlaneRegion,
  awsGlueS3CloudFormationQuickCreateUrl,
  awsLakeFormationCloudFormationQuickCreateUrl,
  dataSource,
  unsupportedLakeFormationPrestoClusters,
  unsupportedRangerHivePrestoClusters,
  authorizationServices,
}) => {
  const dataSourceType = dataSource && dataSource.type;

  const authorizationServicesDisplay = useMemo(() => {
    if (!authorizationServices || authorizationServices.length === 0) {
      return null;
    }

    return authorizationServices.reduce(
      (acc, as) => {
        acc.push({
          display: as.name,
          value: as.authorizationServiceId,
        });
        return acc;
      },
      [{ display: 'No Authorization Service', value: '' }],
    );
  }, [authorizationServices]);

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && error.type === dataSourceType && (
        <Banner title={error.error} scrollIntoView />
      )}
      <Page.FormContent>
        <Form.HiddenInput name='dataSourceType' value={dataSourceType} />
        {dataSourceType ===
          DataSources.constants.dataSourceTypes.elasticsearch && (
          <ElasticsearchConnectionDetails
            active
            disabled={disabled}
            awsRegions={awsRegions}
            dataSource={dataSource}
          />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.glue && (
          <GlueConnectionDetails
            active
            disabled={disabled}
            awsRegions={awsRegions}
            computePlaneRegion={computePlaneRegion}
            awsGlueS3CloudFormationQuickCreateUrl={
              awsGlueS3CloudFormationQuickCreateUrl
            }
            awsLakeFormationCloudFormationQuickCreateUrl={
              awsLakeFormationCloudFormationQuickCreateUrl
            }
            dataSource={dataSource}
            unsupportedLakeFormationPrestoClusters={
              unsupportedLakeFormationPrestoClusters
            }
            unsupportedRangerHivePrestoClusters={
              unsupportedRangerHivePrestoClusters
            }
            authorizationServices={authorizationServicesDisplay}
          />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.hive && (
          <HiveConnectionDetails
            active
            disabled={disabled}
            dataSource={dataSource}
            authorizationServices={authorizationServicesDisplay}
            unsupportedRangerHivePrestoClusters={
              unsupportedRangerHivePrestoClusters
            }
          />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.mysql && (
          <MySqlConnectionDetails
            active
            disabled={disabled}
            dataSource={dataSource}
          />
        )}
        {dataSourceType ===
          DataSources.constants.dataSourceTypes.postgresql && (
          <PostgresConnectionDetails
            active
            disabled={disabled}
            dataSource={dataSource}
          />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.db2 && (
          <IBMDb2ConnectionDetails
            active
            disabled={disabled}
            dataSource={dataSource}
          />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.redshift && (
          <RedshiftConnectionDetails
            active
            disabled={disabled}
            dataSource={dataSource}
          />
        )}
      </Page.FormContent>
    </Page.Box>
  );
};

ConnectionDetails.defaultProps = {
  disabled: false,
  error: null,
  awsGlueS3CloudFormationQuickCreateUrl: null,
  awsLakeFormationCloudFormationQuickCreateUrl: null,
  dataSource: null,
  unsupportedLakeFormationPrestoClusters: false,
  unsupportedRangerHivePrestoClusters: false,
  authorizationServices: null,
};

ConnectionDetails.propTypes = {
  disabled: bool,
  error: shape({
    type: string,
    error: string,
  }),
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  computePlaneRegion: string.isRequired,
  awsGlueS3CloudFormationQuickCreateUrl: string,
  awsLakeFormationCloudFormationQuickCreateUrl: string,
  dataSource: DataSources.propTypes.DataSource,
  unsupportedLakeFormationPrestoClusters: bool,
  unsupportedRangerHivePrestoClusters: bool,
  authorizationServices: arrayOf(
    AuthorizationServices.propTypes.AuthorizationService,
  ),
};

export default ConnectionDetails;
