import Button from './Button';
import HelpColumn from './HelpColumn';
import HelpNavigation, {
  useHelpNavigationDispatch,
  useHelpNavigationState,
} from './HelpNavigation';
import {
  ColumnTitleDiv,
  MultiColumnWrapperDiv,
  HelpHeader,
  HelpSection,
  ListItem,
  MultiColumnDescriptionDiv,
  MultiStepButtonGroupDiv,
  OrderedList,
  P,
  Strong,
} from './styledComponents';

const module = {
  Button,
  ColumnTitle: ColumnTitleDiv,
  HelpColumn,
  MultiColumn: MultiColumnWrapperDiv,
  MultiColumnDescription: MultiColumnDescriptionDiv,
  MultiStepButtonGroupDiv,
  HelpHeader,
  HelpNavigation,
  HelpSection,
  ListItem,
  OrderedList,
  P,
  Strong,
  useHelpNavigationDispatch,
  useHelpNavigationState,
};

export default module;
