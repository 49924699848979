import { isCommunityEditionTenant } from '../AccountSetup/selectors';
import { getOAuth2PrestoCluster } from '../PrestoClusters/selectors';

export const getIdentityProviderId = (state, props) => {
  return props?.params?.identityProviderId;
};

const checkIdp = (as) => {
  if (as === 'not found') {
    return null;
  }
  return as;
};

export const getIdentityProvider = (state, props) => {
  if (!state.identityProviders.identityProviders) {
    return null;
  }
  const identityProviderId = getIdentityProviderId(state, props);

  if (identityProviderId) {
    const idp =
      state.identityProviders.identityProviders.get(identityProviderId);
    return checkIdp(idp);
  }

  // eslint-disable-next-line no-unreachable-loop
  for (const idp of state.identityProviders.identityProviders) {
    return checkIdp(idp[1]);
  }

  return null;
};

export const getLoadingInProgress = (state) => {
  return state.identityProviders.loadingInProgress;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getError = (state) => {
  return state.identityProviders.error;
};

export const getErrorMessagesText = (state, props) => {
  const identityProvider = getIdentityProvider(state, props);

  return identityProvider?.errorMessages?.reduce((previous, current, index) => {
    return index === 0
      ? current.errorMessage
      : `${previous}\n${current.errorMessage}`;
  }, '');
};

export const getEnableExternalIdentityProvider = (state) => {
  const isCommunityEdition = isCommunityEditionTenant(state);
  return (
    !isCommunityEdition &&
    // state.subscription?.tenant?.featureFlags?.enableExternalIdentityProvider
    true
  );
};

export const getIsNotFound = (state, props) => {
  const identityProviderId = getIdentityProviderId(state, props);
  if (!state.identityProviders.identityProviders) {
    return null;
  }
  if (identityProviderId) {
    const as =
      state.identityProviders.identityProviders.get(identityProviderId);
    if (as === 'not found') {
      return true;
    }
  }
  return false;
};

export const canDestroy = (state) => {
  const hasOAuth2PrestoCluster = !!getOAuth2PrestoCluster(state);
  return !hasOAuth2PrestoCluster;
};
