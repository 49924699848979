import { useCallback } from 'react';
import { func, string } from 'prop-types';
import {
  CDSModal,
  ModalContainer,
  ModalHeader,
  ModalHeaderLabel,
  ModalHeaderHeading,
  ModalClose,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalFooterBtnSecondary,
  ModalFooterBtnPrimary,
  VisuallyHidden,
} from './styledComponents';
import { onAccessibleClick } from '../utils/accessibility';

const Modal = ({ unmount, onSubmit, onCancel, confirmMessage }) => {
  const ariaLabel = '';
  const primaryButtonText = 'OK';
  const secondaryButtonText = 'Cancel';

  const handleSubmit = useCallback(
    (event) => {
      return onAccessibleClick(event, () => {
        onSubmit();
        unmount();
      });
    },
    [onSubmit, unmount],
  );

  const handleCancel = useCallback(
    (event) => {
      return onAccessibleClick(event, () => {
        onCancel();
        unmount();
      });
    },
    [onCancel, unmount],
  );

  const modalButton = (
    <ModalClose type='button' aria-label='close' onClick={handleCancel}>
      <ModalCloseIcon
        focusable='false'
        preserveAspectRatio='xMidYMid meet'
        xmlns='http://www.w3.org/2000/svg'
        fill='white'
        aria-hidden='true'
        width='20'
        height='20'
        viewBox='0 0 32 32'
      >
        <path
          fill='white'
          d='M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z'
        />
      </ModalCloseIcon>
    </ModalClose>
  );

  const modalBody = (
    <>
      <ModalContainer
        role='dialog'
        aria-label={ariaLabel}
        aria-modal='true'
        tabIndex='-1'
      >
        <ModalHeader>
          <ModalHeaderLabel id='cds--modal-header__label--modal-1' />
          <ModalHeaderHeading id='cds--modal-header__heading--modal-1'>
            {confirmMessage}
          </ModalHeaderHeading>
          {modalButton}
        </ModalHeader>
        <ModalContent />
        <ModalFooter>
          <ModalFooterBtnSecondary
            type='button'
            autoFocus
            onClick={handleCancel}
          >
            {secondaryButtonText}
          </ModalFooterBtnSecondary>
          <ModalFooterBtnPrimary type='button' onClick={handleSubmit}>
            {primaryButtonText}
          </ModalFooterBtnPrimary>
        </ModalFooter>
      </ModalContainer>
      <VisuallyHidden tabIndex='0' role='link'>
        Focus sentinel
      </VisuallyHidden>
    </>
  );

  return (
    <CDSModal
      className='cds--modal-tall cds--modal--danger'
      role='presentation'
    >
      {/* Non-translatable: Focus-wrap */}
      <VisuallyHidden tabIndex='0' role='link'>
        Focus sentinel
      </VisuallyHidden>
      {modalBody}
    </CDSModal>
  );
};

Modal.defaultProps = {
  onSubmit: null,
  onCancel: null,
  unmount: null,
  confirmMessage: null,
};

Modal.propTypes = {
  onSubmit: func,
  onCancel: func,
  unmount: func,
  confirmMessage: string,
};

export default Modal;
