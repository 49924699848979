import { useState, useCallback, useContext, useEffect } from 'react';
import { string, func, number, shape, bool } from 'prop-types';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import DataSources from '../../DataSources';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import { useEnableFeature } from '../../utils/featureHooks';

const display = {
  title: 'Danger Zone',
  deleteDataSource: 'Delete Data Source Configuration',
  deleteDataSourceButton: 'Delete',
  deleteClusterConfirm:
    'This action will delete the data source configuration information.',
  cancelButton: 'Cancel',
};

const DangerZone = ({
  navTarget,
  dataSourceId,
  dataSource,
  deleteDataSource,
  actionStatus,
  usePop,
}) => {
  const enableDelete = useEnableFeature({
    requireValidSubscription: false,
    requireActiveComputePlane: true,
  });

  const actionInProgress = useContext(ActionInProgressStateContext);
  const setActionInProgress = useContext(ActionInProgressSetStateContext);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const onDelete = useCallback(() => {
    setActionInProgress(true);
    setConfirmDelete(true);
  }, [setActionInProgress]);

  const onConfirmDelete = useCallback(() => {
    const actionId = Date.now();
    setConfirmDelete(actionId);
    deleteDataSource(dataSourceId, actionId);
    setActionInProgress(false);
  }, [dataSourceId, deleteDataSource, setActionInProgress]);

  const onCancel = useCallback(() => {
    setActionInProgress(false);
    setConfirmDelete(false);
  }, [setActionInProgress]);

  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === confirmDelete
    ) {
      setConfirmDelete(false);
    }
  }, [actionStatus, confirmDelete, usePop]);

  const showActionStatusError =
    actionStatus && actionStatus.error && actionStatus.name === 'delete';

  return (
    <Page.BoxNavTarget navTarget={navTarget} error>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      {showActionStatusError && (
        <Page.InformationBanner>
          <Banner scrollIntoView title={actionStatus.error} />
        </Page.InformationBanner>
      )}
      <Page.DangerZone>
        {confirmDelete !== true && (
          <>
            <div>
              <h3>{display.deleteDataSource}</h3>
            </div>
            <div>
              <Form.ErrorButton
                label={display.deleteDataSourceButton}
                onClick={onDelete}
                disabled={
                  dataSource.isAttached ||
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress) ||
                  !enableDelete ||
                  dataSource.status ===
                    DataSources.constants.status.secret_updating ||
                  dataSource.status ===
                    DataSources.constants.status.secret_deleting
                }
                showLoading={
                  actionStatus &&
                  actionStatus.inProgress &&
                  actionStatus.actionId === confirmDelete
                }
              />
            </div>
          </>
        )}
        {confirmDelete === true && (
          <Page.ConfirmDanger>
            <Banner title={display.deleteClusterConfirm}>
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={onCancel}
                  label={display.cancelButton}
                />
                <Form.ErrorButton
                  onClick={onConfirmDelete}
                  label={display.deleteDataSourceButton}
                />
              </div>
            </Banner>
          </Page.ConfirmDanger>
        )}
      </Page.DangerZone>
    </Page.BoxNavTarget>
  );
};

DangerZone.defaultProps = {
  actionStatus: undefined,
  usePop: true,
};

DangerZone.propTypes = {
  navTarget: string.isRequired,
  dataSourceId: string.isRequired,
  dataSource: shape({
    isAttached: bool.isRequired,
  }).isRequired,
  deleteDataSource: func.isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    inProgress: bool.isRequired,
  }),
  usePop: bool,
};

export default DangerZone;
