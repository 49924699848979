import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { arrayOf, bool, shape, string } from 'prop-types';
import Form from '../../Form';
import DataSources from '../../DataSources';
import {
  awsAccessKeyRegex,
  awsSecretRegex,
  elasticSearchHostnameRegex,
} from '../../../utils/regex';
import { required, pattern } from '../utils';
import Help from './ElasticsearchConnectionDetailsHelp';

const display = {
  formContentHeader: 'Connection Configuration',
  securityContentHeader: 'Security',
  hostLabel: 'Endpoint',
  hostRequiredError: 'Endpoint is required',
  enableTLSEncryptionLabel: 'Enable TLS encryption',
  regionLabel: 'AWS Region',
  regionRequiredError: 'AWS Region is required',
  credentialTypeLabel: 'Elasticsearch Credentials',
  accessTypeIAMRoleLabel: 'IAM role',
  accessTypeAccessKeyLabel: 'Access key',
  roleLabel: 'Role ARN',
  roleRequiredError: 'Role ARN is required',
  accessKeyLabel: 'Access Key ID',
  accessKeyRequiredError: 'Access key ID is required',
  accessKeyInvalidError: 'Access key ID is invalid',
  secretLabel: 'Secret Access Key',
  secretRequiredError: 'Secret access key is required',
  secretInvalidError: 'Secret access key is invalid',
  endpointURLError: 'Endpoint must be in the format: https://[hostname]',
};

const type = DataSources.constants.dataSourceTypes.elasticsearch;

const ElasticsearchConnectionDetails = ({
  disabled,
  active,
  awsRegions,
  dataSource,
}) => {
  const { getValues, clearErrors } = useFormContext();

  useEffect(() => {
    if (!active) {
      clearErrors('elasticsearch');
    }
  }, [active, clearErrors]);

  return (
    <>
      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.formContentHeader}</h3>
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.TextInput
          name='elasticsearch.host'
          label={display.hostLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.metadata.host}
          validationRules={{
            validate: required(getValues, type, display.hostRequiredError),
            pattern: {
              value: elasticSearchHostnameRegex,
              message: display.endpointURLError,
            },
          }}
          fieldHelp={Help.Host}
        />
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.securityContentHeader}</h3>
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.CheckboxInput
          name='elasticsearch.tls.enabled'
          label={display.enableTLSEncryptionLabel}
          defaultChecked={
            dataSource &&
            dataSource.connection.elasticsearch.tls.enabled === false
              ? undefined
              : true
          }
          fieldHelp={Help.TLSEnabled}
        />
        <Form.SelectInput
          name='elasticsearch.aws.region'
          label={display.regionLabel}
          disabled={disabled}
          defaultValue={
            dataSource && dataSource.connection.elasticsearch.aws.region
          }
          validationRule={{
            validate: required(getValues, type, display.regionRequiredError),
          }}
          items={awsRegions}
          fieldHelp={Help.AWSRegion}
        />
        <Form.TextInput
          name='elasticsearch.aws.accessKey'
          label={display.accessKeyLabel}
          disabled={disabled}
          defaultValue={
            dataSource && dataSource.connection.elasticsearch.aws['access-key']
          }
          validationRules={{
            validate: required(
              getValues,
              type,
              display.accessKeyRequiredError,
              pattern(awsAccessKeyRegex, display.accessKeyInvalidError),
            ),
          }}
          fieldHelp={Help.AWSAccessKey}
        />
        <Form.PasswordInput
          name='elasticsearch.aws.secretKey'
          label={display.secretLabel}
          disabled={disabled}
          validationRules={{
            validate: required(
              getValues,
              type,
              display.secretRequiredError,
              pattern(awsSecretRegex, display.secretInvalidError),
            ),
          }}
          fieldHelp={Help.AWSSecretKey}
        />
      </div>
    </>
  );
};

ElasticsearchConnectionDetails.defaultProps = {
  disabled: false,
  dataSource: null,
};

ElasticsearchConnectionDetails.propTypes = {
  disabled: bool,
  active: bool.isRequired,
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  dataSource: DataSources.propTypes.DataSource,
};

export default ElasticsearchConnectionDetails;
