import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Your compute plane is being destroyed</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>Wait for the process to be completed.</Help.ListItem>
        <Help.ListItem>
          If there are errors, resolve the error and then click the Try Again.
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>This deprovisioning takes 20-30 minutes to complete.</Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
