import {
  call,
  put,
  putResolve,
  takeEvery,
  all,
  race,
  take,
  select,
} from 'redux-saga/effects';
import { fromWebToken } from '@aws-sdk/credential-providers';
import { buildUrl, paramsToObject } from '../utils/buildUrl';
import * as environment from '../utils/environment';
import telemetry from '../telemetry';
import * as routerActions from '../router/actions';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as selectors from './selectors';
import * as utils from './utils';

function* initAuth() {
  yield putResolve(routerActions.initSearchParams());
  yield put(actions.getAuth());
  const result = yield race({
    success: take(actionTypes.getAuth.success),
    failure: take(actionTypes.getAuth.failure),
  });

  if (result.success) {
    yield put(actions.signIn());
  }

  yield put(actions.finishedInitialCheck());
}

function* refreshToken() {
  const currentTenantId = yield select(selectors.getTenantId);

  yield put(actions.getAuth());

  const result = yield race({
    success: take(actionTypes.getAuth.success),
    failure: take(actionTypes.getAuth.failure),
  });

  if (result.failure) {
    yield put(actions.signOut());
    return;
  }

  const updatedTenantId = yield select(selectors.getTenantId);
  if (currentTenantId !== updatedTenantId) {
    yield put(actions.signOut(true));
  }
}

function* signOut(action) {
  yield call(telemetry.clearUser);

  if (action.payload.changeTenant) {
    yield call(initAuth);
  }
}

export function* getAWSCredentials() {
  yield put(actions.getCognitoToken());

  const result = yield race({
    success: take(actionTypes.getCognitoToken.success),
    failure: take(actionTypes.getCognitoToken.failure),
  });

  if (result.failure) {
    return null;
  }

  const { token, identityPoolId } = result.success.payload.apiResult.body;

  const region = identityPoolId.split(':')[0];

  const credentials = yield call(fromWebToken, {
    roleArn: process.env.REACT_APP_COGNITO_IDENTITY_IAM_ROLE,
    webIdentityToken: token,
  });

  return { region, credentials };
}

function* devSignIn(action) {
  const { crn, location, navigate } = action.payload;
  const newCRNCookie = `ibmlh.dev.crn=${crn};path=/;secure;max-age=31536000`;
  document.cookie = newCRNCookie;

  const searchParams = new URLSearchParams(location.search);
  const crnParam = searchParams.get('crn');
  if (crnParam !== crn) {
    if (!crnParam) {
      searchParams.append('crn', crn);
    } else {
      searchParams.set('crn', crn);
    }

    const uri = buildUrl({
      path: location?.pathname,
      queryParams: paramsToObject(searchParams.entries()),
      relative: true,
    });
    navigate(uri);
  }

  yield call(initAuth);
}

function* productionSignIn(action) {
  const uri = yield utils.getLoginUri(action.payload.location);
  window.location.href = uri;
}

export default function* authRootSaga() {
  yield call(initAuth);
  const sagas = [
    takeEvery(actionTypes.signOut, signOut),
    takeEvery(actionTypes.startRefreshToken, refreshToken),
  ];
  if (environment.isLocalUI()) {
    sagas.push(takeEvery('SIGNIN_SUBMIT', devSignIn));
  }

  if (environment.isStagingUI() || environment.isProductionUI()) {
    sagas.push(takeEvery('SIGNIN_SUBMIT', productionSignIn));
  }
  yield all(sagas);
}
