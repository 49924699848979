import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import * as actionTypes from './actionTypes';

const initialState = {
  identityProviders: null,
  loadingInProgress: false,
  removeInProgress: false,
  error: null,
};

const onNavigation = (draft) => {
  draft.loadingInProgress = false;
  draft.error = null;
};

const get = (draft, action) => {
  if (!draft.identityProviders) {
    draft.identityProviders = new Map();
  }
  const data = action.payload.apiResult.body;
  draft.identityProviders.set(action.payload.params.identityProviderId, data);
};

const getFailure = (draft, action) => {
  if (!draft.identityProviders) {
    draft.identityProviders = new Map();
  }
  draft.identityProviders.set(
    action.payload.params.identityProviderId,
    'not found',
  );
};

const getStart = (draft) => {
  draft.loadingInProgress = true;
};

const getAllFailure = (draft, action) => {
  draft.loadingInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
};

const getPartial = (draft, action) => {
  if (!draft.identityProviders) {
    draft.identityProviders = new Map();
  }

  const { results } = action.payload.apiResult.body;
  for (let i = 0; i < results.length; i += 1) {
    draft.identityProviders.set(results[i].identityProviderId, results[i]);
  }
};

const getAll = (draft, action) => {
  if (draft.identityProviders) {
    draft.identityProviders.clear();
  }
  getPartial(draft, action);
  draft.loadingInProgress = false;
};

const removeStart = (draft) => {
  draft.removeInProgress = true;
  draft.error = null;
};

const removeFailure = (draft, action) => {
  draft.removeInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
};

const removeSuccess = (draft) => {
  draft.removeInProgress = false;
  draft.error = null;
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.get.success]: get,
  [actionTypes.get.failure]: getFailure,
  [actionTypes.getAll.request]: getStart,
  [actionTypes.getAll.success]: getAll,
  [actionTypes.getAll.failure]: getAllFailure,
  [actionTypes.remove.request]: removeStart,
  [actionTypes.remove.success]: removeSuccess,
  [actionTypes.remove.failure]: removeFailure,
  [auth.actionTypes.signOut]: signOut,
};

export default makeReducer(initialState, actionReducers);
