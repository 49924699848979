import {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useContext,
} from 'react';
import { node, arrayOf, string } from 'prop-types';

const InPageNavigationStateContext = createContext();
const InPageNavigationDispatchContext = createContext();

const useInPageNavigationState = (ignoreNull = false) => {
  const context = useContext(InPageNavigationStateContext);
  if (!ignoreNull && context === undefined) {
    throw new Error(
      'useInPageNavigationState must be used within InPageNavigation',
    );
  }
  return context;
};

const useInPageNavigationDispatch = (ignoreNull = false) => {
  const context = useContext(InPageNavigationDispatchContext);
  if (!ignoreNull && context === undefined) {
    throw new Error(
      'useInPageNavigationState must be used within InPageNavigation',
    );
  }
  return context;
};

const createIntersectionObserver = (state) => {
  if (state.observer) {
    state.observer.disconnect();
  }

  if (state.scrollRef && state.handleIntersection) {
    const scrollHeight = state.scrollRef.getBoundingClientRect().height;
    const marginBottom = (scrollHeight - 45) * -1;
    const observer = new IntersectionObserver(state.handleIntersection, {
      root: state.scrollRef,
      rootMargin: `-44px 0px ${marginBottom}px 0px`,
    });

    Object.values(state.navRefs).forEach((navRef) => {
      observer.observe(navRef);
    });

    return observer;
  }
  return null;
};

const reducer = (state, action) => {
  let newState = state;
  switch (action.type) {
    case 'setActiveNav': {
      if (state.activeNav !== action.payload.navTarget) {
        newState = { ...state, activeNav: action.payload.navTarget };
      }
      break;
    }
    case 'setNavRef': {
      const { navTarget, navRef } = action.payload;
      if (state.navRefs[navTarget] && state.observer) {
        state.observer.unobserve(state.navRefs[navTarget]);
      }

      if (
        state.lastTab === navTarget &&
        state.navRefs[navTarget] &&
        state.resizeObserver
      ) {
        state.resizeObserver.unobserve(state.navRefs[navTarget]);
      }

      const newNavRefs = { ...state.navRefs };
      newNavRefs[navTarget] = navRef;
      if (state.observer) {
        state.observer.observe(navRef);
      }
      if (state.lastTab === navTarget && state.resizeObserver) {
        state.resizeObserver.observe(navRef);
      }
      newState = {
        ...state,
        navRefs: newNavRefs,
      };

      break;
    }
    case 'setHandleIntersection': {
      newState = {
        ...state,
        handleIntersection: action.payload.handleIntersection,
      };

      const observer = createIntersectionObserver(newState);
      if (observer) {
        newState.observer = observer;
        newState.intersectionObserverRef.current = observer;
      }

      break;
    }
    case 'setScrollRef': {
      const { scrollRef } = action.payload;
      if (state.scrollRef && state.resizeObserver) {
        state.resizeObserver.unobserve(state.scrollRef);
      }

      if (state.resizeObserver) {
        state.resizeObserver.observe(scrollRef);
      }
      newState = {
        ...state,
        scrollRef,
      };

      const observer = createIntersectionObserver(newState);
      if (observer) {
        newState.observer = observer;
        newState.intersectionObserverRef.current = observer;
      }

      break;
    }
    case 'scrollIntoView': {
      const { navTarget } = action.payload;
      const targetRef = state.navRefs[navTarget];

      if (targetRef) {
        targetRef.previousSibling.scrollIntoView({
          behavior: 'smooth',
        });
      }
      break;
    }
    case 'setResizeObserver': {
      if (state.resizeObserver) {
        state.resizeObserver.disconnect();
      }

      if (state.scrollRef) {
        action.payload.observer.observe(state.scrollRef);
      }
      if (state.navRefs[state.lastTab]) {
        action.payload.observer.observe(state.navRefs[state.lastTab]);
      }

      newState = { ...state, resizeObserver: action.payload.observer };
      break;
    }
    case 'updateHeight': {
      const { height, isNavTarget } = action.payload;
      newState = { ...state };
      if (isNavTarget) {
        newState.lastNavHeight = height;
      } else {
        newState.scrollViewPortHeight = height;
      }

      if (
        newState.lastNavHeight &&
        newState.scrollViewPortHeight &&
        newState.scrollViewPortHeight > newState.lastNavHeight
      ) {
        newState.scrollPaddingBottom =
          newState.scrollViewPortHeight - newState.lastNavHeight;
      } else {
        newState.scrollPaddingBottom = 0;
      }
      break;
    }
    default:
      break;
  }
  // console.log({ type: action.type, payload: action.payload, state, newState });
  return newState;
};

const InPageNavigation = ({ navTabs, children }) => {
  const intersectionObserverRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, {
    activeNav: navTabs[0],
    navTabs,
    lastTab: navTabs[navTabs.length - 1],
    navRefs: {},
    intersectionObserverRef,
  });

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          dispatch({
            type: 'setActiveNav',
            payload: {
              navTarget: entry.target.dataset.navTarget,
            },
          });
        }
      });
    };
    dispatch({
      type: 'setHandleIntersection',
      payload: { handleIntersection },
    });

    const handleResize = (entries) => {
      entries.forEach((entry) => {
        const { height } = entry.contentRect;
        const isNavTarget =
          entry.target.dataset && entry.target.dataset.navTarget;
        dispatch({ type: 'updateHeight', payload: { height, isNavTarget } });
      });
    };

    const observer = new ResizeObserver(handleResize);
    dispatch({ type: 'setResizeObserver', payload: { observer } });

    return () => {
      observer.disconnect();
      if (intersectionObserverRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        intersectionObserverRef.current.disconnect();
      }
    };
  }, []);

  return (
    <InPageNavigationStateContext.Provider value={state}>
      <InPageNavigationDispatchContext.Provider value={dispatch}>
        {children}
      </InPageNavigationDispatchContext.Provider>
    </InPageNavigationStateContext.Provider>
  );
};

InPageNavigation.propTypes = {
  navTabs: arrayOf(string).isRequired,
  children: node.isRequired,
};

export default InPageNavigation;
export { useInPageNavigationState, useInPageNavigationDispatch };
