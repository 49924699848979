import { OptionalBadge } from '../../Badge';

const display = {
  PrestoCPP: 'Presto C++',
};

const PrestoCPPBadge = () => {
  return <OptionalBadge>{display.PrestoCPP}</OptionalBadge>;
};

export default PrestoCPPBadge;
