export const mapTypeName = (ds) => {
  const types = {
    rangerhive: 'Apache Ranger',
    lakeformation: 'Lake Formation',
  };

  let typeName = null;
  if (ds?.authorizationServiceType === 'rangerhive') {
    typeName = types.rangerhive;
  } else if (ds?.connection?.hive?.security === 'lake-formation') {
    typeName = types.lakeformation;
  }

  return typeName;
};
