import { useMemo } from 'react';
import { string } from 'prop-types';
import Page from '../../Page';
import Table from '../../Table';
import DataSources from '../../DataSources';

const display = {
  title: 'Available Presto Users',
  usernameColumnLabel: 'Name',
  iamRoleLabel: 'IAM Role ARN',
};

const dataKeyGenerator = (prestoUserMapping) => {
  return prestoUserMapping.prestoUserId;
};

const baseColumns = [
  {
    name: display.usernameColumnLabel,
    selector: (prestoUserMapping) => {
      return prestoUserMapping.username;
    },
    cellWidth: '2fr',
  },
];

const dataSourceColumns = {
  glue: [
    {
      name: display.iamRoleLabel,
      selector: (prestoUserMapping) => {
        return prestoUserMapping.configuration.iamRoleMapping;
      },
      cellWidth: '6fr',
    },
  ],
};

const PrestoUsers = ({ dataSourceType, prestoUserMappings }) => {
  const columns = useMemo(() => {
    return baseColumns.concat(dataSourceColumns[dataSourceType]);
  }, [dataSourceType]);
  return (
    <Page.Box>
      <Page.TableBoxHeader>
        <h2>{display.title}</h2>
      </Page.TableBoxHeader>
      <Table.Table
        data={prestoUserMappings}
        columns={columns}
        dataKeyGenerator={dataKeyGenerator}
      />
    </Page.Box>
  );
};

PrestoUsers.propTypes = {
  prestoUserMappings: DataSources.propTypes.PrestoUserMappings.isRequired,
  dataSourceType: string.isRequired,
};

export default PrestoUsers;
