const toReplace = 'http://replacethis';

export function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export function buildUrl({ baseUrl, path, queryParams, relative }) {
  const url = relative ? new URL(path, toReplace) : new URL(path, baseUrl);

  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      url.searchParams.set(key, value);
    }
  }

  if (relative) {
    const { href } = url;
    return href.replace(toReplace, '');
  }
  return url.href;
}
