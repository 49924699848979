import Table from './Table';
import { useAlertIdStateContext, useAlertIdDispatchContext } from './context';
import {
  ButtonCellDiv,
  DataCellDiv,
  DataCellVerticalDiv,
  DataCenteredCellDiv,
  ImageCellDiv,
} from './styledComponents';

const module = {
  Table,
  ButtonCell: ButtonCellDiv,
  DataCell: DataCellDiv,
  DataCellVertical: DataCellVerticalDiv,
  DataCenteredCell: DataCenteredCellDiv,
  ImageCell: ImageCellDiv,
  useAlertIdDispatchContext,
  useAlertIdStateContext,
};

export default module;
