/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useMemo } from 'react';
import get from 'lodash.get';
import { useFormContext } from 'react-hook-form';
import {
  string,
  bool,
  object,
  number,
  oneOfType,
  elementType,
} from 'prop-types';
import Label from './Label';
import { pasteTrimText } from './utils';
import {
  InputErrorDiv,
  TextInput,
  HorizontalInputDiv,
  SecondLabelDiv,
  TextInputDiv,
} from './styledComponents';

const validateInt = (event) => {
  const code = event.keyCode || event.charCode;
  if (code < 48 || code > 57) {
    event.stopPropagation();
    event.preventDefault();
    return false;
  }
  return true;
};

const InnerCompleteTextInput = ({
  name,
  type,
  label,
  secondLabel,
  disabled,
  optional,
  defaultValue,
  validationRules,
  autoComplete,
  isPrivate,
  min,
  max,
  fieldHelp,
  hidden,
  trimPasted,
}) => {
  const {
    register: formRegister,
    formState: { errors: formErrors },
    getFieldId,
    setValue,
    formState,
  } = useFormContext();

  const isFormSubmitted = formState.isSubmitted;
  const inputId = getFieldId(name);

  const error = get(formErrors, name);

  const className = useMemo(() => {
    if (!error && !secondLabel) {
      return undefined;
    }

    let c = '';
    if (error) {
      c = `${c} error`;
    }
    if (secondLabel) {
      c = `${c} secondLabel`;
    }
    return c;
  }, [error, secondLabel]);

  const wrapperClassName = useMemo(() => {
    if (!secondLabel && !hidden) {
      return undefined;
    }

    let c = '';
    if (secondLabel) {
      c = `${c} secondLabel`;
    }
    if (hidden) {
      c = `${c} hidden`;
    }
    return c;
  }, [secondLabel, hidden]);

  const handlePaste = useCallback(
    (event) => {
      if (!event.target.disabled) {
        const pastedInput =
          (event &&
            event.clipboardData &&
            event.clipboardData.getData('Text')) ||
          (window &&
            window.clipboardData &&
            window.clipboardData.getData('Text'));

        if (pastedInput && typeof pastedInput === 'string') {
          const input = event.target;
          const text = input.value;
          const start = input.selectionStart;
          const end = input.selectionEnd;
          const { textUpdated, position } = pasteTrimText(
            pastedInput,
            text,
            start,
            end,
          );

          setValue(name, textUpdated, {
            shouldDirty: true,
            shouldValidate: isFormSubmitted,
          });

          input.focus();
          input.setSelectionRange(position, position);
          event.preventDefault();
        }
      }
    },
    [isFormSubmitted, name, setValue],
  );

  return (
    <>
      {!hidden && label && (
        <Label
          inputId={inputId}
          label={label}
          optional={optional}
          fieldHelp={fieldHelp}
        />
      )}
      <TextInputDiv className={wrapperClassName}>
        <TextInput
          type={type === 'integer' ? 'text' : type}
          disabled={disabled}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          id={inputId}
          min={min}
          max={max}
          className={className}
          data-private={isPrivate ? true : undefined}
          aria-invalid={error ? 'true' : 'false'}
          {...formRegister(name, validationRules)}
          onPaste={!disabled && trimPasted ? handlePaste : undefined}
          onKeyPressCapture={type === 'integer' ? validateInt : undefined}
        />
        {secondLabel && <SecondLabelDiv>{secondLabel}</SecondLabelDiv>}
      </TextInputDiv>
      {error && !hidden && (
        <InputErrorDiv role='alert'>{error.message}</InputErrorDiv>
      )}
    </>
  );
};

InnerCompleteTextInput.defaultProps = {
  type: 'text',
  label: undefined,
  secondLabel: undefined,
  disabled: false,
  optional: false,
  defaultValue: undefined,
  validationRules: undefined,
  autoComplete: 'off',
  isPrivate: false,
  min: undefined,
  max: undefined,
  fieldHelp: undefined,
  hidden: false,
  trimPasted: false,
};

InnerCompleteTextInput.propTypes = {
  name: string.isRequired,
  type: string,
  label: string,
  secondLabel: string,
  disabled: bool,
  optional: bool,
  defaultValue: oneOfType([string, number]),
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  autoComplete: string,
  isPrivate: bool,
  min: number,
  max: number,
  fieldHelp: elementType,
  hidden: bool,
  trimPasted: bool,
};

const CompleteTextInput = ({ horizontal, ...props }) => {
  if (horizontal) {
    return (
      <HorizontalInputDiv>
        <InnerCompleteTextInput {...props} />
      </HorizontalInputDiv>
    );
  }

  return <InnerCompleteTextInput {...props} />;
};

CompleteTextInput.defaultProps = {
  type: 'text',
  label: undefined,
  secondLabel: undefined,
  disabled: false,
  optional: false,
  defaultValue: undefined,
  validationRules: undefined,
  autoComplete: 'off',
  isPrivate: false,
  min: undefined,
  max: undefined,
  fieldHelp: undefined,
  horizontal: false,
  hidden: false,
};

CompleteTextInput.propTypes = {
  name: string.isRequired,
  type: string,
  label: string,
  secondLabel: string,
  disabled: bool,
  optional: bool,
  defaultValue: oneOfType([string, number]),
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  autoComplete: string,
  isPrivate: bool,
  min: number,
  max: number,
  fieldHelp: elementType,
  horizontal: bool,
  hidden: bool,
};

export default CompleteTextInput;
