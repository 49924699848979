import { call } from 'redux-saga/effects';
import { KMS } from '@aws-sdk/client-kms';
import { Buffer } from 'buffer';
import logger from './logger';
import { getAWSCredentials } from '../auth/saga';

function* encryptData(data, keyId, encryptionAlgorithm) {
  try {
    const awsCredentials = yield call(getAWSCredentials);
    if (!awsCredentials) {
      throw Error('Could not get AWS credentials');
    }

    const params = {
      KeyId: keyId,
      Plaintext: Buffer.from(data),
      EncryptionAlgorithm: encryptionAlgorithm,
    };
    const kms = new KMS(awsCredentials);
    const encryptedResult = yield call([kms, kms.encrypt], params);
    logger.info(encryptedResult);
    const encryptedData = {
      keyId: encryptedResult.KeyId,
      encryptionAlgorithm: encryptedResult.EncryptionAlgorithm,
      ciphertextBlob: btoa(
        String.fromCharCode.apply(null, encryptedResult.CiphertextBlob),
      ),
    };
    return encryptedData;
  } catch (e) {
    logger.error(e);
    throw e;
  }
}

export default encryptData;
