import { useCallback } from 'react';
import { bool, object } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Form from '../../Form';
import Help from './IngressHelp';
import validatePasswordComplexity from '../../utils/validatePasswordComplexity';
import Data from '../../Data';

const display = {
  formContentHeader: 'Ingress',
  prometheusWebsiteLabel: 'Prometheus Website',
  prometheusWebsiteRequiredError: 'required',
  userLabel: 'User Name',
  passwordLabel: 'Password',
  enableLabel: 'Enabled',
  disableLabel: 'Disabled',
  enabledValue: 'enabled',
  disabledValue: 'disabled',
  usernameRequiredError: 'Username is required',
  usernameMaxLengthError: 'Username cannot be longer than 255 characters',
  usernameInvalidCharacters:
    'Username can only consist of alphanumeric characters, dash(-), underscore(_), period(.), and tilde(~)',
  passwordRequiredError: 'Password is required',
  minLengthError: 'Password must be at least 8 characters',
  maxLengthError: 'Password cannot be longer than 64 characters',
  // ... other display constants
};

const Ingress = ({ disabled, prometheus }) => {
  // Render the form inputs
  const { watch, getValues } = useFormContext();
  const website = watch('prometheus.website', display.enabledValue);
  const validateComplexity = useCallback(
    (value) => {
      const username = getValues('username');
      return validatePasswordComplexity(value, username);
    },
    [getValues],
  );
  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
      </div>
      <div>
        <Form.RadioInputs
          name='prometheus.website'
          label={display.prometheusWebsiteLabel}
          disabled={disabled}
          defaultValue={
            prometheus?.ingress ? display.enabledValue : display.disabledValue
          }
          validationRules={{
            required: display.prometheusWebsiteRequiredError, // Ensure this message is defined in the display object
          }}
          fieldHelp={Help.website}
          values={[
            {
              label: display.disableLabel,
              value: display.disabledValue,
            },
            {
              label: display.enableLabel,
              value: display.enabledValue,
            },
          ]}
        />
        {website === display.enabledValue && (
          <>
            <Form.TextInput
              name='prometheus.user'
              type='string'
              label={display.userLabel}
              disabled={disabled}
              validationRules={{
                required: display.usernameRequiredError,
                maxLength: {
                  value: 255,
                  message: display.usernameMaxLengthError,
                },
                pattern: {
                  value: /^[a-zA-Z0-9\-_.~]*$/,
                  message: display.usernameInvalidCharacters,
                },
              }}
              fieldHelp={Help.username}
              defaultValue={prometheus?.ingress?.user}
            />
            <Form.PasswordInput
              name='prometheus.password'
              label={display.passwordLabel}
              disabled={disabled}
              validationRules={{
                required: display.passwordRequiredError,
                minLength: { value: 8, message: display.minLengthError },
                maxLength: { value: 64, message: display.maxLengthError },
                validate: { validateComplexity },
              }}
              fieldHelp={Help.password}
              defaultValue={undefined}
            />
          </>
        )}
        {website === display.disabledValue && (
          <>
            <Data.DataField label={display.userLabel} disabled />
            <Data.DataField label={display.passwordLabel} disabled />
          </>
        )}
      </div>
    </>
  );
};

Ingress.defaultProps = {
  disabled: false,
  prometheus: null,
};

Ingress.propTypes = {
  disabled: bool,
  // eslint-disable-next-line react/forbid-prop-types
  prometheus: object,
};

export default Ingress;
