import { node } from 'prop-types';
import { SuccessBadgeSpan } from './styledComponents';

const SuccessBadge = ({ children }) => {
  return <SuccessBadgeSpan>{children || 'Success'}</SuccessBadgeSpan>;
};

SuccessBadge.defaultProps = {
  children: null,
};

SuccessBadge.propTypes = {
  children: node,
};

export default SuccessBadge;
