import get from 'lodash.get';
import { useFormContext } from 'react-hook-form';
import { string, bool, elementType, object, number } from 'prop-types';
import Help from '../Help';
import {
  InputErrorDiv,
  CheckboxLabelDiv,
  CheckboxLabel,
  CheckboxInput,
  HorizontalInputDiv,
} from './styledComponents';

const InnerCompleteCheckboxInput = ({
  name,
  label,
  disabled,
  defaultChecked,
  validationRules,
  isPrivate,
  fieldHelp,
  mediumFont,
  compact,
  hideValidationError,
}) => {
  const {
    register: formRegister,
    formState: { errors: formErrors },
    getFieldId,
  } = useFormContext();
  const inputId = getFieldId(name);

  const error = get(formErrors, name);

  return (
    <>
      <CheckboxLabelDiv
        className={`${fieldHelp ? 'hasFieldHelp' : ''} ${
          compact ? 'compact' : ''
        }`}
      >
        <CheckboxInput
          type='checkbox'
          disabled={disabled}
          defaultChecked={defaultChecked}
          id={inputId}
          className={error ? 'error' : undefined}
          data-private={isPrivate ? true : undefined}
          aria-invalid={error ? 'true' : 'false'}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formRegister(name, validationRules)}
        />
        <CheckboxLabel
          htmlFor={inputId}
          className={`${label ? 'hasLabel' : ''} ${
            mediumFont ? 'mediumFont' : ''
          }`}
        >
          {label}
        </CheckboxLabel>
        {fieldHelp && <Help.Button fieldHelp={fieldHelp} />}
      </CheckboxLabelDiv>
      {!hideValidationError && error && (
        <InputErrorDiv role='alert'>{error.message}</InputErrorDiv>
      )}
    </>
  );
};

InnerCompleteCheckboxInput.defaultProps = {
  disabled: false,
  defaultChecked: false,
  validationRules: undefined,
  isPrivate: false,
  fieldHelp: undefined,
  mediumFont: false,
  compact: false,
  label: undefined,
  hideValidationError: false,
};

InnerCompleteCheckboxInput.propTypes = {
  name: string.isRequired,
  label: string,
  disabled: bool,
  defaultChecked: bool,
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  isPrivate: bool,
  fieldHelp: elementType,
  mediumFont: bool,
  compact: bool,
  hideValidationError: bool,
};

const CompleteCheckboxInput = ({ horizontal, ...props }) => {
  if (horizontal) {
    return (
      <HorizontalInputDiv>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <InnerCompleteCheckboxInput {...props} />
      </HorizontalInputDiv>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <InnerCompleteCheckboxInput {...props} />;
};

CompleteCheckboxInput.defaultProps = {
  type: 'text',
  disabled: false,
  defaultChecked: undefined,
  validationRules: undefined,
  autoComplete: 'off',
  isPrivate: false,
  min: undefined,
  max: undefined,
  fieldHelp: undefined,
  horizontal: false,
  label: undefined,
  hideValidationError: false,
};

CompleteCheckboxInput.propTypes = {
  name: string.isRequired,
  type: string,
  label: string,
  disabled: bool,
  defaultChecked: bool,
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  autoComplete: string,
  isPrivate: bool,
  min: number,
  max: number,
  fieldHelp: elementType,
  horizontal: bool,
  hideValidationError: bool,
};

export default CompleteCheckboxInput;
