import { useEffect, useState } from 'react';
import { arrayOf, elementType, string } from 'prop-types';
import { useFormWizard } from './useFormWizard';
import { formWizardStep } from './propTypes';
import Help from '../Help';
import Page from '../Page';

const InnerFormWizard = ({ steps, help, initialStep }) => {
  const [error, setError] = useState(null); // State to store error message
  const formWizardState = useFormWizard();
  const helpDispatch = Help.useHelpNavigationDispatch();

  useEffect(() => {
    try {
      formWizardState.initializeFormWizard(steps, initialStep);
    } catch (err) {
      setError(err.message);
    }
    // formWizardState.initializeFormWizard(steps, initialStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { activeStepId } = formWizardState;
  // eslint-disable-next-line arrow-body-style
  const activeStep = steps.find((step) => step.id === activeStepId) ?? steps[0];
  useEffect(() => {
    return () => {
      helpDispatch(null);
    };
  }, [helpDispatch, activeStepId]);

  return (
    <Page.FormPage>
      {error && <div style={{ color: 'red' }}>{error}</div>}{' '}
      {/* Display error message */}
      <Page.FormColumn>
        <div className='step-content'>{activeStep.render()}</div>
      </Page.FormColumn>
      <Help.HelpColumn DefaultHelp={help} />
    </Page.FormPage>
  );
};

InnerFormWizard.defaultProps = {
  initialStep: null,
};

InnerFormWizard.propTypes = {
  help: elementType.isRequired,
  initialStep: string,
  steps: arrayOf(formWizardStep).isRequired,
};

export const FormWizard = ({ steps, help, initialStep }) => {
  return (
    <Help.HelpNavigation>
      <InnerFormWizard steps={steps} help={help} initialStep={initialStep} />
    </Help.HelpNavigation>
  );
};

FormWizard.defaultProps = {
  initialStep: null,
};

FormWizard.propTypes = {
  help: elementType.isRequired,
  initialStep: string,
  steps: arrayOf(formWizardStep).isRequired,
};
