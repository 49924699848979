import { string } from 'prop-types';
import Page from '../Page';
import NotFound from './components/NotFound';

const NotFoundPage = ({ code, title, description }) => {
  return (
    <Page.StandardLayout>
      <NotFound code={code} title={title} description={description} />
    </Page.StandardLayout>
  );
};

NotFoundPage.propTypes = {
  title: string,
  code: string,
  description: string,
};
NotFoundPage.defaultProps = {
  title: undefined,
  code: undefined,
  description: undefined,
};

export default NotFoundPage;
