import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const submit = (values) => {
  const body = {
    type: values.type,
    username: values.username,
    password: values.password,
    prestoUserId: values.prestoUserId,
  };

  return {
    type: actionTypes.submit,
    payload: {
      values: body,
    },
  };
};

export const patch = (prestoUserId, body) => {
  return createApiAction(
    actionTypes.patch,
    api.patch,
    true,
    { prestoUserId },
    body,
  );
};
