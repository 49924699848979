import { useCallback, useState } from 'react';
import { bool, func, string, elementType } from 'prop-types';
import Page from '../../../Page';
import Form from '../../../Form';
import Data from '../../../Data';
import IAMPolicy from '../../../IAMPolicy';
import * as Help from '../CreateComputePlaneHelp';

const display = {
  title: 'Step 3 of 8 - View IAM Policies for AWS Provisioning Role',
  policyDefinitionsHeader: 'IAM Policy Definitions',
  iamRoleCreationHeader: 'IAM Role Creation',
  awsPermissionBoundaryPolicyLabel: 'Permission Boundary AWS IAM Policy',
  awsCoreInfrastructurePolicyLabel: 'Core Infrastructure AWS IAM Policy',
  awsOperationsPolicyLabel: 'Operations AWS IAM Policy',
  awsRoleTrustRelationshipPolicyLabel: 'IAM Role Trust Relationship Policy',
  viewPolicy: 'View Policy',
  cloudFormationTemplateLabel: 'Provisioning Role CloudFormation Template',
  viewCloudFormationTemplate: 'View CloudFormation Template',
  terraformTemplateLabel: 'Provisioning Role Terraform',
  viewTerraformTemplate: 'View Terraform',
};

const PolicyField = ({ label, fieldHelp, policyType, setViewPolicy }) => {
  const handleClick = useCallback(() => {
    setViewPolicy(policyType);
  }, [setViewPolicy, policyType]);
  return (
    <>
      <Data.Label label={label} fieldHelp={fieldHelp} />
      <Data.ButtonGroup>
        <Form.TertiaryButton onClick={handleClick}>
          {display.viewPolicy}
        </Form.TertiaryButton>
      </Data.ButtonGroup>
    </>
  );
};

PolicyField.defaultProps = {
  fieldHelp: undefined,
};

PolicyField.propTypes = {
  fieldHelp: elementType,
  label: string.isRequired,
  policyType: string.isRequired,
  setViewPolicy: func.isRequired,
};

export const ReviewPolicies = ({ onBack, onNext, submitInProgress }) => {
  const [policyType, setViewPolicy] = useState(null);

  const handleCloseViewPolicy = useCallback(() => {
    setViewPolicy(null);
  }, [setViewPolicy]);

  if (policyType) {
    return (
      <IAMPolicy.EmbededContainer
        policyType={policyType}
        onClose={handleCloseViewPolicy}
      />
    );
  }

  return (
    <>
      <Page.Box>
        <Page.BoxHeader>
          <h2>{display.title}</h2>
        </Page.BoxHeader>
        <Page.FormContent rows={3}>
          <div>
            <h3>{display.policyDefinitionsHeader}</h3>
          </div>
          <div>
            <PolicyField
              label={display.awsPermissionBoundaryPolicyLabel}
              fieldHelp={Help.AWSPermissionBoundaryPolicy}
              policyType={IAMPolicy.constants.policyType.permissionBoundary}
              setViewPolicy={setViewPolicy}
            />
            <PolicyField
              label={display.awsCoreInfrastructurePolicyLabel}
              fieldHelp={Help.AWSCoreInfrastructurePolicy}
              policyType={IAMPolicy.constants.policyType.coreInfrastructure}
              setViewPolicy={setViewPolicy}
            />
            <PolicyField
              label={display.awsOperationsPolicyLabel}
              fieldHelp={Help.AWSOperationsPolicy}
              policyType={IAMPolicy.constants.policyType.operations}
              setViewPolicy={setViewPolicy}
            />
            <PolicyField
              label={display.awsRoleTrustRelationshipPolicyLabel}
              fieldHelp={Help.AWSTrustRelationshipPolicy}
              policyType={IAMPolicy.constants.policyType.trustRelationship}
              setViewPolicy={setViewPolicy}
            />
          </div>
          <div>
            <h3>{display.iamRoleCreationHeader}</h3>
          </div>
          <div>
            <PolicyField
              label={display.cloudFormationTemplateLabel}
              fieldHelp={Help.ProvisioningRoleCFTemplate}
              policyType={
                IAMPolicy.constants.policyType.provisioningRoleCFTemplate
              }
              setViewPolicy={setViewPolicy}
            />

            {/* TODO when we create the terraform template we can render this button */}
            {/* <Data.Label
              label={display.terraformTemplateLabel}
              fieldHelp={undefined}
            />
            <Data.ButtonGroup>
              <Form.TertiaryButton>
                {display.viewTerraformTemplate}
              </Form.TertiaryButton>
            </Data.ButtonGroup> */}
          </div>
        </Page.FormContent>
      </Page.Box>
      <Page.Buttons>
        <Form.SecondaryButton
          label='Back'
          onClick={onBack}
          disabled={submitInProgress}
          disabledOnErrors={false}
        />
        <Form.PrimaryButton
          label='Next'
          disabled={false}
          disabledOnErrors={false}
          onClick={onNext}
        />
      </Page.Buttons>
    </>
  );
};

ReviewPolicies.defaultProps = {
  submitInProgress: false,
};

ReviewPolicies.propTypes = {
  submitInProgress: bool,
  onBack: func.isRequired,
  onNext: func.isRequired,
};
