import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>How to change my Scaling Policy?</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Enter a <Help.Strong>target</Help.Strong> value
        </Help.ListItem>
        <Help.ListItem>
          Enter a <Help.Strong>Minimum</Help.Strong> and a
          <Help.Strong> Maximum Worker Node Count</Help.Strong>
        </Help.ListItem>
        <Help.ListItem>
          Enter the number of nodes by which you would like your cluster to
          scale out
        </Help.ListItem>
        <Help.ListItem>
          Enter the time window where the cluster needs to be below the target
          Queued Query count before starting to step down to the{' '}
          <Help.Strong>Minimum Worker Node Count</Help.Strong>
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        Your Presto cluster worker node count will increase by up to the
        specified scale out step size when the Queued Queries count of the
        cluster exceeds the target.
      </Help.P>
      <Help.P>
        When the cluster Queued Query count stays below the target past the
        specified time window, it will scale in by the{' '}
        <Help.Strong>Scaling Step Size</Help.Strong> until more tha target of
        the Queued Query count is utilized or it reaches the minimum worker node
        count.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = { PageHelp };

export default module;
