import { useEffect, useState, useCallback, useMemo } from 'react';
import { bool, func, string, shape, number } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Form from '../../Form';
import Page from '../../Page';
import PrestoClusters from '../../PrestoClusters';
import PrestoDebuggingForm from './PrestoDebuggingForm';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import useRouteBuilder from '../../../router/useRouteBuilder';

const PrestoDebugging = ({
  prestoCluster,
  prestoClusterId,
  notFound,
  actionStatus,
  loadPrestoCluster,
  triggerPrestoDebugging,
  usePop,
}) => {
  useEffect(() => {
    loadPrestoCluster(prestoClusterId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoClusterId]);

  const [actionInProgress, setActionInProgress] = useState(false);

  const triggerPrestoClusterDebugging = useCallback(
    (values) => {
      const submitId = Date.now();
      setActionInProgress(submitId);

      const k8NodeNamesArray = values.k8s.nodeNames
        ? values.k8s.nodeNames.split(',').map((name) => {
            return name.trim();
          })
        : [];

      const newBody = {
        k8NodeNames: k8NodeNamesArray,
        workflowName:
          values.forceHeapDumpGeneration === 'yes'
            ? 'heapDumpCollectTransfer'
            : 'heapDumpTransfer',
      };

      return triggerPrestoDebugging(prestoClusterId, newBody, submitId);
    },
    [triggerPrestoDebugging, prestoClusterId],
  );

  const submitSuccess = useMemo(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    ) {
      return actionStatus.success;
    }
    return false;
  }, [actionStatus, actionInProgress]);

  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!prestoCluster) {
    return <Page.Loading />;
  }

  return (
    <ActionInProgressStateContext.Provider value={actionInProgress}>
      <ActionInProgressSetStateContext.Provider value={setActionInProgress}>
        <Page.PageWithOverflow>
          <Form.Form
            name='trigger-presto-debugging'
            submitAction={triggerPrestoClusterDebugging}
          >
            <PrestoDebuggingForm
              prestoCluster={prestoCluster}
              actionStatus={actionStatus}
              prestoClusterId={prestoClusterId}
              submitSuccess={submitSuccess}
              usePop={usePop}
            />
          </Form.Form>
        </Page.PageWithOverflow>
      </ActionInProgressSetStateContext.Provider>
    </ActionInProgressStateContext.Provider>
  );
};

PrestoDebugging.defaultProps = {
  prestoCluster: null,
  notFound: false,
  actionStatus: undefined,
  usePop: true,
};

PrestoDebugging.propTypes = {
  prestoClusterId: string.isRequired,
  prestoCluster: PrestoClusters.propTypes.PrestoCluster,
  notFound: bool,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  loadPrestoCluster: func.isRequired,
  triggerPrestoDebugging: func.isRequired,
  usePop: bool,
};

export default PrestoDebugging;
