import { useEffect } from 'react';
import { bool, func } from 'prop-types';
import Loading from './Loading';
import Page from '../../Page';
import DataSources from '../../DataSources';
import Buckets from '../../Buckets';

const display = {
  header: 'Data Sources',
  dataSourcesHeader: 'Data Sources',
  bucketsHeader: 'Buckets',
};

const navTabs = [display.dataSourcesHeader, display.bucketsHeader];

const DataSourcesPage = ({ loadDataSources, loadBuckets, loadingFinished }) => {
  useEffect(() => {
    loadDataSources();
    loadBuckets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loadingFinished) {
    return <Loading header={display.header} />;
  }

  return (
    <Page.Page>
      <Page.InPageNavigation navTabs={navTabs}>
        <Page.PageHeaderWithNav>
          <div>
            <h1>{display.header}</h1>
          </div>
          <Page.PageNavigation navTabs={navTabs} />
        </Page.PageHeaderWithNav>
        <Page.Scroll>
          <Page.BoxNavTarget navTarget={display.dataSourcesHeader}>
            <DataSources.Container />
          </Page.BoxNavTarget>
          <Page.BoxNavTarget navTarget={display.bucketsHeader}>
            <Buckets.Container />
          </Page.BoxNavTarget>
        </Page.Scroll>
      </Page.InPageNavigation>
    </Page.Page>
  );
};

DataSourcesPage.propTypes = {
  loadDataSources: func.isRequired,
  loadBuckets: func.isRequired,
  loadingFinished: bool.isRequired,
};

export default DataSourcesPage;
