import * as api from './api';
import * as actionTypes from './actionTypes';
import { createApiAction } from '../../rayApi/actions';

export const submit = (values) => {
  const body = {
    name: values.name,
    description: values.description,
    type: values.authorizationServiceType,
    connection: values[values.authorizationServiceType],
  };

  return {
    type: actionTypes.submit,
    payload: {
      body,
    },
  };
};

export const post = (body) => {
  return createApiAction(actionTypes.post, api.post, true, null, body);
};
