import styled from 'styled-components/macro';
import { colors, fonts } from '../../css';

export const BannerDiv = styled.div`
  display: flex;
  border: 1px solid;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 32px;
  position: relative;

  > svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    flex: 0 0 auto;
  }

  > div {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    flex: 1 1 auto;

    > div.title {
      ${fonts.medium_semibold}
      color: ${colors.font_light};
      letter-spacing: 0.16px;
    }

    > div.details {
      ${fonts.medium}
      color: ${colors.font_light};
      margin-top: 4px;
    }

    > div.details:not(.compact) {
      padding-bottom: 24px;
    }

    div.buttons {
      align-self: flex-end;
      display: flex;
    }

    form {
      align-self: stretch;
    }
  }

  &.error {
    border-color: ${colors.error};
    background-color: ${colors.error_dim};
    white-space: pre-wrap; /* CSS property to make \n work */

    > svg {
      fill: ${colors.error};
    }
  }

  &.warning {
    border-color: ${colors.warning};
    background-color: ${colors.warning_dim};

    > svg {
      fill: ${colors.warning};
    }
  }

  &.success {
    border-color: ${colors.success};
    background-color: ${colors.success_dim};

    > svg {
      fill: ${colors.success};
    }
  }
`;

export const CloseIconButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;

  flex-grow: 0;
  border: 1px solid transparent;
  padding: 0;
  height: 20px;
  padding: 2px;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;

  &:focus,
  &:active,
  &:hover {
    border-color: ${colors.error};
    text-decoration: underline;
    outline: none;
  }

  svg {
    height: 15px;
    fill: ${colors.font_light};
  }
`;
