/* eslint-disable react/jsx-props-no-spreading */
import { string, bool, elementType } from 'prop-types';
import { OptionalBadge } from '../Badge';
import Help from '../Help';
import { Label, LabelDiv } from './styledComponents';

const MainLabel = ({ inputId, label, optional, fieldHelp, ...props }) => {
  if (!fieldHelp) {
    return (
      <Label htmlFor={inputId} {...props}>
        {label}
        {optional && <OptionalBadge />}
      </Label>
    );
  }

  return (
    <LabelDiv>
      <Label htmlFor={inputId} {...props}>
        {label}
        {optional && <OptionalBadge />}
      </Label>
      <Help.Button fieldHelp={fieldHelp} />
    </LabelDiv>
  );
};

MainLabel.defaultProps = {
  inputId: undefined,
  label: null,
  optional: false,
  fieldHelp: undefined,
};

MainLabel.propTypes = {
  inputId: string,
  label: string,
  optional: bool,
  fieldHelp: elementType,
};

export default MainLabel;
