import { connect } from '../../utils/redux';
import auth from '../../auth';
import Header from './components/Header';
import { getTenantServiceName } from '../AccountSetup/selectors';

const mapStateToProps = (state, props) => {
  return {
    authenticated: auth.selectors.isAuthenticated(state),
    name: auth.selectors.getName(state),
    serviceName: getTenantServiceName(state),
    noNav: props.noNav,
  };
};

export default connect(mapStateToProps, {
  signOut: auth.actions.signOut,
})(Header);
