import { connect } from '../../utils/redux';
import DataSources from '../DataSources';
import PrestoClusters from '../PrestoClusters';
import { AttachDataSources } from './components';
import * as selectors from './selectors';

const mapStateToProps = (state, props) => {
  return {
    prestoClusterId: PrestoClusters.selectors.getPrestoClusterId(state, props),
    prestoCluster: PrestoClusters.selectors.getPrestoCluster(state, props),
    notFound: PrestoClusters.selectors.getIsNotFound(state, props),
    actionStatus: PrestoClusters.selectors.getActionStatus(state, props),
    usePop: PrestoClusters.selectors.getUsePop(state),
    prestoClusterDataSourceIds: PrestoClusters.selectors.getDataSourceIds(
      state,
      props,
    ),
    dataSources: selectors.getDataSources(state, props),
    isCommunityEditionCluster:
      PrestoClusters.selectors.isCommunityEditionPrestoCluster(state, props),
  };
};

export default connect(mapStateToProps, {
  loadPrestoCluster: PrestoClusters.actions.get,
  loadPrestoClusterDataSources: PrestoClusters.actions.getDataSources,
  loadDataSources: DataSources.actions.getAll,
  saveDataSources: PrestoClusters.actions.putDataSources,
})(AttachDataSources);
