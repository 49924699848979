import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const submit = (identityProviderId, values) => {
  return {
    type: actionTypes.submit,
    payload: {
      identityProviderId,
      body: values,
    },
  };
};

export const patch = (identityProviderId, body) => {
  return createApiAction(
    actionTypes.patch,
    api.patch,
    true,
    { identityProviderId },
    body,
  );
};
