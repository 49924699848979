import Help from '../Help';

const CustomTags = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Tags</Help.HelpHeader>
      <Help.P>Specify AWS Custom Tag keys and values</Help.P>
    </Help.HelpSection>
  );
};

const module = {
  CustomTags,
};

export default module;
