import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import * as actionTypes from './actionTypes';

const initialState = {
  authorizationServices: null,
  authorizationServicesDataSourceIds: null,
  authorizationServicesPrestoClusterIds: null,
  loadingInProgress: false,
  error: null,
  actionStatus: new Map(),
};

const onNavigation = (draft) => {
  draft.loadingInProgress = false;
  draft.error = null;
  draft.authorizationServicesDataSourceIds = null;
  draft.authorizationServicesPrestoClusterIds = null;
  draft.actionStatus = new Map();
};

const get = (draft, action) => {
  if (!draft.authorizationServices) {
    draft.authorizationServices = new Map();
  }
  const data = action.payload.apiResult.body;
  draft.authorizationServices.set(
    action.payload.params.authorizationServiceId,
    data,
  );
};

const getFailure = (draft, action) => {
  if (!draft.authorizationServices) {
    draft.authorizationServices = new Map();
  }
  draft.authorizationServices.set(
    action.payload.params.authorizationServiceId,
    'not found',
  );
};

const getStart = (draft) => {
  draft.loadingInProgress = true;
};

const getDataSources = (draft, action) => {
  if (!draft.authorizationServicesDataSourceIds) {
    draft.authorizationServicesDataSourceIds = new Map();
  }

  const dataSourceIds = action.payload.apiResult.body.results.map((as) => {
    return as.dataSourceId;
  });
  draft.authorizationServicesDataSourceIds.set(
    action.payload.params.authorizationServiceId,
    dataSourceIds,
  );
};

const getDataSourcesFailure = (draft, action) => {
  if (!draft.authorizationServicesDataSourceIds) {
    draft.authorizationServicesDataSourceIds = new Map();
  }
  draft.authorizationServicesDataSourceIds.set(
    action.payload.params.authorizationServiceId,
    [],
  );
};

const getAllFailure = (draft, action) => {
  draft.loadingInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
};

const getPartial = (draft, action) => {
  if (!draft.authorizationServices) {
    draft.authorizationServices = new Map();
  }

  const { results } = action.payload.apiResult.body;
  for (let i = 0; i < results.length; i += 1) {
    draft.authorizationServices.set(
      results[i].authorizationServiceId,
      results[i],
    );
  }
};

const getAll = (draft, action) => {
  if (draft.authorizationServices) {
    draft.authorizationServices.clear();
  }
  getPartial(draft, action);
  draft.loadingInProgress = false;
};

const getPrestoClustersSuccess = (draft, action) => {
  if (!draft.authorizationServicesPrestoClusterIds) {
    draft.authorizationServicesPrestoClusterIds = new Map();
  }

  const prestoClusterIds = action.payload.apiResult.body.results.map((pc) => {
    return pc.prestoClusterId;
  });
  draft.authorizationServicesPrestoClusterIds.set(
    action.payload.params.authorizationServiceId,
    prestoClusterIds,
  );
};

const getPrestoClustersFailure = (draft, action) => {
  if (!draft.authorizationServicesPrestoClusterIds) {
    draft.authorizationServicesPrestoClusterIds = new Map();
  }
  draft.authorizationServicesPrestoClusterIds.set(
    action.payload.params.authorizationServiceId,
    [],
  );
};

const actionStart = (draft, action) => {
  const status = {
    ...action.payload.actionInfo,
    success: false,
    inProgress: true,
  };
  draft.actionStatus.set(action.payload.params.authorizationServiceId, status);
};

const removeSuccess = (draft, action) => {
  const oldStatus = draft.actionStatus.get(
    action.payload.params.authorizationServiceId,
  );
  if (oldStatus.actionId === action.payload.actionInfo.actionId) {
    const status = {
      ...oldStatus,
      success: true,
      inProgress: false,
      error: null,
    };
    draft.actionStatus.set(
      action.payload.params.authorizationServiceId,
      status,
    );
  }

  draft.authorizationServices.delete(
    action.payload.params.authorizationServiceId,
  );
};

const actionFailure = (draft, action) => {
  const oldStatus = draft.actionStatus.get(
    action.payload.params.authorizationServiceId,
  );
  if (oldStatus.actionId === action.payload.actionInfo.actionId) {
    const status = {
      ...oldStatus,
      success: false,
      inProgress: false,
      error: action.payload.apiResult.body.errorMessage,
    };
    draft.actionStatus.set(
      action.payload.params.authorizationServiceId,
      status,
    );
  }
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.get.success]: get,
  [actionTypes.get.failure]: getFailure,
  [actionTypes.getDataSources.success]: getDataSources,
  [actionTypes.getDataSources.failure]: getDataSourcesFailure,
  [actionTypes.getAll.request]: getStart,
  [actionTypes.getAll.success]: getAll,
  [actionTypes.getAll.failure]: getAllFailure,
  [actionTypes.remove.request]: actionStart,
  [actionTypes.remove.success]: removeSuccess,
  [actionTypes.remove.failure]: actionFailure,
  [actionTypes.getPrestoClusters.success]: getPrestoClustersSuccess,
  [actionTypes.getPrestoClusters.failure]: getPrestoClustersFailure,
  [auth.actionTypes.signOut]: signOut,
};

export default makeReducer(initialState, actionReducers);
