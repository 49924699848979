export const getRoute = () => {
  return '/prestoClusters';
};

export const getAddPrestoClusterRoute = () => {
  return '/prestoClusters/add';
};

export const getManagePrestoClusterRoute = (prestoClusterId) => {
  return `/prestoClusters/${prestoClusterId}`;
};

export const getManagePrestoClusterDataSourcesRoute = (prestoClusterId) => {
  return `/prestoClusters/${prestoClusterId}/dataSources`;
};

export const getChangePrestoClusterPasswordRoute = (prestoClusterId) => {
  return `/prestoClusters/${prestoClusterId}/credentials`;
};

export const getPrestoClusterAutoScalingPolicy = (prestoClusterId) => {
  return `/prestoClusters/${prestoClusterId}/scaling`;
};

export const getPrestoClusterGroupedConfigRoute = (prestoClusterId) => {
  return `/prestoClusters/${prestoClusterId}/groupedconfig`;
};

export const getPrestoClusterEnableSpotRoute = (prestoClusterId) => {
  return `/prestoClusters/${prestoClusterId}/enablespot`;
};

export const getPrestoClusterPrestoUsersRoute = (prestoClusterId) => {
  return `/prestoClusters/${prestoClusterId}/prestousers`;
};

// ne nado, also delete the component
export const getPrestoDebuggingRoute = (prestoClusterId) => {
  return `/prestoclusters/${prestoClusterId}/prestodebugging`;
};
