const Close = () => {
  return (
    <svg viewBox='0 0 46 46' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23 17.889692L40.889692 0 46 5.110308 28.110308 23 46 40.889692 40.889692 46 23 28.110308 5.110308 46 0 40.889692 17.889692 23 0 5.110308 5.110308 0z'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Close;
