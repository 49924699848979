import { useMemo } from 'react';
import { bool, shape, func, string } from 'prop-types';
import Page from '../../../Page';
import Form from '../../../Form';
import * as constants from '../../constants';
import * as Help from '../CreateComputePlaneHelp';
import { useFormWizard } from '../../../FormWizard/useFormWizard';
import Banner from '../../../Banner';
import { VPCTypeOptionsDiv, VPCTypeOptionDiv } from '../styledComponents';

const display = {
  title: 'Step 1 of 8 - Choose VPC Type',
  required: 'Please select a VPC type',
  fullyManaged: {
    label: 'Fully Managed VPC',
    description: `IBM watsonx.data creates a VPC for the compute plane in your AWS account. Great for individuals and
    organizations that want to avoid managing AWS networking complexities.`,
  },
  userManaged: {
    label: 'User Managed VPC',
    description: `Preferred choice for organizations that want to manage all details of AWS networking.
    This is an advanced option and not recommended for those unfamiliar with AWS.`,
  },
};

const VPCType = ({ label, description }) => {
  return () => {
    return (
      <VPCTypeOptionDiv>
        <div>{label}</div>
        <p>{description}</p>
      </VPCTypeOptionDiv>
    );
  };
};

const vpcTypeValues = [
  {
    value: constants.vpcTypes.fullyManaged,
    label: display.fullyManaged.label,
    component: VPCType({
      label: display.fullyManaged.label,
      description: display.fullyManaged.description,
    }),
  },
  {
    value: constants.vpcTypes.userManaged,
    label: display.userManaged.label,
    component: VPCType({
      label: display.userManaged.label,
      description: display.userManaged.description,
    }),
  },
];

export const SelectVPC = ({
  computePlane,
  onNext,
  submitInProgress,
  error,
}) => {
  const formWizard = useFormWizard();

  const handleSubmit = (data) => {
    const { nextStepId } = formWizard;
    const isBYOVPC = data.vpcType === constants.vpcTypes.userManaged;
    onNext({ data: { isBYOVPC }, nextStepId });
  };

  const defaultVPCType = useMemo(() => {
    return computePlane?.computeConfiguration?.isBYOVPC
      ? constants.vpcTypes.userManaged
      : constants.vpcTypes.fullyManaged;
  }, [computePlane]);

  return (
    <Form.Form name={constants.stepIds.step1} submitAction={handleSubmit}>
      <Page.Box>
        <Page.BoxHeader>
          <h2>{display.title}</h2>
        </Page.BoxHeader>
        {error && <Banner title={error} scrollIntoView />}
        <VPCTypeOptionsDiv>
          <Form.RadioInputs
            name='vpcType'
            label='VPC Type'
            values={vpcTypeValues}
            disabled={false}
            defaultValue={defaultVPCType}
            validationRules={{ required: display.required }}
            fieldHelp={Help.VPCType}
          />
        </VPCTypeOptionsDiv>
      </Page.Box>
      <Page.Buttons>
        <Form.Submit
          label='Next'
          disabled={submitInProgress}
          disabledOnErrors={false}
          showLoading={submitInProgress}
        />
      </Page.Buttons>
    </Form.Form>
  );
};

SelectVPC.defaultProps = {
  computePlane: null,
  submitInProgress: false,
  error: undefined,
};

SelectVPC.propTypes = {
  computePlane: shape({
    computeConfiguration: shape({
      isBYOVPC: bool,
    }),
  }),
  error: string,
  submitInProgress: bool,
  onNext: func.isRequired,
};
