import { shape, string, arrayOf } from 'prop-types';
import Table from '../../Table';
import { ClusterNameCellDiv, SummaryRowDiv } from './styledComponents';
import { Cluster } from '../propTypes';

const display = {
  clusterNameColumnLabel: 'Cluster name',
  resourceColumnLabel: 'Resource',
  durationColumnLabel: 'Duration (hour)',
  creditPerHourColumnLabel: 'Credit per hour',
  totalCreditsColumnLabel: 'Total credits',
};

const NameCell = ({ data }) => {
  return (
    <Table.DataCell className='alignment-flex-start'>
      <ClusterNameCellDiv>
        <div className='cluster-name'>{data.name}</div>
        <div className='created-on '>Created on:</div>
        <div>{data.createdOn}</div>
      </ClusterNameCellDiv>
    </Table.DataCell>
  );
};

NameCell.propTypes = {
  data: shape({
    name: string.isRequired,
    createdOn: string.isRequired,
  }).isRequired,
};

const SummaryRow = (data) => {
  const { totalCredits } = data;
  return (
    <SummaryRowDiv>
      <div className='empty'>&nbsp;</div>
      <div className='label'>Total</div>
      <div className='sum'>{totalCredits}</div>
    </SummaryRowDiv>
  );
};

const ResourceCell = ({ data }) => {
  return (
    <Table.DataCellVertical>
      {data.map((c) => {
        return (
          <div
            className='grouped'
            key={`${c.containerTypeDisplay}-${c.instanceType}`}
          >
            <div className='medium-bold'>{c.containerTypeDisplay}</div>
            <div>{c.instanceType}</div>
          </div>
        );
      })}
    </Table.DataCellVertical>
  );
};

ResourceCell.propTypes = {
  data: shape({
    containerTypeDisplay: string.isRequired,
    instanceType: string.isRequired,
  }).isRequired,
};

const DurationCell = ({ data }) => {
  return (
    <Table.DataCellVertical className='align-right'>
      {data.map((c) => {
        return <div key={`${c.containerType}-${c.duration}`}>{c.duration}</div>;
      })}
    </Table.DataCellVertical>
  );
};

DurationCell.propTypes = {
  data: shape({
    duration: string.isRequired,
    containerType: string.isRequired,
  }).isRequired,
};

const CreditsPerHourCell = ({ data }) => {
  return (
    <Table.DataCellVertical className='align-right'>
      {data.map((c) => {
        return (
          <div key={`${c.containerType}-${c.creditsPerHour}`}>
            {c.creditsPerHour}
          </div>
        );
      })}
    </Table.DataCellVertical>
  );
};

CreditsPerHourCell.propTypes = {
  data: shape({
    creditsPerHour: string.isRequired,
    containerType: string.isRequired,
  }).isRequired,
};

const TotalCreditsCell = ({ data }) => {
  return (
    <Table.DataCellVertical className='align-right padding-right'>
      {data.map((c) => {
        return (
          <div key={`${c.containerType}-${c.totalCredits}`}>
            {c.totalCredits}
          </div>
        );
      })}
    </Table.DataCellVertical>
  );
};

TotalCreditsCell.propTypes = {
  data: shape({
    totalCredits: string.isRequired,
    containerType: string.isRequired,
  }).isRequired,
};

const columns = [
  {
    name: display.clusterNameColumnLabel,
    selector: (cluster) => {
      return { name: cluster.name, createdOn: cluster.createDateDisplay };
    },
    cellRender: NameCell,
    cellWidth: '2fr',
  },
  {
    name: display.resourceColumnLabel,
    selector: (cluster) => {
      return cluster.containers.map((c) => {
        return {
          containerTypeDisplay: c.containerTypeDisplay,
          instanceType: c.instanceType,
        };
      });
    },
    cellRender: ResourceCell,
    cellWidth: '1fr',
  },
  {
    name: display.durationColumnLabel,
    selector: (cluster) => {
      return cluster.containers.map((c) => {
        return { duration: c.durationDisplay, containerType: c.containerType };
      });
    },
    cellRender: DurationCell,
    cellWidth: '1fr',
    className: 'align-right',
  },
  {
    name: display.creditPerHourColumnLabel,
    selector: (cluster) => {
      return cluster.containers.map((c) => {
        return {
          creditsPerHour: c.creditPerHourDisplay,
          containerType: c.containerType,
        };
      });
    },
    cellRender: CreditsPerHourCell,
    cellWidth: '1fr',
    className: 'align-right',
  },
  {
    name: display.totalCreditsColumnLabel,
    selector: (cluster) => {
      return cluster.containers.map((c) => {
        return {
          totalCredits: c.totalCreditsDisplay,
          containerType: c.containerType,
        };
      });
    },
    cellRender: TotalCreditsCell,
    cellWidth: '1fr',
    className: 'align-right',
  },
];

const dataKeyGenerator = (cluster) => {
  return cluster.clusterId;
};

const ClusterUsageDetailsTable = ({ clusters }) => {
  return (
    <Table.Table
      data={clusters}
      columns={columns}
      dataKeyGenerator={dataKeyGenerator}
      summaryRow={SummaryRow}
    />
  );
};

ClusterUsageDetailsTable.propTypes = {
  clusters: arrayOf(Cluster).isRequired,
};

export default ClusterUsageDetailsTable;
