import { connect } from '../../utils/redux';
import { AddIdentityProvider } from './components';
import * as selectors from './selectors';
import * as actions from './actions';

const mapStateToProps = (state) => {
  return {
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    usePop: selectors.getUsePop(state),
    error: selectors.getError(state),
  };
};

export default connect(mapStateToProps, {
  submit: actions.submit,
})(AddIdentityProvider);
