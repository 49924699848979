import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool } from 'prop-types';
import { connectionParametersOrConfigurationPropertiesRegex } from '../../../utils/regex';
import Form from '../../Form';
import DataSources from '../../DataSources';
import { required, portValidation, endpointValidation } from '../utils';
import Help from './MySqlConnectionDetailsHelp';

const display = {
  formContentHeader: 'Connection Configuration',
  formExplaination: 'Secondary explanation',
  endpointLabel: 'Endpoint',
  endpointRequiredError: 'Endpoint is required',
  endpointPatternError: 'Endpoint is not in valid format',
  portLabel: 'Port',
  portRequiredError: 'Port is required',
  portNotANumberError: 'Port should be a number',
  configurationPropertiesLabel: 'Configuration Properties',
  configurationPropertiesIllegalCharactersError:
    'Configuration properties should be in the format: parameter=value&parameter=value with valid characters',
  userLabel: 'User',
  userRequiredError: 'User is required',
  passwordLabel: 'Password',
  passwordRequiredError: 'Password is required',
};

const type = DataSources.constants.dataSourceTypes.mysql;

const MySqlConnectionDetails = ({ disabled, active, dataSource }) => {
  const { getValues, clearErrors } = useFormContext();
  const { dataSourceType } = getValues();

  useEffect(() => {
    if (!active) {
      clearErrors(['mysql']);
    }
  }, [active, clearErrors]);

  return (
    <>
      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.formContentHeader}</h3>
        {/* <div>{display.formExplaination}</div> */}
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.TextInput
          name='mysql.endpoint'
          label={display.endpointLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.metadata.endpoint}
          validationRules={{
            validate: required(
              getValues,
              type,
              display.endpointRequiredError,
              endpointValidation(display.endpointPatternError),
            ),
          }}
          fieldHelp={Help.Endpoint}
        />
        <Form.TextInput
          type='integer'
          name='mysql.port'
          label={display.portLabel}
          disabled={disabled}
          defaultValue={(dataSource && dataSource.metadata.port) || '3306'}
          validationRules={{
            validate: required(
              getValues,
              type,
              display.portRequiredError,
              portValidation,
            ),
            valueAsNumber: true,
          }}
          fieldHelp={Help.Port}
        />
        <Form.TextInput
          name='mysql.configurationProperties'
          label={display.configurationPropertiesLabel}
          disabled={disabled}
          optional
          defaultValue={
            dataSource && dataSource.metadata.configurationProperties
          }
          validationRules={{
            pattern:
              dataSourceType === type
                ? {
                    value: connectionParametersOrConfigurationPropertiesRegex,
                    message:
                      display.configurationPropertiesIllegalCharactersError,
                  }
                : null,
          }}
          fieldHelp={Help.ConfigurationProperties}
        />
        <Form.TextInput
          name='mysql.user'
          label={display.userLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.connection['connection-user']}
          validationRules={{
            validate: required(getValues, type, display.userRequiredError),
          }}
          fieldHelp={Help.User}
        />
        <Form.PasswordInput
          name='mysql.password'
          label={display.passwordLabel}
          disabled={disabled}
          validationRules={{
            validate: required(getValues, type, display.passwordRequiredError),
          }}
          fieldHelp={Help.Password}
        />
      </div>
    </>
  );
};

MySqlConnectionDetails.defaultProps = {
  disabled: false,
  dataSource: null,
};

MySqlConnectionDetails.propTypes = {
  disabled: bool,
  active: bool.isRequired,
  dataSource: DataSources.propTypes.DataSource,
};

export default MySqlConnectionDetails;
