import { func, arrayOf } from 'prop-types';
import Page from '../../Page';
import * as propTypes from '../propTypes';
import BucketsTable from './BucketsTable';
import BucketError from './BucketError';
import { NoDataDiv } from './styledComponent';

const display = {
  bucketsLabel: 'IBM watsonx.data managed S3 Buckets',
  noBucketsLabel: 'There are no IBM watsonx.data managed S3 buckets.',
};

const Buckets = ({ buckets, deleteBucket, errorMessages }) => {
  return (
    <>
      <Page.TableBoxHeader>
        <h2>{display.bucketsLabel}</h2>
      </Page.TableBoxHeader>
      {errorMessages && errorMessages.length > 0 && (
        <BucketError messages={errorMessages} />
      )}
      {((buckets && buckets.length === 0) || !buckets) && (
        <NoDataDiv>{display.noBucketsLabel}</NoDataDiv>
      )}
      {buckets && buckets.length > 0 && (
        <BucketsTable buckets={buckets} deleteBucket={deleteBucket} />
      )}
    </>
  );
};

Buckets.defaultProps = {
  buckets: null,
  errorMessages: null,
};

Buckets.propTypes = {
  buckets: arrayOf(propTypes.Bucket),
  deleteBucket: func.isRequired,
  errorMessages: arrayOf(propTypes.ErrorMessage),
};

export default Buckets;
