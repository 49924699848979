import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';
import { fonts, colors } from '../../css';

const animationDuration = 4000;
const delayBeforeRepeat = 2000;
const rowCount = 8;

const fadeUp = keyframes`
  0% {
    opacity: 100%;
  }
  25% {
    opacity: 20%;
  }
  50% {
    opacity: 100%;
  }
`;

export const CircleLoading = `
.bootLoader-v1 {
  width: auto;
  height: auto;
  top: calc(50% - 2.25rem);
  left: calc(50% - 2.25rem);
  z-index: 8000;
}

@keyframes uc-boot-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes uc-boot-stroke {
  0% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 16;
  }
}

.bootLoader-v1 svg {
  fill: transparent;
  height: 88px;
  width: 88px;
  margin-top: 0;
  margin-botton: 0;
}

.bootLoader-v1 svg circle {
  -webkit-animation-name: uc-boot-stroke;
  animation-name: uc-boot-stroke;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  -webkit-animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  stroke-width: 10;
  stroke-linecap: butt;
  stroke-dasharray: 240;
  stroke: #0f62fe;
  stroke-dashoffset: 16;
}

.bootLoader-v1 .bx--loading {
  -webkit-animation-name: uc-boot-rotate;
  animation-name: uc-boot-rotate;
  -webkit-animation-duration: 690ms;
  animation-duration: 690ms;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  width: 5.5rem;
  height: 5.5rem;
}
`;

export const LoadingLogoSvg = styled.svg`
  circle {
    animation: ${fadeUp} ${animationDuration}ms infinite ease-in-out;
  }

  circle.row1 {
    animation-delay: 0ms;
  }

  circle.row2 {
    animation-delay: ${((animationDuration - delayBeforeRepeat) / rowCount) *
    1}ms;
  }

  circle.row3 {
    animation-delay: ${((animationDuration - delayBeforeRepeat) / rowCount) *
    2}ms;
  }

  circle.row4 {
    animation-delay: ${((animationDuration - delayBeforeRepeat) / rowCount) *
    3}ms;
  }

  circle.row5 {
    animation-delay: ${((animationDuration - delayBeforeRepeat) / rowCount) *
    4}ms;
  }

  circle.row6 {
    animation-delay: ${((animationDuration - delayBeforeRepeat) / rowCount) *
    5}ms;
  }

  circle.row7 {
    animation-delay: ${((animationDuration - delayBeforeRepeat) / rowCount) *
    6}ms;
  }

  circle.row8 {
    animation-delay: ${((animationDuration - delayBeforeRepeat) / rowCount) *
    7}ms;
  }
`;

export const LoadingLogoIconSvg = styled(LoadingLogoSvg)`
  fill: ${colors.font_light};
`;

export const PageDiv = styled.div`
  flex: 1 0 auto;
  align-self: center;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  &.boxed {
    margin-top: 40px;
    border: 1px solid ${colors.border};
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 ${colors.shadow};
    background-color: ${colors.section_background};
    padding: 24px;
    color: ${colors.font_light};
  }

  > div {
    ${fonts.large_bold}
    color: ${colors.font_light};
    padding-right: 40px;
  }

  svg {
    width: 250px;
  }
`;

export const SmallLoadingDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  color: ${colors.font_light};
  ${fonts.large_bold};
  letter-spacing: 0.16px;

  svg {
    fill: ${colors.font_light};
    width: 27px;
  }

  div {
    margin-left: 10px;
  }
`;
