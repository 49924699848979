export const getErrors = (state) => {
  return state.authorizationServiceAdd.errors;
};

export const getSubmitInProgress = (state) => {
  return state.authorizationServiceAdd.submitInProgress;
};

export const getSubmitSuccess = (state) => {
  return state.authorizationServiceAdd.submitSuccess;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};
