import styled from 'styled-components/macro';
import { colors } from '../../css';

export const HttpErrorsDiv = styled.div`
  width: 100%;
  height: 100%;

  > .content {
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    color: ${colors.font_light};

    > .title {
      color: ${colors.font_light};
    }
  }

  .error-image {
    position: fixed;
    pointer-events: none;
    bottom: 0;
    width: 100%;
    max-height: 100%;
  }
`;
