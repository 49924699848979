import { useEffect } from 'react';
import { Outlet, useLocation, useNavigationType } from 'react-router';
import { func } from 'prop-types';
import { connect } from '../utils/redux';
import { createHistoryAction } from './actions';

const Base = ({ locationChanged }) => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    locationChanged(location, navigationType);
  }, [location, navigationType, locationChanged]);

  return <Outlet />;
};

Base.propTypes = {
  locationChanged: func.isRequired,
};

export default connect(undefined, { locationChanged: createHistoryAction })(
  Base,
);
