export function parseVersion(versionStr, allowMissingParts) {
  const [version, release, modification, fixes] = versionStr
    .split('.')
    .map((p) => {
      const i = Number.parseInt(p, 10);
      if (`${i}` !== p) {
        throw new Error(
          `Invalid version ${versionStr}. Only integers supported.`,
        );
      }
      return i;
    });
  if (
    !allowMissingParts &&
    (version === undefined ||
      release === undefined ||
      modification === undefined ||
      fixes === undefined)
  ) {
    throw new Error(`Invalid version ${versionStr}`);
  }

  return {
    version: version || 0,
    release: release || 0,
    modification: modification || 0,
    fixes: fixes || 0,
  };
}

export function parsePrestoVersion(version) {
  if (version.includes('-AHN-')) {
    // 0.242-AHN-0.7
    const split = version.split('-AHN-');
    const prestoVersion = split[0];
    const ahanaVersion = parseVersion(split[1], true);

    return {
      prestoVersion,
      ahanaVersion,
    };
  }

  if (version.includes('.ibm.saas-v')) {
    // 0.282.ibm.saas-v1.1.0.6
    const split = version.split('.ibm.saas-v');
    const prestoVersion = split[0];
    const ahanaVersion = parseVersion(split[1], true);

    return {
      prestoVersion,
      ahanaVersion,
    };
  }

  // 0.282.ibm.blueray-v1.1.0.6
  const split = version.split('.ibm.blueray-v');
  const prestoVersion = split[0];
  const ahanaVersion = parseVersion(split[1], true);

  return {
    prestoVersion,
    ahanaVersion,
  };
}

function convertVersionToVRMF(version) {
  if (version.version) {
    return version;
  }

  return {
    version: version.major,
    release: version.minor,
    modification: version.patch,
    fixes: 0,
  };
}

export function compare(versionA, versionB) {
  const vrmfA = convertVersionToVRMF(versionA);
  const vrmfB = convertVersionToVRMF(versionB);

  if (
    !Number.isInteger(vrmfA.version) ||
    !Number.isInteger(vrmfA.release) ||
    !Number.isInteger(vrmfA.modification) ||
    !Number.isInteger(vrmfA.fixes)
  ) {
    throw new Error('Invalid versionA. Only integers supported.');
  }

  if (
    !Number.isInteger(vrmfB.version) ||
    !Number.isInteger(vrmfB.release) ||
    !Number.isInteger(vrmfB.modification) ||
    !Number.isInteger(vrmfB.fixes)
  ) {
    throw new Error('Invalid versionB. Only integers supported.');
  }

  if (vrmfA.version !== vrmfB.version) {
    return vrmfA.version < vrmfB.version ? -1 : 1;
  }

  if (vrmfA.release !== vrmfB.release) {
    return vrmfA.release < vrmfB.release ? -1 : 1;
  }

  if (vrmfA.modification !== vrmfB.modification) {
    return vrmfA.modification < vrmfB.modification ? -1 : 1;
  }

  if (vrmfA.fixes !== vrmfB.fixes) {
    return vrmfA.fixes < vrmfB.fixes ? -1 : 1;
  }
  return 0;
}

export function greaterThanOrEqualTo(versionA, versionB) {
  return compare(versionA, versionB) >= 0;
}
