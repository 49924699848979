import { connect } from '../../utils/redux';
import { getCRN } from '../../auth/selectors';
import { SignIn } from './components';
import * as actions from './actions';
import * as selectors from './selectors';

const mapStateToProps = (state, props) => {
  return {
    crn: getCRN(state),
    submitInProgress: selectors.getSubmitInProgress(state),
    error: selectors.getError(state),
    errorStatus: selectors.getErrorStatus(state),
    message: selectors.getMessage(state, props),
  };
};

export default connect(mapStateToProps, {
  signIn: actions.submit,
})(SignIn);
