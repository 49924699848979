const Help = () => {
  return (
    <svg viewBox='0 0 54 54' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27 53.6666667C12.272 53.6666667.33333333 41.728.33333333 27S12.272.33333333 27 .33333333C41.728.33333333 53.6666667 12.272 53.6666667 27 53.6666667 41.728 41.728 53.6666667 27 53.6666667zM24.3333333 35v5.3333333h5.3333334V35h-5.3333334zm5.3333334-4.3866667c4.4293727-1.3349981 7.2137521-5.7116922 6.5459577-10.2894224-.6677943-4.5777302-4.5864632-7.976591-9.2126244-7.9905776-4.4491328-.0003391-8.2803441 3.1389552-9.1546667 7.5013334l5.232 1.048c.4129858-2.0662014 2.3580103-3.4577428 4.4468897-3.1814668 2.0888794.2762759 3.6052626 2.1256243 3.4668915 4.2281464-.1383711 2.102522-1.8840441 3.7371881-3.9911145 3.7373204-1.4727593 0-2.6666667 1.1939073-2.6666667 2.6666666v4h5.3333334v-1.72z'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Help;
