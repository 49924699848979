import { createSelector } from 'reselect';
import Big from 'big.js';
import { format } from 'date-fns';
import { populateMonthlyDropdown } from '../../utils/dateUtils';

export const tenantSelector = (state) => {
  return state && state.costManagement && state.costManagement.tenant;
};

export const clustersSelector = (state) => {
  return state && state.costManagement && state.costManagement.clusters;
};

export const contractSelector = (state) => {
  return state && state.costManagement && state.costManagement.contract;
};

export const getError = (state) => {
  return state && state.costManagement && state.costManagement.error;
};

export const getSubscriptionSummaryIsLoading = (state) => {
  if (!state || !state.costManagement) return false;

  const { tenantLoading, contractLoading } = state.costManagement;
  return tenantLoading || contractLoading;
};

export const getUsageDetailsIsLoading = (state) => {
  if (!state || !state.costManagement) return false;

  const { clustersLoading } = state.costManagement;
  return clustersLoading;
};

export const getTenant = createSelector(tenantSelector, (tenant) => {
  if (!tenant) return null;

  return {
    ...tenant,
    createDateDisplay: format(Date.parse(tenant.createDate), 'PPP'),
    cumulativeConsumedCreditsDisplay: tenant.cumulativeConsumedCredits
      ? tenant.cumulativeConsumedCredits.toLocaleString()
      : '0',
  };
});

export const getContract = createSelector(contractSelector, (contract) => {
  if (!contract) return null;

  const formattingPattern = 'PPP';

  return {
    ...contract,
    createDateDisplay: format(
      Date.parse(contract.createDate),
      formattingPattern,
    ),
    endDateDisplay: format(Date.parse(contract.endDate), formattingPattern),
    billedCreditsDisplay: contract.billedCredits
      ? contract.billedCredits.toLocaleString()
      : '0',
    purchasedCreditsDisplay: contract.purchasedCredits
      ? contract.purchasedCredits.toLocaleString()
      : '0',
    remainingCreditsDisplay: contract.remainingCredits
      ? contract.remainingCredits.toLocaleString()
      : '0',
  };
});

const getContainerDisplayName = (type) => {
  switch (type) {
    case 'worker':
      return 'Worker';
    case 'coordinator':
      return 'Coordinator';
    case 'hms':
      return 'IBM watsonx.data Hive Metastore';
    default:
      return type;
  }
};

export const getClusters = createSelector(clustersSelector, (clusters) => {
  if (!clusters) return null;

  const result = clusters.map((cluster) => {
    const clusterResult = {
      ...cluster,
      createDateDisplay: format(Date.parse(cluster.createDate), 'PPP'),
    };
    clusterResult.containers = cluster.containers.map((container) => {
      return {
        ...container,
        containerTypeDisplay: getContainerDisplayName(container.containerType),
        durationDisplay: container.duration.toFixed(3),
        creditPerHourDisplay: container.creditsPerHour.toFixed(2),
        totalCreditsDisplay: container.totalCredits.toFixed(4),
      };
    });
    return clusterResult;
  });

  return result;
});

export const getTotalTenantCredits = createSelector(getClusters, (clusters) => {
  if (!clusters) return null;

  const totalCredits = clusters.reduce((acc, cluster) => {
    return acc.plus(
      cluster.containers.reduce((containerAcc, container) => {
        return containerAcc.plus(container.totalCredits);
      }, new Big(0)),
    );
  }, new Big(0));

  return totalCredits.toNumber();
});

export const getFilterMonths = () => {
  const now = new Date();
  return populateMonthlyDropdown(now.getMonth());
};
