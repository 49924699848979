import { useMemo, useState } from 'react';
import { arrayOf, object, func, any } from 'prop-types';
import * as propTypes from './propTypes';
import Row from './Row';
import { AlertIdDispatchContext, AlertIdStateContext } from './context';
import { TableDiv, HeaderCellDiv } from './styledComponents';

const Table = ({
  columns,
  data,
  dataKeyGenerator,
  additionalContext,
  alert,
  alertIdSelector,
  summaryRow,
}) => {
  const [alertId, setAlertId] = useState(null);
  const { tableStyle } = useMemo(() => {
    const columnCount = columns.length;

    let hasCustomWidth = false;
    const columnTemplate = columns.reduce((acc, column) => {
      if (column.cellWidth) {
        hasCustomWidth = true;
        return `${acc} ${column.cellWidth}`;
      }
      return `${acc} 1fr`;
    }, '');

    const ts = {
      '--column-count': columnCount,
    };

    if (hasCustomWidth) {
      ts.gridTemplateColumns = columnTemplate;
    }

    const tss = hasCustomWidth
      ? `--column-count: ${columnCount}; grid-template-columns: ${columnTemplate};`
      : `--column-count: ${columnCount};`;

    return {
      tableStyle: ts,
      tableStyleStr: tss,
    };
  }, [columns]);

  return (
    <AlertIdStateContext.Provider value={alertId}>
      <AlertIdDispatchContext.Provider value={setAlertId}>
        <TableDiv style={tableStyle}>
          {columns.map((c, index) => {
            let className = '';
            if (index + 1 === columns.length) {
              className = 'last';
            }
            if (c.className) {
              className += ` ${c.className}`;
            }

            return (
              <HeaderCellDiv key={c.name} className={className}>
                {c.headerRender
                  ? c.headerRender({ column: c, columnIndex: index })
                  : c.name}
              </HeaderCellDiv>
            );
          })}
          {data.map((d, index) => {
            if (!d) {
              return null;
            }
            return (
              <Row
                columns={columns}
                data={d}
                key={dataKeyGenerator(d, index)}
                additionalContext={additionalContext}
                alert={alert}
                alertIdSelector={alertIdSelector}
                summaryRow={summaryRow}
              />
            );
          })}
        </TableDiv>
      </AlertIdDispatchContext.Provider>
    </AlertIdStateContext.Provider>
  );
};

Table.defaultProps = {
  additionalContext: undefined,
  alert: undefined,
  alertIdSelector: undefined,
  summaryRow: undefined,
};

Table.propTypes = {
  columns: propTypes.columns.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: arrayOf(object).isRequired,
  dataKeyGenerator: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalContext: any,
  alert: func,
  alertIdSelector: func,
  summaryRow: func,
};

export default Table;
