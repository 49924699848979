import { string, bool } from 'prop-types';
import Banner from '../../Banner';
import Page from '../../Page';
import Form from '../../Form';
import Help from '../../Help';
import PageHelp from './PageHelp';
import Security from '../../Security';
import * as AddProviderHelp from './IdentityProviderAddHelp';
import SelectIDPType from './SelectIDPType';
import { genericURLRegex } from '../../../utils/regex';

const display = {
  header: 'Add an Identity Provider',
  cancelButton: 'Cancel',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  boxTitle: 'Details',
  generalContentHeader: 'General',
  formContentHeader: 'Connection',
  wellKnownConfigUrlLabel: 'Well Known Config URL',
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  nameLabel: 'Name',
  wellKnownConfigUrlRequiredError: 'Well Known Config URL is required',
  clientIdRequiredError: 'Client ID is required',
  clientSecretRequiredError: 'Client Secret is required',
  nameRequiredError: 'Name is required',
  addButton: 'Add Identity Provider',
  addingButton: 'Adding Identity Provider...',
  urlError:
    'Well Known Config URL must be in the format: https://[hostname] or http://[hostname]',
};

const AddIdentityProviderForm = ({
  submitInProgress,
  submitSuccess,
  usePop,
  error,
  oauth2ClientAppRedirectUrl,
}) => {
  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute: Security.route.getRoute,
    usePop,
  });

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <SelectIDPType disabled={submitInProgress} error={error?.type} />
          <Page.Box>
            <Page.BoxHeader>
              <h2>{display.boxTitle}</h2>
            </Page.BoxHeader>
            {error && <Banner title={error} />}
            <Page.FormContent>
              <div>
                <h3>{display.generalContentHeader}</h3>
              </div>
              <div>
                <Form.TextInput
                  name='name'
                  label={display.nameLabel}
                  disabled={submitInProgress}
                  validationRules={{
                    required: display.nameRequiredError,
                  }}
                  fieldHelp={AddProviderHelp.Name}
                />
              </div>
              <div>
                <h3>{display.formContentHeader}</h3>
              </div>
              <div>
                <Form.TextInput
                  name='wellKnownConfigUrl'
                  label={display.wellKnownConfigUrlLabel}
                  disabled={submitInProgress || false}
                  validationRules={{
                    required: display.wellKnownConfigUrlRequiredError,
                    pattern: {
                      value: genericURLRegex,
                      message: display.urlError,
                    },
                  }}
                  fieldHelp={AddProviderHelp.WellKnownConfigUrl}
                />
                <Form.TextInput
                  name='clientId'
                  label={display.clientIdLabel}
                  disabled={submitInProgress || false}
                  validationRules={{
                    required: display.clientIdRequiredError,
                  }}
                  fieldHelp={AddProviderHelp.ClientId}
                />
                <Form.PasswordInput
                  name='clientSecret'
                  label={display.clientSecretLabel}
                  disabled={submitInProgress || false}
                  validationRules={{
                    required: display.clientSecretRequiredError,
                  }}
                  fieldHelp={AddProviderHelp.ClientSecret}
                />
              </div>
            </Page.FormContent>
          </Page.Box>
          <Page.Buttons>
            <Form.SecondaryButton
              onClick={onCancel}
              label={display.cancelButton}
              disabled={submitInProgress}
              disabledOnErrors={false}
            />
            <Form.Submit
              label={
                !submitInProgress ? display.addButton : display.addingButton
              }
              disabled={submitInProgress}
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

AddIdentityProviderForm.defaultProps = {
  usePop: true,
  error: null,
};

AddIdentityProviderForm.propTypes = {
  submitInProgress: bool.isRequired,
  submitSuccess: bool.isRequired,
  usePop: bool,
  error: string,
  oauth2ClientAppRedirectUrl: string.isRequired,
};

export default AddIdentityProviderForm;
