import styled from 'styled-components/macro';
import { FormContentDiv } from '../../Page/styledComponents';
import { colors, fonts } from '../../../css';

export const NoDataDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;

export const SpanningContentDiv = styled(FormContentDiv)`
  grid-template-columns: auto;
  grid-column-gap: 40px;
`;

export const IDPTypeOptionsDiv = styled.div`
  padding: 24px;
  padding-bottom: 0;

  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    margin-left: 0;
    width: 100%;

    > label {
      ${fonts.medium_bold}
      letter-spacing: 0;
      color: ${colors.font_light};
      padding: 0;
      border: 1px solid ${colors.border};
      border-radius: 4px;
      background-position: right 16px top 16px;

      &:after {
        display: none;
      }
    }
  }
`;

export const IDPTypeOptionDiv = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 40px 24px 24px;

  > img {
    max-height: 180px;
  }

  div {
    font-weight: 700;
    font-size: 24px;
  }

  div.coming-soon {
    margin-top: 5px;
    ${fonts.small}
    color: ${colors.error};
  }
`;
