import { string } from 'prop-types';
import Page from '../Page';
import Forbidden from './components/Forbidden';

const ForbiddenPage = ({ title, code, description }) => {
  return (
    <Page.StandardLayout>
      <Forbidden title={title} code={code} description={description} />
    </Page.StandardLayout>
  );
};

ForbiddenPage.propTypes = {
  title: string,
  code: string,
  description: string,
};
ForbiddenPage.defaultProps = {
  title: undefined,
  code: undefined,
  description: undefined,
};
export default ForbiddenPage;
