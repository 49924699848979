import { oneOfType, string } from 'prop-types';
import Data from '../../Data';
import DataSources from '../../DataSources';
import { AwsRegion } from '../../AwsRegion';

const display = {
  formContentHeader: 'Access',
  regionLabel: 'Region',
  s3RoleLabel: 'S3 Role ARN',
  glueRoleLabel: 'Glue Role ARN',
  glueDataCatalogIdLabel: 'Glue Data Catalog ID',
  accessKeyLabel: 'Access Key',
  secretLabel: 'Secret',
};

const GlueConnectionDetails = ({ hive, credentialType }) => {
  if (!hive) {
    return null;
  }

  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
      </div>
      <div>
        <Data.DataField label={display.regionLabel}>
          <AwsRegion regionValue={hive['metastore.glue']?.region} />
        </Data.DataField>
        {hive['metastore.glue'].catalogid && (
          <Data.DataField
            label={display.glueDataCatalogIdLabel}
            value={hive['metastore.glue'].catalogid}
          />
        )}
        {credentialType === 'iam' && (
          <>
            <Data.DataField
              label={display.s3RoleLabel}
              value={hive.s3['iam-role']}
            />
            <Data.DataField
              label={display.glueRoleLabel}
              value={hive['metastore.glue']['iam-role']}
            />
          </>
        )}
        {credentialType === 'access_key' && (
          <>
            <Data.DataField
              label={display.accessKeyLabel}
              value={hive.s3['aws-access-key']}
            />
            <Data.DataField
              label={display.secretLabel}
              value={hive.s3['aws-secret-key']}
              isPrivate
            />
          </>
        )}
      </div>
    </>
  );
};

GlueConnectionDetails.defaultProps = {
  credentialType: undefined,
};

GlueConnectionDetails.propTypes = {
  hive: oneOfType([
    DataSources.propTypes.GlueConnection.hive,
    DataSources.propTypes.GlueWithLakeFormationConnection.hive,
  ]).isRequired,
  credentialType: string,
};

export default GlueConnectionDetails;
