import * as actions from './actions';
import * as actionTypes from './actionTypes';
import Container from './Container';
import reducer from './reducer';
import * as route from './route';
import * as selectors from './selectors';

const module = {
  actions,
  actionTypes,
  Container,
  reducer,
  route,
  selectors,
};

export default module;
