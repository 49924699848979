import Container from './Container';
import reducer from './reducer';
import saga from './saga';

const module = {
  Container,
  reducer,
  saga,
};

export default module;
