import { useMemo, useCallback, useEffect, useState } from 'react';
import { number, func, string, shape, bool } from 'prop-types';
import Form from '../../Form';
import { onAccessibleClick } from '../../utils/accessibility';

const maxWorkerNodes = 100;
const display = {
  workerNodesLabel: 'Number of nodes',
  workerNodesRequiredError: 'Worker nodes is required',
  updateButtonLabel: 'Update',
  updatingButtonLabel: 'Updating...',
  cancelButtonLabel: 'Cancel',
};

const buildWorkerNodesRangeErrorMessage = (min = 1, max = maxWorkerNodes) => {
  return `Worker nodes must be between ${min} and ${max}`;
};

const mustBeIntegerInRange = (min, max, errorMessage) => {
  return (value) => {
    const i = parseInt(value, 10);
    if (!i || i < min || i > max) {
      return errorMessage;
    }
    return true;
  };
};

const EditWorkerNodes = ({
  prestoClusterId,
  currentNodeCount,
  actionStatus,
  close,
  setWorkerNodes,
  autoScaling,
  maxWorkerNodeCount,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const workerNodeRangeValidation = useMemo(() => {
    // If cluster has "scale out" auto-scaling, we use the user-defined min and max of worker nodes
    if (autoScaling && autoScaling.type === 'cpu') {
      return mustBeIntegerInRange(
        autoScaling.minWorkerNodes,
        autoScaling.maxWorkerNodes,
        buildWorkerNodesRangeErrorMessage(
          autoScaling.minWorkerNodes,
          autoScaling.maxWorkerNodes,
        ),
      );
    }

    return mustBeIntegerInRange(
      1,
      maxWorkerNodeCount,
      buildWorkerNodesRangeErrorMessage(1, maxWorkerNodeCount),
    );
  }, [autoScaling, maxWorkerNodeCount]);

  const save = useCallback(
    (values) => {
      const submitId = Date.now();
      setSubmitting(submitId);
      return setWorkerNodes(prestoClusterId, values, submitId);
    },
    [setWorkerNodes, prestoClusterId],
  );

  const cancel = useCallback(
    (event) => {
      return onAccessibleClick(event, () => {
        return close();
      });
    },
    [close],
  );

  const submitInProgress = useMemo(() => {
    return (
      actionStatus &&
      actionStatus.inProgress &&
      actionStatus.actionId === submitting
    );
  }, [actionStatus, submitting]);

  useEffect(() => {
    if (
      actionStatus &&
      actionStatus.success &&
      actionStatus.actionId === submitting
    ) {
      close();
    }
  }, [actionStatus, submitting, close]);

  return (
    <Form.Form name='edit-worker-nodes' submitAction={save} horizontal>
      <Form.TextInput
        name='workerNodes'
        type='integer'
        label={display.workerNodesLabel}
        defaultValue={currentNodeCount}
        horizontal
        validationRules={{
          required: display.workerNodesRequiredError,
          pattern: {
            value: /^[0-9]+$/,
            message: display.workerNodesRangeError,
          },
          validate: workerNodeRangeValidation,
        }}
      />
      <Form.SecondaryButton
        onClick={cancel}
        label={display.cancelButtonLabel}
        disabled={submitInProgress}
        disabledOnErrors={false}
      />
      <Form.SubmitEditNode
        label={
          !submitInProgress
            ? display.updateButtonLabel
            : display.updatingButtonLabel
        }
        disabled={submitInProgress}
        showLoading={submitInProgress}
      />
    </Form.Form>
  );
};

EditWorkerNodes.defaultProps = {
  actionStatus: undefined,
  autoScaling: null,
};

EditWorkerNodes.propTypes = {
  prestoClusterId: string.isRequired,
  currentNodeCount: number.isRequired,
  close: func.isRequired,
  setWorkerNodes: func.isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  autoScaling: shape({
    type: string.isRequired,
  }),
  maxWorkerNodeCount: number.isRequired,
};

export default EditWorkerNodes;
