import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const submit = (values) => {
  return {
    type: actionTypes.submit,
    payload: {
      body: values,
    },
  };
};

export const post = (body) => {
  return createApiAction(actionTypes.post, api.post, true, null, body);
};
