import { shape, string, oneOfType, object } from 'prop-types';

export const RangerHiveConnection = {
  hive: shape({
    ranger: shape({
      policy: shape({
        'hive-servicename': string,
      }),
      service: shape({
        'basic-auth-password': string,
        'basic-auth-username': string,
      }),
      'rest-endpoint': string,
    }),
    security: string,
  }),
};

export const AuthorizationService = shape({
  authorizationServiceId: string.isRequired,
  name: string.isRequired,
  type: string.isRequired,
  description: string,
  tenantId: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  catalogFiles: object,
  connection: oneOfType([shape(RangerHiveConnection)]),
  createDate: string.isRequired,
  updateDate: string.isRequired,
});
