import { string } from 'prop-types';
import SyntaxHighlighting from '../SyntaxHighlighting';
import Page from '../Page';
import { CodeDiv } from './styledComponents';

const CodeBox = ({ code, language }) => {
  return (
    <Page.Box>
      <CodeDiv>
        <SyntaxHighlighting code={code} language={language} />
      </CodeDiv>
    </Page.Box>
  );
};

CodeBox.propTypes = {
  code: string.isRequired,
  language: string.isRequired,
};

export default CodeBox;
