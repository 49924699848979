import {
  connect as reduxConnect,
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from 'react-redux';
import logger from './logger';

/**
 * Wraps mapStateToProps to add error handling.
 * @param {function} mapStateToProps - The original mapStateToProps function.
 * @returns {function} - The wrapped mapStateToProps function with error handling.
 */
const wrapMapStateToProps = (mapStateToProps) => {
  if (!mapStateToProps || typeof mapStateToProps !== 'function') {
    return mapStateToProps;
  }

  return (state, props) => {
    try {
      return mapStateToProps(state, props);
    } catch (error) {
      logger.error('mapStateToProps error', error);
      throw error;
    }
  };
};

/**
 * Wraps dispatchProps with error handling.
 * @param {object} actionCreators - The action creators to be wrapped, normally is an object where each key in the object is assumed to be an action creator function.
 * @returns {function} -  A function that returns an object where each key is an action creator function bound to the dispatch function with error handling.
 */
const wrapDispatchProps = (actionCreators) => {
  if (!actionCreators || typeof actionCreators !== 'object') {
    return actionCreators;
  }

  return (dispatch) => {
    return Object.entries(actionCreators).reduce(
      (wrappedDispatchProps, kvp) => {
        // eslint-disable-next-line no-param-reassign
        wrappedDispatchProps[kvp[0]] = wrapWithTryCatch(kvp[1], dispatch);
        return wrappedDispatchProps;
      },
      {},
    );
  };
};

/**
 * Wraps an action creator with error handling.
 * @param {function} actionCreator - The original action creator.
 * @param {function} dispatch - The Redux dispatch function.
 * @returns {function} - The wrapped action creator with error handling.
 */
const wrapWithTryCatch = (actionCreator, dispatch) => {
  return (...args) => {
    try {
      dispatch(actionCreator(...args));
    } catch (error) {
      logger.error('dispatch error', error);
      throw error;
    }
  };
};

export const connect = (
  mapStateToProps,
  dispatchProps,
  mergeProps,
  options,
) => {
  return reduxConnect(
    wrapMapStateToProps(mapStateToProps),
    wrapDispatchProps(dispatchProps),
    mergeProps,
    options,
  );
};

/**
 * Custom hook that wraps useSelector with error handling.
 * @param {function} selector - The selector function to extract state.
 * @returns {*} - The selected state.
 */
export const useSelector = (selector) => {
  try {
    return useReduxSelector(selector);
  } catch (error) {
    logger.error('useSelector error', error);
    throw error;
  }
};

/**
 * Custom hook that wraps useDispatch with error handling.
 * @returns {function} - The dispatch function.
 */
export const useDispatch = () => {
  const dispatch = useReduxDispatch();

  return (actionCreator, ...args) => {
    try {
      const action = actionCreator(...args);
      return dispatch(action);
    } catch (error) {
      logger.error('useDispatch error', error);
      throw error;
    }
  };
};
