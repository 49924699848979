import { apiVerbs, createApiActionType } from '../../rayApi/actionTypes';

const componentName = 'PRESTO_CLUSTERS';
export const getAll = createApiActionType(apiVerbs.GET, `${componentName}_ALL`);
export const get = createApiActionType(apiVerbs.GET, componentName);
export const getDataSources = createApiActionType(
  apiVerbs.GET,
  `${componentName}_DATA_SOURCES`,
);
export const patch = createApiActionType(apiVerbs.PATCH, componentName);
export const remove = createApiActionType(apiVerbs.DELETE, componentName);
export const putRestart = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_RESTART`,
);
export const putStart = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_START`,
);
export const putStop = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_STOP`,
);
export const patchWorkerNodes = createApiActionType(
  apiVerbs.PATCH,
  `${componentName}_WORKER_NODES`,
);
export const putDataSources = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_DATA_SOURCES`,
);
export const submitCredentials = `${componentName}_SUBMIT_CREDENTIALS`;
export const putCredentials = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_CREDENTIALS`,
);
export const putExportLogs = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_EXPORT_LOGS`,
);
export const putExportDebuggingInfo = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_EXPORT_DEBUGGING_INFO`,
);
export const putTriggerPrestoDebugging = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_TRIGGER_PRESTO_DEBUGGING`,
);

export const putAutoScalingPolicy = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_AUTOSCALING_POLICY`,
);

export const putGroupedConfig = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_GROUPED_CONFIG`,
);

export const putEnableSpot = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_ENABLE_SPOT`,
);

export const putPrestoUsers = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_PRESTO_USERS`,
);
