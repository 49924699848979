import { useEffect } from 'react';
import { func, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Form from '../../Form';
import Page from '../../Page';
import useRouteBuilder from '../../../router/useRouteBuilder';
import Data from '../../Data';

const display = {
  backButton: 'Back',
  copyButton: 'Copy',
};

const Policy = ({ onClose, policyContent, policyTitle }) => {
  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');

  useEffect(() => {
    if (!policyContent) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [policyContent, navigate, notFoundRoute]);

  if (!policyContent) {
    return <Page.Loading />;
  }

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{policyTitle}</h2>
        <div className='buttons'>
          <Form.SecondaryButton onClick={onClose}>
            {display.backButton}
          </Form.SecondaryButton>
        </div>
      </Page.BoxHeader>
      <Data.CodeBox code={policyContent} language='json' />
    </Page.Box>
  );
};

Policy.propTypes = {
  onClose: func.isRequired,
  policyContent: string.isRequired,
  policyTitle: string.isRequired,
};

export default Policy;
