import { useMemo } from 'react';
import { string, arrayOf } from 'prop-types';
import { DetailsHeader as Header } from './styledComponents';
import Page from '../../Page';
import AuthorizationServices from '../../AuthorizationServices';
import { useEnableFeature } from '../../utils/featureHooks';
import PrestoClusters from '../../PrestoClusters';

const display = {
  editAuthorizationServiceButton: 'Edit',
};

const DetailsHeader = ({ title, authorizationServiceId, prestoClusters }) => {
  const enableManageLink = useEnableFeature();
  const atLeastOneClusterPending = useMemo(() => {
    return prestoClusters === null
      ? false
      : prestoClusters.some((pc) => {
          return (
            PrestoClusters.constants.getSimpleStatus(
              pc.status,
              pc.workflowInProgress,
            ) === PrestoClusters.constants.simpleStatus.pending
          );
        });
  }, [prestoClusters]);

  return (
    <Header>
      <h2>{title}</h2>
      <div className='buttons'>
        <Page.PrimaryLink
          to={AuthorizationServices.routes.getUpdateAuthorizationServiceRoute(
            authorizationServiceId,
          )}
          disabled={!enableManageLink || atLeastOneClusterPending}
        >
          {display.editAuthorizationServiceButton}
        </Page.PrimaryLink>
      </div>
    </Header>
  );
};

DetailsHeader.defaultProps = {
  prestoClusters: null,
};

DetailsHeader.propTypes = {
  title: string.isRequired,
  authorizationServiceId: string.isRequired,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
};

export default DetailsHeader;
