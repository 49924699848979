import { bool, object } from 'prop-types';
import Form from '../../Form';
import Help from './EnableJMXHelp';

const display = {
  formContentHeader: 'Presto Metrics',
  EnableJMXLabel: 'Enable sending Presto metrics to IBM',
};

const EnableJMX = ({ disabled, prometheus }) => {
  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
      </div>
      <div>
        <Form.CheckboxInput
          name='prometheus.enableJMX'
          label={display.EnableJMXLabel}
          disabled={disabled}
          defaultChecked={!!prometheus?.enableJMXMetrics}
          fieldHelp={Help.enableJMX}
        />
      </div>
    </>
  );
};

EnableJMX.defaultProps = {
  disabled: false,
  prometheus: null,
};

EnableJMX.propTypes = {
  disabled: bool,
  // eslint-disable-next-line react/forbid-prop-types
  prometheus: object,
};

export default EnableJMX;
