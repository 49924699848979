import { shape, string } from 'prop-types';
import Label from './Label';
import { DataDivDiv } from './styledComponents';

const DataFieldWithLogo = ({ logo }) => {
  return (
    <DataDivDiv>
      <img src={logo.Logo} alt={logo.alt} />
      <Label label={logo.title} />
    </DataDivDiv>
  );
};

DataFieldWithLogo.defaultProps = {
  logo: null,
};

DataFieldWithLogo.propTypes = {
  logo: shape({
    Logo: string,
    alt: string,
    title: string,
  }),
};

export default DataFieldWithLogo;
