import { takeEvery, call, put, all } from 'redux-saga/effects';
import { bcryptPassword } from '../../utils/bcrypt';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as PrestoUsersUpdateActions from '../PrestoUsersUpdate/actions';
import * as PrestoUsersUpdateActionTypes from '../PrestoUsersUpdate/actionTypes';

function* cancelableCreateOrUpdatePrestoUser(action) {
  const { values } = action.payload;
  const passwordHash = yield call(bcryptPassword, values.password);

  const body = {
    type: values.type,
    username: values.username,
    password: passwordHash,
  };

  if (action.type === actionTypes.submit) {
    yield put(actions.post(body));
  }

  if (action.type === PrestoUsersUpdateActionTypes.submit) {
    yield put(PrestoUsersUpdateActions.patch(values.prestoUserId, body));
  }
}

export default function* prestoUserAddRootSaga() {
  yield all([
    takeEvery(actionTypes.submit, cancelableCreateOrUpdatePrestoUser),
    takeEvery(
      PrestoUsersUpdateActionTypes.submit,
      cancelableCreateOrUpdatePrestoUser,
    ),
  ]);
}
