import CheckboxField from './CheckboxField';
import CodeBox from './CodeBox';
import CodeField from './CodeField';
import DataField from './DataField';
import DataFieldWithCopy from './DataFieldWithCopy';
import DataFieldWithLink from './DataFieldWithLink';
import DataFieldWithLogo from './DataFieldWithLogo';
import Label from './Label';
import RadioField from './RadioField';
import { ButtonGroupDiv, Tooltip } from './styledComponents';
import MultiLineDataField from './MultiLineDataField';

const module = {
  ButtonGroup: ButtonGroupDiv,
  CheckboxField,
  CodeBox,
  CodeField,
  DataField,
  DataFieldWithCopy,
  DataFieldWithLink,
  DataFieldWithLogo,
  Label,
  RadioField,
  Tooltip,
  MultiLineDataField,
};

export default module;
