import Help from '../../Help';

const forceGenerate = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Force Heap Dump Generation</Help.HelpHeader>
      <Help.P>
        If you choose to force generate a heap dump, you must specify the node
        names where the heap dumps will be generated and transferred.
      </Help.P>
      <Help.P>
        If you choose not to force generate, specifying node names is optional.
        The heap dumps available on all nodes will be transferred.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  forceGenerate,
};

export default module;
