/* eslint-disable no-unused-vars */
import { takeEvery, call, put, all } from 'redux-saga/effects';
import ComputePlane from '../ComputePlane';
import FormWizard from '../FormWizard';
import rayApi from '../../rayApi';
import * as actions from './actions';
import * as actionTypes from './actionTypes';

function* updateComputePlaneHandler(action) {
  const {
    computePlaneId,
    updatedData,
    nextStepId,
    isComputeConfigurationDirty = false,
    shouldReverifyRole = false,
  } = action.payload;
  // Make the API call to update the compute plane.
  const isUpdateSuccessful = yield call(
    rayApi.saga.callApi,
    ComputePlane.actions.patch(computePlaneId, { ...updatedData }),
  );

  if (isUpdateSuccessful) {
    if (shouldReverifyRole) {
      yield put(actions.resetHasVerifyRoleSuccess());
    }
    // Make the API call to get the compute plane again.
    yield call(rayApi.saga.callApi, ComputePlane.actions.get(computePlaneId));
    // Refetch the configuration when its considered dirty
    if (isComputeConfigurationDirty) {
      yield call(
        rayApi.saga.callApi,
        ComputePlane.actions.getConfiguration(computePlaneId),
      );
    }

    // Call the setNextStepId action
    yield put(FormWizard.actions.setActiveStep(nextStepId));
  }

  yield put(actions.setSubmitInProgress({ setSubmitInProgress: false }));
}

function* deployComputePlaneHandler(action) {
  const { computePlaneId, nextStepId } = action.payload;
  // call the deploy api
  const isDeployApiSuccessful = yield call(
    rayApi.saga.callApi,
    ComputePlane.actions.deploy(computePlaneId),
  );

  if (isDeployApiSuccessful) {
    // Make the API call to get the compute plane again.
    yield call(rayApi.saga.callApi, ComputePlane.actions.get(computePlaneId));
    // Call the setNextStepId action
    yield put(FormWizard.actions.setActiveStep(nextStepId));
  }
  yield put(actions.setSubmitInProgress({ setSubmitInProgress: false }));
}

function* destroyComputePlaneHandler(action) {
  const { computePlaneId, nextStepId } = action.payload;
  // call the destroy api
  const isDestroyApiSuccessful = yield call(
    rayApi.saga.callApi,
    ComputePlane.actions.destroy(computePlaneId),
  );

  if (isDestroyApiSuccessful) {
    // Make the API call to get the compute plane again.
    yield call(rayApi.saga.callApi, ComputePlane.actions.get(computePlaneId));
  }
  yield put(actions.setSubmitInProgress({ setSubmitInProgress: false }));
}

export default function* computePlaneSaga() {
  yield all([
    takeEvery(actionTypes.updateComputePlane, updateComputePlaneHandler),
    takeEvery(actionTypes.requestDeploy, deployComputePlaneHandler),
    takeEvery(actionTypes.requestDestroy, destroyComputePlaneHandler),
  ]);
}
