import { string } from 'prop-types';

const Edit = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.4 10.288l11.312 11.3146667L11.312 48H0V36.6853333l26.4-26.4V10.288zm3.7706667-3.7706667l5.656-5.65866663c1.0413329-1.04101851 2.7293337-1.04101851 3.7706666 0l7.544 7.54400003c1.0410185 1.0413329 1.0410185 2.7293337 0 3.7706666l-5.6586666 5.656-11.312-11.312z'
        fillRule='evenodd'
      />
    </svg>
  );
};

Edit.defaultProps = {
  className: undefined,
};

Edit.propTypes = {
  className: string,
};

export default Edit;
