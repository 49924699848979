import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const submit = (computePlaneId, values) => {
  return {
    type: actionTypes.submit,
    payload: {
      computePlaneId,
      values,
    },
  };
};

export const put = (computePlaneId, body) => {
  return createApiAction(
    actionTypes.put,
    api.put,
    true,
    { computePlaneId },
    body,
  );
};
