import * as environment from '../utils/environment';

let port = window.location.port ? `:${window.location.port}` : '';

let hostname;
if (environment.isProductionUI()) {
  hostname = 'ibmlh-api.cvpc.lakehouse.cloud.ibm.com';
} else if (environment.isStagingUI()) {
  hostname = 'ibmlh-api.staging.cvpc.lakehouse.test.cloud.ibm.com';
} else if (environment.isLocalhost()) {
  hostname = 'localhost';
  port = ':3015';
} else {
  const subdomain = window.location.hostname.split('.')[1];
  hostname = `ibmlh-api.${subdomain}.dev.cvpc.lakehouse.test.cloud.ibm.com`;
}

export const api = `${window.location.protocol}//${hostname}${port}/`;
