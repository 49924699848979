import { colors } from './colors';
import { fonts } from './fonts';

const baseButton = `
  ${fonts.medium_bold}
  border: 1px solid ${colors.border};
  border-radius: 4px;
  box-shadow: 0 2px 1px 0 ${colors.input_shadow};
  padding: 10px 20px;
  min-height: 3rem;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;

  > div {
    padding-bottom: 2px;
    border-bottom: 1px solid transparent;
    > svg {
      display: block;
      height: 19px;
    }
  }

  &:focus,
  &:active,
  &:hover {
    text-decoration: underline;
    outline: none;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;

    &:focus,
    &:active,
    &:hover {
      text-decoration: none;
    }
  }

  & + button,
  & + a,
  & + input[type='submit'],
  & + div > button {
    margin-left: 11px;
  }

`;

const primaryButton = `
  background-color: ${colors.primary_button};
  color: ${colors.primary_button_font};
  border-color: ${colors.primary_button_border};
  border-radius: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  &:any-link {
    color: ${colors.primary_button_font};
  }

  &:disabled,
  &[disabled] {
    background-color: ${colors.primary_button_disabled};
    border-color: ${colors.primary_button_disabled_border};
    background-image: linear-gradient(
      to bottom,
      ${colors.primary_button_disabled_gradient1},
      ${colors.primary_button_disabled_gradient1} 50%,
      ${colors.primary_button_disabled_gradient2}
    );
  }
`;

const secondaryButton = `
  background-color: ${colors.secondary_button};
  color: ${colors.secondary_button_font};
  border-color: ${colors.secondary_button_border};
  border-radius: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  &:disabled,
  &[disabled] {
    background-color: ${colors.secondary_button_disabled};
    border-color: ${colors.secondary_button_disabled_border};
    background-image: linear-gradient(
      to bottom,
      ${colors.secondary_button_disabled_gradient1},
      ${colors.secondary_button_disabled_gradient1} 50%,
      ${colors.secondary_button_disabled_gradient2}
    );
  }
`;

const tertiaryButton = `
  background-color: transparent;
  color: ${colors.tertiary_button_font};
  border-color: ${colors.tertiary_button_border};
  border-radius: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  svg {
    fill: ${colors.tertiary_button_font};
  }
  &:focus,
  &:active,
  &:hover {
    div {
      border-bottom-color: ${colors.tertiary_button_font};
    }
  }

  &:disabled,
  &[disabled] {
    color: ${colors.font_disabled};
    background-color: ${colors.section_background};
    border-color: ${colors.border};
    background-image: none;

    svg {
      fill: ${colors.font_disabled};
    }

    &:focus,
    &:active,
    &:hover {
      div {
        border-bottom-color: ${colors.font_disabled};
      }
    }
  }
`;

const errorButton = `
  background-color: ${colors.error_button};
  color: ${colors.error_button_font};
  border-color: ${colors.error_button_border};
  border-radius: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  &:disabled,
  &[disabled] {
    background-color: ${colors.error_button_disabled};
    border-color: ${colors.error_button_disabled_border};
  }
`;

const successButton = `
  background-color: ${colors.success_button};
  background-image: linear-gradient(
    to bottom,
    ${colors.success_button_gradient1},
    ${colors.success_button_gradient1} 50%,
    ${colors.success_button_gradient2}
  );
  color: ${colors.success_button_font};
  border-color: ${colors.success_button_border};

  &:disabled,
  &[disabled] {
    background-color: ${colors.success_button_disabled};
    border-color: ${colors.success_button_disabled_border};
    background-image: linear-gradient(
      to bottom,
      ${colors.success_button_disabled_gradient1},
      ${colors.success_button_disabled_gradient1} 50%,
      ${colors.success_button_disabled_gradient2}
    );
  }
`;

const warningButton = `
  background-color: ${colors.warning_button};
  background-image: linear-gradient(
    to bottom,
    ${colors.warning_button_gradient1},
    ${colors.warning_button_gradient1} 50%,
    ${colors.warning_button_gradient2}
  );
  color: ${colors.warning_button_font};
  border-color: ${colors.warning_button_border};
  border-radius: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  &:disabled,
  &[disabled] {
    background-color: ${colors.warning_button_disabled};
    border-color: ${colors.warning_button_disabled_border};
    background-image: linear-gradient(
      to bottom,
      ${colors.warning_button_disabled_gradient1},
      ${colors.warning_button_disabled_gradient1} 50%,
      ${colors.warning_button_disabled_gradient2}
    );
  }
`;

const module = {
  baseButton,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  errorButton,
  successButton,
  warningButton,
};

export default module;
