import styled from 'styled-components/macro';
import { fonts, colors } from '../../css';

const BadgeSpan = styled.span`
  ${fonts.small}
  padding: 2.5px 9px;
  border: 1px solid;
  border-radius: 4px;
  text-transform: uppercase;
`;

export const OptionalBadgeSpan = styled(BadgeSpan)`
  border-color: ${colors.border};
  color: ${colors.white};
  background-color: ${colors.secondary_button};
`;

export const ErrorBadgeSpan = styled(BadgeSpan)`
  border-color: ${colors.badge_error_border};
  color: ${colors.badge_error_font};
  background-color: ${colors.badge_error_background};
`;

export const WarningBadgeSpan = styled(BadgeSpan)`
  border-color: ${colors.badge_warning_border};
  color: ${colors.badge_warning_font};
  background-color: ${colors.badge_warning_background};
`;

export const SuccessBadgeSpan = styled(BadgeSpan)`
  border-color: ${colors.badge_success_border};
  color: ${colors.badge_success_font};
  background-color: ${colors.badge_success_background};
`;
