import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import * as actionTypes from './actionTypes';

const initialState = {
  submitInProgress: false,
  submitSuccess: false,
  error: undefined,
};

const onNavigation = () => {
  return { ...initialState };
};

const submitStart = (draft) => {
  draft.submitInProgress = true;
  draft.error = undefined;
};

const submitSuccess = (draft) => {
  draft.submitInProgress = false;
  draft.submitSuccess = true;
  draft.error = undefined;
};

const submitFailure = (draft, action) => {
  draft.submitInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.resetForm]: onNavigation,
  [actionTypes.submit]: submitStart,
  [actionTypes.post.success]: submitSuccess,
  [actionTypes.post.failure]: submitFailure,
  [auth.actionTypes.signOut]: signOut,
};

export default makeReducer(initialState, actionReducers);
