import Help from '../../Help';

const Section = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Elasticsearch</Help.HelpHeader>
      <Help.P>something</Help.P>
    </Help.HelpSection>
  );
};

const Host = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Endpoint</Help.HelpHeader>
      <Help.P>
        The URL of the OpenSearch instance (e.g.
        https://search-es-abcd.us.east-1.es.amazonaws.com).
      </Help.P>
    </Help.HelpSection>
  );
};

const TLSEnabled = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>TLS Encryption</Help.HelpHeader>
      <Help.P>
        Amazon OpenSearch TLS Encryption is on by default. Un-select if your
        OpenSearch instance has the option &quot;Require HTTPS&quot; set to
        Disabled in your ElasticSearch dashboard.
      </Help.P>
    </Help.HelpSection>
  );
};

const AWSRegion = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Region</Help.HelpHeader>
      <Help.P>The AWS region where the OpenSearch instance is located.</Help.P>
    </Help.HelpSection>
  );
};

const AWSAccessKey = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Access Key ID</Help.HelpHeader>
      <Help.P>
        The AWS access key for the user with permission to connect to your
        OpenSearch instance (e.g. AKIRASB3AK3RXMR33PUK).
      </Help.P>
    </Help.HelpSection>
  );
};

const AWSSecretKey = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Secret Access Key</Help.HelpHeader>
      <Help.P>
        The secret access key associated with the access key ID defined above.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  Section,
  Host,
  TLSEnabled,
  AWSRegion,
  AWSAccessKey,
  AWSSecretKey,
};

export default module;
