import { produce, enableMapSet } from 'immer';
import logger from './logger';

enableMapSet();

export const makeReducer = (
  initialState,
  actionHandlers,
  computedActionHandlers,
) => {
  return produce((draft, action) => {
    try {
      if (actionHandlers[action.type]) {
        return actionHandlers[action.type](draft, action);
      }
      if (computedActionHandlers) {
        const actionHandler = computedActionHandlers(action.type);
        if (actionHandler) {
          return actionHandler(draft, action);
        }
      }
    } catch (error) {
      logger.error(`Error in reducer: ${error.message}`, { action, error });
    }
    return draft;
  }, initialState);
};
