import styled from 'styled-components';
import { fonts, colors } from '../../../css';

export const SuccessDiv = styled.div`
  flex: 1 0 auto;
  align-self: center;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    ${fonts.large_bold}
    color: ${colors.font_light};
    margin-bottom: 25px;
  }

  svg {
    width: 250px;
  }
`;

export const LoadingDiv = styled.div`
  margin-top: 40px;
  padding: 24px;
`;

export const VPCTypeOptionsDiv = styled.div`
  padding: 24px;
  padding-bottom: 0;

  > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    margin-left: 0;
    width: 100%;

    > label {
      ${fonts.medium_bold}
      letter-spacing: 0;
      color: ${colors.font_light};
      padding: 0;
      border: 1px solid ${colors.border};
      border-radius: 4px;
      background-position: right 16px top 16px;

      &:after {
        display: none;
      }
    }

    > button {
      margin-left: auto;
    }
  }
`;

export const VPCTypeOptionDiv = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: start;
  padding: 0px 24px 24px 24px;

  p {
    ${fonts.medium}
  }

  div {
    margin-top: 20px;
    text-align: center;
  }
`;

export const RoleCreationSteps = styled.div`
  ol {
    list-style: decimal inside;
  }

  li {
    padding-bottom: 8px;
  }
`;
