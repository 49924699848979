import { createApiAction } from '../rayApi/actions';
import telemetry from '../telemetry';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const signIn = () => {
  return {
    type: actionTypes.signIn,
  };
};

export const signOut = (changeTenant) => {
  return {
    type: actionTypes.signOut,
    payload: {
      changeTenant,
    },
  };
};

export const finishedInitialCheck = () => {
  return {
    type: actionTypes.finishedInitialCheck,
  };
};

export const startRefreshToken = () => {
  return {
    type: actionTypes.startRefreshToken,
  };
};

const actionSanitizer = (action) => {
  let sanitizedAction = action;
  if (action.type === actionTypes.getAuth.success) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        body: {
          ...action.payload.body,
          accessToken: telemetry.sanitize(
            action.payload.apiResult.body.accessToken,
          ),
        },
      },
      sanitized: true,
    };
  }
  return sanitizedAction;
};

telemetry.actionSanitizer.registerActionSanitizer('auth', actionSanitizer);

export const getAuth = () => {
  return createApiAction(actionTypes.getAuth, api.getAuth, false, null, null);
};

export const getCognitoToken = () => {
  return createApiAction(
    actionTypes.getCognitoToken,
    api.getCognitoToken,
    true,
  );
};
