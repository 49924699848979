import { number } from 'prop-types';
import AlertIcon from '../../../icons/Alert';
import Links from '../../Page/Links';
import { FooterDiv, FooterBoxDiv } from './styledComponents';

const Trial = ({ trialDaysLeft }) => {
  return (
    <FooterDiv className='warning'>
      <FooterBoxDiv className='warning'>
        <div className='icon'>
          <AlertIcon />
        </div>
        <div className='message'>
          <h3>
            You are currently within the IBM watsonx.data Cloud Free Trial
          </h3>
          <div>
            Please visit the IBM watsonx.data AWS Marketplace listing to
            subscribe.
          </div>
        </div>
        <div className='details'>
          <h3>{trialDaysLeft} days left</h3>
        </div>
        <div>
          <Links.TertiaryLink
            to={process.env.REACT_APP_MARKETPLACE_URL}
            external
            newTab
          >
            Go to AWS Marketplace
          </Links.TertiaryLink>
        </div>
      </FooterBoxDiv>
    </FooterDiv>
  );
};

Trial.propTypes = {
  trialDaysLeft: number.isRequired,
};

export default Trial;
