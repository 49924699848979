import { useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool, string, oneOfType, number } from 'prop-types';
import Data from '../../Data';
import Form from '../../Form';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import * as constants from '../constants';
import * as utils from '../utils';
import Help from './AutoScalingHelp';
import PrestoClusters from '../../PrestoClusters';

const display = {
  header: 'Cluster Scaling',
  strategyLabel: 'Scaling Strategy',
  staticLabel: 'Static',
  scalingLabel: 'Auto Scaling',
  dynamicTypeLabel: 'Type',
  cpuLabel: 'CPU',
  queuedQueriesLabel: 'Queued Queries',
  defaultWorkerNodesLabel: 'Default Worker Node Count',
  defaultWorkerNodesRequiredError: 'Default worker nodes is required',
  defaultWorkerNodesRangeError: 'Default worker nodes must be between 1 and ',
  idleCostSavingsLabel: 'Scale to a single worker node when idle',
  idleCostSavingsIdleTimeLabel:
    'Time window before scaling to a single worker node',
  cpuScaleInMinWorkerNodesLabel: 'Minimum Worker Node Count',
  cpuScaleInMaxWorkerNodesLabel: 'Maximum Worker Node Count',
  cpuScaleInStepSizeLabel: 'Scaling Step Size',
  cpuScaleInTimeWindowLabel: 'Scale In Time Window',
  timeWindowRequiredError: 'Time window is required',
  timeWindowRangeError: 'Time window must be between 5 and 60 minutes',
  idleTimeSecondLabel: 'Minutes',
  scaleInStepSizeSecondLabel: 'Nodes',
  queryTerminationGracePeriodLabel: 'Query Termination Grace Period',
  queryTerminationGracePeriodSecondLabel: 'Minutes',
  queryTerminationGracePeriodError:
    'Time window must be between 1 and 120 minutes',
  queryTerminationGracePeriodRequiredError: 'Time window is required',
  autoScalingTypeRequiredError: 'Scaling type is required',
  cpuTargetLabel: 'Target (%)',
  queuedQueriesTargetLabel: 'Target',
  cpuTargetError: 'Target must between 0 and 100',
  queuedQueriesTargetError: 'Target must between 0 and 10000',
};

const types = {
  dynamic: 'dynamic',
  idleCostSavings: constants.autoScaling.idleCostSavings,
  cpu: constants.autoScaling.cpu,
  queuedQueries: constants.autoScaling.queuedQueries,
};

const dynamicTypes = [
  {
    display: 'CPU',
    value: 'cpu',
  },
  {
    display: 'Queued Queries',
    value: 'queuedQueries',
  },
];

const AutoScaling = ({
  disabled,
  defaultAutoScaling,
  autoScalingType,
  maxWorkerNodeCount,
}) => {
  const isCommunityEditionTenant = useIsCommunityEditionTenant();

  const { watch, trigger, getValues, setValue } = useFormContext();

  const dynamicType = watch('autoScaling.dynamic.type', types.cpu);

  // Watch dynamicType and update the target value accordingly
  useEffect(() => {
    // Set default target value based on dynamicType
    if (dynamicType === types.cpu) {
      setValue(
        'autoScaling.target',
        defaultAutoScaling && defaultAutoScaling.target
          ? defaultAutoScaling.target
          : 40,
      );
    } else if (dynamicType === types.queuedQueries) {
      setValue(
        'autoScaling.target',
        defaultAutoScaling ? defaultAutoScaling.target : 100,
      );
    }
  }, [dynamicType, defaultAutoScaling, setValue]);

  const workerNodesRangeError = useMemo(() => {
    return `${display.defaultWorkerNodesRangeError} ${maxWorkerNodeCount}`;
  }, [maxWorkerNodeCount]);

  const defaultWorkerNodeRangeValidation = useMemo(() => {
    return () => {
      const values = getValues();
      return utils.mustBeIntegerInRange(
        1,
        maxWorkerNodeCount,
        workerNodesRangeError,
      )(values.autoScaling.workerNodes);
    };
  }, [getValues, maxWorkerNodeCount, workerNodesRangeError]);

  const idleTimeRangeValidation = useMemo(() => {
    return () => {
      const values = getValues();
      return utils.mustBeIntegerInRange(
        5,
        60,
        display.timeWindowRangeError,
      )(values.autoScaling.idleTime);
    };
  }, [getValues]);

  const queryTerminationGracePeriodValidation = useMemo(() => {
    return () => {
      const values = getValues();
      return utils.mustBeIntegerInRange(
        1,
        120,
        display.queryTerminationGracePeriodError,
      )(values.prestoWorkerTerminationGracePeriod);
    };
  }, [getValues]);

  const minimumWorkerNodeRangeValidation = useMemo(() => {
    return () => {
      const values = getValues();
      const validateResult = utils.validateCPUScaleIn(
        values.autoScaling.minWorkerNodes,
        values.autoScaling.maxWorkerNodes,
        values.autoScaling.stepSize,
        maxWorkerNodeCount,
      );

      if (validateResult.trigger) {
        trigger('autoScaling.maxWorkerNodes');
        trigger('autoScaling.stepSize');
      }

      return validateResult.result.minNodes;
    };
  }, [getValues, trigger, maxWorkerNodeCount]);

  const maximumWorkerNodeRangeValidation = useMemo(() => {
    return () => {
      const values = getValues();
      const validateResult = utils.validateCPUScaleIn(
        values.autoScaling.minWorkerNodes,
        values.autoScaling.maxWorkerNodes,
        values.autoScaling.stepSize,
        maxWorkerNodeCount,
      );

      if (validateResult.trigger) {
        trigger('autoScaling.minWorkerNodes');
        trigger('autoScaling.stepSize');
      }

      return validateResult.result.maxNodes;
    };
  }, [getValues, trigger, maxWorkerNodeCount]);

  const stepSizeRangeValidation = useMemo(() => {
    return () => {
      const values = getValues();

      if (!values.autoScaling) return false;

      const validateResult = utils.validateCPUScaleIn(
        values.autoScaling.minWorkerNodes,
        values.autoScaling.maxWorkerNodes,
        values.autoScaling.stepSize,
        maxWorkerNodeCount,
      );

      if (validateResult.trigger) {
        trigger('autoScaling.minWorkerNodes');
        trigger('autoScaling.maxWorkerNodes');
      }

      return validateResult.result.stepSize;
    };
  }, [getValues, trigger, maxWorkerNodeCount]);

  const cpuTargetValidation = useMemo(() => {
    return () => {
      const values = getValues();
      return utils.mustBeIntegerInRange(
        0,
        100,
        display.cpuTargetError,
      )(values.autoScaling.target);
    };
  }, [getValues]);

  const queuedQueriesTargetValidation = useMemo(() => {
    return () => {
      const values = getValues();
      return utils.mustBeIntegerInRange(
        0,
        10000,
        display.queuedQueriesTargetError,
      )(values.autoScaling.target);
    };
  }, [getValues]);

  useEffect(() => {
    if (isCommunityEditionTenant) {
      setValue('autoScaling.type', types.idleCostSavings);
    }
  }, [isCommunityEditionTenant, setValue]);

  const scalingType =
    autoScalingType || watch('autoScaling.type', types.idleCostSavings);

  const enableIdleCostSavings = autoScalingType
    ? true
    : watch('autoScaling.enableIdleCostSavings', false);
  const minWorkerNodes = watch('autoScaling.minWorkerNodes', null);
  const maxWorkerNodes = watch('autoScaling.maxWorkerNodes', null);
  const defaultStepSize = useMemo(() => {
    if (
      !minWorkerNodes ||
      !maxWorkerNodes ||
      Number.isNaN(minWorkerNodes) ||
      Number.isNaN(maxWorkerNodes)
    ) {
      return null;
    }

    const stepSize = Math.max(
      1,
      Math.round((maxWorkerNodes - minWorkerNodes) / 10),
    );

    if (!defaultAutoScaling) {
      setValue('autoScaling.stepSize', stepSize);
    }
    return stepSize;
  }, [defaultAutoScaling, minWorkerNodes, maxWorkerNodes, setValue]);

  const timeWindowLabel = useMemo(() => {
    if (
      scalingType === types.cpu ||
      scalingType === types.queuedQueries ||
      scalingType === types.dynamic
    ) {
      return display.cpuScaleInTimeWindowLabel;
    }
    return display.idleCostSavingsIdleTimeLabel;
  }, [scalingType]);

  const timeWindowHelpField = useMemo(() => {
    if (
      scalingType === types.cpu ||
      scalingType === types.queuedQueries ||
      scalingType === types.dynamic
    ) {
      return Help.CPUScaleInTimeWindow;
    }
    return Help.IdleCostSavingsIdleTime;
  }, [scalingType]);

  useEffect(() => {
    trigger('autoScaling.stepSize');
  }, [defaultStepSize, trigger]);

  useEffect(() => {
    if (maxWorkerNodes) {
      trigger('autoScaling.maxWorkerNodes');
    }
  }, [maxWorkerNodes, trigger]);

  // Allows to submit form even when there were errors before switching scaling strategies
  useEffect(() => {
    if (scalingType === types.idleCostSavings) {
      trigger('autoScaling.minWorkerNodes');
      trigger('autoScaling.maxWorkerNodes');
      trigger('autoScaling.stepSize');
    } else {
      trigger('autoScaling.workerNodes');
    }
  }, [scalingType, trigger]);

  // Allows to submit form even when there were errors before switching scaling strategies
  useEffect(() => {
    if (scalingType === types.idleCostSavings) {
      trigger('autoScaling.minWorkerNodes');
      trigger('autoScaling.maxWorkerNodes');
      trigger('autoScaling.stepSize');
    } else {
      trigger('autoScaling.workerNodes');
    }
  }, [scalingType, trigger]);

  return (
    <>
      {!defaultAutoScaling && (
        <div>
          <h3>{display.header}</h3>
        </div>
      )}
      <div>
        {!defaultAutoScaling && (
          <Form.RadioInputs
            name='autoScaling.type'
            label={display.strategyLabel}
            values={[
              {
                label: display.staticLabel,
                value: types.idleCostSavings,
              },
              {
                label: display.scalingLabel,
                value: types.dynamic,
              },
            ]}
            disabled={disabled || isCommunityEditionTenant}
            defaultValue={types.idleCostSavings}
            fieldHelp={Help.Type(isCommunityEditionTenant)}
          />
        )}
        {(scalingType === types.dynamic ||
          scalingType === types.cpu ||
          scalingType === types.queuedQueries) && (
          <Form.SelectInput
            name='autoScaling.dynamic.type'
            label={display.dynamicTypeLabel}
            disabled={disabled || defaultAutoScaling}
            defaultValue={
              defaultAutoScaling ? defaultAutoScaling.type : types.cpu
            }
            items={dynamicTypes}
            validationRules={{
              required: display.autoScalingTypeRequiredError,
            }}
            fieldHelp={
              defaultAutoScaling
                ? Help.AutoScalingTypeView()
                : Help.AutoScalingType()
            }
          />
        )}
        {scalingType === types.idleCostSavings && (
          <>
            <Form.TextInput
              name='autoScaling.workerNodes'
              type='integer'
              label={display.defaultWorkerNodesLabel}
              disabled={disabled}
              validationRules={{
                required: display.defaultWorkerNodesRequiredError,
                validate: defaultWorkerNodeRangeValidation,
              }}
              fieldHelp={Help.DefaultWorkerNodes(
                maxWorkerNodeCount,
                isCommunityEditionTenant,
              )}
              defaultValue={
                defaultAutoScaling ? defaultAutoScaling.workerNodes : undefined
              }
            />

            {!defaultAutoScaling && (
              <Form.CheckboxInput
                name='autoScaling.enableIdleCostSavings'
                label={display.idleCostSavingsLabel}
                fieldHelp={Help.EnableIdleCostSavings(isCommunityEditionTenant)}
                disabled={isCommunityEditionTenant}
              />
            )}
          </>
        )}
        {(scalingType === types.dynamic ||
          scalingType === types.cpu ||
          scalingType === types.queuedQueries) && (
          <>
            {dynamicType === types.cpu && (
              <Form.TextInput
                name='autoScaling.target'
                type='integer'
                label={display.cpuTargetLabel}
                disabled={disabled}
                validationRules={{
                  validate: cpuTargetValidation,
                }}
                fieldHelp={Help.CPUTarget}
                defaultValue={
                  defaultAutoScaling && defaultAutoScaling.target
                    ? defaultAutoScaling.target
                    : 40
                }
              />
            )}
            {dynamicType === types.queuedQueries && (
              <Form.TextInput
                name='autoScaling.target'
                type='integer'
                label={display.queuedQueriesTargetLabel}
                disabled={disabled}
                validationRules={{
                  validate: queuedQueriesTargetValidation,
                }}
                fieldHelp={Help.QueuedQueriesTarget}
                defaultValue={
                  defaultAutoScaling ? defaultAutoScaling.target : 100
                }
              />
            )}
            <Form.TextInput
              name='autoScaling.minWorkerNodes'
              type='integer'
              label={display.cpuScaleInMinWorkerNodesLabel}
              disabled={disabled}
              validationRules={{
                validate: minimumWorkerNodeRangeValidation,
              }}
              fieldHelp={Help.MinimumWorkerNodes}
              defaultValue={
                defaultAutoScaling
                  ? defaultAutoScaling.minWorkerNodes
                  : undefined
              }
            />
            <Form.TextInput
              name='autoScaling.maxWorkerNodes'
              type='integer'
              label={display.cpuScaleInMaxWorkerNodesLabel}
              disabled={disabled}
              validationRules={{
                validate: maximumWorkerNodeRangeValidation,
              }}
              fieldHelp={Help.MaximumWorkerNodes}
              defaultValue={
                defaultAutoScaling
                  ? defaultAutoScaling.maxWorkerNodes
                  : undefined
              }
            />
            <Form.TextInput
              name='autoScaling.stepSize'
              type='integer'
              label={display.cpuScaleInStepSizeLabel}
              secondLabel={display.scaleInStepSizeSecondLabel}
              disabled={disabled}
              defaultValue={
                defaultAutoScaling
                  ? defaultAutoScaling.stepSize
                  : defaultStepSize
              }
              validationRules={{
                validate: stepSizeRangeValidation,
              }}
              fieldHelp={Help.CPUScaleInStepSize}
            />
          </>
        )}
        {(scalingType === types.cpu ||
          scalingType === types.queuedQueries ||
          scalingType === types.dynamic ||
          enableIdleCostSavings) && (
          <Form.TextInput
            name='autoScaling.idleTime'
            type='integer'
            label={timeWindowLabel}
            secondLabel={display.idleTimeSecondLabel}
            disabled={disabled}
            defaultValue={defaultAutoScaling ? defaultAutoScaling.idleTime : 30}
            validationRules={{
              // required: display.timeWindowRequiredError,
              validate: idleTimeRangeValidation,
            }}
            fieldHelp={timeWindowHelpField}
          />
        )}
        {scalingType === types.idleCostSavings && !enableIdleCostSavings && (
          <Data.DataField
            label={display.idleCostSavingsIdleTimeLabel}
            secondLabel={display.idleTimeSecondLabel}
            disabled
            fieldHelp={Help.IdleCostSavingsIdleTime}
          />
        )}
        {!defaultAutoScaling && (
          <Form.TextInput
            name='prestoWorkerTerminationGracePeriod'
            type='integer'
            label={display.queryTerminationGracePeriodLabel}
            secondLabel={display.queryTerminationGracePeriodSecondLabel}
            defaultValue={10}
            disabled={disabled}
            validationRules={{
              required: display.timeWindowRequiredError,
              validate: queryTerminationGracePeriodValidation,
              valueAsNumber: true,
            }}
            fieldHelp={Help.QueryTerminationGracePeriod}
          />
        )}
      </div>
    </>
  );
};

AutoScaling.defaultProps = {
  disabled: false,
  defaultAutoScaling: null,
  autoScalingType: null,
};

AutoScaling.propTypes = {
  disabled: bool,
  defaultAutoScaling: oneOfType([
    PrestoClusters.propTypes.AutoScalingPolicyIdleCostSavings,
    PrestoClusters.propTypes.AutoScalingPolicyCPU,
    PrestoClusters.propTypes.AutoScalingPolicyQueuedQueries,
  ]),
  autoScalingType: string,
  maxWorkerNodeCount: number.isRequired,
};

export default AutoScaling;
