import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import * as actionTypes from './actionTypes';

const initialState = {
  submitInProgress: false,
  submitSuccess: false,
  errors: undefined,
};

const onNavigation = () => {
  return { ...initialState };
};

const submitStart = (draft) => {
  draft.submitInProgress = true;
  draft.submitSuccess = false;
  draft.errors = undefined;
};

const submitSuccess = (draft) => {
  draft.submitInProgress = false;
  draft.submitSuccess = true;
  draft.errors = undefined;
};

const submitFailure = (draft, action) => {
  draft.submitInProgress = false;
  draft.submitSuccess = false;

  if (action.payload.apiResult.body.errorCode === 'authorizationservice-1') {
    draft.errors = {
      type: action.payload.apiResult.body.errorMessage,
    };
  } else if (
    action.payload.apiResult.body.errorCode &&
    action.payload.apiResult.body.errorCode.startsWith(
      `authorizationservice-${action.payload.requestBody.type}-`,
    )
  ) {
    draft.errors = {
      connection: {
        type: action.payload.requestBody.type,
        error: action.payload.apiResult.body.errorMessage,
      },
    };
  } else {
    draft.errors = {
      details: action.payload.apiResult.body.errorMessage,
    };
  }
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.submit]: submitStart,
  [actionTypes.post.success]: submitSuccess,
  [actionTypes.post.failure]: submitFailure,
  [auth.actionTypes.signOut]: signOut,
};

export default makeReducer(initialState, actionReducers);
