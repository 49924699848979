import styled from 'styled-components/macro';
import { colors } from '../../../css';
import { FormContentDiv } from '../../Page/styledComponents';

export const NoDataDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;

export const SpanningContentDiv = styled(FormContentDiv)`
  grid-template-columns: minmax(150px, 20%) auto;
  grid-column-gap: 40px;
`;
