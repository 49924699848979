import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Workload Profile</Help.HelpHeader>
      <Help.P>
        Select a workload type for your cluster that suits the type of queries
        you are planning to run.
      </Help.P>
      <Help.P>
        Low concurrency is useful for clusters that run a limited number of
        queries or a few large, complex queries. It also supports bigger and
        heavier ETL jobs.
      </Help.P>
      <Help.P>
        High concurrency is better for running multiple queries at the same
        time. For example, dashboard and reporting queries or A/B testing
        analytics, etc.
      </Help.P>
      <Help.P>
        This setting can be changed once the cluster has been created and
        cluster restart is not required.{' '}
        <Help.Strong>
          However, the change will only apply to new queries.
        </Help.Strong>
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
