import Data from '../../Data';
import DataSources from '../../DataSources';

const display = {
  formContentHeader: 'Access',
  formExplaination: 'Secondary explanation',
  endpointLabel: 'Endpoint',
  configurationPropertiesLabel: 'Configuration Properties',
  portLabel: 'Port',
  userLabel: 'User',
  passwordLabel: 'Password',
};

const MySqlConnectionDetails = ({
  endpoint,
  port,
  configurationProperties,
  'connection-user': user,
  'connection-password': password,
}) => {
  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
        {/* <div>{display.formExplaination}</div> */}
      </div>
      <div>
        <Data.DataField label={display.endpointLabel} value={endpoint} />
        <Data.DataField label={display.portLabel} value={port} />
        <Data.DataField
          optional
          label={display.configurationPropertiesLabel}
          value={configurationProperties}
        />
        <Data.DataField label={display.userLabel} value={user} />
        <Data.DataField label={display.passwordLabel} value={password} />
      </div>
    </>
  );
};

MySqlConnectionDetails.propTypes = DataSources.propTypes.MySqlConnection;

export default MySqlConnectionDetails;
