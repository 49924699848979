import { shape, string, number, arrayOf } from 'prop-types';

export const Tenant = shape({
  billedUpdateDate: string,
  consumedUpdateDate: string,
  contractPublicId: string,
  createDate: string.isRequired,
  cumulativeBilledCredits: number,
  cumulativeConsumedCredits: number,
  subscriptionType: string.isRequired,
  tenantId: string.isRequired,
});

export const Container = shape({
  containerType: string.isRequired,
  containerTypeDisplay: string.isRequired,
  creditsPerHour: number.isRequired,
  duration: number.isRequired,
  instanceType: string.isRequired,
  totalCredits: number.isRequired,
});

export const Cluster = shape({
  clusterId: string.isRequired,
  createDate: string.isRequired,
  createDateDisplay: string.isRequired,
  name: string.isRequired,
  totalCredits: number.isRequired,
  containers: arrayOf(Container),
});

export const Contract = shape({
  billedCredits: number,
  contractId: string.isRequired,
  createDate: string.isRequired,
  endDate: string.isRequired,
  purchasedCredits: number,
  remainingCredits: number,
  updateDate: string.isRequired,
});
