import Help from '../../Help';

const KeystoreFile = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Keystore File</Help.HelpHeader>
      <Help.P>
        Upload Keystore file if SSL is enabled on Apache Ranger Service.
      </Help.P>
    </Help.HelpSection>
  );
};

const KeystorePassword = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Keystore Password</Help.HelpHeader>
      <Help.P>The password for the keystore.</Help.P>
    </Help.HelpSection>
  );
};

const TruststoreFile = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Truststore File</Help.HelpHeader>
      <Help.P>
        Upload truststore file if SSL is enabled on Apache Ranger Service. This
        only required if SSL is enabled with truststore.
      </Help.P>
    </Help.HelpSection>
  );
};

const TruststorePassword = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Truststore Password</Help.HelpHeader>
      <Help.P>The password for the truststore.</Help.P>
    </Help.HelpSection>
  );
};

const module = {
  KeystoreFile,
  KeystorePassword,
  TruststoreFile,
  TruststorePassword,
};

export default module;
