import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Need to update your Presto user?</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>Change the username if desired.</Help.ListItem>
        <Help.ListItem>Pick a new password.</Help.ListItem>
        <Help.ListItem>
          When you click &quot;Modify Presto User&quot;, the Presto user will be
          updated with the new credentials. It may take a few minutes to take
          effect.
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        Credentials are changed without restarting the cluster, so running
        queries can continue to execute. However, once the new password is set,
        any tool connecting to associated clusters will need the new credentials
        to successfully connect to those clusters.
      </Help.P>
      <Help.P>
        One exception is if this Presto user is mapped to Lake Formation enabled
        Glue data sources. In this scenario, if the username is changed, any
        cluster using that data source will be restarted. Password-only changes
        will not cause any cluster restart.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
