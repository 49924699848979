import { string, bool } from 'prop-types';
import HiveS3Logo from '../../../img/logo/hive-s3.svg';
import GlueS3Logo from '../../../img/logo/glue-s3.svg';
import RedshiftLogo from '../../../img/logo/redshift.svg';
import ElasticsearchLogo from '../../../img/logo/elasticsearch.svg';
import RdsMySqlLogo from '../../../img/logo/rds-mysql.svg';
import RdsPostgresLogo from '../../../img/logo/rds-postgres.svg';
import IBMDb2Logo from '../../../img/logo/IBM_db2.svg';
import Banner from '../../Banner';
import DataSources from '../../DataSources';
import Page from '../../Page';
import Form from '../../Form';
import {
  DataSourceTypeOptionsDiv,
  DataSourceTypeOptionDiv,
} from './styledComponents';

const display = {
  boxTitle: 'Connector Type',
  requiredError: 'Please select data source type',
  hive: 'Hive Metastore for Amazon S3',
  glue: 'AWS Glue Data Catalog for Amazon S3',
  elasticsearch: 'Amazon OpenSearch',
  mysql: 'MySQL',
  postgresql: 'PostgreSQL',
  redshift: 'Amazon Redshift',
  db2: 'IBM Db2',
  netezza: 'IBM Netezza',
  kafka: 'Apache kafka',
  mongodb: 'MongoDB',
  sqlserver: 'SQL Server',
};

const dataSourceType = (label, Logo, disabled) => {
  return () => {
    return (
      <DataSourceTypeOptionDiv>
        <img src={Logo} alt={label} />
        <div>{label}</div>
        {disabled && <div className='coming-soon'>Coming soon!</div>}
      </DataSourceTypeOptionDiv>
    );
  };
};

const dataSourceTypeValues = [
  {
    value: DataSources.constants.dataSourceTypes.glue,
    label: display.glue,
    component: dataSourceType(display.glue, GlueS3Logo),
  },
  {
    value: DataSources.constants.dataSourceTypes.hive,
    label: display.hive,
    component: dataSourceType(display.hive, HiveS3Logo),
  },
  {
    value: DataSources.constants.dataSourceTypes.elasticsearch,
    label: display.elasticsearch,
    component: dataSourceType(display.elasticsearch, ElasticsearchLogo),
  },
  {
    value: DataSources.constants.dataSourceTypes.mysql,
    label: display.mysql,
    component: dataSourceType(display.mysql, RdsMySqlLogo),
  },
  {
    value: DataSources.constants.dataSourceTypes.postgresql,
    label: display.postgresql,
    component: dataSourceType(display.postgresql, RdsPostgresLogo),
  },
  {
    value: DataSources.constants.dataSourceTypes.redshift,
    label: display.redshift,
    component: dataSourceType(display.redshift, RedshiftLogo),
  },
  {
    value: DataSources.constants.dataSourceTypes.db2,
    label: display.db2,
    component: dataSourceType(display.db2, IBMDb2Logo),
  },
];

const SelectDataSourceType = ({ error, disabled }) => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <DataSourceTypeOptionsDiv>
        <Form.RadioInputs
          name='dataSourceType'
          values={dataSourceTypeValues}
          disabled={disabled}
          defaultValue={DataSources.constants.dataSourceTypes.glue}
          validationRules={{ required: display.requiredError }}
        />
      </DataSourceTypeOptionsDiv>
    </Page.Box>
  );
};

SelectDataSourceType.defaultProps = {
  error: undefined,
  disabled: false,
};

SelectDataSourceType.propTypes = {
  error: string,
  disabled: bool,
};

export default SelectDataSourceType;
