import parseJSON from 'date-fns/parseJSON';
import isAfter from 'date-fns/isAfter';
import { getNow } from '../../utils/dateUtils';
import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import ComputePlaneAdd from '../ComputePlaneAdd';
import * as actionTypes from './actionTypes';
import * as constants from './constants';

const initialState = {
  tenantId: null,
  tenant: null,
  subscriptionStatus: constants.subscriptionStatus.unknown,
  trialExpirationDate: null,
};

const get = (draft, action) => {
  draft.tenant = action.payload.apiResult.body;
};

const setSubscriptionStatus = (draft, action) => {
  const subscriptionStatus =
    action.payload.apiResult &&
    action.payload.apiResult.headers &&
    action.payload.apiResult.headers.get('x-ahana-subscription-status');

  if (subscriptionStatus && constants.subscriptionStatus[subscriptionStatus]) {
    draft.subscriptionStatus = constants.subscriptionStatus[subscriptionStatus];

    if (constants.subscriptionStatus[subscriptionStatus] === 'trial') {
      const result = action.payload.apiResult.headers.get(
        'x-ahana-trial-expiration-date',
      );
      const today = getNow();
      const trialExpirationDate = parseJSON(result);

      if (isAfter(trialExpirationDate, today)) {
        draft.trialExpirationDate = trialExpirationDate;
      } else {
        draft.subscriptionStatus =
          constants.subscriptionStatus['trial-expired'];
      }
    }
  }
};

const setTenantId = (draft, action) => {
  draft.tenantId = action.payload.apiResult?.body?.tenantId;
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  [actionTypes.get.success]: get,
  [auth.actionTypes.signOut]: signOut,
  [auth.actionTypes.getAuth.success]: setTenantId,
};

const computedActionReducers = (actionType) => {
  if (
    (actionType.endsWith('_SUCCESS') &&
      actionType !== ComputePlaneAdd.actionTypes.resetHasVerifyRoleSuccess &&
      actionType !== 'SIGNIN_SUCCESS') ||
    actionType.endsWith('_FAILURE')
  ) {
    return setSubscriptionStatus;
  }
  return null;
};

export default makeReducer(
  initialState,
  actionReducers,
  computedActionReducers,
);
