import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Configure Prometheus</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>Choose instance type</Help.ListItem>
        <Help.ListItem>
          Provide yaml for Prometheus remote write rule.
        </Help.ListItem>
        <Help.ListItem>
          Optionally chose to enable the Prometheus website.
        </Help.ListItem>
        <Help.ListItem>Chose to enable JMX metrics dubugging.</Help.ListItem>
        <Help.ListItem>Save changes</Help.ListItem>
      </Help.OrderedList>
      <Help.P>This deployment takes 10-15 minutes to complete.</Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
