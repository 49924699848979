import * as constants from './constants';
import Container from './Container';
import EmbededContainer from './EmbededContainer';
import * as routes from './routes';
import * as selectors from './selectors';

const module = {
  constants,
  Container,
  EmbededContainer,
  routes,
  selectors,
};

export default module;
