import HiveS3Logo from '../../img/logo/hive-s3.svg';
import GlueS3Logo from '../../img/logo/glue-s3.svg';
import RedshiftLogo from '../../img/logo/redshift.svg';
import ElasticsearchLogo from '../../img/logo/elasticsearch.svg';
import RdsMySqlLogo from '../../img/logo/rds-mysql.svg';
import RdsPostgresLogo from '../../img/logo/rds-postgres.svg';
import IBMDb2Logo from '../../img/logo/IBM_db2.svg';

export const dataSourceTypes = {
  hive: 'hive',
  glue: 'glue',
  elasticsearch: 'elasticsearch',
  mysql: 'mysql',
  postgresql: 'postgresql',
  redshift: 'redshift',
  db2: 'db2',
  netezza: 'netezza',
  kafka: 'kafka',
  mongodb: 'mongodb',
  sqlserver: 'sqlserver',
};

export const status = Object.freeze({
  secret_failure: 'secret_failure',
  secret_updating: 'secret_updating',
  secret_deleting: 'secret_deleting',
  delete_failure: 'delete_failure',
  deleted: 'deleted',
  ready: 'ready',
});

export const statusMessages = {
  [status.secret_updating]: {
    title: 'Managing data source credentials',
    description:
      'The authentication credentials for the data source are currently being stored in the secret manager.',
  },
  [status.secret_deleting]: {
    title: 'Deleting data source credentials',
    description:
      'The authentication credentials for the data source are currently being deleted from the secret manager.',
  },
  [status.secret_failure]: {
    title: 'Error in managing data source credentials',
    description:
      'Failed to store the data source authentication credentials in the secret manager.',
  },
  [status.delete_failure]: {
    title: 'Error in deleting data source credentials',
    description:
      'Failed to delete the data source authentication credentials in the secret manager when attempting to delete the data source.',
  },
};

const dstl = {};
dstl[dataSourceTypes.hive] = {
  Logo: HiveS3Logo,
  alt: 'Hive-S3',
  title: 'Hive Metastore for Amazon S3',
};
dstl[dataSourceTypes.glue] = {
  Logo: GlueS3Logo,
  alt: 'Glue-S3',
  title: 'AWS Glue Data Catalog for Amazon S3',
};
dstl[dataSourceTypes.elasticsearch] = {
  Logo: ElasticsearchLogo,
  alt: 'Elasticsearch',
  title: 'Amazon OpenSearch',
};
dstl[dataSourceTypes.mysql] = {
  Logo: RdsMySqlLogo,
  alt: 'MySql',
  title: 'MySQL',
};
dstl[dataSourceTypes.postgresql] = {
  Logo: RdsPostgresLogo,
  alt: 'Postgres',
  title: 'PostgreSQL',
};
dstl[dataSourceTypes.redshift] = {
  Logo: RedshiftLogo,
  alt: 'Redshift',
  title: 'Amazon Redshift',
};
dstl[dataSourceTypes.db2] = {
  Logo: IBMDb2Logo,
  alt: 'Db2',
  title: 'IBM Db2',
};

export const dataSourceTypeLogos = dstl;
