import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from '../../utils/redux';
import * as selectors from './selectors';
import * as actions from './actions';

export const useFormWizard = () => {
  const dispatch = useDispatch();

  const selectState = useSelector((state) => {
    return selectors.getState(state);
  });

  const onSetActiveStep = useCallback(
    (activeStepId) => {
      dispatch(actions.setActiveStep, activeStepId);
    },
    [dispatch],
  );

  const initializeFormWizard = useCallback(
    (steps, initialStep) => {
      dispatch(actions.initialize, steps, initialStep);
    },
    [dispatch],
  );

  const nextStepId = useMemo(() => {
    const currStepIdx = selectState.steps.findIndex((step) => {
      return step.id === selectState.activeStepId;
    });

    if (currStepIdx === -1) {
      // we didn't find the step this is edge case so return the first step in steps array
      return selectState.steps?.[0]?.id;
    }

    if (currStepIdx < selectState.steps.length - 1) {
      // current step is not the last step in the array
      return selectState.steps[currStepIdx + 1].id;
    }

    // The last step in steps returns null as next step
    return null;
  }, [selectState.activeStepId, selectState.steps]);

  const previousStepId = useMemo(() => {
    const currStepIdx = selectState.steps.findIndex((step) => {
      return step.id === selectState.activeStepId;
    });

    if (currStepIdx === -1) {
      // we didn't find the step this is edge case so return the first step in steps array
      return selectState.steps?.[0]?.id;
    }

    if (currStepIdx > 0) {
      // current step is not the first step in the array
      return selectState.steps[currStepIdx - 1].id;
    }

    // The first step in steps returns null as previous step
    return null;
  }, [selectState.activeStepId, selectState.steps]);

  return {
    ...selectState,
    nextStepId,
    previousStepId,
    onSetActiveStep,
    initializeFormWizard,
  };
};
