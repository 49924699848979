import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { string, bool, func, arrayOf, shape } from 'prop-types';
import Page from '../../../../Page';
import Form from '../../../../Form';
import Data from '../../../../Data';
import ComputePlane from '../../../../ComputePlane';
import * as Help from '../../CreateComputePlaneHelp';
import { validateCIDR, prestoClustersWorkersEstimated } from '../../../utils';

const display = {
  deploymentHeader: 'Compute Plane Deployment',
  awsAvailabilityZoneDisabled: 'Loading...',
  awsAvailabilityZoneLabel: 'AWS Availability Zones',
  awsAvailabilityZoneRequiredError: 'AWS availability zone is required',
  awsAvailabilityZoneTwoOrThree:
    'Please select two or three availability zones',
  vpcCIDRLabel: 'IPv4 CIDR Block',
  vpcCIDRRequiredError: 'CIDR block is required',
};

const pickTwoOrThree = (value) => {
  return (
    (value && (value.length === 2 || value.length === 3)) ||
    display.awsAvailabilityZoneTwoOrThree
  );
};

const AvailabilityZoneMultiSelect = ({
  disabled,
  awsAZsIDsByRegion,
  defaultValue,
}) => {
  const awsAZSelectItems = useMemo(() => {
    if (!awsAZsIDsByRegion) return null;
    return awsAZsIDsByRegion.map((azId) => {
      return {
        display: `${azId.zoneName} (${azId.zoneId})`,
        value: azId.zoneName,
      };
    });
  }, [awsAZsIDsByRegion]);

  if (!awsAZSelectItems) {
    return (
      <Data.DataField
        label={display.awsAvailabilityZoneLabel}
        value={display.awsAvailabilityZoneDisabled}
        disabled
        fieldHelp={Help.AWSAvailabilityZones}
      />
    );
  }
  return (
    <Form.MultiSelectInput
      name='awsAvailabilityZones'
      label={display.awsAvailabilityZoneLabel}
      defaultValue={defaultValue}
      validationRules={{
        required: display.awsAvailabilityZoneRequiredError,
        validate: { pickTwoOrThree },
      }}
      items={awsAZSelectItems}
      disabled={disabled}
      fieldHelp={Help.AWSAvailabilityZones}
    />
  );
};

AvailabilityZoneMultiSelect.defaultProps = {
  disabled: false,
  awsAZsIDsByRegion: null,
  defaultValue: undefined,
};

AvailabilityZoneMultiSelect.propTypes = {
  awsAZsIDsByRegion: ComputePlane.propTypes.awsAZsIDs,
  disabled: bool,
  defaultValue: arrayOf(string),
};

export const FullyManaged = ({
  computePlane,
  awsAZsIDsByRegion,
  getRegionInfo,
}) => {
  useEffect(() => {
    const { computePlaneId, awsRegion } = computePlane;
    getRegionInfo(computePlaneId, awsRegion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computePlane, getRegionInfo]);

  const vpcCIDR = useMemo(() => {
    return (computePlane && computePlane.vpcCIDR) ?? '10.128.0.0/16';
  }, [computePlane]);

  const { watch } = useFormContext();
  const currentVpcCIDR = watch('vpcCIDR');
  const currentAZsCount = watch('awsAvailabilityZones')?.length;

  const pcWorkersEstimated = useMemo(() => {
    if (!currentVpcCIDR || !currentAZsCount) {
      return null;
    }
    return prestoClustersWorkersEstimated(currentVpcCIDR, currentAZsCount);
  }, [currentVpcCIDR, currentAZsCount]);

  return (
    <Page.FormContent rows={3}>
      <div>
        <h3>{display.deploymentHeader}</h3>
      </div>
      <div>
        <AvailabilityZoneMultiSelect
          awsAZsIDsByRegion={awsAZsIDsByRegion}
          disabled={false}
          defaultValue={computePlane?.awsAvailabilityZones}
        />
        <Form.TextInput
          name='vpcCIDR'
          label={display.vpcCIDRLabel}
          defaultValue={vpcCIDR}
          disabled={false}
          validationRules={{
            required: display.vpcCIDRRequiredError,
            validate: { validateCIDR },
          }}
          fieldHelp={Help.VpcCIDR}
        />
        <div>{pcWorkersEstimated}</div>
      </div>
    </Page.FormContent>
  );
};

FullyManaged.defaultProps = {
  computePlane: null,
  awsAZsIDsByRegion: null,
};

FullyManaged.propTypes = {
  computePlane: shape({
    awsRegion: string.isRequired,
    awsAvailabilityZones: arrayOf(string),
    vpcCIDR: string,
  }),
  awsAZsIDsByRegion: ComputePlane.propTypes.awsAZsIDs,
  getRegionInfo: func.isRequired,
};
