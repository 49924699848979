import { connect } from '../../utils/redux';
import * as selectors from './selectors';
import * as ComputePlaneActions from '../ComputePlane/actions';
import * as actions from './actions';
import { StandalonePrometheus } from './components';

const mapStateToProps = (state) => {
  return {
    prometheus: selectors.getPrometheus(state),
    computePlaneId: selectors.getComputePlaneId(state),
    deleteInProgress: selectors.getPrometheusDeleteInProgress(state),
    canDestroyOrEditPrometheus: selectors.getCanDestroyOrEditPrometheus(state),
    error: selectors.getError(state),
    canEnablePrometheus: selectors.getCanEnablePrometheus(state),
  };
};

export default connect(mapStateToProps, {
  get: ComputePlaneActions.get,
  deletePrometheus: actions.deletePrometheus,
  patchPrometheusJMX: actions.patchPrometheusJMX,
})(StandalonePrometheus);
