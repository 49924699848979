import { createSelector } from 'reselect';

const getSupportedAwsRegions = (state) => {
  return (
    state.computePlane.configuration &&
    state.computePlane.configuration.awsRegions
  );
};

export const getAwsRegions = createSelector(
  getSupportedAwsRegions,
  (awsRegions) => {
    if (!awsRegions) return [];
    return awsRegions.map((r) => {
      return {
        display: `${r.regionName} ${r.region}`,
        value: r.region,
      };
    });
  },
);
