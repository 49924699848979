import { string, arrayOf } from 'prop-types';
import Page from '../../Page';
import Data from '../../Data';
import AuthorizationServices from '../../AuthorizationServices';
import HiveRangerLogo from '../../../img/logo/hive-ranger.svg';
import DetailsHeader from './DetailsHeader';
import IsEnabledBadge from './IsEnabledBadge';
import PrestoClusters from '../../PrestoClusters';

const display = {
  boxTitle: 'Details',
  plugInTypeHeader: 'Plugin Type',
  connectionConfigurationHeader: 'Connection Configuration',
  hivePluginServicenameLabel: 'Hive Plugin Service Name',
  rangerRestEndPointURLLabel: 'Ranger REST Endpoint URL',
  rangerAdminUsernameLabel: 'Ranger Admin Username',
  rangerAdminPasswordLabel: 'Ranger Admin Password',
  auditingWithRangerHeader: 'Auditing with Ranger',
  sslConfigurationHeader: 'SSL Configuration',
};

const Logo = {
  Logo: HiveRangerLogo,
  alt: 'Hive-Ranger',
  title: 'Hive Ranger',
};

const RangerHiveDetails = ({ authorizationService }) => {
  return (
    <>
      <div>
        <h3>{display.plugInTypeHeader}</h3>
      </div>
      <div>
        <Data.DataFieldWithLogo logo={Logo} />
      </div>
      <div>
        <h3>{display.connectionConfigurationHeader}</h3>
      </div>
      <div>
        <Data.DataField
          label={display.hivePluginServicenameLabel}
          value={
            authorizationService.connection.hive.ranger.policy[
              'hive-servicename'
            ]
          }
        />
        <Data.DataField
          label={display.rangerRestEndPointURLLabel}
          value={authorizationService.connection.hive.ranger['rest-endpoint']}
        />
        <Data.DataField
          label={display.rangerAdminUsernameLabel}
          value={
            authorizationService.connection.hive.ranger.service[
              'basic-auth-username'
            ]
          }
        />
        <Data.DataField
          label={display.rangerAdminPasswordLabel}
          value={
            authorizationService.connection.hive.ranger.service[
              'basic-auth-password'
            ]
          }
        />
        <div>
          <Data.Label label={display.auditingWithRangerHeader} />
          <div className='badge'>
            <IsEnabledBadge
              enabled={
                authorizationService.catalogFiles &&
                authorizationService.catalogFiles['hive.ranger.audit.path']
              }
            />
          </div>
        </div>
        <div>
          <Data.Label label={display.sslConfigurationHeader} />
          <div className='badge'>
            <IsEnabledBadge
              enabled={
                authorizationService.catalogFiles &&
                (authorizationService.catalogFiles[
                  'hive.ranger.service.keystore.path'
                ] ||
                  authorizationService.catalogFiles[
                    'hive.ranger.service.truststore.path'
                  ])
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

RangerHiveDetails.defaultProps = {
  authorizationService: null,
};

RangerHiveDetails.propTypes = {
  authorizationService: AuthorizationServices.propTypes.AuthorizationService,
};

const Details = ({
  navTarget,
  authorizationServiceId,
  authorizationService,
  prestoClusters,
}) => {
  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <DetailsHeader
        title={display.boxTitle}
        authorizationServiceId={authorizationServiceId}
        prestoClusters={prestoClusters}
      />
      <Page.FormContent rows={2}>
        <RangerHiveDetails authorizationService={authorizationService} />
      </Page.FormContent>
    </Page.BoxNavTarget>
  );
};

Details.defaultProps = {
  authorizationService: null,
  prestoClusters: null,
};

Details.propTypes = {
  navTarget: string.isRequired,
  authorizationServiceId: string.isRequired,
  authorizationService: AuthorizationServices.propTypes.AuthorizationService,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
};

export default Details;
