import styled from 'styled-components/macro';

export const Pre = styled.pre`
  text-align: left;
  overflow: scroll;
  font-family: 'Lucida Console', Monaco, monospace;

  span {
    font-family: 'Lucida Console', Monaco, monospace;
  }
`;

export const Line = styled.div`
  display: table-row;
`;

export const LineNumber = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

export const LineContent = styled.span`
  display: table-cell;
`;
