import styled from 'styled-components/macro';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import RadioButtonMarker from '../../img/radio_button.svg';
import RadioButtonSelectedMarker from '../../img/radio_button_selected.svg';
import CheckboxMarker from '../../img/checkbox.svg';
import CheckboxSelectedMarker from '../../img/checkbox_selected.svg';
import { fonts, colors } from '../../css';

const reactTooltip = `margin-right: 10px;
  ${fonts.small}
  color: ${colors.font_light};
  background-color: ${colors.secondary_button};
  border: 0;
  padding: 5px 10px;
  border-radius: 4px;
  border-color: ${colors.secondary_button_border};
`;

export const Tooltip = styled(ReactTooltip)`
  ${reactTooltip}
`;

export const LabelDiv = styled.div`
  ${fonts.medium_bold}
  color: ${colors.font_light};
  margin-bottom: 15px;

  > span {
    margin-left: 10px;
    vertical-align: 2px;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.54;
  }
`;

export const LabelWrapperDiv = styled.div`
  margin-bottom: 7px;
  display: flex;
  justify-content: space-around;

  > ${LabelDiv} {
    margin-bottom: 8px;
    flex-grow: 1;
  }

  > button {
    flex-grow: 0;
    border: 1px solid transparent;
    padding: 0;
    border-radius: 50%;
    height: 30px;
    padding: 2px;
    background-color: ${colors.section_background};
    cursor: pointer;

    &:focus,
    &:active,
    &:hover {
      border-color: ${colors.focus_light};
      text-decoration: underline;
      outline: none;
    }

    svg {
      height: 24px;
      fill: ${colors.white};
    }
  }
`;

export const SecondLabelDiv = styled.div`
  ${fonts.medium_bold}
  color: ${colors.font_light};
  margin-bottom: 24px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.54;
  }
`;

export const DataDivDiv = styled.div`
  display: flex;
  align-items: center;

  img {
    flex-direction: column;
    max-height: 50px;
    margin-right: 16px;
    margin-bottom: 15px;
  }

  &.secondLabel {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 35% auto;
    align-items: center;
    column-gap: 22px;
  }
`;

export const DataDiv = styled.div`
  ${fonts.medium}
  color: ${colors.font_light};
  outline: 0;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  background-color: ${colors.section_background};
  box-shadow: 0 2px 1px 0 ${colors.input_shadow};
  padding: 9px 16px;
  margin-bottom: 24px;
  min-height: 42px;
  overflow-x: hidden;

  &.disabled {
    opacity: 0.54;
    background-color: ${colors.section_background};
  }

  &.error {
    margin-bottom: 3px;
  }
`;

export const DataWithActionDiv = styled(DataDiv)`
  display: flex;
  flex-flow: row;
  justify-content: stretch;
  align-content: stretch;
  padding: 0;
  border: 0;

  > div {
    flex: 1 1 auto;
    padding: 9px 16px;
    border: 1px solid ${colors.border};
    border-right-width: 0;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &.hide-value {
      opacity: 0.54;
      background-color: ${colors.page_background};
    }
  }

  > div.react-tooltip {
    ${reactTooltip}
  }

  > button {
    flex: 0 0 auto;
    ${fonts.medium_bold}
    outline: 0;
    border: 1px solid ${colors.border};
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: white;
    padding: 4px 12px;
    &.link {
      padding: 4px 11px;
    }
    background-color: ${colors.section_background};
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: ${colors.header_menu_focus};
      border-color: ${colors.header_menu_focus_border};
    }

    &:active {
      background-color: ${colors.header_menu_click};
      border-color: ${colors.header_menu_click_border};
    }

    svg {
      fill: ${colors.font_light};
      height: 18px;
    }
  }
`;

export const RadioGroupDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  margin-bottom: 24px;
  margin-left: 5px;
`;

export const RadioLabel = styled.div`
  flex: 1;
  ${fonts.medium}
  letter-spacing: 0.16px;
  color: ${colors.font_light};
  background-repeat: no-repeat;
  background-image: url(${RadioButtonMarker});
  background-size: 22px 22px;
  padding-left: 38px;
  position: relative;

  &:after {
    content: '';
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 9px 5px;
    position: absolute;
    top: -10px;
    bottom: 8px;
    left: -5px;
    right: 5px;
    height: 100%;
  }

  &.selected {
    background-image: url(${RadioButtonSelectedMarker});
  }

  &:disabled {
    opacity: 0.54;
  }
`;

export const ButtonGroupDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 24px;

  > * {
    margin-right: 20px;
  }
`;

export const CodeDiv = styled.div`
  padding: 0px 24px;
`;

export const CheckboxLabel = styled.label`
  ${fonts.medium_bold}
  line-height: 24px;
  letter-spacing: 0.16px;
  color: ${colors.font_light};
  min-height: 28px;
  min-width: 28px;
  padding: 1px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-image: url(${CheckboxMarker});
  background-position: 1px 1px;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;

  &.hasLabel {
    padding-left: 35px;
  }

  &.checked {
    background-image: url(${CheckboxSelectedMarker});
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.54;
  }
`;

export const CheckboxLabelDiv = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
  align-items: flex-start;

  > ${CheckboxLabel} {
    flex-grow: 1;
  }
`;

export const TextArea = styled.textarea`
  ${fonts.medium}
  color: ${colors.font_light};
  outline: 0;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  background-color: ${colors.section_background};
  box-shadow: 0 2px 1px 0 ${colors.input_shadow};
  padding: 9px 16px;
  margin-bottom: 24px;
  min-height: 200px; // Set a minimum height
  resize: vertical; // Allow vertical resizing
  overflow-x: auto;
  overflow-y: auto; // Allow vertical scrolling inside the textarea
  // pointer-events: none; /* Prevents the textarea from being clickable */
  cursor: default;
  outline: none;
  caret-color: transparent;
  // &:focus {
  //   outline: none; /* Removes the focus outline */
  // }

  &:disabled {
    opacity: 0.54;
    background-color: ${colors.section_background};
  }

  // &:focus {
  //   border-color: ${colors.focus_light};
  // }

  &::placeholder {
    color: ${colors.placeholder};
  }

  &.error {
    border-color: ${colors.error};
    background-color: ${colors.error_background};
  }
`;
