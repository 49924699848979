import { buildUrl } from '../../utils/buildUrl';
import telemetry from '../../telemetry';
import rayApi from '../../rayApi';

const baseResource = 'datasources';

export const getAll = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: baseResource });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const get = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.dataSourceId}`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getPrestoClusters = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.dataSourceId}/prestoclusters`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const post = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: baseResource });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePOST(uri, headers, prepedBody, abortFetchSignal);
};

export const patch = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.dataSourceId}`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePATCH(uri, headers, prepedBody, abortFetchSignal);
};

export const remove = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.dataSourceId}`,
  });
  return rayApi.api.executeDELETE(uri, headers, body, abortFetchSignal);
};

const networkRequestSanitizer = (request) => {
  let sanitizedRequest = request;
  if (sanitizedRequest.url.includes(baseResource)) {
    sanitizedRequest = {
      ...sanitizedRequest,
      body: telemetry.sanitize(sanitizedRequest.body),
    };
  }
  if (
    sanitizedRequest.url.includes('amazonaws') &&
    sanitizedRequest.url.includes('kms')
  ) {
    sanitizedRequest = {
      ...sanitizedRequest,
      body: telemetry.sanitize(sanitizedRequest.body),
    };

    if (sanitizedRequest.headers['x-amz-security-token']) {
      sanitizedRequest.headers['x-amz-security-token'] = 'sanitized';
    }
  }
  return sanitizedRequest;
};

const networkResponseSanitizer = (response) => {
  let sanitizedResponse = response;

  if (
    sanitizedResponse.status === 200 &&
    sanitizedResponse.method === 'GET' &&
    sanitizedResponse.url.includes(baseResource)
  ) {
    sanitizedResponse = {
      ...sanitizedResponse,
      body: telemetry.sanitize(sanitizedResponse.body),
    };
  }

  return sanitizedResponse;
};

telemetry.networkSanitizer.registerNetworkSanitizer(
  'dataSources',
  networkRequestSanitizer,
  networkResponseSanitizer,
);
