import { useCallback, useContext, useMemo } from 'react';
import { bool, string, shape, number } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import PrestoClusters from '../../PrestoClusters';
import { ActionInProgressStateContext } from '../../utils/ActionInProgressContext';
import { useEnableFeature } from '../../utils/featureHooks';
import PrestoClusterInfo from './PrestoClusterInfo';
import PendingStatus from './PendingStatus';
import PageHelp from './PageHelp';
import ChangeEnableSpotHelp from './ChangeEnableSpotHelp';

const display = {
  header: 'Spot Configuration',
  cancelButton: 'Cancel',
  updateButton: 'Update',
  savingButton: 'Updating...',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  enableSpotLabel: 'Enable Spot Instances',
  enableASGCapacityRebalance: 'Enable Capacity Rebalance',
  formContentHeader: 'Spot Settings',
};

const ChangeEnableSpotForm = ({
  spotEnabled,
  enableASGCapacityRebalance,
  name,
  status,
  actionStatus,
  prestoClusterId,
  submitSuccess,
  usePop,
  workflowInProgress,
}) => {
  const { watch } = useFormContext();

  const enableSpotValue = watch('enableSpot', spotEnabled);

  const enableFeature = useEnableFeature();
  const actionInProgress = useContext(ActionInProgressStateContext);

  const submitInProgress = useMemo(() => {
    return (
      actionStatus &&
      actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    );
  }, [actionStatus, actionInProgress]);

  const getRoute = useCallback(() => {
    return PrestoClusters.routes.getManagePrestoClusterRoute(prestoClusterId);
  }, [prestoClusterId]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  const canChangeEnableSpot =
    enableFeature &&
    (status === PrestoClusters.constants.status.active ||
      status === PrestoClusters.constants.status.inactive) &&
    !workflowInProgress;

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    !actionStatus.inProgress &&
    actionStatus.name === 'editEnableSpot';

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.TertiaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          {!canChangeEnableSpot && (
            <Banner scrollIntoView>
              <PendingStatus name={name} status={status} />
            </Banner>
          )}
          {showActionStatusError && (
            <Banner scrollIntoView title={actionStatus.error} />
          )}
          {!enableFeature && (
            <Banner scrollIntoView title={display.featureUnavailable} />
          )}
          <PrestoClusterInfo name={name} />
          <Page.Box>
            <Page.BoxHeader>
              <h2>{display.header}</h2>
            </Page.BoxHeader>
            <Page.FormContent>
              <div>
                <h3>{display.formContentHeader}</h3>
                {/* <div>{display.formExplaination}</div> */}
              </div>
              <div>
                <Form.CheckboxInput
                  name='enableSpot'
                  defaultChecked={spotEnabled}
                  label={display.enableSpotLabel}
                  disabled={
                    submitInProgress || !canChangeEnableSpot || !enableFeature
                  }
                  fieldHelp={ChangeEnableSpotHelp.EnableSpot}
                />
                <Form.CheckboxInput
                  name='enableASGCapacityRebalance'
                  defaultChecked={enableASGCapacityRebalance}
                  label={display.enableASGCapacityRebalance}
                  disabled={
                    submitInProgress ||
                    !canChangeEnableSpot ||
                    !enableFeature ||
                    !enableSpotValue
                  }
                  fieldHelp={ChangeEnableSpotHelp.EnableCapacityRebalance}
                />
              </div>
            </Page.FormContent>
          </Page.Box>
          <Page.Buttons>
            <Form.TertiaryButton
              onClick={onCancel}
              label={display.cancelButton}
            />
            <Form.Submit
              label={display.updateButton}
              disabled={
                submitInProgress || !canChangeEnableSpot || !enableFeature
              }
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

ChangeEnableSpotForm.defaultProps = {
  actionStatus: undefined,
  spotEnabled: false,
  enableASGCapacityRebalance: true,
  name: undefined,
  status: null,
  submitSuccess: false,
  usePop: false,
  workflowInProgress: null,
};

ChangeEnableSpotForm.propTypes = {
  spotEnabled: bool,
  enableASGCapacityRebalance: bool,
  name: string,
  status: string,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  prestoClusterId: string.isRequired,
  submitSuccess: bool,
  usePop: bool,
  workflowInProgress: bool,
};

export default ChangeEnableSpotForm;
