/* eslint-disable react/jsx-props-no-spreading */
import { string } from 'prop-types';
/* eslint-disable */

const Logo = (props) => {
  return (
    <div
      aria-atomic='true'
      aria-live='assertive'
      className='bx--loading bx--loading--small'
    >
      <label id='loading-id-1' className='bx--visually-hidden'>
        Active loading indicator
      </label>
      <svg className='bx--loading__svg' viewBox='0 0 100 100'>
        <title>Active loading indicator</title>
        <circle className='bx--loading__stroke' cx='50%' cy='50%' r='44' />
      </svg>
    </div>
  );
};

Logo.defaultProps = {
  classNames: undefined,
};

Logo.propTypes = {
  classNames: string,
};

export default Logo;
