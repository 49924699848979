import { stringify } from 'yaml';
import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import * as actionTypes from './actionTypes';
import * as ComputePlaneActionTypes from '../ComputePlane/actionTypes';

const initialState = {
  submitInProgress: false,
  submitSuccess: false,
  errors: undefined,
  submitId: null,
  prometheusConfiguration: null,
};

const onNavigation = () => {
  return { ...initialState };
};

const get = (draft, action) => {
  const prometheusConfiguration = action.payload.apiResult.body
    .prometheusConfiguration
    ? {
        ...action.payload.apiResult.body.prometheusConfiguration,
      }
    : null;

  if (prometheusConfiguration?.remoteWriteRule) {
    const cleanedRemoteWriteRule = {
      ...prometheusConfiguration.remoteWriteRule,
    };

    if (cleanedRemoteWriteRule.basic_auth) {
      cleanedRemoteWriteRule.basic_auth = {
        ...cleanedRemoteWriteRule.basic_auth,
        password: undefined,
      };
    }

    if (cleanedRemoteWriteRule.authorization) {
      cleanedRemoteWriteRule.authorization = {
        ...cleanedRemoteWriteRule.authorization,
        credentials: undefined,
      };
    }

    if (cleanedRemoteWriteRule.sigv4) {
      cleanedRemoteWriteRule.sigv4 = {
        ...cleanedRemoteWriteRule.sigv4,
        secret_key: undefined,
      };
    }

    if (cleanedRemoteWriteRule.oauth2) {
      cleanedRemoteWriteRule.oauth2 = {
        ...cleanedRemoteWriteRule.oauth2,
        client_secret: undefined,
        proxy_connect_header: cleanedRemoteWriteRule.oauth2.proxy_connect_header
          ? {
              ...cleanedRemoteWriteRule.oauth2.proxy_connect_header,
              // Assuming proxy_connect_header may contain sensitive info
            }
          : undefined,
      };
    }

    if (
      cleanedRemoteWriteRule.azuread &&
      cleanedRemoteWriteRule.azuread.oauth
    ) {
      cleanedRemoteWriteRule.azuread.oauth = {
        ...cleanedRemoteWriteRule.azuread.oauth,
        client_secret: undefined,
      };
    }

    prometheusConfiguration.displayRemoteWriteRule = stringify(
      cleanedRemoteWriteRule,
      {
        keepUndefined: true,
      },
    ).replace(': null', ': ');
  }

  draft.prometheusConfiguration = prometheusConfiguration;
};

const submitStart = (draft) => {
  draft.submitInProgress = true;
  draft.submitSuccess = false;
  draft.errors = undefined;
};

const submitSuccess = (draft) => {
  draft.submitInProgress = false;
  draft.submitSuccess = true;
  draft.errors = undefined;
};

const submitFailure = (draft, action) => {
  draft.submitInProgress = false;
  draft.submitSuccess = false;

  // if (action.payload.apiResult.body.errorCode === 'computeplane-6') {
  //   draft.errors = {
  //     computePlane: action.payload.apiResult.body.errorMessage,
  //   };
  // } else if (
  //   action.payload.apiResult.body.errorCode === 'computeplane-4' ||
  //   action.payload.apiResult.body.errorCode === 'computeplane-22'
  // ) {
  //   draft.errors = {
  //     configuration: action.payload.apiResult.body.errorMessage,
  //   };
  // } else {
  //   draft.errors = {
  //     ingress: action.payload.apiResult.body.errorMessage,
  //   };
  // }
  draft.errors = {
    prometheus: action.payload.apiResult.body.errorMessage,
  };
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.submit]: submitStart,
  [actionTypes.put.success]: submitSuccess,
  [actionTypes.put.failure]: submitFailure,
  [auth.actionTypes.signOut]: signOut,
  [ComputePlaneActionTypes.get.success]: get,
};

export default makeReducer(initialState, actionReducers);
