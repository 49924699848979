import { makeReducer } from '../utils/reducer';
import * as actionTypes from './actionTypes';

const initialState = {
  pathname: null,
  search: null,
  hash: null,
  state: null,
  key: null,
  hasLocationHistory: 0,
};

const currentRouteChange = (draft, action) => {
  draft.pathname = action.payload.location.pathname;
  draft.search = action.payload.location.search;
  draft.hash = action.payload.location.hash;
  draft.state = action.payload.location.state;
  draft.key = action.payload.location.key;

  if (action.payload.action === 'PUSH') {
    draft.hasLocationHistory += 1;
  }
  if (action.payload.action === 'POP' && draft.hasLocationHistory > 0) {
    draft.hasLocationHistory -= 1;
  }
};

const initializeSearch = (draft, action) => {
  draft.search = action.payload.search;
};

const actionReducers = {
  [actionTypes.currentRouteChange]: currentRouteChange,
  [actionTypes.initSearchParams]: initializeSearch,
};

export default makeReducer(initialState, actionReducers);
