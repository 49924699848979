/* eslint-disable no-restricted-globals */
export function convertTimestampStrToDate(timestampStr) {
  const timestamp = parseInt(timestampStr, 10);
  return new Date(timestamp);
}

let testingDate;
/**
 * Get the current Date, or date configured for testing
 * @return {Date}
 */
export function getNow() {
  if (testingDate) {
    return testingDate;
  }

  return new Date();
}

export function setNow(now) {
  testingDate = now;
}

export function convertTimestampStrToLocaleString(timestampStr) {
  const d = new Date(timestampStr);
  return d.toLocaleString();
}

export function populateMonthlyDropdown(currentMonthIndex, year) {
  if (
    isNaN(currentMonthIndex) ||
    currentMonthIndex < 0 ||
    currentMonthIndex > 11
  )
    return null;

  if (year && (isNaN(year) || year < 2020 || year > 3000)) return null;

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const MONTHS_IN_YEAR = 12;
  const now = new Date();
  const currentYear = year || now.getFullYear();

  const options = [];

  for (let i = MONTHS_IN_YEAR - 1; i >= 0; i -= 1) {
    let y = currentYear;
    let monthIndex = currentMonthIndex - i;

    if (monthIndex < 0) {
      // The month belongs to the previous year
      y = currentYear - 1;
      monthIndex = MONTHS_IN_YEAR - Math.abs(monthIndex);
    }

    options.push({
      value: `${monthIndex}/${y}`,
      display: `${monthNames[monthIndex]} (${y})`,
    });
  }

  return options;
}

// NOTE: this receives a month index (0-11) in the format M/YYYY or MM/YYYY
// and returns a month in date format (1-12) in the format YYYYMMDD
export function getMonthlyRange(monthIndexYear) {
  if (!monthIndexYear.includes('/')) return null;

  const RADIX_INTEGER_CONVERSION = 10;

  // eslint-disable-next-line prefer-const
  let [month, year] = monthIndexYear.split('/');
  month = parseInt(month, RADIX_INTEGER_CONVERSION);
  year = parseInt(year, RADIX_INTEGER_CONVERSION);

  if (isNaN(month) || month < 0 || month > 11 || isNaN(year)) return null;

  const lastDateOfMonth = new Date(year, month + 1, 0); // using 0 as a day for the next month gets the last day of the current month
  month += 1;

  if (month.toString().length === 1) month = `0${month}`;

  return {
    startDate: `${year}${month}01`,
    endDate: `${year}${month}${lastDateOfMonth.getDate()}`,
  };
}
