import { useCallback, useState } from 'react';
import { arrayOf, bool, string, shape } from 'prop-types';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import Banner from '../../Banner';
import AuthorizationServices from '../../AuthorizationServices';
import DataSources from '../../DataSources';
import PrestoClusters from '../../PrestoClusters';
import PrestoUsers from '../../PrestoUsers';
import {
  DataSourceDetails,
  PrestoUserMappings,
} from '../../DataSourceAdd/components';
import ConnectionDetails from './ConnectionDetails';
import PageHelp from './PageHelp';

const display = {
  header: 'Edit Data Source',
  updateButton: 'Modify Data Source',
  cancelButton: 'Cancel',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  savingDataSource: 'Updating data source...',
  confirmTitle:
    'This action will restart the Presto clusters that have this data source attached.',
  confirmDetails:
    'All queries running on those clusters will fail. The clusters will be moved to pending state and will be unusable until they move back to active state.',
};

const UpdateDataSourceForm = ({
  usePop,
  submitInProgress,
  submitSuccess,
  errors,
  dataSource,
  awsRegions,
  computePlaneRegion,
  awsGlueS3CloudFormationQuickCreateUrl,
  awsLakeFormationCloudFormationQuickCreateUrl,
  prestoClusters,
  prestoUsers,
  loadingPrestoUsers,
  unsupportedLakeFormationPrestoClusters,
  unsupportedRangerHivePrestoClusters,
  authorizationServices,
  dataSourceId,
}) => {
  const getRoute = useCallback(() => {
    return DataSources.routes.getManageDataSourceRoute(dataSourceId);
  }, [dataSourceId]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const onConfirm = useCallback(() => {
    setConfirmSubmit(true);
  }, []);

  const cancelConfirm = useCallback(() => {
    setConfirmSubmit(false);
  }, []);

  const showBanner =
    prestoClusters === null
      ? false
      : prestoClusters.some((pc) => {
          return pc.status === PrestoClusters.constants.status.active;
        });

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>
          {display.header} {usePop}
        </h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <DataSourceDetails
            disabled={submitInProgress}
            error={errors.details}
            dataSource={dataSource}
          />
          <ConnectionDetails
            disabled={submitInProgress}
            error={errors.connection}
            dataSource={dataSource}
            awsRegions={awsRegions}
            computePlaneRegion={computePlaneRegion}
            awsGlueS3CloudFormationQuickCreateUrl={
              awsGlueS3CloudFormationQuickCreateUrl
            }
            awsLakeFormationCloudFormationQuickCreateUrl={
              awsLakeFormationCloudFormationQuickCreateUrl
            }
            unsupportedLakeFormationPrestoClusters={
              unsupportedLakeFormationPrestoClusters
            }
            unsupportedRangerHivePrestoClusters={
              unsupportedRangerHivePrestoClusters
            }
            authorizationServices={authorizationServices}
          />
          <PrestoUserMappings
            disabled={submitInProgress}
            error={errors.prestoUserMappings}
            dataSource={dataSource}
            prestoUsers={prestoUsers}
            loadingPrestoUsers={loadingPrestoUsers}
          />
          {!showBanner && (
            <Page.Buttons>
              <Form.SecondaryButton
                onClick={onCancel}
                label={display.cancelButton}
                disabled={submitInProgress}
                disabledOnErrors={false}
              />
              <Form.Submit
                label={
                  !submitInProgress
                    ? display.updateButton
                    : display.savingDataSource
                }
                disabled={submitInProgress}
                showLoading={submitInProgress}
              />
            </Page.Buttons>
          )}
          {showBanner && confirmSubmit && (
            <Banner
              title={display.confirmTitle}
              details={display.confirmDetails}
            >
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={cancelConfirm}
                  label={display.cancelButton}
                  disabled={submitInProgress}
                  disabledOnErrors={false}
                />
                <Form.Submit
                  label={
                    !submitInProgress
                      ? display.updateButton
                      : display.savingDataSource
                  }
                  disabled={submitInProgress}
                  showLoading={submitInProgress}
                />
              </div>
            </Banner>
          )}
          {showBanner && !confirmSubmit && (
            <Page.Buttons>
              <Form.SecondaryButton
                onClick={onCancel}
                label={display.cancelButton}
                disabled={submitInProgress}
                disabledOnErrors={false}
              />
              <Form.PrimaryButton
                onClick={onConfirm}
                label={
                  !submitInProgress
                    ? display.updateButton
                    : display.savingDataSource
                }
                disabled={submitInProgress}
                showLoading={submitInProgress}
              />
            </Page.Buttons>
          )}
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

UpdateDataSourceForm.defaultProps = {
  usePop: true,
  submitInProgress: false,
  submitSuccess: false,
  errors: {},
  awsGlueS3CloudFormationQuickCreateUrl: null,
  awsLakeFormationCloudFormationQuickCreateUrl: null,
  prestoClusters: [],
  prestoUsers: null,
  unsupportedLakeFormationPrestoClusters: false,
  unsupportedRangerHivePrestoClusters: false,
  authorizationServices: null,
};

UpdateDataSourceForm.propTypes = {
  usePop: bool,
  submitInProgress: bool,
  submitSuccess: bool,
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
  dataSource: DataSources.propTypes.DataSource.isRequired,
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  computePlaneRegion: string.isRequired,
  awsGlueS3CloudFormationQuickCreateUrl: string,
  awsLakeFormationCloudFormationQuickCreateUrl: string,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadingPrestoUsers: bool.isRequired,
  unsupportedLakeFormationPrestoClusters: bool,
  unsupportedRangerHivePrestoClusters: bool,
  authorizationServices: arrayOf(
    AuthorizationServices.propTypes.AuthorizationService,
  ),
  dataSourceId: string.isRequired,
};

export default UpdateDataSourceForm;
