import { buildUrl } from '../../utils/buildUrl';
import telemetry from '../../telemetry';
import rayApi from '../../rayApi';
import noop from '../../utils/noop';

const baseResource = 'prestousers';

export const getAll = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: baseResource });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const get = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoUserId}`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getPrestoClustersForPrestoUser = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoUserId}/prestoclusters`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const getDataSourcesForPrestoUser = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoUserId}/datasources`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};

export const post = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: baseResource });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePOST(uri, headers, prepedBody, abortFetchSignal);
};

export const patch = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoUserId}`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePATCH(uri, headers, prepedBody, abortFetchSignal);
};

export const remove = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.prestoUserId}`,
  });
  return rayApi.api.executeDELETE(uri, headers, body, abortFetchSignal);
};

const networkRequestSanitizer = (request) => {
  let sanitizedRequest = request;
  if (
    sanitizedRequest.url.includes(baseResource) &&
    (sanitizedRequest.method === 'POST' || sanitizedRequest.method === 'PATCH')
  ) {
    sanitizedRequest = {
      ...sanitizedRequest,
      body: telemetry.sanitize(sanitizedRequest.body),
    };
  }
  return sanitizedRequest;
};

telemetry.networkSanitizer.registerNetworkSanitizer(
  'prestoUsers',
  networkRequestSanitizer,
  noop,
);
