import { takeEvery, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import encryptData from '../../utils/encryptData';
import * as IdentityProviderUpdateActions from '../IdentityProvidersUpdate/actions';
import * as IdentityProviderUpdateActionsTypes from '../IdentityProvidersUpdate/actionTypes';

const keyId = process.env.REACT_APP_KMS_BROWSER_ENCRYPT_KEY_ALIAS;
const encryptionAlgorithm = 'RSAES_OAEP_SHA_256';

function* cancelableCreateOrUpdateIdentityProvider(action) {
  const body = { ...action.payload.body };
  const encrypted = yield call(
    encryptData,
    body.clientSecret,
    keyId,
    encryptionAlgorithm,
  );

  body.clientSecret = encrypted;

  if (action.type === actionTypes.submit) {
    yield put(actions.post(body));
  }

  if (action.type === IdentityProviderUpdateActionsTypes.submit) {
    const { identityProviderId } = action.payload;
    yield put(IdentityProviderUpdateActions.patch(identityProviderId, body));
  }
}

export default function* identityProviderAddRootSaga() {
  yield all([
    takeEvery(actionTypes.submit, cancelableCreateOrUpdateIdentityProvider),
    takeEvery(
      IdentityProviderUpdateActionsTypes.submit,
      cancelableCreateOrUpdateIdentityProvider,
    ),
  ]);
}
