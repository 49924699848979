import { useEffect, useMemo } from 'react';
import { arrayOf, func, shape, string, bool } from 'prop-types';
import Page from '../../../../Page';
import Form from '../../../../Form';
import Data from '../../../../Data';
import ComputePlane from '../../../../ComputePlane';
import { validateSubnets } from '../../../utils';
import * as Help from '../../CreateComputePlaneHelp';

const display = {
  awsSubnetsDisabled: 'Loading...',
  vpcContentHeader: 'VPC Settings',
  privateSubnets: 'Private Subnet IDs',
  publicSubnets: 'Public Subnet IDs',
  subnetsRequired: 'At least two subnets are required.',
};

const SubnetsMultiSelect = ({
  name,
  label,
  disabled,
  awsSubnets,
  fieldHelp,
  defaultValue,
}) => {
  const awsSubnetsSelectItems = useMemo(() => {
    if (!awsSubnets) return null;
    return awsSubnets.map((s) => {
      return {
        display: s.name ? `${s.id} (${s.name})` : `${s.id}`,
        value: s.id,
      };
    });
  }, [awsSubnets]);

  if (!awsSubnetsSelectItems) {
    return (
      <Data.DataField
        label={label}
        value={display.awsSubnetsDisabled}
        disabled
        fieldHelp={fieldHelp}
      />
    );
  }
  return (
    <Form.MultiSelectInput
      name={name}
      label={label}
      defaultValue={defaultValue}
      validationRules={{
        required: display.subnetsRequired,
        validate: { validateSubnets },
      }}
      items={awsSubnetsSelectItems}
      disabled={disabled}
      fieldHelp={fieldHelp}
    />
  );
};

SubnetsMultiSelect.defaultProps = {
  disabled: false,
  awsSubnets: null,
  defaultValue: undefined,
};

SubnetsMultiSelect.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  awsSubnets: ComputePlane.propTypes.awsSubnets,
  disabled: bool,
  defaultValue: arrayOf(string),
  fieldHelp: func.isRequired,
};

export const UserManaged = ({ computePlane, awsSubnets, getVPCSubnets }) => {
  useEffect(() => {
    const { computePlaneId } = computePlane;
    getVPCSubnets(computePlaneId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computePlane, getVPCSubnets]);

  return (
    <Page.FormContent rows={3}>
      <div>
        <h3>{display.vpcContentHeader}</h3>
      </div>
      <div>
        <SubnetsMultiSelect
          name='privateSubnets'
          label={display.privateSubnets}
          awsSubnets={awsSubnets}
          disabled={false}
          fieldHelp={Help.privateSubnets}
          defaultValue={computePlane?.awsCloudConfiguration?.privateSubnetIds}
        />
        <SubnetsMultiSelect
          name='publicSubnets'
          label={display.publicSubnets}
          awsSubnets={awsSubnets}
          disabled={false}
          fieldHelp={Help.publicSubnets}
          defaultValue={computePlane?.awsCloudConfiguration?.publicSubnetIds}
        />
      </div>
    </Page.FormContent>
  );
};

UserManaged.defaultProps = {
  computePlane: null,
  awsSubnets: null,
};

UserManaged.propTypes = {
  computePlane: shape({
    awsCloudConfiguration: shape({
      privateSubnetIds: arrayOf(string),
      publicSubnetIds: arrayOf(string),
    }),
  }),
  awsSubnets: ComputePlane.propTypes.awsSubnets,
  getVPCSubnets: func.isRequired,
};
