import * as constants from './constants';

const getPolicyType = (props) => {
  return props?.policyType || props?.params?.policyType || props;
};

export const getPolicyContent = (state, props) => {
  const policyType = getPolicyType(props);

  if (!state.computePlane.configuration) return null;

  switch (policyType) {
    case constants.policyType.permissionBoundary:
      return state.computePlane.configuration
        .awsPermissionBoundaryPolicyContent;
    case constants.policyType.coreInfrastructure:
      return state.computePlane.configuration
        .awsCoreInfrastructurePolicyContent;
    case constants.policyType.operations:
      return state.computePlane.configuration.awsOperationsPolicyContent;
    case constants.policyType.trustRelationship:
      return state.computePlane.configuration.awsTrustRelationshipPolicyContent;
    case constants.policyType.provisioningRoleCFTemplate:
      return state.computePlane.configuration.cloudFormationTemplateContent;
    case constants.policyType.provisioningRoleTerraformTemplate:
      return state.computePlane.configuration.terraformContent;
    default:
      return null;
  }
};

export const getPolicyTitle = (state, props) => {
  const policyType = getPolicyType(props);

  switch (policyType) {
    case constants.policyType.permissionBoundary:
      return 'Permission Boundary AWS IAM Policy';
    case constants.policyType.coreInfrastructure:
      return 'Core Infrastructure AWS IAM Policy';
    case constants.policyType.operations:
      return 'Operations AWS IAM Policy';
    case constants.policyType.trustRelationship:
      return 'IAM Role Trust Relationship Policy';
    case constants.policyType.provisioningRoleCFTemplate:
      return 'Provisioning Role CloudFormation Template';
    case constants.policyType.provisioningRoleTerraformTemplate:
      return 'Provisioning Role Terraform Template';
    default:
      return null;
  }
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};
