import Help from '../../Help';

const Hostname = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Hostname</Help.HelpHeader>
      <Help.P>
        The hostname to the IBM Db2 instance (e.g. your.hostname.com,
        1.23.45.67).
      </Help.P>
    </Help.HelpSection>
  );
};

const Port = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Port</Help.HelpHeader>
      <Help.P>
        The port for the IBM Db2 instance (the default port for IBM Db2 is
        50000).
      </Help.P>
    </Help.HelpSection>
  );
};

const Database = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Database Name</Help.HelpHeader>
      <Help.P>
        The name of the database to connect to. If you need to access multiple
        databases on this database server, please create additional data sources
        with the same endpoint and port and a different database name.
      </Help.P>
    </Help.HelpSection>
  );
};

const ConfigurationProperties = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Configuration Properties</Help.HelpHeader>
      <Help.P>
        Additional properties which can be used to specify additional driver
        behavior specific to IBM Db2 (e.g. readOnly=true;traceLevel=-1). See{' '}
        <a
          href='https://www.ibm.com/docs/en/db2/11.5?topic=information-properties-data-server-driver-jdbc-sqlj'
          target='_blank'
          rel='noopener noreferrer'
        >
          https://www.ibm.com/docs/en/db2/11.5?topic=information-properties-data-server-driver-jdbc-sqlj
        </a>{' '}
        for more details.
      </Help.P>
    </Help.HelpSection>
  );
};

const User = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>User</Help.HelpHeader>
      <Help.P>The database username.</Help.P>
    </Help.HelpSection>
  );
};

const Password = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Password</Help.HelpHeader>
      <Help.P>The password for the database user.</Help.P>
    </Help.HelpSection>
  );
};

const SSLEnabled = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>SSL connection</Help.HelpHeader>
      <Help.P>
        Db2 SSL connection is on by default. Un-select if your Db2 instance is
        not configured with SSL support.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  Hostname,
  Port,
  Database,
  User,
  Password,
  SSLEnabled,
  ConfigurationProperties,
};

export default module;
