import { useCallback, useEffect } from 'react';
import { string, shape, bool, func, object, arrayOf } from 'prop-types';
import Page from '../../Page';
import Form from '../../Form';
import Help from '../../Help';
import PageHelp from './PageHelp';
import StandalonePrometheus from '../../StandalonePrometheus';
import { DropDownValue } from '../propTypes';
import Prometheus from './Prometheus';
import CustomTags from '../../CustomTags/CustomTags';

const display = {
  header: 'Configure Prometheus',
  saveButton: 'Save',
  cancelButton: 'Cancel',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  savingPrometheus: 'Adding Prometheus...',
};

const AddPrometheusForm = ({
  submitInProgress,
  submitSuccess,
  errors,
  usePop,
  prometheus,
  instanceTypeValues,
}) => {
  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute: StandalonePrometheus.routes.getRoute,
    usePop,
  });

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>
          {display.header} {usePop}
        </h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <Prometheus
            disabled={submitInProgress}
            error={errors.prometheus}
            prometheus={prometheus}
            instanceTypeValues={instanceTypeValues}
          />
          <CustomTags
            customTags={prometheus?.customTags}
            disabled={submitInProgress}
          />
          <Page.Buttons>
            <Form.SecondaryButton
              onClick={onCancel}
              label={display.cancelButton}
              disabled={submitInProgress}
              disabledOnErrors={false}
            />
            <Form.Submit
              label={
                !submitInProgress
                  ? display.saveButton
                  : display.savingPrometheus
              }
              disabled={submitInProgress}
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

AddPrometheusForm.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  usePop: true,
  errors: {},
  prometheus: null,
};

AddPrometheusForm.propTypes = {
  submitInProgress: bool,
  submitSuccess: bool,
  usePop: bool,
  errors: shape({
    prometheus: string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  prometheus: object,
  instanceTypeValues: arrayOf(DropDownValue).isRequired,
};

const AddPrometheus = ({
  create,
  errors,
  submitInProgress,
  submitSuccess,
  usePop,
  prometheusConfiguration,
  computePlaneId,
  instanceTypeValues,
  get,
}) => {
  useEffect(() => {
    if (computePlaneId) {
      get(computePlaneId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computePlaneId]);

  const submitAction = useCallback(
    (data) => {
      create(computePlaneId, data);
    },
    [create, computePlaneId],
  );

  return (
    <Page.PageWithOverflow>
      <Form.Form name='add-prometheus' submitAction={submitAction}>
        <AddPrometheusForm
          submitInProgress={submitInProgress}
          submitSuccess={submitSuccess}
          errors={errors}
          usePop={usePop}
          prometheus={prometheusConfiguration}
          instanceTypeValues={instanceTypeValues}
        />
      </Form.Form>
    </Page.PageWithOverflow>
  );
};

AddPrometheus.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  usePop: true,
  errors: {},
  prometheusConfiguration: null,
  computePlaneId: null,
};

AddPrometheus.propTypes = {
  create: func.isRequired,
  submitInProgress: bool,
  submitSuccess: bool,
  usePop: bool,
  errors: shape({
    prometheus: string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  prometheusConfiguration: object,
  computePlaneId: string,
  instanceTypeValues: arrayOf(DropDownValue).isRequired,
  get: func.isRequired,
};

export default AddPrometheus;
