import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { colors, fonts, buttons } from '../../css';
import Form from '../Form';
import { BannerDiv } from '../Banner';
import Icons from '../../icons';

const minPageWidth = 500;
const maxPageWidth = 1500;
export const StandardLayoutDiv = styled.div`
  flex: 1 0 auto;
  min-height: 100vh;
  background-color: ${colors.page_background};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
`;

export const LogoBoxDiv = styled.div`
  width: 427px;
  display: flex;
  flex: 0 0 auto !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 48px 30px 30px;
  margin-top: 100px;
  background-color: ${colors.section_background};
  border: 1px solid ${colors.border};
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 ${colors.shadow};

  > div.title {
    margin-top: 24px;
    margin-bottom: 24px;
    ${fonts.large_bold}
    color: ${colors.font_light};
    letter-spacing: 0.2px;
    align-self: center;
  }

  > img.logo {
    height: 100px;
  }

  a {
    ${fonts.medium_bold}
    color: ${colors.font_light};
    text-decoration: none;
    margin-bottom: 24px;
    align-self: center;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: ${colors.focus_light};
      outline: none;
    }
  }

  form {
    display: flex;
    flex-flow: column;
  }
`;

export const LogoBoxFooterDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  a {
    ${fonts.medium}
    color: ${colors.font_light};
    text-decoration: none;
    letter-spacing: 0.16px;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: ${colors.focus_light};
      outline: none;
    }

    &:not(:last-child) {
      :after {
        display: inline-block;
        content: '•';
        margin: 0 10px 10px;
      }

      &:hover,
      &:active,
      &:focus {
        :after {
          text-decoration: none;
          color: ${colors.font_light};
        }
      }
    }
  }
`;

export const ScrollDiv = styled.div`
  margin: 0 auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  min-height: 0px;
  max-height: calc(100vh - var(--header-height));
  width: 100%;

  > * {
    flex: 1 0 auto;
  }
`;

export const PageDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height));
  min-height: calc(100vh - var(--header-height));
  max-height: calc(100vh - var(--header-height));
`;

export const PageWithOverflowDiv = styled(PageDiv)`
  overflow-y: auto;
`;

export const MainDiv = styled.div`
  margin: 0 auto;
  background-color: ${colors.page_background};
  width: 100%;
  min-width: ${minPageWidth}px;
  max-width: ${maxPageWidth}px;
  padding: 40px;
`;

export const PageScrollDiv = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  /* scroll-snap-type: y proximity; */
`;

export const PageHeaderDiv = styled.div`
  flex: 0 0 auto;
  width: 100%;
  border-bottom: 1px solid ${colors.border};
  display: flex;
  flex-flow: column;
  justify-content: center;

  > ${MainDiv} {
    min-width: ${minPageWidth}px;
    max-width: ${maxPageWidth}px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 auto;

    h1 {
      color: ${colors.font_light};
      ${fonts.extra_large_extra_bold};
      letter-spacing: 0.32px;
      padding-top: 37px;
      padding-bottom: 41px;
      margin: 0;

      > span {
        margin-left: 10px;
        vertical-align: 6px;
      }
    }
  }
`;

export const PageNavigationDiv = styled.div`
  > div {
    display: flex;
    justify-content: flex-start;
    padding: 0;

    > div {
      margin: 0 20px;
      padding: 14px 3px;
      ${fonts.medium_bold}
      color: ${colors.font_light};
      outline: none;
      cursor: pointer;

      &.selected {
        padding: 14px 3px 11px;
        border-bottom: 3px solid ${colors.primary_button};
      }

      &:focus {
        padding: 11px 0;
        border: 3px solid ${colors.input_focus};
      }
    }
  }
`;

export const PageHeaderWithNavDiv = styled(PageHeaderDiv)`
  padding-top: 37px;
  > ${MainDiv} {
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 auto;
      h1 {
        padding-bottom: 0;
        padding-top: 0;

        > span {
          margin-left: 10px;
          vertical-align: 6px;
        }
      }

      div.buttons {
        padding-top: 2px;
      }
    }

    ${PageNavigationDiv} {
      padding-top: 13px;
    }
  }
`;

export const PageHeaderButtonsDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 24px;

  > * {
    margin-left: 11px;
  }
`;

export const PageEmptyDiv = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: ${colors.font_light};
  padding-bottom: 10px;
  height: calc(100vh - var(--header-height) - 122px);
  min-height: calc(100vh - var(--header-height) - 122px);
  max-height: calc(100vh - var(--header-height) - 122px);

  > * {
    flex: 0 0 auto;
  }

  svg {
    flex: 0 1 auto;
    margin-top: 40px;
    margin-bottom: 30px;
    height: 250px;
  }

  > div {
    display: flex;
    flex-flow: column;
    align-items: center;
    @media (max-height: 580px) {
      display: block;
    }
  }

  .title {
    ${fonts.large_bold}
    letter-spacing: 0.2px;
    margin-bottom: 8px;
  }

  .details {
    ${fonts.medium}
    letter-spacing: 0.16px;
  }
`;

export const FormPageDiv = styled.div`
  margin: 0 auto;
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-template-rows: auto;
  background-color: ${colors.page_background};
  width: 100%;
  min-width: ${minPageWidth}px;
  max-width: ${maxPageWidth}px;
  padding: 0 40px;
`;

export const FormColumnDiv = styled.div`
  width: 100%;
  display: block;
  border-right: 1px solid ${colors.border};
  padding-bottom: 100px;
  padding-right: 30px;
  min-width: 0;
  overflow-x: auto;

  > form > div,
  > div {
    margin-top: 40px;
  }
`;

export const HelpColumnDiv = styled.div`
  position: sticky;
  top: 0;
  overflow: auto;
  max-height: calc(100vh - var(--header-height) - 70px - 122px);
  display: block;
  padding-left: 70px;
  padding-top: 70px;
`;

export const InformationGridDiv = styled.div`
  display: grid;
  background-color: ${colors.border};
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  grid-template-rows: auto;

  .cell {
    background-color: ${colors.section_background};
    padding: 24px;

    .title {
      ${fonts.medium_bold}
      color: ${colors.font_light};
    }

    .data {
      ${fonts.extra_large_extra_bold}
      color: ${colors.font_light};
      flex-grow: 1;
    }

    .stretch {
      display: flex;
      flex-flow: row;
      justify-content: stretch;
      width: 100%;
    }
  }
`;

export const BoxDiv = styled.div`
  display: flex;
  flex-flow: column;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 ${colors.shadow};
  background-color: ${colors.section_background};
  color: ${colors.font_light};
  ${fonts.medium};
  letter-spacing: 0.16px;

  &.error {
    border-color: ${colors.error};
  }

  ${BannerDiv} {
    letter-spacing: 0.4px;
    margin: 24px;
    margin-bottom: 8px;
  }

  & + div {
    margin-top: 43px;
  }

  ${InformationGridDiv} + ${InformationGridDiv}:not(:nth-child(1)) {
    border-top: 1px solid ${colors.border};
  }
`;

export const BoxNavTargetDiv = styled(BoxDiv)`
  margin-top: 40px;

  & + span {
    margin-top: 3px;
  }

  & + span + div {
    margin-top: 36px;
  }
`;

export const BoxNavTargetSpan = styled.span`
  display: block;
  height: 1px;
`;

export const BoxHeaderDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.border};

  h2 {
    ${fonts.large_bold};
    letter-spacing: 0.2px;
    padding: 24px;
    margin: 0;

    > * {
      margin-left: 10px;
    }
  }

  h3 {
    ${fonts.medium_bold};
    letter-spacing: 0.2px;
    padding: 24px;
    margin: 0;
  }

  div.buttons {
    padding: 24px;
    display: flex;
    flex-flow: row;

    > * {
      margin-left: 10px;
    }
  }
`;

export const TableBoxHeaderDiv = styled(BoxHeaderDiv)`
  border-bottom: 0;

  div {
    display: flex;
    flex-flow: row;
    align-items: center;
  }
`;

export const BoxContentWithHelpDiv = styled.div`
  display: grid;
  grid-template-columns: auto minmax(200px, 50%);
  grid-template-rows: auto;
  border-bottom-right-radius: 4px;
`;

export const BoxContentHelpDiv = styled.div`
  display: block;
  border-left: 1px solid ${colors.border};
  background-color: ${colors.page_background};
  padding: 24px 32px;
  border-bottom-right-radius: 4px;
`;

export const FormContentDiv = styled.div`
  --row-count: 1;
  display: grid;
  grid-template-columns: auto minmax(373px, 50%);
  grid-template-rows: repeat(var(--row-count), auto);
  grid-column-gap: 24px;
  padding: 24px;

  h3 {
    ${fonts.medium_bold};
    margin: 0;
  }

  > div {
    display: flex;
    flex-flow: column;
  }

  .badge {
    margin-bottom: 24px;
  }
`;

export const DataContentWithLogoDiv = styled(FormContentDiv)`
  grid-template-columns: minmax(150px, 10%) auto;
  grid-column-gap: 40px;
  color: ${colors.font_light};
  img.logo {
    width: 100%;
    padding-left: 20px;
    display: block;
  }
`;

export const InformationBannerDiv = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${colors.border};
`;

export const DangerZoneDiv = styled.div`
  --row-count: 1;
  display: grid;
  grid-template-columns: auto 240px;
  grid-template-rows: repeat(var(--row-count), auto);
  background-color: ${colors.border};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  grid-row-gap: 1px;

  h3 {
    ${fonts.medium_bold};
    margin: 0;
    padding-bottom: 4px;
  }

  > div {
    padding: 24px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background-color: ${colors.section_background};

    ${Form.ButtonWrapperDiv} {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  > div:last-child,
  div:nth-last-child(2) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const ConfirmDangerDiv = styled.div`
  grid-column: 1 / 3;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  position: relative;
`;

const LinkButton = styled(Link)`
  ${buttons.baseButton}
  display: inline-block;
`;

export const PrimaryLink = styled(LinkButton)`
  ${buttons.primaryButton}
`;

export const SecondaryLink = styled(LinkButton)`
  ${buttons.secondaryButton}
`;

export const TertiaryLink = styled(LinkButton)`
  ${buttons.tertiaryButton}
`;

const ExternalLinkButton = styled.a`
  ${buttons.baseButton}
  &.new-tab {
    --icon-size: 15px;
    position: relative;
    padding-right: calc(20px + var(--icon-size));

    &:before {
      content: '';
      position: absolute;
      background-image: ${Icons.dataUri.LinkDark};
      background-size: var(--icon-size) var(--icon-size);
      background-repeat: no-repeat;
      height: var(--icon-size);
      width: var(--icon-size);
      right: var(--icon-size);
      top: calc(var(--icon-size) - 2px);
    }
  }
`;

export const PrimaryExternalLink = styled(ExternalLinkButton)`
  ${buttons.primaryButton}

  &.new-tab {
    &:before {
      background-image: ${Icons.dataUri.LinkLight};
    }
  }
`;

export const SecondaryExternalLink = styled(ExternalLinkButton)`
  ${buttons.secondaryButton}
`;

export const TertiaryExternalLink = styled(ExternalLinkButton)`
  ${buttons.tertiaryButton}
`;

const DownloadLinkButton = styled.a`
  ${buttons.baseButton}

  &.icon {
    --icon-size: 18px;
    position: relative;
    padding-right: calc(20px + var(--icon-size));

    &:before {
      content: '';
      position: absolute;
      background-image: ${Icons.dataUri.DownloadDarkLink};
      background-size: var(--icon-size) var(--icon-size);
      background-repeat: no-repeat;
      height: var(--icon-size);
      width: var(--icon-size);
      right: calc(var(--icon-size) - 3px);
      top: calc(var(--icon-size) - 6px);
    }
  }
`;

export const DownloadPrimaryLink = styled(DownloadLinkButton)`
  ${buttons.primaryButton}

  &.icon {
    &:before {
      background-image: ${Icons.dataUri.DownloadLightLink};
    }
  }
`;

export const DownloadSecondaryLink = styled(DownloadLinkButton)`
  ${buttons.secondaryButton}
  &.icon {
    &:before {
      background-image: ${Icons.dataUri.DownloadLightLink};
    }
  }
`;

export const DownloadTertiaryLink = styled(DownloadLinkButton)`
  ${buttons.tertiaryButton}
`;

const DisabledLinkButton = styled.span`
  ${buttons.baseButton}
  display: block;

  &.inline {
    display: inline;
  }
`;

export const DisabledPrimaryLink = styled(DisabledLinkButton)`
  ${buttons.primaryButton}
`;

export const DisabledSecondaryLink = styled(DisabledLinkButton)`
  ${buttons.secondaryButton}
`;

export const DisabledTertiaryLink = styled(DisabledLinkButton)`
  ${buttons.tertiaryButton}
`;
