import styled from 'styled-components/macro';
import Page from '../../Page';
import Form from '../../Form';
import { colors, fonts } from '../../../css';

export const NoDataDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;

export const MetastoreHeader = styled(Page.BoxHeader)`
  display: flex;
  flex-flow: row;
  justify-content: stretch;

  h2 {
    flex: 7 0 auto;
  }

  .logo {
    flex: 0 0 auto;
    border-left: 1px solid ${colors.border};
    padding: 17px;

    img {
      vertical-align: middle;
      width: 40px;
    }
  }

  .integration-name {
    flex: 3 0 auto;
    border-left: 1px solid ${colors.border};
    padding: 14px 30px 14px 16px;

    .title {
      ${fonts.medium_bold}
      letter-spacing: 0.16px;
    }

    .subtitle {
      padding-top: 4px;
      ${fonts.medium}
      letter-spacing: 0.16px;
    }
  }
`;

export const MetastoreDiv = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding: 24px 24px 0;

  > div {
    flex: 1 0 auto;
    padding-right: 24px;

    &:last-child {
      padding-right: 0;
    }
  }
`;

export const SupportDiv = styled.div`
  display: grid;
  grid-template-columns: auto 240px;
  grid-template-rows: repeat(var(--row-count), auto);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  h3 {
    ${fonts.medium_bold};
    margin: 0;
    padding-bottom: 4px;
  }

  > div {
    padding: 24px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background-color: ${colors.section_background};

    ${Form.ButtonWrapperDiv} {
      width: 100%;

      button {
        width: 100%;
      }
    }

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  > div:last-child,
  > div:nth-last-child(2),
  > div:nth-last-child(3),
  > div:nth-last-child(4) {
    border-top: 1px ${colors.border} solid;
  }
`;

export const ClusterDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const PrestoEndpointDiv = styled.div`
  display: flex;
  place-items: baseline;

  > :nth-child(1) {
    flex: auto;
  }

  > a {
    margin-left: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
`;

export const DownloadsDiv = styled.div`
  padding: 10px 0;
  margin-bottom: 10px;
`;

export const TitleBadge = styled.span`
  position: relative;
  bottom: 6px;
  left: 11px;
`;

export const CustomPluginWrapperDiv = styled.div`
  padding-top: 16px;
`;
