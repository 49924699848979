/* eslint-disable import/no-import-module-exports */
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/rootReducer';
import rootSaga from '../sagas/rootSaga';
import telemetry from '../telemetry';
import { isLocalUI, isStagingUI } from '../utils/environment';

const initializeStore = (preloadedState) => {
  const middleware = [];

  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  const telemetryMiddleware = telemetry.getTelemetryMiddleware();
  if ((isLocalUI() || isStagingUI()) && telemetryMiddleware.length === 0) {
    middleware.push(createLogger());
  }
  middleware.push(...telemetryMiddleware);

  // eslint-disable-next-line no-underscore-dangle
  const withDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

  const store = createStore(
    rootReducer,
    preloadedState,
    withDevTools(applyMiddleware(...middleware)),
  );

  sagaMiddleware.run(rootSaga);

  if (process.env.NODE_ENV === 'development' && module.hot) {
    // TODO: replace module.hot with process.webpackHot
    module.hot.accept('../reducers/rootReducer', () => {
      // eslint-disable-next-line global-require
      const newRootReducer = require('../reducers/rootReducer').default;
      store.replaceReducer(newRootReducer);
    });
  }

  return store;
};

export default initializeStore;
