import { arrayOf, bool, string } from 'prop-types';
import { useMemo } from 'react';
import Banner from '../../Banner';
import Page from '../../Page';
import Form from '../../Form';
import { clusterNameRegex } from '../../../utils/regex';
import Help from './PrestoClusterDetailsHelp';
import {
  ClusterSettingsConcurrencyDiv,
  ClusterSettingsConcurrencyDivs,
} from './styledComponents';
import Data from '../../Data';

const display = {
  boxTitle: 'General',
  formContentHeader: 'Cluster Name',
  formExplaination: 'Secondary explanation',
  nameLabel: 'Name',
  nameRequiredError: 'Name is required',
  nameTooLong:
    'The cluster name must begin and end with a letter or number. It can be a maximum of 50 characters long.',
  nameFormatError:
    'This value can only contain letters, numbers, hyphens and spaces. It must begin and end with a letter or number.',
  concurrencyLabel: 'Workload Profile',
  concurrencyRequiredError: 'Concurrency mode is required',
  concurrencyLowValue: 'low',
  concurrencyLowLabel: 'Low Concurrency',
  concurrencyLowDetails:
    'Low concurrency is useful for clusters that run a limited number of queries or a few large, complex queries. It also supports bigger and heavier ETL jobs.',
  concurrencyHighValue: 'high',
  concurrencyHighLabel: 'High Concurrency',
  concurrencyHighDetails:
    'High concurrency is better for running multiple queries at the same time. For example, dashboard and reporting queries or A/B testing analytics, etc.',
  prestoVersionHeader: 'Presto Version',
  prestoVersionLabel: 'Version',
  prestoVersionRequiredError: 'Presto Version is required',
  prestoVersionDisabled: 'Loading...',
  prestoVersionInvalidError:
    'Selected Presto version is no longer available. Please choose a valid version from the list.',
  prestoVersionUnavailable:
    'No versions available at this time, please try again later.',
};

const concurrencyMode = (label, details) => {
  return () => {
    return (
      <ClusterSettingsConcurrencyDiv>
        <div>{label}</div>
        <div className='details'>{details}</div>
      </ClusterSettingsConcurrencyDiv>
    );
  };
};

const concurrencyValues = [
  {
    value: display.concurrencyLowValue,
    label: display.concurrencyLowLabel,
    component: concurrencyMode(
      display.concurrencyLowLabel,
      display.concurrencyLowDetails,
    ),
  },
  {
    value: display.concurrencyHighValue,
    label: display.concurrencyHighLabel,
    component: concurrencyMode(
      display.concurrencyHighLabel,
      display.concurrencyHighDetails,
    ),
  },
];

const PrestoClusterDetails = ({
  error,
  errorVersions,
  disabled,
  prestoVersions,
  prestoVersionsLoading,
}) => {
  const prestoVersionsItems = useMemo(() => {
    if (!prestoVersions || prestoVersions.length === 0) return null;
    return prestoVersions.map((version) => {
      return {
        display: version,
        value: version,
      };
    });
  }, [prestoVersions]);

  const validatePrestoVersion = (selectedVersion) => {
    if (!selectedVersion) return true; // If nothing is selected, no need to validate
    return prestoVersionsItems?.some((item) => {
      return item.value === selectedVersion;
    })
      ? true
      : display.prestoVersionInvalidError;
  };

  const defaultVersion = prestoVersionsItems?.find((item) => {
    return item.value.startsWith('0.282');
  });

  let prestoVersionContent;

  if (prestoVersionsLoading) {
    prestoVersionContent = (
      <Data.DataField
        label={display.prestoVersionLabel}
        value={display.prestoVersionDisabled}
        disabled
        fieldHelp={Help.PrestoVersions}
      />
    );
  } else if (prestoVersionsItems && prestoVersionsItems.length > 0) {
    prestoVersionContent = (
      <Form.SelectInput
        name='prestoVersion'
        label={display.prestoVersionLabel}
        disabled={disabled}
        validationRules={{
          required: display.prestoVersionRequiredError,
          validate: validatePrestoVersion,
        }}
        items={prestoVersionsItems}
        fieldHelp={Help.PrestoVersions}
        defaultValue={defaultVersion ? defaultVersion.value : undefined}
      />
    );
  } else {
    prestoVersionContent = (
      <Data.DataField
        label={display.prestoVersionLabel}
        value={display.prestoVersionUnavailable}
        disabled
        fieldHelp={Help.PrestoVersionsUnavailable}
      />
    );
  }

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      {errorVersions && (
        <Banner title={`Presto Version: ${errorVersions}`} scrollIntoView />
      )}
      {/* {prestoVersions?.errorMessages &&
        prestoVersions?.errorMessages.map((err, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Banner key={index} title={err.errorMessage || err.errorSummary} />
          );
        })} */}
      <Page.FormContent>
        <div>
          <h3>{display.formContentHeader}</h3>
          {/* <div>{display.formExplaination}</div> */}
        </div>
        <div>
          <Form.TextInput
            name='name'
            label={display.nameLabel}
            disabled={disabled}
            validationRules={{
              required: display.nameRequiredError,
              maxLength: { value: 50, message: display.nameTooLong },
              pattern: {
                value: clusterNameRegex,
                message: display.nameFormatError,
              },
            }}
            fieldHelp={Help.Name}
          />
        </div>
        <div>
          <h3>{display.prestoVersionHeader}</h3>
        </div>
        <div>{prestoVersionContent}</div>
        <ClusterSettingsConcurrencyDivs>
          <Form.RadioInputs
            name='concurrency'
            label={display.concurrencyLabel}
            values={concurrencyValues}
            fieldHelp={Help.ConcurrencyMode}
            defaultValue={display.concurrencyLowValue}
          />
        </ClusterSettingsConcurrencyDivs>
      </Page.FormContent>
    </Page.Box>
  );
};

PrestoClusterDetails.defaultProps = {
  disabled: false,
  error: undefined,
  errorVersions: undefined,
  prestoVersions: null,
};

PrestoClusterDetails.propTypes = {
  disabled: bool,
  error: string,
  errorVersions: string,
  prestoVersions: arrayOf(string),
  prestoVersionsLoading: bool.isRequired,
};

export default PrestoClusterDetails;
