import { func, bool, string } from 'prop-types';
import Page from '../../../../Page';
import Form from '../../../../Form';
import Data from '../../../../Data';
import Banner from '../../../../Banner';
import * as Help from '../../CreateComputePlaneHelp';
import * as constants from '../../../constants';

const display = {
  verifyRoleTitle: 'Verify Provisioning Role',
  verifySetupHeader: 'Verify Setup',
  roleCreationLabel: 'Role Creation Method',
  verifyMethodLabels: {
    cloudFormation: 'CloudFormation',
    terraform: 'Terraform',
  },
  verifyRoleBtn: 'Verify Role',
};

export const VerifyRoleCreation = ({
  error,
  onVerifyProvisioningRole,
  verifyRoleInProgress,
}) => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.verifyRoleTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <Page.FormContent rows={3}>
        <div>
          <h3>{display.verifySetupHeader}</h3>
        </div>
        <div>
          <Form.RadioInputs
            name='verify.roleCreationSetup'
            label={display.roleCreationLabel}
            defaultValue={constants.verifyRoleCreationMethodType.cloudFormation}
            fieldHelp={Help.VerifyRoleCreation}
            values={[
              {
                label: display.verifyMethodLabels.cloudFormation,
                value: constants.verifyRoleCreationMethodType.cloudFormation,
              },
              {
                label: display.verifyMethodLabels.terraform,
                value: constants.verifyRoleCreationMethodType.terraform,
              },
            ]}
          />
          <Data.ButtonGroup>
            <Form.TertiaryButton
              label={display.verifyRoleBtn}
              onClick={onVerifyProvisioningRole}
              disabled={verifyRoleInProgress}
              showLoading={verifyRoleInProgress}
              disabledOnErrors={false}
            />
          </Data.ButtonGroup>
        </div>
      </Page.FormContent>
    </Page.Box>
  );
};

VerifyRoleCreation.defaultProps = {
  error: undefined,
};

VerifyRoleCreation.propTypes = {
  error: string,
  verifyRoleInProgress: bool.isRequired,
  onVerifyProvisioningRole: func.isRequired,
};
