const stateSanitizers = new Map();

const registerStateSanitizer = (name, stateSanitizer) => {
  if (stateSanitizers.has(name)) {
    throw new Error(`State sanitizer with name ${name} already registered`);
  }

  stateSanitizers.set(name, stateSanitizer);
};

const sanitizeState = (originalState) => {
  let state = originalState;

  for (const stateSanitizer of stateSanitizers.values()) {
    state = stateSanitizer(state);
    if (!state) {
      return null;
    }
  }

  return state;
};

const module = {
  registerStateSanitizer,
  sanitizeState,
};

export default module;
