import * as actions from './actions';
import * as actionTypes from './actionTypes';
import reducer from './reducer';
import saga from './saga';
import * as selectors from './selectors';
import * as api from './api';

const module = {
  actions,
  api,
  actionTypes,
  reducer,
  saga,
  selectors,
};

export default module;
