import { string } from 'prop-types';
import HiveLogo from '../../../img/logo/metahive.svg';
import S3Logo from '../../../img/logo/s3.svg';
import Data from '../../Data';
import Page from '../../Page';
import { useIsSubscriptionValid } from '../../utils/featureHooks';
import { ClusterDiv } from './styledComponents';

const display = {
  title: 'IBM watsonx.data Managed Catalog and Storage',
  hiveLabel: 'Hive Metastore Catalog Name',
  s3BucketLabel: 'IBM watsonx.data managed S3 bucket',
};

const ClusterStorageInformation = ({ hmsWarehouseDir }) => {
  const isSubscriptionValid = useIsSubscriptionValid();

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      <ClusterDiv>
        <Page.DataContentWithLogo>
          <img src={HiveLogo} alt='Hive' className='logo' />
          <div>
            <Data.DataFieldWithCopy
              label={display.hiveLabel}
              value={isSubscriptionValid ? 'managed_hive' : ''}
              disabled={!isSubscriptionValid}
            />
          </div>
        </Page.DataContentWithLogo>
        <Page.DataContentWithLogo>
          <img src={S3Logo} alt='AWS S3' className='logo' />
          <div>
            <Data.DataFieldWithLink
              label={display.s3BucketLabel}
              value={isSubscriptionValid ? `${hmsWarehouseDir}` : ''}
              url={
                isSubscriptionValid
                  ? `https://s3.console.aws.amazon.com/s3/buckets/${hmsWarehouseDir}`
                  : ''
              }
              disabled={!isSubscriptionValid}
            />
          </div>
        </Page.DataContentWithLogo>
      </ClusterDiv>
    </Page.Box>
  );
};

ClusterStorageInformation.propTypes = {
  hmsWarehouseDir: string.isRequired,
};

export default ClusterStorageInformation;
