import { useMemo } from 'react';
import { bool } from 'prop-types';
import Page from '../../Page';
import PrestoUsers from '../../PrestoUsers';
import AuthorizationServices from '../../AuthorizationServices';
import IdentityProviders from '../../IdentityProviders';

const display = {
  header: 'Security',
  prestoUsersHeader: 'Presto Users',
  identityProviderHeader: 'Identity Provider',
  authorizationServicesHeader: 'Authorization Services',
};

const navTabsBasic = [
  display.prestoUsersHeader,
  display.authorizationServicesHeader,
];

const navTabsWithIdP = [
  display.prestoUsersHeader,
  display.identityProviderHeader,
  display.authorizationServicesHeader,
];

const Security = ({ enableIdentityProvider }) => {
  const navTabs = useMemo(() => {
    return enableIdentityProvider ? navTabsWithIdP : navTabsBasic;
  }, [enableIdentityProvider]);

  return (
    <Page.Page>
      <Page.InPageNavigation navTabs={navTabs}>
        <Page.PageHeaderWithNav>
          <div>
            <h1>{display.header}</h1>
          </div>
          <Page.PageNavigation navTabs={navTabs} />
        </Page.PageHeaderWithNav>
        <Page.Scroll>
          <Page.BoxNavTarget navTarget={display.prestoUsersHeader}>
            <PrestoUsers.Container />
          </Page.BoxNavTarget>
          {enableIdentityProvider && (
            <Page.BoxNavTarget navTarget={display.identityProviderHeader}>
              <IdentityProviders.Container />
            </Page.BoxNavTarget>
          )}
          <Page.BoxNavTarget navTarget={display.authorizationServicesHeader}>
            <AuthorizationServices.Container />
          </Page.BoxNavTarget>
        </Page.Scroll>
      </Page.InPageNavigation>
    </Page.Page>
  );
};

Security.defaultProps = {
  enableIdentityProvider: false,
};

Security.propTypes = {
  enableIdentityProvider: bool,
};

export default Security;
