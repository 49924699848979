import { CostManagement } from './components';
import { connect } from '../../utils/redux';
import * as selectors from './selectors';
import * as actions from './actions';
import { getTenantId } from '../AccountSetup/selectors';

const mapStateToProps = (state) => {
  return {
    filterMonths: selectors.getFilterMonths(state),
    tenant: selectors.getTenant(state),
    tenantId: getTenantId(state),
    clusters: selectors.getClusters(state),
    contract: selectors.getContract(state),
    totalTenantCredits: selectors.getTotalTenantCredits(state),
    subscriptionSummaryIsLoading:
      selectors.getSubscriptionSummaryIsLoading(state),
    usageDetailsIsLoading: selectors.getUsageDetailsIsLoading(state),
    error: selectors.getError(state),
  };
};

export default connect(mapStateToProps, {
  loadTenantSummary: actions.getTenantSummary,
  loadClusters: actions.getClusters,
  loadContract: actions.getContract,
})(CostManagement);
