import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';

const baseResource = 'authorizationservices';

export const post = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({ baseUrl: rootUri, path: baseResource });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePOST(uri, headers, prepedBody, abortFetchSignal);
};
