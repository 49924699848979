import styled from 'styled-components/macro';
import { colors } from '../../../css';

export const StatusDiv = styled.div`
  margin-left: 20px;
  border-radius: 50%;
  height: 30px;

  svg {
    height: 24px;
    fill: ${colors.warning};
  }
`;

export const NoDataDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;
