import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const submit = (dataSourceId, type, values) => {
  const body = {
    name: values.name,
    description: values.description,
    type,
    connection: values[type],
    prestoUserMappings: values.prestoUserMappings,
    authorizationServiceId:
      values.authorizationServiceId !== ''
        ? values.authorizationServiceId
        : undefined,
  };

  return {
    type: actionTypes.update,
    payload: {
      dataSourceId,
      body,
    },
  };
};

export const put = (dataSourceId, body) => {
  return createApiAction(
    actionTypes.put,
    api.put,
    true,
    { dataSourceId },
    body,
  );
};
