import { shape, string, bool, oneOfType, arrayOf } from 'prop-types';

export const HiveConnection = {
  connector: shape({
    name: string,
  }),
  hive: shape({
    metastore: shape({
      uri: string.isRequired,
    }).isRequired,
    s3: shape({
      'aws-access-key': string,
      'aws-secret-key': string,
      'iam-role': string,
    }).isRequired,
  }),
};

export const GlueConnection = {
  connector: shape({
    name: string,
  }),
  hive: shape({
    metastore: string,
    'metastore.glue': shape({
      region: string,
    }),
    s3: shape({
      'aws-access-key': string,
      'aws-secret-key': string,
      'iam-role': string,
    }).isRequired,
  }),
};

export const GlueWithLakeFormationConnection = {
  connector: shape({
    name: string,
  }),
  hive: shape({
    metastore: string,
    'metastore.glue': shape({
      region: string,
    }),
    security: string,
  }),
};

export const MySqlConnection = {
  connector: shape({
    string,
  }),
  'connection-url': string.isRequired,
  'connection-user': string.isRequired,
  'connection-password': string.isRequired,
};

export const PostgresConnection = {
  connector: shape({
    name: string,
  }),
  'connection-user': string.isRequired,
  'connection-password': string.isRequired,
  jdbc: string.isRequired,
  database: string.isRequired,
};

export const IBMDb2Connection = {
  connector: shape({
    name: string,
  }),
  'connection-user': string.isRequired,
  'connection-password': string.isRequired,
  jdbc: string.isRequired,
  database: string.isRequired,
  sslConnection: bool.isRequired,
};

export const RedshiftConnection = {
  connector: shape({
    name: string,
  }),
  'connection-user': string.isRequired,
  'connection-password': string.isRequired,
  endpoint: string.isRequired,
  connectionParameters: string,
};

export const ElasticsearchConnection = {
  tls: shape({
    enabled: bool,
  }),
  aws: shape({
    region: string.isRequired,
    'access-key': string,
    'secret-key': string,
  }).isRequired,
};

export const PrestoUserMapping = shape({
  prestoUserId: string.isRequired,
  username: string.isRequired,
  configuration: shape({
    iamRoleMapping: string.isRequired,
  }),
});

export const PrestoUserMappings = arrayOf(PrestoUserMapping);

export const DataSource = shape({
  name: string.isRequired,
  catalogName: string.isRequired,
  description: string,
  isAttached: bool.isRequired,
  connection: oneOfType([
    shape(HiveConnection),
    shape(GlueConnection),
    shape(GlueWithLakeFormationConnection),
    shape(MySqlConnection),
    shape(PostgresConnection),
    shape(IBMDb2Connection),
    shape(RedshiftConnection),
    shape(ElasticsearchConnection),
  ]).isRequired,
  tenantId: string.isRequired,
  dataSourceId: string.isRequired,
  type: string.isRequired,
  prestoUserMappings: PrestoUserMappings,
  createDate: string.isRequired,
  updateDate: string.isRequired,
});
