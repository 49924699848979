import { useCallback } from 'react';
import { node, string, bool } from 'prop-types';
import { useInPageNavigationDispatch } from './InPageNavigation';
import { BoxNavTargetDiv, BoxNavTargetSpan } from './styledComponents';

const BoxNavTarget = ({ navTarget, error, children }) => {
  const dispatch = useInPageNavigationDispatch();
  const boxRef = useCallback(
    (n) => {
      dispatch({ type: 'setNavRef', payload: { navTarget, navRef: n } });
    },
    [navTarget, dispatch],
  );
  return (
    <>
      <BoxNavTargetSpan />
      <BoxNavTargetDiv
        className={error ? 'error' : undefined}
        ref={boxRef}
        data-nav-target={navTarget}
      >
        {children}
      </BoxNavTargetDiv>
    </>
  );
};

BoxNavTarget.defaultProps = {
  navTarget: null,
  error: false,
};

BoxNavTarget.propTypes = {
  navTarget: string,
  error: bool,
  children: node.isRequired,
};

export default BoxNavTarget;
