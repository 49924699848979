import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool } from 'prop-types';
import Page from '../../Page';
import Form from '../../Form';
import Help from './RangerHiveSSLDetailsHelp';

const display = {
  boxTitle: 'SSL Details',
  formContentHeader: 'SSL Configuration',
  keystoreFileLabel: 'Keystore File',
  keystorePasswordLabel: 'Keystore Password',
  truststoreFileLabel: 'Truststore File',
  truststorePasswordLabel: 'Truststore Password',
  keystorePasswordRequiredError:
    'A keystore password is required with a keystore file',
  keystoreFileRequiredError:
    'A keystore password requires a corresponding file',
  truststorePasswordRequiredError:
    'A truststore password is required with a truststore file',
  truststoreFileRequiredError:
    'A truststore password requires a corresponding file',
};

const RangerHiveSSLDetails = ({ disabled }) => {
  const { getValues, trigger, watch } = useFormContext();
  const keystoreFile = watch('rangerhive.keystore');
  const truststoreFile = watch('rangerhive.truststore');

  const keystoreFileValidation = useCallback(() => {
    trigger('rangerhive.keystorepassword');
    return true;
  }, [trigger]);

  const keystorePasswordValidation = useCallback(
    (value) => {
      const values = getValues();
      const { keystore } = values.rangerhive;
      if (keystore && !value) {
        return display.keystorePasswordRequiredError;
      }
      if (!keystore && value) {
        return display.keystoreFileRequiredError;
      }
      return true;
    },
    [getValues],
  );

  const truststoreFileValidation = useCallback(() => {
    trigger('rangerhive.truststorepassword');
    return true;
  }, [trigger]);

  const truststorePasswordValidation = useCallback(
    (value) => {
      const values = getValues();
      const { truststore } = values.rangerhive;
      if (truststore && !value) {
        return display.truststorePasswordRequiredError;
      }
      if (!truststore && value) {
        return display.truststoreFileRequiredError;
      }
      return true;
    },
    [getValues],
  );

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      <Page.FormContent>
        <>
          <div>
            <h3>{display.formContentHeader}</h3>
          </div>
          <div>
            <Form.FileInput
              name='rangerhive.keystore'
              label={display.keystoreFileLabel}
              optional
              disabled={disabled}
              binary
              base64Encode
              validationRules={{
                validate: keystoreFileValidation,
              }}
              fieldHelp={Help.KeystoreFile}
            />
            <Form.PasswordInput
              name='rangerhive.keystorepassword'
              optional={!keystoreFile}
              label={display.keystorePasswordLabel}
              disabled={disabled}
              validationRules={{
                validate: keystorePasswordValidation,
              }}
              fieldHelp={Help.KeystorePassword}
            />
            <Form.FileInput
              name='rangerhive.truststore'
              label={display.truststoreFileLabel}
              disabled={disabled}
              optional
              binary
              base64Encode
              validationRules={{
                validate: truststoreFileValidation,
              }}
              fieldHelp={Help.TruststoreFile}
            />
            <Form.PasswordInput
              name='rangerhive.truststorepassword'
              optional={!truststoreFile}
              disabled={disabled}
              label={display.truststorePasswordLabel}
              validationRules={{
                validate: truststorePasswordValidation,
              }}
              fieldHelp={Help.TruststorePassword}
            />
          </div>
        </>
      </Page.FormContent>
    </Page.Box>
  );
};

RangerHiveSSLDetails.defaultProps = {
  disabled: false,
};

RangerHiveSSLDetails.propTypes = {
  disabled: bool,
};

export default RangerHiveSSLDetails;
