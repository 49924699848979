export const getSubmitInProgress = (state) => {
  return state.identityProviderUpdate.submitInProgress;
};

export const getSubmitSuccess = (state) => {
  return state.identityProviderUpdate.submitSuccess;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getError = (state) => {
  return state.identityProviderUpdate.error;
};

export const getIdentityProviderId = (state, props) => {
  return props.params.identityProviderId;
};
