import { node } from 'prop-types';
import { WarningBadgeSpan } from './styledComponents';

const WarningBadge = ({ children }) => {
  return <WarningBadgeSpan>{children || 'Warning'}</WarningBadgeSpan>;
};

WarningBadge.defaultProps = {
  children: null,
};

WarningBadge.propTypes = {
  children: node,
};

export default WarningBadge;
