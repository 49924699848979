import { bool, string } from 'prop-types';
import DataSources from '../../DataSources';
import Banner from '../../Banner';
import Page from '../../Page';
import Form from '../../Form';
import Help from './DataSourceDetailsHelp';

const display = {
  boxTitle: 'Details',
  formContentHeader: 'General',
  formExplaination: 'Secondary explanation',
  nameLabel: 'Name',
  nameRequiredError: 'Name is required',
  nameTooLong: 'This value can be a max of 100 characters',
  nameIllegalCharacters:
    'This value can only contain lower-case letters, numbers, and underscores.',
  descriptionLabel: 'Description',
};

const DataSourceDetails = ({ error, disabled, dataSource }) => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <Page.FormContent>
        <div>
          <h3>{display.formContentHeader}</h3>
          {/* <div>{display.formExplaination}</div> */}
        </div>
        <div>
          <Form.TextInput
            name='name'
            label={display.nameLabel}
            disabled={disabled}
            validationRules={{
              required: display.nameRequiredError,
              maxLength: { value: 100, message: display.nameTooLong },
              pattern: {
                value: /^[a-z0-9_]+$/,
                message: display.nameIllegalCharacters,
              },
            }}
            fieldHelp={Help.Name}
            defaultValue={(dataSource && dataSource.name) || undefined}
          />
          <Form.TextInput
            name='description'
            label={display.descriptionLabel}
            disabled={disabled}
            optional
            defaultValue={(dataSource && dataSource.description) || undefined}
          />
        </div>
      </Page.FormContent>
    </Page.Box>
  );
};

DataSourceDetails.defaultProps = {
  disabled: false,
  error: undefined,
  dataSource: null,
};

DataSourceDetails.propTypes = {
  disabled: bool,
  error: string,
  dataSource: DataSources.propTypes.DataSource,
};

export default DataSourceDetails;
