import { string, shape, arrayOf } from 'prop-types';

export const awsRegion = shape({
  region: string.isRequired,
  regionName: string.isRequired,
  availabilityZones: arrayOf(string).isRequired,
});

export const awsRegions = arrayOf(awsRegion);

export const errorMessage = shape({
  errorMessage: string.isRequired,
  errorCode: string.isRequired,
  errorSummary: string.isRequired,
  cloudformationUrl: string.isRequired,
});

export const configuration = shape({
  cloudFormationTemplateQuickCreateUrl: string,
  cloudFormationTemplateContent: string,
  cloudFormationTemplateS3Url: string,
  infrastructurePolicyContent: string,
  operationsPolicyContent: string,
  permissionBoundaryPolicyContent: string,
  trustRelationShipPolicyContent: string,
});
