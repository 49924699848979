/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import { string, object, bool, arrayOf, shape } from 'prop-types';
import Page from '../../Page';
import Banner from '../../Banner';
import Data from '../../Data';
import DataSources from '../../DataSources';
import ConnectionDetailsHeader from './ConnectionDetailsHeader';
import ElasticsearchConnectionDetails from './ElasticsearchConnectionDetails';
import GlueConnectionDetails from './GlueConnectionDetails';
import HiveConnectionDetails from './HiveConnectionDetails';
import MySqlConnectionDetails from './MySqlConnectionDetails';
import PostgresConnectionDetails from './PostgresConnectionDetails';
import IBMDb2ConnectionDetails from './IBMDb2ConnectionDetails';
import RedshiftConnectionDetails from './RedshiftConnectionDetails';

const display = {
  boxTitle: 'Details',
  connectorTypeHeader: 'Connector Type',
  descriptionHeader: 'Info',
  catalogNameLabel: 'Catalog Name',
  descriptionLabel: 'Description',
  authorizationHeader: 'Authorization',
  authorizationLabel: 'Authorization Service',
};

const ConnectionDetails = ({
  dataSourceId,
  dataSourceType,
  dataSource,
  connection,
  metadata,
  catalogName,
  description,
  navTarget,
  canModify,
}) => {
  const Logo = useMemo(() => {
    return DataSources.constants.dataSourceTypeLogos[dataSourceType];
  }, [dataSourceType]);

  const showStatusBanner =
    DataSources.constants.status.secret_failure === dataSource.status ||
    DataSources.constants.status.delete_failure === dataSource.status ||
    DataSources.constants.status.secret_updating === dataSource.status ||
    DataSources.constants.status.secret_deleting === dataSource.status;

  const isSecretUpdating =
    DataSources.constants.status.secret_updating === dataSource.status ||
    DataSources.constants.status.secret_deleting === dataSource.status;

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <ConnectionDetailsHeader
        title={display.boxTitle}
        dataSourceId={dataSourceId}
        dataSource={dataSource}
        canModify={canModify && !isSecretUpdating}
      />
      {showStatusBanner && (
        <Page.InformationBanner>
          <Banner
            scrollIntoView
            isWarning={isSecretUpdating}
            title={
              DataSources.constants.statusMessages[dataSource.status]?.title
            }
          >
            <p key='desc'>
              {
                DataSources.constants.statusMessages[dataSource.status]
                  ?.description
              }
            </p>
            {dataSource?.errorMessages?.map((m) => {
              if (typeof m === 'string') {
                return <p key={m}>{m}</p>;
              }
              return <p key={m}>{m.errorSummary || m.errorMessage}</p>;
            })}
          </Banner>
        </Page.InformationBanner>
      )}
      <Page.FormContent rows={2}>
        <div>
          <h3>{display.connectorTypeHeader}</h3>
        </div>
        <div>
          <Data.DataFieldWithLogo logo={Logo} />
        </div>
        <div>
          <h3>{display.descriptionHeader}</h3>
        </div>
        <div>
          <Data.DataField
            label={display.catalogNameLabel}
            value={catalogName}
          />
          {description && (
            <Data.DataField
              label={display.descriptionLabel}
              value={description}
            />
          )}
        </div>
        {dataSourceType ===
          DataSources.constants.dataSourceTypes.elasticsearch && (
          <ElasticsearchConnectionDetails {...connection} {...metadata} />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.glue && (
          <GlueConnectionDetails {...connection} {...metadata} />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.hive && (
          <HiveConnectionDetails {...connection} {...metadata} />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.mysql && (
          <MySqlConnectionDetails {...connection} {...metadata} />
        )}
        {dataSourceType ===
          DataSources.constants.dataSourceTypes.postgresql && (
          <PostgresConnectionDetails {...connection} {...metadata} />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.db2 && (
          <IBMDb2ConnectionDetails {...connection} {...metadata} />
        )}
        {dataSourceType === DataSources.constants.dataSourceTypes.redshift && (
          <RedshiftConnectionDetails
            {...connection}
            {...metadata}
            catalogName={catalogName}
            description={description}
          />
        )}
        {dataSource.authorizationServiceName && (
          <>
            <div>
              <h3>{display.authorizationHeader}</h3>
            </div>
            <div>
              <Data.DataField
                label={display.authorizationLabel}
                value={dataSource.authorizationServiceName}
              />
            </div>
          </>
        )}
      </Page.FormContent>
    </Page.BoxNavTarget>
  );
};

ConnectionDetails.defaultProps = {
  dataSource: null,
  description: undefined,
  canModify: true,
  errorMessages: undefined,
};

ConnectionDetails.propTypes = {
  dataSourceId: string.isRequired,
  dataSourceType: string.isRequired,
  dataSource: DataSources.propTypes.DataSource,
  // eslint-disable-next-line react/forbid-prop-types
  connection: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: object.isRequired,
  catalogName: string.isRequired,
  description: string,
  navTarget: string.isRequired,
  canModify: bool,
  errorMessages: arrayOf(
    shape({
      errorSummary: string,
      errorMessage: string,
    }),
  ),
};

export default ConnectionDetails;
