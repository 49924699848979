import Alert from './Alert';
import Link from './Link';
import convertSvgComponentToDataUri from './convertSvgComponentToDataUri';
import { colors } from '../css';
import Download from './Download';
import Progress from './Progress';

const module = {
  Alert,
  Link,
  Progress,
  dataUri: {
    Alert: convertSvgComponentToDataUri(Alert),
    LinkDark: convertSvgComponentToDataUri(Link, {
      fillColor: colors.tertiary_button_font,
    }),
    LinkLight: convertSvgComponentToDataUri(Link, {
      fillColor: colors.primary_button_font,
    }),
    DownloadDarkLink: convertSvgComponentToDataUri(Download, {
      fillColor: colors.tertiary_button_font,
    }),
    DownloadLightLink: convertSvgComponentToDataUri(Download, {
      fillColor: colors.primary_button_font,
    }),
  },
};

export default module;
