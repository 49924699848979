const componentName = 'COMPUTE_PLANE_ADD';

// Used by the new create compute plane wizard
export const updateComputePlane = `${componentName}/UPDATE_COMPUTE_PLANE`;
export const updateComputePlaneSuccess = `${componentName}/UPDATE_COMPUTE_PLANE_SUCCESS`;
export const updateComputePlaneError = `${componentName}/UPDATE_COMPUTE_PLANE_ERROR`;
export const requestDeploy = `${componentName}/DEPLOY`;
export const requestDestroy = `${componentName}/DESTROY`;
export const resetHasVerifyRoleSuccess = `${componentName}/RESET_HAS_VERIFY_ROLE_SUCCESS`;
export const setSubmitInProgress = `${componentName}/SET_SUBMIT_IN_PROGRESS`;
