import { useEffect, useState, useCallback, useMemo } from 'react';
import { bool, arrayOf, func, string, shape, number } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Form from '../../Form';
import Page from '../../Page';
import DataSources from '../../DataSources';
import PrestoClusters from '../../PrestoClusters';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import useRouteBuilder from '../../../router/useRouteBuilder';
import AttachDataSourcesForm from './AttachDataSourcesForm';

const AttachDataSources = ({
  prestoClusterId,
  prestoCluster,
  notFound,
  actionStatus,
  usePop,
  prestoClusterDataSourceIds,
  dataSources,
  isCommunityEditionCluster,
  loadPrestoCluster,
  loadPrestoClusterDataSources,
  loadDataSources,
  saveDataSources,
}) => {
  useEffect(() => {
    loadPrestoCluster(prestoClusterId);
    loadPrestoClusterDataSources(prestoClusterId);
    loadDataSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoClusterId]);

  const [actionInProgress, setActionInProgress] = useState(false);

  const save = useCallback(
    (values) => {
      const selectedIds = Object.keys(values).reduce((acc, key) => {
        if (values[key]) {
          acc.push(key);
        }
        return acc;
      }, []);
      const submitId = Date.now();
      setActionInProgress(submitId);
      return saveDataSources(prestoClusterId, selectedIds, submitId);
    },
    [saveDataSources, prestoClusterId],
  );

  const submitSuccess = useMemo(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    ) {
      return actionStatus.success;
    }
    return false;
  }, [actionStatus, actionInProgress]);

  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (
    !prestoCluster ||
    !prestoClusterDataSourceIds ||
    !dataSources ||
    notFound
  ) {
    return <Page.Loading />;
  }

  return (
    <ActionInProgressStateContext.Provider value={actionInProgress}>
      <ActionInProgressSetStateContext.Provider value={setActionInProgress}>
        <Page.PageWithOverflow>
          <Form.Form name='attach-data-sources' submitAction={save}>
            <AttachDataSourcesForm
              prestoCluster={prestoCluster}
              prestoClusterId={prestoClusterId}
              actionStatus={actionStatus}
              prestoClusterDataSourceIds={prestoClusterDataSourceIds}
              dataSources={dataSources}
              isCommunityEditionCluster={isCommunityEditionCluster}
              usePop={usePop}
              submitSuccess={submitSuccess}
            />
          </Form.Form>
        </Page.PageWithOverflow>
      </ActionInProgressSetStateContext.Provider>
    </ActionInProgressStateContext.Provider>
  );
};

AttachDataSources.defaultProps = {
  prestoCluster: null,
  prestoClusterDataSourceIds: null,
  isCommunityEditionCluster: true,
  dataSources: null,
  usePop: true,
  notFound: false,
  actionStatus: undefined,
};

AttachDataSources.propTypes = {
  prestoClusterId: string.isRequired,
  prestoCluster: PrestoClusters.propTypes.PrestoCluster,
  notFound: bool,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  usePop: bool,
  prestoClusterDataSourceIds: arrayOf(string),
  dataSources: arrayOf(DataSources.propTypes.DataSource),
  isCommunityEditionCluster: bool,
  loadPrestoCluster: func.isRequired,
  loadPrestoClusterDataSources: func.isRequired,
  loadDataSources: func.isRequired,
  saveDataSources: func.isRequired,
};

export default AttachDataSources;
