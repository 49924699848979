import { useCallback, useState, useLayoutEffect, useMemo, useRef } from 'react';
import get from 'lodash.get';
import { useFormContext } from 'react-hook-form';
import {
  string,
  bool,
  object,
  number,
  oneOfType,
  elementType,
} from 'prop-types';
import VisibleIcon from '../../icons/Visible';
import NotVisibleIcon from '../../icons/NotVisible';
import { onAccessibleClick } from '../utils/accessibility';
import Label from './Label';
import {
  InputErrorDiv,
  PasswordInput,
  HorizontalInputDiv,
  PasswordWrapper,
} from './styledComponents';

const InnerCompletePasswordInput = ({
  name,
  label,
  disabled,
  optional,
  defaultValue,
  validationRules,
  autoComplete,
  fieldHelp,
  hidden,
}) => {
  const passwordRef = useRef(null);
  const [inputType, setInputType] = useState('text');
  const [viewPassword, setViewPassword] = useState(false);
  const toggleViewPassword = useCallback(
    (e) => {
      return onAccessibleClick(e, () => {
        setViewPassword(!viewPassword);
      });
    },
    [viewPassword],
  );

  const {
    register: formRegister,
    formState: { errors: formErrors },
    getFieldId,
  } = useFormContext();
  const inputId = getFieldId(name);
  const { ref: passwordFieldRef, ...formRegisterProps } = formRegister(
    name,
    validationRules,
  );

  useLayoutEffect(() => {
    if (passwordFieldRef.current && !viewPassword) {
      const style = window.getComputedStyle(passwordFieldRef.current);
      if (!style.webkitTextSecurity && !style['font-family']) {
        setInputType('password');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordFieldRef]);

  const error = get(formErrors, name);

  const wrapperClassName = useMemo(() => {
    if (!error && !hidden) {
      return undefined;
    }

    let c = '';
    if (error) {
      c = `${c} error`;
    }
    if (hidden) {
      c = `${c} hidden`;
    }
    return c;
  }, [error, hidden]);

  return (
    <>
      {!hidden && (
        <Label
          inputId={inputId}
          label={label}
          optional={optional}
          fieldHelp={fieldHelp}
        />
      )}
      <PasswordWrapper className={wrapperClassName}>
        <PasswordInput
          type={viewPassword ? 'text' : inputType}
          className={!viewPassword ? 'hide' : undefined}
          disabled={disabled}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          id={inputId}
          data-private
          aria-invalid={error ? 'true' : 'false'}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formRegisterProps}
          ref={(e) => {
            passwordFieldRef(e);
            passwordRef.current = e;
          }}
        />
        <button onClick={toggleViewPassword} type='button' tabIndex={-1}>
          {viewPassword ? <VisibleIcon /> : <NotVisibleIcon />}
        </button>
      </PasswordWrapper>
      {error && !hidden && (
        <InputErrorDiv role='alert'>{error.message}</InputErrorDiv>
      )}
    </>
  );
};

InnerCompletePasswordInput.defaultProps = {
  disabled: false,
  optional: false,
  defaultValue: undefined,
  validationRules: undefined,
  autoComplete: 'off',
  fieldHelp: undefined,
  hidden: false,
};

InnerCompletePasswordInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  optional: bool,
  defaultValue: oneOfType([string, number]),
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  autoComplete: string,
  fieldHelp: elementType,
  hidden: bool,
};

const CompletePasswordInput = ({ horizontal, ...props }) => {
  if (horizontal) {
    return (
      <HorizontalInputDiv>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <InnerCompletePasswordInput {...props} />
      </HorizontalInputDiv>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <InnerCompletePasswordInput {...props} />;
};

CompletePasswordInput.defaultProps = {
  disabled: false,
  optional: false,
  defaultValue: undefined,
  validationRules: undefined,
  autoComplete: 'off',
  horizontal: false,
  hidden: false,
};

CompletePasswordInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  optional: bool,
  defaultValue: oneOfType([string, number]),
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  autoComplete: string,
  horizontal: bool,
  hidden: bool,
};

export default CompletePasswordInput;
