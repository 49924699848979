import { useMemo } from 'react';
import { arrayOf, bool, number, string } from 'prop-types';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import { DropDownValue } from '../propTypes';
import Help from './ClusterSettingsHelp';
import AutoScaling from './AutoScaling';
import { isDevUI } from '../../../utils/environment';

const availabilityZoneAutomatic = 'Automatic';

const display = {
  boxTitle: 'Cluster Settings',
  formContentHeader: 'Node Instance Types',
  formExplaination: 'Secondary explanation',
  workerNodesLabel: 'Worker Node Count',
  workerNodesRequiredError: 'Worker node count is required',
  workerNodesRangeError: 'Worker node count must be between 1 and ',
  instanceTypeLabel: 'Worker Node Instance Type',
  enableSpotLabel: 'Enable Spot Worker Nodes',
  instanceTypeRequiredError: 'Worker node instance type is required',
  prestoCoordinatorInstanceTypeLabel: 'Coordinator Instance Type',
  prestoCoordinatorInstanceTypeRequiredError:
    'Coordinator instance type is required',
  availabilityZoneLabel: 'Availability Zone',
};

const ClusterSettings = ({
  enableEnableSpot,
  error,
  disabled,
  maxWorkerNodeCount,
  instanceTypeValues,
  coordinatorInstanceTypeValues,
  awsAZSelect,
}) => {
  const isCommunityEditionTenant = useIsCommunityEditionTenant();

  const awsAZSelectItems = useMemo(() => {
    if (!awsAZSelect) return null;
    const azSelectInput = awsAZSelect.map((az) => {
      return {
        display: az,
        value: az,
      };
    });

    azSelectInput.unshift({
      display: availabilityZoneAutomatic,
      value: availabilityZoneAutomatic,
    });

    return azSelectInput;
  }, [awsAZSelect]);

  const defaultPrestoCoordinatorInstanceType =
    isCommunityEditionTenant || isDevUI() ? 'm5.large' : 'r5.4xlarge';
  const defaultInstanceType =
    isCommunityEditionTenant || isDevUI() ? 'm5.large' : 'r5.2xlarge';

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <Page.FormContent>
        <div>
          <h3>{display.formContentHeader}</h3>
          {/* <div>{display.formExplaination}</div> */}
        </div>
        <div>
          <Form.SelectInput
            name='prestoCoordinatorInstanceType'
            label={display.prestoCoordinatorInstanceTypeLabel}
            disabled={disabled}
            defaultValue={defaultPrestoCoordinatorInstanceType}
            items={coordinatorInstanceTypeValues}
            validationRules={{
              required: display.prestoCoordinatorInstanceTypeRequiredError,
            }}
            fieldHelp={Help.PrestoCoordinatorAWSInstanceType(
              isCommunityEditionTenant,
            )}
          />
          <Form.SelectInput
            name='instanceType'
            label={display.instanceTypeLabel}
            disabled={disabled}
            defaultValue={defaultInstanceType}
            items={instanceTypeValues}
            validationRules={{
              required: display.instanceTypeRequiredError,
            }}
            fieldHelp={Help.AWSInstanceType(isCommunityEditionTenant)}
          />
          {enableEnableSpot && (
            <Form.CheckboxInput
              name='enableSpot'
              label={display.enableSpotLabel}
              fieldHelp={Help.EnableSpot(isCommunityEditionTenant)}
              disabled={disabled}
            />
          )}
          {awsAZSelectItems && (
            <Form.SelectInput
              name='availabilityZones'
              label={display.availabilityZoneLabel}
              defaultValue={availabilityZoneAutomatic}
              items={awsAZSelectItems}
              fieldHelp={Help.AWSAvailabilityZone}
            />
          )}
        </div>
        <AutoScaling
          error={error}
          disabled={disabled}
          maxWorkerNodeCount={maxWorkerNodeCount}
        />
      </Page.FormContent>
    </Page.Box>
  );
};

ClusterSettings.defaultProps = {
  disabled: false,
  error: undefined,
  enableEnableSpot: false,
  awsAZSelect: null,
};

ClusterSettings.propTypes = {
  disabled: bool,
  error: string,
  enableEnableSpot: bool,
  maxWorkerNodeCount: number.isRequired,
  instanceTypeValues: arrayOf(DropDownValue).isRequired,
  coordinatorInstanceTypeValues: arrayOf(DropDownValue).isRequired,
  awsAZSelect: arrayOf(string),
};

export default ClusterSettings;
