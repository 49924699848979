import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { arrayOf, bool, string } from 'prop-types';
import Page from '../../Page';
import Form from '../../Form';
import Data from '../../Data';
import Banner from '../../Banner';
import { dInstanceTypeRegex } from '../../../utils/regex';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import { DropDownValue } from '../propTypes';
import Help from './DataLakeSettingsHelp';

const display = {
  boxTitle: 'Data Lake Settings',
  dataLakeCachingHeader: 'Data Lake Caching',
  enableDataIOCachingLabel: 'Enable Data IO Cache',
  enableFragmentResultCache: 'Enable Intermediate Result Set Cache',
  hiveMetastoreHeader: 'Hive Metastore',
  ahanaManagedHMSLabel: 'Attach an IBM watsonx.data Hive Metastore',
  hmsInstanceTypeLabel: 'Hive Metastore Instance Type',
  hmsInstanceTypeRequiredError: 'Hive Metastore AWS instance type is required',
  attachQueryLogTableLabel: 'Enable cluster query log',
  prestoCPPLabel: 'Provision Presto C++ Worker Nodes',
  prestoCPPHeader: 'Presto C++',
};

const DataLakeSettings = ({
  hmsInstanceTypeValues,
  error,
  disabled,
  prestoCPPEnabled,
}) => {
  const { watch, setValue } = useFormContext();
  const createHms = watch('ahanaManagedHms', true);
  const isCommunityEditionTenant = useIsCommunityEditionTenant();

  const instanceType = watch('instanceType');
  const dWorkerInstanceTypeSelected = useMemo(() => {
    return dInstanceTypeRegex.test(instanceType);
  }, [instanceType]);

  const prestoCPPSelected = watch('prestoCPP');

  const prestoVersionSelected = watch('prestoVersion');
  const versionTooLow = useMemo(() => {
    if (prestoVersionSelected) {
      const versionMatch = prestoVersionSelected.match(/^\d+\.\d+/);
      if (versionMatch) {
        const version = versionMatch[0];
        return parseFloat(version) < 0.286;
      }
    }
    return true;
  }, [prestoVersionSelected]);

  // Ensure checkbox is deselected when version is too low
  useEffect(() => {
    if (versionTooLow) {
      setValue('prestoCPP', false); // Deselect the checkbox
    }
  }, [versionTooLow, setValue]);

  useEffect(() => {
    setValue('attachQueryLogTable', false);
  }, [setValue, prestoCPPSelected]);

  useEffect(() => {
    if (prestoCPPSelected) {
      setValue('enableFragmentResultCache', false);
    } else {
      setValue('enableFragmentResultCache', dWorkerInstanceTypeSelected);
    }
  }, [setValue, dWorkerInstanceTypeSelected, prestoCPPSelected]);

  useEffect(() => {
    if (prestoCPPSelected) {
      setValue('enableDataCache', false);
    } else {
      setValue('enableDataCache', dWorkerInstanceTypeSelected);
    }
  }, [setValue, dWorkerInstanceTypeSelected, prestoCPPSelected]);

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <Page.FormContent>
        {prestoCPPEnabled && (
          <>
            <div>
              <h3>{display.prestoCPPHeader}</h3>
            </div>
            <div>
              <Form.CheckboxInput
                name='prestoCPP'
                label={display.prestoCPPLabel}
                fieldHelp={Help.prestoCPP}
                disabled={versionTooLow}
              />
            </div>
          </>
        )}
        <div>
          <h3>{display.hiveMetastoreHeader}</h3>
        </div>
        <div>
          <Form.CheckboxInput
            name='ahanaManagedHms'
            label={display.ahanaManagedHMSLabel}
            fieldHelp={Help.AhanaManagedHMS}
            defaultChecked
          />
          {!createHms && (
            <>
              <Data.DataField
                label={display.hmsInstanceTypeLabel}
                disabled
                fieldHelp={Help.HMSInstanceType(isCommunityEditionTenant)}
              />
              <Form.CheckboxInput
                name='attachQueryLogTablePlaceholder'
                label={display.attachQueryLogTableLabel}
                disabled /* always disabled, regardless of Presto C++ status, because of no HMS */
                fieldHelp={Help.AttachQueryLogTable}
              />
            </>
          )}
          {createHms && (
            <>
              <Form.SelectInput
                name='hmsInstanceType'
                label={display.hmsInstanceTypeLabel}
                disabled={disabled}
                defaultValue='m5.xlarge'
                items={hmsInstanceTypeValues}
                validationRules={{
                  required: display.hmsInstanceTypeRequiredError,
                }}
                fieldHelp={Help.HMSInstanceType(isCommunityEditionTenant)}
              />
              <Form.CheckboxInput
                name='attachQueryLogTable'
                label={display.attachQueryLogTableLabel}
                disabled={disabled || prestoCPPSelected}
                // defaultChecked
                fieldHelp={Help.AttachQueryLogTable}
              />
            </>
          )}
        </div>
        <div>
          <h3>{display.dataLakeCachingHeader}</h3>
        </div>
        <div>
          <Form.CheckboxInput
            name='enableDataCache'
            label={display.enableDataIOCachingLabel}
            fieldHelp={Help.DataLakeIOCaching(isCommunityEditionTenant)}
            disabled={
              dWorkerInstanceTypeSelected ||
              isCommunityEditionTenant ||
              prestoCPPSelected
            }
          />
          <Form.CheckboxInput
            name='enableFragmentResultCache'
            label={display.enableFragmentResultCache}
            fieldHelp={Help.FragmentResultCaching(isCommunityEditionTenant)}
            disabled={
              dWorkerInstanceTypeSelected ||
              isCommunityEditionTenant ||
              prestoCPPSelected
            }
          />
        </div>
      </Page.FormContent>
    </Page.Box>
  );
};

DataLakeSettings.defaultProps = {
  disabled: false,
  error: undefined,
};

DataLakeSettings.propTypes = {
  disabled: bool,
  error: string,
  hmsInstanceTypeValues: arrayOf(DropDownValue).isRequired,
  prestoCPPEnabled: bool.isRequired,
};

export default DataLakeSettings;
