export const onAccessibleClick = (event, handler) => {
  if (!event) return;
  if (event.keyCode || event.charCode) {
    const code = event.keyCode || event.charCode;
    if (code === 13 || code === 32) {
      event.preventDefault();
      handler(event);
    }
  } else {
    event.preventDefault();
    handler(event);
  }
};
