import { connect } from '../../utils/redux';
import Buckets from '../Buckets';
import DataSources from '../DataSources';
import { DataSourcesPage } from './components';
import * as selectors from './selectors';

const mapStateToProps = (state) => {
  return {
    loadingInProgress: DataSources.selectors.getLoadingInProgress(state),
    dataSources: DataSources.selectors.getDataSources(state),
    loadingFinished: selectors.getLoadingFinished(state),
  };
};

export default connect(mapStateToProps, {
  loadDataSources: DataSources.actions.getAll,
  loadBuckets: Buckets.actions.getAll,
})(DataSourcesPage);
