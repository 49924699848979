import { node } from 'prop-types';
import { PageHeaderDiv, MainDiv } from './styledComponents';

const PageHeader = ({ children }) => {
  return (
    <PageHeaderDiv>
      <MainDiv>{children}</MainDiv>
    </PageHeaderDiv>
  );
};

PageHeader.propTypes = {
  children: node.isRequired,
};

export default PageHeader;
