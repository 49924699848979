import { useEffect, useCallback } from 'react';
import { arrayOf, bool, func, string, shape } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Page from '../../Page';
import Form from '../../Form';
import PrestoClusters from '../../PrestoClusters';
import useRouteBuilder from '../../../router/useRouteBuilder';
import AuthorizationServices from '../../AuthorizationServices';
import UpdateAuthorizationServiceForm from './UpdateAuthorizationServiceForm';

const UpdateAuthorizationService = ({
  errors,
  usePop,
  submitInProgress,
  submitSuccess,
  notFound,
  authorizationServiceId,
  authorizationService,
  prestoClusters,
  loadAuthorizationService,
  loadPrestoClusters,
  update,
}) => {
  useEffect(() => {
    loadAuthorizationService(authorizationServiceId);
    loadPrestoClusters(authorizationServiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationServiceId]);

  const submitAction = useCallback(
    (data) => {
      update(authorizationServiceId, data);
    },
    [update, authorizationServiceId],
  );

  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!authorizationService || notFound) {
    return <Page.Loading />;
  }

  return (
    <Page.PageWithOverflow>
      <Form.Form
        name='update-authorization-service'
        submitAction={submitAction}
      >
        <UpdateAuthorizationServiceForm
          errors={errors}
          usePop={usePop}
          submitInProgress={submitInProgress}
          submitSuccess={submitSuccess}
          authorizationServiceId={authorizationServiceId}
          authorizationService={authorizationService}
          prestoClusters={prestoClusters}
        />
      </Form.Form>
    </Page.PageWithOverflow>
  );
};

UpdateAuthorizationService.defaultProps = {
  errors: {},
  usePop: true,
  submitInProgress: false,
  submitSuccess: false,
  notFound: false,
  authorizationService: null,
  prestoClusters: null,
};

UpdateAuthorizationService.propTypes = {
  errors: shape({
    details: string,
    connection: shape({
      type: string,
      error: string,
    }),
    type: string,
  }),
  usePop: bool,
  submitInProgress: bool,
  submitSuccess: bool,
  notFound: bool,
  authorizationServiceId: string.isRequired,
  authorizationService: AuthorizationServices.propTypes.AuthorizationService,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  loadAuthorizationService: func.isRequired,
  loadPrestoClusters: func.isRequired,
  update: func.isRequired,
};

export default UpdateAuthorizationService;
