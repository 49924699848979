const LogOut = () => {
  return (
    <svg
      id='icon'
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
    >
      <title>logout</title>
      <path
        fill='white'
        d='M6,30H18a2.0023,2.0023,0,0,0,2-2V25H18v3H6V4H18V7h2V4a2.0023,2.0023,0,0,0-2-2H6A2.0023,2.0023,0,0,0,4,4V28A2.0023,2.0023,0,0,0,6,30Z'
      />
      <polygon
        fill='white'
        points='20.586 20.586 24.172 17 10 17 10 15 24.172 15 20.586 11.414 22 10 28 16 22 22 20.586 20.586'
      />
      <rect
        id='_Transparent_Rectangle_'
        data-name='&lt;Transparent Rectangle&gt;'
        style={{ fill: 'none' }}
        width='32'
        height='32'
      />
    </svg>
  );
};

export default LogOut;
