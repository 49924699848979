import { useRef, useEffect, useMemo } from 'react';
import { string, bool, node, oneOfType, object, func } from 'prop-types';
import Icons from '../../icons';
import { BannerDiv, CloseIconButton } from './styledComponents';
import CloseIcon from '../../icons/Close';

const Banner = ({
  title,
  details,
  isWarning,
  isSuccess,
  scrollIntoView,
  children,
  showCloseIcon,
  onDismiss,
}) => {
  const bannerRef = useRef();
  useEffect(() => {
    if (scrollIntoView && bannerRef.current) {
      bannerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollIntoView]);

  const className = useMemo(() => {
    if (isWarning) {
      return 'warning';
    }
    if (isSuccess) {
      return 'success';
    }
    return 'error';
  }, [isWarning, isSuccess]);

  const showingCloseIcon = useMemo(() => {
    return showCloseIcon && onDismiss;
  }, [showCloseIcon, onDismiss]);

  return (
    <BannerDiv className={className} ref={bannerRef}>
      <Icons.Alert />
      <div>
        <div className='title'>{title}</div>
        {details && (
          <div className={showingCloseIcon ? 'details compact' : 'details'}>
            {details}
          </div>
        )}
        {children}
      </div>
      {showingCloseIcon && (
        <CloseIconButton type='button' onClick={onDismiss}>
          <CloseIcon />
        </CloseIconButton>
      )}
    </BannerDiv>
  );
};

Banner.defaultProps = {
  details: null,
  isWarning: false,
  isSuccess: false,
  scrollIntoView: false,
  children: null,
  showCloseIcon: null,
  onDismiss: null,
};

Banner.propTypes = {
  title: string.isRequired,
  details: oneOfType([string, object]),
  isWarning: bool,
  isSuccess: bool,
  scrollIntoView: bool,
  children: node,
  showCloseIcon: bool,
  onDismiss: func,
};

export default Banner;
