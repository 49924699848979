/* eslint-disable react/jsx-props-no-spreading */
import { string, oneOfType, number, bool } from 'prop-types';
import { Timespan } from '../../Date';
import PrestoClusters from '../../PrestoClusters';
import Page from '../../Page';
import { useEnableFeature } from '../../utils/featureHooks';
import { InformationGridDiv } from '../../Page/styledComponents';

const display = {
  title: 'Cluster Scaling',
  minimumWorkerNodeCount: 'Minimum worker node count',
  maximumWorkerNodeCount: 'Maximum worker node count',
  cpuScaleInStepSize: 'Scaling Step Size',
  cpuScaleInTimeWindow: 'Scale In Time Window',
  idleTimeWindow: 'Time window before scaling to minimum node count',
  defaultWorkerNodeCount: 'Default worker node count',
  idleTimeWindowSingle: 'Time window before scaling to a single node',
  changeAutoScalingPolicyButton: 'Change Scaling Policy',
  cpuTarget: 'Target (%)',
  queuedQueriesTarget: 'Target',
};

const IdleCostSavings = ({ workerNodes, idleTime }) => {
  return (
    <InformationGridDiv style={{ '--grid-columns': 2 }}>
      <div className='cell'>
        <div className='title'>{display.defaultWorkerNodeCount}</div>
        <div className='data'>{workerNodes}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.idleTimeWindowSingle}</div>
        <div className='data'>
          <Timespan milliseconds={idleTime * 60 * 1000} />
        </div>
      </div>
    </InformationGridDiv>
  );
};

IdleCostSavings.propTypes = {
  workerNodes: number.isRequired,
  idleTime: number.isRequired,
};

const CPUScaleIn = ({
  minWorkerNodes,
  maxWorkerNodes,
  stepSize,
  idleTime,
  target,
}) => {
  return (
    <InformationGridDiv style={{ '--grid-columns': 2 }}>
      <div className='cell'>
        <div className='title'>{display.minimumWorkerNodeCount}</div>
        <div className='data'>{minWorkerNodes}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.maximumWorkerNodeCount}</div>
        <div className='data'>{maxWorkerNodes}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.cpuScaleInStepSize}</div>
        <div className='data'>{stepSize}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.cpuScaleInTimeWindow}</div>
        <div className='data'>
          <Timespan milliseconds={idleTime * 60 * 1000} />
        </div>
      </div>
      <div className='cell'>
        <div className='title'>{display.cpuTarget}</div>
        <div className='data'>{target}</div>
      </div>
    </InformationGridDiv>
  );
};

CPUScaleIn.propTypes = {
  minWorkerNodes: number.isRequired,
  maxWorkerNodes: number.isRequired,
  stepSize: number.isRequired,
  idleTime: number.isRequired,
  target: number.isRequired,
};

const QueuedQueriesScaleIn = ({
  minWorkerNodes,
  maxWorkerNodes,
  stepSize,
  idleTime,
  target,
}) => {
  return (
    <InformationGridDiv style={{ '--grid-columns': 2 }}>
      <div className='cell'>
        <div className='title'>{display.minimumWorkerNodeCount}</div>
        <div className='data'>{minWorkerNodes}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.maximumWorkerNodeCount}</div>
        <div className='data'>{maxWorkerNodes}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.cpuScaleInStepSize}</div>
        <div className='data'>{stepSize}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.cpuScaleInTimeWindow}</div>
        <div className='data'>
          <Timespan milliseconds={idleTime * 60 * 1000} />
        </div>
      </div>
      <div className='cell'>
        <div className='title'>{display.queuedQueriesTarget}</div>
        <div className='data'>{target}</div>
      </div>
    </InformationGridDiv>
  );
};

QueuedQueriesScaleIn.propTypes = {
  minWorkerNodes: number.isRequired,
  maxWorkerNodes: number.isRequired,
  stepSize: number.isRequired,
  idleTime: number.isRequired,
  target: number.isRequired,
};

const AutoScaling = ({
  prestoClusterId,
  autoScaling,
  status,
  workflowInProgress,
}) => {
  const enableEditLink = useEnableFeature();

  if (!autoScaling) {
    return null;
  }

  const canChangeAutoScaling =
    (status === PrestoClusters.constants.status.active ||
      status === PrestoClusters.constants.status.inactive) &&
    !workflowInProgress;

  return (
    <Page.BoxNavTarget>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
        <div className='buttons'>
          <Page.PrimaryLink
            to={PrestoClusters.routes.getPrestoClusterAutoScalingPolicy(
              prestoClusterId,
            )}
            disabled={!canChangeAutoScaling || !enableEditLink}
          >
            {display.changeAutoScalingPolicyButton}
          </Page.PrimaryLink>
        </div>
      </Page.BoxHeader>
      {autoScaling.type === 'cpu' && (
        <CPUScaleIn {...autoScaling} target={autoScaling.target || 40} />
      )}
      {autoScaling.type === 'idleCostSavings' && (
        <IdleCostSavings {...autoScaling} />
      )}
      {autoScaling.type === 'queuedQueries' && (
        <QueuedQueriesScaleIn {...autoScaling} />
      )}
    </Page.BoxNavTarget>
  );
};

AutoScaling.defaultProps = {
  autoScaling: null,
};

AutoScaling.propTypes = {
  prestoClusterId: string.isRequired,
  status: string.isRequired,
  autoScaling: oneOfType([
    PrestoClusters.propTypes.AutoScalingPolicyIdleCostSavings,
    PrestoClusters.propTypes.AutoScalingPolicyCPU,
    PrestoClusters.propTypes.AutoScalingPolicyQueuedQueries,
  ]),
  workflowInProgress: bool.isRequired,
};

export default AutoScaling;
