import { string, arrayOf, shape, func } from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Form from '../../Form';
import { DateFilterDiv } from './styledComponents';
import { getMonthlyRange } from '../../../utils/dateUtils';

const display = {
  monthFilterLabel: 'Date',
};

const MonthlyFilterDropdown = ({ filterMonths, loadClusters }) => {
  const { watch } = useFormContext();
  const selectedMonthFilter = watch('monthFilter', null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentMonthYear = useMemo(() => {
    return `${new Date().getMonth()}/${new Date().getFullYear()}`;
  });

  useEffect(() => {
    if (selectedMonthFilter) {
      const { startDate, endDate } = getMonthlyRange(selectedMonthFilter);
      loadClusters(startDate, endDate);
    }
  }, [loadClusters, selectedMonthFilter]);

  return (
    <DateFilterDiv>
      <Form.SelectInput
        name='monthFilter'
        label={display.monthFilterLabel}
        disabled={false}
        items={filterMonths}
        defaultValue={currentMonthYear}
      />
    </DateFilterDiv>
  );
};

MonthlyFilterDropdown.propTypes = {
  filterMonths: arrayOf(
    shape({
      display: string.isRequired,
      value: string,
    }),
  ).isRequired,
  loadClusters: func.isRequired,
};

export default MonthlyFilterDropdown;
