import { Children } from 'react';
import { node } from 'prop-types';
import { FieldRowDiv } from './styledComponents';

const FieldRow = ({ children }) => {
  return (
    <FieldRowDiv>
      {Children.map(children, (child) => {
        return <div>{child}</div>;
      })}
    </FieldRowDiv>
  );
};

FieldRow.propTypes = {
  children: node.isRequired,
};

export default FieldRow;
