import { apiVerbs, createApiActionType } from '../../rayApi/actionTypes';

const componentName = 'DATA_SOURCES';
export const getAll = createApiActionType(apiVerbs.GET, `${componentName}_ALL`);
export const get = createApiActionType(apiVerbs.GET, componentName);
export const getPrestoClusters = createApiActionType(
  apiVerbs.GET,
  `${componentName}_PRESTO_CLUSTERS`,
);
export const patch = createApiActionType(apiVerbs.PATCH, componentName);
export const remove = createApiActionType(apiVerbs.DELETE, componentName);
