import * as environment from '../utils/environment';

export const getLoginUri = (location) => {
  const baseIAMUri = environment.getIAMUri();
  const path = location.state?.from?.pathname ?? '/home';
  const search = location.state?.from?.search ?? location.search;
  const decodedSearch = decodeURIComponent(search);
  const redirectUri = `https://${window.location.hostname}${path}${decodedSearch}`;

  return `${baseIAMUri}?client_id=lakehouse&redirect_uri=${encodeURIComponent(
    redirectUri,
  )}`;
};
