import { string } from 'prop-types';
import CircleIcon from './CircleIcon';
import { SmallLoadingDiv } from './styledComponents';

const SmallLoading = ({ message }) => {
  return (
    <SmallLoadingDiv>
      <CircleIcon />
      {message && <div>{message}</div>}
    </SmallLoadingDiv>
  );
};

SmallLoading.defaultProps = {
  message: undefined,
};

SmallLoading.propTypes = {
  message: string,
};

export default SmallLoading;
