import { useCallback } from 'react';
import { string, bool } from 'prop-types';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../icons/Copy';
import { Tooltip } from '../Data/styledComponents';
import { TertiaryButton } from './Button';
import { CopyButtonWrapperDiv } from './styledComponents';

const display = {
  copiedMessage: 'Copied',
};

const CopyButton = ({
  contentToCopy,
  buttonLabel,
  tooltipPlacement,
  disabled,
  disabledOnErrors,
}) => {
  const onCopyClick = useCallback(
    (event) => {
      copy(contentToCopy);
      if (
        event.type === 'click' &&
        event.clientX !== 0 &&
        event.clientY !== 0
      ) {
        event.currentTarget.blur();
      }
    },
    [contentToCopy],
  );

  return (
    <CopyButtonWrapperDiv>
      <TertiaryButton
        onClick={onCopyClick}
        disabled={disabled}
        disabledOnErrors={disabledOnErrors}
        data-tooltip-id='copy-button-tooltip'
      >
        <CopyIcon />
        {buttonLabel}
      </TertiaryButton>
      <Tooltip
        id='copy-button-tooltip'
        content={display.copiedMessage}
        openOnClick
        delayHide={1000}
        noArrow
        place={tooltipPlacement || 'left'}
      />
    </CopyButtonWrapperDiv>
  );
};

CopyButton.defaultProps = {
  tooltipPlacement: 'right',
  disabled: false,
  disabledOnErrors: true,
};

CopyButton.propTypes = {
  buttonLabel: string.isRequired,
  contentToCopy: string.isRequired,
  tooltipPlacement: string,
  disabled: bool,
  disabledOnErrors: bool,
};

export default CopyButton;
