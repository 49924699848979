import Container from './Container';
import reducer from './reducer';
import * as routes from './routes';
import * as constants from './constants';
import * as propTypes from './propTypes';
import * as actions from './actions';
import * as selectors from './selectors';

const module = {
  Container,
  reducer,
  constants,
  propTypes,
  routes,
  actions,
  selectors,
};

export default module;
