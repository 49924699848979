export const getSubmitInProgress = (state) => {
  return state.signIn.submitInProgress;
};

export const getError = (state) => {
  return state.signIn.error;
};

export const getErrorStatus = (state) => {
  return state.signIn.errorStatus;
};

export const getMessage = (state, props) => {
  const { location } = props;
  if (location && location.state) {
    return location.state.message;
  }
  return null;
};
