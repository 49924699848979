import { createContext, useContext, useState } from 'react';
import { node } from 'prop-types';

const HelpNavigationStateContext = createContext();
const HelpNavigationDispatchContext = createContext();

const useHelpNavigationState = (ignoreNull = false) => {
  const context = useContext(HelpNavigationStateContext);
  if (!ignoreNull && context === undefined) {
    throw new Error(
      'useHelpNavigationState must be used within HelpNavigation',
    );
  }
  return context;
};

const useHelpNavigationDispatch = (ignoreNull = false) => {
  const context = useContext(HelpNavigationDispatchContext);
  if (!ignoreNull && context === undefined) {
    throw new Error(
      'useHelpNavigationDispatch must be used within HelpNavigation',
    );
  }
  return context;
};

const HelpNavigation = ({ children }) => {
  const [state, dispatch] = useState(null);

  return (
    <HelpNavigationStateContext.Provider value={state}>
      <HelpNavigationDispatchContext.Provider value={dispatch}>
        {children}
      </HelpNavigationDispatchContext.Provider>
    </HelpNavigationStateContext.Provider>
  );
};

HelpNavigation.propTypes = {
  children: node.isRequired,
};

export default HelpNavigation;
export { useHelpNavigationState, useHelpNavigationDispatch };
