import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import telemetry from '../../telemetry';
// doing this to avoid circluar dependency
import * as prestoClustersActionTypes from '../PrestoClusters/actionTypes';
// doing this to avoid circluar dependency
import * as prestoUsersActionTypes from '../PrestoUsers/actionTypes';
import * as authorizationServicesActionTypes from '../AuthorizationServices/actionTypes';
import * as actionTypes from './actionTypes';
import { mapTypeName } from '../AuthorizationServices/mapTypeName';

const initialState = {
  dataSources: null,
  dataSourcesPrestoClusterIds: null,
  loadingInProgress: false,
  submitInProgress: false,
  error: null,
  actionStatus: new Map(),
};

const onNavigation = (draft) => {
  draft.submitInProgress = false;
  draft.loadingInProgress = false;
  draft.error = null;
  // maybe some day we can be smarter about this
  // but for right now this is the easy way to
  // not show stale information
  draft.dataSourcesPrestoClusterIds = null;
  draft.actionStatus = new Map();
};

const getStart = (draft) => {
  draft.loadingInProgress = true;
};

const getAllFailure = (draft, action) => {
  // TODO: display error message?
  draft.loadingInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
};

const get = (draft, action) => {
  if (!draft.dataSources) {
    draft.dataSources = new Map();
  }
  let data = action.payload.apiResult.body;
  data = {
    ...data,
    authorizationServiceTypeName: mapTypeName(data) ?? 'None',
  };
  draft.dataSources.set(action.payload.params.dataSourceId, data);
};

const getFailure = (draft, action) => {
  if (!draft.dataSources) {
    draft.dataSources = new Map();
  }
  draft.dataSources.set(action.payload.params.dataSourceId, 'not found');
};

const getPartial = (draft, action) => {
  if (!draft.dataSources) {
    draft.dataSources = new Map();
  }
  const { results } = action.payload.apiResult.body;
  for (let i = 0; i < results.length; i += 1) {
    const result = {
      ...results[i],
      authorizationServiceTypeName: mapTypeName(results[i]) ?? 'None',
    };
    draft.dataSources.set(results[i].dataSourceId, result);
  }
};

const getAll = (draft, action) => {
  if (draft.dataSources) {
    draft.dataSources.clear();
  }
  getPartial(draft, action);
  draft.loadingInProgress = false;
};

const getPrestoClusters = (draft, action) => {
  if (!draft.dataSourcesPrestoClusterIds) {
    draft.dataSourcesPrestoClusterIds = new Map();
  }

  const prestoClusterIds = action.payload.apiResult.body.results.map((pc) => {
    return pc.prestoClusterId;
  });
  draft.dataSourcesPrestoClusterIds.set(
    action.payload.params.dataSourceId,
    prestoClusterIds,
  );
};

const getPrestoClustersFailure = (draft, action) => {
  if (!draft.dataSourcesPrestoClusterIds) {
    draft.dataSourcesPrestoClusterIds = new Map();
  }
  draft.dataSourcesPrestoClusterIds.set(action.payload.params.dataSourceId, []);
};

const submitStart = (draft) => {
  draft.submitInProgress = true;
  draft.error = null;
};

const submitSuccess = (draft) => {
  draft.submitInProgress = false;
  draft.error = null;
};

const submitFailure = (draft, action) => {
  draft.submitInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
};

const actionStart = (draft, action) => {
  const status = {
    ...action.payload.actionInfo,
    success: false,
    inProgress: true,
  };
  draft.actionStatus.set(action.payload.params.dataSourceId, status);
};

const actionFailure = (draft, action) => {
  const oldStatus = draft.actionStatus.get(action.payload.params.dataSourceId);
  if (oldStatus.actionId === action.payload.actionInfo.actionId) {
    const status = {
      ...oldStatus,
      success: false,
      inProgress: false,
      error: action.payload.apiResult.body.errorMessage,
    };
    draft.actionStatus.set(action.payload.params.dataSourceId, status);
  }
};

const removeSuccess = (draft, action) => {
  const oldStatus = draft.actionStatus.get(action.payload.params.dataSourceId);
  if (oldStatus.actionId === action.payload.actionInfo.actionId) {
    const status = {
      ...oldStatus,
      success: true,
      inProgress: false,
      error: null,
    };
    draft.actionStatus.set(action.payload.params.dataSourceId, status);
  }

  draft.dataSources.delete(action.payload.params.dataSourceId);
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.get.success]: get,
  [actionTypes.get.failure]: getFailure,
  [authorizationServicesActionTypes.getDataSources.success]: getPartial,
  [prestoClustersActionTypes.getDataSources.success]: getPartial,
  [prestoUsersActionTypes.getDataSourcesForPrestoUser.success]: getPartial,
  [actionTypes.getPrestoClusters.success]: getPrestoClusters,
  [actionTypes.getPrestoClusters.failure]: getPrestoClustersFailure,
  [actionTypes.getAll.request]: getStart,
  [actionTypes.getAll.success]: getAll,
  [actionTypes.getAll.failure]: getAllFailure,
  [actionTypes.patch.request]: submitStart,
  [actionTypes.patch.success]: submitSuccess,
  [actionTypes.patch.failure]: submitFailure,
  [actionTypes.remove.request]: actionStart,
  [actionTypes.remove.success]: removeSuccess,
  [actionTypes.remove.failure]: actionFailure,
  [auth.actionTypes.signOut]: signOut,
};

const stateSanitizer = (state) => {
  return {
    ...state,
    dataSources: {
      ...state.dataSources,
      dataSources: telemetry.sanitize(state.dataSources.dataSources),
    },
  };
};

telemetry.stateSanitizer.registerStateSanitizer('dataSources', stateSanitizer);

export default makeReducer(initialState, actionReducers);
