import { string, shape } from 'prop-types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import useObjectURL from 'use-object-url';
import Page from '../../../../Page';
import Form from '../../../../Form';
import Data from '../../../../Data';
import { roleCreationMethod } from '../../../constants';
import * as Help from '../../CreateComputePlaneHelp';
import { RoleCreationSteps } from '../../styledComponents';

const ROLE_CREATION_METHOD_DEFAULT = roleCreationMethod.cloudFormation;

const display = {
  title: 'Step 4 of 8 - Create Provisioning Role',
  roleCreationHeader: 'IAM Role Creation',
  roleCreationLabel: 'Role Creation Method',
  roleCreationMethodLabels: {
    quick: 'Quick Create',
    manual: 'Manual CloudFormation',
    terraform: 'Terraform',
    email: 'Email AWS Administrator',
  },
  quickCreateLabel: 'CloudFormation Quick Create',
  manualCreateLabel: 'CloudFormation Template',
  terraformCreateLabel: 'Terraform',
  manualStepsLabel: 'Manual Role Creation Steps with CloudFormation',
  cloudFormationButtonLabel: 'Open CloudFormation',
  cloudFormationFinalHelp:
    'Click through the AWS CloudFormation Quick Create process without changing any values. Once the CloudFormation status is complete return here to verify the role creation at the bottom of the page.',
  manualCloudFormationButtonLabel: 'Save CloudFormation Template',
  terraformButtonLabel: 'Save Terraform',
  terraformStepsLabel: 'Manual Role Creation Steps with Terraform',
};

const ManualRoleCreation = ({
  stackName,
  awsAccountId,
  awsRegion,
  cloudFormationTemplate,
  awsCloudFormationUrl,
}) => {
  const cloudFormationTemplateBlob = useMemo(() => {
    return new Blob([cloudFormationTemplate], { type: 'application/text' });
  }, [cloudFormationTemplate]);
  const url = useObjectURL(cloudFormationTemplateBlob);
  return (
    <>
      <Data.Label
        label={display.manualCreateLabel}
        fieldHelp={Help.CloudFormationManualCreate}
      />
      <Data.ButtonGroup>
        <Page.TertiaryLink
          download
          downloadFileName={`${stackName}.yaml`}
          to={url}
        >
          {display.manualCloudFormationButtonLabel}
        </Page.TertiaryLink>
      </Data.ButtonGroup>
      <RoleCreationSteps>
        <Data.Label label={display.manualStepsLabel} />
        <ol>
          <li>Save this CloudFormation template to disk.</li>
          <li>
            In AWS account <strong>{awsAccountId}</strong> and AWS region{' '}
            <strong>{awsRegion}</strong>, open{' '}
            <a
              href={awsCloudFormationUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              CloudFormation
            </a>{' '}
            and create a new stack.
          </li>
          <li>Upload the saved file as the template for the new stack.</li>
          <li>
            Name the stack <strong>{stackName}</strong>.
          </li>
          <li>
            Once the CloudFormation status is complete, return here to verify
            the role creation at the bottom of the page.
          </li>
        </ol>
      </RoleCreationSteps>
    </>
  );
};

ManualRoleCreation.propTypes = {
  awsAccountId: string.isRequired,
  awsRegion: string.isRequired,
  stackName: string.isRequired,
  cloudFormationTemplate: string.isRequired,
  awsCloudFormationUrl: string.isRequired,
};

const TerraformRoleCreation = ({
  awsAccountId,
  terraformContent,
  terraformFileName,
}) => {
  const terraformBlob = useMemo(() => {
    return new Blob([terraformContent], { type: 'application/text' });
  }, [terraformContent]);
  const url = useObjectURL(terraformBlob);
  return (
    <>
      <Data.Label
        label={display.terraformCreateLabel}
        fieldHelp={Help.TerraformCreate}
      />
      <Data.ButtonGroup>
        <Page.TertiaryLink
          download
          downloadFileName={terraformFileName}
          to={url}
        >
          {display.terraformButtonLabel}
        </Page.TertiaryLink>
      </Data.ButtonGroup>
      <RoleCreationSteps>
        <Data.Label label={display.terraformStepsLabel} />
        <ol>
          <li>Save this Terraform file to disk.</li>
          <li>
            In AWS account <strong>{awsAccountId}</strong> apply this terraform
            file. Do not change or overwrite the default values of any variables
            in the file.
          </li>
          <li>
            Once the Terraform is complete, return here to verify the role
            creation at the bottom of the page.
          </li>
        </ol>
      </RoleCreationSteps>
    </>
  );
};

TerraformRoleCreation.propTypes = {
  awsAccountId: string.isRequired,
  terraformContent: string.isRequired,
  terraformFileName: string.isRequired,
};

export const RoleCreationMethod = ({
  awsAccountId,
  awsCloudFormationQuickCreateUrl,
  cloudFormationTemplate,
  terraformContent,
  terraformFileName,
  computePlane,
  cfStackName,
  awsCloudFormationUrl,
}) => {
  const { watch } = useFormContext();
  const roleMethod = watch('roleCreation.method', ROLE_CREATION_METHOD_DEFAULT);
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      <Page.FormContent rows={3}>
        <div>
          <h3>{display.roleCreationHeader}</h3>
        </div>
        <div>
          <Form.RadioInputs
            name='roleCreation.method'
            label={display.roleCreationLabel}
            defaultValue={ROLE_CREATION_METHOD_DEFAULT}
            fieldHelp={Help.RoleCreationMethod}
            values={[
              {
                label: display.roleCreationMethodLabels.quick,
                value: roleCreationMethod.cloudFormation,
              },
              {
                label: display.roleCreationMethodLabels.manual,
                value: roleCreationMethod.manual,
              },
              {
                label: display.roleCreationMethodLabels.terraform,
                value: roleCreationMethod.terraform,
              },
            ]}
          />
          {roleMethod === roleCreationMethod.cloudFormation && (
            <>
              <Data.Label
                label={display.quickCreateLabel}
                fieldHelp={Help.CloudFormationQuickCreate}
              />
              <Data.ButtonGroup>
                <Page.TertiaryLink
                  to={awsCloudFormationQuickCreateUrl}
                  external
                  newTab
                >
                  {display.cloudFormationButtonLabel}
                </Page.TertiaryLink>
              </Data.ButtonGroup>
              <div>{display.cloudFormationFinalHelp}</div>
            </>
          )}
          {roleMethod === roleCreationMethod.manual && (
            <ManualRoleCreation
              stackName={cfStackName}
              awsAccountId={awsAccountId}
              awsRegion={computePlane?.awsRegion}
              cloudFormationTemplate={cloudFormationTemplate}
              awsCloudFormationUrl={awsCloudFormationUrl}
            />
          )}
          {roleMethod === roleCreationMethod.terraform && (
            <TerraformRoleCreation
              awsAccountId={awsAccountId}
              terraformContent={terraformContent}
              terraformFileName={terraformFileName}
            />
          )}
        </div>
      </Page.FormContent>
    </Page.Box>
  );
};

RoleCreationMethod.defaultProps = {
  awsAccountId: null,
  computePlane: null,
  cfStackName: null,
  terraformFileName: null,
  terraformContent: null,
};

RoleCreationMethod.propTypes = {
  awsAccountId: string,
  awsCloudFormationQuickCreateUrl: string.isRequired,
  cloudFormationTemplate: string.isRequired,
  computePlane: shape({
    computePlaneId: string,
    awsRegion: string,
  }),
  cfStackName: string,
  terraformFileName: string,
  terraformContent: string,
  awsCloudFormationUrl: string.isRequired,
};
