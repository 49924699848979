import { string, shape } from 'prop-types';
import Data from '../../Data';
import DataSources from '../../DataSources';

const display = {
  formContentHeader: 'Access',
  formExplaination: 'Secondary explanation',
  hostLabel: 'Endpoint',
  portLabel: 'Port',
  enableTLSEncryptionLabel: 'Enable TLS encryption',
  regionLabel: 'Region',
  credentialTypeLabel: 'Elasticsearch Credentials',
  roleLabel: 'Role ARN',
  accessKeyLabel: 'Access Key ID',
  secretLabel: 'Secret Access Key',
};

// const credentialTypeValues = [
//   {
//     label: 'IAM role',
//     value: 'iam',
//   },
//   {
//     label: 'Access key',
//     value: 'access_key',
//   },
// ];

const ElasticsearchConnectionDetails = ({ elasticsearch, host }) => {
  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
        {/* <div>{display.formExplaination}</div> */}
      </div>
      <div>
        <Data.DataField label={display.hostLabel} value={host} />
        <Data.CheckboxField
          label={display.enableTLSEncryptionLabel}
          value={elasticsearch.tls && elasticsearch.tls.enabled}
        />
        <Data.DataField
          label={display.regionLabel}
          value={elasticsearch.aws.region}
        />
        {/* <Data.RadioField
          label={display.credentialTypeLabel}
          options={credentialTypeValues}
          value={aws.credentialType}
        />
        {aws.credentialType === 'iam' && (
          <Data.DataField label={display.roleLabel} value={aws.role} />
        )}
        {aws.credentialType === 'access_key' && (
          <> */}
        <Data.DataField
          label={display.accessKeyLabel}
          value={elasticsearch.aws['access-key']}
        />
        <Data.DataField
          label={display.secretLabel}
          value={elasticsearch.aws['secret-key']}
        />
        {/* </>
        )} */}
      </div>
    </>
  );
};

ElasticsearchConnectionDetails.propTypes = {
  elasticsearch: shape(DataSources.propTypes.ElasticsearchConnection)
    .isRequired,
  host: string.isRequired,
};

export default ElasticsearchConnectionDetails;
