import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { string, bool, shape, object } from 'prop-types';
import Form from '../Form';
import Table from '../Table';
import Page from '../Page';
import Banner from '../Banner';
import Help from '../Help';
import { OptionalBadge } from '../Badge';
import * as constants from './constants';
import { customTagKeyRegex, customTagValueRegex } from '../../utils/regex';
import CustomTagsHelp from './CustomTagsHelp';
import { DataCellTextInputDivCustomTag } from './styledComponents';

const display = {
  boxTitle: 'AWS Tags',
  keyColumnLabel: 'Key',
  valueColumnLabel: 'Value',
  customTagKeyStartError: 'Key cannot start with aws: or ibmlh:',
  customTagKeyUniqueError: 'Key must be unique',
  customTagKeyRequiredByValueSpecifiedError:
    'Key is required if value specified',
  customTagValueRequiredByKeySpecifiedError:
    'Value is required if key specified',
  customTagKeyFormatError:
    'Key can only contain letters, numbers, spaces, and the following characters: _ . : / = + - @',
  customTagValueFormatError:
    'Value can only contain letters, numbers, spaces, and the following characters: _ . : / = + - @',
};

const CustomTagKey = ({ data, className, key }) => {
  const { watch } = useFormContext();
  const customTagKeys = watch('customTagKeys');
  const customTagValues = watch('customTagValues');

  const customTagKeyValidation = useCallback(
    (tagKey) => {
      if (!tagKey && customTagValues?.[data?.count]) {
        return display.customTagKeyRequiredByValueSpecifiedError;
      }

      if (tagKey) {
        const notUnique = customTagKeys?.find((k, i) => {
          return k === tagKey && i !== data.count;
        });
        if (notUnique) {
          return display.customTagKeyUniqueError;
        }
        if (tagKey.slice(0, 4) === 'aws:' || tagKey.slice(0, 6) === 'ibmlh:') {
          return display.customTagKeyStartError;
        }
        if (!customTagKeyRegex.test(tagKey)) {
          return display.customTagKeyFormatError;
        }
      }

      return true;
    },
    [data, customTagKeys, customTagValues],
  );

  return (
    <DataCellTextInputDivCustomTag
      key={key}
      className={`justify-center ${className}`}
    >
      <Form.TextInput
        name={`customTagKeys.${data.count}`}
        disabled={data.disabled}
        defaultValue={data.customTagKey}
        validationRules={{
          validate: customTagKeyValidation,
        }}
      />
    </DataCellTextInputDivCustomTag>
  );
};

CustomTagKey.defaultProps = {
  className: '',
};

CustomTagKey.propTypes = {
  data: shape({
    count: string.isRequired,
  }).isRequired,
  className: string,
  key: string.isRequired,
};

const CustomTagValue = ({ data, className, key }) => {
  const { watch } = useFormContext();
  const customTagKeys = watch('customTagKeys');

  const customTagValueValidation = useCallback(
    (tagValue) => {
      if (!tagValue && customTagKeys?.[data?.count]) {
        return display.customTagValueRequiredByKeySpecifiedError;
      }

      return true;
    },
    [data, customTagKeys],
  );
  return (
    <DataCellTextInputDivCustomTag
      key={key}
      className={`justify-center ${className}`}
    >
      <Form.TextInput
        name={`customTagValues.${data.count}`}
        disabled={data.disabled}
        defaultValue={data.customTagValue}
        validationRules={{
          pattern: {
            value: customTagValueRegex,
            message: display.customTagValueFormatError,
          },
          validate: customTagValueValidation,
        }}
      />
    </DataCellTextInputDivCustomTag>
  );
};

CustomTagValue.defaultProps = {
  className: '',
};

CustomTagValue.propTypes = {
  data: shape({
    count: string.isRequired,
  }).isRequired,
  className: string,
  key: string.isRequired,
};

const columns = [
  {
    name: display.keyColumnLabel,
    selector: (data, additionalContext) => {
      return {
        count: data.count,
        customTagKey: data.customTagKey,
        disabled: additionalContext.disabled,
      };
    },
    cellRender: CustomTagKey,
    cellWidth: 'auto',
  },
  {
    name: display.valueColumnLabel,
    headerRender: () => {
      return (
        <>
          <div>{display.valueColumnLabel}</div>
          <Help.Button fieldHelp={CustomTagsHelp.CustomTags} />
        </>
      );
    },
    selector: (data, additionalContext) => {
      return {
        count: data.count,
        customTagValue: data.customTagValue,
        disabled: additionalContext.disabled,
      };
    },
    cellRender: CustomTagValue,
    cellWidth: 'auto',
  },
];

const dataKeyGenerator = (data) => {
  return data.count;
};

const CustomTags = ({ customTags, disabled, error }) => {
  const additionalContext = useMemo(() => {
    return {
      disabled,
    };
  }, [disabled]);

  const dataCustomTags = useMemo(() => {
    let entries = [];
    if (customTags) {
      entries = Object.entries(customTags);
    }
    return Array.from(Array(constants.maxCustomTagsCount).keys()).reduce(
      (acc, i) => {
        acc.push({
          count: i,
          customTagKey: entries[i]?.[0],
          customTagValue: entries[i]?.[1],
        });
        return acc;
      },
      [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page.Box>
      {constants.maxCustomTagsCount > 0 && (
        <>
          <Page.TableBoxHeader>
            <h2>
              {display.boxTitle}
              <OptionalBadge />
            </h2>
          </Page.TableBoxHeader>
          {error && <Banner title={error} scrollIntoView />}
          <Table.Table
            data={dataCustomTags}
            columns={columns}
            dataKeyGenerator={dataKeyGenerator}
            additionalContext={additionalContext}
          />
        </>
      )}
    </Page.Box>
  );
};

CustomTags.defaultProps = {
  customTags: undefined,
  disabled: true,
  error: undefined,
};

CustomTags.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customTags: object,
  disabled: bool,
  error: string,
};

export default CustomTags;
