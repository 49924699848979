import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { string, bool, func, node } from 'prop-types';
import Loading from '../Loading';
import {
  ButtonWrapperDiv,
  PrimaryButton as Primary,
  SecondaryButton as Secondary,
  TertiaryButton as Tertiary,
  ErrorButton as ErrorB,
  SuccessButton as Success,
  WarningButton as Warning,
} from './styledComponents';

const Button = (ButtonStyle, name) => {
  const B = forwardRef(
    (
      {
        onClick,
        label,
        disabled,
        disabledOnErrors,
        showLoading,
        children,
        className,
        tooltip,
        ...props
      },
      ref,
    ) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const formContext = useFormContext();
      const { formState } = formContext || {};

      const formErrors = formState?.errors;

      return (
        <ButtonWrapperDiv
          className={className}
          ref={ref}
          title={tooltip}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          <ButtonStyle
            type='button'
            disabled={
              disabled ||
              (disabledOnErrors &&
                formContext &&
                Object.keys(formErrors).length > 0)
            }
            onClick={onClick}
            className={showLoading ? 'show-loading' : undefined}
          >
            {children || label}
          </ButtonStyle>

          {showLoading && <Loading.CircleIcon />}
        </ButtonWrapperDiv>
      );
    },
  );

  B.displayName = name;

  B.defaultProps = {
    label: undefined,
    disabled: false,
    disabledOnErrors: true,
    showLoading: false,
    children: undefined,
    className: undefined,
    tooltip: undefined,
  };

  B.propTypes = {
    onClick: func.isRequired,
    label: string,
    disabled: bool,
    disabledOnErrors: bool,
    showLoading: bool,
    children: node,
    className: string,
    tooltip: string,
  };
  return B;
};

const PrimaryButton = Button(Primary, 'PrimaryButton');
const SecondaryButton = Button(Secondary, 'SecondaryButton');
const TertiaryButton = Button(Tertiary, 'TertiaryButton');
const ErrorButton = Button(ErrorB, 'ErrorButton');
const SuccessButton = Button(Success, 'SuccessButton');
const WarningButton = Button(Warning, 'WarningButton');

export {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  ErrorButton,
  SuccessButton,
  WarningButton,
};
