export const getErrors = (state) => {
  return state.authorizationServiceUpdate.errors;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getSubmitInProgress = (state) => {
  return state.authorizationServiceUpdate.submitInProgress;
};

export const getSubmitSuccess = (state) => {
  return state.authorizationServiceUpdate.submitSuccess;
};
