import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';

const baseResource = 'tenants';

// READ THIS!!!!!!
// putting this api here for right now.
// I full expect this to be moved in the future when
// we add some kind of account management section

export const get = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `${baseResource}/${params.tenantId}`,
  });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};
