import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const getAll = () => {
  return createApiAction(actionTypes.getAll, api.getAll, true);
};

export const get = (prestoUserId) => {
  return createApiAction(actionTypes.get, api.get, true, { prestoUserId });
};

export const getPrestoClustersForPrestoUser = (prestoUserId) => {
  return createApiAction(
    actionTypes.getPrestoClustersForPrestoUser,
    api.getPrestoClustersForPrestoUser,
    true,
    { prestoUserId },
  );
};

export const getDataSourcesForPrestoUser = (prestoUserId) => {
  return createApiAction(
    actionTypes.getDataSourcesForPrestoUser,
    api.getDataSourcesForPrestoUser,
    true,
    { prestoUserId },
  );
};

export const patch = (
  prestoUserId,
  { name, description, type, connection },
) => {
  return createApiAction(
    actionTypes.patch,
    api.patch,
    true,
    { prestoUserId },
    { name, description, type, connection },
  );
};

export const remove = (prestoUserId, actionId) => {
  return createApiAction(
    actionTypes.remove,
    api.remove,
    true,
    {
      prestoUserId,
    },
    null,
    { actionId, name: 'delete' },
  );
};
