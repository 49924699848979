import { createSelector } from 'reselect';
import DataSources from '../DataSources';
import PrestoClusters from '../PrestoClusters';

export const getDataSources = createSelector(
  DataSources.selectors.getDataSources,
  PrestoClusters.selectors.getPrestoCluster,
  (dataSources, prestoCluster) => {
    if (!dataSources) return null;
    if (!prestoCluster) return null;

    let mappedDataSources;
    if (
      prestoCluster.clusterConfiguration?.workerContainerType === 'prestoCPP'
    ) {
      mappedDataSources = dataSources.map((ds) => {
        if (DataSources.utils.isLakeFormation(ds)) {
          return {
            ...ds,
            invalidForPrestoCPPGlueWithLakeFormation: true,
          };
        }

        if (
          ds.type !== DataSources.constants.dataSourceTypes.hive &&
          ds.type !== DataSources.constants.dataSourceTypes.glue
        ) {
          return {
            ...ds,
            invalidForPrestoCPP: true,
          };
        }

        return ds;
      });
    } else {
      mappedDataSources = dataSources;
    }

    const supportsLakeFormation =
      PrestoClusters.utils.isGlueWithLakeFormationSupported(prestoCluster);
    const supportsRanger =
      PrestoClusters.utils.isRangerHiveSupported(prestoCluster);
    if (supportsLakeFormation && supportsRanger) return mappedDataSources;

    mappedDataSources = mappedDataSources.map((ds) => {
      if (DataSources.utils.isLakeFormation(ds)) {
        return {
          ...ds,
          invalidLakeFormation: !supportsLakeFormation,
        };
      }

      if (DataSources.utils.isRangerHive(ds)) {
        return {
          ...ds,
          invalidRangerHive: !supportsRanger,
        };
      }

      return ds;
    });

    return mappedDataSources;
  },
);
