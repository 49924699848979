import { bool, string, object, arrayOf } from 'prop-types';
import Banner from '../../Banner';
import Page from '../../Page';
import { DropDownValue } from '../propTypes';
import ConfigurationDetails from './ConfigurationDetails';
import Ingress from './Ingress';
import EnableJMX from './EnableJMX';

const display = {
  boxTitle: 'Prometheus',
};

const Prometheus = ({ disabled, error, prometheus, instanceTypeValues }) => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <Page.FormContent>
        <ConfigurationDetails
          disabled={disabled}
          prometheus={prometheus}
          instanceTypeValues={instanceTypeValues}
        />
        <Ingress disabled={disabled} prometheus={prometheus} />
        <EnableJMX disabled={disabled} prometheus={prometheus} />
      </Page.FormContent>
    </Page.Box>
  );
};

Prometheus.defaultProps = {
  disabled: false,
  error: undefined,
  prometheus: null,
};

Prometheus.propTypes = {
  disabled: bool,
  error: string,
  // eslint-disable-next-line react/forbid-prop-types
  prometheus: object,
  instanceTypeValues: arrayOf(DropDownValue).isRequired,
};

export default Prometheus;
