import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import Help from '../../Help';
import useRouteBuilder from '../../../router/useRouteBuilder';

const PageHelp = () => {
  const addDataSourceRoute = useRouteBuilder(
    routes.dataSources.getAddDataSourceRoute,
  );
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Ready to add data to your Presto cluster?
      </Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Check the box in the &quot;Selected&quot; column to add a data source
          to the cluster.
        </Help.ListItem>
        <Help.ListItem>
          To remove a data source from the cluster, uncheck the
          &quot;Selected&quot; box.
        </Help.ListItem>
        <Help.ListItem>
          When you click &quot;Modify Cluster&quot; the cluster will be
          restarted with the set of selected data sources.
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        If you do not see the data source you are looking for,{' '}
        <Link to={addDataSourceRoute}>you can create a new one.</Link>
      </Help.P>
      <Help.P>
        Caution: Saving changes here will restart the Presto cluster. All
        queries running will fail. The cluster will be moved to pending state
        and will be unusable until it moves back to active state.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
