import { arrayOf, string, shape, func, number, bool } from 'prop-types';
import Page from '../../Page';
import SubscriptionSummary from './SubscriptionSummary';
import UsageDetails from './UsageDetails';
import { Tenant, Cluster, Contract } from '../propTypes';

const display = {
  header: 'Cost Management',
  subscriptionSummaryHeader: 'Subscription Summary',
  usageDetailsHeader: 'Usage Details',
};

const navTabs = [display.subscriptionSummaryHeader, display.usageDetailsHeader];

const CostManagement = ({
  filterMonths,
  loadTenantSummary,
  tenant,
  tenantId,
  loadClusters,
  clusters,
  totalTenantCredits,
  loadContract,
  contract,
  subscriptionSummaryIsLoading,
  usageDetailsIsLoading,
  error,
}) => {
  return (
    <Page.Page>
      <Page.InPageNavigation navTabs={navTabs}>
        <Page.PageHeaderWithNav>
          <div>
            <h1>{display.header}</h1>
          </div>
          <Page.PageNavigation navTabs={navTabs} />
        </Page.PageHeaderWithNav>
        <Page.Scroll>
          <SubscriptionSummary
            navTarget={display.subscriptionSummaryHeader}
            tenant={tenant}
            tenantId={tenantId}
            loadTenantSummary={loadTenantSummary}
            contract={contract}
            loadContract={loadContract}
            error={error}
            isLoading={subscriptionSummaryIsLoading}
          />
          <UsageDetails
            navTarget={display.usageDetailsHeader}
            filterMonths={filterMonths}
            loadClusters={loadClusters}
            clusters={clusters}
            totalTenantCredits={totalTenantCredits}
            isLoading={usageDetailsIsLoading}
          />
        </Page.Scroll>
      </Page.InPageNavigation>
    </Page.Page>
  );
};

CostManagement.defaultProps = {
  tenant: null,
  clusters: null,
  totalTenantCredits: null,
  contract: null,
  error: null,
};

CostManagement.propTypes = {
  filterMonths: arrayOf(
    shape({
      display: string.isRequired,
      value: string,
    }),
  ).isRequired,
  loadTenantSummary: func.isRequired,
  loadClusters: func.isRequired,
  tenant: Tenant,
  tenantId: string.isRequired,
  clusters: arrayOf(Cluster),
  totalTenantCredits: number,
  loadContract: func.isRequired,
  contract: Contract,
  subscriptionSummaryIsLoading: bool.isRequired,
  usageDetailsIsLoading: bool.isRequired,
  error: string,
};

export default CostManagement;
