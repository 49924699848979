import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { string, bool } from 'prop-types';
import Loading from '../Loading';
import { ButtonWrapperDiv, SubmitDestructiveButton } from './styledComponents';

const SubmitDestructive = ({ label, disabled, showLoading, fullWidth }) => {
  const {
    formState: { errors: formErrors },
  } = useFormContext();
  const className = useMemo(() => {
    if (fullWidth && showLoading) {
      return 'full-width show-loading bx--btn bx--btn--secondary';
    }
    if (fullWidth) {
      return 'full-width bx--btn bx--btn--secondary';
    }
    if (showLoading) {
      return 'show-loading bx--btn bx--btn--secondary';
    }
    return 'bx--btn bx--btn--secondary';
  }, [showLoading, fullWidth]);

  return (
    <ButtonWrapperDiv>
      <SubmitDestructiveButton
        className={className}
        disabled={disabled || Object.keys(formErrors).length > 0}
      >
        {label}
      </SubmitDestructiveButton>
      {showLoading && <Loading.CircleIcon />}
    </ButtonWrapperDiv>
  );
};

SubmitDestructive.defaultProps = {
  disabled: false,
  showLoading: false,
  fullWidth: false,
};

SubmitDestructive.propTypes = {
  label: string.isRequired,
  disabled: bool,
  showLoading: bool,
  fullWidth: bool,
};

export default SubmitDestructive;
