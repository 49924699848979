import { Navigate, useLocation } from 'react-router-dom';
import { bool } from 'prop-types';
import routes from './routes';

// RootRoute is only used when the url path is '/' or ''
// in other words, when the user does not specify a destination
// and this is the place the app decides where they should go
// by default
const RootRoute = ({ authenticated }) => {
  const location = useLocation();

  if (authenticated) {
    return (
      <Navigate
        to={{ pathname: routes.home.getRoute(), search: location.search }}
        replace
      />
    );
  }

  return (
    <Navigate
      to={{ pathname: routes.signIn.getRoute(), search: location.search }}
      replace
    />
  );
};

RootRoute.propTypes = {
  authenticated: bool.isRequired,
};

export default RootRoute;
