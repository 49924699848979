import { useMemo } from 'react';
import { bool, func, shape, string } from 'prop-types';
import Page from '../../../../Page';
import Form from '../../../../Form';
import ComputePlane from '../../../../ComputePlane';
import { FullyManaged } from './FullyManaged';
import { UserManaged } from './UserManaged';
import * as constants from '../../../constants';
import CustomTags from '../../../../CustomTags/CustomTags';
import { useFormWizard } from '../../../../FormWizard/useFormWizard';
import Banner from '../../../../Banner';

const display = {
  title: 'Step 5 of 8 - Deployment Details',
};

export const DeploymentDetails = ({
  computePlane,
  awsSubnets,
  awsAZsIDsByRegion,
  onBack,
  onNext,
  submitInProgress,
  error,
  getVPCSubnets,
  getRegionInfo,
}) => {
  const formWizard = useFormWizard();
  const isUserManagedVPC = useMemo(() => {
    return computePlane?.computeConfiguration?.isBYOVPC ?? false;
  }, [computePlane]);

  const handleSubmit = (data) => {
    const { nextStepId } = formWizard;

    let updateData = {};
    if (isUserManagedVPC) {
      updateData = {
        privateSubnets: data.privateSubnets,
        publicSubnets: data.publicSubnets,
        customTags: {},
      };
    } else {
      updateData = {
        vpcCIDR: data.vpcCIDR,
        awsAvailabilityZones: data.awsAvailabilityZones,
        customTags: {},
      };
    }

    if (data.customTagKeys?.length > 0 && data.customTagValues?.length > 0) {
      const tags = {};
      data.customTagKeys.forEach((key, i) => {
        if (key) {
          tags[`${key}`] = data.customTagValues[i];
        }
      });
      updateData.customTags = tags;
    }

    onNext({ data: updateData, nextStepId });
  };

  return (
    <Form.Form name={constants.stepIds.step5} submitAction={handleSubmit}>
      <Page.Box>
        <Page.BoxHeader>
          <h2>{display.title}</h2>
        </Page.BoxHeader>
        {error && <Banner title={error} scrollIntoView />}
        {!isUserManagedVPC && (
          <FullyManaged
            computePlane={computePlane}
            awsAZsIDsByRegion={awsAZsIDsByRegion}
            getRegionInfo={getRegionInfo}
          />
        )}
        {isUserManagedVPC && (
          <UserManaged
            computePlane={computePlane}
            awsSubnets={awsSubnets}
            getVPCSubnets={getVPCSubnets}
          />
        )}
      </Page.Box>
      <CustomTags
        customTags={computePlane.customTags}
        disabled={submitInProgress}
      />
      <Page.Buttons>
        <Form.SecondaryButton
          label='Back'
          onClick={onBack}
          disabled={submitInProgress}
          disabledOnErrors={false}
        />
        <Form.Submit
          label='Next'
          disabled={submitInProgress}
          disabledOnErrors
          showLoading={submitInProgress}
        />
      </Page.Buttons>
    </Form.Form>
  );
};

DeploymentDetails.defaultProps = {
  computePlane: null,
  awsSubnets: null,
  awsAZsIDsByRegion: null,
  error: undefined,
  submitInProgress: false,
};

DeploymentDetails.propTypes = {
  computePlane: shape({
    computeConfiguration: shape({ isBYOVPC: bool }),
  }),
  awsSubnets: ComputePlane.propTypes.awsSubnets,
  awsAZsIDsByRegion: ComputePlane.propTypes.awsAZsIDs,
  error: string,
  submitInProgress: bool,
  onBack: func.isRequired,
  onNext: func.isRequired,
  getVPCSubnets: func.isRequired,
  getRegionInfo: func.isRequired,
};
