import * as routes from './routes';
import Container from './Container';
import reducer from './reducer';

const module = {
  Container,
  reducer,
  routes,
};

export default module;
