import { shape, string } from 'prop-types';

export const Bucket = shape({
  awsUrl: string.isRequired,
  bucketId: string.isRequired,
  createDate: string.isRequired,
  name: string.isRequired,
  prestoClusterId: string.isRequired,
  prestoClusterName: string.isRequired,
  region: string.isRequired,
  status: string.isRequired,
  tenantId: string.isRequired,
  type: string.isRequired,
  updateDate: string.isRequired,
});

export const ErrorMessage = shape({
  key: string.isRequired,
  title: string.isRequired,
  data: string.isRequired,
});
