import { createApiAction } from '../../rayApi/actions';
import telemetry from '../../telemetry';
import DataSources from '../DataSources';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const submit = (values) => {
  const body = {
    name: values.name,
    description: values.description,
    type: values.dataSourceType,
    connection: values[values.dataSourceType],
    prestoUserMappings: values.prestoUserMappings,
    authorizationServiceId:
      values.authorizationServiceId !== ''
        ? values.authorizationServiceId
        : undefined,
  };

  return {
    type: actionTypes.submit,
    payload: {
      body,
    },
  };
};

export const post = (body) => {
  return createApiAction(
    actionTypes.post,
    DataSources.api.post,
    true,
    null,
    body,
  );
};

export const getConfiguration = () => {
  return createApiAction(
    actionTypes.getConfiguration,
    api.getConfiguration,
    true,
  );
};

const actionSanitizer = (action) => {
  let sanitizedAction = action;
  if (
    action.type === actionTypes.post.request ||
    action.type === actionTypes.submit
  ) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        body: {
          ...action.payload.body,
          connection: telemetry.sanitize(action.payload.body.connection),
        },
      },
      sanitized: true,
    };
  } else if (
    action.type === actionTypes.post.pending ||
    action.type === actionTypes.post.accepted ||
    action.type === actionTypes.post.success ||
    action.type === actionTypes.post.failure ||
    action.type === actionTypes.post.abort
  ) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        requestBody: {
          ...action.payload.requestBody,
          connection: telemetry.sanitize(action.payload.requestBody.connection),
        },
      },
      sanitized: true,
    };
  }
  return sanitizedAction;
};

telemetry.actionSanitizer.registerActionSanitizer(
  'dataSourceAdd',
  actionSanitizer,
);
