import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const getAll = () => {
  return createApiAction(actionTypes.getAll, api.getAll, true);
};

export const get = (authorizationServiceId) => {
  return createApiAction(actionTypes.get, api.get, true, {
    authorizationServiceId,
  });
};

export const getDataSources = (authorizationServiceId) => {
  return createApiAction(actionTypes.getDataSources, api.getDataSources, true, {
    authorizationServiceId,
  });
};

export const getPrestoClusters = (authorizationServiceId) => {
  return createApiAction(
    actionTypes.getPrestoClusters,
    api.getPrestoClusters,
    true,
    { authorizationServiceId },
  );
};

export const remove = (authorizationServiceId, actionId) => {
  return createApiAction(
    actionTypes.remove,
    api.remove,
    true,
    { authorizationServiceId },
    null,
    { actionId, name: 'delete' },
  );
};
