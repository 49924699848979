import { useState, useCallback, useRef, useEffect } from 'react';
import { string, func, bool } from 'prop-types';
import DropDown from '../../../icons/DropDown';
import Avatar from '../../../img/user--avatar.svg';
import AvatarCommunity from '../../../img/avatar_orange.svg';
import { onAccessibleClick } from '../../utils/accessibility';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import AccountOptionsDropdown from './AccountOptionsDropdown';
import { AccountOptionsDiv, IconDiv } from './styledComponents';

const AccountOptions = ({ name, noNav, signOut }) => {
  const isCommunityEditionTenant = useIsCommunityEditionTenant();
  const [isOpen, setOpen] = useState(false);
  const toggleMenu = useCallback(
    (event) => {
      return onAccessibleClick(event, () => {
        setOpen(!isOpen);
      });
    },
    [isOpen],
  );
  const accountOptionsRef = useRef(null);
  const [dropDownWidth, setDropDownWidth] = useState('200px');
  useEffect(() => {
    if (accountOptionsRef.current) {
      const width = accountOptionsRef.current.offsetWidth;
      setDropDownWidth(`${width}px`);
    }
  }, [accountOptionsRef]);

  const handleClick = useCallback(
    (e) => {
      if (accountOptionsRef.current) {
        if (!accountOptionsRef.current.contains(e.target)) {
          setOpen(false);
        }
      }
    },
    [accountOptionsRef],
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);
  return (
    <AccountOptionsDiv
      className={isOpen ? 'open' : undefined}
      tabIndex='0'
      role='button'
      onClick={toggleMenu}
      onKeyDown={toggleMenu}
      style={{ '--account-options-dropdown-width': dropDownWidth }}
      ref={accountOptionsRef}
    >
      <img
        src={isCommunityEditionTenant ? AvatarCommunity : Avatar}
        alt='avatar'
      />
      <div className='name'>{name}</div>
      <IconDiv className='small dropdown'>
        <DropDown />
      </IconDiv>
      {isOpen && <AccountOptionsDropdown noNav={noNav} signOut={signOut} />}
    </AccountOptionsDiv>
  );
};

AccountOptions.propTypes = {
  name: string.isRequired,
  signOut: func.isRequired,
  noNav: bool.isRequired,
};

export default AccountOptions;
