import { useEffect } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router';
import RenderModal from './RenderModal';
import Modal from './Modal';

export default function UseCustomizePrompt({ when, message }) {
  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      RenderModal(
        <Modal
          onSubmit={() => {
            setTimeout(blocker.proceed, 0);
          }}
          onCancel={() => {
            blocker.reset();
          }}
          confirmMessage={message}
        />,
      );
    }
  }, [blocker, message]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
    }
  }, [blocker, when]);
}
