import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool, string } from 'prop-types';
import PasswordInput from './PasswordInput';

const display = {
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordRequiredError: 'Password is required',
  matchPasswordsError: 'Passwords should match',
};

const ConfirmPasswordInput = ({ disabled, name, passwordFieldName }) => {
  const { getValues } = useFormContext();
  const matchPasswords = useCallback(
    (value) => {
      const password = getValues(passwordFieldName);
      return password === value || display.matchPasswordsError;
    },
    [getValues, passwordFieldName],
  );

  return (
    <PasswordInput
      name={name}
      label={display.confirmPasswordLabel}
      disabled={disabled}
      validationRules={{
        required: display.confirmPasswordRequiredError,
        validate: { matchPasswords },
      }}
      isPrivate
    />
  );
};

ConfirmPasswordInput.propTypes = {
  disabled: bool.isRequired,
  name: string.isRequired,
  passwordFieldName: string.isRequired,
};

export default ConfirmPasswordInput;
