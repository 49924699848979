import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import encryptData from '../../utils/encryptData';
import * as AuthorizationServiceUpdateActions from '../AuthorizationServiceUpdate/actions';
import * as AuthorizationServiceUpdateActionTypes from '../AuthorizationServiceUpdate/actionTypes';

const keyId = process.env.REACT_APP_KMS_BROWSER_ENCRYPT_KEY_ALIAS;
const encryptionAlgorithm = 'RSAES_OAEP_SHA_256';

function* cancelableCreateOrUpdateAuthorizationService(action) {
  const newBody = { ...action.payload.body };
  const { type, connection } = newBody;

  if (type === 'rangerhive') {
    if (connection.password) {
      const encryptedAdminPassword = yield call(
        encryptData,
        connection.password,
        keyId,
        encryptionAlgorithm,
      );
      newBody.connection = { ...newBody.connection };
      newBody.connection.password = encryptedAdminPassword;
    }

    if (connection.keystore && connection.keystorepassword) {
      const encryptedKeystorePassword = yield call(
        encryptData,
        connection.keystorepassword,
        keyId,
        encryptionAlgorithm,
      );
      newBody.connection = { ...newBody.connection };
      newBody.connection.keystorepassword = encryptedKeystorePassword;
    }

    if (connection.truststore && connection.truststorepassword) {
      const encryptedTruststorePassword = yield call(
        encryptData,
        connection.truststorepassword,
        keyId,
        encryptionAlgorithm,
      );
      newBody.connection = { ...newBody.connection };
      newBody.connection.truststorepassword = encryptedTruststorePassword;
    }
  }

  if (action.type === actionTypes.submit) {
    yield put(actions.post(newBody));
  }

  if (action.type === AuthorizationServiceUpdateActionTypes.update) {
    const { authorizationServiceId } = action.payload;

    yield put(
      AuthorizationServiceUpdateActions.put(authorizationServiceId, newBody),
    );
  }
}

export default function* authorizationServiceAddRootSaga() {
  yield all([
    takeEvery(actionTypes.submit, cancelableCreateOrUpdateAuthorizationService),
    takeEvery(
      AuthorizationServiceUpdateActionTypes.update,
      cancelableCreateOrUpdateAuthorizationService,
    ),
  ]);
}
