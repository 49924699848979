import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Want to add or remove Presto users to your Presto cluster?
      </Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Check the box in the &quot;Selected&quot; column to add a Presto user
          to the cluster.
        </Help.ListItem>
        <Help.ListItem>
          To remove a Presto user from the cluster, uncheck the
          &quot;Selected&quot; box.
        </Help.ListItem>
        <Help.ListItem>
          When you click &quot;Modify Cluster&quot;, the cluster will be updated
          with the set of selected Presto users.
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        If you do not see the Presto user you are looking for, you can create a
        new one.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
