import { string } from 'prop-types';
import { HttpErrorsDiv } from '../styledComponents';
import image from './notfound.svg';

const NotFound = ({ title, code, description }) => {
  return (
    <HttpErrorsDiv>
      <div className='content'>
        <p className='error-code'>{code || 'Error 404'}</p>
        <h1 className='title'>{title || 'Resource not found'}</h1>
        <p className='description'>
          {description || 'The resource you are looking for was not found.'}
        </p>
      </div>
      <img className='error-image' src={image} alt='Resource not found' />
    </HttpErrorsDiv>
  );
};

NotFound.propTypes = {
  title: string,
  code: string,
  description: string,
};
NotFound.defaultProps = {
  title: undefined,
  code: undefined,
  description: undefined,
};

export default NotFound;
