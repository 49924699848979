import { NavLink, useLocation } from 'react-router-dom';
import { string, func, bool } from 'prop-types';
import routes from '../../../router/routes';
import AccountOptions from './AccountOptions';

const display = {
  home: 'Home',
  prestoClusters: 'Clusters',
  dataSources: 'Data Sources',
  security: 'Security',
  workspaces: 'Workspaces',
  integrations: 'Integrations',
};

const blurClicks = (event) => {
  if (event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) {
    event.currentTarget.blur();
  }
};

const Navigation = ({ name, serviceName, noNav, signOut }) => {
  const { search } = useLocation();
  return (
    <>
      {!noNav && (
        <div className='nav pages'>
          <NavLink
            to={{ pathname: routes.home.getRoute(), search }}
            className='nav_link'
            onClick={blurClicks}
          >
            {display.home}
          </NavLink>
          <NavLink
            to={{ pathname: routes.prestoClusters.getRoute(), search }}
            className='nav_link'
            onClick={blurClicks}
          >
            {display.prestoClusters}
          </NavLink>
          <NavLink
            to={{ pathname: routes.dataSources.getRoute(), search }}
            className='nav_link'
            onClick={blurClicks}
          >
            {display.dataSources}
          </NavLink>
          <NavLink
            to={{ pathname: routes.security.getRoute(), search }}
            className='nav_link'
            onClick={blurClicks}
          >
            {display.security}
          </NavLink>
          {/* <NavLink
            to={routes.workspaces.getRoute()}
            className='nav_link'
            onClick={blurClicks}
          >
            {display.workspaces}
          </NavLink>
          <NavLink
            to={routes.integrations.getRoute()}
            className='nav_link'
            onClick={blurClicks}
          >
            {display.integrations}
          </NavLink> */}
        </div>
      )}
      <div className='nav'>
        {serviceName && <div className='nav_service_name'>{serviceName}</div>}
        <AccountOptions name={name} noNav={noNav} signOut={signOut} />
      </div>
    </>
  );
};

Navigation.defaultProps = {
  serviceName: undefined,
  noNav: false,
};

Navigation.propTypes = {
  name: string.isRequired,
  serviceName: string,
  noNav: bool,
  signOut: func.isRequired,
};

export default Navigation;
