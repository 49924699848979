export const getVerifyRoleInProgress = (state) => {
  return state.computePlaneAdd.verifyRoleInProgress;
};

export const getVerifyRoleError = (state) => {
  return state.computePlaneAdd.verifyRoleError;
};

export const getHasVerifyRoleSuccess = (state) => {
  return state.computePlaneAdd.hasVerifyRoleSuccess;
};

export const getSubmitInProgress = (state) => {
  return state.computePlaneAdd.submitInProgress;
};

export const getDestroyInProgress = (state) => {
  return state.computePlaneAdd.destroyInProgress;
};
