import { makeReducer } from '../../utils/reducer';
import * as actionTypes from './actionTypes';
import auth from '../../auth';

const initialState = {
  tenant: null,
  contract: null,
  clusters: null,
  error: null,
  tenantLoading: false,
  contractLoading: false,
  clustersLoading: false,
};

const errorMessage =
  'There was a problem retrieving some of your cost management data. Please try again.';

const onNavigation = (draft) => {
  draft.error = null;
  draft.tenantLoading = false;
  draft.contractLoading = false;
  draft.clustersLoading = false;
  draft.clusters = null;
};

const getTenantSummaryStart = (draft) => {
  draft.tenantLoading = true;
};

const getTenantSummary = (draft, action) => {
  draft.tenant = action.payload.apiResult.body;
  draft.tenantLoading = false;
};

const getTenantSummaryFailure = (draft) => {
  draft.error = errorMessage;
  draft.tenantLoading = false;
};

const getClustersStart = (draft) => {
  draft.clustersLoading = true;
};

const getClusters = (draft, action) => {
  draft.clusters = action.payload.apiResult.body.results;
  draft.clustersLoading = false;
};

const getClustersFailure = (draft) => {
  draft.error = errorMessage;
  draft.clustersLoading = false;
};

const getContractStart = (draft) => {
  draft.contractLoading = true;
};

const getContract = (draft, action) => {
  draft.contract = action.payload.apiResult.body;
  draft.contractLoading = false;
};

const getContractFailure = (draft) => {
  draft.error = errorMessage;
  draft.contractLoading = false;
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.getTenantSummary.request]: getTenantSummaryStart,
  [actionTypes.getTenantSummary.success]: getTenantSummary,
  [actionTypes.getTenantSummary.failure]: getTenantSummaryFailure,
  [actionTypes.getClusters.request]: getClustersStart,
  [actionTypes.getClusters.success]: getClusters,
  [actionTypes.getClusters.failure]: getClustersFailure,
  [actionTypes.getContract.request]: getContractStart,
  [actionTypes.getContract.success]: getContract,
  [actionTypes.getContract.failure]: getContractFailure,
  [auth.actionTypes.signOut]: signOut,
};

export default makeReducer(initialState, actionReducers);
