import React from 'react';
import { createRoot } from 'react-dom/client';

export default function RenderModal(modal) {
  const el = document.createElement('div');
  document.body.appendChild(el);
  const root = createRoot(el);
  const unmount = () => {
    root.unmount();
    document.body.removeChild(el);
  };

  const clonedElement = React.cloneElement(modal, { unmount });
  root.render(clonedElement);
}
