import { connect } from '../../utils/redux';
import DataSources from '../DataSources';
import { DataSource } from './components';

const mapStateToProps = (state, props) => {
  return {
    dataSourceId: DataSources.selectors.getDataSourceId(state, props),
    dataSource: DataSources.selectors.getDataSource(state, props),
    notFound: DataSources.selectors.getIsNotFound(state, props),
    actionStatus: DataSources.selectors.getActionStatus(state, props),
    usePop: DataSources.selectors.getUsePop(state),
    prestoClusters: DataSources.selectors.getPrestoClusters(state, props),
    canModify: DataSources.selectors.getCanModify(state, props),
  };
};

export default connect(mapStateToProps, {
  loadDataSource: DataSources.actions.get,
  deleteDataSource: DataSources.actions.remove,
  loadPrestoClusters: DataSources.actions.getPrestoClusters,
})(DataSource);
