import { Fragment, useMemo } from 'react';
import get from 'lodash.get';
import { useFormContext } from 'react-hook-form';
import {
  string,
  func,
  bool,
  object,
  arrayOf,
  shape,
  element,
  oneOfType,
  elementType,
} from 'prop-types';
import Help from '../Help';
import {
  InputErrorDiv,
  RadioInputGroupLabel,
  RadioInputGroup,
  RadioLabel,
  RadioInput,
} from './styledComponents';

const CompleteRadioInputs = ({
  name,
  label,
  values,
  className,
  disabled,
  defaultValue,
  validationRules,
  isPrivate,
  fieldHelp,
  hidden,
}) => {
  const {
    register: formRegister,
    formState: { errors: formErrors },
    getFieldId,
  } = useFormContext();
  const inputId = getFieldId(name);

  const error = get(formErrors, name);

  const radioGroupClassName = useMemo(() => {
    let c = '';
    if (className) {
      c = `${c} ${className}`;
    }
    if (hidden) {
      c = `${c} hidden`;
    }

    if (formErrors[name]) {
      c = `${c} error`;
    }

    return c === '' ? undefined : c;
  }, [className, formErrors, hidden, name]);

  return (
    <>
      {label && !hidden && (
        <RadioInputGroupLabel>
          <span className={disabled ? 'disabled' : undefined}>{label}</span>
          <Help.Button fieldHelp={fieldHelp} />
        </RadioInputGroupLabel>
      )}
      <RadioInputGroup className={radioGroupClassName}>
        {values.map((value) => {
          const Component = value.component;
          return (
            <Fragment key={value.value}>
              <RadioInput
                type='radio'
                disabled={disabled || value.disabled}
                defaultChecked={defaultValue === value.value}
                value={value.value}
                aria-invalid={error ? 'true' : 'false'}
                id={`${inputId}-${value.value}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...formRegister(name, validationRules)}
              />
              {Component ? (
                <RadioLabel
                  htmlFor={`${inputId}-${value.value}`}
                  disabled={disabled || value.disabled}
                  data-private={isPrivate ? true : undefined}
                >
                  <Component />
                </RadioLabel>
              ) : (
                <RadioLabel
                  htmlFor={`${inputId}-${value.value}`}
                  disabled={disabled || value.disabled}
                  data-private={isPrivate ? true : undefined}
                >
                  {value.label}
                </RadioLabel>
              )}
            </Fragment>
          );
        })}
      </RadioInputGroup>
      {error && !hidden && (
        <InputErrorDiv role='alert'>{error.message}</InputErrorDiv>
      )}
    </>
  );
};

CompleteRadioInputs.defaultProps = {
  label: undefined,
  disabled: false,
  defaultValue: undefined,
  validationRules: undefined,
  className: '',
  isPrivate: false,
  fieldHelp: undefined,
  hidden: false,
};

CompleteRadioInputs.propTypes = {
  name: string.isRequired,
  label: string,
  values: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
      component: oneOfType([element, func]),
    }),
  ).isRequired,
  className: string,
  disabled: bool,
  defaultValue: string,
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  isPrivate: bool,
  fieldHelp: elementType,
  hidden: bool,
};

export default CompleteRadioInputs;
