export const getManagePrestoUserRoute = (prestoUserId) => {
  return `/prestousers/${prestoUserId}`;
};

export const getUpdatePrestoUserRoute = (prestoUserId) => {
  return `/prestousers/${prestoUserId}/update`;
};

export const getRoute = () => {
  return '/security';
};
