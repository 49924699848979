export const instanceTypes = [
  { value: 't3.xlarge', display: 't3.xlarge' },
  { value: 't3.2xlarge', display: 't3.2xlarge' },
  { value: 'm7i.4xlarge', display: 'm7i.4xlarge' },
];

export const nonProdInstanceTypes = [
  { value: 't3.medium', display: 't3.medium' },
  ...instanceTypes,
];
