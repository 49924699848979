import { useCallback, useEffect } from 'react';
import { func, string, bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Page from '../../Page';
import Form from '../../Form';
import useRouteBuilder from '../../../router/useRouteBuilder';
import UpdateIdentityProviderForm from './UpdateIdentityProviderForm';
import IdentityProviders from '../../IdentityProviders';

const UpdateIdentityProvider = ({
  submitInProgress,
  submitSuccess,
  usePop,
  error,
  update,
  identityProviderId,
  loadIdentityProvider,
  identityProvider,
  notFound,
}) => {
  useEffect(() => {
    loadIdentityProvider(identityProviderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityProviderId]);

  const submitForm = useCallback(
    (data) => {
      update(identityProviderId, data);
    },
    [update, identityProviderId],
  );

  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!identityProvider || notFound) {
    return <Page.Loading />;
  }

  return (
    <Page.PageWithOverflow>
      <Form.Form name='update-identity-provider-form' submitAction={submitForm}>
        <UpdateIdentityProviderForm
          identityProvider={identityProvider}
          submitInProgress={submitInProgress}
          submitSuccess={submitSuccess}
          usePop={usePop}
          error={error}
        />
      </Form.Form>
    </Page.PageWithOverflow>
  );
};

UpdateIdentityProvider.defaultProps = {
  error: null,
  notFound: false,
  identityProvider: null,
  submitSuccess: false,
  usePop: true,
};

UpdateIdentityProvider.propTypes = {
  error: string,
  identityProvider: IdentityProviders.propTypes.IdentityProvider,
  identityProviderId: string.isRequired,
  loadIdentityProvider: func.isRequired,
  notFound: bool,
  submitInProgress: bool.isRequired,
  submitSuccess: bool,
  update: func.isRequired,
  usePop: bool,
};

export default UpdateIdentityProvider;
