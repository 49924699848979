import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Need to update your identity provider details?
      </Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Change any of the information about your identity provider. Only the
          Name and Client Secret can be changed.
        </Help.ListItem>
        <Help.ListItem>
          When you click “Edit Identity Provider” the identity provider
          integration will be updated with the new information. The change takes
          effect immediately.
        </Help.ListItem>
      </Help.OrderedList>
    </Help.HelpSection>
  );
};

export default PageHelp;
