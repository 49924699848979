import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool } from 'prop-types';
import Page from '../../Page';
import Form from '../../Form';
import PrestoUsers from '../../PrestoUsers';
import validatePasswordComplexity from '../../utils/validatePasswordComplexity';
import CredentialsHelp from './CredentialsHelp';

const display = {
  title: 'Credentials',
  formContentHeader: 'Settings',
  usernameLabel: 'Username',
  passwordLabel: 'Password',
  passwordConfirmLabel: 'Confirm Password',
  passwordRequiredError: 'Password is required',
  minLengthError: 'Password must be at least 8 characters',
  maxLengthError: 'Password cannot be longer than 64 characters',
};

const Credentials = ({ prestoUser, disabled }) => {
  const { getValues } = useFormContext();
  const validateComplexity = useCallback(
    (value) => {
      const username = getValues('username');
      return validatePasswordComplexity(value, username);
    },
    [getValues],
  );
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      <Page.FormContent rowCount={2}>
        <div>
          <h3>{display.formContentHeader}</h3>
        </div>
        <div>
          <Form.HiddenInput name='type' value='file' />
          <Form.HiddenInput
            name='prestoUserId'
            value={prestoUser.prestoUserId}
          />
          <Form.TextInput
            name='username'
            type='string'
            label={display.usernameLabel}
            defaultValue={prestoUser.username}
            disabled={disabled}
            validationRules={{
              required: display.usernameRequiredError,
              maxLength: {
                value: 255,
                message: display.usernameMaxLengthError,
              },
              pattern: {
                value: /^[a-zA-Z0-9\-_.~]*$/,
                message: display.usernameInvalidCharacters,
              },
            }}
            fieldHelp={CredentialsHelp.Username}
          />
          <Form.PasswordInput
            name='password'
            label={display.passwordLabel}
            disabled={disabled}
            validationRules={{
              required: display.passwordRequiredError,
              minLength: { value: 8, message: display.minLengthError },
              maxLength: { value: 64, message: display.maxLengthError },
              validate: { validateComplexity },
            }}
            fieldHelp={CredentialsHelp.Password}
          />
          <Form.ConfirmPasswordInput
            name='password2'
            disabled={disabled}
            passwordFieldName='password'
          />
        </div>
      </Page.FormContent>
    </Page.Box>
  );
};

Credentials.defaultProps = {
  disabled: false,
};

Credentials.propTypes = {
  prestoUser: PrestoUsers.propTypes.PrestoUser.isRequired,
  disabled: bool,
};

export default Credentials;
