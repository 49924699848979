import { useMemo, useCallback } from 'react';
import { func, arrayOf } from 'prop-types';
import Banner from '../../Banner';
import Dismissible from '../../Dismissible';
import * as propTypes from '../propTypes';

const BucketErrorMessage = ({ data, onDismiss }) => {
  const message = useMemo(() => {
    if (!data || data.length === 0) return null;
    return data[0];
  }, [data]);

  const onClick = useCallback(() => {
    return onDismiss(message.key);
  }, [onDismiss, message]);

  if (!message) {
    return null;
  }

  return (
    <Banner
      title={message.title}
      details={message.data}
      scrollIntoView
      showCloseIcon
      onDismiss={onClick}
    />
  );
};

BucketErrorMessage.defaultProps = {
  data: null,
  onDismiss: null,
};

BucketErrorMessage.propTypes = {
  data: arrayOf(propTypes.ErrorMessage),
  onDismiss: func,
};

const BucketError = ({ messages }) => {
  return (
    <Dismissible componentName='BucketError' data={messages}>
      <BucketErrorMessage />
    </Dismissible>
  );
};

BucketError.defaultProps = {
  messages: null,
};

BucketError.propTypes = {
  messages: arrayOf(propTypes.ErrorMessage),
};

export default BucketError;
