import { useEffect, useMemo } from 'react';
import { func, string, bool } from 'prop-types';
import Page from '../../Page';
import { InformationGridDiv } from '../../Page/styledComponents';
import { Tenant, Contract } from '../propTypes';
import Banner from '../../Banner';
import { LoadingDiv } from './styledComponents';
import Loading from '../../Loading';

const display = {
  title: 'Subscription Summary',
  subscriptionStartDateLabel: 'Subscription Start Date',
  subscriptionEndDateLabel: 'Subscription End Date',
  initialCreditsLabel: 'Initial IBM watsonx.data Compute Credits Balance',
  ahanaCreditsUsedLabel: 'IBM Compute Credits Used To Date',
  creditsUsedLabel: 'Credits Used To Date',
  creditsRemainingLabel: 'Credits Remaining',
};

const SubscriptionSummary = ({
  navTarget,
  tenant,
  tenantId,
  loadContract,
  contract,
  error,
  isLoading,
  loadTenantSummary,
}) => {
  useEffect(() => {
    if (!tenant) {
      loadTenantSummary(tenantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  const isContract = useMemo(() => {
    return !!tenant?.contractPublicId;
  }, [tenant]);

  useEffect(() => {
    if (tenant && tenant.contractPublicId && !contract) {
      loadContract(tenant.contractPublicId);
    }
  }, [tenant, loadContract, contract]);

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      {isLoading && (
        <LoadingDiv className='top-padding'>
          <Loading.Loading />
        </LoadingDiv>
      )}
      {!isLoading && tenant && isContract && contract && (
        <>
          <InformationGridDiv style={{ '--grid-columns': 2 }}>
            <div className='cell'>
              <div className='title'>{display.subscriptionStartDateLabel}</div>
              <div className='data'>{contract.createDateDisplay}</div>
            </div>
            <div className='cell'>
              <div className='title'>{display.subscriptionEndDateLabel}</div>
              <div className='data'>{contract.endDateDisplay}</div>
            </div>
          </InformationGridDiv>
          <InformationGridDiv style={{ '--grid-columns': 3 }}>
            <div className='cell'>
              <div className='title'>{display.initialCreditsLabel}</div>
              <div className='data'>{contract.purchasedCreditsDisplay}</div>
            </div>
            <div className='cell'>
              <div className='title'>{display.creditsUsedLabel}</div>
              <div className='data'>{contract.billedCreditsDisplay}</div>
            </div>
            <div className='cell'>
              <div className='title'>{display.creditsRemainingLabel}</div>
              <div className='data'>{contract.remainingCreditsDisplay}</div>
            </div>
          </InformationGridDiv>
        </>
      )}
      {!isLoading && tenant && !isContract && (
        <InformationGridDiv style={{ '--grid-columns': 2 }}>
          <div className='cell'>
            <div className='title'>{display.subscriptionStartDateLabel}</div>
            <div className='data'>{tenant.createDateDisplay}</div>
          </div>
          <div className='cell'>
            <div className='title'>{display.ahanaCreditsUsedLabel}</div>
            <div className='data'>
              {tenant.cumulativeConsumedCreditsDisplay}
            </div>
          </div>
        </InformationGridDiv>
      )}
    </Page.BoxNavTarget>
  );
};

SubscriptionSummary.defaultProps = {
  tenant: null,
  contract: null,
  error: null,
};

SubscriptionSummary.propTypes = {
  navTarget: string.isRequired,
  tenant: Tenant,
  contract: Contract,
  loadContract: func.isRequired,
  error: string,
  isLoading: bool.isRequired,
  loadTenantSummary: func.isRequired,
  tenantId: string.isRequired,
};

export default SubscriptionSummary;
