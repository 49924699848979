export const isAuthenticated = (state) => {
  return state.auth.jwtToken !== null;
};

export const getJWTToken = (state) => {
  return state.auth.jwtToken;
};

export const getIssuer = (state) => {
  return state.auth.issuer;
};

export const getName = (state) => {
  return state.auth.name;
};

export const isReady = (state) => {
  return state.auth.finishedInitialCheck;
};

export const getEmailAddress = (state) => {
  return state.auth.emailAddress;
};

export const getCRN = (state) => {
  const searchParams = new URLSearchParams(
    state.currentRoute.search ?? undefined,
  );
  return searchParams.get('crn');
};

export const isJWTExpired = (state) => {
  return (
    state.auth.expirationTimestamp === null ||
    state.auth.expirationTimestamp < Date.now()
  );
};

export const getTenantId = (state) => {
  return state.auth.tenantId;
};

export const getError = (state) => {
  return state.auth.error;
};
