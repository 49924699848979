import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Ready to add a data source?</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>Select the type of connector.</Help.ListItem>
        <Help.ListItem>
          Provide the connection details for your data store. Make sure to read
          the additional documentation for that connection type to understand
          any additional permissions configuration that maybe required.
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        This will create a data source that can be attached to Presto clusters
        in your compute plane. We recommend the use of data sources that are in
        the same AWS region for both data transfer cost and performance
        optimization purposes.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
