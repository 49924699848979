import Help from '../../Help';

const Type = (isCommunityEdition) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Scaling Strategy</Help.HelpHeader>
        {isCommunityEdition && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}

        <Help.P>
          You can configure your Presto cluster by selecting one of the
          following scaling strategies.
        </Help.P>

        <Help.P>
          The <Help.Strong>Static</Help.Strong> strategy means that the number
          of worker nodes will stay constant while the cluster is in the active
          state.
        </Help.P>

        <Help.P>
          The <Help.Strong>Auto Scaling</Help.Strong> strategy means that the
          number of worker nodes will automatically increase or decrease in
          response to the scaling type below.
        </Help.P>
      </Help.HelpSection>
    );
  };
};

const DefaultWorkerNodes = (maxWorkerNodeCount) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Default Worker Node Count</Help.HelpHeader>
        <Help.P>
          A Presto worker is responsible for executing tasks and processing
          data. Worker nodes fetch data from connectors and exchange
          intermediate data with each other. The coordinator is responsible for
          fetching results from the workers and returning the final results to
          the client.
        </Help.P>
        <Help.P>
          Enter the number of worker nodes you want in the Presto cluster. Pick
          a number between 1 and {maxWorkerNodeCount}.
        </Help.P>
        <Help.P>
          The coordinator node is not included in this worker node count.
        </Help.P>
      </Help.HelpSection>
    );
  };
};

const EnableIdleCostSavings = (isCommunityEdition) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>
          Scale to a Single Worker Node when Idle
        </Help.HelpHeader>
        {isCommunityEdition && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
        <Help.P>
          To control costs, you can enable scaling down to a single worker node
          when idle.
        </Help.P>

        <Help.P>
          Idle is defined as not receiving any query during the time window
          specified below.
        </Help.P>

        <Help.P>
          When a cluster is scaled down to a single worker node due to being
          idle, it is in the idle state. If the cluster receives a query while
          in the idle state, it will automatically scale back out to the{' '}
          <Help.Strong>Default Worker Node Count</Help.Strong>.
        </Help.P>
      </Help.HelpSection>
    );
  };
};

const IdleCostSavingsIdleTime = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Time Window Before Scaling to a Single Worker Node
      </Help.HelpHeader>

      <Help.P>
        The time window over which if no queries are received the cluster is
        considered idle.
      </Help.P>
    </Help.HelpSection>
  );
};

const MinimumWorkerNodes = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Minimum Worker Node Count</Help.HelpHeader>
      <Help.P>
        The minimum number of worker nodes that this cluster can have.
      </Help.P>
      <Help.P>
        A Presto worker is responsible for executing tasks and processing data.
        Worker nodes fetch data from connectors and exchange intermediate data
        with each other. The coordinator is responsible for fetching results
        from the workers and returning the final results to the client.
      </Help.P>
      <Help.P>
        The coordinator node is not included in this worker node count.
      </Help.P>
    </Help.HelpSection>
  );
};

const MaximumWorkerNodes = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Maximum Worker Node Count</Help.HelpHeader>
      <Help.P>
        The maximum number of worker nodes that this cluster can scale out to.
      </Help.P>
      <Help.P>
        A Presto worker is responsible for executing tasks and processing data.
        Worker nodes fetch data from connectors and exchange intermediate data
        with each other. The coordinator is responsible for fetching results
        from the workers and returning the final results to the client.
      </Help.P>
      <Help.P>
        The coordinator node is not included in this worker node count.
      </Help.P>
    </Help.HelpSection>
  );
};

const CPUScaleInStepSize = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Scaling Step Size</Help.HelpHeader>
      <Help.P>
        The increment of worker nodes the cluster will increase or decrease by
        during auto scaling.
      </Help.P>
    </Help.HelpSection>
  );
};

const CPUScaleInTimeWindow = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Scale In Time Window</Help.HelpHeader>
      <Help.P>
        For CPU type auto scaling, if the CPU utilization of the cluster is
        below the target for this time window, the number of worker nodes will
        decrease by the
        <Help.Strong> Scaling Step Size </Help.Strong>until utilization is above
        the target or the minimum worker count is reached.
      </Help.P>

      <Help.P>
        For Queued Queries auto scaling, if the queued query count is below the
        target for this time window, the number of worker nodes will decrease by
        the
        <Help.Strong> Scaling Step Size </Help.Strong>until count is above the
        target or the minimum worker count is reached.
      </Help.P>
    </Help.HelpSection>
  );
};

const QueryTerminationGracePeriod = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Query Termination Grace Period</Help.HelpHeader>
      <Help.P>
        Reducing Presto workers on a cluster will gracefully shutdown workers so
        that any running queries will not fail due to the scale in. The query
        termination grace period is the maximum time window that is allowed for
        existing query tasks to complete on Presto workers before forcefully
        terminating those workers.
      </Help.P>
    </Help.HelpSection>
  );
};

const AutoScalingType = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Auto Scaling Type</Help.HelpHeader>
      <Help.P>
        Presto worker nodes will automatically scale based on the metrics you
        selected.
      </Help.P>
      <Help.P>
        The <Help.Strong>CPU</Help.Strong> strategy means that the number of
        worker nodes will automatically increase and decrease in response to
        cluster CPU usage.
      </Help.P>

      <Help.P>
        If the average CPU utilization exceeds the{' '}
        <Help.Strong>target</Help.Strong>, the number of worker nodes will
        increase by up to the <Help.Strong>Scaling Step Size</Help.Strong>.
      </Help.P>
      <Help.P>
        If the average CPU utilization is below the{' '}
        <Help.Strong>target</Help.Strong> for the{' '}
        <Help.Strong>Scale In Time Window</Help.Strong>, the number of worker
        nodes will decrease by the <Help.Strong>Scaling Step Size</Help.Strong>.
      </Help.P>

      <Help.P>
        The <Help.Strong>Queued Queries</Help.Strong> strategy means that the
        number of worker nodes will automatically increase or decrease in
        response to cluster query queue depth.
      </Help.P>

      <Help.P>
        If the number of Queued Queries per node exceeds the{' '}
        <Help.Strong>target</Help.Strong>, the number of worker nodes will
        increase by up to the <Help.Strong>Scaling Step Size</Help.Strong>.
      </Help.P>
      <Help.P>
        If the number of Queued Queries per node is below the{' '}
        <Help.Strong>target</Help.Strong> for the{' '}
        <Help.Strong>Scale In Time Window</Help.Strong>, the number of worker
        nodes will decrease by the <Help.Strong>Scaling Step Size</Help.Strong>.
      </Help.P>
    </Help.HelpSection>
  );
};

const CPUTarget = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Auto Scaling Target</Help.HelpHeader>
      <Help.P>
        This is the threshold that the number of worker nodes will automatically
        increase or decrease.
      </Help.P>

      <Help.P>
        CPU target is a number between 0 and 100 representing the{' '}
        <Help.Strong>percentage</Help.Strong> of average CPU usage.
      </Help.P>
    </Help.HelpSection>
  );
};

const QueuedQueriesTarget = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Auto Scaling Target</Help.HelpHeader>
      <Help.P>
        This is the threshold that the number of worker nodes will automatically
        increase or decrease.
      </Help.P>

      <Help.P>
        Queued Queries target is a number between 0 and 10000 representing the{' '}
        <Help.Strong>count</Help.Strong> of the queries in queue per worker
        node.
      </Help.P>
    </Help.HelpSection>
  );
};

const AutoScalingTypeView = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Auto Scaling Type</Help.HelpHeader>
      <Help.P>
        This is the auto scaling strategy you choose. Auto scaling type cannot
        change once cluster is created.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  Type,
  DefaultWorkerNodes,
  EnableIdleCostSavings,
  IdleCostSavingsIdleTime,
  MinimumWorkerNodes,
  MaximumWorkerNodes,
  QueryTerminationGracePeriod,
  CPUScaleInStepSize,
  CPUScaleInTimeWindow,
  AutoScalingType,
  CPUTarget,
  QueuedQueriesTarget,
  AutoScalingTypeView,
};

export default module;
