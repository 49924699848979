import { apiVerbs, createApiActionType } from '../../rayApi/actionTypes';

const componentName = 'STANDALONE_PROMETHEUS';

export const deletePrometheus = createApiActionType(
  apiVerbs.DELETE,
  componentName,
);

export const patchPrometheusJMX = createApiActionType(
  apiVerbs.PATCH,
  componentName,
);
