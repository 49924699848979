import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const submit = (authorizationServiceId, values) => {
  const body = {
    name: values.name,
    description: values.description,
    type: values.authorizationServiceType,
    connection: values[values.authorizationServiceType],
  };

  return {
    type: actionTypes.update,
    payload: {
      authorizationServiceId,
      body,
    },
  };
};

export const put = (authorizationServiceId, body) => {
  return createApiAction(
    actionTypes.put,
    api.put,
    true,
    { authorizationServiceId },
    body,
  );
};
