const fontFamily = {
  // eslint-disable-next-line quotes
  IBMPlex: "font-family: 'IBM Plex Sans', sans-serif;",
};

const fontWeight = {
  regular: 'font-weight: 400;',
  semiBold: 'font-weight: 600;',
  bold: 'font-weight: 700;',
  extraBold: 'font-weight: 800;',
};

const fontSize = {
  small: 'font-size: 12px;',
  medium_small: 'font-size: 14px;',
  medium: 'font-size: 16px;',
  large: 'font-size: 20px;',
  extraLarge: 'font-size: 32px;',
};

const lineHeight = {
  small: 'line-height: 17px;',
  medium_small: 'line-height: 19px;',
  medium: 'line-height: 22px;',
  large: 'line-height: 27px;',
  extraLarge: 'line-height: 44px;',
};

export const fonts = {
  /** 12px semi-bold */
  small: `${fontFamily.IBMPlex}
  ${fontWeight.semiBold}
  ${fontSize.small}
  ${lineHeight.small}
  `,
  /** 14px regular */
  medium_small: `${fontFamily.IBMPlex}
  ${fontWeight.regular}
  ${fontSize.medium_small}
  ${lineHeight.medium_small}
  `,
  /** 14px regular */
  medium_small_bold: `${fontFamily.IBMPlex}
  ${fontWeight.bold}
  ${fontSize.medium_small}
  ${lineHeight.medium_small}
  `,
  /** 16px regular */
  medium: `${fontFamily.IBMPlex}
  ${fontWeight.regular}
  ${fontSize.medium}
  ${lineHeight.medium}
  `,
  /** 16px semi-bold */
  medium_semibold: `${fontFamily.IBMPlex}
  ${fontWeight.semiBold}
  ${fontSize.medium}
  ${lineHeight.medium}
  `,
  /** 16px bold */
  medium_bold: `${fontFamily.IBMPlex}
  ${fontWeight.bold}
  ${fontSize.medium}
  ${lineHeight.medium}
  `,
  /** 20px bold */
  large_bold: `${fontFamily.IBMPlex}
  ${fontWeight.bold}
  ${fontSize.large}
  ${lineHeight.large}
  `,
  /** 32px extra-bold */
  extra_large_extra_bold: `${fontFamily.IBMPlex}
  ${fontWeight.extraBold}
  ${fontSize.extraLarge}
  ${lineHeight.extraLarge}
  `,
};
