import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Ready to create a Presto cluster?</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>Pick a cluster name.</Help.ListItem>
        <Help.ListItem>Choose a Presto version.</Help.ListItem>
        <Help.ListItem>Select your concurrency profile.</Help.ListItem>
        <Help.ListItem>
          Select the number of workers and the AWS instance type you want
          provisioned.
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        This will create a Presto cluster with an optionally attached Hive
        Metastore catalog attached to an AWS S3 bucket.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
