import { createSelector } from 'reselect';
import ComputePlane from '../ComputePlane';

export const getErrors = (state) => {
  return state.dataSourceAdd.errors;
};

export const getSubmitInProgress = (state) => {
  return state.dataSourceAdd.submitInProgress;
};

export const getSubmitSuccess = (state) => {
  return state.dataSourceAdd.submitSuccess;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

const getSupportedAwsRegions = (state) => {
  return (
    state.computePlane.configuration &&
    state.computePlane.configuration.awsRegions
  );
};

export const getAwsRegions = createSelector(
  getSupportedAwsRegions,
  (awsRegions) => {
    if (!awsRegions) return [];
    return awsRegions.map((r) => {
      return {
        display: `${r.regionName} ${r.region}`,
        value: r.region,
      };
    });
  },
);

export const getComputePlaneRegion = (state) => {
  const computePlane = ComputePlane.selectors.getComputePlane(state);
  return computePlane.awsRegion;
};

export const getAwsGlueS3CloudFormationQuickCreateUrl = (state) => {
  return (
    state.dataSourceAdd.configuration &&
    state.dataSourceAdd.configuration.awsGlueS3CloudFormationQuickCreateUrl
  );
};

export const getAwsLakeFormationCloudFormationQuickCreateUrl = (state) => {
  return (
    state.dataSourceAdd.configuration &&
    state.dataSourceAdd.configuration
      .awsLakeFormationCloudFormationQuickCreateUrl
  );
};
