export const getManageAuthorizationServiceRoute = (authorizationServiceId) => {
  return `/authorizationservices/${authorizationServiceId}`;
};

export const getAddAuthorizationServiceRoute = () => {
  return '/authorizationservices/add';
};

export const getUpdateAuthorizationServiceRoute = (authorizationServiceId) => {
  return `/authorizationservices/${authorizationServiceId}/update`;
};
