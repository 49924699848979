import styled from 'styled-components/macro';
import { colors, fonts } from '../../css';
import { BannerDiv } from '../Banner';
import {
  CheckboxLabelDiv,
  TextInput,
  TextInputDiv,
  InputErrorDiv,
} from '../Form/styledComponents';

export const TableDiv = styled.div`
  color: ${colors.font_light};
  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);
  grid-template-rows: auto;
  position: relative;
`;

export const HeaderCellDiv = styled.div`
  ${fonts.medium_semibold}
  letter-spacing: 0.16px;
  padding: 16px 0 16px 24px;
  border-bottom: 1px solid ${colors.border};
  display: flex;
  justify-content: space-between;

  &.last {
    padding-right: 24px;
  }

  &.align-right {
    justify-content: end;
  }
`;

export const DataCellDiv = styled.div`
  ${fonts.medium}
  letter-spacing: 0.16px;
  padding: 21px 0 21px 24px;
  border-top: 1px solid ${colors.border};
  position: relative;
  display: flex;
  align-items: center;

  &.form-cell {
    flex-flow: column;
    align-items: normal;
  }

  &.last {
    padding-right: 24px;
  }

  &.justify-center {
    justify-content: center;
  }

  &.start-row {
    padding-left: 24px;
  }

  &.hover {
    background-color: ${colors.header_menu_focus};
    border-color: ${colors.header_menu_focus_border};

    &:before {
      content: '';
      position: absolute;
      border-top: 1px solid ${colors.header_menu_focus_border};
      top: -1px;
      left: 0;
      height: 1px;
      width: 100%;
    }
  }

  ${BannerDiv} {
    flex: 1 1 auto;
    margin: 0;
  }

  ${CheckboxLabelDiv} {
    min-height: unset;
  }

  ${TextInput} {
    margin-bottom: 0;
  }

  ${TextInputDiv} {
    flex: 1 1 0;
  }

  ${InputErrorDiv} {
    margin-bottom: 0;
  }

  &.alignment-flex-start {
    align-items: flex-start;
  }
`;

export const DataCellVerticalDiv = styled(DataCellDiv)`
  flex-direction: column;
  align-items: flex-start;

  &.align-right {
    align-items: flex-end;
  }

  &.padding-right {
    padding-right: 24px;
  }

  .grouped {
    padding-top: min(10px);
    padding-bottom: min(10px);
  }

  .grouped div:nth-child(1) {
    justify-content: flex-end;
  }

  .grouped div:nth-child(2) {
    justify-content: flex-start;
  }

  .medium-bold {
    ${fonts.medium_bold}
  }

  div {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
  }
`;

export const DataCenteredCellDiv = styled(DataCellDiv)`
  justify-content: center;
  padding: 21px 0;
`;

export const ImageCellDiv = styled(DataCellDiv)`
  justify-content: center;
  padding: 0;

  > img {
    max-height: 50px;
  }
`;

export const ButtonCellDiv = styled(DataCellDiv)`
  padding: 14px 24px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  background-color: transparent;
`;

export const DataRowDiv = styled.div`
  display: contents;

  &:hover > ${DataCellDiv} {
    background: ${colors.header_menu_focus};
    border-top: 1px solid ${colors.header_menu_focus_border};
    /* border-bottom: 1px solid ${colors.header_menu_focus_border}; */
  }

  &:hover + div > ${DataCellDiv} {
    border-top: 1px solid ${colors.header_menu_focus_border};
  }
`;
