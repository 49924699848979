export const getSubmitInProgress = (state) => {
  return state.identityProviderAdd.submitInProgress;
};

export const getSubmitSuccess = (state) => {
  return state.identityProviderAdd.submitSuccess;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getError = (state) => {
  return state.identityProviderAdd.error;
};
