import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>How to change my Scaling Policy?</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Enter a <Help.Strong>Default Worker Node Count</Help.Strong>
        </Help.ListItem>
        <Help.ListItem>
          Enter the time window where the cluster needs to be idle before
          scaling to a single worker node
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        When the cluster does not receive any queries during the specified time
        window, it will scale to a single worker node.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
