import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';

export const patch = (rootUri, headers, params, body, abortFetchSignal) => {
  const uri = buildUrl({
    baseUrl: rootUri,
    path: `identityproviders/${params.identityProviderId}`,
  });
  const prepedBody = rayApi.api.buildBody(body);
  return rayApi.api.executePATCH(uri, headers, prepedBody, abortFetchSignal);
};
