import { shape, string } from 'prop-types';

export const IdentityProvider = shape({
  identityProviderId: string.isRequired,
  wellKnownConfigUrl: string.isRequired,
  clientId: string.isRequired,
  redirectUrl: string.isRequired,
  status: string.isRequired,
  errorMessage: shape({
    errorMessage: string.isRequired,
    errorCode: string.isRequired,
    errorSummary: string.isRequired,
    cloudformationUrl: string.isRequired,
  }),
  createDate: string.isRequired,
  updateDate: string.isRequired,
});
