import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Ready to add an authorization service?</Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>Select the type of authorization service.</Help.ListItem>
        <Help.ListItem>
          Provide the configuration details for your authorization service. Make
          sure to read the additional documentation for that service type to
          understand any additional configuration that may be required.
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        This will create an authorization service that can be enabled on data
        sources in your compute plane.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
