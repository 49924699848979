import { useEffect } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import Banner from '../../Banner';
import Loading from '../../Loading';
import Page from '../../Page';
import routes from '../../../router/routes';
import { useEnableFeature } from '../../utils/featureHooks';
import * as propTypes from '../propTypes';
import PrestoUsersTable from './PrestoUsersTable';
import { NoDataDiv } from './styledComponent';

const display = {
  prestoUsersLabel: 'Presto Users',
  createPrestoUserLabel: 'Add Presto User',
  noPrestoUsersLabel: 'You do not have any Presto users.',
};

const PrestoUsers = ({
  loadingInProgress,
  prestoUsers,
  getPrestoUsers,
  error,
}) => {
  const enableFeature = useEnableFeature();
  useEffect(() => {
    getPrestoUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingInProgress && !prestoUsers) {
    return (
      <Page.PageEmpty>
        <Loading.Loading />
      </Page.PageEmpty>
    );
  }

  return (
    <>
      {error && <Banner title={error} scrollIntoView />}
      <>
        <Page.TableBoxHeader>
          <h2>{display.prestoUsersLabel}</h2>
          <div className='buttons'>
            <Page.PrimaryLink
              to={routes.prestoUsersAdd.getRoute()}
              disabled={!enableFeature}
            >
              {display.createPrestoUserLabel}
            </Page.PrimaryLink>
          </div>
        </Page.TableBoxHeader>
        {(!prestoUsers || prestoUsers.length === 0) && (
          <NoDataDiv>{display.noPrestoUsersLabel}</NoDataDiv>
        )}
        {prestoUsers && prestoUsers.length > 0 && (
          <PrestoUsersTable prestoUsers={prestoUsers} />
        )}
      </>
    </>
  );
};

PrestoUsers.defaultProps = {
  prestoUsers: null,
  error: null,
};

PrestoUsers.propTypes = {
  loadingInProgress: bool.isRequired,
  prestoUsers: arrayOf(propTypes.PrestoUser),
  getPrestoUsers: func.isRequired,
  error: string,
};

export default PrestoUsers;
