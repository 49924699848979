import { arrayOf, string } from 'prop-types';
import noop from '../../../utils/noop';
import Page from '../../Page';
import Table from '../../Table';
import * as propTypes from '../propTypes';
import { getManagePrestoUserRoute } from '../routes';

const display = {
  nameColumnLabel: 'Username',
  clustersColumnLabel: 'Clusters',
  manageButtonLabel: 'View',
};

const ManageCell = ({ data, key }) => {
  return (
    <Table.ButtonCell key={key}>
      <Page.TertiaryLink to={data}>
        {display.manageButtonLabel}
      </Page.TertiaryLink>
    </Table.ButtonCell>
  );
};

ManageCell.propTypes = {
  data: string.isRequired,
  key: string.isRequired,
};

const dataKeyGenerator = (prestoUser) => {
  return prestoUser.prestoUserId;
};

const columns = [
  {
    name: display.nameColumnLabel,
    selector: (prestoUser) => {
      return prestoUser.username;
    },
    cellWidth: '2fr',
  },
  {
    name: display.clustersColumnLabel,
    selector: (prestoUser) => {
      return prestoUser.prestoClusterCount;
    },
    cellWidth: '2fr',
  },
  {
    name: 'Manage',
    selector: (prestoUser) => {
      return getManagePrestoUserRoute(prestoUser.prestoUserId);
    },
    headerRender: noop,
    cellRender: ManageCell,
    cellWidth: '2fr',
  },
];

const PrestoUsersTable = ({ prestoUsers }) => {
  return (
    <Table.Table
      data={prestoUsers}
      columns={columns}
      dataKeyGenerator={dataKeyGenerator}
    />
  );
};

PrestoUsersTable.propTypes = {
  prestoUsers: arrayOf(propTypes.PrestoUser).isRequired,
};

export default PrestoUsersTable;
