/* eslint-disable no-unused-vars */
import { string, arrayOf, shape, func, number, bool } from 'prop-types';
import { useEffect, useMemo } from 'react';
import Page from '../../Page';
import Form from '../../Form';
import ClusterUsageDetailsTable from './ClusterUsageDetailsTable';
import { TotalCredits, NoDataDiv, LoadingDiv } from './styledComponents';
import { Cluster } from '../propTypes';
import Loading from '../../Loading';
import MonthlyFilterDropdown from './MonthlyFilterDropdown';
import noop from '../../../utils/noop';

const display = {
  title: 'Usage Details',
  noClusterUsageDetailsLabel:
    'There is no cluster usage data for the selected date.',
};

const UsageDetails = ({
  navTarget,
  filterMonths,
  loadClusters,
  clusters,
  totalTenantCredits,
  isLoading,
}) => {
  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.TableBoxHeader>
        <h2>{display.title}</h2>
      </Page.TableBoxHeader>
      <Form.Form name='filter-month' submitAction={noop}>
        <MonthlyFilterDropdown
          filterMonths={filterMonths}
          loadClusters={loadClusters}
        />
      </Form.Form>
      {isLoading && (
        <LoadingDiv>
          <Loading.Loading />
        </LoadingDiv>
      )}
      {!isLoading && clusters && clusters.length > 0 && (
        <>
          <ClusterUsageDetailsTable clusters={clusters} />
          <TotalCredits>
            <div>Total Credits</div>
            <div className='total'>{totalTenantCredits}</div>
          </TotalCredits>
        </>
      )}
      {!isLoading && (!clusters || clusters.length === 0) && (
        <NoDataDiv>{display.noClusterUsageDetailsLabel}</NoDataDiv>
      )}
    </Page.BoxNavTarget>
  );
};

UsageDetails.defaultProps = {
  clusters: null,
  totalTenantCredits: null,
};

UsageDetails.propTypes = {
  navTarget: string.isRequired,
  filterMonths: arrayOf(
    shape({
      display: string.isRequired,
      value: string,
    }),
  ).isRequired,
  loadClusters: func.isRequired,
  clusters: arrayOf(Cluster),
  totalTenantCredits: number,
  isLoading: bool.isRequired,
};

export default UsageDetails;
