import { buildUrl } from '../../utils/buildUrl';
import rayApi from '../../rayApi';

export const getConfiguration = (
  rootUri,
  headers,
  params,
  body,
  abortFetchSignal,
) => {
  const uri = buildUrl({ baseUrl: rootUri, path: 'datasources/configuration' });
  return rayApi.api.executeGET(uri, headers, abortFetchSignal);
};
