import { bool, string } from 'prop-types';
import { ErrorBadge, SuccessBadge, WarningBadge } from '../../Badge';
import PrestoClusters from '../../PrestoClusters';

const display = {
  initializing: 'Initializing',
  running: 'Running',
  stopped: 'Stopped',
  error: 'Error',
  workflowInProgress: 'Modifying...',
};

const Active = () => {
  return <SuccessBadge>{display.running}</SuccessBadge>;
};

const Error = () => {
  return <ErrorBadge>{display.error}</ErrorBadge>;
};

const Inactive = () => {
  return <ErrorBadge>{display.stopped}</ErrorBadge>;
};

const Initializing = () => {
  return <WarningBadge>{display.initializing}</WarningBadge>;
};

const Empty = () => {
  return null;
};

const TitleNav = ({ name, status, hasError, workflowInProgress }) => {
  let StatusBadge;

  switch (status) {
    case PrestoClusters.constants.status.active:
      StatusBadge = Active;
      break;
    case PrestoClusters.constants.status.destroyed:
    case PrestoClusters.constants.status.inactive:
    case PrestoClusters.constants.status.archived:
      StatusBadge = Inactive;
      break;
    case PrestoClusters.constants.status.initializing:
      StatusBadge = Initializing;
      break;
    case PrestoClusters.constants.status.init_failure:
    default:
      StatusBadge = Empty;
      break;
  }

  return (
    <h1>
      {name}
      <StatusBadge />
      {hasError && <Error />}
      {workflowInProgress && (
        <WarningBadge>{display.workflowInProgress}</WarningBadge>
      )}
    </h1>
  );
};

TitleNav.propTypes = {
  name: string.isRequired,
  status: string.isRequired,
  hasError: bool.isRequired,
  workflowInProgress: bool.isRequired,
};

export default TitleNav;
