import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as api from './api';
import * as components from './components';
import Container from './Container';
import * as propTypes from './propTypes';
import reducer from './reducer';
import * as routes from './routes';
import * as selectors from './selectors';

const module = {
  actions,
  actionTypes,
  api,
  components,
  Container,
  propTypes,
  reducer,
  routes,
  selectors,
};

export default module;
