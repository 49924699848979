import { apiVerbs, createApiActionType } from '../rayApi/actionTypes';

const componentName = 'AUTH';
export const signOut = 'AUTH_SIGN_OUT';
export const signIn = 'AUTH_SIGN_IN';
export const finishedInitialCheck = 'AUTH_FINISHED_INITIAL_CHECK';
export const startRefreshToken = 'AUTH_START_REFRESH_TOKEN';

// This is for the new Auth endpoint.
export const getAuth = createApiActionType(apiVerbs.GET, componentName);
export const getCognitoToken = createApiActionType(
  apiVerbs.GET,
  'COGNITO_TOKEN',
);
