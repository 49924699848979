import { connect } from '../../utils/redux';
import PrestoUsers from '../PrestoUsers';
import PrestoUsersAdd from '../PrestoUsersAdd';
import AuthorizationServices from '../AuthorizationServices';
import { AddDataSource } from './components';
import * as selectors from './selectors';
import * as actions from './actions';

const mapStateToProps = (state) => {
  return {
    errors: selectors.getErrors(state),
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    usePop: selectors.getUsePop(state),
    // The 6 props below are a good candidate for fetching with useSelector,
    // since they apply to the Glue component only currrently
    awsRegions: selectors.getAwsRegions(state),
    computePlaneRegion: selectors.getComputePlaneRegion(state),
    awsGlueS3CloudFormationQuickCreateUrl:
      selectors.getAwsGlueS3CloudFormationQuickCreateUrl(state),
    awsLakeFormationCloudFormationQuickCreateUrl:
      selectors.getAwsLakeFormationCloudFormationQuickCreateUrl(state),
    prestoUsers: PrestoUsers.selectors.getPrestoUsers(state),
    loadingPrestoUsers: PrestoUsers.selectors.getLoadingInProgress(state),
    authorizationServices:
      AuthorizationServices.selectors.getAuthorizationServices(state),
  };
};

export default connect(mapStateToProps, {
  create: actions.submit,
  getConfiguration: actions.getConfiguration,
  loadPrestoUsers: PrestoUsers.actions.getAll,
  resetCreatePrestoUser: PrestoUsersAdd.actions.resetForm,
  getAuthorizationServices: AuthorizationServices.actions.getAll,
})(AddDataSource);
