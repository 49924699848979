import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool, arrayOf, shape, string } from 'prop-types';
import Form from '../../Form';
import DataSources from '../../DataSources';
import {
  required,
  requiredCredentialType,
  patternIAMRole,
  pattern,
} from '../utils';
import {
  awsAccessKeyRegex,
  awsSecretRegex,
  thriftHostnameURIRegex,
  thriftIPAddressURIRegex,
} from '../../../utils/regex';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import Help from './HiveConnectionDetailsHelp';

const display = {
  formContentHeader: 'Connection Configuration',
  formExplaination: 'Secondary explanation',
  thriftUrlLabel: 'Thrift Endpoint URI(s)',
  thriftUrlRequiredError: 'Thrift endpoint URI(s) are required',
  thriftUrlPatternError:
    'Thrift endpoint URI(s) should be in the format: thrift://[hostname]:[port]. Multiple URIs should be separated by commas.',
  credentialTypeLabel: 'S3 Credentials',
  roleLabel: 'Role ARN',
  roleRequiredError: 'Role ARN is required',
  roleARNPatternError:
    'Role ARN should be in the format of "arn:aws:iam::<AWS ACCOUNT ID>:role/<ROLE NAME>"',
  accessKeyLabel: 'Access Key ID',
  accessKeyRequiredError: 'Access key ID is required',
  accessKeyInvalidError: 'Access key ID is invalid',
  secretLabel: 'Secret Access Key',
  secretRequiredError: 'Secret access key is required',
  secretInvalidError: 'Secret access key is invalid',
  authorizationHeader: 'Authorization',
  authorizationLabel: 'Authorization Service',
  icebergHeader: 'Iceberg',
  icebergLabel: 'Iceberg Table',
};

const credentialTypeValues = [
  {
    label: 'IAM role',
    value: 'iam',
  },
  {
    label: 'Access key',
    value: 'access_key',
  },
];

const type = DataSources.constants.dataSourceTypes.hive;

export const thriftValidate = (errorMessage, additionalValidation) => {
  return (value) => {
    if (value === undefined || value === null) return true;

    // If input has no commas, validate single URI
    if (!value.includes(','))
      return (
        thriftHostnameURIRegex.test(value) ||
        thriftIPAddressURIRegex.test(value) ||
        errorMessage
      );
    // Otherwise, split by commas and check each URI
    const invalidURIs = value.split(',').some((uri) => {
      return (
        !thriftHostnameURIRegex.test(uri) && !thriftIPAddressURIRegex.test(uri)
      );
    });
    if (invalidURIs) return errorMessage;

    if (additionalValidation) {
      return additionalValidation(value);
    }
    return true;
  };
};

const HiveConnectionDetails = ({
  disabled,
  active,
  dataSource,
  authorizationServices,
  unsupportedRangerHivePrestoClusters,
}) => {
  const { getValues, watch, clearErrors } = useFormContext();
  const isCommunityEditionTenant = useIsCommunityEditionTenant();

  useEffect(() => {
    if (!active) {
      clearErrors('hive');
    }
  }, [active, clearErrors]);

  const credentialType = watch(
    'hive.credentialType',
    (dataSource && dataSource.metadata.credentialType) ||
      credentialTypeValues[0].value,
  );

  useEffect(() => {
    clearErrors('hive.s3');
  }, [credentialType, clearErrors]);

  const showAuthorization = useMemo(() => {
    return authorizationServices && authorizationServices.length > 0;
  }, [authorizationServices]);

  return (
    <>
      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.icebergHeader}</h3>
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.CheckboxInput
          name='hive.iceberg'
          label={display.icebergLabel}
          disabled={disabled}
          defaultChecked={
            dataSource && dataSource.connection.connector.name === 'iceberg'
          }
        />
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.formContentHeader}</h3>
        {/* <div>{display.formExplaination}</div> */}
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.TextInput
          name='hive.metastore.uri'
          label={display.thriftUrlLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.connection.hive.metastore.uri}
          validationRules={{
            validate: required(
              getValues,
              type,
              display.thriftUrlRequiredError,
              thriftValidate(display.thriftUrlPatternError),
            ),
          }}
          fieldHelp={Help.Thrift}
        />
        <Form.RadioInputs
          name='hive.credentialType'
          label={display.credentialTypeLabel}
          values={credentialTypeValues}
          disabled={disabled}
          defaultValue={
            (dataSource && dataSource.metadata.credentialType) ||
            credentialTypeValues[0].value
          }
        />
        <Form.TextInput
          name='hive.s3.iamRole'
          label={display.roleLabel}
          disabled={disabled}
          defaultValue={dataSource && dataSource.connection.hive.s3['iam-role']}
          validationRules={{
            validate: requiredCredentialType(
              getValues,
              type,
              'hive.credentialType',
              credentialTypeValues[0].value,
              display.roleRequiredError,
              patternIAMRole(display.roleARNPatternError),
            ),
          }}
          hidden={credentialType !== credentialTypeValues[0].value}
          fieldHelp={Help.RoleARN}
          trimPasted
        />
        <Form.TextInput
          name='hive.s3.accessKey'
          label={display.accessKeyLabel}
          disabled={disabled}
          defaultValue={
            dataSource && dataSource.connection.hive.s3['aws-access-key']
          }
          validationRules={{
            validate: requiredCredentialType(
              getValues,
              type,
              'hive.credentialType',
              credentialTypeValues[1].value,
              display.accessKeyRequiredError,
              pattern(awsAccessKeyRegex, display.accessKeyInvalidError),
            ),
          }}
          hidden={credentialType !== credentialTypeValues[1].value}
          fieldHelp={Help.AccessKey}
        />
        <Form.PasswordInput
          name='hive.s3.secret'
          label={display.secretLabel}
          disabled={disabled}
          validationRules={{
            validate: requiredCredentialType(
              getValues,
              type,
              'hive.credentialType',
              credentialTypeValues[1].value,
              display.secretRequiredError,
              pattern(awsSecretRegex, display.secretInvalidError),
            ),
          }}
          hidden={credentialType !== credentialTypeValues[1].value}
          fieldHelp={Help.SecretKey}
        />
      </div>
      {showAuthorization && (
        <>
          <div style={{ display: active ? undefined : 'none' }}>
            <h3>{display.authorizationHeader}</h3>
          </div>
          <div style={{ display: active ? undefined : 'none' }}>
            <Form.SelectInput
              name='authorizationServiceId'
              optional
              label={display.authorizationLabel}
              disabled={
                disabled ||
                unsupportedRangerHivePrestoClusters ||
                isCommunityEditionTenant
              }
              items={authorizationServices}
              fieldHelp={Help.AuthorizationService(isCommunityEditionTenant)}
              defaultValue={
                dataSource ? dataSource.authorizationServiceId : undefined
              }
            />
          </div>
        </>
      )}
    </>
  );
};

HiveConnectionDetails.defaultProps = {
  disabled: false,
  dataSource: null,
  authorizationServices: null,
  unsupportedRangerHivePrestoClusters: false,
};

HiveConnectionDetails.propTypes = {
  disabled: bool,
  active: bool.isRequired,
  dataSource: DataSources.propTypes.DataSource,
  authorizationServices: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ),
  unsupportedRangerHivePrestoClusters: bool,
};

export default HiveConnectionDetails;
