import { useEffect } from 'react';
import { arrayOf, func } from 'prop-types';
import { NoDataDiv } from './styledComponent';
import Page from '../../Page';
import routes from '../../../router/routes';
import { useEnableFeature } from '../../utils/featureHooks';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import AuthorizationServicesTable from './AuthorizationServicesTable';
import * as propTypes from '../propTypes';

const display = {
  authorizationServicesLabel: 'Authorization Services',
  addAuthorizationServiceLabel: 'Add Authorization Service',
  noAuthorizationServicesLabel: 'You do not have any authorization services.',
  communityEditionLabel:
    'This feature is not available for community edition users.',
};

const AuthorizationServices = ({
  authorizationServices,
  getAuthorizationServices,
}) => {
  useEffect(() => {
    getAuthorizationServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const enableFeature = useEnableFeature();
  const isCommunityEditionTenant = useIsCommunityEditionTenant();

  return (
    <>
      <Page.TableBoxHeader>
        <div>
          <h2>{display.authorizationServicesLabel}</h2>
        </div>
        <div className='buttons'>
          <Page.PrimaryLink
            to={routes.authorizationServices.getAddAuthorizationServiceRoute()}
            disabled={!enableFeature || isCommunityEditionTenant}
          >
            {display.addAuthorizationServiceLabel}
          </Page.PrimaryLink>
        </div>
      </Page.TableBoxHeader>
      {(!authorizationServices || authorizationServices.length === 0) && (
        <NoDataDiv>
          {display.noAuthorizationServicesLabel}{' '}
          {isCommunityEditionTenant && display.communityEditionLabel}
        </NoDataDiv>
      )}
      {authorizationServices && authorizationServices.length > 0 && (
        <AuthorizationServicesTable
          authorizationServices={authorizationServices}
        />
      )}
    </>
  );
};

AuthorizationServices.defaultProps = {
  authorizationServices: null,
};

AuthorizationServices.propTypes = {
  authorizationServices: arrayOf(propTypes.AuthorizationService),
  getAuthorizationServices: func.isRequired,
};

export default AuthorizationServices;
