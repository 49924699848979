import { useCallback } from 'react';
import { func, string, bool } from 'prop-types';
import { useNavigate, useLocation } from 'react-router';
import * as environment from '../../../utils/environment';
import Banner from '../../Banner';
import Form from '../../Form';

const display = {
  crnLabel: 'CRN',
  crnRequiredError: 'CRN is required',
  logInButton: 'Log In',
  localAuth:
    'Make sure you are running local-dev-proxy and have logged into http://localhost:3000 recently.',
  devAuth: 'Make sure you have logged into IBM Test Cloud recently.',
};

const SignInForm = ({ crn, submitInProgress, error, message, signIn }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const signInFunc = useCallback(
    (values) => {
      signIn(values, location, navigate);
    },
    [signIn, location, navigate],
  );

  return (
    <Form.Form name='sign-in' submitAction={signInFunc}>
      {error && <Banner title={error} />}
      {!error && message && <Banner title={message} isWarning />}
      {environment.isDevUI() && <Banner title={display.devAuth} isWarning />}
      {environment.isLocalhost() && (
        <Banner title={display.localAuth} isWarning />
      )}
      {(environment.isLocalUI() || environment.isDevUI()) && (
        <Form.TextInput
          name='crn'
          label={display.crnLabel}
          disabled={submitInProgress}
          validationRules={{ required: display.crnRequiredError }}
          defaultValue={crn}
        />
      )}

      <Form.Submit
        fullWidth
        disabled={submitInProgress}
        label={display.logInButton}
      />
    </Form.Form>
  );
};

SignInForm.defaultProps = {
  error: null,
  message: null,
  crn: null,
};

SignInForm.propTypes = {
  submitInProgress: bool.isRequired,
  error: string,
  message: string,
  signIn: func.isRequired,
  crn: string,
};

export default SignInForm;
