import { createSelector } from 'reselect';
import ComputePlane from '../ComputePlane';

export const getComputePlaneStatus = createSelector(
  ComputePlane.selectors.getComputePlane,
  (computePlane) => {
    return computePlane?.status;
  },
);

export const getAWSRoleArn = createSelector(
  ComputePlane.selectors.getComputePlane,
  (computePlane) => {
    return computePlane?.awsRoleARN;
  },
);

export const getComputePlaneId = createSelector(
  ComputePlane.selectors.getComputePlane,
  (computePlane) => {
    return computePlane?.computePlaneId;
  },
);

export const getErrorMessages = createSelector(
  ComputePlane.selectors.getComputePlane,
  (computePlane) => {
    return computePlane?.errorMessages;
  },
);
