import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import * as actionTypes from './actionTypes';
import { status } from './enums';

const initialState = {
  buckets: null,
  loadingInProgress: false,
  error: null,
};

const onNavigation = (draft) => {
  draft.loadingInProgress = false;
  draft.error = null;
};

const getStart = (draft) => {
  draft.loadingInProgress = true;
};

const getAllFailure = (draft, action) => {
  draft.loadingInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
};

const get = (draft, action) => {
  if (!draft.buckets) {
    draft.buckets = new Map();
  }
  const data = action.payload.apiResult.body;
  draft.buckets.set(action.payload.params.bucketId, data);
};

const getFailure = (draft, action) => {
  if (!draft.buckets) {
    draft.buckets = new Map();
  }
  draft.buckets.set(action.payload.params.bucketId, 'not found');
};

const getPartial = (draft, action) => {
  if (!draft.buckets) {
    draft.buckets = new Map();
  }

  const { results } = action.payload.apiResult.body;
  for (let i = 0; i < results.length; i += 1) {
    draft.buckets.set(results[i].bucketId, results[i]);
  }
};

const getAll = (draft, action) => {
  if (draft.buckets) {
    draft.buckets.clear();
  }
  getPartial(draft, action);
  draft.loadingInProgress = false;
};

const actionStart = (draft, action) => {
  draft.loadingInProgress = true;
  draft.error = null;

  // Set the bucket as deleting and store the previous status
  const { bucketId } = action.payload.params;
  const bucket = { ...draft.buckets.get(bucketId) };
  bucket.old_status = bucket.status;
  bucket.status = status.deleting;
  draft.buckets.set(bucketId, bucket);
};

const removeSuccess = (draft) => {
  draft.loadingInProgress = false;
  draft.error = null;
};

const actionFailure = (draft, action) => {
  draft.error = {
    message: action.payload.apiResult.body.errorMessage,
    bucketId: action.payload.params.bucketId,
  };
  draft.loadingInProgress = false;

  // Restore the pre-failure bucket status
  const { bucketId } = action.payload.params;
  const bucket = { ...draft.buckets.get(bucketId) };
  if (bucket) {
    bucket.status = bucket.old_status;
    delete bucket.old_status;
    draft.buckets.set(bucketId, bucket);
  }
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.get.success]: get,
  [actionTypes.get.failure]: getFailure,
  [actionTypes.getAll.request]: getStart,
  [actionTypes.getAll.success]: getAll,
  [actionTypes.getAll.failure]: getAllFailure,
  [actionTypes.remove.request]: actionStart,
  [actionTypes.remove.success]: removeSuccess,
  [actionTypes.remove.failure]: actionFailure,
  [auth.actionTypes.signOut]: signOut,
};

export default makeReducer(initialState, actionReducers);
