const display = {
  minimumWorkerNodesRequiredError: 'Minimum worker nodes is required',
  minimumWorkerNodesRangeError: (max) => {
    return `Minimum worker nodes must be between 1 and ${max}`;
  },
  maximumWorkerNodesRequiredError: 'Maximum worker nodes is required',
  maximumWorkerNodesRangeError: (min, maxWorkerNodeCount) => {
    return `Maximum worker nodes must be between ${min} and ${maxWorkerNodeCount}`;
  },
  stepSizeRequiredError: 'Scale out step size is required',
  stepSizeRangeError: (range) => {
    return `Scale out step size must be between 1 and ${range}`;
  },
};

export const mustBeIntegerInRange = (min, max, errorMessage) => {
  return (value) => {
    const i = Number(value);
    if (Number.isNaN(i) || !i || !Number.isInteger(i) || i < min || i > max) {
      return errorMessage;
    }
    return true;
  };
};

let cpuScaleInValidationMemo = {
  values: { minNodes: null, maxNodes: null, stepSize: null },
  result: { minNodes: true, maxNodes: true, stepSize: true },
};

export const resetValidateCPUScaleInMemo = () => {
  cpuScaleInValidationMemo = {
    values: { minNodes: null, maxNodes: null, stepSize: null },
    result: { minNodes: true, maxNodes: true, stepSize: true },
  };
};

export const validateCPUScaleIn = (
  minNodes,
  maxNodes,
  stepSize,
  maxWorkerNodeCount,
) => {
  if (
    Object.is(cpuScaleInValidationMemo.values.minNodes, minNodes) &&
    Object.is(cpuScaleInValidationMemo.values.maxNodes, maxNodes) &&
    Object.is(cpuScaleInValidationMemo.values.stepSize, stepSize)
  ) {
    return { result: cpuScaleInValidationMemo.result, trigger: false };
  }

  const values = { minNodes, maxNodes, stepSize };
  const invalidMinNodes = !minNodes || Number.isNaN(minNodes);
  const invalidMaxNodes = !maxNodes || Number.isNaN(maxNodes);
  const invalidStepSize = !stepSize || Number.isNaN(stepSize);
  const result = {};

  if (invalidMinNodes || invalidMaxNodes || invalidStepSize) {
    if (invalidMinNodes) {
      result.minNodes = display.minimumWorkerNodesRequiredError;
    }
    if (invalidMaxNodes) {
      result.maxNodes = display.maximumWorkerNodesRequiredError;
    }
    if (invalidStepSize) {
      result.stepSize = display.stepSizeRequiredError;
    }
  } else {
    if (minNodes >= maxWorkerNodeCount || maxNodes > minNodes) {
      const max =
        minNodes >= maxWorkerNodeCount
          ? maxWorkerNodeCount - 1
          : Math.min(maxWorkerNodeCount, Math.floor(maxNodes)) - 1;
      result.minNodes = mustBeIntegerInRange(
        1,
        max,
        display.minimumWorkerNodesRangeError(max),
      )(minNodes);
    } else {
      result.minNodes = true;
    }

    if (minNodes < maxWorkerNodeCount) {
      const min = Math.floor(minNodes) + 1;
      result.maxNodes = mustBeIntegerInRange(
        min,
        maxWorkerNodeCount,
        display.maximumWorkerNodesRangeError(min, maxWorkerNodeCount),
      )(maxNodes);
    } else {
      result.maxNodes = true;
    }

    const range = Math.floor(maxNodes) - Math.floor(minNodes);
    if (range > 0 && maxNodes >= 0 && minNodes >= 0) {
      result.stepSize = mustBeIntegerInRange(
        1,
        range,
        display.stepSizeRangeError(range),
      )(stepSize);
    } else {
      result.stepSize = true;
    }
  }
  cpuScaleInValidationMemo = { values, result };
  return { result, trigger: true };
};
