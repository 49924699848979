import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const get = (bucketId) => {
  return createApiAction(actionTypes.get, api.get, true, { bucketId });
};

export const getAll = () => {
  return createApiAction(actionTypes.getAll, api.getAll, true);
};

export const remove = (bucketId) => {
  return createApiAction(
    actionTypes.remove,
    api.remove,
    true,
    {
      bucketId,
    },
    null,
    { name: 'delete' },
  );
};
