import get from 'lodash.get';
import { iamRoleRegex, mysqlEndpointRegex, ipRegex } from '../../utils/regex';

export const pattern = (regex, errorMessage, additionalValidation) => {
  return (value) => {
    if (value === undefined || value === null || value === '') return true;

    if (value.match(regex) === null) {
      return errorMessage;
    }

    if (additionalValidation) {
      return additionalValidation(value);
    }
    return true;
  };
};

export const patternIAMRole = (errorMessage, additionalValidation) => {
  return pattern(iamRoleRegex, errorMessage, additionalValidation);
};

export const requiredMultipleSelect = (
  getValues,
  type,
  selects,
  errorMessage,
  additionalValidation,
) => {
  return (value) => {
    const values = getValues();
    if (values.dataSourceType !== type) return true;
    const notSelected = selects.find((select) => {
      return get(values, select.propName) !== select.group;
    });
    if (notSelected) return true;

    if (!value) {
      return errorMessage;
    }
    if (additionalValidation) {
      return additionalValidation(value);
    }
    return true;
  };
};

export const requiredCredentialType = (
  getValues,
  type,
  credentialTypePropName,
  credentialTypeGroup,
  errorMessage,
  additionalValidation,
) => {
  return requiredMultipleSelect(
    getValues,
    type,
    [{ propName: credentialTypePropName, group: credentialTypeGroup }],
    errorMessage,
    additionalValidation,
  );
};

export const required = (
  getValues,
  type,
  errorMessage,
  additionalValidation,
) => {
  return (value) => {
    const { dataSourceType } = getValues();
    if (dataSourceType !== type) return true;
    if (!value) {
      return errorMessage;
    }
    if (additionalValidation) {
      return additionalValidation(value);
    }
    return true;
  };
};

const display = {
  portOutOfRangeError: 'Port should be between 0 - 65535',
};

export const portValidation = (value) => {
  if (value <= 0 || value > 65535) {
    return display.portOutOfRangeError;
  }

  return true;
};

export const endpointValidation = (errorMessage, additionalValidation) => {
  return (value) => {
    if (value === undefined || value === null) return true;

    if (!ipRegex.test(value) && !mysqlEndpointRegex.test(value)) {
      return errorMessage;
    }

    if (additionalValidation) {
      return additionalValidation(value);
    }

    return true;
  };
};
