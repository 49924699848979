import { any, arrayOf, shape, string } from 'prop-types';
import noop from '../../../utils/noop';
import Table from '../../Table';
import Page from '../../Page';
import { ErrorBadge } from '../../Badge';
import Icons from '../../../icons';
import * as constants from '../constants';
import { getManageDataSourceRoute } from '../routes';
import * as propTypes from '../propTypes';
import { StatusDiv } from './styledComponent';

const display = {
  manageButtonLabel: 'View',
  statusColumnLabel: 'Status',
  nameColumnLabel: 'Name',
  typeColumnLabel: 'Type',
  catalogNameColumnLabel: 'Catalog Name',
  urlColumnLabel: 'Connection URL',
  authorizationTypeNameColumnLabel: 'Authorization',
};

const ManageCell = ({ data, key }) => {
  return (
    <Table.ButtonCell key={key}>
      <Page.TertiaryLink to={data}>
        {display.manageButtonLabel}
      </Page.TertiaryLink>
    </Table.ButtonCell>
  );
};

ManageCell.propTypes = {
  data: string.isRequired,
  key: string.isRequired,
};

const LogoCell = ({ data, key }) => {
  return (
    <Table.ImageCell key={key}>
      <img src={data.Logo} alt={data.alt} style={{ display: 'block' }} />
    </Table.ImageCell>
  );
};

LogoCell.propTypes = {
  data: shape({
    // eslint-disable-next-line react/forbid-prop-types
    Logo: any,
    alt: string.isRequired,
  }).isRequired,
  key: string.isRequired,
};

const StatusCell = ({ data, key }) => {
  return (
    <Table.DataCell key={key}>
      {constants.status.secret_failure === data && <ErrorBadge />}
      {constants.status.delete_failure === data && <ErrorBadge />}
      {(constants.status.secret_updating === data ||
        constants.status.secret_deleting === data) && (
        <StatusDiv>
          <Icons.Progress />
        </StatusDiv>
      )}
    </Table.DataCell>
  );
};

StatusCell.propTypes = {
  data: string.isRequired,
  key: string.isRequired,
};
const columns = [
  {
    name: display.typeColumnLabel,
    selector: (dataSource) => {
      return constants.dataSourceTypeLogos[dataSource.type];
    },
    headerRender: noop,
    cellRender: LogoCell,
    cellWidth: '1fr',
  },
  {
    name: display.statusColumnLabel,
    selector: (dataSource) => {
      return dataSource.status;
    },
    cellRender: StatusCell,
    cellWidth: '1fr',
  },
  {
    name: display.nameColumnLabel,
    selector: (dataSource) => {
      return dataSource.name;
    },
    cellWidth: '2fr',
  },
  {
    name: display.catalogNameColumnLabel,
    selector: (dataSource) => {
      return dataSource.catalogName;
    },
    cellWidth: '2fr',
  },
  {
    name: display.urlColumnLabel,
    selector: (dataSource) => {
      switch (dataSource.type) {
        case constants.dataSourceTypes.mysql:
        case constants.dataSourceTypes.postgresql:
        case constants.dataSourceTypes.db2:
          return dataSource.metadata.displayConnectionUrl;
        case constants.dataSourceTypes.hive:
          return (
            dataSource.connection.hive &&
            dataSource.connection.hive.metastore.uri
          );
        case constants.dataSourceTypes.glue:
          return null;
        case constants.dataSourceTypes.elasticsearch:
          return `${dataSource.metadata.host}:${dataSource.connection.elasticsearch.port}`;
        default:
          return dataSource.connection['connection-url'];
      }
    },
    cellWidth: '3fr',
  },
  {
    name: display.authorizationTypeNameColumnLabel,
    selector: (dataSource) => {
      return dataSource.authorizationServiceTypeName;
    },
    cellWidth: '2fr',
  },
  {
    name: 'Manage',
    selector: (dataSource) => {
      return getManageDataSourceRoute(dataSource.dataSourceId);
    },
    headerRender: noop,
    cellRender: ManageCell,
    cellWidth: '2fr',
  },
];

const dataKeyGenerator = (dataSource) => {
  return dataSource.dataSourceId;
};

const DataSourcesTable = ({ dataSources }) => {
  return (
    <Table.Table
      data={dataSources}
      columns={columns}
      dataKeyGenerator={dataKeyGenerator}
    />
  );
};

DataSourcesTable.propTypes = {
  dataSources: arrayOf(propTypes.DataSource).isRequired,
};

export default DataSourcesTable;
