function pasteTrimText(pastedInput, text, start, end) {
  const pastedInputUpdated = pastedInput.trim();
  const textUpdated =
    text.substring(0, start) +
    pastedInputUpdated +
    text.substring(end, text.length);

  const position = (text.substring(0, start) + pastedInputUpdated).length;

  return { textUpdated, position };
}

export { pasteTrimText };
