/* eslint-disable no-unused-vars */
import { bool, func, string } from 'prop-types';
import Page from '../../../Page';
import Form from '../../../Form';
import SuccessFilled from '../../../../icons/SuccessFilled';
import { SuccessDiv } from '../styledComponents';
import Banner from '../../../Banner';

const display = {
  title: 'Steps 6 of 8 - Check Prerequisites',
  deployButton: 'Deploy Compute Plane',
  deployingButton: 'Deploying...',
};

export const CheckPrerequisites = ({
  onBack,
  onDeployComputePlane,
  submitInProgress,
  error,
}) => {
  return (
    <>
      <Page.Box>
        <Page.BoxHeader>
          <h2>{display.title}</h2>
        </Page.BoxHeader>
        {error && <Banner title={error} scrollIntoView />}
        <SuccessDiv>
          <SuccessFilled />
          <div>All Checks Passed!</div>
        </SuccessDiv>
      </Page.Box>
      <Page.Buttons>
        <Form.SecondaryButton
          label='Back'
          onClick={onBack}
          disabled={submitInProgress}
          disabledOnErrors={false}
        />
        <Form.PrimaryButton
          label={
            !submitInProgress ? display.deployButton : display.deployingButton
          }
          disabled={submitInProgress}
          showLoading={submitInProgress}
          disabledOnErrors
          onClick={onDeployComputePlane}
        />
      </Page.Buttons>
    </>
  );
};

CheckPrerequisites.defaultProps = {
  error: undefined,
  submitInProgress: false,
};

CheckPrerequisites.propTypes = {
  error: string,
  submitInProgress: bool,
  onBack: func.isRequired,
  onDeployComputePlane: func.isRequired,
};
