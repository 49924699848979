import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const getAll = () => {
  return createApiAction(actionTypes.getAll, api.getAll, true);
};

export const get = (identityProviderId) => {
  return createApiAction(actionTypes.get, api.get, true, {
    identityProviderId,
  });
};

export const remove = (identityProviderId) => {
  return createApiAction(
    actionTypes.remove,
    api.remove,
    true,
    {
      identityProviderId,
    },
    null,
  );
};
