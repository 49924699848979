import { connect } from '../../utils/redux';
import PrestoClusters from '../PrestoClusters';
import PrestoUsers from '../PrestoUsers';
import PrestoUsersAdd from '../PrestoUsersAdd';
import { ChangePrestoUsers } from './components';

const mapStateToProps = (state, props) => {
  return {
    prestoClusterId: PrestoClusters.selectors.getPrestoClusterId(state, props),
    prestoUsers: PrestoUsers.selectors.getPrestoUsers(state),
    prestoUserIds: PrestoClusters.selectors.getPrestoClusterPrestoUserIds(
      state,
      props,
    ),
    status: PrestoClusters.selectors.getPrestoClusterStatus(state, props),
    name: PrestoClusters.selectors.getPrestoClusterName(state, props),
    notFound: PrestoClusters.selectors.getIsNotFound(state, props),
    actionStatus: PrestoClusters.selectors.getActionStatus(state, props),
    usePop: PrestoClusters.selectors.getUsePop(state),
    workflowInProgress:
      PrestoClusters.selectors.getPrestoClusterWorkflowInProgress(state, props),
  };
};

export default connect(mapStateToProps, {
  loadPrestoCluster: PrestoClusters.actions.get,
  loadPrestoUsers: PrestoUsers.actions.getAll,
  savePrestoUsers: PrestoClusters.actions.putPrestoUsers,
  resetCreatePrestoUser: PrestoUsersAdd.actions.resetForm,
})(ChangePrestoUsers);
