import AlertIcon from '../../../icons/Alert';
import { FooterDiv, FooterBoxDiv } from './styledComponents';

const Trial = () => {
  return (
    <FooterDiv className='warning'>
      <FooterBoxDiv className='warning'>
        <div className='icon'>
          <AlertIcon />
        </div>
        <div className='message'>
          <h3>Your compute plane is being upgraded.</h3>
          <div>While this is being processed, no changes can be made.</div>
        </div>
      </FooterBoxDiv>
    </FooterDiv>
  );
};

export default Trial;
