/** #1950FF */
const blue = '#0F62FE';
const white = '#FFFFFF';
const darkPurple = '#4819AD';
/** const gray = '#393939'; */
const purple = '#6727FF';
const red = '#FB543F';
const orange = '#FF8000';
const green = '#03BC62';

export const colors = {
  /** #ffffff */
  font_light: '#F4F4F4',
  /** #000000 */
  font_dark: '#000000',
  font_disabled: '#7D7E7E',

  /** #242424 */
  header: '#242424',
  header_gradient: 'rgba(255,255,255,0.1)',
  header_nav_focus: 'rgba(255,255,255,0.3)',
  /** #FAF8FF */
  header_menu_focus: '#393939',
  header_menu_focus_border: '#ffffff',
  header_menu_click: '#ffffff',
  header_menu_click_border: blue,

  footer: 'rgba(0,0,0,0.56)',

  /** #fafafa */
  page_background: '#000000',
  /** #ffffff */
  section_background: '#262626',
  /** rgba(0,0,0,0.1) */
  shadow: 'rgba(0,0,0,0.1)',
  /** #e1e1e1 */
  border: '#393939',

  /** #4819AD */
  input_focus: '#fff',
  /** rgba(219,204,255,0.4) - #DBCCFF */
  input_focus_shadow: 'rgba(219,204,255,0.4)',
  /** rgba(22,29,37,0.05) - #161D25 */
  input_shadow: 'rgba(22,29,37,0.05)',

  primary_button: blue,
  primary_button_font: '#ffffff',
  primary_button_border: '#0148D7',
  primary_button_gradient1: 'rgba(38,90,255,0.92)',
  primary_button_gradient2: 'rgba(24,75,241,0.92)',
  primary_button_disabled: '#c6c6c6',
  /** primary_button_disabled_gradient1: 'rgba(147, 175, 255, 0.93)',
  primary_button_disabled_gradient2: 'rgba(133, 161, 241, 0.93)', */
  primary_button_disabled_border: '#c6c6c6',
  /** '#6826FF', */
  secondary_button: '#6f6f6f',
  secondary_button_font: '#ffffff',
  secondary_button_border: '#6f6f6f',
  secondary_button_gradient1: 'rgba(112, 50, 255, 0.93)',
  secondary_button_gradient2: 'rgba(98, 36, 241, 0.93)',
  secondary_button_disabled: '#c6c6c6',
  /** secondary_button_disabled_gradient1: 'rgba(147, 175, 255, 0.93)',
  secondary_button_disabled_gradient2: 'rgba(133, 161, 241, 0.93)', */
  secondary_button_disabled_border: '#c6c6c6',

  tertiary_button: '#6f6f6f',
  tertiary_button_font: '#ffffff',
  tertiary_button_border: '#6f6f6f',
  tertiary_button_gradient1: 'rgba(255, 255, 255, 0.93)',
  tertiary_button_gradient2: 'rgba(241, 241, 241, 0.93)',
  tertiary_button_disabled: '#F3F3F3',
  tertiary_button_disabled_gradient1: 'rgba(244, 244, 244, 0.93)',
  tertiary_button_disabled_gradient2: 'rgba(230, 230, 230, 0.93)',
  tertiary_button_disabled_border: '#e1e1e1',

  error_button: '#da1e28',
  error_button_font: '#ffffff',
  error_button_border: '#E23A25',
  error_button_gradient1: 'rgba(251, 90, 70, 0.95)',
  error_button_gradient2: 'rgba(242, 81, 61, 0.95)',
  error_button_disabled: '#c6c6c6',
  error_button_disabled_gradient1: 'rgba(253, 174, 165, 0.95)',
  error_button_disabled_gradient2: 'rgba(244, 165, 156, 0.95)',
  error_button_disabled_border: '#c6c6c6',

  success_button: green,
  success_button_font: '#ffffff',
  success_button_border: '#099E55',
  success_button_gradient1: 'rgba(12, 190, 104, 0.95)',
  success_button_gradient2: 'rgba(3, 181, 95, 0.95)',
  success_button_disabled: '#80DAAE',
  success_button_disabled_gradient1: 'rgba(133, 219, 177, 0.95)',
  success_button_disabled_gradient2: 'rgba(123, 210, 168, 0.95)',
  success_button_disabled_border: '#42BA80',

  warning_button: orange,
  warning_button_font: '#ffffff',
  warning_button_border: '#FF8000',
  warning_button_gradient1: 'rgba(255, 171, 9, 0.95)',
  warning_button_gradient2: 'rgba(246, 162, 0, 0.95)',
  warning_button_disabled: '#FFD27D',
  warning_button_disabled_gradient1: 'rgba(255, 212, 130, 0.95)',
  warning_button_disabled_gradient2: 'rgba(246, 203, 121, 0.95)',
  warning_button_disabled_border: '#FFA83D',

  badge_error_font: red,
  badge_error_border: '#FFD5D0',
  badge_error_background: 'rgba(251, 84, 63, 0.1)',

  badge_optional_font: '#983ACB',
  badge_optional_border: '#F0D3FF',
  badge_optional_background: 'rgba(152, 58, 203, 0.1)',

  badge_success_font: green,
  badge_success_border: '#BEE7D4',
  badge_success_background: 'rgba(3, 188, 98, 0.1)',

  badge_warning_font: orange,
  badge_warning_border: '#FFD2A4',
  badge_warning_background: 'rgba(255, 128, 0, 0.1)',

  help_bullet_fill: purple,
  help_bullet_border: purple,
  focus_dark: blue,
  focus_light: purple,
  purple,
  darkPurple,
  white,
  error: red,
  error_dim: 'rgba(251,84,63,0.1)',
  warning: orange,
  warning_dim: 'rgba(255,128,0,0.1)',
  success: green,
  success_dim: 'rgba(3,188,98,0.1)',
};
