import { connect } from '../../utils/redux';
import * as selectors from './selectors';
import { Footer } from './components';

const mapStateToProps = (state) => {
  return {
    displayFooter: selectors.displayFooter(state),
    displayAWSUnsubscribed: selectors.displayAWSUnsubscribed(state),
    displayAWSFailed: selectors.displayAWSFailed(state),
    displayTrial: selectors.displayTrial(state),
    displayTrialExpired: selectors.displayTrialExpired(state),
    trialDaysLeft: selectors.trialDaysLeft(state),
    displayComputePlaneUpgrade: selectors.displayComputePlaneUpgrade(state),
    displayComputePlaneLowerThanMinimumVersion:
      selectors.displayComputePlaneLowerThanMinimumVersion(state),
  };
};

export default connect(mapStateToProps)(Footer);
