import { useEffect } from 'react';
import { string, arrayOf, func, shape } from 'prop-types';
import DataSources from '../../DataSources';
import PrestoClusters from '../../PrestoClusters';
import Page from '../../Page';
import { useEnableFeature } from '../../utils/featureHooks';
import { NoDataDiv } from './styledComponents';

const { DataSourcesTable } = DataSources.components;

const display = {
  title: 'Data Sources',
  attachButton: 'Manage Data Sources',
  noDataSources: 'No additional data sources',
  loading: 'Loading...',
};

const AttachedDataSources = ({
  navTarget,
  dataSources,
  loadDataSources,
  prestoCluster,
  prestoClusterId,
}) => {
  useEffect(() => {
    loadDataSources(prestoClusterId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoClusterId]);

  const enableManageLink = useEnableFeature();

  const canAttachDataSource =
    (prestoCluster.status === PrestoClusters.constants.status.active ||
      prestoCluster.status === PrestoClusters.constants.status.inactive) &&
    !prestoCluster.workflowInProgress;

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.TableBoxHeader>
        <h2>{display.title}</h2>
        <div className='buttons'>
          <Page.PrimaryLink
            to={PrestoClusters.routes.getManagePrestoClusterDataSourcesRoute(
              prestoClusterId,
            )}
            disabled={!canAttachDataSource || !enableManageLink}
          >
            {display.attachButton}
          </Page.PrimaryLink>
        </div>
      </Page.TableBoxHeader>
      {dataSources && dataSources.length > 0 && (
        <DataSourcesTable dataSources={dataSources} />
      )}
      {dataSources && dataSources.length === 0 && (
        <NoDataDiv>{display.noDataSources}</NoDataDiv>
      )}
      {!dataSources && <NoDataDiv>{display.loading}</NoDataDiv>}
    </Page.BoxNavTarget>
  );
};

AttachedDataSources.defaultProps = {
  dataSources: null,
};

AttachedDataSources.propTypes = {
  dataSources: arrayOf(DataSources.propTypes.DataSource),
  navTarget: string.isRequired,
  loadDataSources: func.isRequired,
  prestoClusterId: string.isRequired,
  prestoCluster: shape({
    status: string.isRequired,
  }).isRequired,
};

export default AttachedDataSources;
