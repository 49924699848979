import { connect } from '../../utils/redux';
import AuthorizationServices from '../AuthorizationServices';
import { AuthorizationService } from './components';

const mapStateToProps = (state, props) => {
  return {
    authorizationService:
      AuthorizationServices.selectors.getAuthorizationService(state, props),
    authorizationServiceId:
      AuthorizationServices.selectors.getAuthorizationServiceId(state, props),
    dataSources: AuthorizationServices.selectors.getDataSources(state, props),
    actionStatus: AuthorizationServices.selectors.getActionStatus(state, props),
    notFound: AuthorizationServices.selectors.getIsNotFound(state, props),
    usePop: AuthorizationServices.selectors.getUsePop(state),
    prestoClusters: AuthorizationServices.selectors.getPrestoClusters(
      state,
      props,
    ),
  };
};

export default connect(mapStateToProps, {
  loadAuthorizationService: AuthorizationServices.actions.get,
  loadDataSources: AuthorizationServices.actions.getDataSources,
  loadPrestoClusters: AuthorizationServices.actions.getPrestoClusters,
  deleteAuthorizationService: AuthorizationServices.actions.remove,
})(AuthorizationService);
