import { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import useRouteBuilder from '../../router/useRouteBuilder';
import { onAccessibleClick } from '../utils/accessibility';
import useCustomizePrompt from './useCustomizePrompt';

const useFormNavigation = ({
  submitSuccess,
  confirmCancelMessage,
  getRoute,
  usePop,
  extendOnCancel,
  extendShowPrompt,
  extendSubmitSuccess,
}) => {
  const {
    formState: { isDirty: isFormDirty },
  } = useFormContext();

  const navigate = useNavigate();
  const location = useLocation();
  const route = useRouteBuilder(getRoute);
  const replaceOnSuccessRoute = useRouteBuilder(
    location.state && location.state.replaceOnSuccess,
  );

  const onCancel = useCallback(
    (e) => {
      return onAccessibleClick(e, () => {
        if (extendOnCancel) {
          const handled = extendOnCancel();
          if (handled) {
            return;
          }
        }
        if (usePop) {
          navigate(-1);
        } else {
          navigate(route, { replace: true });
        }
      });
    },
    [navigate, usePop, route, extendOnCancel],
  );

  const showPrompt = useCallback(() => {
    let custom = true;
    if (extendShowPrompt) {
      custom = extendShowPrompt();
    }
    return custom && isFormDirty && !submitSuccess;
  }, [isFormDirty, submitSuccess, extendShowPrompt]);

  useCustomizePrompt({
    when: showPrompt,
    message: confirmCancelMessage,
  });

  useEffect(() => {
    if (submitSuccess) {
      if (extendSubmitSuccess) {
        const handled = extendSubmitSuccess();
        if (handled) {
          return;
        }
      }
      if (replaceOnSuccessRoute) {
        navigate(replaceOnSuccessRoute, { replace: true });
      } else if (usePop) {
        navigate(-1);
      } else {
        navigate(route, { replace: true });
      }
    }
  }, [
    submitSuccess,
    navigate,
    usePop,
    route,
    extendSubmitSuccess,
    replaceOnSuccessRoute,
  ]);

  return { onCancel };
};

export default useFormNavigation;
