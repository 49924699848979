const apiActionTypeStages = {
  request: 'REQUEST_SAGA',
  pending: 'PENDING',
  accepted: 'ACCEPTED',
  success: 'SUCCESS',
  failure: 'FAILURE',
  abort: 'ABORT',
};

export const apiVerbs = {
  DELETE: 'DELETE',
  GET: 'GET',
  PATCH: 'PATCH',
  POST: 'POST',
  PUT: 'PUT',
};

export const createApiActionType = (verb, resourceName) => {
  return Object.entries(apiActionTypeStages).reduce((acc, current) => {
    acc[current[0]] = `${verb}_${resourceName}_${current[1]}`;
    return acc;
  }, {});
};
