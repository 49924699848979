import { string, bool, elementType } from 'prop-types';
import Label from './Label';
import { DataWithActionDiv } from './styledComponents';
import LinkIcon from '../../icons/Link';

const DataFieldWithLink = ({ label, value, isPrivate, fieldHelp, url }) => {
  return (
    <>
      <Label label={label} fieldHelp={fieldHelp} />
      <DataWithActionDiv>
        <div data-private={isPrivate ? true : undefined}>{value}</div>
        <button
          onClick={() => {
            window.open(url || value, '_blank', 'noopener,noreferrer');
          }}
          type='button'
          disabled={value === ''}
          className='link'
        >
          <LinkIcon fillColor='white' />
        </button>
      </DataWithActionDiv>
    </>
  );
};

DataFieldWithLink.defaultProps = {
  value: null,
  isPrivate: false,
  fieldHelp: undefined,
};

DataFieldWithLink.propTypes = {
  label: string.isRequired,
  value: string,
  url: string.isRequired,
  isPrivate: bool,
  fieldHelp: elementType,
};

export default DataFieldWithLink;
