import { node } from 'prop-types';
import { OptionalBadgeSpan } from './styledComponents';

const OptionalBadge = ({ children }) => {
  return <OptionalBadgeSpan>{children || 'Optional'}</OptionalBadgeSpan>;
};

OptionalBadge.defaultProps = {
  children: null,
};

OptionalBadge.propTypes = {
  children: node,
};

export default OptionalBadge;
