import { useMemo } from 'react';

let count = 0;

const Stop = () => {
  const { linearGradientId, filterId, circleId } = useMemo(() => {
    count += 1;
    return {
      linearGradientId: `stopLG${count}`,
      filterId: `stopF${count}`,
      circleId: `stopC${count}`,
    };
  }, []);

  return (
    <svg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient
          x1='50%'
          y1='50%'
          x2='50%'
          y2='100%'
          id={linearGradientId}
        >
          <stop stopColor='#FFF' stopOpacity='0.5' offset='0%' />
          <stop stopOpacity='0.5' offset='100%' />
        </linearGradient>
        <filter
          x='-17.5%'
          y='-12.5%'
          width='135.0%'
          height='135.0%'
          filterUnits='objectBoundingBox'
          id={filterId}
        >
          <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur
            stdDeviation='2'
            in='shadowOffsetOuter1'
            result='shadowBlurOuter1'
          />
          <feComposite
            in='shadowBlurOuter1'
            in2='SourceAlpha'
            operator='out'
            result='shadowBlurOuter1'
          />
          <feColorMatrix
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0496612762 0'
            in='shadowBlurOuter1'
          />
        </filter>
        <circle id={circleId} cx='20' cy='20' r='20' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <use
          fill='#000'
          filter={`url(#${filterId})`}
          xlinkHref={`#${circleId}`}
        />
        <use fill='#FB543F' xlinkHref={`#${circleId}`} />
        <circle
          stroke='#E23A25'
          strokeLinejoin='square'
          fillOpacity='0.05'
          fill={`url(#${linearGradientId})`}
          cx='20'
          cy='20'
          r='19.5'
        />
        <g>
          <path d='M6.666667 6.666667h26.6666667v26.6666667H6.666667z' />
          <path
            d='M20.0000003 31.1111114c-6.13666663 0-11.11111108-4.9744444-11.11111108-11.1111111 0-6.13666663 4.97444445-11.11111108 11.11111108-11.11111108 6.1366667 0 11.1111111 4.97444445 11.1111111 11.11111108 0 6.1366667-4.9744444 11.1111111-11.1111111 11.1111111zm0-12.6822222l-3.1422222-3.14333331-1.57222221 1.57222221 3.14333331 3.1422222-3.14333331 3.1422223 1.57222221 1.5722222 3.1422222-3.1433334 3.1422223 3.1433334 1.5722222-1.5722222-3.1433334-3.1422223 3.1433334-3.1422222-1.5722222-1.57222221-3.1422223 3.14333331z'
            fill='#FFF'
          />
        </g>
      </g>
    </svg>
  );
};

export default Stop;
