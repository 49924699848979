import Data from '../../Data';
import DataSources from '../../DataSources';

const display = {
  formContentHeader: 'Access',
  endpointLabel: 'Endpoint',
  portLabel: 'Port',
  databaseLabel: 'Database',
  configurationPropertiesLabel: 'Configuration Properties',
  userLabel: 'User',
  passwordLabel: 'Password',
  enableSSLConnectionLabel: 'SSL connection',
};

const IBMDb2ConnectionDetails = ({
  'connection-user': user,
  'connection-password': password,
  endpoint,
  port,
  sslConnection,
  database,
  configurationProperties,
}) => {
  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
      </div>
      <div>
        <Data.DataField label={display.endpointLabel} value={endpoint} />
        <Data.DataField label={display.portLabel} value={port} />
        <Data.DataField label={display.databaseLabel} value={database} />
        <Data.DataField label={display.userLabel} value={user} />
        <Data.DataField
          optional
          label={display.configurationPropertiesLabel}
          value={configurationProperties}
        />
        <Data.DataField label={display.passwordLabel} value={password} />
        <Data.CheckboxField
          label={display.enableSSLConnectionLabel}
          value={sslConnection}
        />
      </div>
    </>
  );
};

IBMDb2ConnectionDetails.propTypes = DataSources.propTypes.IBMDb2Connection;

export default IBMDb2ConnectionDetails;
