import { useEffect } from 'react';
import { string, arrayOf, func } from 'prop-types';
import PrestoClusters from '../../PrestoClusters';
import { PrestoClustersTable } from '../../PrestoClusters/components';
import Page from '../../Page';
import { NoDataDiv } from './styledComponents';

const display = {
  title: 'Attached Clusters',
  noAttachedClusters: 'No attached clusters',
  loading: 'Loading...',
};

const AttachedClusters = ({
  navTarget,
  prestoClusters,
  loadPrestoClusters,
  dataSourceId,
}) => {
  useEffect(() => {
    loadPrestoClusters(dataSourceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceId]);

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.TableBoxHeader>
        <h2>{display.title}</h2>
      </Page.TableBoxHeader>
      {prestoClusters && prestoClusters.length > 0 && (
        <PrestoClustersTable prestoClusters={prestoClusters} />
      )}
      {prestoClusters && prestoClusters.length === 0 && (
        <NoDataDiv>{display.noAttachedClusters}</NoDataDiv>
      )}
      {!prestoClusters && <NoDataDiv>{display.loading}</NoDataDiv>}
    </Page.BoxNavTarget>
  );
};

AttachedClusters.defaultProps = {
  prestoClusters: null,
};

AttachedClusters.propTypes = {
  navTarget: string.isRequired,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  loadPrestoClusters: func.isRequired,
  dataSourceId: string.isRequired,
};

export default AttachedClusters;
