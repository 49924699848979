import { connect } from '../../utils/redux';
import { IAMPolicyContentEmbed } from './components';
import * as selectors from './selectors';

const mapStateToProps = (state, props) => {
  return {
    onClose: props.onClose,
    policyContent: selectors.getPolicyContent(state, props),
    policyTitle: selectors.getPolicyTitle(state, props),
  };
};

export default connect(mapStateToProps)(IAMPolicyContentEmbed);
