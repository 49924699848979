import { string, bool } from 'prop-types';
import { PageDiv, CircleLoading } from './styledComponents';

const Spinner = ({ message, boxed }) => {
  return (
    <PageDiv className={boxed ? 'boxed' : undefined}>
      <style type='text/css'>{CircleLoading}</style>
      <div className='bootLoader-v1' id='_bootLoader'>
        <div aria-live='assertive' className='bx--loading'>
          <svg className='bx--loading__svg' viewBox='0 0 100 100'>
            <title>Loading</title>
            <circle cx='50%' cy='50%' r='44' />
          </svg>
        </div>
      </div>
      {message && <div>{message}</div>}
    </PageDiv>
  );
};

Spinner.defaultProps = {
  message: undefined,
  boxed: false,
};

Spinner.propTypes = {
  message: string,
  boxed: bool,
};

export default Spinner;
