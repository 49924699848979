import Help from '../../Help';

const Endpoint = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Ranger Endpoint</Help.HelpHeader>
      <Help.P>
        The URL to your Apache Ranger service REST endpoint (e.g. http://{'<'}
        ranger-admin-host{'>'}:{'<'}ranger-admin-port{'>'}).
      </Help.P>
    </Help.HelpSection>
  );
};

const Servicename = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Hive Service Name</Help.HelpHeader>
      <Help.P>
        The service name of Ranger Hive plugin where Ranger policies are
        defined.
      </Help.P>
    </Help.HelpSection>
  );
};

const Username = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Username</Help.HelpHeader>
      <Help.P>The Ranger service admin username.</Help.P>
    </Help.HelpSection>
  );
};

const Password = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Password</Help.HelpHeader>
      <Help.P>The password for the Ranger service admin user.</Help.P>
    </Help.HelpSection>
  );
};

const AuditConfigFile = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Audit Config File</Help.HelpHeader>
      <Help.P>
        Upload ranger-hive-audit.xml file from Apache Ranger Service (e.g.
        /etc/conf/hive/ranger-hive-audit.xml file).
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  AuditConfigFile,
  Endpoint,
  Servicename,
  Username,
  Password,
};

export default module;
