import { createSelector } from 'reselect';
import DataSources from '../DataSources';
import PrestoClusters from '../PrestoClusters';

export const getErrors = (state) => {
  return state.dataSourceUpdate.errors;
};

export const getSubmitInProgress = (state) => {
  return state.dataSourceUpdate.submitInProgress;
};

export const getSubmitSuccess = (state) => {
  return state.dataSourceUpdate.submitSuccess;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getUnsupportedLakeFormationPrestoClusters = createSelector(
  DataSources.selectors.getDataSource,
  DataSources.selectors.getPrestoClusters,
  (dataSource, prestoClusters) => {
    if (dataSource && dataSource.isAttached && !prestoClusters) {
      return true;
    }

    if (prestoClusters) {
      const pcs = prestoClusters.filter((pc) => {
        return !PrestoClusters.utils.isGlueWithLakeFormationSupported(pc);
      });

      return pcs.length > 0;
    }

    return false;
  },
);

export const getUnsupportedRangerHivePrestoClusters = createSelector(
  DataSources.selectors.getDataSource,
  DataSources.selectors.getPrestoClusters,
  (dataSource, prestoClusters) => {
    if (dataSource && dataSource.isAttached && !prestoClusters) {
      return true;
    }

    if (prestoClusters) {
      const pcs = prestoClusters.filter((pc) => {
        return !PrestoClusters.utils.isRangerHiveSupported(pc);
      });

      return pcs.length > 0;
    }

    return false;
  },
);
