export const isProductionUI = (hostname) => {
  const host = hostname || (window && window.location.hostname);
  return host === 'ibmlh-app.cvpc.lakehouse.cloud.ibm.com';
};

export const isStagingUI = (hostname) => {
  const host = hostname || (window && window.location.hostname);
  return host === 'ibmlh-app.staging.cvpc.lakehouse.test.cloud.ibm.com';
};

export const isLocalUI = (hostname) => {
  const host = hostname || (window && window.location.hostname);
  return (
    (host &&
      host.startsWith('ibmlh-app.') &&
      host.endsWith('.dev.cvpc.lakehouse.test.cloud.ibm.com') &&
      !isStagingUI() &&
      !isProductionUI()) ||
    host === 'localhost'
  );
};

export const isLocalhost = (hostname) => {
  const host = hostname || (window && window.location.hostname);
  return host === 'localhost';
};

export const isDevUI = (hostname) => {
  return (
    !isProductionUI(hostname) &&
    !isStagingUI(hostname) &&
    !isLocalhost(hostname)
  );
};

// TODO this will need to change further when we get things running in IBM cloud domain.
export const getEnvironmentName = (hostname) => {
  if (isProductionUI(hostname)) return '';
  if (isStagingUI(hostname)) return 'staging';
  const host = hostname || (window && window.location.hostname);
  const r = host.match(
    /^ibmlh-app\.(?<env>\w+)\.cvpc\.lakehouse\.test\.cloud\.ibm\.com$/,
  );
  return r?.groups?.env ?? 'local';
};

const isStorybookEnvDefined = typeof STORYBOOK_ENV !== 'undefined';
export const isStorybook = () => {
  // eslint-disable-next-line no-undef
  return isStorybookEnvDefined && STORYBOOK_ENV === 'react';
};

export const getIAMUri = () => {
  if (isProductionUI()) {
    return 'https://identity-1.us-south.iam.cloud.ibm.com/identity/authorize';
  }

  return 'https://identity-1.us-south.iam.test.cloud.ibm.com/identity/authorize';
};
