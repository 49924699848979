import { useCallback } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { func, bool } from 'prop-types';
import routes from '../../../router/routes';
import useRouteBuilder from '../../../router/useRouteBuilder';
import AccountIcon from '../../../icons/Account';
import LogOutIcon from '../../../icons/LogOut';
import { onAccessibleClick } from '../../utils/accessibility';
import { AccountOptionsDropDownDiv, IconDiv } from './styledComponents';

const AccountOptionsDropdown = ({ noNav, signOut }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const signIn = useRouteBuilder(routes.signIn.getRoute());
  const onSignOut = useCallback(
    (event) => {
      return onAccessibleClick(event, () => {
        signOut();
        navigate(signIn, { replace: true });
      });
    },
    [signOut, signIn, navigate],
  );

  return (
    <AccountOptionsDropDownDiv>
      {!noNav && (
        <NavLink
          className='option'
          to={{
            pathname: routes.computePlanes.viewComputePlaneRoute(),
            search: location.search,
          }}
        >
          <IconDiv className='account'>
            <AccountIcon />
          </IconDiv>
          <div>Compute Plane</div>
        </NavLink>
      )}
      <div
        className='option'
        tabIndex='0'
        role='button'
        onClick={onSignOut}
        onKeyDown={onSignOut}
      >
        <IconDiv className='log-out'>
          <LogOutIcon />
        </IconDiv>
        <div>Log out</div>
      </div>
    </AccountOptionsDropDownDiv>
  );
};

AccountOptionsDropdown.propTypes = {
  signOut: func.isRequired,
  noNav: bool.isRequired,
};

export default AccountOptionsDropdown;
