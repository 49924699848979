export const getSubmitInProgress = (state) => {
  return state.prestoUsersAdd.submitInProgress;
};

export const getSubmitSuccess = (state) => {
  return state.prestoUsersAdd.submitSuccess;
};

export const getError = (state) => {
  return state.prestoUsersAdd.error;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};
