import { node, number } from 'prop-types';
import { FormContentDiv } from './styledComponents';

const FormContent = ({ rowCount, children }) => {
  const style = rowCount
    ? {
        '--row-count': rowCount,
      }
    : undefined;
  return <FormContentDiv style={style}>{children}</FormContentDiv>;
};

FormContent.defaultProps = {
  rowCount: undefined,
};

FormContent.propTypes = {
  rowCount: number,
  children: node.isRequired,
};

export default FormContent;
