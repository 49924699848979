import { bool, string } from 'prop-types';
import Page from '../../Page';
import Banner from '../../Banner';
import Form from '../../Form';
import AuthorizationServices from '../../AuthorizationServices';
import Help from './AuthorizationServiceDetailsHelp';

const display = {
  boxTitle: 'Details',
  formContentHeader: 'General',
  nameLabel: 'Name',
  descriptionLabel: 'Description',
  nameRequiredError: 'Name is required',
};

const AuthorizationServiceDetails = ({
  error,
  disabled,
  authorizationService,
}) => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <Page.FormContent>
        <div>
          <h3>{display.formContentHeader}</h3>
        </div>
        <div>
          <Form.TextInput
            name='name'
            label={display.nameLabel}
            disabled={disabled}
            validationRules={{
              required: display.nameRequiredError,
            }}
            fieldHelp={Help.Name}
            defaultValue={
              (authorizationService && authorizationService.name) || undefined
            }
          />
          <Form.TextInput
            name='description'
            label={display.descriptionLabel}
            disabled={disabled}
            optional
            defaultValue={
              authorizationService && authorizationService.description
            }
          />
        </div>
      </Page.FormContent>
    </Page.Box>
  );
};

export default AuthorizationServiceDetails;

AuthorizationServiceDetails.defaultProps = {
  disabled: false,
  error: undefined,
  authorizationService: null,
};

AuthorizationServiceDetails.propTypes = {
  disabled: bool,
  error: string,
  authorizationService: AuthorizationServices.propTypes.AuthorizationService,
};
