import { useCallback, useEffect } from 'react';
import { bool, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Form from '../../Form';
import Page from '../../Page';
import useRouteBuilder from '../../../router/useRouteBuilder';
import { onAccessibleClick } from '../../utils/accessibility';
import Data from '../../Data';

const display = {
  backButton: 'Back',
  copyButton: 'Copy',
};

const Policy = ({ usePop, policyContent, policyTitle }) => {
  const navigate = useNavigate();
  const homeRoute = useRouteBuilder('/');
  const notFoundRoute = useRouteBuilder('/notfound');
  const onBack = useCallback(
    (e) => {
      return onAccessibleClick(e, () => {
        if (usePop) {
          navigate(-1);
        } else {
          navigate(homeRoute, { replace: true });
        }
      });
    },
    [usePop, navigate, homeRoute],
  );

  useEffect(() => {
    if (!policyContent) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [policyContent, navigate, notFoundRoute]);

  if (!policyContent) {
    return <Page.Loading />;
  }

  return (
    <Page.Page>
      <Page.PageHeader>
        <h1>{policyTitle}</h1>
        <Page.PageHeaderButtons>
          <Form.CopyButton
            contentToCopy={policyContent}
            buttonLabel={display.copyButton}
            tooltipPlacement='left'
          />
          <Form.SecondaryButton onClick={onBack} label={display.backButton} />
        </Page.PageHeaderButtons>
      </Page.PageHeader>
      <Page.Scroll>
        <Data.CodeBox code={policyContent} language='json' />
      </Page.Scroll>
    </Page.Page>
  );
};

Policy.propTypes = {
  usePop: bool.isRequired,
  policyContent: string.isRequired,
  policyTitle: string.isRequired,
};

export default Policy;
