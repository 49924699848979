import { useEffect, useState, useCallback, useMemo } from 'react';
import { bool, func, string, shape, number, oneOfType } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Form from '../../Form';
import Page from '../../Page';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import useRouteBuilder from '../../../router/useRouteBuilder';
import PrestoClusters from '../../PrestoClusters';
import ChangeAutoScalingPolicyForm from './ChangeAutoScalingPolicyForm';

const ChangeAutoScalingPolicy = ({
  prestoCluster,
  prestoClusterId,
  name,
  status,
  notFound,
  actionStatus,
  usePop,
  loadPrestoCluster,
  saveAutoScalingPolicy,
  autoScalingPolicy,
  autoScalingType,
  maxWorkerNodeCount,
}) => {
  useEffect(() => {
    loadPrestoCluster(prestoClusterId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoClusterId]);

  const [actionInProgress, setActionInProgress] = useState(false);

  const save = useCallback(
    (values) => {
      const { autoScaling } = values;
      autoScaling.type = autoScalingType;

      // Destructure to remove the dynamic field from autoScaling
      const { dynamic, ...cleanedAutoScaling } = autoScaling;

      const submitId = Date.now();
      setActionInProgress(submitId);
      return saveAutoScalingPolicy(
        prestoClusterId,
        cleanedAutoScaling,
        submitId,
      );
    },
    [saveAutoScalingPolicy, prestoClusterId, autoScalingType],
  );

  const submitSuccess = useMemo(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    ) {
      return actionStatus.success;
    }
    return false;
  }, [actionStatus, actionInProgress]);

  const navigate = useNavigate();
  const notFoundRoute = useRouteBuilder('/notfound');
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!prestoCluster || notFound) {
    return <Page.Loading />;
  }

  return (
    <ActionInProgressStateContext.Provider value={actionInProgress}>
      <ActionInProgressSetStateContext.Provider value={setActionInProgress}>
        <Page.PageWithOverflow>
          <Form.Form name='change-autoscaling-policy' submitAction={save}>
            <ChangeAutoScalingPolicyForm
              prestoClusterId={prestoClusterId}
              prestoCluster={prestoCluster}
              actionStatus={actionStatus}
              maxWorkerNodeCount={maxWorkerNodeCount}
              usePop={usePop}
              notFound={notFound}
              submitSuccess={submitSuccess}
              status={status}
              name={name}
              autoScalingType={autoScalingType}
              autoScalingPolicy={autoScalingPolicy}
            />
          </Form.Form>
        </Page.PageWithOverflow>
      </ActionInProgressSetStateContext.Provider>
    </ActionInProgressStateContext.Provider>
  );
};

ChangeAutoScalingPolicy.defaultProps = {
  name: null,
  status: null,
  usePop: true,
  notFound: false,
  actionStatus: undefined,
  prestoCluster: null,
  autoScalingPolicy: null,
  autoScalingType: null,
  maxWorkerNodeCount: null,
};

ChangeAutoScalingPolicy.propTypes = {
  prestoClusterId: string.isRequired,
  prestoCluster: PrestoClusters.propTypes.PrestoCluster,
  name: string,
  status: string,
  notFound: bool,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  usePop: bool,
  loadPrestoCluster: func.isRequired,
  saveAutoScalingPolicy: func.isRequired,
  autoScalingPolicy: oneOfType([
    PrestoClusters.propTypes.AutoScalingPolicyIdleCostSavings,
    PrestoClusters.propTypes.AutoScalingPolicyCPU,
    PrestoClusters.propTypes.AutoScalingPolicyQueuedQueries,
  ]),
  autoScalingType: string,
  maxWorkerNodeCount: number,
};

export default ChangeAutoScalingPolicy;
