import { createContext, useContext, useState } from 'react';
import { node } from 'prop-types';

const CreatePrestoUserStateContext = createContext();
const CreatePrestoUserDispatchContext = createContext();

const useCreatePrestoUserState = (ignoreNull = false) => {
  const context = useContext(CreatePrestoUserStateContext);
  if (!ignoreNull && context === undefined) {
    throw new Error(
      'useCreatePrestoUserState must be used within CreatePrestoUser',
    );
  }
  return context;
};

const useCreatePrestoUserDispatch = (ignoreNull = false) => {
  const context = useContext(CreatePrestoUserDispatchContext);
  if (!ignoreNull && context === undefined) {
    throw new Error(
      'useCreatePrestoUserDispatch must be used within CreatePrestoUser',
    );
  }
  return context;
};

const CreatePrestoUserNavigation = ({ children }) => {
  const [state, dispatch] = useState(null);

  return (
    <CreatePrestoUserStateContext.Provider value={state}>
      <CreatePrestoUserDispatchContext.Provider value={dispatch}>
        {children}
      </CreatePrestoUserDispatchContext.Provider>
    </CreatePrestoUserStateContext.Provider>
  );
};

CreatePrestoUserNavigation.propTypes = {
  children: node.isRequired,
};

export default CreatePrestoUserNavigation;
export { useCreatePrestoUserState, useCreatePrestoUserDispatch };
