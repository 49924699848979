import { string, bool, elementType } from 'prop-types';
import { OptionalBadge } from '../Badge';
import Help from '../Help';
import { LabelWrapperDiv, LabelDiv } from './styledComponents';

const MainLabel = ({ label, optional, disabled, fieldHelp }) => {
  if (!fieldHelp) {
    return (
      <LabelDiv className={disabled ? 'disabled' : undefined}>
        {label}
        {optional && <OptionalBadge />}
      </LabelDiv>
    );
  }

  return (
    <LabelWrapperDiv>
      <LabelDiv className={disabled ? 'disabled' : undefined}>
        {label}
        {optional && <OptionalBadge />}
      </LabelDiv>
      <Help.Button fieldHelp={fieldHelp} />
    </LabelWrapperDiv>
  );
};

MainLabel.defaultProps = {
  optional: false,
  disabled: false,
  fieldHelp: undefined,
};

MainLabel.propTypes = {
  label: string.isRequired,
  optional: bool,
  disabled: bool,
  fieldHelp: elementType,
};

export default MainLabel;
