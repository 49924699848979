import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
import * as actionTypes from './actionTypes';

const initialState = {
  submitInProgress: false,
  submitSuccess: false,
  errors: undefined,
  submitId: null,
  prestoVersions: null,
  prestoVersionsLoading: true,
};

const onNavigation = () => {
  return { ...initialState };
};

const submitStart = (draft) => {
  draft.submitInProgress = true;
  draft.submitSuccess = false;
  draft.errors = undefined;
};

const submitSuccess = (draft) => {
  draft.submitInProgress = false;
  draft.submitSuccess = true;
  draft.errors = undefined;
};

const submitFailure = (draft, action) => {
  draft.submitInProgress = false;
  draft.submitSuccess = false;

  if (action.payload.apiResult.body.errorCode === 'prestocluster-1') {
    draft.errors = {
      details: action.payload.apiResult.body.errorMessage,
    };
  } else if (
    action.payload.apiResult.body.errorCode === 'prestocluster-2' ||
    action.payload.apiResult.body.errorCode === 'prestocluster-3' ||
    action.payload.apiResult.body.errorCode === 'prestocluster-37' ||
    action.payload.apiResult.body.errorCode === 'prestocluster-38' ||
    action.payload.apiResult.body.errorCode === 'prestocluster-39'
  ) {
    draft.errors = {
      clusterSettings: action.payload.apiResult.body.errorMessage,
    };
  } else if (action.payload.apiResult.body.errorCode === 'prestocluster-17') {
    draft.errors = {
      dataLakeSettings: action.payload.apiResult.body.errorMessage,
    };
  } else if (
    action.payload.apiResult.body.errorCode === 'prestocluster-13' ||
    action.payload.apiResult.body.errorCode === 'prestocluster-14' ||
    action.payload.apiResult.body.errorCode === 'prestocluster-15' ||
    action.payload.apiResult.body.errorCode === 'prestocluster-16'
  ) {
    draft.errors = {
      clustrAuth: action.payload.apiResult.body.errorMessage,
    };
  } else if (
    action.payload.apiResult.body.errorCode === 'tag-1' ||
    action.payload.apiResult.body.errorCode === 'tag-2' ||
    action.payload.apiResult.body.errorCode === 'tag-3' ||
    action.payload.apiResult.body.errorCode === 'tag-4' ||
    action.payload.apiResult.body.errorCode === 'tag-5' ||
    action.payload.apiResult.body.errorCode === 'tag-6' ||
    action.payload.apiResult.body.errorCode === 'tag-7'
  ) {
    draft.errors = {
      customTags: action.payload.apiResult.body.errorMessage,
    };
  } else {
    draft.errors = {
      details: action.payload.apiResult.body.errorMessage,
    };
  }
};

const signOut = () => {
  return { ...initialState };
};

const getVersions = (draft, action) => {
  draft.prestoVersions = action.payload.apiResult.body.result;
  draft.prestoVersionsLoading = false;
};

const getVersionsStart = (draft, action) => {
  draft.prestoVersionsLoading = true;
};

const getVersionsFailure = (draft, action) => {
  draft.prestoVersionsLoading = false;
  draft.errors = {
    prestoVersions: action.payload.apiResult.body.errorMessage,
  };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.submit]: submitStart,
  [actionTypes.post.success]: submitSuccess,
  [actionTypes.post.failure]: submitFailure,
  [auth.actionTypes.signOut]: signOut,
  [actionTypes.getVersions.success]: getVersions,
  [actionTypes.getVersions.request]: getVersionsStart,
  [actionTypes.getVersions.failure]: getVersionsFailure,
};

export default makeReducer(initialState, actionReducers);
