import { connect } from '../../utils/redux';
import ComputePlane from '../ComputePlane';
import { DestroyComputePlane } from './components';
import * as selectors from './selectors';

const mapStateToProps = (state) => {
  return {
    status: selectors.getComputePlaneStatus(state),
    errorMessages: selectors.getErrorMessages(state),
    iamRoleUrl: ComputePlane.selectors.getIamRoleUrl(state),
    awsRoleARN: selectors.getAWSRoleArn(state),
    computePlaneId: selectors.getComputePlaneId(state),
  };
};

export default connect(mapStateToProps, {
  destroy: ComputePlane.actions.destroy,
})(DestroyComputePlane);
