import Help from '../../Help';

export const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        You are now ready to setup your IBM watsonx.data compute plane!
      </Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Choose what type of VPC deployment you want.
        </Help.ListItem>
        <Help.ListItem>
          Enter some basic information so we can create a custom AWS IAM policy
          for you.
        </Help.ListItem>
        <Help.ListItem>
          Review the AWS IAM policies and role creation templates.
        </Help.ListItem>
        <Help.ListItem>
          Create your AWS IAM Role to allow the creation of the compute plane in
          your AWS account.
        </Help.ListItem>
        <Help.ListItem>
          Supply some final details about how your compute plane should be
          deployed.
        </Help.ListItem>
        <Help.ListItem>Validate all prerequisites are met.</Help.ListItem>
        <Help.ListItem>Start the compute plane creation process.</Help.ListItem>
        <Help.ListItem>
          Your compute plane is ready for its first Presto Cluster!
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>This deployment takes 20-30 minutes to complete.</Help.P>
    </Help.HelpSection>
  );
};
