import { useEffect } from 'react';
import { bool, func } from 'prop-types';
import AddDataSourcesSvg from '../../../img/add_data_sources.svg';
import CreateClusterSvg from '../../../img/create_cluster.svg';
// import CreateNotebookSvg from '../../../img/create_notebook.svg';
// import NotebookTutorialSvg from '../../../img/notebook_tutorial.svg';
import routes from '../../../router/routes';
// import { string } from 'prop-types';
import Page from '../../Page';
import { useEnableFeature } from '../../utils/featureHooks';
import { BoxesDiv, BoxDiv } from './styledComponents';

const display = {
  notebookTutorial: {
    header: '5 min notebook tutorial',
    descripton: 'short description of the feature goes below the title',
    button: 'Get started',
  },
  createNotebook: {
    header: 'Create notebook',
    descripton: 'short description of the feature goes below the title',
    button: 'Create new notebook',
  },
  createCluster: {
    header: 'Create cluster',
    descripton:
      'Get started with creating a Presto cluster deployed with a managed Hive Metastore',
    button: 'Create new cluster',
  },
  addDataSource: {
    header: 'Add a data source',
    descripton:
      'Add a data source like an Amazon S3 data lake via a data catalog or a relational database',
    button: 'Add a new data source',
  },
};

const HomePage = ({ canCreateCluster, loadPrestoClusters }) => {
  useEffect(() => {
    loadPrestoClusters();
  });

  const enableFeature = useEnableFeature();

  return (
    <Page.Page>
      <Page.Scroll>
        <BoxesDiv>
          {/* <BoxDiv style={{ backgroundImage: `url(${NotebookTutorialSvg})` }}>
            <h2>{display.notebookTutorial.header}</h2>
            <p>{display.notebookTutorial.descripton}</p>
            <Page.SecondaryLink to='/'>{display.notebookTutorial.button}</Page.SecondaryLink>
          </BoxDiv>
          <BoxDiv style={{ backgroundImage: `url(${CreateNotebookSvg})` }}>
            <h2>{display.createNotebook.header}</h2>
            <p>{display.createNotebook.descripton}</p>
            <Page.SecondaryLink to='/workspaces'>{display.createNotebook.button}</Page.SecondaryLink>
          </BoxDiv> */}
          <BoxDiv style={{ backgroundImage: `url(${CreateClusterSvg})` }}>
            <h2>{display.createCluster.header}</h2>
            <p>{display.createCluster.descripton}</p>
            <Page.PrimaryLink
              to={routes.prestoClusters.getAddPrestoClusterRoute()}
              state={{ replaceOnSuccess: routes.prestoClusters.getRoute() }}
              disabled={!enableFeature || !canCreateCluster}
            >
              {display.createCluster.button}
            </Page.PrimaryLink>
          </BoxDiv>
          <BoxDiv style={{ backgroundImage: `url(${AddDataSourcesSvg})` }}>
            <h2>{display.addDataSource.header}</h2>
            <p>{display.addDataSource.descripton}</p>
            <Page.PrimaryLink
              to={routes.dataSources.getAddDataSourceRoute()}
              state={{ replaceOnSuccess: routes.dataSources.getRoute() }}
              disabled={!enableFeature}
            >
              {display.addDataSource.button}
            </Page.PrimaryLink>
          </BoxDiv>
        </BoxesDiv>
      </Page.Scroll>
    </Page.Page>
  );
};

HomePage.propTypes = {
  canCreateCluster: bool.isRequired,
  loadPrestoClusters: func.isRequired,
};

export default HomePage;
