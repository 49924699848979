import Help from '../../Help';

export const Name = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Name</Help.HelpHeader>
      <Help.P>
        Pick a name for the identity provider. This is unique across the
        watsonx.data compute plane. We recommend a descriptive name to help you
        identify the integration in your identity provider.
      </Help.P>
    </Help.HelpSection>
  );
};

export const WellKnownConfigUrl = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Well Known Config URL</Help.HelpHeader>
      <Help.P>
        The URL of the “well-known” location providing the JSON document
        supporting discovery by the OpenID provider as specified per the OpenID
        Connect standard.
      </Help.P>
    </Help.HelpSection>
  );
};

export const ClientId = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Client ID</Help.HelpHeader>
      <Help.P>Client identifier for provider authentication.</Help.P>
    </Help.HelpSection>
  );
};

export const ClientSecret = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Client Secret</Help.HelpHeader>
      <Help.P>Client secret (i.e. password).</Help.P>
    </Help.HelpSection>
  );
};
