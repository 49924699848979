import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { func, string, bool } from 'prop-types';
// import { useNavigate } from 'react-router-dom';
import Page from '../../Page';
import Form from '../../Form';
import Help from '../../Help';
import PageHelp from './PageHelp';
import Banner from '../../Banner';
import Security from '../../Security';
import validatePasswordComplexity from '../../utils/validatePasswordComplexity';
import AddPrestoUserHelp from './AddPrestoUserHelp';
import RenderModal from '../../Form/RenderModal';
import Modal from '../../Form/Modal';

const display = {
  header: 'Create Presto User',
  cancelButton: 'Cancel',
  boxTitle: 'Presto User',
  addButton: 'Create Presto User',
  submitting: 'Creating Presto User...',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  formContentHeader: 'Credentials Settings',
  formExplaination: 'Secondary explanation',
  usernameLabel: 'Username',
  usernameRequiredError: 'Username is required',
  usernameMaxLength: 'Username cannot be longer than 255 characters',
  usernameInvalidCharacters:
    'Username can only consist of alphanumeric characters, dash(-), underscore(_), period(.), and tilde(~)',
  passwordLabel: 'Password',
  passwordRequiredError: 'Password is required',
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordRequiredError: 'Password is required',
  minLengthError: 'Password must be at least 8 characters',
  maxLengthError: 'Password cannot be longer than 64 characters',
  matchPasswordsError: 'Passwords should match',
  complexityError:
    'Password must contain at least 3 of the following: one uppercase letter, one lowercase letter, one number and one special character.',
  passwordInvalidCharacters:
    'Password can only contain printable ASCII characters except space( ), slash(/), double quote("), at sign(@), backslash(\\) or single quote(\')',
  usernameAndPasswordMustBeDifferent: 'Username and password must be different',
};

const AddPrestoUserForm = ({
  submitInProgress,
  submitSuccess,
  usePop,
  error,
  hideCreatePrestoUser,
}) => {
  const {
    getValues,
    formState: { isDirty },
  } = useFormContext();

  // const navigate = useNavigate();
  const validateComplexity = useCallback(
    (value) => {
      const username = getValues('username');
      return validatePasswordComplexity(value, username);
    },
    [getValues],
  );

  const extendOnCancel = useCallback(() => {
    if (hideCreatePrestoUser) {
      // eslint-disable-next-line no-alert
      if (
        !isDirty ||
        RenderModal(
          <Modal
            onSubmit={() => {
              hideCreatePrestoUser();
            }}
            onCancel={() => {}}
            confirmMessage={display.confirmCancel}
          />,
        )
      ) {
        hideCreatePrestoUser();
      }
      return true;
    }
    return false;
  }, [hideCreatePrestoUser, isDirty]);

  const extendShowPrompt = useCallback(() => {
    return !hideCreatePrestoUser;
  }, [hideCreatePrestoUser]);

  const extendSubmitSuccess = useCallback(() => {
    if (hideCreatePrestoUser) {
      hideCreatePrestoUser();
      return true;
    }
    return false;
  }, [hideCreatePrestoUser]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute: Security.route.getRoute,
    usePop,
    extendOnCancel,
    extendShowPrompt,
    extendSubmitSuccess,
  });

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <Page.Box>
            <Page.BoxHeader>
              <h2>{display.boxTitle}</h2>
            </Page.BoxHeader>
            {error && <Banner title={error} />}
            <Page.FormContent>
              <div>
                <h3>{display.formContentHeader}</h3>
              </div>
              <div>
                <Form.HiddenInput name='type' value='file' />
                <Form.TextInput
                  name='username'
                  type='string'
                  label={display.usernameLabel}
                  disabled={submitInProgress}
                  validationRules={{
                    required: display.usernameRequiredError,
                    maxLength: {
                      value: 255,
                      message: display.usernameMaxLengthError,
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9\-_.~]*$/,
                      message: display.usernameInvalidCharacters,
                    },
                  }}
                  fieldHelp={AddPrestoUserHelp.Username}
                />
                <Form.PasswordInput
                  name='password'
                  label={display.passwordLabel}
                  disabled={submitInProgress}
                  validationRules={{
                    required: display.passwordRequiredError,
                    minLength: { value: 8, message: display.minLengthError },
                    maxLength: { value: 64, message: display.maxLengthError },
                    validate: { validateComplexity },
                  }}
                  fieldHelp={AddPrestoUserHelp.Password}
                />
                <Form.ConfirmPasswordInput
                  disabled={submitInProgress}
                  name='password2'
                  passwordFieldName='password'
                />
              </div>
            </Page.FormContent>
          </Page.Box>
          <Page.Buttons>
            <Form.SecondaryButton
              onClick={onCancel}
              label={display.cancelButton}
              disabled={submitInProgress}
              disabledOnErrors={false}
            />
            <Form.Submit
              label={!submitInProgress ? display.addButton : display.submitting}
              disabled={submitInProgress}
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

AddPrestoUserForm.defaultProps = {
  usePop: true,
  error: null,
  hideCreatePrestoUser: undefined,
};

AddPrestoUserForm.propTypes = {
  submitInProgress: bool.isRequired,
  submitSuccess: bool.isRequired,
  usePop: bool,
  error: string,
  hideCreatePrestoUser: func,
};

const AddPrestoUser = ({
  submitInProgress,
  submitSuccess,
  error,
  submit,
  usePop,
  hideCreatePrestoUser,
}) => {
  return (
    <Page.PageWithOverflow>
      <Form.Form name='add-presto-user' submitAction={submit}>
        <AddPrestoUserForm
          submitInProgress={submitInProgress}
          submitSuccess={submitSuccess}
          error={error}
          usePop={usePop}
          hideCreatePrestoUser={hideCreatePrestoUser}
        />
      </Form.Form>
    </Page.PageWithOverflow>
  );
};

AddPrestoUser.defaultProps = {
  submitSuccess: false,
  error: undefined,
  usePop: true,
  hideCreatePrestoUser: undefined,
};

AddPrestoUser.propTypes = {
  submit: func.isRequired,
  submitInProgress: bool.isRequired,
  submitSuccess: bool,
  error: string,
  usePop: bool,
  hideCreatePrestoUser: func,
};

export default AddPrestoUser;
