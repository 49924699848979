import { useEffect } from 'react';
import { bool, arrayOf, func, shape, string } from 'prop-types';
import PrestoUsers from '../../PrestoUsers';
import PrestoClusters from '../../PrestoClusters';
import Page from '../../Page';
import { useEnableFeature } from '../../utils/featureHooks';
import { NoDataDiv } from './styledComponents';

const { PrestoUsersTable } = PrestoUsers.components;

const display = {
  title: 'Presto Users',
  attachButton: 'Manage Presto Users',
  loading: 'Loading...',
  noPrestoUsers: 'No attached Presto users',
};

const AttachedPrestoUsers = ({
  prestoUsers,
  loadPrestoUsers,
  loading,
  prestoCluster,
  prestoClusterId,
}) => {
  useEffect(() => {
    loadPrestoUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enableUserLink = useEnableFeature();

  const canAttachPrestoUsers =
    (prestoCluster.status === PrestoClusters.constants.status.active ||
      prestoCluster.status === PrestoClusters.constants.status.inactive) &&
    !prestoCluster.workflowInProgress;

  return (
    <Page.Box>
      <Page.TableBoxHeader>
        <h2>{display.title}</h2>
        <div className='buttons'>
          <Page.PrimaryLink
            to={PrestoClusters.routes.getPrestoClusterPrestoUsersRoute(
              prestoClusterId,
            )}
            disabled={!canAttachPrestoUsers || !enableUserLink}
          >
            {display.attachButton}
          </Page.PrimaryLink>
        </div>
      </Page.TableBoxHeader>
      {!prestoUsers && loading && <NoDataDiv>{display.loading}</NoDataDiv>}
      {((!prestoUsers && !loading) ||
        (prestoUsers && prestoUsers.length === 0)) && (
        <NoDataDiv>{display.noPrestoUsers}</NoDataDiv>
      )}
      {prestoUsers && prestoUsers.length > 0 && (
        <PrestoUsersTable prestoUsers={prestoUsers} />
      )}
    </Page.Box>
  );
};

AttachedPrestoUsers.defaultProps = {
  prestoUsers: null,
  loading: false,
};

AttachedPrestoUsers.propTypes = {
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadPrestoUsers: func.isRequired,
  loading: bool,
  prestoClusterId: string.isRequired,
  prestoCluster: shape({
    status: string.isRequired,
  }).isRequired,
};

export default AttachedPrestoUsers;
