export const isGlueWithLakeFormationSupported = (prestoCluster) => {
  if (!prestoCluster) return false;

  const { isCommunityEdition } = prestoCluster.clusterConfiguration;

  return !isCommunityEdition;
};

export const isRangerHiveSupported = (pc) => {
  if (!pc) return false;

  const { isCommunityEdition } = pc.clusterConfiguration;

  return !isCommunityEdition;
};
