/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { string, shape, bool, object, arrayOf, elementType } from 'prop-types';
import { useSelect } from 'downshift';
import DropDownIcon from '../../icons/DropDown';
import Label from './Label';
import {
  InputErrorDiv,
  SelectInputButton,
  SelectList,
  SelectInputDiv,
} from './styledComponents';

const itemToString = (item) => {
  return item ? item.display : '';
};

const SelectInput = ({
  name,
  label,
  disabled,
  defaultValue,
  validationRules,
  items,
  isPrivate,
  optional,
  fieldHelp,
}) => {
  const {
    field: { onChange, onBlur, value, ref: selectButtonRef },
    fieldState: { error: fieldError },
  } = useController({
    name,
    rules: validationRules,
    defaultValue,
  });

  const initItem = useMemo(() => {
    const init = items.find((i) => {
      return i.value === value;
    });
    return init;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectedItemChange = useCallback(
    (state) => {
      if (state.selectedItem) {
        onChange(state.selectedItem.value);
      } else {
        onChange(null);
      }
    },
    [onChange],
  );

  const onIsOpenChange = useCallback(
    (state) => {
      if (!state.isOpen) {
        onBlur();
      }
    },
    [onBlur],
  );

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useSelect({
    items,
    itemToString,
    initialSelectedItem: initItem,
    onSelectedItemChange,
    onIsOpenChange,
  });

  const selectInputDivRef = useRef(null);
  const [dropDownWidth, setDropDownWidth] = useState('200px');
  const resizeObserverRef = useRef(null);
  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries) => {
      if (entries) {
        entries.forEach((entry) => {
          const { width } = entry.contentRect;
          setDropDownWidth(`${width}px`);
        });
      }
    });
    if (selectInputDivRef.current) {
      resizeObserverRef.current.observe(selectInputDivRef.current);
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [selectInputDivRef]);

  const handleFocusChange = useCallback(
    (e) => {
      if (
        selectInputDivRef.current &&
        !selectInputDivRef.current.contains(e.target) &&
        selectButtonRef.current &&
        !selectButtonRef.current.contains(e.target)
      ) {
        closeMenu();
      }
    },
    [selectInputDivRef, closeMenu, selectButtonRef],
  );

  useEffect(() => {
    document.addEventListener('focusin', handleFocusChange);

    return () => {
      document.removeEventListener('focusin', handleFocusChange);
    };
  }, [handleFocusChange]);

  let buttonClassName = fieldError ? 'error ' : '';
  if (isOpen) {
    buttonClassName = `${buttonClassName}open`;
  }
  if (buttonClassName === '') {
    buttonClassName = undefined;
  }

  return (
    <>
      <Label
        label={label}
        optional={optional}
        fieldHelp={fieldHelp}
        {...getLabelProps()}
      />
      <SelectInputButton
        ref={selectButtonRef}
        {...getToggleButtonProps({
          disabled,
          className: buttonClassName,
          type: 'button',
        })}
        data-private={isPrivate ? true : undefined}
      >
        <div>{selectedItem ? itemToString(selectedItem) : ''}</div>
        <DropDownIcon />
      </SelectInputButton>
      {fieldError && !isOpen && (
        <InputErrorDiv role='alert'>{fieldError.message}</InputErrorDiv>
      )}
      <SelectInputDiv
        ref={selectInputDivRef}
        style={{ '--dropdown-width': dropDownWidth }}
        className={buttonClassName}
      >
        <SelectList
          {...getMenuProps({ className: isOpen ? 'open' : undefined })}
        >
          {isOpen &&
            items.map((item, index) => {
              let itemClassName =
                index === highlightedIndex ? 'highlighted ' : '';
              if (selectedItem && item.value === selectedItem.value) {
                itemClassName = `${itemClassName}selected`;
              }
              if (itemClassName === '') {
                itemClassName = undefined;
              }

              return (
                <li
                  key={`${item.value}`}
                  {...getItemProps({ item, index, className: itemClassName })}
                  data-private={isPrivate ? true : undefined}
                >
                  {item.display}
                </li>
              );
            })}
        </SelectList>
      </SelectInputDiv>
    </>
  );
};

SelectInput.defaultProps = {
  disabled: false,
  defaultValue: undefined,
  validationRules: undefined,
  items: [],
  isPrivate: false,
  optional: false,
  fieldHelp: undefined,
};

SelectInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  defaultValue: string,
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  items: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ),
  isPrivate: bool,
  optional: bool,
  fieldHelp: elementType,
};

export default SelectInput;
