const DropDown = () => {
  return (
    <svg viewBox='0 0 56 30' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M55.64 2.155L28.934 29.608a1.308 1.308 0 01-1.704.141l-.143-.122L.359 2.155A1.26 1.26 0 01.4.355C.638.126.958 0 1.292 0h53.414C55.421 0 56 .57 56 1.274c0 .328-.129.644-.36.88z'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default DropDown;
