import {
  call,
  delay,
  put,
  takeEvery,
  all,
  race,
  take,
  cancelled,
  select,
  fork,
  join,
} from 'redux-saga/effects';
import auth from '../auth';
import * as urls from './urls';
import logger from '../utils/logger';
import { buildHeaders } from './api';
import * as ably from './ably';

const wwwAuthenticateRegex = /\w*(="([\w\s]*)")?/g;

function* refreshAuthToken() {
  yield put(auth.actions.startRefreshToken());
  yield race([
    take(auth.actionTypes.getAuth.success),
    take(auth.actionTypes.signOut),
  ]);
}

function* executeApiRequest(action) {
  let abortController;
  try {
    const payload = {
      apiCallId: action.payload.apiCallId,
      actionInfo: action.payload.actionInfo,
      params: action.payload.params,
      requestBody: action.payload.body,
    };

    yield put({
      type: action.payload.actionTypes.pending,
      payload,
    });

    abortController = new AbortController();
    const abortFetchSignal = abortController.signal;

    let retryCount = 0;

    while (retryCount < 4) {
      retryCount += 1;
      let authToken;

      if (action.payload.requiresAuthentication) {
        let isAuthenticated = yield select(auth.selectors.isAuthenticated);
        const isJWTExpired = yield select(auth.selectors.isJWTExpired);

        if (isAuthenticated && isJWTExpired) {
          yield call(refreshAuthToken);
          isAuthenticated = yield select(auth.selectors.isAuthenticated);
        }

        if (isAuthenticated) {
          // get auth token
          authToken = yield select(auth.selectors.getJWTToken);
        } else {
          yield put({
            type: action.payload.actionTypes.failure,
            payload: {
              apiResult: {
                body: {
                  errorMessage: 'Authentication required',
                },
              },
              ...payload,
            },
          });
          return;
        }
      }

      const crn = yield select(auth.selectors.getCRN);
      if (!crn) {
        yield put({
          type: action.payload.actionTypes.failure,
          payload: {
            apiResult: {
              body: {
                errorMessage: 'CRN required',
              },
            },
            ...payload,
          },
        });
        return;
      }

      const headers = buildHeaders(
        authToken,
        action.payload.apiCallId,
        crn || '',
      );

      const apiResult = yield call(
        action.payload.api,
        urls.api,
        headers,
        action.payload.params,
        action.payload.body,
        abortFetchSignal,
      );

      if (apiResult.ok) {
        yield put({
          type: action.payload.actionTypes.success,
          payload: {
            apiResult,
            ...payload,
          },
        });
        return;
      }

      let loopAgain = false;
      if (apiResult.status === 401) {
        let tokenExpired = apiResult?.body?.errorCode.startsWith('identity');

        if (!tokenExpired && apiResult?.body.message === 'Unauthorized') {
          // www-authenticate: Bearer scope="" error="invalid_token" error_description="the token has expired"
          let wwwAuthenticate = apiResult.headers.get('www-authenticate');
          if (Array.isArray(wwwAuthenticate)) {
            // eslint-disable-next-line prefer-destructuring
            wwwAuthenticate = wwwAuthenticate[0];
          }

          if (typeof wwwAuthenticate === 'string') {
            logger.log(wwwAuthenticate);
            const parts = [
              ...wwwAuthenticate.matchAll(wwwAuthenticateRegex),
            ].flat();

            tokenExpired =
              (parts.includes('error="invalid_token"') &&
                parts.includes('error_description="the token has expired"')) ||
              wwwAuthenticate === 'Bearer';

            if (!tokenExpired) {
              // I dont think these errors necessarily mean that the token
              // needs to be refreshed. I think they are more an issue with the
              // API Gateway not reaching Cognito
              const communicationError =
                parts.includes('error="invalid_token"') &&
                (parts.includes(
                  'error_description="OIDC discovery endpoint communication error"',
                ) ||
                  parts.includes(
                    'error_description="JWKS communication error"',
                  ));
              if (!communicationError || retryCount > 5) {
                logger.error(wwwAuthenticate);
              } else {
                logger.warn(wwwAuthenticate);
              }
              if (communicationError) {
                yield delay(2 * retryCount);
              }
            }
          }
        }

        if (tokenExpired) {
          // refresh auth token
          yield call(refreshAuthToken);
          loopAgain = true;
        }
      } else if (apiResult.status === undefined) {
        // When there is no status code, there was some
        // networking problem. retry the request
        // TypeError: Failed to fetch
        yield delay(2 * retryCount);
        loopAgain = true;
      }
      if (!loopAgain) {
        const errorPayload = {
          apiResult,
          ...payload,
        };

        if (apiResult.status >= 500) {
          logger.error('API Server error', {
            ...apiResult,
            apiCallId: payload.apiCallId,
          });
          errorPayload.apiResult = {
            ...errorPayload.apiResult,
            body: {
              errorMessage: 'Server error',
            },
          };
        }
        yield put({
          type: action.payload.actionTypes.failure,
          payload: errorPayload,
        });
        return;
      }
    }
  } finally {
    if (yield cancelled()) {
      if (abortController) {
        abortController.abort();
      }

      yield put({
        type: action.payload.actionTypes.abort,
        payload: { apiCallId: action.payload.apiCallId },
      });
    }
  }
}

function matchRayApiActions(action) {
  return action.type.endsWith('REQUEST_SAGA');
}

function matchAction(actionType, apiCallId) {
  return (action) => {
    return (
      action.type === actionType &&
      action.payload &&
      action.payload.apiCallId === apiCallId
    );
  };
}

function* cancelableExecuteApiRequest(action) {
  if (action.payload.requiresAuthentication) {
    yield race({
      logOut: take(auth.actionTypes.signOut),
      executeApiRequest: call(executeApiRequest, action),
    });
  } else {
    yield call(executeApiRequest, action);
  }
}

export function* callApi(apiAction) {
  try {
    const { apiCallFinished } = yield all({
      apiCallFinished: race({
        success: take(
          matchAction(
            apiAction.payload.actionTypes.success,
            apiAction.payload.apiCallId,
          ),
        ),
        failure: take(
          matchAction(
            apiAction.payload.actionTypes.failure,
            apiAction.payload.apiCallId,
          ),
        ),
      }),
      apiCall: call(cancelableExecuteApiRequest, apiAction),
    });

    return !!apiCallFinished.success;
  } finally {
    if (yield cancelled()) {
      yield put({
        type: apiAction.payload.actionTypes.abort,
        payload: { apiCallId: apiAction.payload.apiCallId },
      });
    }
  }
}

function* ablyAuthCallback(args) {
  try {
    const success = yield call(callApi, ably.actions.getAblyJWT());
    if (!success) {
      args.callback({ statusCode: 500 }, null);
      return;
    }

    const ablyJWT = yield select(ably.selectors.getAblyJWT);
    args.callback(null, ablyJWT);
  } catch (e) {
    logger.error(e);
    args.callback({ statusCode: 500 }, null);
  } finally {
    if (yield cancelled()) {
      logger.info('ablyAuthCallback saga cancelled');
      args.callback({ statusCode: 403 }, null);
    }
  }
}

function* ablyChannelMessage(args) {
  if (args.message.name === 'cdc') {
    yield put({
      type: 'REALTIME_CDC',
      payload: {
        ...args,
      },
    });
  }
}

function* ablyDispatcher(message) {
  if (message.type === 'authCallback') {
    yield fork(ablyAuthCallback, message.args);
  } else {
    yield fork(ablyChannelMessage, message.args);
  }
}

function* runAbly() {
  let channel;
  try {
    const tenantId = yield select(ably.selectors.getTenantId);
    channel = yield call(ably.connectToChannel, tenantId);
    const t = yield takeEvery(channel, ablyDispatcher);
    yield join(t);
  } finally {
    if (yield cancelled()) {
      if (channel) {
        channel.close();
      }
    }
  }
}

function* cancelableRunAbly() {
  yield race({
    logOut: take(auth.actionTypes.signOut),
    runAbly: call(runAbly),
  });
}

export function* rayApiRootSaga() {
  yield all([
    takeEvery(matchRayApiActions, cancelableExecuteApiRequest),
    takeEvery(auth.actionTypes.signIn, cancelableRunAbly),
  ]);
}
